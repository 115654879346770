import React from 'react';
import { logout } from '../../core/authn/Client';
import { Button } from '../../kit/Button';
import { Dialog } from '../../kit/Dialog';
import { Layout } from '../../kit/Layout';
import { Text } from '../../kit/Text';
import { InitialSyncErrorReason } from '../../syncstream/SyncCenterProvider';

interface IProps {
  errorReason: InitialSyncErrorReason;
  isServerHealthy: boolean;
}
interface DialogContent {
  dialogHeader: string;
  dialogText: string;
}
export function ServerErrorHandlerDialog(props: IProps) {
  const { dialogHeader, dialogText } = getDialogContent(props.isServerHealthy, props.errorReason);
  function onDialogClose() {
    logout();
  }

  function refreshPage() {
    window.location.reload();
  }

  return (
    <Dialog open={true} secondary size={'sm'} onRequestClose={onDialogClose}>
      <Layout padding={0.5} gap={1}>
        <Text weight={'bold'} size={'medium'}>
          {dialogHeader}
        </Text>
        <Text>{dialogText}</Text>
        <Layout horizontal justify={'right'} gap={0.5} style={{ justifySelf: 'flex-end', width: '5em' }}>
          <Button disabled={!props.isServerHealthy} fullWidth onClick={refreshPage}>
            Refresh
          </Button>
          <Button fullWidth onClick={onDialogClose}>
            Logout
          </Button>
        </Layout>
      </Layout>
    </Dialog>
  );
}

function getDialogContent(isServerHealthy: boolean, errorReason: InitialSyncErrorReason): DialogContent {
  if (errorReason === InitialSyncErrorReason.Other) {
    return {
      dialogHeader: 'Failed to Sync',
      dialogText:
        'Something went wrong when syncing the data. Please click refresh to try again or click logout to exit',
    };
  } else {
    return {
      dialogHeader: isServerHealthy ? 'Server back online' : 'Network Disconnected',
      dialogText: isServerHealthy
        ? 'Connected to the network. Please click refresh to try again'
        : 'There has been a connection problem and loading failed to complete. Please check your internet connection and try again.',
    };
  }
}
