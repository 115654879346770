import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { Layout } from '../../../../kit/Layout';
import { Text } from '../../../../kit/Text';
import { colors } from '../../../../kit/Theme/Theme';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import { PatchObservationInfo } from '../patches/PatchUtils';

export function PatchObservationCompact(props: { observationInfoArr: PatchObservationInfo[] }) {
  const { observationInfoArr } = props;
  return (
    <Layout gap={0.5}>
      <PatchObservationContainer gap={0.5} padding={'0.875rem'}>
        <Text style={{ color: colors.bg_orange_600 }} size={'small'} weight={'bold'}>
          Patch Observations
        </Text>
        {observationInfoArr.map((observationInfo, index) => {
          return <PatchObservationDetails key={index} patchObservationInfo={observationInfo} />;
        })}
      </PatchObservationContainer>
    </Layout>
  );
}

function PatchObservationDetails(props: { patchObservationInfo: PatchObservationInfo }) {
  const apiUtils = useApiUtils();

  const createdByNameAndRole =
    apiUtils.users.getUserFullNameAndRoleFromSubjectId(props.patchObservationInfo.userSubjectId) ?? 'Unknown user';
  return (
    <Layout>
      <Text size={'small'} weight={'200'}>
        Patch {props.patchObservationInfo.patchStatus.toLowerCase()} at{' '}
        {`${DateUtils.dateStringTo24HourTimeString(props.patchObservationInfo.createdAt)} on ${format(
          DateUtils.toDate(props.patchObservationInfo.createdAt),
          'dd MMM yyyy',
        )}`}{' '}
        by {createdByNameAndRole}
      </Text>
      {props.patchObservationInfo.comments.length > 0 && (
        <Text size={'small'} weight={'200'}>
          Note: {props.patchObservationInfo.comments[0]}
        </Text>
      )}
    </Layout>
  );
}

const PatchObservationContainer = styled(Layout)`
  border-width: 1px;
  border-style: groove;
`;
