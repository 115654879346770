import * as React from 'react';
import { Tooltip } from 'react-tooltip';

export function IconReproductiveHazardous(props: React.SVGProps<SVGSVGElement>) {
  return (
      <>
        <Tooltip id="IconReproductiveHazardous" />
        <svg
          id="prefix__Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x={0}
          y={0}
          viewBox="0 0 19.1 16.5"
          xmlSpace="preserve"
          data-tooltip-content={props.xlinkTitle ?? "Reproductive Hazardous"}
          data-tooltip-id="IconReproductiveHazardous"
          data-tooltip-place="top"
          {...props}
        >
          <style>{'.prefix__strephaz{fill:#e50090}'}</style>
          <path
            className="prefix__strephaz"
            d="M8.2 11.7c0 .3-.2.5-.6.5h-.2v-1h.3c.3 0 .5.2.5.5zm.2.9c.4-.2.6-.5.6-1 0-.7-.5-1.1-1.3-1.1H6.5V14h.9v-1.2h.2l.8 1.2.9-.1-.9-1.3zm4.1 1.4v-3.4h-.9v1.3h-1.2v-1.3h-.8V14h.9v-1.4h1.2V14h.8z"
          />
          <path className="prefix__strephaz" d="M19.1 16.5H0L9.6 0l9.5 16.5zm-15.6-2h12.2L9.6 4 3.5 14.5z" />
        </svg>
      </>
  );
}
