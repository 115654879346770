import { isWithinInterval } from 'date-fns';
import React from 'react';
import { WarningDetailRes } from 'server-openapi';
import styled from 'styled-components';
import { useHealthCheck } from '../../core/healthcheck/HealthCheckProvider';
import { DateUtils } from '../../core/utils/dateUtils';
import { Button } from '../../kit/Button';
import { Dialog } from '../../kit/Dialog';
import { Checkbox } from '../../kit/Forms/CheckBoxFormInput';
import { useAsync } from '../../kit/hooks/UseAsync';
import { Layout } from '../../kit/Layout';
import { Text } from '../../kit/Text';
import { toasts } from '../../kit/Toasts/Toaster';
import { SyncStreamAPI } from '../../syncstream/api';

export function MaintenanceWarningDialog() {
  const isServerHealthy = useHealthCheck().isHealthy;

  //do not check if the device is offline
  if (!isServerHealthy) {
    return null;
  }
  return <MaintenanceWarningDialogContent />;
}

function MaintenanceWarningDialogContent() {
  const [open, setOpen] = React.useState(true);
  const [dismissWarning, setDismissWarning] = React.useState(false);

  const warningDetailsApi = new SyncStreamAPI().warningDetails;

  const warningDetailRes = useAsync(async () => {
    return await warningDetailsApi.warningDetailsFetchLatestWarningDetail();
  });
  const warningDetails = warningDetailRes.value?.data;
  if (warningDetailRes.loading || !warningDetails || !isActiveWarning(warningDetails!)) {
    return null;
  }

  async function onDialogClose() {
    if (dismissWarning) {
      const warningId = warningDetailRes.value?.data.id;
      await warningDetailsApi.warningDetailsDismissNotification(warningId);
      toasts.success('Warning will not be shown again');
    }
    setOpen(false);
  }

  return (
    <Dialog secondary open={open} size={'sm'} onRequestClose={onDialogClose}>
      <Layout padding={0.5} gap={1}>
        <Text weight={'bold'} size={'medium'}>
          Maintenance Warning Alert
        </Text>
        <Text>{warningDetails.message}</Text>
        <Layout align={'center'} horizontal gap={0.5}>
          <Checkbox name={'dismiss-warning'} value={dismissWarning} onChange={(_, value) => setDismissWarning(value)} />
          <Text weight={'800'}>Do not show again</Text>
        </Layout>
        <Layout horizontal justify={'right'} gap={0.5} style={{ justifySelf: 'flex-end', width: '5em' }}>
          <Button fullWidth>
            <StyledAnchor rel={'noreferrer'} target={'_blank'} href={'https://psp.mps-aust.com.au/Home/Resources'}>
              Resources
            </StyledAnchor>
          </Button>
          <Button fullWidth onClick={onDialogClose}>
            OK
          </Button>
        </Layout>
      </Layout>
    </Dialog>
  );
}

function isActiveWarning(warningDetailRes: WarningDetailRes): boolean {
  const startTime = DateUtils.toDate(warningDetailRes.startTime!);
  const endTime = DateUtils.toDate(warningDetailRes.endTime!);

  if (isWithinInterval(Date.now(), { start: startTime, end: endTime })) {
    return true;
  }
  return false;
}

const StyledAnchor = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.button.primary.fg};
  font-size: 0.8rem;
`;
