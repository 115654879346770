import React from 'react';
import { HSPatient } from 'server-openapi';
import styled from 'styled-components';
import { DialogButton } from '../../../kit/DialogButton';
import { Layout } from '../../../kit/Layout';
import { useSyncCenter } from '../../../syncstream/SyncCenterProvider';
import { useMap } from "../../../core/storage/hooks/UseStore";
import { OrderNotesProps } from '../../FacilityManagement/FacilityOrdersPage/Components/OrderNotesDialog';
import { OrderResupplyProps } from '../../FacilityManagement/FacilityOrdersPage/Components/OrderResupplyDialog';
import { PatientOrders } from '../../FacilityManagement/FacilityOrdersPage/Components/PatientOrders/PatientOrders';
import { PatientNotes } from './sidebar/PatientNotes';
import { PatientTests } from './sidebar/PatientTests';
import { PatientCharts } from './sidebar/PatientCharts';
import { PatientCeasedMedications } from './sidebar/PatientCeasedMedications';
import { useApiUtils } from '../../../syncstream/utils/hooks/useApiUtils';
import { MyHealthRecord } from "./sidebar/MyHealthRecord";
import { Button } from '../../../kit/Button';
import {toasts} from "../../../kit/Toasts/Toaster";
import {useGroupPermissions} from "../../../core/authz/PermissionsProvider";

interface Props {
  patient: HSPatient;
  openOrderResupply?: (orderResupply: OrderResupplyProps) => void;
  openOrderNotes?: (orderNotes: OrderNotesProps) => void;

}

export function ResidentDetailsSidebar(props: Props) {
  const services = useSyncCenter();

  // TODO: the point of hooks is to not pass globally accessible
  // things like stores as props through many levels
  const facilitiesStore = useMap(services.facilities);
  const patientsStore = useMap(services.patients);
  const drugStore = useMap(services.drugs);
  const orderStore = useMap(services.orders);
  const facilityGroupId = useApiUtils().patients.findFacilityGroup(props.patient);
  const groupPermissions = useGroupPermissions();
  const canViewResupply = groupPermissions.canViewResupplyOrder;

  const facility = facilitiesStore.get(props.patient.facility!.toString())!;
    return (
    <Container>
      <Layout horizontal gap={1}>
        <SidebarButton label="Notes/Docs" size="full" dialogSecondary>
          <PatientNotes patient={props.patient} groupPermissions={groupPermissions}/>
        </SidebarButton>
        <SidebarButton lazy label="Tests" size="full" dialogSecondary>
          <PatientTests patient={props.patient} />
        </SidebarButton>
        <SidebarButton lazy label="Chart" size="full" dialogSecondary>
          <PatientCharts patient={props.patient} />
        </SidebarButton>
        {
          canViewResupply ? <SidebarButton lazy label="Resupply" size="full" dialogSecondary>
                <PatientOrders
                    patient={props.patient}
                    facilitiesStore={facilitiesStore}
                    patientsStore={patientsStore}
                    ordersStore={orderStore}
                    drugsStore={drugStore}
                    openOrderResupply={props.openOrderResupply}
                    openOrderNotes={props.openOrderNotes}
                />
              </SidebarButton>
              :
              <NotAllowedButton onClick={() => {
                toasts.error("You do not have permission to view orders");
              }}>Resupply</NotAllowedButton>
        }
        <SidebarButton lazy label="Ceased Med" size="full" dialogSecondary>
          <PatientCeasedMedications facilityGroupId={facilityGroupId} patient={props.patient} />
        </SidebarButton>
        {facility.usesMhr ? (
            <SidebarButton lazy label="MHR" size="full" dialogSecondary>
              <MyHealthRecord facilityGroupId = {facilityGroupId} patient={props.patient}/>
            </SidebarButton>
        ) : null}
      </Layout>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  margin-bottom: 3rem;
  transform: rotate(-90deg) translateX(100%);
  transform-origin: bottom right;
`;

  const NotAllowedButton = styled(Button)`
  background: ${(props) => props.theme.backgrounds.light.bg};
  border-radius: 12px 12px 0px 0px;
  `;
const SidebarButton = styled(DialogButton)`
  background: ${(props) => props.theme.backgrounds.light.bg};
  border-radius: 12px 12px 0px 0px;
`;
