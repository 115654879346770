import React from 'react';

interface IProps {
  className: string;
  onVisibilityChange: (isVisible: boolean) => void;
  children: React.ReactNode;
  onClick?: () => void;
}

export function ScrollHandler(props: IProps) {
  const containerDiv = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const element = containerDiv.current;
    if (
      element !== null &&
      (element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth)
    ) {
      props.onVisibilityChange(true);
    }
  }, []);

  return (
    <div className={props.className} onClick={props.onClick} ref={containerDiv}>
      {props.children}
    </div>
  );
}
