import { differenceInCalendarDays } from 'date-fns/esm';
import { DateUtils } from '../../core/utils/dateUtils';

export const AllergyUtils = {
  getChartReviewDateColour: (dateString?: string | null) => {
    if (!dateString) {
      return 'red';
    }
    const date = DateUtils.toDate(dateString);
    if (differenceInCalendarDays(date, new Date()) <= 0) {
      return 'red';
    }
    if (differenceInCalendarDays(date, new Date()) <= 14) {
      return 'orange';
    }
    return 'green';
  },

  getSeverityColor: (severity?: string | null) => {
    switch (severity) {
      case 'Mild':
        return 'green';
      case 'Moderate':
        return 'orange';
      case 'Severe':
        return 'red';
      default:
        return 'red';
    }
  },
};
