// import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';
// import { apis } from '../core/mrs/apis';
// import { ImageData } from 'server-openapi';
import { appconfig } from '../appconfig';

const PLACEHOLDER_SOURCE = '/images/silhouettes/na.jpg';
const PLACEHOLDER_URL = require("./placeholder.png");

// TODO: please move this out of the ui kit since it is coupled to the API
// TODO: the interface should be refactored to indicate if the facility group id is always required
interface Props {
  facilityGroupId: number;
  src: string;
  className?: string;
  onClick?: () => void;
}

const StyledImage = styled.img`
  object-fit: cover;
`;

export function Image(props: Props) {
  return (
    <VisibilitySensor>
      {({ isVisible }) => <FacilityImage {...props} facilityGroupId={props.facilityGroupId} isVisible={isVisible} />}
    </VisibilitySensor>
  );
}

interface FacilityImageProps extends Props {
  facilityGroupId: number;
  isVisible: boolean;
  onClick?: () => void;
}

const FacilityImage = (props: FacilityImageProps) => {
  const url = props.src.startsWith(PLACEHOLDER_SOURCE) ? PLACEHOLDER_URL : appconfig.API_URL + props.src;

  // If the image fails to load (e.g. the network connection is not available), then show the placeholder image.
  // TODO: The placeholder image is fine for patients, but is not right for tablets.
  function handleError(event: React.ChangeEvent<HTMLImageElement>) {
    event.target.onerror = null;
    event.target.src = PLACEHOLDER_URL;
  };
  return <StyledImage className={props.className} src={url} onError={handleError} onClick={props.onClick}/>;
};

/****
const FacilityImageOLD = (props: FacilityImageProps) => {
  const [image, setImage] = useState<AxiosResponse<ImageData> | undefined>();
  const fetchImage = async () => {
    setImage(undefined);
    const newImage = await apis.images.imageGetImage(props.facilityGroupId, props.src);
    setImage(newImage);
  };
  useEffect(() => {
    if (props.isVisible) {
      fetchImage();
    }
  }, [props.src, props.facilityGroupId, props.isVisible]);

  if (props.src === PLACEHOLDER_SOURCE) {
    return <StyledImage className={props.className} src={require('./placeholder.png')} />;
  }

  if (!image) {
    return <StyledImage className={props.className} src={require('./placeholder.png')} />;
  }

  //assumes format is jpg currently
  return <StyledImage className={props.className} src={`data:image/jpeg;base64,${image.data.data}`} />;
};
***/
