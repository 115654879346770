import _ from 'lodash';
import React from 'react';
import { Button } from '../../kit/Button';
import { Layout } from '../../kit/Layout';
import { Text } from '../../kit/Text';
import { toasts } from '../../kit/Toasts/Toaster';
import { Form } from '../../kit/Forms/Form';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useSyncCenter } from '../../syncstream/SyncCenterProvider';
import { useCurrentUser } from '../../core/authn/UserProvider';
import { sha512 } from 'js-sha512';
import { appconfig } from '../../appconfig';
import { UpsertPinOp } from '../../syncstream/SyncPinUsers';
import { PinInput } from './PinInput';
import { useStore } from '../../core/storage/hooks/UseStore';
import itiriri from 'itiriri';

interface FormFields {
  pin: string;
}

export function PinUpdateForm(props: { onSubmit: () => void }) {
  const services = useSyncCenter();
  const form = useForm<FormFields>();
  const currentUser = useCurrentUser();
  const usersStore = useStore(services.users.store).store;
  const userDetails = itiriri(usersStore.values()).find((u) => u.subjectId === currentUser.profile.sub);

  async function onSubmit(data: FormFields) {
    if (data.pin.length !== 4) {
      return;
    }
    const req: UpsertPinOp = {
      type: 'upsert-pin',
      pinUser: {
        firstName: userDetails?.firstName,
        familyName: userDetails?.familyName,
        email: userDetails?.email,
        subjectId: currentUser.profile.sub,
        pinHash: sha512(data.pin.concat(appconfig.PINSALT)),
      },
      newPin: data.pin,
    };
    await services.pinUsers.service.enqueue.upsertPin(req);
    // to ensure pin users syncs up immediately after user enters pin without needing to select a facility
    await services.pinUsers.service.syncUp();
    toasts.success('Pin successfully set');
    props.onSubmit();
  }

  return (
    <StyledForm form={form} onSubmit={onSubmit}>
      <Layout gap={1} justify="center" align="center">
        <Text weight="bold">Please set your four digit PIN</Text>
        <Layout horizontal justify="center">
          <PinInput required name="pin" />
          <Button type="submit" style={{ height: '100%' }}>
            OK
          </Button>
        </Layout>
      </Layout>
    </StyledForm>
  );
}

const StyledForm = styled((props) => Form<FormFields>(props))`
  margin: auto;
`;
