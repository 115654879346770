import React, { useContext, useState } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { Theme } from './themes';
import { GlobalStyle } from './GlobalStyle';
import { ToastProvider } from '../Toasts/ToastProvider';
import { ThemeEditor } from './ThemeEditor';

interface Props {
  theme: Theme;
  children: React.ReactNode;
}

interface ThemeContext {
  theme: Theme;
  setTheme: (theme: Theme) => void;
}

export const ThemeContext = React.createContext<ThemeContext>({} as ThemeContext);

export function useThemeContext() {
  return useContext(ThemeContext);
}

export const ThemeProvider = (props: Props) => {
  const [theme, setTheme] = useState(props.theme);
  return (
    // the top level "theme context provider" allows us to
    // get/set the theme in our theme editor.
    // the "StyledComponentsThemeProvider" allows us to
    // get the theme in our UI components (styled components)
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <StyledComponentsThemeProvider theme={theme}>
        <GlobalStyle />
        <ToastProvider />
        <ThemeEditor />
        {props.children}
      </StyledComponentsThemeProvider>
    </ThemeContext.Provider>
  );
};
