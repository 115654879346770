/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';

export function createServiceProvider<T>(): [React.Provider<T>, () => T] {
  const context = React.createContext<T>({} as any);

  const provider = context.Provider;
  const useHook = () => useContext(context);

  return [provider, useHook];
}
