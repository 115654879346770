import * as React from 'react';
import { SVGProps } from 'react';

const WarningTriangleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 36 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.583 30.25h34.834L18 .167.583 30.25Zm19-4.75h-3.166v-3.166h3.166V25.5Zm0-6.333h-3.166v-6.333h3.166v6.333Z"
      fill={props.color ?? '#4E2974'}
    />
  </svg>
);

export default WarningTriangleIcon;
