import { DrugCategoryMapping } from 'server-openapi';
import { IStorage } from '../core/storage/Contract';
import { SyncStreamAPI } from './api';
import { ISyncService } from './SyncCenter';
import {SyncUtils} from "./utils/SyncUtils";

export class SyncDrugCategories implements ISyncService {
  get name(): string {
    return 'SyncDrugCategories';
  }

  constructor(private api: SyncStreamAPI,
      private storage: IStorage<DrugCategoryMapping>,
      private latestChangeNumbers: IStorage<string | undefined>,
      ) {}

  async syncDown() {
    const drugCategories = await this.api.drugCategories.drugCategoriesListDrugCategories((await this.latestChangeNumbers.get('')) ?? "");
    if (drugCategories.data?.drugCategoryMappings && drugCategories.data.drugCategoryMappings.length > 0) {
      await this.storage.setMany(
          drugCategories.data.drugCategoryMappings.map((drugCategory) => ({
            key: this.storage.get_key!(drugCategory),
            value: drugCategory,
          })),
      );
      await this.latestChangeNumbers.set(
          '',
          drugCategories.data.etag || "",
      );

    }
  }

  async syncUp() {
    // Do nothing
  }

  async clear() {
    await this.storage.clear();
  }

  async hasQueuedData() {
        return false;
  }
  isAllowed(canUserAccessMedication: boolean): boolean {
    // Only if you can view a round.
    return canUserAccessMedication;
  }
}
