import React, {useEffect, useState} from "react";
import {Table, TableBody, TableCell, TableColumn, TableRow} from "../../../../../kit/Table";
import {apis} from "../../../../../core/mrs/apis";
import {CreateUserMessage, CreateUserMessageType, CreateUserStatus, RoleDto, UserOnboardingReq} from "server-openapi";
import {Button, Variation} from "../../../../../kit/Button";
import {colors, Intent} from "../../../../../kit/Theme/Theme";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import SvgTick from "../../../../../kit/Icons/Tick";
import SvgWarning from "../../../../../kit/Icons/Warning";
import WarningTriangleIcon from "../../../../../kit/Icons/WarningTriangle";

interface IParams {
    usersToImport: UserOnboardingReq[];
    facilityGroupId: number;
    roles: RoleDto[];
    updateCompleted: () => void;
}

export function ImportUsersDetail(props: IParams) {
    const [numInProgress, setNumInProgress] = useState<number>(0);
    const [inProgress, setInProgress] = useState<boolean>(false);

    function decrement(): void {
        setNumInProgress(numInProgress - 1);
        if (numInProgress === 0) {
            setInProgress(false);
        }
    }
    return (
        <Table fullWidth>
            <thead>
                <tr>
                    <TableColumn>FIRST NAME</TableColumn>
                    <TableColumn>FAMILY NAME</TableColumn>
                    <TableColumn>LOGIN TYPE</TableColumn>
                    <TableColumn>EMAIL</TableColumn>
                    <TableColumn>ROLES</TableColumn>
                </tr>
            </thead>
            <TableBody>
                {props.usersToImport.map((user) => {
                    return <UserRow user={user} inProgress={inProgress} facilityGroupId={props.facilityGroupId} roles={props.roles} updateCompleted={decrement} key={user.email}></UserRow>
                })}
            </TableBody>
            <tfoot>
                <tr>
                    <td colSpan={6}>
                        <ButtonActionsContainer>
                            <Button onClick={() => props.updateCompleted()} intent={Intent.Secondary} variation={Variation.outline}>Cancel</Button>
                            <Button onClick={() => setInProgress(true)}>Perform Import</Button>
                        </ButtonActionsContainer>
                    </td>
                </tr>
            </tfoot>
        </Table>
    )

}
export interface IUserRowOptions {
    user: UserOnboardingReq;
    facilityGroupId: number;
    roles: RoleDto[];
    inProgress: boolean;
    updateCompleted: () => void;
}

export function UserRow (props: IUserRowOptions) {
    const { user, facilityGroupId, roles, inProgress, updateCompleted } = props;
    const [state, setState] = useState<"Pending" | "InProgress" | "Warning" | "Error" | "Completed">("Pending");
    const [messages, setMessages] = useState<CreateUserMessage[]>([]);

    useEffect(() => {
        if (inProgress) {
            performUpdate();
        }
    }, [inProgress]);

    async function performUpdate() {
        setState("InProgress");
        setMessages([]);
        await apis.users.userInviteUser({
            facilityGroupId: facilityGroupId,
            userDetails: user
        }).then((resp) => {
            if (resp.data.createUserStatus === CreateUserStatus.Created) {
                setState("Completed");
            }
            else if (resp.data.createUserStatus === CreateUserStatus.AccessExists) {
                setState("Warning");
            }
            else {
                setState("Error");
            }
            setMessages(resp.data.messages ?? []);
            updateCompleted();
        }).catch(err => {
            setState("Error");
            setMessages([{message: err.message, messageType: CreateUserMessageType.Error}]);
            updateCompleted();
        });

    }
    function roleDescription(role: string, roleList: RoleDto[]): string {
        return roleList.find(x => x.urn === role)?.name ?? 'unknown';
    }

    return (
        <TableRow>
            <TableCell>
                {user.firstName}
            </TableCell>
            <TableCell>
                {user.familyName}
            </TableCell>
            <TableCell>
                {user.loginType}
            </TableCell>
            <TableCell>
                {user.email}
            </TableCell>
            <TableCell>
                {user.userRoleAssignments.map((role) =>  roleDescription(role.roleUrn ?? '', roles)).join(", ")}
            </TableCell>
            <td>
                {
                    state === "InProgress" &&  <Loader type="Oval" height={40} width={40} />
                }
                {
                    state === "Completed" &&  <SvgTick color={colors.bg_gray_600}></SvgTick>
                }
                {
                    state === "Warning" &&  <SvgWarning color={colors.bg_orange_500}></SvgWarning>
                }
                {
                    state === "Error" &&  <WarningTriangleIcon color={colors.bg_red_500}></WarningTriangleIcon>
                }
            </td>
            <TableCell>
                {
                    messages.map((msg) => {
                        return (
                            <Message key={msg.message} messageType={msg.messageType}>{msg.message}</Message>
                        );
                    })}
            </TableCell>
        </TableRow>
    );
}
const ButtonActionsContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const Message = styled.div<{ messageType: CreateUserMessageType | undefined }>`
   color: ${(props) => props.messageType === CreateUserMessageType.Error ? colors.bg_red_500 : props.messageType === CreateUserMessageType.Warning ? colors.bg_orange_500 : "black" }
`;
