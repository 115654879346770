import { HSDrug, HSFacility, HSMedication, HSPatient } from 'server-openapi';
import { OrderResupplyAction, OrderResupplyProps } from './OrderResupplyDialog';
import { OrderNotesProps } from './OrderNotesDialog';
import { OrderRowProps } from './OrderRow';
import { Order } from '../../../../common/Order';
import { AdministrationWarnings, DrugUtils } from '../../../../syncstream/utils/DrugUtils';

export type VwOrder = {
  key: string;
  facility?: HSFacility;
  patient?: HSPatient;
  drug?: HSDrug;
  route: string;
  directions: string;
  administrationWarnings: AdministrationWarnings;
  action: OrderResupplyAction;
};

export function mapDrugToView(
  facilityGroupId: number,
  drug: HSDrug,
  route: string,
  directions: string,
  administrationWarnings: AdministrationWarnings,
  hsPatientId?: number,
  openOrderResupply?: (orderResupply: OrderResupplyProps) => void,
  openOrderNotes?: (orderNotes: OrderNotesProps) => void,
  findRecentOrder?: (hsPatientId: number, hsDrugCode: string) => Order | undefined,
): OrderRowProps {
  return {
    facilityGroupId: facilityGroupId,
    openOrderNotes: openOrderNotes,
    openOrderResupply: openOrderResupply,
    order: hsPatientId && findRecentOrder ? findRecentOrder(hsPatientId, drug.drugCode!) : undefined,
    hsPatientId: hsPatientId,
    vwOrder: {
      key: drug.hsId!.toString(),
      drug: drug,
      route: route,
      directions: directions,
      administrationWarnings: administrationWarnings,
      action: OrderResupplyAction.Create,
    },
  };
}

export function mapMedicationToView(
  findDrug: (hsDrugId: number) => HSDrug,
  findOrder: (hsMedicationId: number) => Order | undefined,
  facilityGroupId: number,
  facility?: HSFacility,
  patient?: HSPatient,
  openOrderResupply?: (orderResupply: OrderResupplyProps) => void,
  openOrderNotes?: (orderNotes: OrderNotesProps) => void,
) {
  return (medication: HSMedication): OrderRowProps => {
    const drug = findDrug(medication.drug!);
    const order = findOrder(medication.hsId!);
    const route = medication.route?.code ?? '';
    const directions = medication.directions ?? '';
    const administrationWarnings = DrugUtils.getAdministrationWarnings(
      drug,
      undefined,
      medication, //this is hsmedication
      medication.selfAdministered ?? false,
    );
    return {
      order: order,
      openOrderNotes: openOrderNotes,
      openOrderResupply: openOrderResupply,
      hsPatientId: patient?.hsId,
      hsMedicationId: medication.hsId!,
      facilityGroupId: facilityGroupId,
      vwOrder: {
        key: medication.hsId!.toString(),
        patient: undefined,
        facility: facility,
        drug: drug,
        route: route,
        directions: directions,
        administrationWarnings: administrationWarnings,
        action: OrderResupplyAction.Create,
      },
    };
  };
}

export function mapOrderToView(
  facilityGroupId: number,
  patientsStore: ReadonlyMap<string, HSPatient>,
  facilitiesStore: ReadonlyMap<string, HSFacility>,
  drugStore: ReadonlyMap<string, HSDrug>,
  includePatient: boolean,
  openOrderResupply?: (orderResupply: OrderResupplyProps) => void,
  openOrderNotes?: (orderNotes: OrderNotesProps) => void,
) {
  return (order: Order): OrderRowProps => {
    const patient = patientsStore.get(order.hsPatientId.toString())!;
    const facility = facilitiesStore.get(order.hsFacilityId.toString());
    const hsMedications = (
      patient?.patientProfiles?.map((pp) => pp.allCurrentMedications ?? ([] as HSMedication[])) ?? []
    ).flat();
    const hsMedication = hsMedications.find((m) => m.hsId === order.hsMedicationId);
    const drug = hsMedication && drugStore.get(hsMedication.drug!.toString());
    const route = hsMedication?.route?.code ?? '';
    const directions = hsMedication?.directions ?? '';
    const administrationWarnings = DrugUtils.getAdministrationWarnings(
      drug,
      undefined,
      hsMedication,
      hsMedication?.selfAdministered ?? false,
    );

    return {
      order: order,
      hsPatientId: patient?.hsId,
      openOrderNotes: openOrderNotes,
      openOrderResupply: openOrderResupply,
      facilityGroupId: facilityGroupId,
      vwOrder: {
        key: order.id,
        patient: includePatient ? patient : undefined,
        facility: facility,
        drug: drug,
        route: route,
        directions: directions,
        administrationWarnings: administrationWarnings,
        action: OrderResupplyAction.Cancel,
      },
    };
  };
}
