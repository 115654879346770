import React from 'react';
import { Paper } from './Paper';
import { capitalize, errorText, Text } from './Text';
import { Intent } from './Theme/Theme';

interface Props {
  loading: boolean;
  error?: string | Error;
}

export function Loading(props: Props) {
  if (props.error) {
    return <Paper intent={Intent.Danger}>{capitalize(errorText(props.error))}</Paper>;
  }
  if (props.loading) {
    return <Text>loading...</Text>;
  }
  return null;
}
