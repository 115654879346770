import React from 'react';
import styled, { css } from 'styled-components';
import { Intent } from './Theme/Theme';

const LabelContainer = styled.label`
  width: fit-content;
`;

const InputContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding: 5px;
  width: 43px;
  height: 43px;
  border-radius: 50%;
  border-width: 2px;
  border-color: white;
  border-style: solid;
  transition: all 150ms;
`;

const HiddenRadioButton = styled.input`
  height: 0;
  width: 0;
  overflow: hidden;
  position: absolute;
`;

const StyledRadioButton = styled.div<IRadioButtonProps>`
  vertical-align: middle;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: ${(props) => props.buttonBackgroundColor ?? 'None'}};

${HiddenRadioButton}:checked + & {
  ${(props) => {
    if (props.intent) {
      return css`
        background: ${props.theme.button[props.intent].bg};
      `;
    }

    return css`
      background: white;
    `;
  }}
}
`;

const LabelText = styled.span`
  margin-left: 8px;
`;

interface IRadioButtonProps
    extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  intent?: Intent;
  buttonBackgroundColor?: string;
}

export const RadioButton = (props: IRadioButtonProps) => (
    <LabelContainer>
      <InputContainer>
        <HiddenRadioButton
            disabled={props.disabled}
            type={props.type}
            name={props.name}
            onChange={props.onChange}
            checked={props.checked}
            data-testid="radio-button"
        />
        <StyledRadioButton intent={props.intent} buttonBackgroundColor={props.buttonBackgroundColor} />
      </InputContainer>
      <LabelText>{props.label}</LabelText>
    </LabelContainer>
);