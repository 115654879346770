import React from 'react';
import { Grid } from '../../../../../kit/Grid';
import { HSAdministeredDrug, MedicationType, HSTestResult } from 'server-openapi';
import { MedicationInformationBox } from '../../medicationInformation/MedicationInformationBox';
import { DateUtils } from '../../../../../core/utils/dateUtils';
import {CategoryHeading } from './ContinuousMedicationList';
import { MedicationListProps } from '../../../components/MedicationListsTabbedRouter/MedicationListsTabbedRouter';
import { useApiUtils } from '../../../../../syncstream/utils/hooks/useApiUtils';
import { MedicationGroup } from '../../../../../syncstream/utils/ResidentDetailsUtils';
import { EnqueuedDrugCreateData } from '../../../../../syncstream/SyncRounds';

interface Props extends MedicationListProps {
  onAdministerDrug: (
    drug: HSAdministeredDrug,
    doseTimestamp: string,
    testResults?: HSTestResult[],
  ) => Promise<EnqueuedDrugCreateData | undefined>;
  groupedPackedMedicationList: MedicationGroup[];
}

export function ShortCoursesMedicationList(props: Props) {
  const apiUtils = useApiUtils();

  const shortCourses = props.groupedPackedMedicationList.filter(
    (packedMedicationList) => packedMedicationList.categories.medicationType === MedicationType.ShortCourse,
  );

  return (
    <Grid cols={1} gap={1}>
      {shortCourses.map((packedMedicationList, index) => (
        <Grid cols={1} gap={0.5} key={index}>
          <CategoryHeading weight={'bold'}>
            {`${
              packedMedicationList.categories.doseDate &&
              DateUtils.dateTo24HourTimeString(packedMedicationList.categories.doseDate)
            } Short Course ${
              packedMedicationList.categories.profileNumber ? `${packedMedicationList.categories.profileNumber} ` : ''
            }(${apiUtils.residentDetails.getTotalDoseAmounts(packedMedicationList.medications) ?? 0})`}
          </CategoryHeading>
          {packedMedicationList.medications.map((packedMedication) => (
            <MedicationInformationBox
              key={packedMedication.hsId}
              packedMedication={packedMedication}
              infoLabel={
                packedMedication.medicationStartDate && packedMedication.medicationEndDate
                  ? `${DateUtils.toDate(packedMedication.medicationStartDate).toDateString()} - ${DateUtils.toDate(
                      packedMedication.medicationEndDate,
                    ).toDateString()}`
                  : undefined
              }
              currentDosedDrug={apiUtils.rounds.getMostRecentDrugInRoundByPackedMedication(
                packedMedication,
                props.patient.hsId!,
                props.currentRound,
              )}
              onAdminister={props.onAdministerDrug}
              previousDosedDrug={ apiUtils.residentDetails.getDosedDrugForPreviousDoseTime(props.patient, packedMedication) }
              scheduledTime={packedMedication.doseTimestamp}
              status={apiUtils.rounds.getPackedMedicationStatus(packedMedication, props.patient.hsId!)}
              {...(props as MedicationListProps)}
            />
          ))}
        </Grid>
      ))}
    </Grid>
  );
}
