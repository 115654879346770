import { UserManager } from "oidc-client";
import { SessionApi } from "server-openapi";
import { appconfig } from "../../appconfig";

type SessionInfo = {
    name?: string;
    timer?: NodeJS.Timer;
}

const session: SessionInfo = {};

// This session is required for authenticating with the server when loading
// patient and drug images. Originally, patient and drug images were obtained
// via an API and were stored in local storage in base64. Now they are loaded
// directly from the server, which uses considerably less memory, and reduces
// the risk of memory leaks.
//
// However, we need an alternative mechanism for authenticating when loading
// images, because it is not possible to inject a bearer token into the request
// generated by the <img> element.

export function setupSessionEvents(userManager: UserManager, sessionApi: SessionApi) {
    userManager.events.addUserLoaded(async () => {
        await createSession(sessionApi);
    });

    userManager.events.addUserUnloaded(() => {
        if (session.name) {
            console.log("User unloaded: session terminated");
            document.cookie = `${session.name}=;Path=/;Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
            session.name = undefined;
        }
        if (session.timer) {
            clearTimeout(session.timer);
        }
    });
}

async function createSession(sessionApi: SessionApi) {
    try {
        if (session.timer) {
            clearTimeout(session.timer);
            session.timer = undefined;
        }
        const dto = await sessionApi.sessionCreate();
        const secure = appconfig.API_URL.startsWith("https:") ? ";Secure" : "";
        session.name = dto.data.name!;

        document.cookie = `${dto.data.name}=${dto.data.value};Path=/;SameSite=Strict${secure}`;
        console.log("User loaded: session started");
    }
    catch {
        console.error("User loaded: failed to start session, will retry in 30s");
        session.timer = setTimeout(() => createSession(sessionApi), 30000);
    }
}
