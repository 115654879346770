import * as React from 'react';

export function IconTestRequired(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style, backgroundColor: 'white', borderRadius: '999999px', marginRight: '4px' }}
      {...props}
    >
      <path d="M2.5 13L5 10l2.5 2L12 4.5l3.5 2" stroke="#EA2A2A" strokeWidth={2} />
    </svg>
  );
}
