import React from 'react';
import { HSDoseRound, HSPatient } from 'server-openapi';
import styled, { css } from 'styled-components';
import { MemorizedPatientClock } from '../../../../components/PatientClock/PatientClock';
import { Grid } from '../../../../kit/Grid';
import OffsiteIcon from '../../../../kit/Icons/ResidentIcons/Offsite';
import SelfAdministeredIcon from '../../../../kit/Icons/ResidentIcons/SelfAdministered';
import { Layout } from '../../../../kit/Layout';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import { ChartMedChangesLabel } from '../../components/ChartCard';
import {RoundScheduleItem} from "../../../../syncstream/utils/RoundUtils";
import Badge from "../../../../kit/Badge";

const Container = styled.div`
  padding: 0em 1em;
`;

const ResidentContainer = styled.div<{overlayed?: boolean}>`
  color: ${(p) => p.theme.backgrounds.default.fg};
  background-color: ${(props) => props.overlayed ? props.theme.backgrounds.darker.bg :  props.theme.backgrounds.default};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(p) => p.theme.backgrounds.default.fg};
    margin-bottom: 2em;
  }
`;

interface IProps {
  residentGridArray: Array<{ patient: HSPatient; facilityWing: string; hasMedChanges: boolean; scheduleItems: RoundScheduleItem[]; }>;
  activeRound?: HSDoseRound;
  overlay?: boolean;
}

export function DashboardResidentsGrid(props: IProps) {
  const patientUtils = useApiUtils().patients;

  return (
    <Container>
      <Grid colsTemplate="repeat(auto-fill, minmax(400px, 1fr))" gap={1}>
        {props.residentGridArray.map((r) => (
          <ResidentContainer key={r.patient.hsId!}>
            {(props.overlay || r.patient.selfAdministered || r.patient.suspended) && <Overlay />}
            <Layout horizontal padding={'1.5em'} gap={0.5} style={{ width: '100%'}}>
              {r.hasMedChanges && <ChartMedChangesLabel />}
              {r.patient.selfAdministered && <Badge type={'info'} text={'SELF-ADMINISTERED'} />}
              {r.patient.suspended && <Badge type={'info'} text={'OFF-SITE'} />}
            </Layout>
            <ClockContainer>
              <MemorizedPatientClock patientId={r.patient.hsId!} activeRound={props.activeRound} scheduleItems={r.scheduleItems}/>
            </ClockContainer>
            <ResidentNameLocation hasSimilarNameAlert={r.patient.similarNameAlert}>
              <span style={{ fontWeight: 'bold' }}>{patientUtils.getDisplayPatientName(r.patient)}</span>
              <span style={{ fontWeight: 100 }}>
                &nbsp;Rm {r.patient.roomNumber}, {r.facilityWing ?? ''}
              </span>
            </ResidentNameLocation>
          </ResidentContainer>
        ))}
      </Grid>
    </Container>
  );
}

const Overlay = styled.div`
  background-color: rgb(0, 0, 0, 0.3);
  z-index: 10;
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
  border-radius: 2px;
`;

const ResidentNameLocation = styled.p<{ hasSimilarNameAlert?: boolean }>`
  display: flex;
  flex-direction: column;
  ${(p) => {
    if (p.hasSimilarNameAlert) {
      return css`
        border-bottom-width: 3px;
        border-bottom-style: solid;
        border-bottom-color: lightgray;
        padding-bottom: 3px;
      `;
    }
  }}
`;

const ClockContainer = styled.div`
  margin: calc(100% / 10) 0 calc(100% / 5) 0;
`;
