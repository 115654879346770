import React from 'react';
import styled from "styled-components";

type Props = {
  title: string;
  hasLine?: boolean;
};

const Tab: React.FC<Props> = ({ children, hasLine }) => {
    return (
        <div>
            {hasLine && <TabsLine />}
            {children}
        </div>
    );
};

const TabsLine = styled.hr`
  border: 1px solid #4BE1C5;
  margin: 0;
`

export default Tab;
