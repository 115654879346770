import styled from 'styled-components';
import { format } from 'date-fns';
import { DeepMap, FieldError } from 'react-hook-form';

interface Props {
  size?: 'small' | 'medium' | 'large';
  weight?: 'normal' | 'bold' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900';
}

export const Text = styled.span<Props>`
  font-size: ${(props) => {
    switch (props.size) {
      case 'large':
        return props.theme.typography.h1.fontSize;
      case 'medium':
        return props.theme.typography.h2.fontSize;
      case 'small':
        return '0.85em';
      default:
        return props.theme.typography.defaults.fontSize;
    }
  }};
  ${(props) => props.weight && `font-weight: ${props.weight};`}
`;

export function capitalize(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function errorText(text: string | Error | FieldError | DeepMap<unknown, FieldError>): string {
  if (typeof text === 'string') {
    return text;
  }

  if (text instanceof SyntaxError) {
    return 'A browser error occurred';
  }

  if (text instanceof Error && text.message) {
    return text.message ?? 'An unknown error occurred';
  }

  if ('message' in text && typeof text.message === 'string' && text.message) {
    return text.message;
  }

  // React Hook Form FieldError
  if ('type' in text) {
    if (text.type === 'required') {
      return 'This field is required';
    }
    return 'Validation error';
  }

  return String(text) ?? 'Unknown Error';
}

export enum DateFmt {
  SimpleDate = 'dd MMM yyyy',
}

export function dateText(date: Date, fmt: DateFmt) {
  return format(date, fmt);
}

export function stripPrefix(text: string, prefix: string) {
  if (text.startsWith(prefix)) {
    return text.slice(prefix.length);
  }
  return text;
}
