import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { Intent } from './Theme/Theme';

interface Props {
  elevation?: Elevation;
  secondary?: boolean;
  intent?: Intent;
  padding?: number;
}

export type Elevation = 0 | 1 | 2;

export const Paper = styled.div<Props>`
  position: relative;
  padding: ${(props) => props.padding ?? 1}rem;
  border-radius: 0.25rem;
  overflow: hidden;

  ${(props) => elevationMixin(props.elevation ?? 1)}

  ${(props) => {
    if (props.secondary) {
      return css`
        color: ${props.theme.paper.secondary.fg};
        background: ${props.theme.paper.secondary.bg};
      `;
    }
    if (props.intent) {
      return css`
        color: ${props.theme.paper[props.intent].fg};
        background: ${props.theme.paper[props.intent].bg};
      `;
    }
    // the default style is currently "primary"
    return css`
      color: ${props.theme.paper.primary.fg};
      background: ${props.theme.paper.primary.bg};
    `;
  }}
`;

const shadows: Record<Elevation, FlattenSimpleInterpolation> = {
  0: css`
    box-shadow: none;
  `,
  1: css`
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  `,
  2: css`
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  `,
};

export function elevationMixin(elevation: Elevation) {
  return shadows[elevation];
}
