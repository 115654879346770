import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { HSPatient } from "server-openapi";
import { RequireInternet } from "../../../../components/RequireInternet/RequireInternet";
import { apis } from "../../../../core/mrs/apis";
import { Loading } from "../../../../kit/Loading";
import styled from "styled-components";
import { useApiUtils } from "../../../../syncstream/utils/hooks/useApiUtils";
import { PatientCompactInfo } from "../../../../pages/ResidentDetails/components/PatientCompactInfo";

interface IMyHealthRecordProps {
    patient: HSPatient;
    facilityGroupId: number;
}

export function MyHealthRecord(props: IMyHealthRecordProps) {
    return (
        <RequireInternet>
            <MyHealthRecordContent {...props} />
        </RequireInternet>
    );
}

function useMhrIframeUrl(facilityGroupId: number, patientId: number) {
    const [url, setUrl] = useState<string | null | undefined>(undefined);
    const [error, setError] = useState<Error | undefined>(undefined);
    const { patients } = apis;
    useEffect(() => {
        patients.patientsMhrPage(facilityGroupId, patientId, {}).then(response => {
            setUrl(response.data.location);
        }).catch(err => {
            setError(err);
        });
    }, [patients, setUrl, setError, facilityGroupId, patientId]);
    return useMemo(() => ({
        url,
        error
    }), [url, error]);
}

function MyHealthRecordContent(props: IMyHealthRecordProps) {
    const { url, error } = useMhrIframeUrl(props.facilityGroupId, props.patient.hsId!);
    const patientUtils = useApiUtils().patients;
    const facility = patientUtils.findPatientFacility(props.patient);

    if (!url || error) {
        return <Loading loading={!url} error={error} />;
    }

    return (
        <>
            <PatientCompactInfo patient={props.patient} facility={facility!} extraTitle={" \u2014 My Health Record"}/>
            <MhrIFrame src={url} />
        </>
    );
}

interface IframeProps {
    src: string;
    title?: string;
}

const MhrIFrame = React.forwardRef<HTMLIFrameElement, IframeProps>((props, ref) => {
    const internalRef = useRef<HTMLIFrameElement | null>(null);
    useImperativeHandle(ref, () => internalRef.current!);
    const { src, title } = props;
    useEffect(() => {
        const iframe = internalRef.current!;
        const handler = () => setHeight(iframe);
        window.addEventListener("resize", handler);
        handler();
        return () => {
            window.removeEventListener("resize", handler);
        };
    }, []);
    return (
        <StyledIFrame ref={internalRef} src={src} title={title} />
    );
});

function setHeight(iframe: HTMLIFrameElement) {
    const iframeRect = iframe.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    // minimum height of the iframe is so that it takes up all available window height
    const minHeight = windowHeight - iframeRect.top - 1;
    const maxHeight = windowHeight * 10;

    const extra = 0;
    const windowOrDocument: any = iframe.contentWindow || iframe.contentDocument;
    const document: Document | undefined = windowOrDocument.document ?? windowOrDocument;
    if (document) {
        //console.log("iframe document scroll height = %dpx", document.body.scrollHeight);
        // Don't allow to exceed the max height in case this calculation loops forever
        const newHeight = Math.min(Math.max(document.body.scrollHeight + extra, minHeight), maxHeight);
        const height = iframeRect.height;
        if (Math.abs(height - newHeight) >= 1) {
            //console.log("setting iframe.style.height = %dpx", newHeight);
            iframe.style.height = `${newHeight}px`;
        }
    }
}

const StyledIFrame = styled.iframe`
  border: none;
  margin-left: -${props => props.theme.dialog.primary.padding};
  margin-right: -${props => props.theme.dialog.primary.padding};
  margin-bottom: -${props => props.theme.dialog.primary.padding};
`;
