import * as React from 'react';

export function IconNote(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <ellipse cx="21" cy="21" rx="21" ry="21" fill="#4E2974" />
      <path
        transform="translate(14, 10.5)"
        d="M4 14H12V16H4V14ZM4 10H12V12H4V10ZM10 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0ZM14 18H2V2H9V7H14V18Z"
        fill="white"
      />
    </svg>
  );
}
