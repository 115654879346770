import itiriri from 'itiriri';
import {CreatePatchObservationsReq, HSFacility, HSPatchObservation, HSPatient} from 'server-openapi';
import { PersistentQueue } from '../core/queue/PersistentQueue';
import {Entry, IStorage} from '../core/storage/Contract';
import { SyncStreamAPI } from './api';
import {IFacilityGroupSyncService } from './SyncCenter';
import { v4 as uuidv4 } from 'uuid';
import { SyncUtils } from './utils/SyncUtils';
import { Logger } from '../core/logger/logger';
import { DateUtils } from '../core/utils/dateUtils';
import { startOfDay, subDays } from 'date-fns';
import {MemoryCache} from "../core/storage/MemoryCache";

export type PatchObservationOp = CreatePatchObservationOp;

export interface CreatePatchObservationOp {
  type: 'patch-observation-create';
  clinicalSystemId?: string;
  request: CreatePatchObservationsReq;
}

const logger = new Logger('SyncPatchObservations');

export class SyncPatchObservations implements IFacilityGroupSyncService {
  get name(): string {
    return 'SyncPatchObservations';
  }
  private async isStale(o: HSPatchObservation): Promise<boolean> {
    return (!!(o.lastUpdated && o.lastUpdated < DateUtils.fromDate(subDays(startOfDay(new Date()), 7))));
  }

  private async isFacilityGroup(p: HSPatchObservation, facilityGroupId: string): Promise<boolean> {
    const facilityGroup = await this.facilitiesStore.get(p.facilityId!.toString());
    return facilityGroup?.facilityGroupId?.toString() == facilityGroupId;
  }

  constructor(
    private api: SyncStreamAPI,
    private storage: MemoryCache<HSPatchObservation>,
    private facilitiesStore: IStorage<HSFacility>,
    private latestChangeNumbers: IStorage<number | undefined>,
    private queue: PersistentQueue<PatchObservationOp>,
  ) {}
  async load(facilityGroupId: string): Promise<void> {
    await this.storage.reset(async (p) => {
          return (await this.isFacilityGroup(p, facilityGroupId));
    });
  }

  async syncDown(facilityGroupId?: string) {
    const facilitiesToSync = await SyncUtils.getFacilitiesForGroup(facilityGroupId, this.facilitiesStore);
    const changeNumber = await SyncUtils.getChangeNumberForFacilities(
      facilitiesToSync.map((x) => x.hsId!),
      this.latestChangeNumbers,
    );

    const patchObservationData = await this.syncFacilityDown(
      facilityGroupId!,
      changeNumber,
    );
    await this.storage.setMany(
      patchObservationData.map((patchObservation) => {
          const key = this.storage.get_key!(patchObservation);

          return {
            key: this.storage.get_key!(patchObservation),
            value: {
              ...patchObservation,
              clinicalSystemId: key,
            },
          };
        }),
    );

    await SyncUtils.setChangeNumberForFacilities(
        facilitiesToSync.map((x) => x.hsId!),
        this.latestChangeNumbers,
        patchObservationData
    );
  }

  async syncFacilityDown(facilityGroupId: string, changeNumber: number): Promise<HSPatchObservation[]> {
    const pageSize = 200;
    //changeNumber = isFinite(changeNumber) ? changeNumber : 0;
    const patchObservations = await this.api.patchObservations.patchObservationsListPatchObservations(
      changeNumber,
      parseInt(facilityGroupId),
      pageSize
    );

    if (patchObservations.data.length === pageSize) {
      return [
        ...(await this.syncFacilityDown(facilityGroupId, SyncUtils.getLatestChangeNumber(patchObservations.data)!)),
        ...patchObservations.data,
      ];
    }

    return patchObservations.data;
  }

  enqueue = {
    createPatchObservation: async (req: CreatePatchObservationOp) => {
      const patchObservationClinicalSystemId = uuidv4();
      req.request.patchObservation.clinicalSystemId = patchObservationClinicalSystemId;

      const patchObservation = {
        key: patchObservationClinicalSystemId,
        value: req.request.patchObservation,
      };

      await this.storage.set(patchObservation.key, patchObservation.value);
      await this.queue.unshift({ ...req, clinicalSystemId: patchObservationClinicalSystemId });

      return patchObservation;
    },
  };

  async syncUp() {
    const entries = new Map<string, HSPatchObservation>();
    for await (const delivery of this.queue.iterate()) {
      try {
        // eslint-disable-next-line sonarjs/no-small-switch
        switch (delivery.value.type) {
          case 'patch-observation-create':
            const newPatchObservation = await this.api.patchObservations.patchObservationsCreatePatchObservation(
              delivery.value.request,
            );

            entries.set(delivery.value.clinicalSystemId!, {
              ...newPatchObservation.data,
              changeNumber: undefined,
              clinicalSystemId: delivery.value.clinicalSystemId!,
            });

            await delivery.complete();
        }
      } catch (error) {
        logger.error('Sync patch observations failed', error);
        await delivery.failed();
      }
    }
    await this.storage.setMany(
      itiriri(entries.entries())
        .map((entry) => ({ key: entry[0], value: entry[1] }))
        .toArray(),
    );
  }

  async clear() {
    await this.storage.clear();
    await this.latestChangeNumbers.clear();
    await this.queue.clear();
  }

  async hasQueuedData() {
    return (await this.queue.length()) > 0;
  }
  isAllowed(canUserAccessMedication: boolean): boolean {
    // Only if you can view a round.
    return canUserAccessMedication;
  }
  async archive(deletedPatientIds: string[]): Promise<void> {
    const keysToDelete: string[] = [];
    for (let [k, v] of (await this.storage.all()).entries()) {
      if (await this.isStale(v) || deletedPatientIds.some(x => x === v.patientId?.toString() ?? "-1")) {
        keysToDelete.push(k)
      }
    }
    await this.storage.deleteMany(keysToDelete);
  }
  setEncryptionVersion(version: number): void {
    this.storage.compressOnSave = (version > 1);
  }
  async rewrite(): Promise<void> {
    const entries: Entry<HSPatchObservation>[] = [...(await this.storage.all())].map((keyValueArray) => {
      return {
        key: keyValueArray[0],
        value: keyValueArray[1]
      };
    });

    return await this.storage.setMany(entries);
  }
}
