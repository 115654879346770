import React from 'react';
import { HSFacilityGroup } from 'server-openapi';
import styled from 'styled-components';
import { breakpoints } from '../../kit/Theme/Theme';
import {RestrictedNavigationBarMenu} from "./RestrictedNavigationBarMenu";

const NavigationGrid = styled.div<{ inverted?: boolean }>`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr auto auto;
  ${breakpoints.mobileDown} {
    grid-template-columns: 1fr;
  }
  grid-template-rows: 1fr;
  grid-auto-flow: row;
  align-items: center;
  padding: 1em 2em;
  background-color: ${(p) => (p.inverted ? p.theme.appbar.fg : p.theme.appbar.bg)};
  min-height: 84px;
`;

const NavigationColumn = styled.div<{ inverted?: boolean; right?: boolean }>`
  display: flex;
  justify-content: ${(p) => (p.right ? 'flex-end' : 'flex-start')};

  p,
  h1,
  h2,
  h3 {
    color: ${(p) => (p.inverted ? p.theme.appbar.bg : p.theme.appbar.fg)};
  }
  a {
    text-decoration: none;
  }
`;

export function RestrictedNavigationBar(props: {
  left: () => React.ReactNode;
  right?: () => React.ReactNode;
  inverted?: boolean;
  hideMenu?: boolean;
  facilityGroup?: HSFacilityGroup;
}) {
  return (
    <NavigationGrid inverted={props.inverted}>
      <NavigationColumn inverted={props.inverted}>{props.left()}</NavigationColumn>
      <NavigationColumn inverted={props.inverted} right>
        {props.right ? props.right() : null}
      </NavigationColumn>
      {!props.hideMenu && props.facilityGroup && <RestrictedNavigationBarMenu facilityGroup={props.facilityGroup} />}
    </NavigationGrid>
  );
}
