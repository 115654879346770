import React from 'react';
import styled from 'styled-components';
import { Button } from '../../kit/Button';
import { Dialog } from '../../kit/Dialog';
import { Layout } from '../../kit/Layout';
import { Text } from '../../kit/Text';
import {logout} from "./Client";

interface IProps {
  isOpen: boolean;
  setClosed: () => void;
}
export function LogoutWarningDialog(props: IProps) {
  return (
    <Dialog secondary lazy open={props.isOpen} onRequestClose={() => props.setClosed()} size={'sm'}>
      <Layout padding={2} gap={2}>
        <Text weight={'bold'} size={'medium'}>
          Pending Changes
        </Text>
        <Text>
          There are still pending changes to be saved to the server, logging out now may result in losing them. Please
          wait until the application completes the syncing.
        </Text>
        <Text weight={'bold'}>If device is offline, please connect it to the internet to complete sync process</Text>
        <ButtonContainer>
          <Button style={{ marginRight: '2em' }} onClick={() => {
            props.setClosed();
            logout()
          }}>
            Logout
          </Button>
          <Button style={{ marginRight: '0' }} onClick={() => props.setClosed()}>
            Back
          </Button>
        </ButtonContainer>
      </Layout>
    </Dialog>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
