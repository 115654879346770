import { HSCorporate } from 'server-openapi';
import { Entry, IStorage } from '../core/storage/Contract';
import { SyncStreamAPI } from './api';
import { ISyncService } from './SyncCenter';
import { SyncUtils } from './utils/SyncUtils';
import {MemoryCache} from "../core/storage/MemoryCache";

export class SyncCorporates implements ISyncService {
  get name(): string {
    return 'sync corporates';
  }

  constructor(private api: SyncStreamAPI, private storage: IStorage<HSCorporate>) {}

  async syncDown() {
    await this.storage.clear();
    await this.storage.setMany(await this.syncDownWithChangeNumber(0));
  }

  async syncDownWithChangeNumber(changeNumber: number): Promise<Entry<HSCorporate>[]> {
    // TODO: storage locking so that we can be sure the UI
    // didn't accidentally change a resource in between the API
    // giving us fresh data and updating the storage backend.
    const corporates = await this.api.corporates.corporateListCorporates();

    const changes = corporates.data.map((corporate: HSCorporate) => ({
      key: this.storage.get_key!(corporate),
      value: corporate,
    }));
    return changes;
  }

  async syncUp() {
    // Do nothing
  }

  async clear() {
    await this.storage.clear();
  }

  async hasQueuedData() {
    return false;
  }
  isAllowed(canUserAccessMedication: boolean): boolean {
    // Only if you can view a round.
    return true;
  }
}
