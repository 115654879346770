import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { HSDrug } from 'server-openapi';
import { usermanager } from '../../../../core/authn/Client';
import { Logger } from '../../../../core/logger/logger';
import { Button } from '../../../../kit/Button';
import { Dialog } from '../../../../kit/Dialog';
import { Form } from '../../../../kit/Forms/Form';
import { FormGroup } from '../../../../kit/Forms/FormGroup';
import { RadioGroup } from '../../../../kit/Forms/RadioGroup';
import { SelectOption } from '../../../../kit/Forms/Select';
import { TextArea } from '../../../../kit/Forms/TextArea';
import { Layout } from '../../../../kit/Layout';
import { Intent } from '../../../../kit/Theme/Theme';
import { AppServices, useSyncCenter } from '../../../../syncstream/SyncCenterProvider';
import { AdministrationWarnings } from '../../../../syncstream/utils/DrugUtils';
import { MedicationInfoSection } from '../../../ResidentDetails/components/medicationInformation/MedicationInfoSection';
import { toasts } from '../../../../kit/Toasts/Toaster';
import { User } from 'oidc-client';
import {useGroupPermissions} from "../../../../core/authz/PermissionsProvider";

export enum OrderResupplyAction {
  Create,
  Cancel,
}

export interface OrderResupplyProps {
  action: OrderResupplyAction;
  orderId?: string;
  hsPatientId?: number;
  hsMedicationId?: number;
  drug?: HSDrug;
  route?: string;
  directions?: string;
  administrationWarnings: AdministrationWarnings;
  facilityGroupId: number;
}

export interface OrderResupplyDialogProps extends OrderResupplyProps {
  isOpen: boolean;
  setClosed: () => void;
}

interface FormFields {
  orderResupplyOption: boolean;
  notes?: string;
}

const orderResupplyOptions: SelectOption<boolean>[] = [
  {
    label: 'Next daily delivery',
    value: true,
  },
  {
    label: 'As per script',
    value: false,
  },
];

export function OrderResupplyDialog(props: OrderResupplyDialogProps) {
  return (
    <OrderDialogLayout closeButtonColor={'#fff'} open={props.isOpen} onRequestClose={() => props.setClosed()} size="sm">
      <OrderResupplyDialogDetail {...props} />
    </OrderDialogLayout>
  );
}

export function OrderResupplyDialogDetail(props: OrderResupplyDialogProps) {
  const logger = new Logger('OrderResupplyDialog');
  const services = useSyncCenter();
  const form = useForm<FormFields>();
  const [nextDailyDelivery, setNextDailyDelivery] = useState<boolean>(true);
  const actionText = getActionText(props.action);
  const groupPermissions = useGroupPermissions();
  const canRecordNote = groupPermissions.canRecordNote;
  async function onSubmit(data: FormFields) {
    if (data.notes && !canRecordNote) {
      toasts.error('You do not have permission to record notes');
      return;
    }
    logger.info(
      `Submitting order '${props.action}': ${props.hsPatientId}/${props.hsMedicationId}/${props.orderId}; ${data.notes}`,
    );
    const user = await usermanager.getUser();
    await processOrder(
      services,
      user,
      props.hsPatientId,
      props.hsMedicationId,
      props.action,
      props.orderId,
      data,
      props.drug,
    );
    props.setClosed();
  }

  return (
    <Layout gap={1}>
      <h2>{actionText.Header}</h2>
      <InformationBox>
        <MedicationInfoSection {...props} />
      </InformationBox>
      <Form form={form} onSubmit={onSubmit}>
        <Layout gap={2}>
          {props.action === OrderResupplyAction.Create && (
            <RadioGroup
              name="orderResupplyOption"
              cols={2}
              options={orderResupplyOptions}
              value={nextDailyDelivery}
              intent={Intent.Secondary}
              onChange={(_, value) => setNextDailyDelivery(value)}
            />
          )}
          <FormGroup fullWidth>
            <TextArea
              name="notes"
              placeholder="Add note"
              fullWidth
              rules={{
                validate: (notes: string) => {
                  if (props.action === OrderResupplyAction.Cancel)
                    return Boolean(notes) || 'Notes Required for cancelling order resupply';

                  return (
                    Boolean(notes) || !form.watch('orderResupplyOption') || 'Notes required for a next daily delivery'
                  );
                },
              }}
            />
          </FormGroup>
          <OrderButton type="submit" fullWidth intent={Intent.Secondary}>
            {actionText.Action}
          </OrderButton>
        </Layout>
      </Form>
    </Layout>
  );
}

function getActionText(action: OrderResupplyAction) {
  switch (action) {
    case OrderResupplyAction.Create:
      return { Header: 'Order Resupply', Action: 'RESUPPLY' };
    case OrderResupplyAction.Cancel:
      return { Header: 'Cancel Resupply', Action: 'CONFIRM' };
    default:
      return { Header: 'Order', Action: 'CLOSE' };
  }
}

async function processOrder(
  services: AppServices,
  user: User | null,
  hsPatientId: number | undefined,
  hsMedicationId: number | undefined,
  action: OrderResupplyAction,
  orderId: string | undefined,
  data: FormFields,
  drug: HSDrug | undefined,
) {
  if (user?.profile?.sub) {
    const userSubjectId = user?.profile?.sub;
    const userName = user?.profile?.name;
    if (hsPatientId && hsMedicationId && action === OrderResupplyAction.Create) {
      await services.orders.service.createMedicationOrder(
        data.orderResupplyOption,
        userSubjectId,
        hsPatientId!,
        hsMedicationId!,
        drug?.drugCode ?? undefined,
        data.notes,
        userName,
      );
    } else if (orderId && action === OrderResupplyAction.Cancel) {
      await services.orders.service.cancelOrder(orderId!, user?.profile?.sub, data.notes);
    }
  }
}

const OrderButton = styled(Button)`
  width: 100px;
`;

const InformationBox = styled(Layout)`
  background: white;
  color: black;
  display: flex;
  align-items: center;
  padding: 0.875rem;
  width: 100%;
  min-height: 112px;
`;

const OrderDialogLayout = styled(Dialog)`
  background: ${(props) => props.theme.backgrounds.light.bg};
`;
