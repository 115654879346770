/* eslint-disable max-lines */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid } from '../../kit/Grid';
import { MdMenu, MdClose } from 'react-icons/md';
import { breakpoints, colors } from '../../kit/Theme/Theme';
import { Link } from 'react-router-dom';
import { logout } from '../../core/authn/Client';
import { HSFacilityGroup } from 'server-openapi';
import { useCurrentUser } from '../../core/authn/UserProvider';
import { Text } from '../../kit/Text';
import { toasts } from '../../kit/Toasts/Toaster';
import { useApiUtils } from '../../syncstream/utils/hooks/useApiUtils';
import { Dialog } from '../../kit/Dialog';
import { PinUpdateForm } from '../PinComponents/PinUpdateForm';
import { Layout } from '../../kit/Layout';
import Loader from 'react-loader-spinner';
import SvgTick from '../../kit/Icons/Tick';
import { LogoutWarningDialog } from '../../core/authn/LogoutWarningDialog';
import { useQueueDataChecker } from '../../syncstream/utils/hooks/useQueueDataChecker';
import SvgWarning from '../../kit/Icons/Warning';
import { useHealthCheck } from '../../core/healthcheck/HealthCheckProvider';
import { appconfig } from '../../appconfig';

const MenuContainer = styled(Grid)`
  padding: 0.5em 1em;
  border: solid 2px ${(props) => props.theme.button.secondary.bg};
  cursor: pointer;
  background: ${(props) => props.theme.backgrounds.default.fg};
  font-weight: bold;
  color: ${(p) => p.theme.typography.h2.color};
`;

const CloseContainer = styled.div`
  padding: 0.3em;
  border: solid 2px ${(props) => props.theme.typography.h1.color};
  color: ${(props) => props.theme.typography.h1.color};
  font-size: 1.5em;
  cursor: pointer;
  justify-content: center;
  display: inline-flex;
`;

const SlideOutMenu = styled.div<{ isVisible: boolean }>`
  width: 375px;
  transform: ${(p) => (p.isVisible ? 'translateX(0px)' : `translateX(375px)`)};
  ${breakpoints.mobileDown} {
    width: 100%;
    transform: ${(p) => (p.isVisible ? 'translateX(0px)' : `translateX(100%)`)};
  }
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  background: ${(props) => props.theme.button.secondary.bg};
  transition: 0.3s all ease-in-out;
  padding: 2em;
  overflow-y: scroll;
  box-shadow: -5px 0 18px #0000003b;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SyncCompleteCircle = styled.div`
  font-size: 1.5em;
  padding: 0.4em;
  border: solid 2px ${(props) => props.theme.typography.h1.color};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999999rem;
`;

const VersionDisplay = styled.div`
  width: 100%;
  text-align: right;
  font-weight: 100;
  font-style: italic;
`;

const LogoutText = styled(Text)`
  cursor: pointer;
  color: ${(props) => props.theme.backgrounds.default.bg};
  &:hover {
    color: ${() => colors.bg_purple_700};
  }
`;

interface Props {
  facilityGroup: HSFacilityGroup;
}

// eslint-disable-next-line max-lines-per-function
export function RestrictedNavigationBarMenu(props: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const user = useCurrentUser();
  const userUtils = useApiUtils().users;
  const [pinDialogOpen, setPinDialogOpen] = useState(false);

  const hasQueuedData = useQueueDataChecker();
  const isServerHealthy = useHealthCheck().isHealthy;

  return (
    <div>
      <MenuContainer gap={0} colsTemplate="0.8fr 1fr" onClick={() => setIsVisible(!isVisible)}>
        <MdMenu />
        Menu
      </MenuContainer>
      <SlideOutMenu isVisible={isVisible}>
        <Grid gap={2} cols={1}>
          <Layout horizontal align={'center'} justify={'space-between'}>
            <OnlineStatus isServerHealthy={isServerHealthy} hasQueuedData={hasQueuedData ?? false} />
            <CloseContainer onClick={() => setIsVisible(!isVisible)}>
              <MdClose />
            </CloseContainer>
          </Layout>
          <Grid gap={0.75} cols={1}>
            <h3>{props.facilityGroup.name}</h3>

          </Grid>
          <Grid gap={0.75} cols={1}>
            {user.profile.sub && (
              <h3>LOGGED IN AS {userUtils.getUserFullNameAndRoleFromSubjectId(user.profile.sub)}</h3>
            )}
            <>
              <Link to="#" onClick={() => setPinDialogOpen(true)}>
                Set Your PIN
              </Link>
              <Dialog lazy secondary size="full" open={pinDialogOpen} onRequestClose={() => setPinDialogOpen(false)}>
                <PinUpdateForm onSubmit={() => setPinDialogOpen(false)} />
              </Dialog>
            </>
            <Link
              onClick={() => !isServerHealthy && toasts.error("Can't switch facilities while offline")}
              to={isServerHealthy ? '/facility-group/switch' : '#'}
            title={`Currently ${props.facilityGroup.name}`}>
              Switch Facility
            </Link>
            <Link to={`/admin/facility-group/${props.facilityGroup.hsId}/settings/users`}>User Management</Link>
            <Link to={`/facility-group/${props.facilityGroup.hsId}/help`}>Help</Link>
            <Link to="/support">Support</Link>
            <LogoutText
              onClick={async () => {
                if (hasQueuedData) {
                  setOpen(true);
                } else {
                  await logout();
                }
              }}
            >
              Log out
            </LogoutText>
          </Grid>
        </Grid>
        <VersionDisplay>{appconfig.APP_VERSION}</VersionDisplay>
      </SlideOutMenu>
      <LogoutWarningDialog isOpen={open} setClosed={() => setOpen(false)} />
    </div>
  );
}

interface IOnlineStatus {
  isServerHealthy: boolean;
  hasQueuedData: boolean;
}

function OnlineStatus(props: IOnlineStatus) {
  return (
    <Layout horizontal gap={0.5} justify={'center'} align={'center'}>
      {!props.isServerHealthy ? (
        <SyncCompleteCircle>
          <SvgWarning />
        </SyncCompleteCircle>
      ) : props.hasQueuedData ? (
        <Loader type="Oval" secondaryColor={colors.bg_purple_100} color={colors.bg_purple_800} height={40} width={40} />
      ) : (
        <SyncCompleteCircle>
          <SvgTick />
        </SyncCompleteCircle>
      )}
      <Text style={{ color: colors.bg_purple_800 }} weight={'bold'}>
        {!props.isServerHealthy ? 'Offline' : props.hasQueuedData ? `Syncing...` : 'Synced'}
      </Text>
    </Layout>
  );
}

