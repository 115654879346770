import { format } from 'date-fns';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { RequirePermission } from '../../../../components/RequirePermission/RequirePermission';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { Layout } from '../../../../kit/Layout';
import { Text } from '../../../../kit/Text';
import { ICommentsOrOutcomesCompact } from '../../../../syncstream/utils/DrugUtils';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import {useGroupPermissions} from "../../../../core/authz/PermissionsProvider";

interface IProps {
  comment: ICommentsOrOutcomesCompact;
  facilityGroupId: number;
}

function CommentsCompactContent(props: IProps) {
  const userUtils = useApiUtils().users;
  const theme = useTheme();
  const lastUpdatedAt = !!props.comment.lastUpdatedAt ? DateUtils.toDate(props.comment.lastUpdatedAt) : undefined;
  const dateText = lastUpdatedAt ? `${format(lastUpdatedAt, 'd MMM y')} at ${format(lastUpdatedAt, 'HH:mm')}` : '';
  return (
    <>
      <Text style={{ color: theme.typography.h1.color }} size={'small'} weight={'bold'}>
        {`${dateText} by ${userUtils.getUserFullNameAndRoleFromSubjectId(props.comment.lastUpdatedSubjectId)}`}
      </Text>
      <Text size={'small'} weight={'200'}>
        {props.comment.commentText}
      </Text>
    </>
  );
}

export function CommentsCompact(props: IProps) {
    const groupPermissions = useGroupPermissions();
    return (
    <Layout gap={0.5}>
      <NotesContainer gap={0.5} padding={'0.875rem'}>
        <RequirePermission hasPermission={groupPermissions.canViewResidentNotes}>
          <CommentsCompactContent {...props} />
        </RequirePermission>
      </NotesContainer>
    </Layout>
  );
}

const NotesContainer = styled(Layout)`
  border-width: 1px;
  border-style: groove;
`;
