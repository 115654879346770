import itiriri from 'itiriri';

const cache = new Map<string, unknown>();

export function useCached<T>(cachekey: string, fetcher: () => T, subKey?: string): T {
  const getItem = () => {
    const keyStart = `${cachekey} ----- endKeyStart`;
    const jointKey = `${keyStart} ${subKey}`;
    if (!cache.has(jointKey)) {
      cache.set(jointKey, fetcher());
      if (subKey) {
        const oldKey = itiriri(cache.keys()).find((key) => key.startsWith(keyStart) && key !== jointKey);
        if (oldKey) {
          cache.delete(oldKey);
        }
      }
    }

    // return the cached value for the given key
    return cache.get(jointKey) as T;
  };

  return getItem();
}
