import itiriri from 'itiriri';
import { parseInt } from 'lodash-es';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { DateUtils } from '../../../core/utils/dateUtils';
import { IconBack } from '../../../kit/Icons/Back';
import { Layout } from '../../../kit/Layout';
import { Text } from '../../../kit/Text';
import { useSyncCenter } from '../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../core/storage/hooks/UseStore';
import { DashboardNavbar } from '../../Dashboard/DashboardPage/components/DashboardNavbar';
import { OrderRow } from './Components/OrderRow';
import { mapOrderToView } from './Components/OrderViewModel';
import { OrderResupplyDialog, OrderResupplyProps } from './Components/OrderResupplyDialog';
import { OrderNotesDialog, OrderNotesProps } from './Components/OrderNotesDialog';
import {RequirePermission} from "../../../components/RequirePermission/RequirePermission";
import {useGroupPermissions} from "../../../core/authz/PermissionsProvider";

interface IParams {
  facilityGroupId: string;
}

export function FacilityOrdersPage() {
  // Get the currently selected facility from the URL parameters.
  const { facilityGroupId } = useParams<IParams>();

  const services = useSyncCenter();

  const facilityGroupsStore = useStore(services.facilityGroups.store).store;
  const facilitiesStore = useStore(services.facilities.store).store;
  const patientsStore = useStore(services.patients.store).store;
  const ordersStore = useStore(services.orders.store).store;
  const drugStore = useStore(services.drugs.store).store;
  const [orderResupply, setOrderResupply] = useState<OrderResupplyProps>();
  const [orderNotes, setOrderNotes] = useState<OrderNotesProps>();
  const groupPermissions = useGroupPermissions();

  const facilityGroup = itiriri(facilityGroupsStore.values()).find(
    (facilityGroup) => facilityGroup.hsId?.toString() === facilityGroupId,
  );

  const facilities = itiriri(facilitiesStore.values())
    .toArray()
    .filter((facility) => facility.facilityGroupId?.toString() === facilityGroupId);

  const mapper = mapOrderToView(
    parseInt(facilityGroupId),
    patientsStore,
    facilitiesStore,
    drugStore,
    true,
    setOrderResupply,
    setOrderNotes,
  );
  const orders = itiriri(ordersStore.values())
    .filter((order) => {
      return facilities.some((facility) => facility.hsId === order.hsFacilityId) ?? false;
    })
    .sort((a, b) => DateUtils.compareDatesDescending(DateUtils.ensureDate(a.orderedAt)!, DateUtils.ensureDate(b.orderedAt)!))
    .map(mapper)
    .toArray();

  const history = useHistory();

  return (
    <RequirePermission hasPermission={groupPermissions.canViewResupplyOrder}>
      <Layout>
        <DashboardNavbar facilityGroup={facilityGroup} />
        <Container gap={1}>
          <Layout horizontal gap={1.5}>
            <IconBack onClick={() => history.push(`/facility-group/${facilityGroupId}`)} />
            <Text weight={'bold'} size="large" style={{ color: 'white' }}>
              Resupply history
            </Text>
          </Layout>
          {orderResupply && (
            <OrderResupplyDialog {...orderResupply} isOpen={true} setClosed={() => setOrderResupply(undefined)} />
          )}
          {orderNotes && <OrderNotesDialog {...orderNotes} isOpen={true} setClosed={() => setOrderNotes(undefined)} />}
          {orders && orders.map(OrderRow)}
        </Container>
      </Layout>
    </RequirePermission>
  );
}

const Container = styled(Layout)`
  margin: 1.5rem;
`;
