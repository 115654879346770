import { format, isAfter, isBefore, isToday } from 'date-fns';
import itiriri from 'itiriri';
import { parseInt } from 'lodash-es';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AdHocReasonCode, HSFacility } from 'server-openapi';
import styled from 'styled-components';
import { DateUtils } from '../../core/utils/dateUtils';
import { Grid } from '../../kit/Grid';
import { IconBack } from '../../kit/Icons/Back';
import { Image } from '../../kit/Image';
import { Layout } from '../../kit/Layout';
import { Paper } from '../../kit/Paper';
import { Text } from '../../kit/Text';
import { Intent } from '../../kit/Theme/Theme';
import { useSyncCenter } from '../../syncstream/SyncCenterProvider';
import { useStore } from '../../core/storage/hooks/UseStore';
import { useApiUtils } from '../../syncstream/utils/hooks/useApiUtils';
import { DisplayablePatientNoteInfo } from '../../syncstream/utils/ResidentDetailsUtils';
import { DashboardNavbar } from '../Dashboard/DashboardPage/components/DashboardNavbar';
import { StatusIconContainer } from '../ResidentDetails/components/drugDetails/DrugAdministrationHistory';
import { StatusIcon } from '../ResidentDetails/components/drugDetails/StatusIcon';
import { DrugLabel } from '../ResidentDetails/components/medicationInformation/DrugDetails';
import { SquareDosageBox } from '../ResidentDetails/components/medicationInformation/MedicationInformationBox';
import { NursesNotesFilters } from './NursesNotesFilters';
import { SecondCheckDetailsBar } from '../ResidentDetails/components/medicationInformation/SecondCheckDetailsBar';
import { RequirePermission } from '../../components/RequirePermission/RequirePermission';
import {useGroupPermissions} from "../../core/authz/PermissionsProvider";
import IconAttachment from '../../kit/Icons/Attachment';
import Attachment from '../../kit/Icons/Attachment';
import { Tooltip } from 'react-tooltip';

interface IParams {
  facilityGroupId: string;
}

function NurseNotesContent() {
  // Get the currently selected facility from the URL parameters.
  const { facilityGroupId } = useParams<IParams>();
  const history = useHistory();
  const patientUtils = useApiUtils().patients;
  const userUtils = useApiUtils().users;
  const facilityUtils = useApiUtils().facilities;
  const services = useSyncCenter();
  const residentDetailsUtils = useApiUtils().residentDetails;

  const facilityGroupsStore = useStore(services.facilityGroups.store).store;
  const facilityGroup = itiriri(facilityGroupsStore.values()).find(
    (facilityGroup) => facilityGroup.hsId?.toString() === facilityGroupId,
  );

  const [selectedFacility, setSelectedFacility] = React.useState<HSFacility>();
  const [selectedStartDate, setSelectedStartDate] = React.useState<Date>();
  const [selectedEndDate, setSelectedEndDate] = React.useState<Date>();
  const notes = residentDetailsUtils.getDisplayableNoteInfosForFacilityGroupId(facilityGroupId);

  const notesInLast24Hours = notes.filter((note) => {
    return (
      (!selectedFacility || note.patient.facility === selectedFacility?.hsId) &&
      checkDates(note.date, selectedStartDate, selectedEndDate)
    );
  });

  return (
    <>
      <NursesNotesFilters
        selectedFacility={selectedFacility}
        onSelectFacility={(facility) => setSelectedFacility(facility)}
        facilityGroup={facilityGroup}
        selectedStartDate={selectedStartDate}
        onSelectStartDate={(startDate) => setSelectedStartDate(startDate)}
        selectedEndDate={selectedEndDate}
        onSelectEndDate={(endDate) => setSelectedEndDate(endDate)}
      />
      {notesInLast24Hours.map((note, index) => (
        <InformationBox horizontal key={index}>
          <InformationColumn>
            <Layout horizontal gap={1} style={{ alignItems: 'center' }}>
              {note.patient.imageUrl && (
                    <PatientImage src={note.patient.imageUrl} facilityGroupId={parseInt(facilityGroupId)} onClick={() => history.push(`/resident-details/${note.patient.hsId}/continuous/#${note.attachmentReference}`)}/>
              )}
              <Layout>
                <Text weight={'bold'}>{patientUtils.getDisplayPatientName(note.patient)}</Text>
                <Text>
                  {facilityUtils.fetchFacilityById(note.patient.facility)?.name} Rm {note.patient.roomNumber},
                </Text>
              </Layout>
            </Layout>
          </InformationColumn>
          <InformationColumn>
            <Text weight={'bold'}>{DateUtils.dateTo24HourTimeString(note.date)}</Text>
            <Text>{format(note.date, 'dd MMM yyyy')}</Text>
            <Text weight={'bold'}>Note by</Text>
            <Text>
              {!!note.updatedBySubjectId
                ? userUtils.getUserFullNameAndRoleFromSubjectId(note.updatedBySubjectId)
                : userUtils.getUserFullNameAndRoleFromUsername(note.updatedByLogin)}
            </Text>
          </InformationColumn>
          <InformationColumn style={{ flexBasis: '60%' }}>
            <Text weight={'bold'}>{note.title}</Text>
            <Text>{note.comment}</Text>
            <PatientNoteDrugInfoCard note={note} />
          </InformationColumn>
          <InformationColumn style={{ flexBasis: '10%' }}>
            {note.attachmentReference && (
                <>
                  <Tooltip id='{note.attachmentReference}' />
                  <Attachment onClick={() => history.push(`/resident-details/${note.patient.hsId}/continuous/#${note.attachmentReference}`)}
                              width={'50px'} height={'50px'}
                              data-tooltip-content="Note has an attachment"
                              data-tooltip-id="{note.attachmentReference}"
                              data-tooltip-place="left"/>
                </>
             )}
          </InformationColumn>
        </InformationBox>
      ))}
    </>
  );
}

export function NurseNotesPage() {
  // Get the currently selected facility from the URL parameters.
  const groupPermissions = useGroupPermissions();
  const { facilityGroupId } = useParams<IParams>();
  const history = useHistory();
  const services = useSyncCenter();

  const facilityGroupsStore = useStore(services.facilityGroups.store).store;
  const facilityGroup = itiriri(facilityGroupsStore.values()).find(
    (facilityGroup) => facilityGroup.hsId?.toString() === facilityGroupId,
  );
  return (
    <Layout>
      <DashboardNavbar facilityGroup={facilityGroup} />
      <Container gap={1}>
        <Layout horizontal gap={1.5}>
          <IconBack onClick={() => history.push(`/facility-group/${facilityGroupId}`)} />
          <Text weight={'bold'} size="large" style={{ color: 'white' }}>
            All Nurses notes (last 24 hours)
          </Text>
        </Layout>
        <RequirePermission hasPermission={groupPermissions.canViewResidentNotes}>
          <NurseNotesContent />
        </RequirePermission>
      </Container>
    </Layout>
  );
}
interface Props {
  note: DisplayablePatientNoteInfo;
}

export function PatientNoteDrugInfoCard(props: Props) {
  const { administeredDrug, date, updatedByLogin, updatedBySubjectId } = props.note;
  const services = useSyncCenter();
  const drugsStore = useStore(services.drugs.store).store;
  const apiUtils = useApiUtils();
  const confirmationUser = apiUtils.users.getUserFullNameAndRoleFromUserOrEmailId(administeredDrug?.confirmationInitials, administeredDrug?.confirmationUserId);

  if (!administeredDrug) {
    return <></>;
  }

  const drug = itiriri(drugsStore.values()).find((drug) => drug.drugCode === administeredDrug.drugCode);

  // TODO: Remove this when AdHocReasonCode support is added to SS
  if ('administeredAdHocDrugComments' in administeredDrug) {
    administeredDrug.reasonCode === AdHocReasonCode.Dosed;
  }

  return (
    <Layout gap={0}>
      <DrugInfoCard intent={Intent.Info}>
        <Grid colsTemplate={'0.5fr 3fr 1.5fr'}>
          <StatusIconContainer reasonCode={administeredDrug.reasonCode} style={{ alignSelf: 'center' }}>
            <StatusIcon reasonCode={administeredDrug.reasonCode} />
          </StatusIconContainer>
          <Layout>
            {drug && <DrugLabel drug={drug} isCeased={false} isPsychotropicConsent={false} />}
            <Text>
              {DateUtils.dateTo24HourTimeString(date)} by{' '}
              {!!updatedBySubjectId
                ? apiUtils.users.getUserFullNameAndRoleFromSubjectId(updatedBySubjectId)
                : apiUtils.users.getUserFullNameAndRoleFromUsername(updatedByLogin)}
            </Text>
          </Layout>
          <Layout horizontal align="center">
            <SquareDosageBox>{administeredDrug.administeredDosage}</SquareDosageBox>
            <Text>{apiUtils.residentDetails.reasonCodeToString(administeredDrug.reasonCode)}</Text>
          </Layout>
        </Grid>
      </DrugInfoCard>
      {confirmationUser && (
        <SecondCheckDetailsBar userName={confirmationUser} date={date} />
      )}
    </Layout>
  );
}

const DrugInfoCard = styled(Paper)`
  align-items: center;
  margin-top: 0.75rem;
`;

//for some odd reason date input returns a date with 11:00 as time which might mess up midnight to midnight output
function getMidnightDate(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

function checkDates(noteDate: Date, startDate: Date | undefined, endDate: Date | undefined) {
  switch (true) {
    case startDate !== undefined && endDate !== undefined:
      return isAfter(noteDate, getMidnightDate(startDate!)) && isBefore(noteDate, getMidnightDate(endDate!));
    case startDate !== undefined:
      return isAfter(noteDate, getMidnightDate(startDate!));
    case endDate !== undefined:
      return isBefore(noteDate, getMidnightDate(endDate!));
    default:
      return isToday(noteDate);
  }
}

const Container = styled(Layout)`
  margin: 1.5rem;
`;

const PatientImage = styled(Image)`
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 999999999px;
  align-self: center;
`;

const InformationBox = styled(Layout)`
  background: white;
  display: flex;
  align-items: center;
  padding: 0.875rem;
  width: 100%;
`;

const InformationColumn = styled(Layout)`
  flex-basis: 20%;
  padding-right: 15px;
`;
