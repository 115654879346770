import * as React from 'react';
import { SVGProps } from 'react';

const IconAllergyAlert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="tag-allergy-alert_svg__Layer_1"
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...props.style, backgroundColor: 'white', borderRadius: '999999px' }}
    {...props}
  >
    <style>{'.tag-allergy-alert_svg__st0{fill:#ea2a2a}'}</style>
    <path className="tag-allergy-alert_svg__st0" d="M8 4h2v7H8zM8 12h2v2H8z" />
  </svg>
);

export default IconAllergyAlert;
