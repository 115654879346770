import React from 'react';
import { Grid } from '../../../../../kit/Grid';
import { HSAdministeredAdHocDrug, HSDoseRound, NimAvailableDrugDto } from 'server-openapi';
import { useSyncCenter } from '../../../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../../../core/storage/hooks/UseStore';
import itiriri from 'itiriri';
import { MedicationListProps } from '../MedicationListsTabbedRouter';
import { MedicationInformationBox } from '../../medicationInformation/MedicationInformationBox';
import { DateUtils } from '../../../../../core/utils/dateUtils';
import { DrugOutcome } from '../../medicationInformation/DrugOutcomeDialog';
import { EnqueuedDrugCreateData } from '../../../../../syncstream/SyncRounds';
import { MedicationGroup } from '../../../../../syncstream/utils/ResidentDetailsUtils';
interface Props extends MedicationListProps {
  onAdministerAdHoc: (drug: HSAdministeredAdHocDrug) => Promise<EnqueuedDrugCreateData | undefined>;
  onAddOutcome: (
    outcome: DrugOutcome,
    administeredDrugClinicalSystemId: string,
    doseRoundClinicalSystemId: string,
    isAdHoc: boolean,
  ) => Promise<void>;
    groupedPackedMedicationList: MedicationGroup[];
}

export function NIMsMedicationList(props: Props) {
  const services = useSyncCenter();
  const nimsStore = useStore(services.nimAvailableDrugs.store).store;
  const nimList = itiriri(nimsStore.values())
    .filter((nim) => nim.nimConsumers?.some((consumer) => consumer.hsPatientId === props.patient.hsId) ?? false)
    .sort((a, b) => {
      return (
        (props.drugList &&
          props.drugList
            .find((d) => d.hsId === a.drugHsId)
            ?.name?.localeCompare(props.drugList.find((d) => d.hsId === b.drugHsId)?.name ?? '')) ??
        0
      );
    })
    .toArray();

  return (
    <Grid cols={1} gap={0.5}>
      {nimList.map((nim) => (
        <NIMMedicationInformation key={nim.id} nimAvailableDrug={nim} {...props} />
      ))}
    </Grid>
  );
}

interface MedicationInformationProps extends Props {
  currentDosedDrug?: HSAdministeredAdHocDrug;
  nimAvailableDrug: NimAvailableDrugDto;
}

function NIMMedicationInformation(props: MedicationInformationProps) {
  const services = useSyncCenter();
  const roundsStore = useStore(services.rounds.store).store;

  const sortedHistoricalAdministrations = getSortedHistoricalNimAdministrations(
    props.patient.hsId!,
    props.nimAvailableDrug.drugHsId!,
    Array.from(roundsStore.values()),
  );
  const lastAdministeredDrug:
    | { doseRoundClinicalSystemId: string; administeredAdHocDrug: HSAdministeredAdHocDrug }
    | undefined = sortedHistoricalAdministrations[0];

  return (
    <MedicationInformationBox
      {...props}
      currentDosedDrug={props.currentDosedDrug}
      previousDosedDrug={lastAdministeredDrug?.administeredAdHocDrug}
      isUnscheduledMedication
      onAddOutcome={(outcome, drugClinicalSystemId) =>
        props.onAddOutcome(outcome, drugClinicalSystemId, lastAdministeredDrug.doseRoundClinicalSystemId, true)
      }
      groupedPackedMedicationList={props.groupedPackedMedicationList}
    />
  );
}

function getSortedHistoricalNimAdministrations(patientId: number, drugId: number, rounds: HSDoseRound[]) {
  return (
    rounds
      .flatMap((r) =>
        (r.administeredDoses ?? []).map((ad) => ({ ...ad, doseRoundClinicalSystemId: r.clinicalSystemId! })),
      )
      .filter((p) => p.patientId === patientId)
      ?.flatMap((r) =>
        (r.administeredAdHocDrugs ?? []).map((ad) => ({
          doseRoundClinicalSystemId: r.doseRoundClinicalSystemId,
          administeredAdHocDrug: ad,
        })),
      )
      .filter((d) => d.administeredAdHocDrug.drugId === drugId)
      .sort((a, b) =>
        DateUtils.compareDateStringsDescending(
          a.administeredAdHocDrug.administeredAt,
          b.administeredAdHocDrug.administeredAt,
        ),
      ) || []
  );
}
