import * as React from 'react';
import { SVGProps } from 'react';

const OffsiteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="5em" height="2em" viewBox="0 0 66 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={66} height={31} rx={15.5} fill="#DADADA" />
    <path
      d="M15.89 15.848c0-2.556-1.549-4.284-3.949-4.284s-3.96 1.74-3.96 4.308c0 2.556 1.56 4.284 3.936 4.284 2.412 0 3.972-1.752 3.972-4.308Zm-2.065.012c0 1.644-.696 2.688-1.896 2.688-1.188 0-1.896-1.044-1.896-2.688 0-1.644.696-2.688 1.896-2.688s1.896 1.044 1.896 2.688Zm8.237-2.424V11.72h-4.956V20h2.04v-3.144h2.448l-.288-1.74h-2.16v-1.68h2.916Zm6.035 0V11.72H23.14V20h2.04v-3.144h2.448l-.288-1.74h-2.16v-1.68h2.916Zm4.09 3.936v-1.728h-3.275v1.728h3.276Zm6.54.132c0-1.32-.72-1.944-2.52-2.604-1.032-.396-1.26-.6-1.26-1.02 0-.384.3-.648.924-.648.636 0 1.416.24 2.16.672l.312-1.728c-.72-.348-1.416-.552-2.496-.552-1.584 0-2.832.852-2.832 2.448 0 1.368.72 1.908 2.376 2.544 1.164.444 1.404.66 1.404 1.128 0 .492-.408.744-.996.744-.828 0-1.776-.312-2.628-.768l-.3 1.752c.84.384 1.776.624 2.892.624 1.644 0 2.964-.792 2.964-2.592ZM41.94 20v-8.28h-2.052V20h2.052Zm7.94-6.54-.288-1.74H43.04v1.74h2.292V20h2.064v-6.54h2.484ZM56.077 20v-1.644h-3.252v-1.788h2.664l-.264-1.692h-2.4v-1.512h3.144V11.72h-5.16V20h5.268Z"
      fill="#000"
    />
  </svg>
);

export default OffsiteIcon;
