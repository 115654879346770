import {HSFacility, HSPackedPatientPrnMedication, HSPatient} from 'server-openapi';
import { PersistentQueue } from '../core/queue/PersistentQueue';
import {Entry, IStorage} from '../core/storage/Contract';
import { SyncStreamAPI } from './api';
import {IFacilityGroupSyncService } from './SyncCenter';
import { SyncUtils } from './utils/SyncUtils';
import {MemoryCache} from "../core/storage/MemoryCache";

interface Operation {
  type: void;
  payload: HSPackedPatientPrnMedication;
}

export class SyncPackedPatientPrnMedication implements IFacilityGroupSyncService {
  get name(): string {
    return 'SyncPackedPatientPrnMedication';
  }
  private async isFacilityGroup(p: HSPackedPatientPrnMedication, facilityGroupId: string): Promise<boolean> {
    const facilityGroup = await this.facilitiesStore.get(p.facilityId!.toString());
    return facilityGroup?.facilityGroupId?.toString() == facilityGroupId;
  }
  constructor(
    private api: SyncStreamAPI,
    private storage: MemoryCache<HSPackedPatientPrnMedication>,
    private facilitiesStore: IStorage<HSFacility>,
    private latestChangeNumbers: IStorage<number | undefined>,
    private queue: PersistentQueue<Operation>,
  ) {}
  async load(facilityGroupId: string): Promise<void> {
    await this.storage.reset(async (p) => {
          return await this.isFacilityGroup(p, facilityGroupId);
    });
  }

  //eslint-disable-next-line sonarjs/cognitive-complexity
  async syncDown(facilityGroupId?: string) {
    const facilitiesToSync = await SyncUtils.getFacilitiesForGroup(facilityGroupId, this.facilitiesStore);
    const changeNumber = await SyncUtils.getChangeNumberForFacilities(
      facilitiesToSync.map((x) => x.hsId!),
      this.latestChangeNumbers,
    );

    const packedPatientPrnData = await this.syncFacilityDown(
      facilityGroupId!, //non null assertion on facility group id
      facilitiesToSync.map((facility) => facility.hsId!),
      changeNumber,
    );
    await this.storage.setMany(
      packedPatientPrnData
        .map((packedPrn) => ({
          key: this.storage.get_key!(packedPrn),
          value: packedPrn,
        })),
    );
    await SyncUtils.setChangeNumberForFacilities(
        facilitiesToSync.map((x) => x.hsId!),
        this.latestChangeNumbers,
        packedPatientPrnData
    );
  }

  private async syncFacilityDown(
    facilityGroupId: string,
    facilityIds: number[],
    changeNumber: number,
  ): Promise<HSPackedPatientPrnMedication[]> {
    const pageSize = 300;
    const packedPatientPrnMedications =
      await this.api.packedPatientPrnMedications.packedPatientPrnMedicationListPackedPatientPrnMedication(
        changeNumber,
        parseInt(facilityGroupId),
        pageSize,
      );

    if (packedPatientPrnMedications.data.length === pageSize) {
      return [
        ...packedPatientPrnMedications.data,
        ...(await this.syncFacilityDown(
          facilityGroupId,
          facilityIds,
          SyncUtils.getLatestChangeNumber(packedPatientPrnMedications.data)!,
        )),
      ];
    }
    return packedPatientPrnMedications.data;
  }

  async syncUp() {
    // Do nothing
  }

  async clear() {
    await this.storage.clear();
    await this.latestChangeNumbers.clear();
    await this.queue.clear();
  }

  async hasQueuedData() {
    return (await this.queue.length()) > 0;
  }
  isAllowed(canUserAccessMedication: boolean): boolean {
    // Only if you can view a round.
    return canUserAccessMedication;
  }

  async archive(deletedPatientIds: string[]): Promise<void> {
    const keysToDelete: string[] = [];
    for (let [k, v] of (await this.storage.all()).entries()) {
      if (deletedPatientIds.some(x => x === v.patientId?.toString() ?? "-1")) {
        keysToDelete.push(k)
      }
    }
    await this.storage.deleteMany(keysToDelete);
  }
  setEncryptionVersion(version: number): void {
    this.storage.compressOnSave = (version > 1);
  }
  async rewrite(): Promise<void> {
    const entries: Entry<HSPackedPatientPrnMedication>[] = [...(await this.storage.all())].map((keyValueArray) => {
      return {
        key: keyValueArray[0],
        value: keyValueArray[1]
      };
    });

    return await this.storage.setMany(entries);
  }}
