import { range } from 'lodash-es';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FacilityGroupConfigurationDto } from 'server-openapi';
import styled, { useTheme } from 'styled-components';
import {RequirePermission} from '../../../../../components/RequirePermission/RequirePermission';
import { apis } from '../../../../../core/mrs/apis';
import { Button } from '../../../../../kit/Button';
import { FormGroup } from '../../../../../kit/Forms/FormGroup';
import { MultiSelect } from '../../../../../kit/Forms/MultiSelect';
import { Select } from '../../../../../kit/Forms/Select';
import { TextInput } from '../../../../../kit/Forms/TextInput';
import { Grid } from '../../../../../kit/Grid';
import { useAsync } from '../../../../../kit/hooks/UseAsync';
import { Layout } from '../../../../../kit/Layout';
import { Loading } from '../../../../../kit/Loading';
import { colors, Intent } from '../../../../../kit/Theme/Theme';
import { toasts } from '../../../../../kit/Toasts/Toaster';
import { useSyncCenter } from '../../../../../syncstream/SyncCenterProvider';
import {useGlobalPermissions, useGroupPermissions} from "../../../../../core/authz/PermissionsProvider";
import {Checkbox} from "../../../../../kit/Checkbox";

interface IParams {
  facilityGroupId: string;
}

interface IFacilityConfigPermissions {
  canEditRoundWindow: boolean;
  canEditPatchSightFrequency: boolean;
  canEditFacilityLabel: boolean;
  canEditFacilityGroupConfigurationLoginTypes: boolean;
}

export function FacilityGroupConfiguration() {
    const groupPermissions = useGroupPermissions();

    return (
        <RequirePermission hasPermission={groupPermissions.canViewFacilityGroupConfigurationIncludingUserManagement || groupPermissions.canViewFacilityGroupConfigurationExcludingUserManagement}>
            <FacilityGroupConfigurationContent />
        </RequirePermission>
    );
}

/* eslint-disable max-lines-per-function */
/* eslint-disable sonarjs/cognitive-complexity */
function FacilityGroupConfigurationContent() {
    const sightHoursOptions = range(5, 25).map((hour) =>
        hour >= 5 && hour <= 9
            ? { label: `${hour}:00`, value: `0${hour}:00` }
            : { label: `${hour}:00`, value: `${hour}:00` },
    );
    const roundHoursOptions = range(0.5, 3.5, 0.5).map((hour) =>
        hour === 1 ? { label: `${hour} hour`, value: hour * 60 * 60 } : { label: `${hour} hours`, value: hour * 60 * 60 },
    );
    const loginTypeOptions =
          [{ label: `Local`, value: `Local` },
              { label: `SSO`, value: `SSO` }];

    const theme = useTheme();
    const services = useSyncCenter();
    const { facilityGroupId } = useParams<IParams>();
    const groupPermissions = useGroupPermissions();
    const globalPermissions = useGlobalPermissions();

    const facilityConfigPermissions: IFacilityConfigPermissions = {
        canEditRoundWindow: groupPermissions.canConfigurePermittedRoundStartPeriodWindow ?? false,
        canEditFacilityLabel: groupPermissions.canConfigureLabel ?? false,
        canEditPatchSightFrequency: groupPermissions.canConfigureFrequencyForCheckingPatches ?? false,
        canEditFacilityGroupConfigurationLoginTypes: groupPermissions.canEditFacilityGroupConfigurationLoginTypes ?? false,
    };
    // since the FacilityGroupConfigurationDto is a flat object, it is safe to use it as the state
    const [facilityGroupSettings, setFacilityGroupSettings] = useState<FacilityGroupConfigurationDto>();

    const asyncHook = useAsync(async () => {
        const data = (await apis.facilityGroups.facilityGroupsGetFacilityGroupConfiguration(parseInt(facilityGroupId)))
            .data;
        setFacilityGroupSettings(data);
    });

    const formatUrl = (url: string) => {
        try {
            const formattedUrl = new URL(url);
            return formattedUrl.href;
        } catch (e) {
            return 'http://' + url;
        }
    };

    const onSubmitApi = (data: FacilityGroupConfigurationDto) => {
        if (data.facilityGroupIncidentUrl) {
            data.facilityGroupIncidentUrl = formatUrl(data.facilityGroupIncidentUrl);
        }
        if (data.facilityGroupMedicinesDatabaseURL) {
            data.facilityGroupMedicinesDatabaseURL = formatUrl(data.facilityGroupMedicinesDatabaseURL);
        }


        apis.facilityGroups
            .facilityGroupsUpdateFacilityGroupConfiguration(data)
            .then((response) => {
                toasts.success('The configuration has been updated successfully');
                services.facilityGroupConfigurations.store.set(facilityGroupId, response.data);
            })
            .catch((error) => toasts.error(`${error}`));
    };
    function strEnum<T extends string>(o: Array<T>): {[K in T]: K} {
        return o.reduce((res, key) => {
            res[key] = key;
            return res;
        }, Object.create(null));
    }



    return (
        <>
            {asyncHook.loading || asyncHook.error ? (
                <Loading loading={asyncHook.loading} error={asyncHook.error} />
            ) : (
                <Layout gap={2}>
                    <div>
                        <HeaderContainer>
                            <HeaderText>Patches</HeaderText>
                        </HeaderContainer>
                        <SubTitleContainer>
                            <SubTitleText>How often are nurses required to sight patches</SubTitleText>
                        </SubTitleContainer>
                        <Layout style={{ background: theme.backgrounds.lighter.bg, marginBottom: '2rem' }} padding="0.8rem">
                            <MultiSelect
                                onChange={(_, checked, value) => {
                                    let values = facilityGroupSettings?.patchSightHour ?? [];
                                    values = checked ? [...values, value] : values.filter((v) => v !== value);
                                    setFacilityGroupSettings({
                                        ...facilityGroupSettings,
                                        patchSightHour: values,
                                    });
                                }}
                                disabled={!facilityConfigPermissions.canEditPatchSightFrequency}
                                options={sightHoursOptions}
                                name="sightHours"
                                cols={8}
                                required
                                intent={Intent.Primary}
                                buttonBackgroundColor={colors.text_white}
                                selectValues={facilityGroupSettings?.patchSightHour ?? []}
                            />
                        </Layout>
                        <HeaderContainer>
                            <HeaderText>Rounds</HeaderText>
                        </HeaderContainer>
                        <SubTitleContainer>
                            <SubTitleText>A limit to start rounds within a time period before and after the round time</SubTitleText>
                        </SubTitleContainer>
                        <Layout
                            style={{
                                background: theme.backgrounds.lighter.bg,
                                width: '40rem',
                                marginBottom: '2rem',
                            }}
                            padding="1.5rem"
                        >
                            <Select
                                fullWidth
                                name="selectRoundWindow"
                                disabled={!facilityConfigPermissions.canEditRoundWindow}
                                options={roundHoursOptions}
                                value={facilityGroupSettings?.roundWindowInSeconds}
                                onChange={(_, value) =>
                                    setFacilityGroupSettings({ ...facilityGroupSettings, roundWindowInSeconds: value })
                                }
                            />
                        </Layout>
                        <HeaderContainer>
                            <HeaderText>Labelling</HeaderText>
                        </HeaderContainer>
                        <SubTitleContainer>
                            <SubTitleText>Configure the facility labeling in the plural form</SubTitleText>
                        </SubTitleContainer>
                        <Grid
                            colsTemplate="22rem 22rem 22rem"
                            gap={1}
                            style={{
                                background: theme.backgrounds.lighter.bg,
                                marginBottom: '2rem',
                                padding: '1.25rem',
                                width: '70.5rem',
                            }}
                        >
                            <FormGroup label="Facility Label" fullWidth>
                                <TextInput
                                    name="facilityGroupLabel"
                                    onChange={(_, value) =>
                                        setFacilityGroupSettings({ ...facilityGroupSettings, facilityGroupUILabel: value })
                                    }
                                    disabled={!facilityConfigPermissions.canEditFacilityLabel}
                                    value={facilityGroupSettings?.facilityGroupUILabel ?? undefined}
                                    fullWidth
                                    required
                                    autoComplete="off"
                                />
                            </FormGroup>
                            <FormGroup label="Ward Label" fullWidth>
                                <TextInput
                                    name="facilityLabel"
                                    onChange={(_, value) =>
                                        setFacilityGroupSettings({ ...facilityGroupSettings, facilityUILabel: value })
                                    }
                                    disabled={!facilityConfigPermissions.canEditFacilityLabel}
                                    value={facilityGroupSettings?.facilityUILabel ?? undefined}
                                    fullWidth
                                    required
                                    autoComplete="off"
                                />
                            </FormGroup>
                            <FormGroup label="Resident Label" fullWidth>
                                <TextInput
                                    name="residentLabel"
                                    onChange={(_, value) =>
                                        setFacilityGroupSettings({ ...facilityGroupSettings, residentUILabel: value })
                                    }
                                    disabled={!facilityConfigPermissions.canEditFacilityLabel}
                                    value={facilityGroupSettings?.residentUILabel ?? undefined}
                                    fullWidth
                                    required
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Grid>
                        <HeaderContainer>
                            <HeaderText>Incidents</HeaderText>
                        </HeaderContainer>
                        <SubTitleContainer>
                            <SubTitleText>Configure the incident URL</SubTitleText>
                        </SubTitleContainer>
                        <Layout
                            style={{
                                background: theme.backgrounds.lighter.bg,
                                width: '40rem',
                                marginBottom: '2rem',
                            }}
                            padding="1.5rem"
                        >
                            <FormGroup label="Incident URL" fullWidth>
                                <TextInput
                                    name="facilityGroupIncidentUrl"
                                    onChange={(_, value) =>
                                        setFacilityGroupSettings({ ...facilityGroupSettings, facilityGroupIncidentUrl: value })
                                    }
                                    disabled={!facilityConfigPermissions.canEditFacilityLabel}
                                    value={facilityGroupSettings?.facilityGroupIncidentUrl ?? undefined}
                                    fullWidth
                                    required
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Layout>

                        <HeaderContainer>
                            <HeaderText>Support Email</HeaderText>
                        </HeaderContainer>
                        <SubTitleContainer>
                            <SubTitleText>Configure facility's technical support email address.</SubTitleText>
                        </SubTitleContainer>
                        <Layout
                            style={{
                                background: theme.backgrounds.lighter.bg,
                                width: '40rem',
                                marginBottom: '2rem',
                            }}
                            padding="1.5rem"
                        >
                            <FormGroup label="Support Email" fullWidth>
                                <TextInput
                                    name="facilityGroupSupportEmail"
                                    onChange={(_, value) =>
                                        setFacilityGroupSettings({ ...facilityGroupSettings, supportEmail: value })
                                    }
                                    disabled={!facilityConfigPermissions.canEditFacilityLabel}
                                    value={facilityGroupSettings?.supportEmail ?? undefined}
                                    fullWidth
                                    required
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Layout>


                        <HeaderContainer>
                            <HeaderText>Medicines Database URL</HeaderText>
                        </HeaderContainer>
                        <SubTitleContainer>
                            <SubTitleText>Configure the Medicines Database URL. eg eMIMS or AMH link.</SubTitleText>
                        </SubTitleContainer>
                        <Layout
                            style={{
                                background: theme.backgrounds.lighter.bg,
                                width: '40rem',
                                marginBottom: '2rem',
                            }}
                            padding="1.5rem"
                        >
                            <FormGroup label="Medicines Database URL" fullWidth>
                                <TextInput
                                    name="facilityGroupMedicinesDatabaseURL"
                                    onChange={(_, value) =>
                                        setFacilityGroupSettings({ ...facilityGroupSettings, facilityGroupMedicinesDatabaseURL: value })
                                    }
                                    disabled={!facilityConfigPermissions.canEditFacilityLabel}
                                    value={facilityGroupSettings?.facilityGroupMedicinesDatabaseURL ?? undefined}
                                    fullWidth
                                    required
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Layout>



                        {facilityConfigPermissions.canEditFacilityGroupConfigurationLoginTypes ?
                            <>
                                <HeaderContainer>
                                    <HeaderText>Login Types</HeaderText>
                                </HeaderContainer>
                                <SubTitleContainer>
                                    <SubTitleText>Configure the login types that are applicable for this facility</SubTitleText>
                                </SubTitleContainer>
                                <Layout
                                    style={{
                                        background: theme.backgrounds.lighter.bg,
                                        width: '40rem',
                                        marginBottom: '2rem',
                                    }}
                                    padding="1.5rem"
                                >
                                    <FormGroup fullWidth>
                                        <MultiSelect
                                            onChange={(_, checked, value) => {
                                                let values = facilityGroupSettings?.loginTypes ?? [];
                                                values = checked ? [...values, value] : values.filter((v) => v !== value);
                                                setFacilityGroupSettings({
                                                    ...facilityGroupSettings,
                                                    loginTypes: values,
                                                });
                                            }}
                                            disabled={!facilityConfigPermissions.canEditFacilityGroupConfigurationLoginTypes}
                                            options={loginTypeOptions}
                                            name="loginTypes"
                                            cols={8}
                                            required
                                            intent={Intent.Primary}
                                            buttonBackgroundColor={colors.text_white}
                                            selectValues={facilityGroupSettings?.loginTypes?.toString().split(',').map(item=>item.trim()) ?? []}
                                        />
                                    </FormGroup>
                                </Layout>
                            </> : null
                        }
                        {globalPermissions.canOnboardFacilities ?
                            <>
                                <HeaderContainer>
                                    <HeaderText>Application Encryption</HeaderText>
                                </HeaderContainer>
                                <SubTitleContainer>
                                    <SubTitleText>This setting will enable encryption of the browser data.</SubTitleText>
                                </SubTitleContainer>
                                <Layout
                                    style={{
                                        background: theme.backgrounds.lighter.bg,
                                        width: '40rem',
                                        marginBottom: '2rem',
                                    }}
                                    padding="1.5rem"
                                >
                                    <FormGroup fullWidth>
                                        <Checkbox
                                            label="Encrypt Browser Data"
                                            checked={facilityGroupSettings?.encryptionVersion==2 ?? false}
                                            onChange={() => {
                                                setFacilityGroupSettings({
                                                    ...facilityGroupSettings,
                                                    encryptionVersion: (facilityGroupSettings?.encryptionVersion == 1 ? 2 : 1),
                                                });
                                            }}
                                            disabled={!globalPermissions.canOnboardFacilities}
                                            name="encryptionVersion"
                                            required
                                        />
                                    </FormGroup>
                                </Layout>
                            </> : null
                        }

                        {facilityGroupSettings && (
                            <Layout
                                style={{
                                    display: 'flex',
                                    justifyContent: 'right',
                                    marginTop: '1rem',
                                }}
                            >
                                <SubmitBtnContainer>
                                    <SubmitButton
                                        intent={Intent.Secondary}
                                        disabled={ !(facilityConfigPermissions.canEditFacilityLabel
                            || facilityConfigPermissions.canEditFacilityGroupConfigurationLoginTypes
                            || facilityConfigPermissions.canEditPatchSightFrequency
                            || facilityConfigPermissions.canEditRoundWindow)}
                                        onClick={() => {
                                            if (
                                                facilityGroupSettings.patchSightHour === null ||
                      facilityGroupSettings.patchSightHour === undefined ||
                      facilityGroupSettings.patchSightHour.length === 0
                                            ) {
                                                toasts.error('At least one patch hour must be selected');
                                                return;
                                            }
                                            onSubmitApi(facilityGroupSettings);
                                        }}>Submit</SubmitButton>
                                </SubmitBtnContainer>
                            </Layout>
                        )}
                    </div>
                </Layout>
            )}
        </>
    );
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const HeaderText = styled.h2`
  margin: auto 0;
`;

const SubTitleText = styled.p`
  margin: auto 0;
`;

const SubmitBtnContainer = styled.div`
  display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 50px;
    right: 100px;
`

const SubmitButton = styled(Button)`
  width: 250px;
  display: inline-block;
    background-color: ${colors.bg_green_400};
`;
