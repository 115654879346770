/**
 * Returns an array of  strings of names of the flags
 * @param enumType The enum Type
 * @param value the positional number of the enum
 * Source: https://stackoverflow.com/questions/75055449/how-can-i-get-the-active-flags-of-a-flag-enum-in-an-object-in-typescript
 */


export function getSelectedEnumMembers<T extends Record<keyof T, number>>(enumType: T, value: number): Extract<keyof T, string>[] {
    function hasFlag(value: number, flag: number): boolean {
        return (value & flag) === flag;
    }

    const selectedMembers: Extract<keyof T, string>[] = [];
    for (const member in enumType) {
        if (hasFlag(value, enumType[member])) {
            selectedMembers.push(member);
        }
    }
    return selectedMembers;
}
