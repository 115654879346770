import { format, isAfter, intervalToDuration, addSeconds } from 'date-fns';
import dayjs from 'dayjs'; // use dayjs toDate for performance over date-fns
// https://medium.com/swlh/best-moment-js-alternatives-5dfa6861a1eb
import { duration } from 'moment';

export interface Interval {
  start: string;
  end: string;
}

export interface DateInterval {
  start: Date;
  end: Date;
}

export class DateUtils {
  private static localOffSetString = format(new Date(), 'xxx');

  static getLocalOffsetString(): string {
    return this.localOffSetString;
  }

  static areTimesCompatible(time1: string, time2: string) {
    const isTime1Zoned = time1.slice(-6, -5) === '+';
    const isTime2Zoned = time2.slice(-6, -5) === '+';
    const time1TZ = time1.slice(-6);
    const time2TZ = time2.slice(-6);
    if (isTime1Zoned && isTime2Zoned) {
      return time1TZ === time2TZ;
    } else if (isTime1Zoned) {
      return time1TZ === this.getLocalOffsetString();
    } else if (isTime2Zoned) {
      return time2TZ === this.getLocalOffsetString();
    } else {
      return true;
    }
  }

  static isTimeBetween(time: string, { start, end }: Interval): boolean {
    if (this.areTimesCompatible(time, start) && this.areTimesCompatible(time, end)) {
      return time >= start && time <= end;
    } else {
      return isAfter(this.toDate(time), this.toDate(start)) && isAfter(this.toDate(end), this.toDate(time));
    }
  }

  static intervalToDateInterval(interval: Interval): DateInterval {
    return { start: DateUtils.toDate(interval.start), end: DateUtils.toDate(interval.end) };
  }

  // ensureDate and ensureString have been added to deal with the fact that Order objects are created dynamically in the client from
  // HSOrder objects.  These Order objects persist Date objects to IndexedDB.  Ordinarily this seems fine, but encryption is enabled
  // these dates are converted to strings before they are persisted, so the code would blow up when they were read from the db as it
  // was expecting dates, but was receiving strings.
  // As these objects are persisted, temporarily deal with this by making the internal object Date | string.
  // The ensureString and ensureDate are used to coerce them to the correct type.
  static ensureString(dt?: string | Date): string | undefined {
    if (!dt) {
      return undefined;
    }
    if (dt instanceof Date) {
      return DateUtils.fromDate(dt);
    }
    return dt;
  }

  static ensureDate(dt?: string | Date): Date | undefined {
    if (!dt) {
      return undefined;
    }
    if (dt instanceof Date) {
      return dt;
    }
    return DateUtils.toDate(dt);
  }
  static toDate(dateString: string) {
    return dayjs(dateString).toDate();
  }

  static fromDate(utc: Date): string {
    return `${format(utc, "yyyy-MM-dd'T'HH:mm:ss")}${this.localOffSetString}`;
  }

  static dateTo24HourTimeString(date: Date) {
    return date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      //force 24hour format to work on 0 to 23
      hourCycle: 'h23',
    });
  }

  static dateStringTo24HourTimeString(dateString: string) {
    return this.dateTo24HourTimeString(this.toDate(dateString));
  }

  static compareDatesDescending(date1: Date, date2: Date, ascending?: boolean) {
    return (date2.valueOf() - date1.valueOf()) * (ascending ? -1 : 1);
  }

  static compareDates(lhs?: Date | undefined, rhs?: Date | undefined, ascending?: boolean) {
    const direction = ascending ? 1 : -1;
    if (!lhs && rhs) {
      // Put null LHS below non-null RHS
      return direction * -1;
    } else if (lhs && !rhs) {
      // Put null RHS below non-null LHS
      return direction;
    } else if (lhs && rhs) {
      return (lhs.valueOf() - rhs.valueOf()) * direction;
    }

    return 0;
  }

  static compareDateStringsDescending(
    nullableDateString1?: string | null,
    nullableDateString2?: string | null,
    ascending?: boolean,
  ) {
    return nullableDateString1 && nullableDateString2
      ? (this.toDate(nullableDateString2).valueOf() - this.toDate(nullableDateString1).valueOf()) * (ascending ? -1 : 1)
      : 0;
  }

  // Certain dates are supposed to just be a date, without any timezone associated. For those we strip off the offset/timezone
  // This implementation is a little ugly, but ISO strings have consistent formatting, meaning this should be reliable
  static toOffsetlessDate(isoString: string) {
    return this.toDate(isoString.substring(0, 19));
  }

  static getEndDateFromOpenApiDurationAndStartDate(openApiDurationString: string, startDate: Date) {
    const seconds = duration(openApiDurationString).asSeconds();

    return addSeconds(startDate, seconds);
  }

  static toOffsetlessTimestamp(timestamp: number) {
    return timestamp + new Date().getTimezoneOffset() * 60 * 1000;
  }

  static getOpenApiDurationFromInterval(startDate: Date, endDate: Date) {
    const duration = intervalToDuration({ start: startDate, end: endDate });
    return `${duration.days}.${duration.hours}:${duration.minutes}:${duration.seconds}`;
  }

  /**
   * Converts a time string in am/pm format into 24 hour time string
   * Eg - 03:30pm => 15:30
   * @param timeStringRaw string with time in am pm format
   * @returns 24 hour time string
   */
  static timeStringTo24HourTimeString(timeStringRaw: string) {
    //zero padding hack to ensure hour is calculated correctly (in case the hour does not have 0)
    const timeString = timeStringRaw.length < 7 ? `0${timeStringRaw}` : timeStringRaw;
    const hours = parseInt(timeString.substring(0, 2));
    const minutes = timeString.substring(3, 5);
    const timePeriod = timeString.substring(5, 7);

    switch (true) {
      case timePeriod === 'pm' && hours === 12:
        return `${hours}:${minutes}`;
      case timePeriod === 'pm' && hours < 12:
        return `${hours + 12}:${minutes}`;
      case timePeriod === 'am' && hours === 12:
        return `00:${minutes}`;
      case timePeriod === 'am' && hours < 12:
        return `${hours < 10 ? `0${hours}` : hours}:${minutes}`;
      default:
        return '';
    }
  }
  static uniqueDates(input: Date[]): Date[] {
    const hsh = new Map<number, Date>();
    for (const dt of input) {
      hsh.set(dt.getTime(), dt);
    }
    return [...hsh.values()];
  }
  static sortDates(input: Date[], ascending?: boolean): Date[] {
    return input.sort((a, b) => DateUtils.compareDates(a, b, ascending));
  }
}
