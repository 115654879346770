import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import urljoin from 'url-join';
import { ReportingPage } from '../Reporting/ReportingPage';
import { FacilityMetricsPage } from './FacilityMetricsPage/FacilityMetricsPage';
import { NurseNotesPage } from './NurseNotesPage';
import { OccupancyStatusPage } from './OccupancyStatusPage';
import { FacilityOrdersPage } from './FacilityOrdersPage/FacilityOrdersPage';

export function FacilityManagement() {
  const { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>
        <Route path={urljoin(path, 'facility-metrics')} component={FacilityMetricsPage} />
        <Route path={urljoin(path, 'nurse-notes')} component={NurseNotesPage} />
        <Route path={urljoin(path, 'occupancy-status')} component={OccupancyStatusPage} />
        <Route path={urljoin(path, 'orders')} component={FacilityOrdersPage} />
        <Route path={urljoin(path, 'reporting')} component={ReportingPage} />
      </Switch>
    </React.Fragment>
  );
}
