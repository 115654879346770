import React from 'react';
import { TableInstance } from 'react-table';
import { Button } from '../Button';
import { Layout } from '../Layout';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  engine: TableInstance<any>;
  hideControlWhenAtMostOnePage?: boolean;
}

export function PaginationControls(props: Props) {
  const engine = props.engine;

  return !props.hideControlWhenAtMostOnePage ||
    (props.hideControlWhenAtMostOnePage && engine.pageOptions.length <= 1) ? (
    <></>
  ) : (
    <Layout gap={0.3} horizontal align="center">
      <Button onClick={() => engine.gotoPage(0)} disabled={!engine.canPreviousPage}>
        {'<<'}
      </Button>
      <Button onClick={() => engine.previousPage()} disabled={!engine.canPreviousPage}>
        {'<'}
      </Button>
      <Button onClick={() => engine.nextPage()} disabled={!engine.canNextPage}>
        {'>'}
      </Button>
      <Button onClick={() => engine.gotoPage(engine.pageCount - 1)} disabled={!engine.canNextPage}>
        {'>>'}
      </Button>
      <span>
        Page{' '}
        <strong>
          {engine.state.pageIndex + 1} of {engine.pageOptions.length}
        </strong>{' '}
      </span>
    </Layout>
  );
}
