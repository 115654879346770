import * as React from 'react';
import { SVGProps } from 'react';

const SvgRings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="rings_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 392 392"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>{'.rings_svg__st1{fill:currentColor}'}</style>
    <path
      d="m53.7 273.8-.4-.8 3.8-2.3.4.8-3.8 2.3zm-.9-1.4c-23.1-38-28.7-84.2-15.5-126.9l4.2 1.3c-12.8 41.4-7.4 86.3 15 123.2l-3.7 2.4zM39.3 139.7l.2-.8 4.2 1.5-.2.8-4.2-1.5zm.5-1.6C47.9 116 60.6 96.5 77.7 80c15-14.5 32.3-25.6 51.6-33.1l1.6 4.1c-18.7 7.3-35.5 18.1-50 32.1-16.6 16-29 35.1-36.8 56.5l-4.3-1.5zM136.5 49l-1.4-4.2.9-.3 1.4 4.2-.9.3z"
      style={{
        fill: 'currentColor',
        fillOpacity: 0.2,
      }}
    />
    <path
      className="rings_svg__st1"
      d="M66.6 321.5c-7.2-7.5-13.8-15.5-19.5-24l4.3-2.9c5.6 8.3 12 16.1 19 23.4 27.8 28.7 63.4 46.6 102.8 51.7l-.7 5.1c-40.6-5.2-77.3-23.7-105.9-53.3zm-23.4-29.9-.6-.8L47 288l.5.8-4.3 2.8zm-1-1.7C15.6 246.1 9 192.8 24.4 143.7l4.9 1.6c-14.8 47.7-8.4 99.5 17.3 142l-4.4 2.6zm131.4 85 .6-5.1 1 .1-.6 5.1-1-.1zm8.1.9.4-5.2c49.7 3.9 98.8-13.8 134.7-48.4l.3-.3 3.6 3.7-.3.3c-37 35.6-87.5 53.7-138.7 49.9zm-155.2-239 .3-1 4.9 1.8-.3 1-4.9-1.8zm.7-1.9c9.4-25.5 24-48 43.7-67.1 17.3-16.7 37.2-29.5 59.5-38.2l1.8 4.8c-21.5 8.4-41 20.9-57.8 37.1-19 18.5-33.3 40.4-42.4 65.1l-4.8-1.7zm290.5 186.3.7-.7 3.7 3.7-.7.7-3.7-3.7zm5.5-5.6c34-36.4 50.7-85.8 45.9-135.6l5.1-.5c5 51.2-12.3 102.1-47.2 139.6l-3.8-3.5zM138.8 32.1l-1.7-4.9 1-.3 1.7 4.9-1 .3zm1.8-.6-1.6-4.9c48.9-16.2 102.3-10.7 146.6 15l-2.6 4.5c-43-25-95-30.3-142.4-14.6zM369 179l-.1-1 5.2-.6.1 1-5.2.6zm-1.1-7.8c-5.4-36.1-21.6-69.1-47-95.4-9.2-9.5-19.3-17.9-30.3-25l2.8-4.3c11.3 7.3 21.8 16 31.2 25.8 26.2 27.1 43 61.1 48.5 98.2l-5.2.7zm-79-121.5 2.8-4.3.9.5-2.8 4.3-.9-.5z"
    />
    <path
      d="M54.4 333.4c-12-12.4-22.2-26.1-30.4-41.1l9-5.1c7.8 14.1 17.6 27.2 28.9 38.9 22.8 23.6 51.4 40.7 82.6 49.6l-2.8 9.9c-33.1-9.2-63.3-27.4-87.3-52.2zM5.1 244.6C-7.4 194.3.6 140.2 27.1 95.9l8.9 5.4c-25.2 41.9-32.7 93.3-20.9 140.9l-10 2.4zm187.3 148.6.2-10.3c49.7.8 96.8-17.7 132.5-52.2l.3-.3 7.2 7.5-.3.3c-37.9 36.4-87.5 55.9-139.9 55zM66.2 63.2 59 55.7l.1-.1C96.7 19.2 146.3-.4 198.6.4l-.2 10.3C148.9 10 101.9 28.5 66.3 63l-.1.2zm289.2 229.2c25.1-42 32.6-93.3 20.7-140.9l10-2.5c12.6 50.2 4.7 104.4-21.9 148.7l-8.8-5.3zM246.6 17.9l2.8-9.9c33.1 9.4 63.4 27.5 87.5 52.4 11.9 12.3 22.1 26.1 30.4 40.9l-9 5.1c-7.9-14-17.6-27.1-28.8-38.7-22.9-23.8-51.5-40.9-82.9-49.8z"
      style={{
        opacity: 0.5,
        fill: 'currentColor',
      }}
    />
    <path
      className="rings_svg__st1"
      d="M62.6 325.5c-17.6-18.3-31.1-39.5-40-63l-.2-.5 15.5-5.8.2.5c8.1 21.5 20.4 40.8 36.5 57.4 6.8 7.1 14.3 13.6 22.3 19.4l.4.3-9.7 13.3-.4-.3c-8.9-6.4-17.1-13.5-24.6-21.3zm42.1 32.5 8.1-14.4.4.2c28.7 16.1 61.3 23.4 94.3 21.1h.5l1.1 16.5h-.5c-36.3 2.5-72.1-5.5-103.6-23.1l-.3-.3zM16.3 242.3l-.1-.5C7.3 206.6 9 170 21 135.8l.1-.5 15.6 5.4-.1.5c-10.9 31.2-12.4 64.6-4.3 96.6l.1.5-16.1 4zm5.4-105.6C10.1 170.3 8.4 206.5 17 241.1l14.1-3.5c-7.8-31.9-6.4-65.1 4.3-96.2l-13.7-4.7zm207.9 242.1-3.1-16.3.5-.1c32.3-6.1 62-21.5 85.8-44.4l.7-.6 11.5 11.9-.7.6c-26.1 25.2-58.7 42-94.2 48.8l-.5.1zm-2-15.5 2.7 14.2c35.1-6.7 67.3-23.4 93.1-48.3l-10.1-10.4c-23.7 23-53.3 38.3-85.7 44.5zM28.9 116.4l.2-.4C38.6 96.6 51.3 79 67 63.9c10.3-10 21.7-18.7 34-26l.5-.3 8.4 14.2-.5.3c-11.1 6.6-21.5 14.6-30.9 23.7-14.3 13.7-25.9 29.8-34.5 47.4l-.2.4-14.9-7.2zm297 187.4.3-.4c20.9-25.5 33.6-56.3 37-89.3v-.5l16.4 1.7v.5c-3.7 36.1-17.7 70-40.7 98l-.3.4-12.7-10.4zM126.4 43.2l-6.8-15.1.5-.2C153 13.2 189.5 8.5 225.3 14.3l.5.1-2.7 16.4-.5-.1c-32.7-5.3-65.8-.9-95.9 12.5h-.3zm237.7 151.9v-.5c-.5-33.1-10.5-64.9-29-92.2l-.3-.5 13.7-9.3.3.5c20.3 29.9 31.3 64.9 31.8 101.2v.5l-16.5.3zM241.3 34.6l4.5-15.9.5.1c31.2 8.8 59.7 25.9 82.4 49.3 2.4 2.5 4.8 5.1 7.1 7.7l.4.4-12.5 10.9-.4-.4c-2.1-2.4-4.2-4.8-6.4-7.1-20.7-21.4-46.5-36.9-75-45h-.6z"
    />
  </svg>
);

export default SvgRings;
