import React, { useEffect, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import styled from 'styled-components';
import { Text } from '../Text';
import { formInput, FormInput } from './FormInput';
import { SelectOption } from './Select';
import { StyledTextInput } from './TextInput';

interface Props<T> extends FormInput<SelectOption<T>> {
  options: Array<SelectOption<T>>;
  fullWidth?: boolean;
  maxDisplayed?: number;
  onQueryChange?: (event: React.FormEvent | undefined, value: string) => void;
  placeholder?: string;
  invertColours?: boolean;
}

export const TypeaheadSelect = formInput(function <T>(props: Props<T>) {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState(props.defaultValue?.label ?? '');
  const [filteredOptions, setFilteredOptions] = useState(props.options);
  const maxDisplayed = props.maxDisplayed ?? 10;

  useEffect(() => {
    setQuery(props.value?.label ?? '');
  }, [props.value]);

  useEffect(() => {
    setFilteredOptions(props.options.filter((option) => option.label.toLowerCase().includes(query.toLowerCase())));
  }, [query]);

  function onOptionClick(event: React.MouseEvent<HTMLSpanElement, MouseEvent>, option: SelectOption<T>) {
    props.onChange?.(event, option);
    setQuery(option.label);
    setOpen(false);
  }

  async function onQueryChange(event: React.ChangeEvent<HTMLInputElement>) {
    props.onQueryChange?.(event, event.target.value);
    props.control?.setValue(props.name, null);
    setQuery(event.target.value);
    if (!open) {
      setOpen(true);
    }
  }

  return (
    <Popover
      isOpen={open}
      onClickOutside={() => setOpen(false)}
      positions={['bottom', 'top', 'right', 'left']}
      containerStyle={{ overflow: 'hidden', zIndex: '99999' }}
      content={({ childRect: { width: inputFieldWidth } }) => (
        <OptionBoxContainer style={{ width: inputFieldWidth }} invertColours={props.invertColours}>
          {filteredOptions.slice(0, maxDisplayed).map((option, index) => (
            <OptionContainer
              key={index}
              onClick={(event) => onOptionClick(event, option)}
              invertColours={props.invertColours}
            >
              <Text>{option.label}</Text>
            </OptionContainer>
          ))}
        </OptionBoxContainer>
      )}
    >
      <StyledTextInput
        fullWidth={props.fullWidth}
        onFocus={() => setOpen(true)}
        value={query}
        onChange={(event) => onQueryChange(event)}
        autoComplete="off"
        name={props.name}
        placeholder={props.placeholder}
      />
    </Popover>
  );
});

const OptionContainer = styled.div<{ invertColours?: boolean }>`
  padding-left: 0.75rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  &:hover {
    background-color: ${(props) =>
      props.invertColours ? props.theme.dropdown.light.fg : props.theme.dropdown.default.bg};
    color: ${(props) => (props.invertColours ? props.theme.dropdown.light.bg : props.theme.dropdown.default.fg)};
  }
`;

const OptionBoxContainer = styled.div<{ invertColours?: boolean }>`
  color: ${(props) => (props.invertColours ? props.theme.dropdown.default.bg : props.theme.dropdown.light.fg)};
  background-color: ${(props) =>
    props.invertColours ? props.theme.dropdown.default.fg : props.theme.dropdown.light.bg};
`;
