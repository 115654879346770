import itiriri from 'itiriri';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { HSDrug } from 'server-openapi';
import styled from 'styled-components';
import { apis } from '../../../../../core/mrs/apis';
import { Button, Variation } from '../../../../../kit/Button';
import { createDataSource, DataTable } from '../../../../../kit/DataTable/DataTable';
import { Dialog } from '../../../../../kit/Dialog';
import { SelectOption } from '../../../../../kit/Forms/Select';
import { TypeaheadSelect } from '../../../../../kit/Forms/TypeaheadSelect';
import { useAsync } from '../../../../../kit/hooks/UseAsync';
import { IconBin } from '../../../../../kit/Icons/Bin';
import { Intent } from '../../../../../kit/Theme/Theme';
import { toasts } from '../../../../../kit/Toasts/Toaster';
import { useSyncCenter } from '../../../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../../../core/storage/hooks/UseStore';
import { DrugDialogContent } from './DrugDialogContent';
import { ResidentsDialogContent } from './ResidentsDialogContent';
import { TableActionButton } from '../../FacilitySettingsPage';

export interface NimAvailableDrug {
  id: number;
  drug: HSDrug;
  dose?: string;
  route?: string;
  frequency?: string;
  indications?: string;
}

interface IParams {
  facilityGroupId: string;
}

// eslint-disable-next-line max-lines-per-function
export function NimsSettings() {
  const { facilityGroupId } = useParams<IParams>();

  const [existingDrugEntry, setExistingDrugEntry] = useState<NimAvailableDrug>();

  const [selectedDrug, setSelectedDrug] = useState<HSDrug>();

  const [openDrugDialog, setOpenDrugDialog] = useState(false);
  const [openResidentsDialog, setOpenResidentsDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [drugOptions, setDrugOptions] = useState<SelectOption<HSDrug>[]>([]);

  const services = useSyncCenter();
  const drugStore = useStore(services.drugs.store).store;

  const drugs = itiriri(drugStore.values()).toArray();

  const nimAvailableDrugs = useAsync(async () => {
    const nimAvailableDrugsData = (await apis.nimAvailableDrugs.nimAvailableDrugList(parseInt(facilityGroupId))).data;
    setDrugOptions(
      itiriri(drugs)
        .filter((drug) => !nimAvailableDrugsData?.some((d) => d.drugHsId === drug.hsId))
        .sort((a, b) => a.name!.localeCompare(b.name!))
        .toArray()
        .map(
          (drug) =>
            ({ label: `${drug.name} ${drug.formAbbreviation} ${drug.strength}`, value: drug } as SelectOption<HSDrug>),
        ),
    );
    return nimAvailableDrugsData;
  }, []);

  async function onDrugDialogSubmit() {
    setOpenDrugDialog(false);
    setSelectedDrug(undefined);
    setExistingDrugEntry(undefined);

    nimAvailableDrugs.refresh();
  }

  async function onRemoveDrugEntry(id?: number) {
    const response = (
      await apis.nimAvailableDrugs.nimAvailableDrugDelete({
        id: id!,
      })
    ).status;

    if (response === 204) {
      toasts.success('Entry deleted successfully.');
    }

    setOpenConfirmDeleteDialog(false);
    setExistingDrugEntry(undefined);

    nimAvailableDrugs.refresh();
  }

  const tableData =
    nimAvailableDrugs.value?.map((d) => {
      const drug = drugs.find((drug) => drug.hsId === d.drugHsId);

      const nimDrug: NimAvailableDrug = {
        id: d.id!,
        drug: drug!,
        dose: d.dose!,
        route: d.route!,
        frequency: d.frequency!,
        indications: d.indications!,
      };

      return {
        col1: (
          <b>
            {drug?.name} {drug?.formAbbreviation} {drug?.strength}
          </b>
        ),
        col2: d.dose,
        col3: d.route,
        col4: d.frequency,
        col5: d.indications,
        col6: <NimsItemActions nimDrug={nimDrug} />,
      };
    }) ?? [];

  function NimsItemActions(props: { nimDrug: NimAvailableDrug }) {
    const { nimDrug } = props;
    return (
      <ActionContainer>
        <TableActionButton
          variation={Variation.text}
          onClick={() => {
            setExistingDrugEntry(nimDrug);
            setOpenResidentsDialog(true);
          }}
        >
          SEE RESIDENTS
        </TableActionButton>
        <ActionButton
          onClick={() => {
            setExistingDrugEntry(nimDrug);
            setOpenConfirmDeleteDialog(true);
          }}
        >
          <IconBin fill={'#636363'} />
        </ActionButton>
        <ActionButton
          onClick={() => {
            setExistingDrugEntry(nimDrug);
            setOpenDrugDialog(true);
          }}
        >
          &#8942;
        </ActionButton>
      </ActionContainer>
    );
  }

  const dataSource = createDataSource(tableData, () => [
    { accessor: (data) => data.col1, Header: 'NAME', width: '250px' },
    { accessor: (data) => data.col2, Header: 'DOSE', width: '120px' },
    { accessor: (data) => data.col3, Header: 'ROUTE', width: '120px' },
    { accessor: (data) => data.col4, Header: 'FREQUENCY', width: '120px' },
    { accessor: (data) => data.col5, Header: 'INDICATIONS', width: '250px' },
    { accessor: (data) => data.col6, id: 'actions-table-column', width: '250px' },
  ]);

  return (
    <>
      <HeaderContainer>
        <HeaderText>NIMS</HeaderText>
        <AddDrugContainer>
          <DrugLookupContainer>
            <TypeaheadSelect
              name="selectDrug"
              options={drugOptions}
              onChange={(_, v) => setSelectedDrug(v.value)}
              onQueryChange={() => setSelectedDrug(undefined)}
              value={drugOptions.find((option) => option.value === selectedDrug)}
              fullWidth
              placeholder="Search NIM to add..."
              maxDisplayed={15}
            />
          </DrugLookupContainer>
          <AddButton
            onClick={() => {
              setExistingDrugEntry(undefined);
              setOpenDrugDialog(true);
            }}
            disabled={!selectedDrug}
            intent={Intent.Secondary}
          >
            Add
          </AddButton>
        </AddDrugContainer>
      </HeaderContainer>

      <TableContainer>
        <DataTable datasource={dataSource} filters={[]} />
      </TableContainer>

      <Dialog
        open={openDrugDialog}
        onRequestClose={() => setOpenDrugDialog(false)}
        size="full"
        closeButtonColor={'#fff'}
        lazy
      >
        <DrugDialogContent drug={selectedDrug} existingDrugEntry={existingDrugEntry} onSubmit={onDrugDialogSubmit} />
      </Dialog>

      <Dialog
        open={openResidentsDialog}
        onRequestClose={() => setOpenResidentsDialog(false)}
        size="full"
        closeButtonColor={'#fff'}
        lazy
      >
        <ResidentsDialogContent existingDrugEntry={existingDrugEntry!} isDialogOpen={openResidentsDialog} />
      </Dialog>

      <Dialog
        open={openConfirmDeleteDialog}
        onRequestClose={() => setOpenConfirmDeleteDialog(false)}
        size="sm"
        secondary
      >
        <h2>
          Are you sure you want to remove <br />
          {existingDrugEntry?.drug.name} {existingDrugEntry?.drug.formAbbreviation} {existingDrugEntry?.drug.strength}
        </h2>
        <ConfirmDeleteButtonsContainer>
          <Button onClick={() => setOpenConfirmDeleteDialog(false)} intent={Intent.Secondary}>
            CANCEL
          </Button>
          <Button onClick={() => onRemoveDrugEntry(existingDrugEntry?.id)} intent={Intent.Secondary}>
            CONFIRM
          </Button>
        </ConfirmDeleteButtonsContainer>
      </Dialog>
    </>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderText = styled.h2`
  margin: auto 0;
`;

const AddDrugContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const DrugLookupContainer = styled.div`
  width: 400px;
  border-width: 1px;
  border-color: lightgray;
  border-style: solid;
`;

const AddButton = styled(Button)`
  width: 84px;
  display: inline-block;
`;

const TableContainer = styled.div`
  margin-top: 30px;
`;

const ActionButton = styled(Button)`
  padding: 0 5px;
  background: white;

  &:focus {
    outline: none;
  }
`;

const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: auto 30px 30px;
`;

const ConfirmDeleteButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;
