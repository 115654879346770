import { apis } from '../core/mrs/apis';

export class SyncStreamAPI {
  get facilityGroups() {
    return apis.facilityGroups;
  }

  get facilities() {
    return apis.facilities;
  }

  get patients() {
    return apis.patients;
  }

  get drugs() {
    return apis.drugs;
  }

  get drugForms() {
    return apis.drugForms;
  }

  get drugCategories() {
    return apis.drugCategories;
  }

  get packedPatientDays() {
    return apis.packedPatientDays;
  }

  get packedPatientPrnMedications() {
    return apis.packedPatientPrnMedications;
  }

  get rounds() {
    return apis.rounds;
  }

  get administeredDoses() {
    return apis.administeredDoses;
  }

  get administeredDrugs() {
    return apis.administeredDrugs;
  }

  get administeredDrugComments() {
    return apis.administeredDrugComments;
  }

  get administeredDrugOutcomes() {
    return apis.administeredDrugOutcomes;
  }

  get administeredAdHocDrugComments() {
    return apis.administeredAdHocDrugComments;
  }

  get administeredAdHocDrugOutcomes() {
    return apis.administeredAdHocDrugOutcomes;
  }
  get secondCheckSettings() {
    return apis.secondCheckSettings;
  }
  get testResults() {
    return apis.testResults;
  }

  get roundSegments() {
    return apis.roundSegments;
  }

  get orders() {
    return apis.orders;
  }

  get orderComments() {
    return apis.orderComments;
  }

  get patchObservations() {
    return apis.patchObservations;
  }

  get nimAvailableDrugs() {
    return apis.nimAvailableDrugs;
  }

  get administeredAdHocDrugs() {
    return apis.administeredAdHocDrugs;
  }

  get patientProgressNotes() {
    return apis.patientProgressNotes;
  }

  get users() {
    return apis.users;
  }

  get corporates() {
    return apis.corporates;
  }

  get syringeDriverActivities() {
    return apis.syringeDriverActivities;
  }

  get syringeDriverActivityComments() {
    return apis.syringeDriverActivityComments;
  }

  get medicationCharts() {
    return apis.medicationCharts;
  }

  get warningDetails() {
    return apis.WarningDetailsApi;
  }
  get globalSettings() {
    return apis.GlobalSettingsApi;
  }

  get patientMedications() {
    return apis.PatientMedicationApi;
  }

  get patchManagement() {
    return apis.PatchManagementApi;
  }
}
