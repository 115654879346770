import React from 'react';
import styled, { css } from 'styled-components';

export function CircularProgress(props: { children: React.ReactNode; percent: number; size?: number }) {
  return <RadialGradient percent={props.percent}>{props.children}</RadialGradient>;
}

const RadialGradient = styled.div<{ percent: number; size?: number }>`
  ${(p) => {
    const currentDeg = 360 * p.percent;
    const size = p.size ?? 15;

    return css`
      background: conic-gradient(#a4e92a ${currentDeg}deg, rgba(0, 0, 0, 0) ${currentDeg}deg, rgba(0, 0, 0, 0) 360deg);

      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 9999999rem;
      height: ${size}em;
      width: ${size}em;
      margin: auto;

      ::after {
        display: block;
        width: ${size - 2}em;
        position: absolute;
        height: ${size - 2}em;
        background: ${(p) => p.theme.backgrounds.default.bg};
        content: ' ';
        border-radius: 99999em;
        z-index: -1;
      }
    `;
  }}
`;
