/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines */
import { merge } from 'lodash-es';
import { Theme } from './themes';
import { DeepPartial } from '../Utils';

export const colors = {
  bg_white: 'rgb(255, 255, 255)',
  bg_orange_400: 'rgb(255, 153, 118)',
  bg_orange_500: 'rgb(255, 134, 66)',
  bg_orange_600: 'rgb(251, 133, 0)',
  bg_yellow_200: 'rgb(255, 241, 205)',
  bg_yellow_250: 'rgb(255, 210, 0)',
  bg_yellow_300: 'rgb(240, 225, 28)',
  bg_yellow_350: 'rgb(255, 212, 104)',
  bg_blue_600: 'rgb(59, 0, 191)',
  bg_dodger_blue: 'rgb(30, 144, 225)',
  bg_green_200: 'rgb(229, 250, 236)',
  bg_green_400: '#4BE1C5',
  bg_green_500: 'rgb(28, 176, 126)',
  bg_green_600: 'rgb(5, 150, 105)',
  bg_green_700: 'rgb(164, 234, 42)',
  bg_green_900: 'rgb(6, 78, 59)',
  bg_red_500: 'rgb(239, 68, 68)',
  bg_gray_200: 'rgb(245, 245, 245)',
  bg_gray_400: 'rgb(192, 188, 198)',
  bg_gray_600: 'rgb(75, 85, 99)',
  bg_purple_100: 'rgb(251, 244, 255)',
  bg_purple_200: 'rgb(238, 224, 255)',
  bg_purple_300: 'rgb(225, 175, 255)',
  bg_purple_400: 'rgb(160, 50, 225)',
  bg_purple_500: 'rgb(147, 51, 234)',
  bg_purple_600: 'rgb(98, 51, 204)',
  bg_purple_700: 'rgb(81, 53, 119)',
  bg_purple_800: 'rgb(79, 38, 118)',
  bg_purple_900: 'rgba(46, 26, 74, 0.8)',
  bg_pink_200: 'rgb(250, 224, 242)',
  bg_pink_300: 'rgb(245, 193, 228)',
  bg_pink_400: 'rgb(255, 132, 186)',
  bg_pink_500: 'rgb(225, 175, 255)',
  bg_pink_900: 'rgb(131, 24, 67)',
  bg_blue_gray_300: 'rgb(205, 231, 237)',
  bg_blue_gray_600: 'rgb(7, 133, 164)',
  bg_blue_gray_700: 'rgb(69, 75, 122)',
  bg_blue_gray_900: 'rgb(15, 41, 66)',
  text_white: 'rgb(255, 255, 255)',
  text_black: 'rgb(0, 0, 0)',
  text_gray_400: 'rgb(156, 163, 175)',
  text_gray_600: 'rgb(108, 108, 108)',
  text_gray_800: 'rgb(31, 41, 55)',
  text_blue_600: 'rgb(59, 0, 191)',
  text_pink_600: 'rgb(219, 39, 119)',
  none: 'none',
};

const basetheme: Theme = {
  body: {
    background: colors.bg_purple_800,
  },
  backgrounds: {
    lighter: {
      bg: colors.bg_purple_100,
      fg: colors.text_gray_800,
    },
    light: {
      bg: colors.bg_purple_300,
      fg: colors.bg_purple_800,
    },
    default: {
      bg: colors.bg_purple_700,
      fg: colors.text_white,
    },
    dark: {
      bg: colors.none,
      fg: colors.none,
    },
    darker: {
      bg: colors.bg_purple_900,
      fg: colors.none,
    },
  },
  typography: {
    defaults: {
      fontFamily: 'Whitney, sans-serif',
      fontSize: '16px',
      fontWeight: '200',
      color: 'black',
      margin: '0px',
    },
    h1: {
      fontFamily: 'Whitney',
      fontSize: '2rem',
      fontWeight: 'bold',
      color: colors.bg_purple_800,
      margin: '0 0 8px 0',
    },
    h2: {
      fontFamily: 'Whitney',
      fontSize: '1.65rem',
      fontWeight: '400',
      color: colors.bg_purple_800,
      margin: '0 0 4px 0',
    },
    h3: {
      fontFamily: 'Whitney',
      fontSize: '1.25rem',
      fontWeight: '400',
      color: colors.bg_purple_800,
    },
    h4: {
      fontFamily: 'Whitney',
      fontSize: '1.15',
      fontWeight: '400',
      color: colors.bg_purple_800,
    },
    link: {
      color: colors.bg_purple_800,
      underlined: false,
    },
  },
  button: {
    border: {
      radius: '0px', // rounded buttons
      color: 'transparent',
      width: '0',
    },
    primary: {
      fg: colors.text_black,
      bg: colors.bg_green_400,
      border: {
        color: colors.bg_green_400,
      },
    },
    secondary: {
      fg: colors.text_black,
      bg: colors.bg_pink_400,
      border: {
        color: colors.bg_pink_400,
      },
    },
    info: {
      fg: colors.text_black,
      bg: colors.bg_white,
      border: {
        color: colors.text_black,
      },
    },
    success: {
      fg: colors.text_white,
      bg: colors.bg_green_500,
    },
    warning: {
      fg: colors.text_white,
      bg: colors.bg_orange_400,
    },
    danger: {
      fg: colors.text_white,
      bg: colors.bg_red_500,
    },
    disabled: {
      fg: colors.text_gray_400,
      bg: colors.bg_gray_200,
    },
  },
  paper: {
    primary: {
      fg: colors.text_white,
      bg: colors.bg_purple_700,
    },
    secondary: {
      fg: colors.text_black,
      bg: colors.bg_white,
    },
    success: {
      fg: colors.text_white,
      bg: colors.bg_green_400,
    },
    warning: {
      fg: colors.bg_orange_600,
      bg: colors.bg_white,
    },
    danger: {
      fg: colors.text_white,
      bg: colors.bg_red_500,
    },
    info: {
      fg: colors.text_black,
      bg: colors.bg_purple_200,
    },
  },
  table: {
    header: {
      bg: colors.text_white,
      fg: colors.text_black,
      cell: {
        padding: '0.5rem 1rem',
      },
    },
    body: {
      bg: colors.bg_white,
      fg: colors.text_black,
      cell: {
        padding: '1rem',
      },
    },
    border: {
      inner: {
        width: '0.5px',
        color: 'rgba(0, 0, 0, 0.1)',
      },
      outer: {
        radius: '0px',
        width: '0px',
        color: 'rgba(255, 255, 255, 0.3)',
      },
    },
  },
  forms: {
    input: {
      bg: colors.bg_white,
      padding: '0.9rem',
      border: {
        width: '0px',
        radius: '0px',
        color: colors.text_black,
      },
    },
  },
  dialog: {
    primary: {
      fg: colors.text_white,
      bg: colors.bg_purple_700,
      padding: '2rem',
      border: {
        width: '1px',
        radius: '0px',
        color: colors.text_black,
      },
      backdrop: {
        bg: 'rgba(0, 0, 0, 0.3)',
      },
    },
    secondary: {
      fg: colors.text_black,
      bg: colors.bg_purple_300,
      padding: '2rem',
      border: {
        width: '1px',
        radius: '0px',
        color: colors.text_black,
      },
      backdrop: {
        bg: 'rgba(0, 0, 0, 0.3)',
      },
    },
  },
  toast: {
    default: {
      fg: colors.text_black,
      bg: colors.text_white,
    },
    primary: {
      fg: colors.bg_purple_300,
      bg: colors.text_white,
    },
    secondary: {
      fg: colors.bg_purple_200,
      bg: colors.text_white,
    },
    success: {
      fg: colors.text_white,
      bg: colors.bg_green_500,
    },
    warning: {
      fg: colors.text_white,
      bg: colors.bg_orange_400,
    },
    danger: {
      fg: colors.text_white,
      bg: colors.bg_red_500,
    },
    info: {
      fg: colors.text_black,
      bg: colors.text_white,
    },
  },
  appbar: {
    fg: colors.bg_purple_800,
    bg: colors.text_white,
  },
  drawer: {
    fg: colors.bg_gray_600,
    bg: 'white',
    padding: '1rem',
  },
  draweritem: {
    gap: 1,
    padding: '0.8rem 1rem 0.8rem 1rem',
    border: {
      color: 'unset',
      radius: '0px',
      width: '0px',
    },
  },
  dropdown: {
    light: {
      bg: colors.bg_purple_300,
      fg: colors.bg_purple_800,
    },
    default: {
      bg: colors.bg_purple_700,
      fg: colors.text_white,
    },
  },
  medicineColor: {
    cytotoxic: '#A032E1',
    controlledDrug: '#007F00',
    psychotropic: '#FF4C00',
    reproductiveHazardous: '#E50090',
    nonCytotoxic: '#A032E1',
  },
  roundStats: {
    offSite: '#C4C4C4',
    missed: '#FF4C00',
    withheld: '#F3DB01',
    other: '#00151A',
    nostock: '#F3DB01',
    prns: '#FF89BE',
    nims: '#FF89BE',
    charts: '#A032E1',
  },
};

export const adminTheme: Theme = createTheme([
  modifyTheme({
    backgrounds: {
      default: {
        bg: colors.bg_white,
      },
      light: {
        fg: colors.bg_purple_300,
      },
    },
    typography: {
      defaults: {
        color: colors.bg_purple_800,
      },
    },
    button: {
      secondary: {
        fg: colors.text_white,
        bg: colors.bg_purple_800,
        border: {
          color: colors.bg_purple_800,
        },
      },
    },
  }),
]);

export enum Intent {
  Primary = 'primary',
  Secondary = 'secondary',
  Danger = 'danger',
  Success = 'success',
  Warning = 'warning',
  Info = 'info',
}

// Common screen sizes for reference:
// Mobile: 360 x 640
// Mobile: 375 x 667
// Mobile: 360 x 720
// iPhone X: 375 x 812
// Pixel 2: 411 x 731
// Tablet: 768 x 1024
// Laptop: 1366 x 768
// High-res laptop or desktop: 1920 x 1080
export const breakpoints = {
  mobileDown: '@media (max-width: 576px)',
  mobileUp: '@media (min-width: 576px)',
};

export type ThemeModifer = (theme: Theme) => Theme;

export function createTheme(modifiers?: ThemeModifer[]): Theme {
  if (!modifiers) {
    return basetheme;
  }
  return modifiers.reduce((theme, modifier) => modifier(theme), basetheme);
}

export function modifyTheme(overrides: DeepPartial<Theme>): ThemeModifer {
  return (theme: Theme) => merge({}, theme, overrides);
}

export interface Pallet {
  primary: {
    fg: string;
    bg: string;
  };
  secondary: {
    fg: string;
    bg: string;
  };
}

export function pallet(pallet: Pallet) {
  return modifyTheme({
    button: {
      primary: {
        fg: pallet.primary.fg,
        bg: pallet.primary.bg,
        border: {
          color: pallet.primary.bg,
        },
      },
      secondary: {
        fg: pallet.secondary.fg,
        bg: pallet.secondary.bg,
        border: {
          color: pallet.secondary.bg,
        },
      },
    },
    table: {
      header: {
        fg: pallet.secondary.fg,
        bg: pallet.secondary.bg,
      },
    },
    drawer: {
      bg: pallet.secondary.bg,
      fg: pallet.secondary.fg,
    },
    paper: {
      primary: {
        fg: pallet.primary.fg,
        bg: pallet.primary.bg,
      },
      secondary: {
        fg: pallet.secondary.fg,
        bg: pallet.secondary.bg,
      },
    },
  });
}

export interface Edges {
  mode: 'smooth' | 'round' | 'hard';
}

export function edges(edges: Edges) {
  const radius = (max?: number) => {
    switch (edges.mode) {
      case 'hard':
        return '0px';
      case 'smooth':
        return '8px';
      case 'round':
        return `${max ?? 9999}px`;
    }
  };

  return modifyTheme({
    button: {
      border: {
        radius: radius(),
      },
    },
    table: {
      border: {
        outer: {
          radius: radius(12),
        },
      },
    },
    draweritem: {
      border: {
        radius: radius(),
      },
    },
    forms: {
      input: {
        border: {
          radius: radius(),
        },
      },
    },
  });
}

export function denseDrawer() {
  return modifyTheme({
    drawer: {
      padding: '0',
    },
    draweritem: {
      gap: 0,
      padding: '1rem',
    },
  });
}

export function borderlessTable() {
  return modifyTheme({
    table: {
      border: {
        inner: {
          width: '0px',
        },
        outer: {
          width: '0px',
        },
      },
    },
  });
}

export function outlinedTable(props: { size: number; color: string; radius?: number }) {
  return modifyTheme({
    table: {
      border: {
        outer: {
          width: `${props.size}px`,
          color: props.color,
          radius: (() => {
            if (props.radius !== undefined) {
              return `${props.radius}px`;
            }
          })(),
        },
      },
    },
  });
}

export function tablePadding(padding: string) {
  return modifyTheme({
    table: {
      header: {
        cell: {
          padding: padding,
        },
      },
      body: {
        cell: {
          padding: padding,
        },
      },
    },
  });
}
