import { HSDrugForm } from 'server-openapi';

export const DrugFormUtils = {
  // workaround to get the list of all available route types since SyncStream doesn't provide this in the API yet
  getRouteTypes: (drugForms: HSDrugForm[]) => {
    return drugForms
      .map((df) => df.routeTypes ?? [])
      .flat()
      .filter((rt, index, self) => index === self.findIndex((s) => s.code === rt.code));
  },
};
