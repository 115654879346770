import React from 'react';
import { Grid } from '../Grid';
import { RadioButton } from '../RadioButton';
import { Intent } from '../Theme/Theme';
import { FormInput, formInput } from './FormInput';
import { SelectOption } from './Select';

interface Props<T> extends Omit<FormInput<T>, 'value' | 'onChange'> {
  options: SelectOption<T>[];
  cols?: number;
  intent?: Intent;
  buttonBackgroundColor?: string;
  selectValues?: Array<T>;
  onChange?: OnCheckboxChange<T>;
}

export type OnCheckboxChange<T> = (event: React.FormEvent | undefined, checked: boolean, value: T) => void;

export const MultiSelect = formInput(function <T>(props: Props<T>) {
  return (
    <Grid gap={1} cols={props.cols}>
      {props.options.map((opt, i) => (
        <RadioButton
          key={i}
          id={`${props.name}-${i}`}
          type="checkbox"
          name={props.name}
          checked={props.selectValues?.includes(opt.value)}
          value={i}
          intent={props.intent}
          disabled={props.disabled}
          label={opt.label}
          buttonBackgroundColor={props.buttonBackgroundColor}
          onChange={(event) => {
            props.onChange?.(event, event.currentTarget.checked, opt.value);
          }}
        />
      ))}
    </Grid>
  );
});
