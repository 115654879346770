import React from 'react';
import { format } from 'date-fns';
import { OrderResupplyAction, OrderResupplyProps } from './OrderResupplyDialog';
import styled from 'styled-components';
import { OrderNotesProps } from './OrderNotesDialog';
import { VwOrder } from './OrderViewModel';
import { HSFacility, HSPatient } from 'server-openapi';
import { Order, OrderState, GetOrderState } from '../../../../common/Order';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { Button } from '../../../../kit/Button';
import { IconNote } from '../../../../kit/Icons/Note';
import { Text } from '../../../../kit/Text';
import { Image } from '../../../../kit/Image';
import { Layout } from '../../../../kit/Layout';
import { MedicationInfoSection } from '../../../ResidentDetails/components/medicationInformation/MedicationInfoSection';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import { toasts } from '../../../../kit/Toasts/Toaster';
import { useHealthCheck } from '../../../../core/healthcheck/HealthCheckProvider';
import {useGroupPermissions} from "../../../../core/authz/PermissionsProvider";

export interface OrderRowProps {
  facilityGroupId: number;
  vwOrder: VwOrder;
  order?: Order;
  hsPatientId?: number;
  hsMedicationId?: number;
  includeDrug?: boolean;
  openOrderNotes?: (orderNotes: OrderNotesProps) => void;
  openOrderResupply?: (orderResupply: OrderResupplyProps) => void;
}

const dateFormat = 'dd MMM yyyy';

function hasOrderAction(orderAction: OrderResupplyAction, orderState?: OrderState) {
  return (
    (orderAction === OrderResupplyAction.Create &&
      orderState !== OrderState.OnOrder &&
      orderState !== OrderState.Pending) ||
    (orderAction === OrderResupplyAction.Cancel &&
      orderState !== OrderState.Completed &&
      orderState !== OrderState.Cancelled)
  );
}

function getActionString(orderAction: OrderResupplyAction) {
  return orderAction === OrderResupplyAction.Cancel ? 'CANCEL' : 'RESUPPLY';
}

export function OrderRow(props: OrderRowProps) {
  const order = props.vwOrder;
  const hsOrder = props.order;
  const orderState = hsOrder && GetOrderState(hsOrder);
  const hasComments = hsOrder?.update.pendingComments || hsOrder?.comments;
  const groupPermissions = useGroupPermissions();
  const canOrderResuppliesOfMedication = groupPermissions.canOrderResuppliesOfMedication;
  const isServerHealthy = useHealthCheck().isHealthy;

  const showOrderAction = hasOrderAction(order.action, orderState);
  const actionString = getActionString(order.action);

  return (
    <InformationBox horizontal key={order.key}>
      {order.patient && (
        <PatientInformationColumn
          facilityGroupId={props.facilityGroupId}
          facility={order.facility}
          patient={order.patient}
        />
      )}
      {(props.includeDrug ?? true) && order.drug && (
        <InformationColumn>
          <MedicationInfoSection
            drug={order.drug}
            route={order.route}
            directions={order.directions}
            administrationWarnings={order.administrationWarnings}
            facilityGroupId={props.facilityGroupId}
          />
        </InformationColumn>
      )}
      <InformationColumn>
        <RecentOrderDetail hsOrder={hsOrder} />
      </InformationColumn>
      <InformationColumn>
        {hsOrder && hasComments && (
          <IconNote
            data-testid="note-icon"
            onClick={() =>
              props.openOrderNotes &&
              props.openOrderNotes({
                order: hsOrder,
                drug: order.drug,
                route: order.route,
                directions: order.directions,
                administrationWarnings: order.administrationWarnings,
                facilityGroupId: props.facilityGroupId,
              })
            }
          />
        )}
      </InformationColumn>
      <ActionColumn gap={0.5}>
        {hsOrder && <OrderStateDetail order={hsOrder} dispatchedAt={DateUtils.ensureDate(hsOrder?.dispatchedAt)} />}
        {showOrderAction && (
          <StyledButton
            onClick={() => {
              if (!canOrderResuppliesOfMedication) {
                toasts.error('You do not have permission to order resupplies.');
                return;
              }
              if (!isServerHealthy) {
                toasts.error('You must be online to order resupplies.');
                return;
              }
              props.openOrderResupply!({
                orderId: hsOrder?.id,
                drug: order.drug,
                route: order.route,
                directions: order.directions,
                administrationWarnings: order.administrationWarnings,
                action: order.action,
                hsPatientId: props.hsPatientId,
                hsMedicationId: props.hsMedicationId,
                facilityGroupId: props.facilityGroupId,
              });
            }}
            disabled={!props.openOrderResupply}
          >
            {actionString}
          </StyledButton>
        )}
      </ActionColumn>
    </InformationBox>
  );
}

function RecentOrderDetail(props: { hsOrder?: Order }) {
  const userUtils = useApiUtils().users;
  return props.hsOrder ? (
    <>
      <Text weight={'bold'}>{DateUtils.dateTo24HourTimeString(DateUtils.ensureDate(props.hsOrder.orderedAt)!)}</Text>
      <Text>{format(DateUtils.ensureDate(props.hsOrder.orderedAt)!, 'dd MMM yyyy')}</Text>
      <Text weight={'bold'}>Ordered by</Text>
      <Text>{userUtils.getUserFullNameAndRoleFromSubjectId(props.hsOrder.orderedBySubjectId)}</Text>
    </>
  ) : (
    <Text>No recent order</Text>
  );
}

function OrderStateDetail(props: { order: Order; dispatchedAt?: Date }) {
  const orderState = GetOrderState(props.order);
  const userUtils = useApiUtils().users;
  if (orderState === OrderState.Completed) {
    return (
      <>
        <Text weight={'bold'}>COMPLETED</Text>
        <Text>{props.dispatchedAt ? format(props.dispatchedAt, dateFormat) : ''}</Text>
      </>
    );
  } else if (orderState === OrderState.Cancelled) {
    //get the cancelled comment for cancellation time
    const cancelledComment = checkForCancelledOrderComment(props.order);
    return cancelledComment ? (
      <InformationColumn>
        <Text weight={'bold'}>{DateUtils.dateTo24HourTimeString(DateUtils.ensureDate(cancelledComment.commentDate)!)}</Text>
        <Text>{format(DateUtils.ensureDate(cancelledComment.commentDate)!, dateFormat)}</Text>
        <Text weight={'bold'}>Cancelled by</Text>
        <Text>{userUtils.getUserFullNameAndRoleFromSubjectId(cancelledComment.commentBySubjectId)}</Text>
      </InformationColumn>
    ) : (
      <Text weight={'bold'}>CANCELLED</Text>
    );
  } else {
    return <Text>{orderState}</Text>;
  }
}

/**
 * This function works under the assumption that the order object which has been cancelled contains the
 * cancellation comment in either the update property (if not synced up yet) or as part of its comment
 * @param order order object
 * @returns order comment containing the cancellation details
 */
function checkForCancelledOrderComment(order: Order) {
  return [...(order.comments ?? []), ...(order.update.pendingComments ?? [])].find((comment) =>
    comment.comment.includes('Order cancelled'),
  );
}

function PatientInformationColumn(props: { facilityGroupId: number; facility?: HSFacility; patient: HSPatient }) {
  const patientUtils = useApiUtils().patients;
  return (
    <InformationColumn>
      <Layout horizontal gap={1} style={{ alignItems: 'center' }}>
        {props.patient.imageUrl && (
          <PatientImage src={props.patient.imageUrl} facilityGroupId={props.facilityGroupId} />
        )}
        <Layout>
          <Text weight={'bold'}>{patientUtils.getDisplayPatientName(props.patient)}</Text>
          <Text>
            {props.facility?.name ?? ''} Rm {props.patient.roomNumber},
          </Text>
        </Layout>
      </Layout>
    </InformationColumn>
  );
}

const PatientImage = styled(Image)`
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 999999999px;
  align-self: center;
`;

const InformationBox = styled(Layout)`
  background: white;
  color: black;
  display: flex;
  align-items: center;
  padding: 0.875rem;
  margin: .5em auto;
  width: 100%;
  border-radius: 2px;
`;

const InformationColumn = styled(Layout)`
  flex-basis: 100%;
  padding-right: 20px;
`;

const ActionColumn = styled(Layout)`
  flex-basis: 100%;
  padding-right: 20px;
`;

const StyledButton = styled(Button)`
  width: 120px;
  height: 20px;
`;
