import * as React from 'react';
import { HSPatient, MedicationType, HSFacility, SyringeDriverActivityKind } from 'server-openapi';
import styled from 'styled-components';
import { Text } from '../../../../kit/Text';
import { Image } from '../../../../kit/Image';
import { IconControlledDrug } from '../../../../kit/Icons/DrugIcons/ControlledDrug';
import itiriri from 'itiriri';
import { DateUtils, Interval } from '../../../../core/utils/dateUtils';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import { PatientUtils } from '../../../../syncstream/utils/PatientUtils';
import { ResidentDetailsUtils } from '../../../../syncstream/utils/ResidentDetailsUtils';
import { IconTimeCriticalDrug } from '../../../../kit/Icons/DrugIcons/TimeCriticalDrug';
import { FilterDrugType } from '../../../Dashboard/DashboardPage/DashboardPage';
import { RoundUtils } from '../../../../syncstream/utils/RoundUtils';
import {IconInsulinDrug} from "../../../../kit/Icons/DrugIcons/InsulinDrug";

interface IProps {
  patient: HSPatient;
  facilityStore: ReadonlyMap<string, HSFacility>;
  drugType?: FilterDrugType;
  interval: Interval;
}

export interface IResidentEntry {
  patient: HSPatient;
  facility: HSFacility;
  hasControlledDrugInRound: boolean;
  hasTimeCriticalDrugInRound: boolean;
  medicationTimings: Set<string>;
}

export function ResidentRow(props: IProps) {
  const apiUtils = useApiUtils();
  const { patient, facilityStore, interval } = props;
  const facility = facilityStore.get(patient.facility!.toString());

  if (!facility) {
    throw new Error('facility not found');
  }

  const residentEntry = GetResidentEntry(
      apiUtils.rounds,
      apiUtils.patients,
      apiUtils.residentDetails,
      facility,
      interval,
      patient,
      props.drugType,
  );

  return (
      <ResidentContainer>
        <ResidentThumbnail src={patient.imageUrl!} facilityGroupId={facility!.facilityGroupId!} />
        <div>
          <Text weight={'bold'}>{apiUtils.patients.getDisplayPatientName(patient)}</Text>{' '}
          {residentEntry.hasControlledDrugInRound && <ControlledDrugWarning />}
          {residentEntry.hasTimeCriticalDrugInRound && <TimeCriticalDrugWarning />}
        </div>
        <Text>{`${facility!.name} Rm ${patient.roomNumber}`}</Text>
        <DoseTimingsList>
          {Array.from(residentEntry.medicationTimings.values()).map((doseTiming, index) => {
            return <Text key={index}>{doseTiming}</Text>;
          })}
        </DoseTimingsList>
      </ResidentContainer>
  );
}

export function GetResidentEntry(
    roundUtils: RoundUtils,
    patientUtils: PatientUtils,
    ResidentDetailsUtils: ResidentDetailsUtils,
    facility: HSFacility,
    interval: Interval,
    patient: HSPatient,
    drugType?: FilterDrugType,
): IResidentEntry {
  // don't show the CD icon if there are no controlled drugs in round
  const hasControlledDrugInRound =
      drugType !== FilterDrugType.NonControlled &&
      roundUtils.getPackedMedicationsForPatient(true, patient.hsId!, interval).length !== 0;

  const hasTimeCriticalDrugInRound =
      roundUtils.getTimeCriticalMedicationsForPatient(patient.hsId!, interval).length !== 0;

  const medicationTimings = GetMedicationTimings(
      patient,
      patientUtils,
      ResidentDetailsUtils,
      roundUtils,
      interval,
      drugType,
  );

  return {
    patient,
    facility: facility!,
    hasControlledDrugInRound,
    hasTimeCriticalDrugInRound,
    medicationTimings,
  };
}

export function ControlledDrugWarning(): JSX.Element {
  return (
      <DrugWarningContainer>
        <IconControlledDrug data-testid="drug-warning-icon" width={20} xlinkTitle={`Controlled Drug`} />
      </DrugWarningContainer>
  );
}

export function TimeCriticalDrugWarning(): JSX.Element {
  return (
      <TimeCriticalDrugWarningContainer>
        <IconTimeCriticalDrug data-testid="drug-warning-icon" />
      </TimeCriticalDrugWarningContainer>
  );
}
export function InsulinDrugWarning(): JSX.Element {
  return (
      <InsulinWarningContainer>
        <IconInsulinDrug data-testid="insuilin-warning-icon" />
      </InsulinWarningContainer>
  );
}

function GetMedicationTimings(
    patient: HSPatient,
    patientUtils: PatientUtils,
    residentDetailsUtils: ResidentDetailsUtils,
    roundUtils: RoundUtils,
    interval: Interval,
    drugType?: FilterDrugType,
) {
  const packedPatientDay = patientUtils.findPackedPatientDay(patient.hsId!, new Date(), patient.facility);

  // filter packed medications by drug type selection
  const filteredPackedMedications = roundUtils.getFilteredMedicationsByDrugType(
      packedPatientDay?.packedMedications ?? [],
      drugType,
  );

  const regularContinuousMedicationWithinInterval =
      filteredPackedMedications &&
      residentDetailsUtils
          .getGroupedMedications(
              filteredPackedMedications,
              patient.patientProfiles!,
              new Date(),
              patient.hsId!,
              patient.facility!,
          )
          .filter(
              (packedMedicationList) =>
                  packedMedicationList.scheduledActivityAction?.activity.kind === SyringeDriverActivityKind.Start ||
                  (packedMedicationList.categories.medicationType !== MedicationType.Prn &&
                      DateUtils.isTimeBetween(DateUtils.fromDate(packedMedicationList.categories.doseDate!), interval)),
          );

  return new Set<string>(
      regularContinuousMedicationWithinInterval
          ?.map((packedMedicationList) => DateUtils.dateTo24HourTimeString(packedMedicationList.categories.doseDate!))
          .sort(),
  );
}

const ResidentContainer = styled.div.attrs(() => ({ [`data-testid`]: 'resident-container' }))`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: min-content 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-auto-flow: row;
  align-items: center;
  border-bottom: solid 1px ${(props) => props.theme.button.secondary.bg};
  padding: 0.75em 0em;
`;

export const ResidentThumbnail = styled(Image)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const DrugWarningContainer = styled.div`
  display: inline;
`;

const TimeCriticalDrugWarningContainer = styled.div`
  display: inline;
  padding-left: 3px;
`;
const InsulinWarningContainer = styled.div`
  display: inline;
  padding-left: 3px;
`;

export const DoseTimingsList = styled.div<{ justifyLeft?: boolean }>`
  display: flex;
  justify-content: ${(p) => (p.justifyLeft ? 'flex-start' : 'flex-end')};
  gap: 10px;
`;