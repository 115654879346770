import { PlacesType, Tooltip } from 'react-tooltip';
import { appconfig } from '../../appconfig';
import React, { useEffect, useState } from 'react';
import { stringifyMaxDepth } from './stringify';

export function DebugHover(props: any, borderColor?: string, tooltipPlace? : PlacesType, depth? : number  ){
    if (appconfig.ENVIRONMENT !== 'development') return props.children; // only run this in Development environment

    const [componentDepth, setComponentDepth] = useState(depth);
    const [showDebugMessages, setShowDebugMessages] = useState(false);
    const [debugMessageStyle, setDebugMessageStyle] = useState({});
    const [debugMessageFloat, setDebugMessageFloat] = useState(false);
    const [isMouseOver, setIsMouseOver] = useState(false);
    const [tooltipPlacePosition, setTooltipPlacePosition] = useState(tooltipPlace);
    const [borderColorSetting, setBorderColorSetting] = useState(borderColor);

    const Places = ['top', 'top-start', 'top-end', 'right', 'right-start', 'right-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end'];

    // Neon colours from https://codepen.io/argyleink/pen/MWymQGj
    const Colors = ['#FF5161', '#FF53cd', '#9461fd', '#2dd9fe', '#00fe9b', '#ffdb4e'];

    const getRandomElement = (arr: any[]) =>
        arr.length ? arr[Math.floor(Math.random() * arr.length)] : undefined

    if (tooltipPlacePosition?.length=== undefined) {
        setTooltipPlacePosition(getRandomElement(Places));
    }

    if (componentDepth === undefined) {
        setComponentDepth(1);
    }

    if (borderColorSetting?.length === undefined) {
        setBorderColorSetting(getRandomElement(Colors));
    }

    const keyControlDownHandler = (e: KeyboardEvent) => {
        if (e.ctrlKey && e.altKey) {
            e.preventDefault();
            e.stopPropagation();
            if (isMouseOver) setShowDebugMessages(true);
            setDebugMessageStyle({boxShadow: '0 0 '+componentDepth+'px '+componentDepth+'px ' + getRandomElement(Colors) +', inset 0 0 0 '+ componentDepth +'px ' + getRandomElement(Colors)});
        }
    };

    const keyControlUpHandler = (e: KeyboardEvent) => {
        if (e.key === 'Control' || e.key === 'Alt') {
            e.preventDefault();
            e.stopPropagation();
            setShowDebugMessages(false);
            setDebugMessageStyle({});
        }
    };

    useEffect(() => {
        window.addEventListener('keydown',keyControlDownHandler)
        return () => {
            window.removeEventListener("keydown", keyControlDownHandler);
        };
    });

    useEffect(() => {
        window.addEventListener('keyup',keyControlUpHandler)
        return () => {
            window.removeEventListener("keyup", keyControlUpHandler);
        };
    });

    return(
        <>
                <Tooltip id={props.name}
                         key={props.name+'Tooltip'}
                         hidden={!showDebugMessages}
                         float={debugMessageFloat}
                         place={tooltipPlacePosition}
                         //content={props.name}
                         html={props.content && props.name + "<BR>" + stringifyMaxDepth(props.content,2)}
                         offset={5}/>
                <div data-tooltip-id={props.name}
                     style={debugMessageStyle}
                     onClick={(e) => {setTooltipPlacePosition(getRandomElement(Places))}}
                     onMouseEnter={() => setIsMouseOver(true)}
                     onMouseLeave={() => setIsMouseOver(false)}
                >
                {props.children}
            </div>
        </>
);
}

