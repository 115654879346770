import * as React from 'react';
import { SVGProps } from 'react';
import { Tooltip } from 'react-tooltip';

export const TickInsideGreenCircle = (props: SVGProps<SVGSVGElement>) => (
    <>
        <Tooltip id="TickInsideGreenCircle" />
        <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            version="1.1"
            id="svg4646"
             width={props.width}
             style={props.style}
             data-tooltip-content={props.xlinkTitle}
             data-tooltip-id="TickInsideGreenCircle"
             data-tooltip-place="top">
            <defs
                id="defs4638">
                <linearGradient
                    id="0"
                    gradientUnits="userSpaceOnUse"
                    y1="47.85"
                    x2="0"
                    y2="-2.292">
                    <stop
                        stopColor="#4da70e"
                        id="stop4633" />
                    <stop
                        offset="1"
                        stopColor="#9ded46"
                        id="stop4635" />
                </linearGradient>
            </defs>
            <g
                transform="matrix(.92307 0 0 .92307-24.891 2.712)"
                enableBackground="new"
                id="g4644"
                style={{fillOpacity:1, opacity:1 ,fillRule:'nonzero'}}>
                <circle
                    r="26"
                    cy="23.07"
                    cx="52.97"
                    fill="url(#0)"
                    id="circle4640"
                    style={{fillOpacity:1, fillRule:`nonzero`, fill:`#55d400`}} />
                <path
                    d="m66.804 16.712l-2.518-2.518c-.345-.346-.766-.518-1.259-.518-.494 0-.914.173-1.259.518l-12.148 12.167-5.444-5.463c-.346-.346-.766-.518-1.259-.518-.494 0-.914.173-1.259.518l-2.518 2.518c-.346.346-.518.766-.518 1.259 0 .494.173.914.518 1.259l6.704 6.704 2.519 2.518c.345.346.765.518 1.259.518.494 0 .914-.173 1.259-.518l2.519-2.518 13.407-13.407c.345-.346.518-.766.518-1.259 0-.494-.173-.914-.518-1.259"
                    fill="#fff"
                    fillOpacity=".851"
                    id="path4642"
                    style={{fillOpacity:1, fillRule: `nonzero` }} />
            </g>
        </svg>
    </>
);

export default TickInsideGreenCircle;
