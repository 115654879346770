/* eslint-disable max-lines-per-function */
import { isToday, startOfDay } from 'date-fns';
import itiriri from 'itiriri';
import React from 'react';
import { Link } from 'react-router-dom';
import {
    HSDoseRound,
    HSFacility,
    HSFacilityGroup,
    HSPatient,
    HSPatientProfile,
    MedicationType,
    ReasonCode,
} from 'server-openapi';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { NavigationBar } from '../../../../components/NavigationBar/NavigationBar';
import { useCurrentUser } from '../../../../core/authn/UserProvider';
import { useStore } from '../../../../core/storage/hooks/UseStore';
import { DateUtils, Interval } from '../../../../core/utils/dateUtils';
import { Grid } from '../../../../kit/Grid';
import { useSyncCenter } from '../../../../syncstream/SyncCenterProvider';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import { getChartReviews } from '../../ChartListPage';
import { RoundScheduleItem } from '../../../../syncstream/utils/RoundUtils';
import { useGetScheduleFromParameters } from "../../../../syncstream/utils/hooks/GetSchedule";
import {NavigationBarMenu} from "../../../../components/NavigationBar/NavigationBarMenu";

interface IProps {
  facilityGroup?: HSFacilityGroup;
  nextDoseRound?: Date;
}

const RoundTimeDisplay = styled.span`
  font-weight: bold;
  justify-content: right;
  align-items: center;
  display: flex;
  margin-left: 15px;
  flex-grow: 1;
  color: red;
`;

export function DashboardNavbar(props: IProps) {
  const theme = useTheme();
  const { facilityGroup } = props;
  const user = useCurrentUser();
  const userUtils = useApiUtils().users;

  let nextRoundText = '';

  if (props.nextDoseRound) {
    const timeStringLabel = ' Next Round ';
    const timeString = timeStringLabel + DateUtils.dateTo24HourTimeString(props.nextDoseRound);
    nextRoundText = timeString;
  }

  const userFullNameAndRole = userUtils.getUserFullNameAndRoleFromSubjectId(user.profile.sub);

  return (
    <NavigationBar
      nodes={() => ([
          <h1>
            {facilityGroup?.name} - {userFullNameAndRole ? userFullNameAndRole : 'Loading...'}
          </h1>,
          !!props.facilityGroup ? <NavigationBarMenu facilityGroup={props.facilityGroup}/> : <span />,
          <DashboardNavbarLinks theme={theme} facilityGroup={facilityGroup!} />,
          <RoundTimeDisplay>{nextRoundText}</RoundTimeDisplay>,
      ])}
    />
  );
}

function DashboardNavbarLinks(props: { theme: DefaultTheme; facilityGroup: HSFacilityGroup }) {
  const services = useSyncCenter();
  const apiUtils = useApiUtils();
  const roundUtils = useApiUtils().rounds;
  const facilitiesStore = useStore(services.facilities.store).store;
  const roundStore = useStore(services.rounds.store).store;
  const getScheduleFromParameters = useGetScheduleFromParameters();

  const rounds = itiriri(roundStore.values()).toArray();

  const facilities = itiriri(facilitiesStore.values())
    .filter((f) => f.facilityGroupId === props.facilityGroup?.hsId)
    .toArray();
  const facilityIds = facilities.map((f) => f.hsId!);

  const patients = apiUtils.patients
    .getActivePatients()
    .filter((p) => (p.facility !== undefined ? facilityIds.includes(p.facility) : false))
    .toArray();

  const offsiteCount = patients.filter((p) => p.suspended).length;

  const recentMedicationDoses = getAdministeredMedicationForToday(rounds, facilities, patients);

  const chartsCount = getChartReviews(facilitiesStore, patients, apiUtils.patients, '').length;

  // Get refused count for non ad hoc administrations
  const refusedCount = recentMedicationDoses.administeredDrugData.filter(
    (a) => a.administration.reasonCode === ReasonCode.Refused,
  ).length;

  // Get withheld count for non ad hoc administrations
  const withheldCount = recentMedicationDoses.administeredDrugData.filter(
    (a) => a.administration.reasonCode === ReasonCode.Withheld,
  ).length;

  const otherCount = recentMedicationDoses.administeredDrugData.filter(
    (a) => a.administration.reasonCode === ReasonCode.Other,
  ).length;

    // Get No Stock count for non ad hoc administrations
    const noStockCount = recentMedicationDoses.administeredDrugData.filter(
        (a) => a.administration.reasonCode === ReasonCode.NoStock,
    ).length;

  // Get count administered prn drugs
  const prnsCount = recentMedicationDoses.administeredDrugData.filter(
    (a) => a.medication?.medicationType === MedicationType.Prn,
  ).length;

  // Get administered ad hoc drug count
  const nimsCount = recentMedicationDoses.administeredAdHocDrugData.length;

  const roundWindow = apiUtils.rounds.getRoundWindow(new Date(), props.facilityGroup.hsId ?? 0);
  const startOfToday = startOfDay(new Date());
  const newRoundWindow: Interval = {
    start: DateUtils.fromDate(startOfToday),
    end: roundWindow.start,
  };

  const roundDetails: RoundScheduleItem[] = getScheduleFromParameters(
    newRoundWindow,
    facilities!.map((x) => x!.hsId!),
  );
  const activeRounds = roundUtils.getActiveRounds(
    new Date(),
    roundUtils.getIdsOfFacilitiesInFacilityGroup(props.facilityGroup?.hsId),
  );
  const inactiveScheduleItems = apiUtils.rounds.inactiveScheduleItems(activeRounds, roundDetails);
  const patientsWithMissedDoses = Array.from(new Set(inactiveScheduleItems.map((x) => x.patient))).length;

  return (
    <Grid cols={9} colsMobile={3} gap={1}>
      <Link
        to={`/facility-group/${props.facilityGroup.hsId}/dashboard-lists/missed-doses`}
        style={{ textDecoration: 'none' }}
      >
        <StatusIndicator color={props.theme.roundStats.missed} count={patientsWithMissedDoses} label="Missed" />
      </Link>
      <Link
        to={`/facility-group/${props.facilityGroup.hsId}/dashboard-lists/off-site`}
        style={{ textDecoration: 'none' }}
      >
        <StatusIndicator color={props.theme.roundStats.offSite} count={offsiteCount} label="Off-Site" />
      </Link>
      <Link
        to={`/facility-group/${props.facilityGroup.hsId}/dashboard-lists/medication-list/refused`}
        style={{ textDecoration: 'none' }}
      >
        <StatusIndicator color={props.theme.roundStats.missed} count={refusedCount} label="Refused" />
      </Link>
      <Link
        to={`/facility-group/${props.facilityGroup.hsId}/dashboard-lists/medication-list/withheld`}
        style={{ textDecoration: 'none' }}
      >
        <StatusIndicator color={props.theme.roundStats.withheld} count={withheldCount} label="Withheld" />
      </Link>
      <Link to={`/facility-group/${props.facilityGroup.hsId}/dashboard-lists/medication-list/nostock`}
            style={{ textDecoration: 'none' }}>
          <StatusIndicator color={props.theme.roundStats.nostock} count={noStockCount} label="No Stock" />
      </Link>
      <Link
        to={`/facility-group/${props.facilityGroup.hsId}/dashboard-lists/medication-list/other`}
        style={{ textDecoration: 'none' }}>
        <StatusIndicator color={props.theme.roundStats.other} count={otherCount} label="Other" />
      </Link>
      <Link
        to={`/facility-group/${props.facilityGroup.hsId}/dashboard-lists/medication-list/prns`}
        style={{ textDecoration: 'none' }}
      >
        <StatusIndicator
          color={props.theme.roundStats.prns}
          count={prnsCount}
          label="PRNS"
          count-data-testid="prns-count"
        />
      </Link>
      <Link
        to={`/facility-group/${props.facilityGroup.hsId}/dashboard-lists/medication-list/nims`}
        style={{ textDecoration: 'none' }}
      >
        <StatusIndicator
          color={props.theme.roundStats.nims}
          count={nimsCount}
          label="NIMS"
          count-data-testid="nims-count"
        />
      </Link>
      <Link
        to={`/facility-group/${props.facilityGroup.hsId}/dashboard-lists/charts`}
        style={{ textDecoration: 'none' }}
      >
        <StatusIndicator color={props.theme.roundStats.charts} count={chartsCount} label="Charts" />
      </Link>
    </Grid>
  );
}

const StatusContainer = styled.div`
  text-align: center;
`;

const StatusText = styled.span<{ color: string }>`
  color: ${(p) => p.color};
`;

function StatusIndicator(props: { color: string; count: number; label: string; 'count-data-testid'?: string }) {
  return (
    <StatusContainer>
      <h2>
        <StatusText data-testid={props['count-data-testid']} color={props.color}>
          {props.count}
        </StatusText>
      </h2>
      <p style={{ textTransform: 'uppercase' }}>{props.label}</p>
    </StatusContainer>
  );
}

export function getAdministeredMedicationForToday(
  rounds: HSDoseRound[],
  facilities: HSFacility[],
  patients: HSPatient[],
) {
  const administeredDoses = rounds.flatMap((round) =>
    (round.administeredDoses ?? []).filter((ad) => !!facilities.find((f) => f.hsId === ad.facilityId)),
  );
  const administeredDrugs = administeredDoses.flatMap((a) => a.administeredDrugs ?? []);
  const administeredAdHocDrugs = administeredDoses.flatMap((a) => a.administeredAdHocDrugs ?? []);
  const patientProfiles = patients.flatMap((p) => p.patientProfiles ?? []);

  const administeredDrugData = administeredDrugs
    .map((a) => {
      const profile = patientProfiles.find((p) => p.allCurrentMedications?.find((m) => m.hsId === a.medicationId));
      const medication = profile ? findPatientProfileMedication(profile, a.medicationId!) : undefined;
      return {
        administration: a,
        medication: medication,
      };
    })
    .filter(
      (a) =>
        !!a.medication && a.administration.administeredAt && isToday(DateUtils.toDate(a.administration.administeredAt)),
    );
  const administeredAdHocDrugData = administeredAdHocDrugs
    .map((a) => {
      return {
        administration: a,
      };
    })
    .filter((a) => a.administration.administeredAt && isToday(DateUtils.toDate(a.administration.administeredAt)));

  return {
    administeredDrugData,
    administeredAdHocDrugData,
  };
}

export const findPatientProfileMedication = (profile: HSPatientProfile, medicationId: number) => {
  for (const medication of profile.allCurrentMedications ?? []) {
    if (medication.hsId === medicationId) {
      return medication;
    }
  }
};
