import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: ${(props) => props.theme.typography.defaults.fontSize};
    color: ${(props) => props.theme.typography.defaults.color};
    font-weight: ${(props) => props.theme.typography.defaults.fontWeight};
    height: 100%;
  }

  body {
    margin: 0;
    background: ${(props) => props.theme.body.background};
    height: 100%;
  }

  #root {
    height: 100%;
  }

  * {
    box-sizing: border-box;
    font-size: inherit;
    font-family: ${(props) => props.theme.typography.defaults.fontFamily};
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
  }

  h1 {
    font-size: ${(props) => props.theme.typography.h1.fontSize};
    font-family: ${(props) => props.theme.typography.h1?.fontFamily ?? props.theme.typography.defaults.fontFamily};
    color: ${(props) => props.theme.typography.h1?.color ?? props.theme.typography.defaults.color};
    margin: ${(props) => props.theme.typography.h1?.margin ?? props.theme.typography.defaults.margin};
    padding: 0px;
  }

  h2 {
    font-size: ${(props) => props.theme.typography.h2.fontSize};
    font-family: ${(props) => props.theme.typography.h2?.fontFamily ?? props.theme.typography.defaults.fontFamily};
    color: ${(props) => props.theme.typography.h2?.color ?? props.theme.typography.defaults.color};
    margin: ${(props) => props.theme.typography.h2?.margin ?? props.theme.typography.defaults.margin};
    padding: 0px;
  }

  h3 {
    font-size: ${(props) => props.theme.typography.h3.fontSize};
    font-family: ${(props) => props.theme.typography.h3?.fontFamily ?? props.theme.typography.defaults.fontFamily};
    color: ${(props) => props.theme.typography.h3?.color ?? props.theme.typography.defaults.color};
    margin: ${(props) => props.theme.typography.h3?.margin ?? props.theme.typography.defaults.margin};
    padding: 0px;
  }

  h4 {
    font-size: ${(props) => props.theme.typography.h4.fontSize};
    font-family: ${(props) => props.theme.typography.h4?.fontFamily ?? props.theme.typography.defaults.fontFamily};
    color: ${(props) => props.theme.typography.h4?.color ?? props.theme.typography.defaults.color};
    margin: ${(props) => props.theme.typography.h4?.margin ?? props.theme.typography.defaults.margin};
    padding: 0px;
  }

  p {
    font-size: ${(props) => props.theme.typography.p?.fontSize ?? props.theme.typography.defaults.fontSize};
    font-family: ${(props) => props.theme.typography.p?.fontFamily ?? props.theme.typography.defaults.fontFamily};
    color: ${(props) => props.theme.typography.p?.color ?? props.theme.typography.defaults.color};
    margin: ${(props) => props.theme.typography.p?.margin ?? props.theme.typography.defaults.margin};
    padding: 0px;
  }

  a {
    cursor: pointer;
    font-size: ${(props) => props.theme.typography.link?.fontSize ?? props.theme.typography.defaults.fontSize};
    font-family: ${(props) => props.theme.typography.link?.fontFamily ?? props.theme.typography.defaults.fontFamily};
    color: ${(props) => props.theme.typography.link?.color ?? props.theme.typography.defaults.color};
    text-decoration: ${(props) => (props.theme.typography.link?.underlined ? 'underline' : 'inherit')};
    padding: 0px;
  }
  a:hover {
    opacity: 70%;
  }

  ul, ol {
    padding-left: 1em;
  }
`;
