import styled, { css } from 'styled-components';

interface LayoutProps {
  gap?: number;
  horizontal?: boolean;
  justify?: 'left' | 'right' | 'center' | 'space-between';
  align?: 'flex-start' | 'center' | 'flex-end';
  padding?: number | string;
  height?: string;
  opacity?: number;
  border?: string;
}

export const Layout = styled.div<LayoutProps>`
  display: grid;
  grid-gap: ${(props) => props.gap ?? 0}rem;

  grid-template: ${(props) => (props.horizontal ? 'auto / auto' : '1fr / 1fr')};
  /* grid-template: auto / auto; */
  /* grid-template: 1fr / 1fr; */

  grid-auto-flow: ${(props) => (props.horizontal ? 'column' : 'row')};
  justify-items: ${(props) => props.justify ?? 'normal'};
  align-items: ${(props) => props.align ?? 'flex-start'};
  padding: ${(props) => props.padding ?? 0};
  height: ${(props) => props.height ?? 'initial'};
  opacity: ${(props) => props.opacity ?? 1};
  border: ${(props) => props.border ?? 'none'};

  ${(props) => {
    if (props.justify) {
      return css`
        justify-content: ${props.justify};
      `;
    }
    return css`
      justify-content: ${props.horizontal ? 'start' : 'normal'};
    `;
  }}
`;
