import React, {useEffect, useState} from "react";
import {LogMessage, useLogger} from "../../../core/logger/LoggerContext";
import styled from "styled-components";
import {colors} from "../../../kit/Theme/Theme";
import {SearchInput} from "../../../kit/Forms/SearchInput";
import {Link} from "react-router-dom";

const Pagination = () => {
    const [logs,_] = useLogger();
    const [logsArray, setLogsArray] = useState(logs.messages);
    const [currentPage, setCurrentPage] = useState(0);
    const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState(15);
    const [pageNumbers, setPageNumbers] = useState<number[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [hasResult, setHasResult] = useState(true);
    const pageNumbersArray: number[] = [];

    const setTotalNumberOfPages = () => {
        return Math.ceil(Math.ceil(logs.messages.length / 15));
    };

    const createPageNumbersArray = () => {
        for (let i = 1; i <= setTotalNumberOfPages(); i++) {
            pageNumbersArray.push(i);
        }
        setPageNumbers(pageNumbersArray);
    }

    useEffect(() => {
        // Prevents Next/Previous buttons to go out of range
        if (currentPage < 0) {
            setCurrentPage(0);
        }
        if (currentPage > 0 && currentPage === pageNumbers.length) {
            setCurrentPage(pageNumbers.length - 1);
        }

        if (searchQuery.charAt(0) === ':') {
            setLogsArray(logs.messages.filter((logMessage) =>  !searchQuery || logMessage.level.includes(searchQuery.charAt(1).toUpperCase() + searchQuery.slice(2)))
                .slice(currentPage * numberOfItemsPerPage, (currentPage  + 1) * numberOfItemsPerPage));
        } else {
            setLogsArray(logs.messages.filter((logMessage) => !searchQuery || logMessage.message.includes(searchQuery))
                .slice(currentPage * numberOfItemsPerPage, (currentPage + 1) * numberOfItemsPerPage));
        }

        createPageNumbersArray();
    }, [logs.messages, currentPage, searchQuery]);

    const setLogEntryTextColor = (logEntryLevel: string) => {
        let textColor: string;
        switch (logEntryLevel) {
        case 'Info':
            textColor = `${colors.bg_dodger_blue}`;
            break;
        case  'Warning':
            textColor = `${colors.bg_orange_600}`;
            break;
        case 'Error':
            textColor = `${colors.bg_red_500}`;
            break;
        case 'Exception':
            textColor = `${colors.bg_red_500}`;
            break;
        default:
            textColor = `${colors.text_black}`;
        }
        return textColor;
    };



    return(
        <>
            <TitleContainer>
                <TitleWrapper>
                Table shows system wide activity logs.
                    If you are experiencing technical issues with the application, please <Link style={{color: colors.text_white, textDecoration: 'underline'}} to="/support">contact support</Link>.<br/> Search is case sensitive.
                </TitleWrapper>
                <PageNumberWrapper>Page: {currentPage + 1}</PageNumberWrapper>
            </TitleContainer>
            <TableWrapper>
                <SearchInput value={searchQuery}
                    onChange={(event, value) => setSearchQuery(value)} name={'searchInput'} placeholder={'Search... (begin your search with ":" to search for level)'} />
                {hasResult &&
                <table>
                    <thead>
                        <tr style={{backgroundColor: '#eeeeee'}}>
                            <th style={{width: '400px'}}>Logged At</th>
                            <th style={{width: '150px'}}>Level</th>
                            <th style={{width: '150px'}}>Module</th>
                            <th>Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logsArray.map((logEntry: LogMessage, i: number) => (
                            <tr key={i}>
                                <td>{logEntry.createdAt}</td>
                                <td style={{color: setLogEntryTextColor(logEntry.level)}}>{logEntry.level}</td>
                                <td>{logEntry.module}</td>
                                <td >{logEntry.message}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                }
                {/* Page buttons */}
                {pageNumbers.length <= 3 &&
                <PageNumbersContainer>
                    <IconContainer onClick={() => setCurrentPage(currentPage - 1)}>Previous</IconContainer>
                    {pageNumbers.map((pageNumber: number, index: number) => (
                        <PageButton key={index} onClick={() => { setCurrentPage(index);}}>
                            {pageNumber}</PageButton>
                    ))}
                    <IconContainer onClick={() => setCurrentPage(currentPage + 1)}>Next</IconContainer>
                </PageNumbersContainer>
                }
                {pageNumbers.length > 3 &&
                <PageNumbersContainer>
                    <IconContainer onClick={() => setCurrentPage(currentPage - 1)}>Previous</IconContainer>
                    <PageButton onClick={() => { setCurrentPage(0);}}>1</PageButton>
                    {currentPage > 0 && currentPage < pageNumbers.length &&
                    <PageButton style={{backgroundColor: colors.bg_purple_300}}>
                        {currentPage + 1}</PageButton>
                    }
                    <PageButton onClick={() => { setCurrentPage(pageNumbers.length - 1);}}>
                        {pageNumbers.length}</PageButton>
                    <IconContainer onClick={() => setCurrentPage(currentPage + 1)}>Next</IconContainer>
                </PageNumbersContainer>
                }
            </TableWrapper>
            {!hasResult &&
                <NoResultWrapper>
                    No result have been found!
                </NoResultWrapper>
            }
        </>
    )
};

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const TitleWrapper = styled.p`
    padding: 2em;
    color: #ffffff;
    font-size: 15px;
`;

const NoResultWrapper = styled.p`
    text-align: center;
    padding: 2em;
    color: #ffffff;
    font-size: 19px;
`;

const PageNumberWrapper = styled.p`
    padding: 2em;
    color: #ffffff;
    font-size: 15px;
`;

const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(251, 244, 255);
    padding: 0 .5em;
    border: 1px solid #f8f9fa;
    border-radius: .25rem;
    tr > th {
        text-align: left;
        padding: 1em .5em;
    };
    td {
        padding: .5em;
    };
    tbody > tr:hover {
        background-color: #eeeeee;
    }
`;

const PageNumbersContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 1em auto;
    background-color: transparent;
`;

const PageButton = styled.button`
    cursor: pointer;
    margin: 10px 5px;
    padding: 5px;
    width: 70px;
    background-color: ${colors.bg_purple_200};
    border: none;
    &:hover {
        background-color: ${colors.bg_purple_300};
    }
`;

const IconContainer = styled(PageButton)`
    width: 110px;
    font-size: 15px;
    color: ${colors.text_black};
`;

export default Pagination;
