import {IconBack} from "../../../kit/Icons/Back";
import React, {useEffect, useState} from "react";
import {Layout} from "../../../kit/Layout";
import styled from "styled-components";
import {Grid} from "../../../kit/Grid";
import DiagnosisTable from "../components/DiagnosisTable";
import {colors} from "../../../kit/Theme/Theme";
import Tabs from "../../../kit/Tabs/Tabs";
import Tab from "../../../kit/Tabs/Tab";
import Pagination from "../components/Pagination";
import useDashboardData from "../hooks/useDashboardData";

const DiagnosisDashboardPage = () => {
    const { indexedDbData} = useDashboardData();
    const [dbData, setDbData] = useState<{ dataType: string, numberOfRecords: number, queueSize: string }[]>([{ dataType: '', numberOfRecords: 0, queueSize: '' }]);

    useEffect(() => {
        setDbData(indexedDbData);
    }, [indexedDbData]);



    return(
        <DiagnosisDashboardPageContainer>
            <BackButtonContainer align={'center'} horizontal gap={1.5}>
                <IconContainer><IconBack onClick={() => history.back()} /></IconContainer>
                <TitleText>System Diagnostic Dashboard</TitleText>
            </BackButtonContainer>
            <PanelsContainer>
                <TabDisplay>
                    <Tabs>
                        <Tab title="Dashboard" hasLine={true}>
                            <DiagnosisTable data={dbData}/>
                        </Tab>
                        <Tab title="Logs" hasLine={true}>
                            <Pagination />
                        </Tab>
                    </Tabs>
                </TabDisplay>
            </PanelsContainer>
        </DiagnosisDashboardPageContainer>
    );
};

const DiagnosisDashboardPageContainer = styled(Grid)`
    width: 100%;
    height: 100%;
    grid-template-rows: auto 1fr;
    padding: 2.5rem;
`;

const BackButtonContainer = styled(Layout)`
    max-height: 10%;
`;

const IconContainer = styled(Layout)`
    cursor: pointer;
`;

const TitleText = styled.h1`
    color: ${(props) => props.theme.backgrounds.default.fg};
`;

const PanelsContainer = styled.div`
    margin-top: 2.5rem;
    width: 100%;
`;

const TabDisplay = styled.div`
  .selected {
      font-size: 11pt;
      padding: .75em 1.5em;
      background-color: ${() => colors.bg_green_400};
      color: #000000;
  }
  .unselected {
      font-size: 11pt;
      padding: .75em 1.5em;
      background-color: ${() => colors.bg_purple_700};
      color: ${() => colors.bg_gray_400};
  }
`;

export default DiagnosisDashboardPage;