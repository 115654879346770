import itiriri from 'itiriri';
import React, { useEffect, useState } from 'react';
import { HSDrug, HSRouteType } from 'server-openapi';
import styled from 'styled-components';
import { Button } from '../../../../../kit/Button';
import { Select, SelectOption } from '../../../../../kit/Forms/Select';
import { TypeaheadSelect } from '../../../../../kit/Forms/TypeaheadSelect';
import { Layout } from '../../../../../kit/Layout';
import { Intent } from '../../../../../kit/Theme/Theme';
import { useSyncCenter } from '../../../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../../../core/storage/hooks/UseStore';
import { DrugFormUtils } from '../../../../../syncstream/utils/DrugFormUtils';

interface IProps {
  itemType: SecondCheckItemType;
  existingItems: string[];
  onDialogClose: () => void;
  onItemAdd: (item?: HSDrug | HSRouteType) => void;
  isDialogOpen: boolean;
}

export enum SecondCheckItemType {
  Route,
  Drug,
}

export function AddItemDialogContent(props: IProps) {
  const [selectedRouteType, setSelectedRouteType] = useState<HSRouteType>();
  const [selectedDrug, setSelectedDrug] = useState<HSDrug>();

  useEffect(() => {
    setSelectedRouteType(undefined);
    setSelectedDrug(undefined);
  }, [props.isDialogOpen]);

  function onAddButtonClick() {
    props.onDialogClose();

    props.onItemAdd(selectedDrug ?? selectedRouteType);
  }

  return (
    <Layout gap={1}>
      <h2>Add {SecondCheckItemType[props.itemType].toLowerCase()} to second check</h2>
      {props.itemType === SecondCheckItemType.Route && (
        <AddRouteContent
          existingItems={props.existingItems}
          setSelectedRouteType={setSelectedRouteType}
          selectedRouteType={selectedRouteType}
        />
      )}
      {props.itemType === SecondCheckItemType.Drug && (
        <AddDrugContent existingItems={props.existingItems} setSelectedDrug={setSelectedDrug} />
      )}
      <AddCancelButtonsContainer>
        <StyledButton onClick={() => props.onDialogClose()} intent={Intent.Secondary}>
          CANCEL
        </StyledButton>
        <StyledButton
          onClick={() => onAddButtonClick()}
          disabled={!selectedDrug && !selectedRouteType}
          intent={Intent.Secondary}
        >
          ADD
        </StyledButton>
      </AddCancelButtonsContainer>
    </Layout>
  );
}

interface IRouteContentProps {
  existingItems: string[];
  setSelectedRouteType: (value?: HSRouteType) => void;
  selectedRouteType?: HSRouteType;
}

function AddRouteContent(props: IRouteContentProps) {
  const services = useSyncCenter();
  const drugFormsStore = useStore(services.drugForms.store).store;

  const drugForms = itiriri(drugFormsStore.values()).toArray();

  const routeTypes = DrugFormUtils.getRouteTypes(drugForms).filter((rt) => !props.existingItems.includes(rt.code!));

  const routeOptions = itiriri(routeTypes)
    .sort((a, b) => a.code!.localeCompare(b.code!))
    .toArray()
    .map((r) => ({ label: `${r.code} (${r.name})`, value: r.code } as SelectOption<string>));

  return (
    <Select
      fullWidth
      placeholder="- Select route -"
      name="selectRoute"
      options={routeOptions}
      value={props.selectedRouteType?.code}
      onChange={(_, v) => {
        const routeType = routeTypes?.find((rt) => rt.code === v);

        props.setSelectedRouteType(routeType);
      }}
    />
  );
}

interface IDrugContentProps {
  existingItems: string[];
  setSelectedDrug: (value?: HSDrug) => void;
}

function AddDrugContent(props: IDrugContentProps) {
  const services = useSyncCenter();
  const drugStore = useStore(services.drugs.store).store;

  const drugs = itiriri(drugStore.values())
    .filter((d) => !props.existingItems.includes(d.hsId!.toString()))
    .toArray();

  const drugOptions = itiriri(drugs)
    .sort((a, b) => a.name!.localeCompare(b.name!))
    .toArray()
    .map(
      (d) =>
        ({
          label: `${d.name} ${d.formAbbreviation} ${d.strength}`,
          value: d.hsId?.toString(),
        } as SelectOption<string>),
    );

  // issue: onClickOutside is not firing on modal dialog (resulting in popover not closing)
  // https://github.com/alexkatz/react-tiny-popover/issues/139
  return (
    <TypeaheadSelect
      name="selectDrug"
      options={drugOptions}
      onChange={(_, v) => {
        const drug = drugs?.find((d) => d.hsId?.toString() === v.value);

        props.setSelectedDrug(drug);
      }}
      onQueryChange={() => props.setSelectedDrug(undefined)}
      fullWidth
      placeholder="Enter drug name..."
      maxDisplayed={15}
      invertColours
    />
  );
}

const AddCancelButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const StyledButton = styled(Button)`
  width: 100px;
`;
