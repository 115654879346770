/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import {isToday, subDays} from 'date-fns';
import itiriri from 'itiriri';
import {User} from 'oidc-client';
import React from 'react';
import {useForm} from 'react-hook-form';
import {HSDrug, ReasonCode, SyringeDriverActivityKind} from 'server-openapi';
import styled from 'styled-components';
import {useCurrentUser} from '../../../../core/authn/UserProvider';
import {useStore} from '../../../../core/storage/hooks/UseStore';
import {DateUtils} from '../../../../core/utils/dateUtils';
import {Button} from '../../../../kit/Button';
import {Dialog} from '../../../../kit/Dialog';
import {DateInput} from '../../../../kit/Forms/DateInput';
import {Form} from '../../../../kit/Forms/Form';
import {FormGroup} from '../../../../kit/Forms/FormGroup';
import {RadioGroup} from '../../../../kit/Forms/RadioGroup';
import {Select} from '../../../../kit/Forms/Select';
import {TextArea} from '../../../../kit/Forms/TextArea';
import {TextInput} from '../../../../kit/Forms/TextInput';
import {Grid} from '../../../../kit/Grid';
import {Layout} from '../../../../kit/Layout';
import {Text} from '../../../../kit/Text';
import {toasts} from '../../../../kit/Toasts/Toaster';
import {useSyncCenter} from '../../../../syncstream/SyncCenterProvider';
import {
  MedisphereSyringeDriverActivity,
  SyncSyringeDriverActivities,
} from '../../../../syncstream/SyncSyringeDriverActivity';
import {useApiUtils} from '../../../../syncstream/utils/hooks/useApiUtils';
import {ResidentDetailsUtils} from '../../../../syncstream/utils/ResidentDetailsUtils';
import {useRoundSchedule} from '../../../Rounds/services/RoundScheduleProvider';
import {DrugDetailLeftPanel} from '../drugDetails/DrugDetailLeftPanel';
import {
  MedicationInformationBox,
  MedicationInformationProps,
  SecondCheckDialogContainer,
} from '../medicationInformation/MedicationInformationBox';
import {getComments} from '../MedicineAdministerDialog';
import {useGroupPermissions} from "../../../../core/authz/PermissionsProvider";
import { v4 as uuidv4 } from 'uuid';

interface Props extends MedicationInformationProps {
  drug: HSDrug;
  confirmationInitials?: string;
  confirmationUserId?: number;
  open: boolean;
  setOpen: (open: boolean) => void;
  requiresSecondCheck?: boolean;
  isLate: boolean;
  hasBeenDosed: boolean;
}

export function SyringeDriverAdministerDialog(props: Props) {
  return (
    <Dialog lazy closeButtonColor={'#fff'} open={props.open} onRequestClose={() => props.setOpen(false)} size="full">
      <DialogContainer horizontal gap={2} data-testid="medicine-administer-dialog">
        <DrugDetailLeftPanel
          drug={props.drug}
          packedMedication={props.packedMedication}
          facilityGroupId={props.facilityGroupId}
          patient={props.patient}
        />
        <AdministerDrugForm {...props} />
      </DialogContainer>
    </Dialog>
  );
}

interface FormFields {
  status: ReasonCode;
  notes?: string;
  volumeToBeInfused?: string;
  rateOfInfusion?: string;
  endDate?: Date;
  siteLocation?: string;
  siteLocationOther?: string;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function AdministerDrugForm(props: Props) {
  const apiUtils = useApiUtils();
  const groupPermissions = useGroupPermissions();
  const services = useSyncCenter();
  const syringeDriverActivityStore = useStore(services.syringeDriverActivity.store).store;

  const roundSchedule = useRoundSchedule();
  const showAdministerLate = props.isLate && !props.hasBeenDosed;
  const startReasonCodes: ReasonCode[] = showAdministerLate ? [ReasonCode.DosedLate] : [ReasonCode.Dosed];


  const previousAdministration =
    props.packedMedication && apiUtils.residentDetails.getLatestAdministrationForScheduledDrug(props.packedMedication);

  const existingSyringeDriverActivity = itiriri(syringeDriverActivityStore.values())
    .filter(
      (activity) =>
        (activity.administeredDrugClinicalSystemId ?? false) === previousAdministration?.clinicalSystemId ||
        (activity.administeredDrugId ?? false) === previousAdministration?.hsId,
    )
    .toArray();

  const initialActivity = existingSyringeDriverActivity.sort((a1, a2) =>
    DateUtils.compareDateStringsDescending(a1.createdAt, a2.createdAt, true),
  )[0];
  const canRecordNote = groupPermissions.canRecordNote;
  const user = useCurrentUser();
  const reasonCodes: Array<ReasonCode> = [...startReasonCodes, ...stopReasonCodes];
  const form = useForm<FormFields>({
    mode: 'onChange',
    defaultValues:
      previousAdministration && 'administeredDrugComments' in previousAdministration
        ? {
            status: previousAdministration?.reasonCode && reasonCodes.includes(previousAdministration?.reasonCode!) ? previousAdministration?.reasonCode : undefined,
            notes: getComments(previousAdministration),
            volumeToBeInfused: initialActivity?.volumeToBeInfused ?? undefined,
            rateOfInfusion: initialActivity?.rateOfInfusion ?? undefined,
            siteLocation: initialActivity?.sitePosition
              ? syringeDriverSiteLocations.includes(initialActivity.sitePosition)
                ? initialActivity.sitePosition
                : 'Other'
              : undefined,
            siteLocationOther: initialActivity?.sitePosition ?? undefined,
            endDate:
              initialActivity?.timeRemaining && initialActivity?.createdAt
                ? DateUtils.getEndDateFromOpenApiDurationAndStartDate(
                    initialActivity.timeRemaining,
                    DateUtils.toDate(initialActivity.createdAt),
                  )
                : undefined,
          }
        : {
            status: props.requiresSecondCheck && !showAdministerLate ? ReasonCode.Refused : ReasonCode.Dosed,
          },
  });
  const currentStatus = form.watch('status');

  let statusOptions = reasonCodes.map((status) => {
    return {
      label: status === ReasonCode.Other ? 'Other unadministered' : apiUtils.residentDetails.reasonCodeToString(status),
      value: status,
    };
  });

  if (props.requiresSecondCheck) {
    statusOptions = statusOptions.filter((s) => s.value !== 'Dosed' && s.value !== 'SelfAdministered');
  }

  async function onSubmit(data: FormFields) {
    if (data.notes && !canRecordNote) {
      toasts.error('You do not have permission to record notes');
      return;
    }
    const newRound = await processDrugAdministration(
      data,
      props,
      user,
      apiUtils.residentDetails,
      services.syringeDriverActivity.service,
      startReasonCodes
    );

    // Refresh round schedule
    if (props.currentRound) {
      roundSchedule.set({
        ...(newRound ?? props.currentRound),
        version: ((newRound ?? props.currentRound).version ?? 0) + 1,
      });
    }
  }
  function validateNotes(notes: string | undefined) : string | undefined {
    const status = form.watch('status');
    if (!status) {
      return;
    }
    if (status === ReasonCode.DosedLate && (!notes || notes.length ===0)) {
      return 'Notes unless self administered';
    }
    if (form.watch('status') !== ReasonCode.Dosed && (!notes || notes.length === 0)) {
      return 'Notes required if not administered or self administered';
    }
    return;
  }

  const disableForm = !!props.scheduledActivityInformation?.selectedActivity;

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Layout gap={1}>
        <WhiteText size="large">Current status</WhiteText>
        <MedicationInformationBox displayCurrentStatusOnly {...props} />
        <StatusSection gap={1}>
          <Text size="large">Update status</Text>
          <RadioGroup
            name="status"
            options={statusOptions}
            cols={3}
            control={form.control}
            required
            disabled={disableForm}
            onChange={async () => await form.trigger(["notes"])}
          />
        </StatusSection>
        {startReasonCodes.includes(form.watch('status')) && (
          <Layout gap={1}>
            <Grid cols={3}>
              <FormGroup label="Volume to be infused" fullWidth>
                <TextInput
                  name="volumeToBeInfused"
                  placeholder="Volume to be infused"
                  fullWidth
                  required
                  disabled={disableForm}
                />
              </FormGroup>
              <FormGroup label="Rate of infusion" fullWidth>
                <TextInput
                  name="rateOfInfusion"
                  placeholder="Rate of infusion"
                  fullWidth
                  required
                  disabled={disableForm}
                />
              </FormGroup>

              <FormGroup label="End date and time" fullWidth>
                <DateInput
                  name="endDate"
                  dateTime
                  fullWidth
                  required
                  rules={{
                    validate: (endDate: Date) => {
                      if (endDate < new Date()) {
                        return 'Date must be in the future';
                      }
                      if (!isToday(endDate) && !isToday(subDays(endDate, 1))) {
                        return 'Date can only end up to one day from administration';
                      }

                      return true;
                    },
                  }}
                  disabled={disableForm}
                />
              </FormGroup>
            </Grid>
            <FormGroup fullWidth label="Site Location">
              <Grid cols={3}>
                <Select
                  name="siteLocation"
                  options={syringeDriverSiteLocations.map((option) => ({ label: option, value: option }))}
                  required
                  disabled={disableForm}
                />
                {form.watch('siteLocation') === 'Other' && (
                  <TextInput
                    name="siteLocationOther"
                    placeholder="Other site location"
                    required
                    disabled={disableForm}
                  />
                )}
              </Grid>
            </FormGroup>
          </Layout>
        )}
        <FormGroup fullWidth>
          <TextArea
            name="notes"
            placeholder="Add note"
            rows={3}
            fullWidth
            rules={{
              validate: (notes: string) => validateNotes(notes)
            }}
            data-testid="medication-notes"
            disabled={disableForm}
          />
        </FormGroup>
        { props.requiresSecondCheck && currentStatus === ReasonCode.DosedLate ?

            <SecondCheckDialogContainer {...props} /> :
            <Button type="submit" fullWidth data-testid="medication-administer-button" disabled={disableForm}>
              UPDATE STATUS
            </Button>
        }
      </Layout>
    </Form>
  );
}

const DialogContainer = styled(Layout)`
  padding: 1em;
  grid-template-columns: 3fr 5fr;
`;

const StatusSection = styled(Layout)`
  color: white;
`;

const WhiteText = styled(Text)`
  color: white;
`;

// eslint-disable-next-line sonarjs/cognitive-complexity
async function processDrugAdministration(
  data: FormFields,
  props: Props,
  user: User,
  residentDetails: ResidentDetailsUtils,
  syringeDriverService: SyncSyringeDriverActivities,
  startReasonCodes: ReasonCode[]
) {
  const notes = data.notes ? [data.notes] : [];
  const newData = await props.onAdminister?.(
    residentDetails.generateAdministeredDrug(
      props.packedMedication!,
      props.drug,
      data.status!,
      notes,
      user,
      props.confirmationInitials,
      props.confirmationUserId,
      props.packedMedication?.dosage,
    ),
    props.packedMedication && 'doseTimestamp' in props.packedMedication
      ? props.packedMedication?.doseTimestamp ?? ''
      : '',
  );
  const initialActivity: MedisphereSyringeDriverActivity = {
    kind: startReasonCodes.includes(data.status) ? SyringeDriverActivityKind.Start : SyringeDriverActivityKind.Stop,
    volumeToBeInfused: data.volumeToBeInfused ?? 'N/A',
    volumeInfused: '0',
    timeRemaining: data.endDate
      ? DateUtils.getOpenApiDurationFromInterval(
          DateUtils.toDate(newData!.administeredDrug.administeredAt!),
          data.endDate,
        )
      : undefined,
    createdAt: newData?.administeredDrug?.administeredAt,
    sitePosition: data.siteLocationOther ?? data.siteLocation ?? 'N/A',
    administeredDrugClinicalSystemId: newData?.administeredDrug?.clinicalSystemId ?? uuidv4(),
    rateOfInfusion: data.rateOfInfusion ?? 'N/A',
    patientId: props.patient.hsId,
    lastUpdatedBySubjectId: user.profile.sub,
  };
  await syringeDriverService.enqueue.createSyringeDriverActivity({
    type: 'syringe-driver-activity-create',
    medisphereSyringeDriverActivity: initialActivity,
    administeredDrugRoundClinicalSystemId: newData?.round?.clinicalSystemId ?? uuidv4(),
    request: { syringeDriverActivity: initialActivity, facilityGroupId: props.facilityGroupId },
  });

  return newData?.round;
}

const stopReasonCodes: ReasonCode[] = [
  ReasonCode.Refused,
  ReasonCode.NoStock,
  ReasonCode.Withheld,
  ReasonCode.Hospital,
  ReasonCode.Absent,
  ReasonCode.Ceased,
  ReasonCode.Omitted,
  ReasonCode.Leave,
  ReasonCode.Other,
];

export const syringeDriverSiteLocations = [
  'Abdomen Left',
  'Abdomen Right',
  'Upper anterior thighs, Left',
  'Upper anterior thighs, Right',
  'Anterior chest, Left',
  'Anterior chest, Right',
  'Scapula, Left',
  'Scapula, Right',
  'Anterior aspect of upper arms, Left',
  'Anterior aspect of upper arms, Right',
  'Other',
];
