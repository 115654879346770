import React from 'react';
import { StyledTextInput } from '../../kit/Forms/TextInput';
import { formInput, FormInput } from '../../kit/Forms/FormInput';

export const PinInput = formInput(function (props: FormInput<string>) {
  return (
    <StyledTextInput
      type="password"
      name={props.name}
      minLength={4}
      maxLength={4}
      value={props.value ?? ''}
      onChange={(event) => props.onChange && props.onChange(event, event.target.value.replace(/\D/, ''))}
    />
  );
});
