import * as React from 'react';
import { Tooltip } from 'react-tooltip';

export function IconPsychotropic(props: React.SVGProps<SVGSVGElement>) {
  return (
      <>
        <Tooltip id="IconPsychotropic" />
          <svg
            id="prefix__Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x={0}
            y={0}
            viewBox="0 0 19.1 16.5"
            xmlSpace="preserve"
            data-tooltip-content={props.xlinkTitle ?? "Psychotropic Medication"}
            data-tooltip-id="IconPsychotropic"
            data-tooltip-place="top"
            {...props}
          >
            <style>{'.prefix__stpsyc{fill:#ff4c00}'}</style>
            <path
              className="prefix__stpsyc"
              d="M11.7 10.3c0-1.2-.8-1.8-2.2-1.8H7.7V14h1.4v-1.8h.4c1.3 0 2.2-.7 2.2-1.9zm-1.4 0c0 .6-.3.8-.9.8h-.3V9.5h.3c.6 0 .9.3.9.8z"
            />
            <path className="prefix__stpsyc" d="M19.1 16.5H0L9.6 0l9.5 16.5zm-15.6-2h12.2L9.6 4 3.5 14.5z" />
          </svg>
        </>
  );
}
