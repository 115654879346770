import * as React from 'react';
import { Tooltip } from 'react-tooltip';

export function IconControlledDrug(props: React.SVGProps<SVGSVGElement>) {
  return (
      <>
          <Tooltip id="IconControlledDrug" />
          <svg
              id="prefix__Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x={0}
              y={0}
              viewBox="0 0 19.1 16.5"
              xmlSpace="preserve"
              data-tooltip-content={props.xlinkTitle ?? "Controlled Drug"}
              data-tooltip-id="IconControlledDrug"
              data-tooltip-place="top"
              {...props}
          >
            <style>{'.prefix__stcd{fill:#007f00}'}</style>
            <path
                className="prefix__stcd"
                d="M9 13.8l-.1-.8c-.3.2-.6.3-.9.3-.6 0-1.1-.5-1.1-1.3s.4-1.3 1-1.3c.4 0 .7.1.9.3l.1-.9c-.3-.2-.6-.3-1.1-.3-1.2 0-2 .9-2 2.1 0 1.3.8 2.1 2 2.1.5.1.9 0 1.2-.2zm4.2-1.9c0-1.2-.8-2.1-2.2-2.1H9.6V14h1.5c1.4 0 2.1-.8 2.1-2.1zm-1 0c0 .9-.5 1.3-1.2 1.3h-.4v-2.5h.4c.7 0 1.2.4 1.2 1.2z"
            />
            <path className="prefix__stcd" d="M19.1 16.5H0L9.6 0l9.5 16.5zm-15.6-2h12.2L9.6 4 3.5 14.5z" />
          </svg>
      </>
  );
}
