import React, {useContext, useEffect, useReducer, useState} from "react";
import {setLoggerDispatch} from "../../context/AppStateProvider";

interface AppendAction {
    type: "Append";
    message: string;
    level: string;
    module: string;
}

interface ClearAction {
    type: "Clear";
}

type Action = AppendAction | ClearAction;


function reducer(state: LogProviderContext, action: Action): LogProviderContext {
    switch (action.type) {
    case "Append":
        state = {
            messages: [ {
                level: action.level,
                module: action.module,
                message: action.message,
                createdAt: new Date().toLocaleString()
            }, ...state.messages.slice(-10000)]   // Only store up to 10,000 messages.
        };
        break;
    case "Clear":
        state.messages = [];
        break;
    default:
        break;
    }

    return state;
}

export interface LogMessage {
    level: string;
    createdAt: string;
    message: string;
    module: string;
}
export interface LogProviderContext {
    messages: LogMessage[];
}
export type LoggerContextDispatchFunc = (action: Action) => void;
export type LoggerContextStore = [LogProviderContext, LoggerContextDispatchFunc];
export type LoggerActions = [];

// eslint-disable-next-line @typescript-eslint/no-empty-function
const defaultStore: LoggerContextStore = [{messages: []}, () => {
}];
const Context = React.createContext<LoggerContextStore>(defaultStore);

export function useLogger(): LoggerContextStore {
    return useContext(Context);
}
export function useLoggerDispatch(): LoggerContextDispatchFunc {
    return useContext(Context)[1];
}

interface Props {
    children?: React.ReactNode;
}

export const LogContextProvider = (props: Props) => {
    const [state, setState] = useState<LogProviderContext>({messages: []});
    const store: LoggerContextStore = useReducer(reducer, state);
    const dispatchFunc = store[1];
    useEffect(() => {
        setLoggerDispatch(dispatchFunc);
    }, [dispatchFunc]);
    return (
        <Context.Provider value={store}>
            {props.children}
        </Context.Provider>
    );
};

