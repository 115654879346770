import React from 'react';
import { HSFacility, HSFacilityGroup } from 'server-openapi';
import styled from 'styled-components';
import { Select } from '../../kit/Forms/Select';
import { Grid } from '../../kit/Grid';
import { useApiUtils } from '../../syncstream/utils/hooks/useApiUtils';
import { DateInput } from '../../kit/Forms/DateInput';
import { Layout } from '../../kit/Layout';
import { Text } from '../../kit/Text';

interface IProps {
  onSelectFacility: (facility: HSFacility) => void;
  selectedFacility?: HSFacility;
  facilityGroup?: HSFacilityGroup;
  selectedStartDate?: Date;
  onSelectStartDate: (startDate?: Date) => void;
  selectedEndDate?: Date;
  onSelectEndDate: (endDate?: Date) => void;
}

export function NursesNotesFilters(props: IProps) {
  const facilityUtils = useApiUtils().facilities;
  const facilityGroupUtils = useApiUtils().facilityGroups;
  const facilities = facilityUtils.fetchAllFacilities(props.facilityGroup);

  const facilityOptions = facilities.map((facility) => {
    return { label: facility.name ?? 'Unknown', value: facility };
  });
  const wingLabel = props.facilityGroup?.hsId
    ? facilityGroupUtils.getFacilityUILabel(props.facilityGroup?.hsId)
    : 'wings';
  return (
    <FilterContainer alignItemsCenter cols={3} colsMobile={1} gap={1}>
      <Select
        fullWidth
        placeholder={`All ${wingLabel}`}
        name="wing"
        options={facilityOptions}
        value={props.selectedFacility}
        onChange={(_, value) => props.onSelectFacility(value)}
      />
      <DateContainer horizontal align={'center'} gap={0.5}>
        <PlaceholderText>From:</PlaceholderText>
        <DateInput
          name="date"
          fullWidth
          value={props.selectedStartDate}
          onChange={(_, v) => props.onSelectStartDate(v)}
        />
      </DateContainer>
      <DateContainer horizontal align={'center'} gap={0.5}>
        <PlaceholderText>To:</PlaceholderText>
        <DateInput name="date" fullWidth value={props.selectedEndDate} onChange={(_, v) => props.onSelectEndDate(v)} />
      </DateContainer>
    </FilterContainer>
  );
}

const FilterContainer = styled(Grid)`
  width: 100%;
`;

const DateContainer = styled(Layout)`
  color: ${(props) => props.theme.backgrounds.default.fg};
  width: 100%;
  grid-template-columns: 0.05fr 1fr;
`;

const PlaceholderText = styled(Text)`
  justify-self: center;
`;
