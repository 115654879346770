import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import { useStore } from '../../../../core/storage/hooks/UseStore';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { Layout } from '../../../../kit/Layout';
import { Text } from '../../../../kit/Text';
import { colors } from '../../../../kit/Theme/Theme';
import { useSyncCenter } from '../../../../syncstream/SyncCenterProvider';
import { MedisphereTestResult } from '../../../../syncstream/SyncTestResults';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';

export function TestResultsCompact(props: { testResults: MedisphereTestResult[] }) {
  const { testResults } = props;
  return (
    <Layout gap={0.5}>
      <TestResultsContainer gap={0.5} padding={'0.875rem'}>
        <Text style={{ color: colors.text_pink_600 }} size={'small'} weight={'bold'}>
          Test Results
        </Text>
        {testResults.map((testResult, index) => {
          return <TestResultDetails isSmall key={testResult.clinicalSystemId} testResult={testResult} />;
        })}
      </TestResultsContainer>
    </Layout>
  );
}

export function TestResultDetails(props: { testResult: MedisphereTestResult; isSmall?: boolean }) {
  const services = useSyncCenter();
  const testResultTypeStore = useStore(services.testResultTypes.store).store;
  const apiUtils = useApiUtils();

  const createdByNameAndRole =
    apiUtils.users.getUserFullNameAndRoleFromSubjectId(props.testResult.createdBySubjectId) ?? 'Unknown user';
  const testResultType = props.testResult.hsTestResultTypeId
    ? testResultTypeStore.get(props.testResult.hsTestResultTypeId?.toString())
    : undefined;

  return (
    <Text size={props.isSmall ? 'small' : undefined} weight={'200'}>
      {props.testResult.timeStamp &&
        `${format(DateUtils.toDate(props.testResult.timeStamp), 'd MMM y')} at ${DateUtils.dateStringTo24HourTimeString(
          props.testResult.timeStamp,
        )}`}{' '}
      by {createdByNameAndRole} - {testResultType?.name} result:{' '}
      {props.testResult.testResultEntries?.map(
        (entry, index) =>
          `${entry.value} (${entry.key})${index !== props.testResult.testResultEntries!.length - 1 ? ', ' : ''}`,
      )}
    </Text>
  );
}

const TestResultsContainer = styled(Layout)`
  border-width: 1px;
  border-style: groove;
`;
