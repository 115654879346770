import itiriri from 'itiriri';
import React from 'react';
import styled from 'styled-components';
import { Layout } from '../../../../kit/Layout';
import { Paper } from '../../../../kit/Paper';
import { Text } from '../../../../kit/Text';
import { useSyncCenter } from '../../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../../core/storage/hooks/UseStore';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import { FACILITY_METRICS } from './FacilityMetrics';

export function MetricsContainer(props: { facilityGroupId: number }) {
  const { facilityGroupId } = props;

  const services = useSyncCenter();
  const apiUtils = useApiUtils();

  const facilities = itiriri(useStore(services.facilities.store).store.values())
    .filter((f) => f.facilityGroupId === facilityGroupId)
    .toArray();
  const patients = apiUtils.patients
    .getActivePatients()
    .filter((p) => facilities.some((f) => f.hsId === p.facility))
    .toArray();
  const drugs = itiriri(useStore(services.drugs.store).store.values()).toArray();
  const drugCategories = itiriri(useStore(services.drugCategories.store).store.values()).toArray();
  const residentLabel = apiUtils.facilityGroups.getResidentUILabel(facilityGroupId);

  return (
    <MetricsSection horizontal gap={1}>
      {FACILITY_METRICS.map((m, index) => (
        <MetricsCard
          key={index}
          descriptor={m.getTypeDescriptor(residentLabel)}
          value={m.getValue(patients, drugs, drugCategories)}
        />
      ))}
    </MetricsSection>
  );
}

function MetricsCard(props: { descriptor: string; value: string }) {
  const { descriptor, value } = props;
  return (
    <MetricsCardContainer secondary>
      <Layout justify="center" gap={1}>
        <Text weight={'900'} size="large">
          {value}
        </Text>
        <Text weight={'bold'}>{descriptor}</Text>
      </Layout>
    </MetricsCardContainer>
  );
}

const MetricsSection = styled(Layout)`
  margin-top: 15px;
`;

const MetricsCardContainer = styled(Paper)`
  width: 200px;
  height: 200px;
  text-align: center;
  display: flex;
  align-items: center;
`;
