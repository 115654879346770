/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { Control, useForm } from 'react-hook-form';
import { HSAdministeredDrug, HSDoseRound, HSDrug, ReasonCode } from 'server-openapi';
import styled from 'styled-components';
import { useCurrentUser } from '../../../../core/authn/UserProvider';
import { useStore } from '../../../../core/storage/hooks/UseStore';
import { Button } from '../../../../kit/Button';
import { Dialog } from '../../../../kit/Dialog';
import { Form } from '../../../../kit/Forms/Form';
import { FormGroup } from '../../../../kit/Forms/FormGroup';
import { RadioGroup } from '../../../../kit/Forms/RadioGroup';
import { TextArea } from '../../../../kit/Forms/TextArea';
import IconDotMenu from '../../../../kit/Icons/DotMenu';
import { Layout } from '../../../../kit/Layout';
import { Text } from '../../../../kit/Text';
import { colors } from '../../../../kit/Theme/Theme';
import { toasts } from '../../../../kit/Toasts/Toaster';
import { useSyncCenter } from '../../../../syncstream/SyncCenterProvider';
import { DrugUtils } from '../../../../syncstream/utils/DrugUtils';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import { DrugDetailLeftPanel } from '../drugDetails/DrugDetailLeftPanel';
import { MedicationInformationProps } from '../medicationInformation/MedicationInformationBox';
import { getPatchStatusText, PatchStatus } from '../MedicationListsTabbedRouter/TabLists/PatchesMedicationList';
import { getComments } from '../MedicineAdministerDialog';
import { PatchLocationDTO, PatchLocations } from './PatchLocations';
import {PatchBodyBgImage, PatchLocationButton} from './PatchLocationVisual';
import { PatchUtils } from './PatchUtils';
import { getAdministrationError } from '../medicationInformation/MedicationInformationBox';
import { startOfDay } from 'date-fns';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { useRoundSchedule } from '../../../Rounds/services/RoundScheduleProvider';
import {useGroupPermissions} from "../../../../core/authz/PermissionsProvider";
import { DebugHover } from '../../../../core/utils/debughover';

export interface PatchAdministerProps extends MedicationInformationProps {
  drug: HSDrug;
  confirmationInitials?: string;
  confirmationUserId?: number;
  isSighting?: boolean;
  isUpdatePatch?: boolean;
  isReapplyPatch?: boolean;
  patchStatus?: PatchStatus;
  closeDialog?: () => void;
  hideButton?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  viewOnly?: boolean;
  observationTime?: Date;
  isLate?: boolean;
  hasBeenDosed?: boolean;

}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function PatchAdministerDialog(props: PatchAdministerProps) {
  const [open, setOpen] = useState(false);

  function onOpen() {
    const administrationError = getAdministrationError(props);
    if (administrationError) {
      toasts.error(administrationError);
      return;
    }
    toggleOpen(true);
  }

  function toggleOpen(open: boolean) {
    props.hideButton ? props.setOpen?.(open) : setOpen(open);
  }

  const getAdministerBtnDisabilityStatus = (): boolean => {
    return !props.administrationPermissions.canSightPatch;
  };



  return (
    <>
      {!props.hideButton &&
        (props.isUpdatePatch ? (
              <IconDotMenu style={{ cursor: 'pointer' }} onClick={onOpen} />
        ) : props.isReapplyPatch ? (
          <ActionButton data-testid="patch-action-button" onClick={onOpen}>
            {'REAPPLY'}
          </ActionButton>
        ) : props.isLate ? (
            <Text> MISSED <IconDotMenu style={{ cursor: 'pointer' }} onClick={onOpen} /></Text>
        ) : (
            <ActionButton data-testid="patch-action-button" onClick={onOpen} disabled={getAdministerBtnDisabilityStatus()}>
              {props.isSighting ? 'SIGHT' : props.patchStatus === PatchStatus.Removed ? 'REMOVE' : 'ADMINISTER'}
            </ActionButton>
        ))
      }
      <Dialog
        lazy
        closeButtonColor={'#fff'}
        open={props.hideButton ? props.open ?? false : open}
        onRequestClose={() => toggleOpen(false)}
        size="full"
      >
        <DialogContainer horizontal gap={1} data-testid="patch-action-dialog">
          <DrugDetailLeftPanel
            drug={props.drug}
            packedMedication={props.packedMedication}
            facilityGroupId={props.facilityGroupId}
            patient={props.patient}
          />
          <AdministerPatchForm
            {...props}
            onAdminister={async (drug: HSAdministeredDrug, doseTimestamp: string) => {
              const newDrug = await props.onAdminister?.(drug, doseTimestamp);
              toggleOpen(false);
              return newDrug;
            }}
            closeDialog={() => toggleOpen(false)}
          />
        </DialogContainer>
      </Dialog>
    </>
  );
}

interface FormFields {
  status?: PatchStatus;
  notes?: string;
}

// eslint-disable-next-line max-lines-per-function
function AdministerPatchForm(props: PatchAdministerProps) {
  const [selectedPatchLocation, setSelectedPatchLocation] = useState<PatchLocationDTO>();
  const roundSchedule = useRoundSchedule();
  const apiUtils = useApiUtils();
  const services = useSyncCenter();
  const user = useCurrentUser();
  const packedPatientDaysStore = useStore(services.packedPatientDays.store).store;
  const isRemovingPatch =
    PatchUtils.isPatchRemoval(props.drug) ||
    props.patchStatus === PatchStatus.Removed ||
    props.patchStatus === PatchStatus.FallenOff;
  const recentPatchLocations = PatchUtils.getRecentPatchLocations(apiUtils.patients, props.patient);
  const groupPermissions = useGroupPermissions();
  const canRecordNote = groupPermissions.canRecordNote;

  const form = useForm<FormFields>({
    defaultValues: {
      status: props.patchStatus,
      notes: props.currentDosedDrug ? getComments(props.currentDosedDrug) : '',
    },
  });

  useEffect(() => {
    if (
      props.isSighting ||
      props.isUpdatePatch ||
      props.viewOnly ||
      props.patchStatus === PatchStatus.Removed ||
      props.patchStatus === PatchStatus.FallenOff
    ) {
      const { activeDose: activePatch } = DrugUtils.getActivePreviousDoses(
        props.currentDosedDrug as HSAdministeredDrug,
        props.previousDosedDrugs,
        props.packedMedication?.hsId,
      );
      setSelectedPatchLocation(PatchLocations.find((pl) => pl.LocationNumber === activePatch?.patchLocationNumber));
    } else if (isRemovingPatch) {
      const { activePatch } = PatchUtils.getLastPatchMedicationDose(
        props,
        packedPatientDaysStore,
        apiUtils.residentDetails,
      );
      setSelectedPatchLocation(PatchLocations.find((pl) => pl.LocationNumber === activePatch?.patchLocationNumber));
    }
  }, [props.previousDosedDrugs]);

  useEffect(() => {
    form.trigger('notes');
  }, [selectedPatchLocation]);

  function onCreateObservation(newRound?: HSDoseRound) {
    // Refresh round schedule
    if (props.currentRound) {
      roundSchedule.set({
        ...(newRound ?? props.currentRound),
        version: ((newRound ?? props.currentRound).version ?? 0) + 1,
      });
    }
  }

  async function onSubmit(data: FormFields) {
    if (data.notes && !canRecordNote) {
      toasts.error('You do not have permission to record notes');
      return;
    }
    if (props.isSighting || props.isUpdatePatch || isRemovingPatch) {
      await PatchUtils.createPatchObservation(props, services, user, data.status);
      onCreateObservation();
      props.closeDialog!();
    } else if (props.isReapplyPatch) {
      const today = DateUtils.fromDate(startOfDay(new Date()));

      await administerPatchMedication(PatchStatus.Reapplied, data.notes);

      // update patch schedule
      await services.packedPatientDays.service.enqueue.createPatchUpdate({
        type: 'patch-update-create',
        request: { dcode: props.drug.drugCode, patient_id: props.patient.hsId, start_date: today, updated_at: today },
        drug: props.drug,
        patchPackedMedicationId: props.packedMedication?.hsId,
      });
      // update patch removal schedule
      const patchRemovalDrug = props.drugList?.find((d) => d.hsId === props.drug.patchRemovalDrugId);
      await services.packedPatientDays.service.enqueue.createPatchUpdate({
        type: 'patch-update-create',
        request: {
          dcode: patchRemovalDrug?.drugCode,
          patient_id: props.patient.hsId,
          start_date: today,
          updated_at: today,
        },
        drug: patchRemovalDrug!,
      });

      props.closeDialog!();
    } else {
      await administerPatchMedication(PatchStatus.Applied, data.notes);
    }
  }

  async function administerPatchMedication(patchStatus: PatchStatus, notes?: string) {
    if (notes && !canRecordNote) {
      toasts.error('You do not have permission to record notes');
      return;
    }

    const administeredDrug = apiUtils.residentDetails.generateAdministeredDrug(
      props.packedMedication!,
      props.drug,
      ReasonCode.Dosed,
      notes ? [notes] : [],
      user,
      props.confirmationInitials,
      props.confirmationUserId,
      undefined,
      selectedPatchLocation,
    );

    const newRound = (
      await props.onAdminister?.(
        administeredDrug,
        'doseTimestamp' in props.packedMedication! ? props.packedMedication.doseTimestamp! : '',
      )
    )?.round;

    await PatchUtils.createPatchObservation(props, services, user, patchStatus);
    onCreateObservation(newRound);
  }



  return (
    <Form form={form} onSubmit={onSubmit}>
      <Layout gap={1}>
        <PatchLocationSelectorFullBody
          isViewOnly={props.isSighting || props.isUpdatePatch || isRemovingPatch || props.viewOnly}
          selectedPatchLocation={selectedPatchLocation}
          recentlyPatchedLocations={recentPatchLocations}
          setSelectedPatchLocation={setSelectedPatchLocation}
        />
        <StatusSection
          isUpdateOrRemovePatch={props.isUpdatePatch || isRemovingPatch}
          patchStatus={props.patchStatus}
          formControl={form.control}
        />
        <PatchNotes
          isHidden={props.isSighting || props.isUpdatePatch || props.viewOnly || !props.administrationPermissions.canRecordNote}
          isRecentlyPatched={
            !isRemovingPatch && !!selectedPatchLocation && recentPatchLocations.includes(selectedPatchLocation)
          }
        />
        <DebugHover name={'PatchButton'} content={`props.viewOnly=${props.viewOnly}<BR>form.formState.isSubmitSuccessful=${form.formState.isSubmitSuccessful}<BR>selectedPatchLocation=${selectedPatchLocation}<BR>props.isUpdatePatch=${props.isUpdatePatch}<BR>isRemovingPatch=${isRemovingPatch}`}>
        <Button
          style={{width: '250px', margin: "1.5em auto"}}
          data-testid="patch-dialog-action-button"
          type="submit"
          fullWidth
          disabled={
            props.viewOnly ||
            form.formState.isSubmitSuccessful ||
            (!selectedPatchLocation && !props.isUpdatePatch && !isRemovingPatch && !props.isSighting)
          }
        >
          {getActionButtonText(props)}
        </Button>
        </DebugHover>
      </Layout>
    </Form>
  );
}

function getActionButtonText(props: PatchAdministerProps) {
  let actionButtonText = 'ADMINISTER';

  if (props.isSighting) {
    actionButtonText = 'SIGHT';
  } else if (props.isUpdatePatch) {
    actionButtonText = 'UPDATE STATUS';
  } else if (
    PatchUtils.isPatchRemoval(props.drug) ||
    props.patchStatus === PatchStatus.Removed ||
    props.patchStatus === PatchStatus.FallenOff
  ) {
    actionButtonText = 'REMOVE PATCH';
  } else if (props.isReapplyPatch) {
    actionButtonText = 'REAPPLY PATCH';
  }

  return actionButtonText;
}

export function PatchLocationSelectorFullBody(props: {
  isViewOnly?: boolean;
  selectedPatchLocation?: PatchLocationDTO;
  recentlyPatchedLocations: (PatchLocationDTO | undefined)[];
  setSelectedPatchLocation: (selectedPatchLocation: PatchLocationDTO) => void;
}) {
  const { selectedPatchLocation, recentlyPatchedLocations, setSelectedPatchLocation } = props;



  return (
    <>
      <WhiteText size="large" weight="bold">{props.isViewOnly ? 'Applied to area' : 'Select area'}</WhiteText>
      <Layout>
        <PatchBodyBgImage>
          {PatchLocations.map((value, index) => (
            <PatchLocationButton
              locationNumber={value.LocationNumber}
              key={index}
              isSelected={value === selectedPatchLocation}
              recentlyPatched={recentlyPatchedLocations.includes(value)}
              onClick={() => setSelectedPatchLocation(value)}
              style={value.PositionInfo}
              disabled={props.isViewOnly}
              data-testid={`patch-location-selector-${index + 1}`}
            >
              {value.LocationNumber}
            </PatchLocationButton>
          ))}
        </PatchBodyBgImage>
        {selectedPatchLocation && (
          <PatchAreaSelection justify="center" padding={'10px'}>
            {selectedPatchLocation.PatchArea?.toUpperCase()} {props.isViewOnly ? 'APPLIED' : 'SELECTED'}
          </PatchAreaSelection>
        )}
      </Layout>
    </>
  );
}



function StatusSection(props: {
  isUpdateOrRemovePatch?: boolean;
  patchStatus?: PatchStatus;
  formControl: Control<FormFields>;
}) {
  const statuses: PatchStatus[] = [PatchStatus.Removed, PatchStatus.FallenOff];

  if (props.patchStatus === PatchStatus.ToSight || props.patchStatus === PatchStatus.Sighted) {
    statuses.unshift(PatchStatus.Sighted);
  } else if (props.patchStatus === PatchStatus.FallenOff || props.patchStatus === PatchStatus.Reapplied) {
    statuses.unshift(PatchStatus.Reapplied);
  }

  const statusOptions = statuses.map((status) => {
    return { label: getPatchStatusText(status), value: status };
  });



  return (
    <>
      {props.isUpdateOrRemovePatch && (
        <Layout gap={1}>
          <Text size="large">Update status</Text>
          <RadioGroup name="status" options={statusOptions} cols={3} control={props.formControl} required />
        </Layout>
      )}
    </>
  );
}

function PatchNotes(props: { isHidden?: boolean; isRecentlyPatched: boolean }) {
  return (
    <>
      {!props.isHidden && (
        <FormGroup fullWidth>
          <TextArea
            name="notes"
            placeholder="Add note..."
            rows={3}
            fullWidth
            rules={{
              validate: (notes: string) => {
                if (props.isRecentlyPatched && notes.length === 0) {
                  return 'A patch has been applied to this location in the last four weeks. A note is required if you apply the patch here again.';
                }
                return true;
              },
            }}
          />
        </FormGroup>
      )}
    </>
  );
}

const DialogContainer = styled(Layout)`
  padding: 1em;
  grid-template-columns: 3fr 5fr;
`;

const WhiteText = styled(Text)`
  color: ${(props) => props.theme.backgrounds.default.fg};
`;

const ActionButton = styled(Button)`
  min-width: 10rem;
  &:disabled {
    cursor: not-allowed;
  }
`;

const PatchAreaSelection = styled(Layout)`
  background-color: ${colors.bg_purple_400};
`;
