import { format } from 'date-fns';
import itiriri from 'itiriri';
import React from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HSPatient } from 'server-openapi';
import styled from 'styled-components';
import { DateUtils } from '../../core/utils/dateUtils';
import { RadioGroup } from '../../kit/Forms/RadioGroup';
import { SelectOption } from '../../kit/Forms/Select';
import { IconBack } from '../../kit/Icons/Back';
import { Image } from '../../kit/Image';
import { Layout } from '../../kit/Layout';
import { Paper } from '../../kit/Paper';
import { Text } from '../../kit/Text';
import { useSyncCenter } from '../../syncstream/SyncCenterProvider';
import { useStore } from '../../core/storage/hooks/UseStore';
import { useApiUtils } from '../../syncstream/utils/hooks/useApiUtils';
import { DashboardNavbar } from '../Dashboard/DashboardPage/components/DashboardNavbar';

enum OccupancyFilter {
  All,
  Deceased,
  Discharged,
  CurrentOccupant,
}

interface IParams {
  facilityGroupId: string;
}

export function OccupancyStatusPage() {
  // Get the currently selected facility from the URL parameters.
  const { facilityGroupId } = useParams<IParams>();

  const services = useSyncCenter();
  const [filter, setFilter] = useState<OccupancyFilter>(OccupancyFilter.All);

  const facilityGroupsStore = useStore(services.facilityGroups.store).store;
  const facilityGroup = itiriri(facilityGroupsStore.values()).find((f) => f.hsId?.toString() === facilityGroupId);

  const facilityStore = useStore(services.facilities.store).store;
  const facilities = itiriri(facilityStore.values())
    .filter((facility) => facility.facilityGroupId?.toString() === facilityGroupId)
    .toArray();

  const patientsStore = useStore(services.patients.store).store;
  const patients = itiriri(patientsStore.values())
    .toArray()
    .filter((patient) => facilities.some((facility) => facility.hsId === patient.facility));

  const history = useHistory();

  return (
    <Layout>
      <DashboardNavbar facilityGroup={facilityGroup} />
      <Container gap={1}>
        <Layout horizontal gap={1.5}>
          <IconBack onClick={() => history.push(`/facility-group/${facilityGroupId}`)} />
          <Text weight={'bold'} size="large" style={{ color: 'white' }}>
            Occupancy Status
          </Text>
        </Layout>
        <RadioGroupWrapper horizontal gap={1}>
          <Text>Filter By</Text>
          <RadioGroup
            name="occupancyFilter"
            cols={3}
            options={occupancyFilterOptions}
            value={filter}
            onChange={(_, value) => setFilter(value)}
          />
        </RadioGroupWrapper>
        {filterOccupancy(patients, filter).map((patient) => (
          <PatientOccupancyCard patient={patient} key={patient.hsId} />
        ))}
      </Container>
    </Layout>
  );
}

function PatientOccupancyCard(props: { patient: HSPatient }) {
  const services = useSyncCenter();
  const patientUtils = useApiUtils().patients;
  const facilitiesStore = useStore(services.facilities.store).store;
  const facility = itiriri(facilitiesStore.values()).find((facility) => facility.hsId === props.patient.facility);

  if (!facility) {
    throw new Error('facility not found!');
  }

  const patientStatus: { status: string; date?: Date } = (() => {
    if (props.patient.deceasedDate) {
      return { status: 'Deceased', date: DateUtils.toDate(props.patient.deceasedDate) };
    }
    if (props.patient.dischargedDate) {
      return { status: 'Discharged', date: DateUtils.toDate(props.patient.dischargedDate) };
    }
    return { status: 'Current Occupant', date: undefined };
  })();

  return (
    <Paper secondary>
      <InformationBox horizontal gap={1}>
        {props.patient.imageUrl && (
          <PatientImage src={props.patient.imageUrl} facilityGroupId={facility.facilityGroupId!} />
        )}
        <Layout>
          <Text weight={'bold'}>{patientUtils.getDisplayPatientName(props.patient)}</Text>
          <Text>
            {facility?.name} Rm {props.patient.roomNumber}
          </Text>
        </Layout>
        <Layout style={{ marginLeft: 'auto' }}>
          <Text weight={'bold'}>{patientStatus.status}</Text>
          {patientStatus.date && (
            <Text>
              {DateUtils.dateTo24HourTimeString(patientStatus.date)} {format(patientStatus.date, 'dd MMMM yyyy')}
            </Text>
          )}
        </Layout>
      </InformationBox>
    </Paper>
  );
}

function filterOccupancy(patients: HSPatient[], filter: OccupancyFilter) {
  return patients.filter((patient) => {
    switch (filter) {
      case OccupancyFilter.All:
        return true;
      case OccupancyFilter.Deceased:
        return patient.deceasedDate;
      case OccupancyFilter.Discharged:
        return patient.dischargedDate;
      case OccupancyFilter.CurrentOccupant:
        return !patient.deceasedDate && !patient.dischargedDate;
    }
  });
}

const Container = styled(Layout)`
  margin: 1.5rem;
`;

const PatientImage = styled(Image)`
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 999999999px;
`;

const InformationBox = styled(Layout)`
  display: flex;
  align-items: center;
  padding: 0.875rem;
  width: 100%;
`;

const RadioGroupWrapper = styled(Layout)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.backgrounds.default.fg};
`;

const occupancyFilterOptions: SelectOption<OccupancyFilter>[] = [
  {
    label: 'All',
    value: OccupancyFilter.All,
  },
  {
    label: 'Deceased',
    value: OccupancyFilter.Deceased,
  },
  {
    label: 'Discharged',
    value: OccupancyFilter.Discharged,
  },
];
