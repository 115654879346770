import * as React from 'react';
import { SVGProps } from 'react';

const IconPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1.5em" height="1.5em" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={16.5} cy={16.5} r={16.5} fill="#fff" />
    <path
      d="M12.147 9.778c.053.79.186 1.564.4 2.302l-1.067 1.067a13.18 13.18 0 0 1-.676-3.37h1.343Zm8.764 10.684a11.33 11.33 0 0 0 2.311.4v1.325a13.711 13.711 0 0 1-3.378-.667l1.067-1.058ZM13 8H9.889C9.4 8 9 8.4 9 8.889 9 17.236 15.764 24 24.111 24c.489 0 .889-.4.889-.889V20.01c0-.489-.4-.889-.889-.889-1.102 0-2.178-.178-3.173-.507a.748.748 0 0 0-.276-.044.91.91 0 0 0-.63.258l-1.956 1.955a13.465 13.465 0 0 1-5.858-5.858l1.955-1.955a.892.892 0 0 0 .223-.907 10.097 10.097 0 0 1-.507-3.173c0-.489-.4-.889-.889-.889Z"
      fill="#AE48FB"
    />
  </svg>
);

export default IconPhone;
