import * as React from 'react';
import { SVGProps } from 'react';

const SvgTick = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.11 12.879 3.366 8.116l-.177-.178-.177.178-1.64 1.647-.175.176.175.177 6.56 6.587.176.178.177-.178L22.341 2.587l.176-.177-.176-.176-1.64-1.647-.177-.178-.177.178L8.11 12.88Z"
      fill={props.color ?? '#4E2974'}
      stroke={props.color ?? '#4E2974'}
      strokeWidth={0.5}
    />
  </svg>
);

export default SvgTick;
