import { HSFacilityGroup } from 'server-openapi';
import { PersistentQueue } from '../core/queue/PersistentQueue';
import { IStorage } from '../core/storage/Contract';
import { SyncStreamAPI } from './api';
import { ISyncService } from './SyncCenter';
import { SyncUtils } from './utils/SyncUtils';

interface Operation {
  type: void;
  payload: HSFacilityGroup;
}

export class SyncFacilityGroups implements ISyncService {
  get name(): string {
    return 'SyncFacilityGroups';
  }

  constructor(
    private api: SyncStreamAPI,
    private storage: IStorage<HSFacilityGroup>,
    private queue: PersistentQueue<Operation>,
  ) {}
  async syncDown() {
    const facilityGroups = await this.syncDownWithChangeNumber(0);

    await this.storage.clear();
    await this.storage.setMany(
      facilityGroups
        .map((facilityGroup) => ({
          key: this.storage.get_key!(facilityGroup),
          value: facilityGroup,
        })),
    );
  }

  async syncDownWithChangeNumber(changeNumber: number): Promise<HSFacilityGroup[]> {
    // TODO: storage locking so that we can be sure the UI
    // didn't accidently change a resource in between the API
    // giving us fresh data and updating the storage backend.
    const facilityGroups = await this.api.facilityGroups.facilityGroupsListFacilityGroups();
    return facilityGroups.data;
  }

  async syncUp() {
    // Do nothing
  }

  async clear() {
    await this.storage.clear();
    await this.queue.clear();
  }

  async hasQueuedData() {
    return (await this.queue.length()) > 0;
  }
  isAllowed(canUserAccessMedication: boolean): boolean {
    // Only if you can view a round.
    return true;
  }
}
