import React from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonProps } from './Button';

interface Props extends ButtonProps {
  to: string;
}

export const LinkButton = (props: Props) => {
  return (
    <Link to={props.to} style={{ textDecoration: 'none', pointerEvents: props.disabled ? 'none' : 'auto' }}>
      <Button {...props} />
    </Link>
  );
};
