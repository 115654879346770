import * as Sentry from '@sentry/react';
import axios, {AxiosError} from "axios";
import { merge } from 'lodash-es';

interface Options {
  version: string;
  dsn: string;
  environment: string;
  build: string;
}

export function setSentryUser(id?: string, username?: string, email?: string) {
  Sentry.setUser({ id, username, email });
}

export function sentry(options: Options) {
  Sentry.init({
    dsn: options.dsn,
    environment: options.environment,
    tracesSampleRate: 0.1,
    autoSessionTracking: true,
    beforeSend: (event) => {
      return merge(event, {
        tags: {
          version: options.version,
          build: options.build,
        },
      });
    },
  });
}

const traceRegexp = /-([0-9a-f]{16})-([0-9a-f]{8})-/;

export function beforeCapture(scope: Sentry.Scope, error: Error | null) {
  const context = getContext(error);
  if (context) {
    scope.setContext("API Trace", context);
  }
}

export function logApiError(error: any) {
  const context = getContext(error);
  Sentry.captureException(error, scope => {
    if (context) {
      scope.setContext("API Trace", context);
    }
    return scope;
  });
}

function getContext(error: any): Record<string, unknown> | undefined {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError;
    if (!axiosError.request?.headers) {
      return undefined;
    }
    const traceParent = axiosError.request.headers["traceparent"];
    const match = traceRegexp.exec(traceParent);
    if (match) {
      return {
        traceId: match[1],
        spanId: match[2],
        url: axiosError.request.url
      };
    }
  }

  return undefined;
}
