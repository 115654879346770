export class Lock {
  private currentHolder: Promise<void>;

  constructor() {
    // initially the lock is "unlocked" which is represented
    // here by an already completed (resolved) promise.
    this.currentHolder = Promise.resolve();
  }

  // calling acquire will await the current lock holder.
  // when the current holder releases the lock "acquire" will
  // return with a callback function that should be used to release
  // the lock acquisition.
  public async acquire(): Promise<Callback> {
    const holder = this.currentHolder;

    // create a new promise that can be resolved by
    // calling "resolver".
    // this is essentially the "lock" primitive.
    // the next call to "acquire" will end up awaiting
    // this promise.
    let resolver: Callback;
    this.currentHolder = new Promise((resolve) => {
      resolver = resolve;
    });

    // await the promise from the previous "acquire" caller.
    // if someone else has the lock then we're waiting for
    // a promise that will resolve when they call the "resolver"
    // function
    await holder;

    // return the "resolver" function so that the caller
    // can resolve the "currentHolder" promise.
    return resolver!;
  }

  // run is a convenience function that will run the given callback
  // after successfully acquiring the lock ensuring the lock is always
  // released when the callback returns or throws.
  public async run<T = unknown>(callback: () => Promise<T>): Promise<T> {
    const release = await this.acquire();
    try {
      return await callback();
    } finally {
      release();
    }
  }
}

type Callback = () => void;
