import React from 'react';
import { HSDrug, HSFacility, HSPatient } from 'server-openapi';
import { Layout } from '../../../../../kit/Layout';
import { OrderPatientResupplyHistory } from './Components/PatientResupplyHistoryTab';
import { format } from 'date-fns';
import styled from 'styled-components';
import { Image } from '../../../../../kit/Image';
import { OrderPatientResupply } from './Components/PatientResupplyTab';
import itiriri from 'itiriri';
import Tab from '../../../../../kit/Tabs/Tab';
import Tabs from '../../../../../kit/Tabs/Tabs';
import { OrderResupplyProps } from '../OrderResupplyDialog';
import { OrderNotesProps } from '../OrderNotesDialog';
import { DateUtils } from '../../../../../core/utils/dateUtils';
import { Order } from '../../../../../common/Order';
import { useApiUtils } from '../../../../../syncstream/utils/hooks/useApiUtils';
import {colors} from "../../../../../kit/Theme/Theme";
import {RequirePermission} from "../../../../../components/RequirePermission/RequirePermission";
import {useGroupPermissions} from "../../../../../core/authz/PermissionsProvider";

interface Props {
  patient: HSPatient;
  facilitiesStore: ReadonlyMap<string, HSFacility>;
  patientsStore: ReadonlyMap<string, HSPatient>;
  ordersStore: ReadonlyMap<string, Order>;
  drugsStore: ReadonlyMap<string, HSDrug>;
  openOrderResupply?: (orderResupply: OrderResupplyProps) => void;
  openOrderNotes?: (orderNotes: OrderNotesProps) => void;
}

export function PatientOrders(props: Props) {
  const facility = props.facilitiesStore.get(props.patient.facility!.toString())!;

  if (!facility) {
    throw new Error('facility not found!');
  }

  const patientUtils = useApiUtils().patients;
  const groupPermissions = useGroupPermissions();
  const orders = itiriri(props.ordersStore.values())
    .filter((o) => o.hsPatientId === props.patient.hsId!)
    .sort((lhs, rhs) =>
      DateUtils.compareDatesDescending(DateUtils.ensureDate(lhs.dispatchedAt) ?? DateUtils.ensureDate(lhs.orderedAt)!,
          DateUtils.ensureDate(rhs.dispatchedAt) ?? DateUtils.ensureDate(rhs.orderedAt)!),
    )
    .toArray();
  return (
    <RequirePermission hasPermission={groupPermissions.canViewResupplyOrder}>
      <Layout gap={1.5}>
        <Layout horizontal gap={0.5}>
          {props.patient.imageUrl && (
            <PatientImage src={props.patient.imageUrl} facilityGroupId={facility.facilityGroupId!} />
          )}
          <Layout>
            <h2>{patientUtils.getDisplayPatientName(props.patient)}</h2>
            <Heading>
              {props.patient.roomNumber}, {facility?.name} &bull; DOB:{' '}
              {props.patient.dateOfBirth && format(DateUtils.toOffsetlessDate(props.patient.dateOfBirth), 'dd MMM yyyy')}{' '}
              &bull; MPS ID: {props.patient.hsId}
            </Heading>
          </Layout>
        </Layout>

        <TabDisplay>
          <Tabs>
            <Tab key="0" title="Resupply history">
              <OrderPatientResupplyHistory
                patient={props.patient}
                orders={orders}
                facilitiesStore={props.facilitiesStore}
                patientsStore={props.patientsStore}
                drugsStore={props.drugsStore}
                openOrderResupply={props.openOrderResupply}
                openOrderNotes={props.openOrderNotes}
              />
            </Tab>
            <Tab key="1" title="Medicine available for resupply">
              <OrderPatientResupply facility={facility} patient={props.patient} orders={orders} />
            </Tab>
          </Tabs>
        </TabDisplay>
      </Layout>
    </RequirePermission>
  );
}

const PatientImage = styled(Image)`
    border-radius: 50%;
    width: 80px;
    height: 80px;
`;

const Heading = styled.h3`
  font-weight: bold;
`;

const TabDisplay = styled.div`
  .selected {
      font-size: 16pt;
      padding: 1em;
      background-color: ${() => colors.bg_purple_700};
      color: white;
  }
  .unselected {
      font-size: 16pt;
      padding: 1em;
      background-color: ${() => colors.bg_purple_300};
      color: black;
  }
  
`;
