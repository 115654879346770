import React from 'react';
import styled from 'styled-components';
import { FormInput, formInput } from './FormInput';
import { StyledTextInputProps } from './TextInput';

interface Props extends FormInput<string>, StyledTextInputProps {
  name: string;
  fullWidth?: boolean;
  autoComplete?: string;
  disabled?: boolean;
  rows?: number;
  autoFocus?: boolean;
}

const StyledTextArea = styled.textarea<StyledTextInputProps>`
  padding: ${(props) => props.theme.forms.input.padding};
  border: solid;
  border-radius: ${(props) => props.theme.forms.input.border.radius};
  border-width: ${(props) => props.theme.forms.input.border.width};
  border-color: ${(props) => props.theme.forms.input.border.color};
  font-weight: ${(props) => props.theme.typography.defaults.fontWeight};
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  resize: none;

  &:disabled {
    background: rgba(255, 255, 255, 0.6);
  }
`;

export const TextArea = formInput(function (props: Props) {
  const { onChange, ...otherProps } = props;
  return (
    <StyledTextArea
      {...otherProps}
      value={props.value ?? ''}
      onChange={(event) => {
        onChange?.(event, event.currentTarget.value);
      }}
      autoFocus={props.autoFocus}
    />
  );
});
