import React, { useEffect, useState } from 'react';
import { createServiceProvider } from '../../../context/AppServiceProvider';
import { Logger } from '../../../core/logger/logger';
import { useApiUtils } from '../../../syncstream/utils/hooks/useApiUtils';
import { RoundScheduleItem } from '../../../syncstream/utils/RoundUtils';
import { HSDoseRound } from 'server-openapi';
import { useStore } from '../../../core/storage/hooks/UseStore';
import { useSyncCenter } from '../../../syncstream/SyncCenterProvider';
import {useGroupPermissions} from "../../../core/authz/PermissionsProvider";
import {useGetScheduleFromParameters} from "../../../syncstream/utils/hooks/GetSchedule";
import {DateUtils} from "../../../core/utils/dateUtils";
import {useAppState} from "../../../context/AppStateProvider";
import itiriri from "itiriri";

export interface RoundScheduleContext {
  set: (round: HSDoseRound | undefined) => void;
  roundSchedule?: RoundScheduleItem[];
}

const logger = new Logger('RoundScheduleProvider');
const [ServiceProvider, useHook] = createServiceProvider<RoundScheduleContext>();

export const useRoundSchedule = useHook;

interface Props {
  children?: React.ReactNode;
}

export const RoundScheduleProvider = (props: Props) => {
  const services = useSyncCenter();
  const apiUtils = useApiUtils();
  const [round, setRound] = useState<HSDoseRound>();
  const [roundSchedule, setRoundSchedule] = useState<RoundScheduleItem[]>();
  const roundStore = useStore(services.rounds.store).store;
  const permissions = useGroupPermissions();
  const getScheduleFromParameters = useGetScheduleFromParameters();
  const appState = useAppState();
  const facilityStore = useStore(services.facilities.store).store;
  useEffect( () => {
    logger.info('Computing round schedule.');
    const facilityGroupId = +(appState.state.selectedFacilityGroupId ?? "0");
    if (round) {
      const interval = apiUtils.rounds.getRoundWindow(DateUtils.toDate(round.createdAt!), facilityGroupId);
      const roundFromStore = roundStore.get(round.clinicalSystemId!); //this ensures that the round from indexed DB is used
      const facilities = itiriri(facilityStore.values()).toArray().filter(x => x.facilityGroupId === facilityGroupId);
      const newSchedule = roundFromStore ? apiUtils.rounds.getScheduleFromRound(roundFromStore, permissions, apiUtils.residentDetails, getScheduleFromParameters(interval, facilities.map(x => x.hsId ?? 0))) : undefined;
      setRoundSchedule(newSchedule);
    } else {
      setRoundSchedule(undefined);
    }
    logger.info('Completed computing round schedule.');
  }, [round]);

  function set(round: HSDoseRound | undefined): void {
    setRound(round);
  }

  return <ServiceProvider value={{ roundSchedule: roundSchedule, set: set }}>{props.children}</ServiceProvider>;
};
