import {
  HSFacility,
  HSPatient,
  CreateHsPatientProgressNoteReq,
  HSPatientProgressNote,
  UpdatePatientReq, HSDoseRound,
} from 'server-openapi';
import { PersistentQueue } from '../core/queue/PersistentQueue';
import {Entry, IStorage} from '../core/storage/Contract';
import { SyncStreamAPI } from './api';
import itiriri from 'itiriri';
import {IFacilityGroupSyncService, ISyncService} from './SyncCenter';
import { SyncUtils } from './utils/SyncUtils';
import { Logger } from '../core/logger/logger';
import { v4 as uuidv4 } from 'uuid';
import { assertNotUndefined } from '../core/utils/assertionUtils';
import { DateUtils } from '../core/utils/dateUtils';
import { startOfDay, subDays } from 'date-fns';
import {MemoryCache} from "../core/storage/MemoryCache";
import {parseInt} from "lodash-es";

export type PatientOp = CreatePatientProgressNoteOp | UpdatePatientInfoOp;

export interface CreatePatientProgressNoteOp {
  type: 'progress-note-create';
  clinicalSystemId?: string;
  patientId: string;
  request: CreateHsPatientProgressNoteReq;
}

export interface UpdatePatientInfoOp {
  type: 'update-patient-info';
  request: UpdatePatientReq;
}

const logger = new Logger('SyncPatients');

export class SyncPatients implements IFacilityGroupSyncService {
  get name(): string {
    return 'SyncPatients';
  }

  private async isStale(p: HSPatient): Promise<boolean> {
    return !!((!p.active && p.lastUpdated && p.lastUpdated < DateUtils.fromDate(subDays(startOfDay(new Date()), 14))));
  }
  private async isFacilityGroup(p: HSPatient, facilityGroupId: string): Promise<boolean> {
    const facilityGroup = await this.facilitiesStore.get(p.facility!.toString());
    return facilityGroup?.facilityGroupId?.toString() == facilityGroupId;
  }
  // Initialise the patient store to an empty store.  It will get written properly when the load method is called.
  constructor(
    private api: SyncStreamAPI,
    private storage: MemoryCache<HSPatient>,
    private facilitiesStore: IStorage<HSFacility>,
    private latestChangeNumbers: IStorage<number | undefined>,
    private queue: PersistentQueue<PatientOp>,
    private epochStore: MemoryCache<string>
  ) {

  }
  async load(facilityGroupId: string): Promise<void> {
    await this.storage.reset(async (p) => {
          return await this.isFacilityGroup(p, facilityGroupId);
    });
  }

  //eslint-disable-next-line sonarjs/cognitive-complexity
  async syncDown(facilityGroupId?: string) {
    const facilitiesToSync = await SyncUtils.getFacilitiesForGroup(facilityGroupId, this.facilitiesStore);
    const changeNumber = await SyncUtils.getChangeNumberForFacilities(
        facilitiesToSync.map((x) => x.hsId!),
        this.latestChangeNumbers,
    );
    //fetch patients for all facilities within facility group
    const patientData = await this.syncFacilityDown(
      facilityGroupId!, //non null assertion on facility group id
      facilitiesToSync.map((facility) => facility.hsId!),
      changeNumber);

    await this.storage.setMany(
      patientData
        .map((patient) => ({
          key: this.storage.get_key!(patient),
          value: patient,
        }))
    );

    await SyncUtils.setChangeNumberForFacilities(
        facilitiesToSync.map((x) => x.hsId!),
        this.latestChangeNumbers,
        patientData
    );
  }

  private async syncFacilityDown(
    facilityGroupId: string,
    facilityIds: number[],
    changeNumber: number,
  ): Promise<HSPatient[]> {
    const pageSize = 200;
    //changeNumber = isFinite(changeNumber) ? changeNumber : 0;
    const patients = await this.api.patients.patientsListPatients(
      parseInt(facilityGroupId),
      changeNumber,
      pageSize,
    );
    if (patients.data.length === pageSize) {
      return [
        ...patients.data,
        ...(await this.syncFacilityDown(facilityGroupId, facilityIds, SyncUtils.getLatestChangeNumber(patients.data)!)),
      ];
    }
    return patients.data;
  }

  enqueue = {
    createPatientProgressNote: async (req: CreatePatientProgressNoteOp) => {
      const progressNoteClinicalSystemId = uuidv4();
      req.request.patientProgressNote.clinicalSystemId = progressNoteClinicalSystemId;

      const patient = await this.storage.get(req.patientId);

      const createdNote: HSPatientProgressNote = {
        ...req.request.patientProgressNote,
        clinicalSystemId: progressNoteClinicalSystemId,
      };

      if (assertNotUndefined(patient)) {
        const progressNotes = [...(patient.patientProgressNotes ?? []), createdNote];
        const updatedPatient = { ...patient, patientProgressNotes: progressNotes };

        await this.storage.set(req.patientId, updatedPatient);
        await this.queue.unshift({
          ...req,
          request: {
            ...req.request,
            patientProgressNote: createdNote,
          },
        });
      }

      return createdNote;
    },
    updatePatientInfo: async (req: UpdatePatientInfoOp) => {
      //add updated patient object to local store
      const updatedPatient = {
        key: req.request.patient.hsId!.toString(),
        value: req.request.patient,
      };

      await this.storage.set(updatedPatient.key, updatedPatient.value);
      //add the request to the queue
      await this.queue.unshift({ ...req });
      return updatedPatient;
    },
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  async syncUp() {
    const entries = new Map<string, HSPatient>();
    for await (const delivery of this.queue.iterate()) {
      try {
        // eslint-disable-next-line sonarjs/no-small-switch
        switch (delivery.value.type) {
          case 'progress-note-create':
            const storedPatientCreateProgressNote =
              (await this.storage.get(delivery.value.patientId)) ?? entries.get(delivery.value.patientId);

            if (storedPatientCreateProgressNote?.hsId === undefined) {
              throw new Error('Patient id not found when creating progress note');
            }

            delivery.value.request.patientId = storedPatientCreateProgressNote.hsId;

            const newNote = await this.api.patientProgressNotes.patientProgressNoteCreateNote(delivery.value.request);

            entries.set(delivery.value.patientId, {
              ...storedPatientCreateProgressNote,
              patientProgressNotes: [
                ...(storedPatientCreateProgressNote.patientProgressNotes?.map((note) =>
                  note.clinicalSystemId === newNote.data.clinicalSystemId ? newNote.data : note,
                ) ?? []),
              ],
            });
            break;
          case 'update-patient-info':
            //fetch the stored patient from local storage
            const storedPatient =
              (await this.storage.get(delivery.value.request.patient.hsId!.toString())) ??
              entries.get(delivery.value.request.patient.hsId!.toString());
            //send the updated patient object to MRS
            const updatedPatient = await this.api.patients.patientsUpdate(delivery.value.request);

            entries.set(delivery.value.request.patient.hsId!.toString(), {
              ...updatedPatient.data,
              patientProfiles: storedPatient?.patientProfiles,
              patientProgressNotes: storedPatient?.patientProgressNotes,
              patientAllergies: storedPatient?.patientAllergies,
            });

            break;
        }
        await delivery.complete();
      } catch (error) {
        logger.error('Sync patients failed', error);
        await delivery.failed();
      }
    }
    await this.storage.setMany(
      itiriri(entries.entries())
        .map((entry) => ({ key: entry[0], value: entry[1] }))
        .toArray()
    );
  }

  async clear() {
    await this.storage.clear();
    await this.latestChangeNumbers.clear();
    await this.queue.clear();
  }

  async hasQueuedData() {
    return (await this.queue.length()) > 0;
  }
  isAllowed(canUserAccessMedication: boolean): boolean {
    // Only if you can view a round.
    return canUserAccessMedication;
  }
  async archive(): Promise<string[]> {
    const keysToDelete: string[] = [];
    for (let [k, v] of (await this.storage.all()).entries()) {
      if (await this.isStale(v)) {
        keysToDelete.push(k)
      }
    }
    await this.storage.deleteMany(keysToDelete);
    return keysToDelete;
  }

  setEncryptionVersion(version: number): void {
    this.storage.compressOnSave = (version > 1);
  }
  async rewrite(): Promise<void> {
    const entries: Entry<HSPatient>[] = [...(await this.storage.all())].map((keyValueArray) => {
      return {
        key: keyValueArray[0],
        value: keyValueArray[1]
      };
    });
    return await this.storage.setMany(entries);
  }
}
