import * as React from 'react';

export function IconRedLight() {
  return <IconLight fill={'#EA2A2A'} />;
}

export function IconOrangeLight() {
  return <IconLight fill={'#F39200'} />;
}

export function IconGreenLight() {
  return <IconLight fill={'#A4EA2A'} />;
}

export function IconLight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="9.5" cy="10" rx="9.5" ry="10" transform="rotate(-180 9.5 10)" fill={props.fill ?? 'white'} />
    </svg>
  );
}
