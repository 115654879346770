import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { adminTheme } from '../../kit/Theme/Theme';
import styled, { createGlobalStyle, ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import urljoin from 'url-join';
import { OnboardingPage } from './OnboardingPage/OnboardingPage';
import { FacilitySettingsPage } from './FacilitySettings/FacilitySettingsPage';
import { UserDetailsPage } from './UserDetailsPage/UserDetailsPage';
import { GlobalSettingsPage } from './GlobalSettings/GlobalSettingsPage';

export function Admin() {
  const { path } = useRouteMatch();

  return (
    <StyledComponentsThemeProvider theme={adminTheme}>
      <AdminGlobalStyle />
      <AdminContentContainer>
        <Switch>
          <Redirect exact path={path} to={urljoin(path, 'onboarding')} />
          <Route path={urljoin(path, 'onboarding')} component={OnboardingPage} />
          <Route
            exact
            path={urljoin(path, 'facility-group/:facilityGroupId/settings/users/:userId')}
            component={UserDetailsPage}
          />
          <Route path={urljoin(path, 'facility-group/:facilityGroupId/settings')} component={FacilitySettingsPage} />
          <Route path={urljoin(path, '/global-settings')} component={GlobalSettingsPage} />
        </Switch>
      </AdminContentContainer>
    </StyledComponentsThemeProvider>
  );
}

const AdminContentContainer = styled.div`
  height: 100%;
  color: ${(p) => p.theme.typography.defaults.color};
`;

const AdminGlobalStyle = createGlobalStyle`
  body {
    background: ${(props) => props.theme.backgrounds.default.bg};
  }
`;
