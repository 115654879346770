import {isAfter, subWeeks} from 'date-fns';
import itiriri from 'itiriri';
import {
  HSPatchObservation,
  CreatePatchObservationsReq,
  HSPatient,
  HSPackedPatientDay,
  HSDrug,
  HSPackedMedication,
} from 'server-openapi';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { AppServices } from '../../../../syncstream/SyncCenterProvider';
import { DrugUtils } from '../../../../syncstream/utils/DrugUtils';
import { PatientUtils } from '../../../../syncstream/utils/PatientUtils';
import { ResidentDetailsUtils } from '../../../../syncstream/utils/ResidentDetailsUtils';
import { PatchStatus } from '../MedicationListsTabbedRouter/TabLists/PatchesMedicationList';
import { PatchAdministerProps } from './PatchAdministerDialog';
import { PatchLocationDTO, PatchLocations } from './PatchLocations';
import { User } from 'oidc-client';

export const patchRemovalIdentifier = 'REMOVE PATCH';

export interface PatchOperationData {
  packedMedicationId: number;
  patchStatus: PatchStatus;
  patchTime: string;
}

export interface PatchObservationInfo {
  patchStatus: PatchStatus;
  createdAt: string;
  userSubjectId: string;
  comments: string[];
}

export const PatchUtils = {
  isPatch: (drug?: HSDrug) => {
    return !!drug && (drug.formAbbreviation === 'PTCH' || PatchUtils.isPatchRemoval(drug));
  },
  isPatchRemoval: (drug: HSDrug) => {
    return ((!!drug?.description && drug.description.endsWith(patchRemovalIdentifier)) ||
            (!!drug?.name && drug.name.endsWith(patchRemovalIdentifier)));
  },
  createPatchObservation: async (
    props: PatchAdministerProps,
    services: AppServices,
    user: User,
    status?: PatchStatus,
  ) => {
    const newStatus = props.isSighting ? PatchStatus.Sighted : status;
    const patchObservation: HSPatchObservation = {
      patientId: props.patient.hsId,
      facilityId: props.patient.facility,
      active: true,
      createdAt: DateUtils.fromDate(new Date()),
      medicationId: props.packedMedication?.medicationId,
      drugId: props.drug.hsId,
      operation:
        props.packedMedication?.hsId +
        ':' +
        newStatus +
        (props.observationTime ? ':' + DateUtils.fromDate(props.observationTime) : ''),
      lastUpdatedBySubjectId: user.profile.sub,
    };
    const patchObservationReq: CreatePatchObservationsReq = {
      patchObservation: patchObservation,
      facilityGroupId: props.facilityGroupId,
    };

    await services.patchObservations.service.enqueue.createPatchObservation({
      type: 'patch-observation-create',
      request: patchObservationReq,
    });
  },
  getRecentPatchLocations: (patientUtils: PatientUtils, patient: HSPatient): (PatchLocationDTO | undefined)[] => {
    const patientAdministeredDoses = patientUtils.getAdministeredDoses(patient.hsId!).map((v) => v.administeredDose);

    const recentPatientPatches = patientAdministeredDoses
      .flatMap((d) => (d.administeredDrugs && d.administeredDrugs.length > 0 ? d.administeredDrugs : []))
      .filter((d) => d.patchLocationNumber && DateUtils.toDate(d.administeredAt!) >= subWeeks(new Date(), 4))
      .sort((a, b) => DateUtils.compareDateStringsDescending(a.administeredAt, b.administeredAt))
      .slice(0, 3);

    return recentPatientPatches.map((p) => PatchLocations.find((pl) => pl.LocationNumber === p.patchLocationNumber));
  },
  getLastPatchMedicationDose: (
    props: PatchAdministerProps,
    packedPatientDaysStore: ReadonlyMap<string, HSPackedPatientDay>,
    residentDetails: ResidentDetailsUtils,
  ) => {
    const { patient, drugList: drugs, packedMedication } = props;
    const removePatchInstruction = packedMedication;

    const packedPatientDays = itiriri(packedPatientDaysStore.values())
      .filter((p) => p.patientId === patient.hsId && !!p.packDate)
      .toArray();

    const patchDrug = drugs!.find((d) => d.patchRemovalDrugId === removePatchInstruction?.drugHsId);

    const patchPackedMedicationsLatestFirst = packedPatientDays
      .flatMap((p) => p.packedMedications!)
      .filter((pm) => pm.drugHsId === patchDrug?.hsId)
      .sort((a, b) => DateUtils.compareDateStringsDescending(a.doseTimestamp, b.doseTimestamp));

    const removePatchInstructionTimestamp =
      'doseTimestamp' in removePatchInstruction! ? removePatchInstruction.doseTimestamp! : undefined;

    const lastPatchMedication = patchPackedMedicationsLatestFirst.find(
      (pm) => removePatchInstructionTimestamp && pm.doseTimestamp! < removePatchInstructionTimestamp,
    );

    const previousDosedDrugs = residentDetails.getPreviousDosedDrugsForPackedPatientMedications(
      patchPackedMedicationsLatestFirst,
    );

    const { activeDose: activePatch } = DrugUtils.getActivePreviousDoses(
      undefined,
      previousDosedDrugs,
      lastPatchMedication?.hsId,
    );

    return { lastPatchMedication, activePatch };
  },
  getPatchOperationData: (patch: HSPatchObservation): PatchOperationData | undefined => {
    const splitOperation = patch.operation?.split(':');
    if (!splitOperation || splitOperation?.length < 2) {
      return undefined;
    }

    const packedMedicationId = parseInt(splitOperation[0]);

    const patchStatus = Object.values(PatchStatus).find((status) => status === splitOperation[1]);

    // ISO strings can have a colon, so need to join them
    let patchTime = splitOperation.slice(2).join(':');
    if (!patchTime) {
      patchTime = patch.createdAt!;
    }
    if (isAfter(DateUtils.toDate(patch.createdAt!), DateUtils.toDate(patchTime!))) {
      patchTime = patch.createdAt!;
    }

    if (!patchStatus) {
      return undefined;
    }

    return {
      packedMedicationId: packedMedicationId,
      patchStatus: patchStatus,
      patchTime: patchTime
    };
  },

  getPatchRemovalPackedMedicationForPackedMedication: (
    packedPatientDays: HSPackedPatientDay[],
    drug: HSDrug,
    med: HSPackedMedication,
    patientId: number,
  ) => {
    if (!med.doseTimestamp) {
      return undefined;
    }
    const medTime = DateUtils.toDate(med.doseTimestamp);

    const packedDays = packedPatientDays.filter((day) => day.patientId === patientId);

    const nextPatch = packedDays
      .flatMap((day) => day.packedMedications)
      .filter(
        (packedMed) =>
          med?.medicationId === packedMed?.medicationId &&
          packedMed?.doseTimestamp &&
          DateUtils.toDate(packedMed.doseTimestamp).valueOf() > medTime.valueOf(),
      )
      .sort((a, b) => DateUtils.compareDateStringsDescending(a?.doseTimestamp, b?.doseTimestamp, true))[0];

    return packedDays
      .flatMap((p) => p.packedMedications!)
      .filter((pm) => pm.drugHsId === drug!.patchRemovalDrugId)
      .sort((a, b) => DateUtils.compareDateStringsDescending(a.doseTimestamp, b.doseTimestamp, true))
      .find(
        (removal) =>
          removal.doseTimestamp &&
          DateUtils.toDate(removal.doseTimestamp).valueOf() > medTime.valueOf() &&
          (!nextPatch?.doseTimestamp ||
            DateUtils.toDate(removal.doseTimestamp).valueOf() <= DateUtils.toDate(nextPatch.doseTimestamp).valueOf()),
      );
  },
  getPatchObservationInfo: (
    patchObservationStore: ReadonlyMap<string, HSPatchObservation>,
    packedMedicationId: number,
  ): PatchObservationInfo[] => {
    return itiriri(patchObservationStore.values())
      .filter((observation) => PatchUtils.getPatchOperationData(observation)?.packedMedicationId === packedMedicationId)
      .map((observation): PatchObservationInfo | undefined => {
        const patchObservationData = PatchUtils.getPatchOperationData(observation);
        if (patchObservationData) {
          return {
            patchStatus: patchObservationData.patchStatus!,
            createdAt: observation.createdAt!,
            userSubjectId: observation.lastUpdatedBySubjectId!,
            //assuming that the comment only come from the notes text box
            comments:
              observation.comments?.map((comment) => comment.commentText).flatMap((ob) => (ob ? [ob] : [])) ?? [],
          };
        }
        return undefined;
      })
      .toArray()
      .flatMap((ob) => (ob ? [ob] : []))
      .sort((a, b) => DateUtils.compareDateStringsDescending(a.createdAt, b.createdAt));
  },
};
