import {HSFacility} from "server-openapi";
import Select, {components, MultiValue} from "react-select";
import React, {useState} from "react";
import CheckBox from "react-three-state-checkbox";

interface ISelectWards {
    wards: HSFacility[],
    wingLabel: string,
    selectedWardIds?: string[],
    selectionChanged: (wards: string[]) => void
}
interface IFacilityOption {
    label: string;
    value: number;
}

export function SelectWards(props: ISelectWards) {
    const { wards, wingLabel, selectedWardIds, selectionChanged } = props;
    const facilityOptions = wards.map((f) => ({ label: f.name!, value: f.hsId! }));
    const allOptions = [
        {
            label: "All " + wingLabel,
            options: facilityOptions,
            value: -1
        }
    ];
    const selectedFacilityOptions = facilityOptions.filter((x) => !selectedWardIds || selectedWardIds.includes(x.value.toString()));

    // We need explicit control of the ward menu being open or closed so that the "select all" button
    // can close the ward menu list.
    const checkboxState: "All" | "Some" | "None" = selectedFacilityOptions.length === 0 ? "None" : selectedFacilityOptions.length === facilityOptions.length ? "All" : "Some";
    function handleWardSelectionChange(newValue: MultiValue<IFacilityOption>) {
        const facilityIds = newValue.map((v) => v);
        // setSelectedFacilityOptions(facilityIds);
        selectionChanged(facilityIds.map(x => x.value.toString()));
    }

    function handleSelectAll() {
        // setSelectedFacilityOptions([...facilityOptions]);
        if (checkboxState === "All") {
            // Clear.
            selectionChanged([]);
        }
        else {
            // Select all.
            selectionChanged(facilityOptions.map(x => x.value.toString()));
        }
    }

    return <Select
        aria-label="Select Wing"
        isMulti
        placeholder={`Select ${wingLabel}`}
        options={allOptions}
        value={selectedFacilityOptions}
        closeMenuOnSelect={false}
        onChange={handleWardSelectionChange}
        styles={{
            control: (provided) => ({ ...provided, height: '100%', minWidth: '300px', zIndex: 9999}),
            menu: provided =>({...provided, zIndex: 9999})
        }}

        components={{
            MultiValue: ({ index, getValue }) => {
                const values = getValue();
                let valueLabel = '';

                if (values.length > 0) {
                    valueLabel += values[0].label;
                }
                if (values.length > 1) {
                    valueLabel += ` & ${values.length - 1} more`;
                }
                if (values.length === facilityOptions.length) {
                    valueLabel = `All ${wingLabel}s`;
                }

                return <>{!index && valueLabel}</>; // only show one entry
            },
            Option: (props) => {
                return (
                    <div>
                        <components.Option {...props}>
                            <input type="checkbox" checked={props.isSelected} onChange={() => null} />{' '}
                            <label>{props.label}</label>
                        </components.Option>
                    </div>
                );
            },
            GroupHeading: (props) => {
                return (
                    <div>
                        <CheckBox checked={checkboxState === "All"} indeterminate={checkboxState === "Some" } onChange={handleSelectAll} />{' '}
                        <label>Wards</label>
                    </div>
                );
            }
        }}
        hideSelectedOptions={false}
    />
}

