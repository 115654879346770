import * as React from 'react';
import { useParams } from 'react-router-dom';
import { HSFacility, HSPatient } from 'server-openapi';
import styled from 'styled-components';
import { RadioGroup } from '../../../kit/Forms/RadioGroup';
import { SelectOption } from '../../../kit/Forms/Select';
import { Grid } from '../../../kit/Grid';
import { Layout } from '../../../kit/Layout';
import { Text } from '../../../kit/Text';
import { DashboardResidentsGrid } from '../../Dashboard/DashboardPage/components/DashboardResidentsGrid';
import { RoundNavbar } from './components/RoundNavbar';
import { useSyncCenter } from '../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../core/storage/hooks/UseStore';
import itiriri from 'itiriri';
import { useApiUtils } from '../../../syncstream/utils/hooks/useApiUtils';
import { PatientUtils } from '../../../syncstream/utils/PatientUtils';
import { useRoundSchedule } from '../services/RoundScheduleProvider';
import {RoundScheduleItem} from "../../../syncstream/utils/RoundUtils";
import {
  ResidentSortType,
  ResidentSortOrderType,
  useRoundFilterStore
} from "../services/RoundFilterContext";

interface IParams {
  facilityGroupId: string;
  roundId: string;
}

export function RoundPage(): JSX.Element {
  const params = useParams<IParams>();
  const roundId = params.roundId;
  const services = useSyncCenter();
  const roundUtils = useApiUtils().rounds;
  const patientUtils = useApiUtils().patients;
  const facilitiesStore = useStore(services.facilities.store).store;
  const roundStore = useStore(services.rounds.store).store;
  const round = roundStore.get(roundId);
  const roundScheduleContext = useRoundSchedule();
  React.useEffect(() => {
    if (!roundScheduleContext.roundSchedule) {
      roundScheduleContext.set(round);
    }
  }, [roundId]);
  const roundSchedule = useRoundSchedule().roundSchedule ?? [];
  const facilitiesInGroup = itiriri(facilitiesStore.values())
    .filter((f) => f.facilityGroupId === parseInt(params.facilityGroupId))
    .toArray();

  if (!round) {
    throw new Error('Error: round not found!');
  }
  const [filterState, filterDispatch] = useRoundFilterStore();
  const residentsNotSeen = roundUtils.getPatientsToBeSeenWithinRound(round, roundSchedule, true);
  const residentsSeen = roundUtils.getPatientsSeenWithinRound(round, roundSchedule, true);
  const residentsInRound = [...residentsNotSeen, ...residentsSeen];
  if (residentsInRound.length === 0) {
    throw new Error('Unable to start round - no residents in round!');
  }
  // console.log("Filter state in view is " + JSON.stringify(filterState));
  return (
      <Grid cols={1} gap={1}>
        <RoundNavbar
          numberOfResidentsInRound={residentsInRound.length}
          numberOfResidentsSeen={residentsSeen.length}
          facilityGroupId={params.facilityGroupId}
          round={round}
        />
        <RadioGroupWrapper horizontal gap={1}>
          <Text>Sort By</Text>
          <RadioGroup
            name="residentSort"
            cols={3}
            options={residentSortOptions}
            value={filterState.sort}
            onChange={(_, value) => {
              if (value) {
                filterDispatch({
                  type: "SET_SORT_TYPE",
                  payload: value
                });
              }
            }}
          />
          <Text>Order By</Text>
          <RadioGroup
            name="sortOrder"
            cols={2}
            options={sortOrderOptions}
            value={filterState.order}
            onChange={(_, value) => {
              if (value) {
                filterDispatch({
                  type: "SET_SORT_ORDER",
                  payload: value
                });
              }
            }}
          />
        </RadioGroupWrapper>
        <DashboardResidentsGrid
          residentGridArray={getResidentGridArray(
            residentsNotSeen,
            filterState.sort,
            filterState.order,
            facilitiesInGroup,
            patientUtils,
            roundSchedule
          )}
          activeRound={round}
        />
        <SectionText size="large">Residents seen</SectionText>
        <DashboardResidentsGrid
          residentGridArray={getResidentGridArray(
            residentsSeen,
            filterState.sort,
            filterState.order,
            facilitiesInGroup,
            patientUtils,
            roundSchedule
          )}
          activeRound={round}
          overlay
        />
      </Grid>
  );
}

function sortResidents(residents: HSPatient[], sort: ResidentSortType) {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return residents.sort((a, b) => {
    switch (sort) {
      case 'Name':
        return a.familyName?.localeCompare(b.familyName ?? '') ?? 0;
      case 'Room':
        return a.roomNumber?.localeCompare(b.roomNumber ?? '', undefined, { numeric: true }) ?? 0;
      case 'Wing':
        const facilitySort = (a.facility ?? 0) - (b.facility ?? 0);
        if (facilitySort !== 0) {
          return facilitySort;
        }
        return a.familyName?.localeCompare(b.familyName ?? '') ?? 0;
    }
  });
}

function getResidentGridArray(
  patients: HSPatient[],
  residentSort: ResidentSortType,
  sortOrder: ResidentSortOrderType,
  facilities: HSFacility[],
  patientUtils: PatientUtils,
  scheduleItems: RoundScheduleItem[]
) {
  const sortedPatients =
    sortOrder === 'Ascending' ? sortResidents(patients, residentSort) : sortResidents(patients, residentSort).reverse();

  return sortedPatients.map((patient) => {
    return {
      patient,
      facilityWing: facilities.find((f) => f.hsId === patient.facility)?.name ?? 'unknown wing',
      hasMedChanges: patientUtils.fetchChangedMedicationDetailsFromPatient(patient).medicationHasChanged,
      scheduleItems
    };
  });
}

const RadioGroupWrapper = styled(Layout)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.backgrounds.default.fg};
`;

const SectionText = styled(Text)`
  color: white;
  margin: 1rem;
`;

const residentSortOptions: SelectOption<ResidentSortType>[] = [
  {
    label: 'Last Name',
    value: 'Name',
  },
  {
    label: 'Room number',
    value: 'Room',
  },
  {
    label: 'Wing',
    value: 'Wing',
  },
];

const sortOrderOptions: SelectOption<ResidentSortOrderType>[] = [
  {
    label: 'Ascending',
    value: 'Ascending',
  },
  {
    label: 'Descending',
    value: 'Descending',
  },
];
