import { addDays, startOfDay, subDays } from 'date-fns';
import itiriri from 'itiriri';
import {HSDrug, HSFacility, HSPackedPatientDay, HSPatient, UpdatePatchRequest} from 'server-openapi';
import { PersistentQueue } from '../core/queue/PersistentQueue';
import {Entry, IStorage} from '../core/storage/Contract';
import { SyncStreamAPI } from './api';
import {IFacilityGroupSyncService } from './SyncCenter';
import { SyncUtils } from './utils/SyncUtils';
import { DateUtils } from '../core/utils/dateUtils';
import { Logger } from '../core/logger/logger';
import { v4 as uuidv4 } from 'uuid';
import { PatchUtils } from '../pages/ResidentDetails/components/patches/PatchUtils';
import {MemoryCache} from "../core/storage/MemoryCache";

export type Operation = CreatePatchUpdateOp;

export interface CreatePatchUpdateOp {
  type: 'patch-update-create';
  idempotencyKey?: string;
  request: UpdatePatchRequest;
  drug: HSDrug;
  patchPackedMedicationId?: number;
}

const logger = new Logger('SyncPackedPatientDays');

export class SyncPackedPatientDays implements IFacilityGroupSyncService {
  get name(): string {
    return 'SyncPackedPatientDays';
  }
  private async isStale(p: HSPackedPatientDay): Promise<boolean> {
    return (!!(p.packDate && p.packDate < DateUtils.fromDate(subDays(startOfDay(new Date()), 14))));
  }
  private async isFacilityGroup(p: HSPackedPatientDay, facilityGroupId: string): Promise<boolean> {
    const facilityGroup = await this.facilitiesStore.get(p.facilityId!.toString());
    return facilityGroup?.facilityGroupId?.toString() == facilityGroupId;
  }

  constructor(
    private api: SyncStreamAPI,
    private storage: MemoryCache<HSPackedPatientDay>,
    private facilitiesStore: IStorage<HSFacility>,
    private latestChangeNumbers: IStorage<number | undefined>,
    private queue: PersistentQueue<Operation>,
  ) {}
  async load(facilityGroupId: string): Promise<void> {
    await this.storage.reset(async (p) => {
          return await this.isFacilityGroup(p, facilityGroupId);
        });
  }

  //eslint-disable-next-line sonarjs/cognitive-complexity
  async syncDown(facilityGroupId?: string) {
    // TODO: storage locking so that we can be sure the UI
    // didn't accidently change a resource in between the API
    // giving us fresh data and updating the storage backend.
    const facilitiesToSync = await SyncUtils.getFacilitiesForGroup(facilityGroupId, this.facilitiesStore);
    const changeNumber = await SyncUtils.getChangeNumberForFacilities(
      facilitiesToSync.map((x) => x.hsId!),
      this.latestChangeNumbers,
    );

    const packedPatientDayData = await this.syncFacilityDown(
      facilityGroupId!, //non null assertion on facility group id
      facilitiesToSync.map((facility) => facility.hsId!),
      changeNumber,
    );
    await this.storage.setMany(
      packedPatientDayData
        .map((packedPatientDay) => ({
          key: this.storage.get_key!(packedPatientDay),
          value: packedPatientDay,
        })),
    );

    await SyncUtils.setChangeNumberForFacilities(
        facilitiesToSync.map((x) => x.hsId!),
        this.latestChangeNumbers,
        packedPatientDayData,
    );
  }

  private async syncFacilityDown(
    facilityGroupId: string,
    facilityIds: number[],
    changeNumber: number,
  ): Promise<HSPackedPatientDay[]> {
    const pageSize = 300;
    const packedPatientDays = await this.api.packedPatientDays.packedPatientDayListPackedPatientDay(
      changeNumber,
      parseInt(facilityGroupId),
      pageSize,
    );

    if (packedPatientDays.data.length === pageSize) {
      return [
        ...packedPatientDays.data,
        ...(await this.syncFacilityDown(
          facilityGroupId,
          facilityIds,
          SyncUtils.getLatestChangeNumber(packedPatientDays.data)!,
        )),
      ];
    }
    return packedPatientDays.data;
  }

  enqueue = {
    createPatchUpdate: async (req: CreatePatchUpdateOp) => {
      const patchUpdateIdempotencyId = uuidv4();
      req.request.idempotency_key = patchUpdateIdempotencyId;

      const patchUpdate = {
        key: patchUpdateIdempotencyId,
        value: req.request,
      };

      await this.queue.unshift({ ...req, idempotencyKey: patchUpdateIdempotencyId });

      await this.simulateLocalPatchUpdate(req);

      return patchUpdate;
    },
  };

  //eslint-disable-next-line sonarjs/cognitive-complexity
  async simulateLocalPatchUpdate(req: CreatePatchUpdateOp) {
    const now = new Date();

    const subsequentPackedPatientDays = itiriri((await this.storage.all()).values())
      .filter(
        (d) => d.patientId === req.request.patient_id && DateUtils.toOffsetlessDate(d.packDate!) >= startOfDay(now),
      )
      .sort((a, b) => DateUtils.compareDateStringsDescending(a.packDate, b.packDate, true))
      .toArray();

    if (!PatchUtils.isPatchRemoval(req.drug)) {
      subsequentPackedPatientDays.forEach((d) => {
        // remove subsequent patch medidications
        d.packedMedications = d.packedMedications?.filter(
          (p) =>
            p.drugHsId !== req.drug.hsId || p.hsId === req.patchPackedMedicationId || new Date(p.doseTimestamp!) <= now,
        );
      });
    } else {
      let reappliedPatchRemovalDateUpdated = false;

      subsequentPackedPatientDays.forEach((d) => {
        if (!reappliedPatchRemovalDateUpdated) {
          const reappliedPatchRemovalPackedMedication = d.packedMedications?.find(
            (p) => p.drugHsId === req.drug.hsId && new Date(p.doseTimestamp!) > now,
          );

          if (reappliedPatchRemovalPackedMedication) {
            const currentDoseDateTime = new Date(reappliedPatchRemovalPackedMedication.doseTimestamp!);

            const newDoseTimestamp = addDays(startOfDay(now), 7); // keep the time portion but set the patch removal date to 7 days from today (weekly patch)
            newDoseTimestamp.setHours(currentDoseDateTime.getHours());
            newDoseTimestamp.setMinutes(currentDoseDateTime.getMinutes());
            newDoseTimestamp.setSeconds(currentDoseDateTime.getSeconds());

            reappliedPatchRemovalPackedMedication.doseTimestamp = DateUtils.fromDate(newDoseTimestamp);

            reappliedPatchRemovalDateUpdated = true;
          }
        } else {
          d.packedMedications = d.packedMedications?.filter(
            (p) => p.drugHsId !== req.drug.hsId || new Date(p.doseTimestamp!) <= now,
          ); // remove subsequent patch medidications
        }
      });
    }

    await this.storage.setMany(
      subsequentPackedPatientDays.map((packedPatientDay) => ({
        key: packedPatientDay.hsId!.toString(),
        value: packedPatientDay,
      })),
    );
  }

  async syncUp() {
    for await (const delivery of this.queue.iterate()) {
      try {
        // eslint-disable-next-line sonarjs/no-small-switch
        switch (delivery.value.type) {
          case 'patch-update-create':
            await this.api.patchManagement.patchManagementUpdatePatch(delivery.value.request);

            await delivery.complete();
        }
      } catch (error) {
        logger.error('Sync packed patient days failed', error);
        await delivery.failed();
      }
    }
  }

  async clear() {
    await this.storage.clear();
    await this.latestChangeNumbers.clear();
    await this.queue.clear();
  }

  async hasQueuedData() {
    return (await this.queue.length()) > 0;
  }
  isAllowed(canUserAccessMedication: boolean): boolean {
    // Only if you can view a round.
    return canUserAccessMedication;
  }
  async archive(deletedPatientIds: string[]): Promise<void> {
    const keysToDelete: string[] = [];
    for (let [k, v] of (await this.storage.all()).entries()) {
      if (await this.isStale(v) || deletedPatientIds.some(x => x === v.patientId?.toString() ?? "-1")) {
        keysToDelete.push(k)
      }
    }
    await this.storage.deleteMany(keysToDelete);
  }
  setEncryptionVersion(version: number): void {
    this.storage.compressOnSave = (version > 1);
  }
  async rewrite(): Promise<void> {
    const entries: Entry<HSPackedPatientDay>[] = [...(await this.storage.all())].map((keyValueArray) => {
      return {
        key: keyValueArray[0],
        value: keyValueArray[1]
      };
    });

    return await this.storage.setMany(entries);
  }}
