import { useMemo } from "react";
import { MemoryCache } from '../MemoryCache';
import {useMemoryCacheChangeCount} from "./MemoryCacheProvider";

interface StoreData<T> {
  store: ReadonlyMap<string, T>;
  changeCount: number;
}

export function useStore<T>(store: MemoryCache<T>): StoreData<T> {
  const changeCount = useMemoryCacheChangeCount(store);
  const map = store.map;
  return useMemo(() => ({
    store: map,
    changeCount: changeCount
  }), [map, changeCount]);
}

interface Service<T> {
  store: MemoryCache<T>
}

// This is much simpler than useStore
export function useMap<T>(service: Service<T>): ReadonlyMap<string, T> {
  const {store} = useStore(service.store);
  return store;
}
