import React from 'react';
import { Button, ButtonProps } from './Button';

interface Props extends ButtonProps {
  to: string;
}

export const ExternalLink = (props: Props) => {
  return (
    <a href={props.to} target="_blank" rel="noreferrer">
      <Button {...props} />
    </a>
  );
};
