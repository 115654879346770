import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import urljoin from 'url-join';
import { ResidentDetailsPage } from './ResidentDetailsPage';

export function ResidentDetails() {
  const { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>
        <Route path={urljoin(path, 'rounds/:roundId/:patientId')} component={ResidentDetailsPage} />
        <Route path={urljoin(path, ':patientId')} component={ResidentDetailsPage} />
      </Switch>
    </React.Fragment>
  );
}
