import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { Layout } from '../../../../kit/Layout';
import { Text } from '../../../../kit/Text';
import { colors } from '../../../../kit/Theme/Theme';

interface IProps {
  userName: string;
  date?: Date;
}

export function SecondCheckDetailsBar(props: IProps) {

  return (
    <SecondCheckDetailsContainer>
      <Text>
        Second check by {props.userName}{' '}
        {props.date && `at ${DateUtils.dateTo24HourTimeString(props.date!)} on ${format(props.date, 'dd MMM yyyy')}`}
      </Text>
    </SecondCheckDetailsContainer>
  );
}

const SecondCheckDetailsContainer = styled(Layout)`
  justify-items: center;
  background-color: ${colors.bg_green_200};
  color: ${(props) => props.theme.typography.defaults.color};
  width: 100%;
  padding: 10px;
`;
