import itiriri from 'itiriri';
import { startCase } from 'lodash-es';
import { FacilityGroupConfigurationDto } from 'server-openapi';

interface FacilityGroupUtilStore {
  facilityGroupConfigurationStore: ReadonlyMap<string, FacilityGroupConfigurationDto>;
}

export class FacilityGroupUtils {
  private stores: FacilityGroupUtilStore;

  constructor(apiStores: FacilityGroupUtilStore) {
    this.stores = apiStores;
  }

  public getFacilityUILabel = (facilityGroupId: number) =>
      startCase(
          itiriri(this.stores.facilityGroupConfigurationStore.values()).find(
              (config) => config.facilityGroupHsId === facilityGroupId,
          )?.facilityUILabel ?? 'wing',
      );

  public getFacilityGroupUILabel = (facilityGroupId: number) =>
      startCase(
          itiriri(this.stores.facilityGroupConfigurationStore.values()).find(
              (config) => config.facilityGroupHsId === facilityGroupId,
          )?.facilityGroupUILabel ?? 'facility',
      );

  public getResidentUILabel = (facilityGroupId: number) =>
      startCase(
          itiriri(this.stores.facilityGroupConfigurationStore.values()).find(
              (config) => config.facilityGroupHsId === facilityGroupId,
          )?.residentUILabel ?? 'resident',
      );

  public getRoundIntervalsInMinutes = (facilityGroupId: number) => {
    const intervalsSeconds =
        itiriri(this.stores.facilityGroupConfigurationStore.values()).find(
            (config) => config.facilityGroupHsId === facilityGroupId,
        )?.roundWindowInSeconds ?? 120 * 60;
    return intervalsSeconds / 60;
  };

  public getFacilityGroupEncryptionVersion = (facilityGroupId: string) => {
    const encryptionVersion =
        itiriri(this.stores.facilityGroupConfigurationStore.values()).find(
            (config) => config.facilityGroupHsId?.toString() === facilityGroupId,
        )?.encryptionVersion ?? 0;
    return encryptionVersion;
  };

}