import React from 'react';
import styled, { useTheme } from 'styled-components';
import itiriri from 'itiriri';
import { HSDrug } from 'server-openapi';
import { useForm } from 'react-hook-form';
import { Order, GetOrderComments, OrderComment } from '../../../../common/Order';
import { usermanager } from '../../../../core/authn/Client';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { Button } from '../../../../kit/Button';
import { Dialog } from '../../../../kit/Dialog';
import { Form } from '../../../../kit/Forms/Form';
import { FormGroup } from '../../../../kit/Forms/FormGroup';
import { TextArea } from '../../../../kit/Forms/TextArea';
import { Text } from '../../../../kit/Text';
import { Layout } from '../../../../kit/Layout';
import { useSyncCenter } from '../../../../syncstream/SyncCenterProvider';
import { AdministrationWarnings } from '../../../../syncstream/utils/DrugUtils';
import { MedicationInfoSection } from '../../../ResidentDetails/components/medicationInformation/MedicationInfoSection';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import { toasts } from '../../../../kit/Toasts/Toaster';
import { format } from 'date-fns';
import {useGroupPermissions} from "../../../../core/authz/PermissionsProvider";

export enum OrderResupplyAction {
  Create,
  Cancel,
}

export interface OrderNotesProps {
  order: Order;
  drug?: HSDrug;
  route?: string;
  directions?: string;
  administrationWarnings: AdministrationWarnings;
  facilityGroupId: number;
}

export interface OrderNotesDialogProps extends OrderNotesProps {
  isOpen: boolean;
  setClosed: () => void;
}

interface FormFields {
  notes: string;
}

export function OrderNotesDialog(props: OrderNotesDialogProps) {
  return (
    <OrderDialogLayout closeButtonColor={'#fff'} open={props.isOpen} onRequestClose={() => props.setClosed()} size="lg">
      <OrderNotesDialogDetail {...props} />
    </OrderDialogLayout>
  );
}

export function OrderNotesDialogDetail(props: OrderNotesProps) {
  const services = useSyncCenter();
  const form = useForm<FormFields>();

  const pendingComment = props.order.update.pendingComments;

  const groupPermissions = useGroupPermissions();
  const canRecordNote = groupPermissions.canRecordNote;

  async function onSubmit(data: FormFields) {
    if (data.notes && !canRecordNote) {
      toasts.error('You do not have permission to record notes');
      return;
    }
    if (data.notes) {
      const user = await usermanager.getUser();
      if (!user?.profile?.sub) {
        return;
      }
      await services.orders.service.createOrderComment(props.order.id, user.profile.sub, data.notes);
    }
    form.reset();
  }

  const allComments = GetOrderComments(props.order);
  const orderComments = itiriri(allComments).sort((lhs, rhs) =>
    DateUtils.compareDatesDescending(DateUtils.ensureDate(lhs.commentDate)!, DateUtils.ensureDate(rhs.commentDate)!),
  );

  return (
    <Layout gap={1}>
      <h2>Notes</h2>
      <InformationBox>
        <MedicationInfoSection {...props} />
      </InformationBox>
      <Form form={form} onSubmit={onSubmit}>
        <Layout gap={2}>
          <FormGroup error={form.errors.notes} fullWidth>
            <TextArea fullWidth name="notes" placeholder="Add note" rows={3} />
          </FormGroup>
          <AddNoteButtonsContainer>
            <Button type="submit" fullWidth>
              Add Note
            </Button>
          </AddNoteButtonsContainer>
        </Layout>
      </Form>
      {pendingComment && pendingComment.map((comment, index) => <OrderNote key={index} orderComment={comment} />)}
      {orderComments.map((oc, index) => (
        <OrderNote key={index} orderComment={oc} />
      ))}
    </Layout>
  );
}

export function OrderNote(props: { orderComment: OrderComment }) {
  const { orderComment } = props;
  const userUtils = useApiUtils().users;
  const theme = useTheme();
  return (
    <OrderNoteLayout key={orderComment.id} gap={0.3}>
      <Text style={{ color: theme.typography.h1.color }} weight={'bold'}>
        {userUtils.getUserFullNameAndRoleFromSubjectId(orderComment.commentBySubjectId)} at{' '}
        {format(DateUtils.ensureDate(orderComment.commentDate)!, 'dd MMM yyyy')} {DateUtils.dateTo24HourTimeString(DateUtils.ensureDate(orderComment.commentDate)!)}
      </Text>
      <Text>{orderComment.comment}</Text>
    </OrderNoteLayout>
  );
}

const InformationBox = styled(Layout)`
  background: white;
  color: ${(props) => props.theme.button.info.fg};
  display: flex;
  align-items: center;
  padding: 0.875rem;
  width: 100%;
  min-height: 112px;
`;

const OrderNoteLayout = styled(InformationBox)`
  flex-direction: column;
  align-items: flex-start;
`;

const AddNoteButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const OrderDialogLayout = styled(Dialog)`
  background: ${(props) => props.theme.backgrounds.light.bg};
`;
