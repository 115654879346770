import * as React from 'react';

export function IconBin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="17"
      viewBox="0 0 13 17"
      fill={props.fill ?? 'currentColor'}
      {...props}
    >
      <path d="M10 5.875V14.625H3V5.875H10ZM8.6875 0.625H4.3125L3.4375 1.5H0.375V3.25H12.625V1.5H9.5625L8.6875 0.625ZM11.75 4.125H1.25V14.625C1.25 15.5875 2.0375 16.375 3 16.375H10C10.9625 16.375 11.75 15.5875 11.75 14.625V4.125Z" />
    </svg>
  );
}
