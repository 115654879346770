import React from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useAsync } from '../../../kit/hooks/UseAsync';
import { logout } from '../../../core/authn/Client';
import { apis } from '../../../core/mrs/apis';

export function AcceptInvitationPage() {
  const { search } = useLocation();
  const values = queryString.parse(search);
  const code = values.code;

  if (!code || Array.isArray(code)) {
    return <div>Invalid invitation</div>;
  }

  useAsync(async () => {
    const res = (await apis.users.userOnboardUser(code)).status;
    if (res === 200) {
      window.alert('You have accepted the invitation. Please click "ok" to login again');
      logout();
    } else {
      window.alert(`Error code: ${res}`);
    }
  });
  return <div>Accepting invitation...</div>;
}
