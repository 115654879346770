import itiriri from 'itiriri';
import React, {useEffect} from 'react';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import {useAppState} from '../../context/AppStateProvider';
import {Button} from '../../kit/Button';
import {Grid} from '../../kit/Grid';
import {useAsync} from '../../kit/hooks/UseAsync';
import {Paper} from '../../kit/Paper';
import {Intent} from '../../kit/Theme/Theme';
import {SyncCenterProvider, useSyncCenter} from '../../syncstream/SyncCenterProvider';
import {useStore} from '../storage/hooks/UseStore';
import {getErrorMessage, usermanager} from "./Client";
import {useResetDashboardContext} from "../../pages/Dashboard/DashboardContext";
import {toasts} from "../../kit/Toasts/Toaster";
import queryString from 'query-string';
import { tr } from 'date-fns/locale';


const Title = styled.h1`
  color: ${(p) => p.theme.backgrounds.default.fg};
  font-size: 7em;
  line-height: 0.9;
  letter-spacing: -0.016em;
  margin-bottom: 0;
`;

const Tagline = styled.div`
  color: ${(p) => p.theme.backgrounds.default.fg};
  font-size: 1.35rem;
  font-weight: 200;
`;

const Splash = styled.div`
  background-image: url(${require('./splash.png')});
  background-size: cover;
  width: 100vw;
  height: 100vh;
  padding: 2em;
  p,
  h3,
  a {
    color: ${(p) => p.theme.backgrounds.default.fg};
  }
  a {
    text-decoration: underline;
  }
  a span {
    font-size: 1.25rem;
    font-weight: bold;
  }
`;

const LoginContainer = styled(Grid)`
  text-align: center;
  width: fit-content;
`;

const LoginButton = styled(Button)`
  min-width: 310px;
`;

const WarningPaper = styled(Paper)`
  max-width: fit-content;
`;

export function LoginPage() {
  return (
    <SyncCenterProvider disableSyncing>
      <LoginPageContent />
    </SyncCenterProvider>
  );
}

function LoginPageContent() {
  //console.count('LoginPageContent');
  useResetDashboardContext();
  const appState = useAppState();
  const services = useSyncCenter();
  const usersStore = useStore(services.users.store).store;
  const [logoutMessageDisplayed, setLogoutMessageDisplayed] = React.useState(false);

  const hasQueuedData = useAsync(async () => await services.syncCenter.hasQueuedData());

  const queuedDataUser = itiriri(usersStore.values()).find(
    (user) => user.subjectId === appState.state.lastSyncedUserSub,
  );

  useEffect(() => {
    appState.set({selectedFacilityGroupId: ""});
  });

  const queuedDataUserName = queuedDataUser
    ? `${queuedDataUser.firstName} ${queuedDataUser.familyName}`
    : 'Unknown User';

  const onClick = async () => {
    appState.set({ isInitialSyncComplete: false, isLocked: false });
    try {
      await usermanager.signinPopup();
    }
    catch (err: any) {
      if (err.stack.includes('Popup window closed')) {
        toasts.warning('Login window closed without logging in.');
      } else {
        Sentry.captureException(err);
        window.alert(getErrorMessage(err));
      }
    }
  };

  const parsed = queryString.parse(location.search);

  useEffect(() => {
    if (parsed.msg === 'IndexedDBError' && !logoutMessageDisplayed) {
      toasts.toast(
          `Browser cache was cleared. Need to log back in.`,
          {
            timeoutSeconds: 10,
            intent: Intent.Danger
          }
      );
      setLogoutMessageDisplayed(true);
    }

    if (parsed.msg === 'UserInactive' && !logoutMessageDisplayed) {
      toasts.toast(
          'You have been inactive for 10 minutes and have no pin set, so you have been logged out.',
          {
            timeoutSeconds: 30,
            intent: Intent.Danger
          }
      );
      setLogoutMessageDisplayed(true);
    }
  });


  return (
    <Splash>
      <Grid colsMobile={1} cols={2}>
        <Grid cols={1} gap={2}>
          <img src={require('./mps-medica-logo.svg')} width="140" height="96" alt="MPS Medica Logo"/>
          <Title>MediSphere</Title>
          <Tagline>Making medication administration safe for everyone</Tagline>
          <LoginContainer cols={1} gap={2}>
            <LoginButton data-cy="login-page0.
            -login-button" large onClick={onClick} disabled={hasQueuedData.loading}>
              Log in
            </LoginButton>
          </LoginContainer>
        </Grid>
        <div />
      </Grid>
    </Splash>
  );
}
