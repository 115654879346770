/* eslint-disable max-lines-per-function */
import React from 'react';
import {
  HSFacilityGroup,
} from 'server-openapi';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { useCurrentUser } from '../../../../core/authn/UserProvider';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import {RestrictedNavigationBar} from "../../../../components/RestrictedNavigationBar/RestrictedNavigationBar";

interface IProps {
  facilityGroup?: HSFacilityGroup;
}

export function RestrictedDashboardNavbar(props: IProps) {
  const theme = useTheme();
  const { facilityGroup } = props;
  const user = useCurrentUser();
  const userUtils = useApiUtils().users;

  return (
    <RestrictedNavigationBar
      left={() => (
        <>
          <h1>
            {facilityGroup?.name} - {userUtils.getUserFullNameAndRoleFromSubjectId(user.profile.sub)}
          </h1>
        </>
      )}
      facilityGroup={facilityGroup}
    />
  );
}
