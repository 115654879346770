import React from 'react';
import { Grid } from '../../kit/Grid';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import urljoin from 'url-join';
import { CreateRoundPage } from './CreateRoundPage/CreateRoundPage';
import { RoundPage } from './RoundPage/RoundPage';
import { FinishRoundPage } from './FinishRoundPage/FinishRoundPage';
export function Rounds() {
  const { path } = useRouteMatch();

  return (
    <Grid cols={1} gap={0}>
      <Switch>
        <Route exact path={urljoin(path, 'create')} component={CreateRoundPage} />
        <Route exact path={urljoin(path, ':roundId/finish')} component={FinishRoundPage} />
        <Route path={urljoin(path, ':roundId')} component={RoundPage} />
      </Switch>
    </Grid>
  );
}
