import React, { useCallback } from 'react';
import { Text } from '../Text';

type Props = {
  title: string;
  index: number;
  isSelected: boolean;
  setSelectedTab: (index: number) => void;
};

const TabTitle: React.FC<Props> = ({ title, setSelectedTab, index, isSelected }) => {
  const onClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  return (
    <Text style={{ cursor: 'pointer' }} className={ `${isSelected ? "selected" : "unselected"}` } weight={isSelected ? 'bold' : '200'} onClick={onClick}>
      {title}
    </Text>
  );
};

export default TabTitle;
