import styled, { css } from 'styled-components';
import { breakpoints } from './Theme/Theme';

interface Props {
  cols?: number;
  colsMobile?: number;
  colsTemplate?: string;
  colsTemplateMobile?: string;
  gap?: number;
  alignItemsCenter?: boolean;
}

// TODO: prop for no of columns on mobile for responsive display

export const Grid = styled.div<Props>`
  display: grid;
  grid-gap: ${(props) => props.gap ?? 1}rem;
  ${(props) => {
    if (props.colsTemplate) {
      return css`
        grid-template-columns: ${props.colsTemplate};
      `;
    } else {
      return css`
        grid-template-columns: repeat(${props.cols ?? 1}, minmax(0, 1fr));
      `;
    }
  }}

  ${breakpoints.mobileDown} {
    ${(props) => {
      if (props.colsTemplateMobile) {
        return css`
          grid-template-columns: ${props.colsTemplate};
        `;
      } else if (props.colsMobile) {
        return css`
          grid-template-columns: repeat(${props.colsMobile}, minmax(0, 1fr));
        `;
      }
    }}
  }

  ${(props) => {
    if (props.alignItemsCenter) {
      return css`
        align-items: center;
      `;
    }
  }}

  grid-template-rows: 1fr;
  grid-auto-flow: row;
`;
