import * as React from 'react';

export function IconDoNotCrush(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style, backgroundColor: 'white', borderRadius: '999999px' }}
      {...props}
    >
      <path
        d="M13 5.806L12.194 5 9 8.194 5.806 5 5 5.806 8.194 9 5 12.194l.806.806L9 9.806 12.194 13l.806-.806L9.806 9 13 5.806z"
        fill="#EA2A2A"
        stroke="#EA2A2A"
      />
    </svg>
  );
}
