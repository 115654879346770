import * as React from 'react';
import { SVGProps } from 'react';

const IconRightArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="m.559.441 7.52 7.596L.56 15.56V.44Z" fill="#fff" />
  </svg>
);

export default IconRightArrow;
