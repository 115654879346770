import React, {useEffect} from 'react';
import { logout } from './core/authn/Client';
import { Button } from './kit/Button';
import { Dialog } from './kit/Dialog';
import { Layout } from './kit/Layout';
import { Text } from './kit/Text';
import {useGlobalPermissions} from "./core/authz/PermissionsProvider";
import Loader from "react-loader-spinner";

export function UserAccessCheck() {
  const [showDialog, setShowDialog] = React.useState<boolean | undefined>(undefined);
  const globalPermissions = useGlobalPermissions();
  useEffect(() => {
    setShowDialog(globalPermissions.canRead);
  }, [globalPermissions]);

  function onDialogClose() {
    setShowDialog(false);
    logout();
  }

  //console.log(`showDialog:` + showDialog);

  if (showDialog === undefined) {
    return (<Loader type="Oval" color="#005555" height={80} width={80} />);
  }
  if (!showDialog) {
    return (
      <Dialog open={showDialog} secondary size={'sm'} hideCloseButton={true} label={`User Access Check`}>
        <Layout padding={0.5} gap={1}>
          <Text>Your account is not permitted to access MediSphere. Please contact your IT administrator.</Text>
          <Layout horizontal justify={'right'} gap={0.5} style={{ justifySelf: 'flex-end', width: '5em' }}>
            <Button fullWidth onClick={onDialogClose}>
              OK
            </Button>
          </Layout>
        </Layout>
      </Dialog>
    );
  }

  return null;
}
