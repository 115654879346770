import styled from "styled-components";
import {Grid} from "../../../kit/Grid";
import React from "react";

interface Props {
    data: {
        dataType: string;
        numberOfRecords: number;
        queueSize: number|string; }[];
}

const DiagnosisTable = (props: Props) => {
    const titles = ['Data Type', 'Number of Records', 'Queue Size'];

    return(
        <DiagnosisTableContainer>
            <TableWrapper>
                <table>
                    <thead>
                        <tr>
                            {titles.map((title, index) => (
                                <th key={index}>{title}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.dataType}</td>
                                <td>{item.numberOfRecords}</td>
                                <td>{item.queueSize}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </TableWrapper>
        </DiagnosisTableContainer>
    )
};

const DiagnosisTableContainer = styled(Grid)`
    width: 100%;
    height: 100%;
    grid-template-rows: auto 1fr;
    padding: 2em 0;
`;

const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(251, 244, 255);
    padding: 1em .5em;
    border: 1px solid #f8f9fa;
    border-radius: .25rem;
    tr > th {
        text-align: left;
        padding: 1em .5em;
    };
    td {
        padding: .5em;
    };
    tbody > tr:hover {
        background-color: #eeeeee;
    }
`

export default DiagnosisTable;