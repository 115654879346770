import * as React from 'react';
import { SVGProps } from 'react';

const SvgScrollArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1.5em" height="1.5em" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={14} cy={14} transform="rotate(90 14 14)" fill="#EBEBEB" fillOpacity={0.5} r={14} />
    <path
      d="M13.293 21.374a1 1 0 0 0 1.414 0l6.364-6.364a1 1 0 0 0-1.414-1.415L14 19.252l-5.657-5.657A1 1 0 0 0 6.93 15.01l6.364 6.364ZM13 6.667v14h2v-14h-2Z"
      fill="#4E2974"
    />
  </svg>
);

export default SvgScrollArrow;
