import React from 'react';
import { getUser } from '../../../../core/authn/Client';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { Button } from '../../../../kit/Button';
import { Layout } from '../../../../kit/Layout';
import { Intent } from '../../../../kit/Theme/Theme';
import { MedisphereTestResult } from '../../../../syncstream/SyncTestResults';
import { IAdministeredDrugTestFormItem, AdministeredDrugTestForm } from './AdministeredDrugTestForm';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
  onUpdate: (answers: Array<Partial<MedisphereTestResult>>) => void;
  value: Array<Partial<MedisphereTestResult>>;
  administeredDrugClinicalSystemId?: string;
  isAdHoc?: boolean;
  disabled?: boolean;
}

export function AdministeredDrugTests(props: IProps) {
  const user = getUser();
  const formToTestResult = (
    formItem: Partial<IAdministeredDrugTestFormItem>,
    clinicalSystemId: string | null | undefined,
  ): Partial<MedisphereTestResult> => {
    return {
      clinicalSystemId: clinicalSystemId,
      hsTestResultTypeId: formItem.typeId ? parseInt(formItem.typeId) : undefined,
      testResultEntries: formItem.fields || [],
      timeStamp: formItem.timestamp ? DateUtils.fromDate(formItem.timestamp) : '',
      createdBySubjectId: user?.profile.sub,
      administeredDrugClinicalSystemId: props.isAdHoc ? undefined : props.administeredDrugClinicalSystemId,
      administeredAdHocDrugClinicalSystemId: props.isAdHoc ? props.administeredDrugClinicalSystemId : undefined,
    };
  };

  const testResultToForm = (result: Partial<MedisphereTestResult>): Partial<IAdministeredDrugTestFormItem> => {
    return {
      typeId: result.hsTestResultTypeId?.toString(),
      fields: result.testResultEntries || [],
      timestamp: result.timeStamp ? DateUtils.toDate(result.timeStamp) : new Date(),
    };
  };

  return (
    <Layout gap={0.5}>
      {props.value.map((a, index) => (
        <AdministeredDrugTestForm
          key={a.clinicalSystemId ?? ''}
          index={index}
          clinicalSystemIdentifier={a.clinicalSystemId ?? ''}
          answers={testResultToForm(a)}
          onUpdate={(r) =>
            props.onUpdate(
              props.value?.map((f, i) => {
                if (i === index) {
                  return formToTestResult(r, a.clinicalSystemId);
                }
                return f;
              }),
            )
          }
          onDelete={(clinicalSystemId) => {
            const newList = props.value.filter((tr) => {
              return (tr.clinicalSystemId ?? '') !== clinicalSystemId;
            });
            props.onUpdate(newList);
          }}
        />
      ))}
      <Button
        intent={Intent.Secondary}
        onClick={() => {
          props.onUpdate([
            ...props.value,
            {
              clinicalSystemId: uuidv4(),
            },
          ]);
        }}
        disabled={props.disabled}
      >
        Add Test
      </Button>
    </Layout>
  );
}
