/* eslint-disable max-lines */
import * as datefns from 'date-fns';
import itiriri from 'itiriri';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Image } from '../../kit/Image';
import { useSyncCenter } from '../../syncstream/SyncCenterProvider';
import { useStore } from '../../core/storage/hooks/UseStore';
import { BaseClock, DoseIndicator, DoseStatus, PrnDoseIndicator, PrnStatus } from '../BaseClock/BaseClock';
import { isSameDay } from 'date-fns/esm';
import { DateUtils } from '../../core/utils/dateUtils';
import {
  HSAdministeredDose,
  HSDoseRound,
  HSDrug,
  HSPackedMedication,
  HSPackedPatientDay,
  HSPackedPatientPrnMedication,
  HSPatient,
  ReasonCode,
  SyringeDriverActivityKind,
} from 'server-openapi';
import { useApiUtils } from '../../syncstream/utils/hooks/useApiUtils';
import { ClockOutlineStyle } from '../BaseClock/ClockOutlineStyle';
import { PatientUtils } from '../../syncstream/utils/PatientUtils';
import { RoundScheduleItem, RoundUtils, ScheduledActivity } from '../../syncstream/utils/RoundUtils';
import { isMedicationActive } from '../../syncstream/utils/PackedPatientDayUtils';
import { useRoundSchedule } from '../../pages/Rounds/services/RoundScheduleProvider';
import { isEqual } from 'lodash-es';
import { PatchUtils } from '../../pages/ResidentDetails/components/patches/PatchUtils';
import { PatchStatus } from '../../pages/ResidentDetails/components/MedicationListsTabbedRouter/TabLists/PatchesMedicationList';
import { ResidentDetailsUtils } from '../../syncstream/utils/ResidentDetailsUtils';
import { useCached } from '../../kit/hooks/UseCached';
import { DrugUtils } from '../../syncstream/utils/DrugUtils';

interface IProps {
  patientId: number;
  scheduleItems: RoundScheduleItem[];
  activeRound?: HSDoseRound;
  disableLink?: boolean;
  showTimes?: boolean;
  selectedDate?: Date;
  size?: string;
}

const ProfileLink = styled(Link)`
  display: block;
  align-content: center;
  justify-content: center;
  overflow: hidden;
`;

const ProfileImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

function PatientClock(props: IProps) {
  const services = useSyncCenter();
  const apiUtils = useApiUtils();
  const patientsStore = useStore(services.patients.store).store;
  const drugStore = useStore(services.drugs.store).store;
  const patchObservationChangeCount = useStore(services.patchObservations.store).changeCount;
  const patient = useCached(
    props.patientId.toString(),
    () => patientsStore.get(props.patientId.toString())!,
  );
  const roundSchedule = useRoundSchedule().roundSchedule;
  const medicationDay = props.selectedDate ?? new Date();
  const patientBigDay = apiUtils.patients.getAllPatientPackedMedications(patient, medicationDay);
  const packedPatientDay = apiUtils.patients.findPackedPatientDay(props.patientId, medicationDay, patient.facility);
  const previousPackedPatientDay = apiUtils.patients.findPackedPatientDay(
    props.patientId,
    datefns.subDays(medicationDay, 1),
    patient.facility,
  );

  const packedPatientPrnMedications = apiUtils.patients.findPackedPatientPrnMedications(props.patientId);
  const facilityGroupId = apiUtils.patients.findFacilityGroup(patient);

  const patientAdministeredDoses = apiUtils.patients.getAdministeredDoses(patient.hsId!).map((v) => v.administeredDose);

  const interval = apiUtils.rounds.getRoundWindow(
    props.activeRound?.createdAt ? DateUtils.toDate(props.activeRound.createdAt) : new Date(),
    facilityGroupId,
  );

  const dateInterval = DateUtils.intervalToDateInterval(interval);

  const doses = getDoseIndicatorsForSchedule(
    interval,
    medicationDay,
    patientAdministeredDoses,
    props.scheduleItems,
    apiUtils.residentDetails,
    drugStore,
    patientBigDay,
    patchObservationChangeCount,
    props.patientId,
    packedPatientDay,
    previousPackedPatientDay,
  );

  const prnDoses = getPrnDoseIndicators(patientAdministeredDoses, medicationDay, packedPatientPrnMedications);

  const profileLink = props.activeRound
    ? `/resident-details/rounds/${props.activeRound.clinicalSystemId}/${patient?.hsId}`
    : `/resident-details/${patient?.hsId}`;

  const clockOutlineStyle = getClockOutlineStyle(
    apiUtils.patients,
    patient,
    medicationDay,
    props.activeRound ?? apiUtils.rounds.getRoundsFromPatient(patient).pop(),
    !!props.activeRound,
    roundSchedule,
  );
  return (
    <BaseClock
      clockOutlineStyle={clockOutlineStyle}
      showTimes={props.showTimes}
      interval={dateInterval}
      doses={doses}
      prns={prnDoses}
      isPast={!datefns.isToday(medicationDay)}
      size={props.size}
    >
      {props.disableLink ? (
        patient?.imageUrl && <ProfileImage src={patient?.imageUrl} facilityGroupId={facilityGroupId} />
      ) : (
        <ProfileLink to={profileLink}>
          {patient?.imageUrl && <ProfileImage src={patient?.imageUrl} facilityGroupId={facilityGroupId} />}
        </ProfileLink>
      )}
    </BaseClock>
  );
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function getClockOutlineStyle(
  patientUtils: PatientUtils,
  patient: HSPatient,
  medicationDay: Date,
  selectedRound: HSDoseRound | undefined,
  isActiveRound: boolean,
  roundSchedule: RoundScheduleItem[] | undefined,
) {
  const packedMedicationInActiveRound = roundSchedule
    ? roundSchedule.filter((s) => s.patient.hsId === patient.hsId).map((s) => s.packedMedication)
    : undefined;
  const scheduledActivities = roundSchedule
    ? roundSchedule
        .filter((s) => s.patient.hsId === patient.hsId)
        .flatMap((s) => (s.scheduledActivity ? s.scheduledActivity : []))
    : [];
  const reasonCodesFromAdministeredDoses = patientUtils.getReasonCodesFromAdministeredDoses(
    patient,
    medicationDay,
    patientUtils.getAdministeredDosesByRound(medicationDay, patient.hsId!, selectedRound),
    packedMedicationInActiveRound,
  );
  const reasonCodeFromScheduledActivity = patientUtils.getReasonCodesFromScheduledActivity(scheduledActivities);

  return (
    [...reasonCodesFromAdministeredDoses, ...reasonCodeFromScheduledActivity]
      .map((reasonCode) => {
        if (
          !isActiveRound &&
          (reasonCode === ReasonCode.SelfAdministered ||
            reasonCode === ReasonCode.Dosed ||
            reasonCode === ReasonCode.DoseSupplied)
        ) {
          return getPriorityIndex(undefined);
        }
        return getPriorityIndex(reasonCode);
      })
      .sort((a, b) => a.priority - b.priority)[0]?.clockOutlineStyle ??
    (isActiveRound ? ClockOutlineStyle.ADMINISTERED.value : ClockOutlineStyle.DEFAULT.value)
  );
}

function getPriorityIndex(reasonCode: ReasonCode | undefined) {
  switch (reasonCode) {
    case ReasonCode.DosedLate:
      return {
        priority: 0,
        clockOutlineStyle: ClockOutlineStyle.ADMINISTERED_LATE.value,
      };
    case ReasonCode.Withheld:
    case ReasonCode.Omitted:
      return {
        priority: 1,
        clockOutlineStyle: ClockOutlineStyle.WITHHELD.value,
      };
    case ReasonCode.Refused:
      return {
        priority: 2,
        clockOutlineStyle: ClockOutlineStyle.REFUSED.value,
      };
    case ReasonCode.Dosed:
    case ReasonCode.SelfAdministered:
    case ReasonCode.DoseSupplied:
      return {
        priority: 4,
        clockOutlineStyle: ClockOutlineStyle.ADMINISTERED.value,
      };
    default:
      return {
        priority: 3,
        clockOutlineStyle: ClockOutlineStyle.DEFAULT.value,
      };
  }
}

//eslint-disable-next-line sonarjs/cognitive-complexity
function getDoseIndicators(
  interval: { start: string; end?: string },
  administeredAtDate: Date,
  patientAdministeredDoses: HSAdministeredDose[],
  roundUtils: RoundUtils,
  residentDetailsUtils: ResidentDetailsUtils,
  drugStore: ReadonlyMap<string, HSDrug>,
  patientBigDay: HSPackedPatientDay[],
  patchObservationChangeCount: number,
  patientId: number,
  packedPatientDay?: HSPackedPatientDay,
  previousPackedPatientDay?: HSPackedPatientDay,
) {
  const patientAdministeredDrugs = itiriri(patientAdministeredDoses)
    .flat((aDose) =>
      aDose.administeredDrugs
        ? aDose.administeredDrugs.map((aDrug) => {
            return { doseTimestamp: aDose.doseTimestamp, ...aDrug };
          })
        : [],
    )
    .toArray();

  const patientDayAdministeredDrugs = patientAdministeredDrugs.filter(
    (ad) => !!ad.doseTimestamp && isSameDay(DateUtils.toDate(ad.doseTimestamp), administeredAtDate),
  );

  function isPatchRemovalPackedMed(med: HSPackedMedication) {
    if (!med.drugHsId) {
      return false;
    }
    const drug = drugStore.get(med.drugHsId.toString());
    return drug ? PatchUtils.isPatchRemoval(drug) : false;
  }
  const doseTimes: DoseIndicator[] = packedPatientDay?.packedMedications
    ? packedPatientDay.packedMedications
        .filter((med) => isMedicationActive(med) && !isPatchRemovalPackedMed(med))
        .map((packedMed) => {
          const scheduledDoseDateTime = new Date(packedMed.doseTimestamp!);
          let isInsulin = false;
          if (packedMed.drugHsId) {
            const drug = drugStore.get(packedMed.drugHsId.toString());
            isInsulin = drug?.isInsulin ?? false;
          }
          const matchedAdministeredDrug = patientDayAdministeredDrugs.find(
            (pad) =>
              pad.medicationId === packedMed.medicationId &&
              pad.doseTimestamp &&
              datefns.isEqual(new Date(pad.doseTimestamp), scheduledDoseDateTime),
          );
          const doseStatus = mapReasonCodeToDoseStatus(
            matchedAdministeredDrug?.reasonCode,
            scheduledDoseDateTime,
            DateUtils.toDate(interval.start),
          );
          return { time: scheduledDoseDateTime, status: doseStatus, isTimeCritical: packedMed.timeCritical, isInsulin: isInsulin, drugHsId: packedMed.drugHsId!};
        })
    : [];

  // Search for patches
  const activePatches = useCached(
    `${patientId.toString()}-${datefns.startOfDay(administeredAtDate).valueOf().toString()}`,
    () => residentDetailsUtils.getActivePatches(patientId, administeredAtDate, patientBigDay),
    patchObservationChangeCount.toString(),
  );

  // Syringe Drivers are expected to only last for 24 hours at most, meaning we only need to check today and yesterday
  const administeredSyringeDrivers = patientAdministeredDrugs.filter(
    (ad) =>
      [...(packedPatientDay?.packedMedications ?? []), ...(previousPackedPatientDay?.packedMedications ?? [])]
        .filter(isMedicationActive)
        .find((med) => med.medicationId === ad.medicationId)?.route?.code === 'SID',
  );

  const patchActivity = activePatches.flatMap((patch) => {
    const activity = roundUtils.generateScheduledActivityForPatch(patch);

    return activity.filter(
      (act) =>
        datefns.isSameDay(act.time, administeredAtDate) &&
        (!act.patchActivity ||
          !activity.some((otherAct) => otherAct.patchActivity && otherAct.kind === PatchStatus.Removed)),
    );
  });

  const syringeDriverActivity = administeredSyringeDrivers.flatMap((driver) => {
    const activity = roundUtils.generateScheduledActivityForSyringeDriver(driver);
    return activity.filter(
      (act) =>
        datefns.isSameDay(act.time, administeredAtDate) &&
        (!act.syringeActivity ||
          !activity.some(
            (otherAct) =>
              otherAct.syringeActivity &&
              (otherAct.kind === SyringeDriverActivityKind.Cease || otherAct.kind === SyringeDriverActivityKind.Stop),
          )),
    );
  });

  // Only observations and the initial administration (covered by the administered drug) are displayed in the clock
  const syringeDriverDoseTimes = getSyringeDriverActivityDoseIndicators(syringeDriverActivity);

  const patchActivityDoseTimes = getPatchActivityDoseIndicators(patchActivity);
  return mergeDoseTimeIndicators([...doseTimes, ...syringeDriverDoseTimes, ...patchActivityDoseTimes]);
}
function getDoseIndicatorsForSchedule(
    interval: { start: string; end?: string },
    administeredAtDate: Date,
    patientAdministeredDoses: HSAdministeredDose[],
    roundSchedule: RoundScheduleItem[],
    residentDetailsUtils: ResidentDetailsUtils,
    drugStore: ReadonlyMap<string, HSDrug>,
    patientBigDay: HSPackedPatientDay[],
    patchObservationChangeCount: number,
    patientId: number,
    packedPatientDay?: HSPackedPatientDay,
    previousPackedPatientDay?: HSPackedPatientDay,
) {
  const patientAdministeredDrugs = itiriri(patientAdministeredDoses)
      .flat((aDose) =>
          aDose.administeredDrugs
              ? aDose.administeredDrugs.map((aDrug) => {
                return { doseTimestamp: aDose.doseTimestamp, ...aDrug };
              })
              : [],
      )
      .toArray();

  const patientDayAdministeredDrugs = patientAdministeredDrugs.filter(
      (ad) => !!ad.doseTimestamp && isSameDay(DateUtils.toDate(ad.doseTimestamp), administeredAtDate),
  );

  function isPatchRemovalPackedMed(med: HSPackedMedication) {
    if (!med.drugHsId) {
      return false;
    }
    const drug = drugStore.get(med.drugHsId.toString());
    return drug ? PatchUtils.isPatchRemoval(drug) : false;
  }
  const doseTimes: DoseIndicator[] = packedPatientDay?.packedMedications
      ? packedPatientDay.packedMedications
          .filter((med) => isMedicationActive(med) && !isPatchRemovalPackedMed(med))
          .map((packedMed) => {
            const scheduledDoseDateTime = new Date(packedMed.doseTimestamp!);
            let isInsulin = false;
            let isControlled = false;
            if (packedMed.drugHsId) {
              const drug = drugStore.get(packedMed.drugHsId.toString());
              isControlled = DrugUtils.getDrugWarnings(drug!).controlledDrug;
              isInsulin = drug?.isInsulin ?? false;
            }



            // Can be multiple administered drugs for the same med - always use the latest.
            const matchedAdministeredDrugs = patientDayAdministeredDrugs.filter(
                (pad) =>
                    pad.medicationId === packedMed.medicationId &&
                    pad.doseTimestamp &&
                    datefns.isEqual(new Date(pad.doseTimestamp), scheduledDoseDateTime),
            ).sort((a, b) => DateUtils.compareDateStringsDescending(a?.administeredAt, b?.administeredAt) );
            let reasonCode;
            if (matchedAdministeredDrugs && matchedAdministeredDrugs.length > 0) {
              reasonCode = matchedAdministeredDrugs[0].reasonCode;
            }
            const doseStatus = mapReasonCodeToDoseStatus(
                reasonCode,
                scheduledDoseDateTime,
                DateUtils.toDate(interval.start),
            );
            return { time: scheduledDoseDateTime, status: doseStatus, isTimeCritical: packedMed.timeCritical, isInsulin: isInsulin, isControlled: isControlled, drugHsId: packedMed.drugHsId!};
          })
      : [];

  // Syringe Drivers are expected to only last for 24 hours at most, meaning we only need to check today and yesterday
  const administeredSyringeDrivers = patientAdministeredDrugs.filter(
      (ad) =>
          [...(packedPatientDay?.packedMedications ?? []), ...(previousPackedPatientDay?.packedMedications ?? [])]
              .filter(isMedicationActive)
              .find((med) => med.medicationId === ad.medicationId)?.route?.code === 'SID',
  );

  // Search for patches
    const allPatchActivity: ScheduledActivity[] = roundSchedule?.filter(x => !!x.scheduledActivity?.patchActivity).map(x => x.scheduledActivity!) ?? [];
    const patchActivity = allPatchActivity.filter(
        (act) =>
            datefns.isSameDay(act.time, administeredAtDate) &&
            (!act.patchActivity ||
                !allPatchActivity.some((otherAct) => otherAct.patchActivity && otherAct.kind === PatchStatus.Removed)),
    );

    const allSyringeDriverActivity: ScheduledActivity[] = roundSchedule?.filter(x => !!x.scheduledActivity?.syringeActivity).map(x => x.scheduledActivity!) ?? [];
    const syringeDriverActivity = administeredSyringeDrivers.flatMap((driver) => {
      return allSyringeDriverActivity.filter(
          (act) =>
              datefns.isSameDay(act.time, administeredAtDate) &&
              (!act.syringeActivity ||
                  !allSyringeDriverActivity.some(
                      (otherAct) =>
                          otherAct.syringeActivity &&
                          (otherAct.kind === SyringeDriverActivityKind.Cease || otherAct.kind === SyringeDriverActivityKind.Stop),
                  )),
      );
    });

  // Only observations and the initial administration (covered by the administered drug) are displayed in the clock
  const syringeDriverDoseTimes = getSyringeDriverActivityDoseIndicators(syringeDriverActivity);

  const patchActivityDoseTimes = getPatchActivityDoseIndicators(patchActivity);

  const mergedDoseTimeIndicators = mergeDoseTimeIndicators([...doseTimes, ...syringeDriverDoseTimes, ...patchActivityDoseTimes]);
  const DoseIndicatorsForSchedule = mergedDoseTimeIndicators.flatMap( d => {
          return { time: d.time, status: d.status, isTimeCritical: d.isTimeCritical, isInsulin: d.isInsulin, isControlled: d.isControlled, drugHsId: d.drugHsId!};
      }
  );
  return DoseIndicatorsForSchedule;
}

function mapReasonCodeToDoseStatus(
  reasonCode?: ReasonCode,
  scheduledDoseDateTime?: Date,
  intervalStart?: Date,
): DoseStatus {
  switch (reasonCode) {
    case ReasonCode.Dosed:
    case ReasonCode.SelfAdministered:
      return DoseStatus.COMPLETE;
    case ReasonCode.DosedLate:
      return DoseStatus.COMPLETE_LATE;
    case ReasonCode.DoseSupplied:
      return DoseStatus.COMPLETE_WITH_DOSE_SUPPLIED;
    case ReasonCode.Refused:
      return DoseStatus.REFUSED;
    case ReasonCode.NoStock:
      return DoseStatus.NO_STOCK;
    case ReasonCode.Hospital:
    case ReasonCode.Absent:
      return DoseStatus.HOSPITAL_ABSENT;
    case ReasonCode.Other:
      return DoseStatus.OTHER;
    case undefined:
      return scheduledDoseDateTime! < intervalStart! ? DoseStatus.MISSED : DoseStatus.NONE;
    default:
      return DoseStatus.WITHHELD;
  }
}

function getPrnDoseIndicators(
  patientAdministeredDoses: HSAdministeredDose[],
  administeredAtDate: Date,
  packedPatientPrnMedications?: HSPackedPatientPrnMedication,
) {
  const patientDayPrnAdministeredDrugs = itiriri(patientAdministeredDoses)
    .flat((aDose) =>
      aDose.administeredDrugs
        ? aDose.administeredDrugs.filter(
            (aDrug) => aDrug.administeredAt && isSameDay(DateUtils.toDate(aDrug.administeredAt), administeredAtDate),
          )
        : [],
    )
    .toArray();

  const doseTimes: PrnDoseIndicator[] = patientDayPrnAdministeredDrugs
    .filter(
      (pdpad) =>
        packedPatientPrnMedications?.packedPrnMedications?.some((pppm) => pppm.medicationId === pdpad.medicationId) ??
        false,
    )
    .map((pdpad) => {
      const hasOutcome = pdpad.administeredDrugOutcomes ? pdpad.administeredDrugOutcomes.length > 0 : false;

      return {
        time: DateUtils.toDate(pdpad.administeredAt!),
        status: hasOutcome ? PrnStatus.ADMINISTERED_WITH_OUTCOME : PrnStatus.ADMINISTERED,
      };
    });

  return mergeDoseTimeIndicators(doseTimes);
}

function getSyringeDriverActivityDoseIndicators(syringeDriverActivity: ScheduledActivity[]): DoseIndicator[] {
  return syringeDriverActivity
    .filter((activity) => activity.kind === SyringeDriverActivityKind.Observation)
    .map((activity) => ({
      time: activity.time,
      status: activity.syringeActivity ? DoseStatus.COMPLETE : DoseStatus.NONE,
      drugHsId: activity.packedMedicationId!
    }));
}

function getPatchActivityDoseIndicators(patchActivity: ScheduledActivity[]): DoseIndicator[] {
  return patchActivity
    .filter((activity) => activity.kind === PatchStatus.Sighted)
    .map((activity) => ({
      time: activity.time,
      status: activity.patchActivity ? DoseStatus.COMPLETE : DoseStatus.NONE,
      drugHsId: activity.packedMedicationId!
    }));
}

// dose status of lower precedence will be invisible if they occur at the same time as a dose status of a higher precedence
/* eslint-disable sonarjs/cognitive-complexity */
function mergeDoseTimeIndicators<T extends DoseIndicator | PrnDoseIndicator>(doseTimes: T[]): T[] {
  const doseStatuses = new Map<string, T>();
  // merge results according to status precedence, and mark as dose time as time critical if any dose is

  doseTimes.forEach((d) => {
    const key = DateUtils.fromDate(roundToNearestMinutes(d.time, 10)); // aggregate results by hour for now. todo: verify with BA to follow-up
    const existingValue = doseStatuses.get(key);

    // Check with BA what this amount value (count) is about. The code logic below seems strange in what it does.
    if (!existingValue) {
      doseStatuses.set(key, { ...d, amount: 1 });
    } else if (existingValue && d.status > existingValue.status) {
      doseStatuses.set(key, {
        ...d,
        isControlled: (existingValue as DoseIndicator).isControlled ? true : (d as DoseIndicator).isControlled,
        isTimeCritical: (existingValue as DoseIndicator).isTimeCritical ? true : (d as DoseIndicator).isTimeCritical,
        isInsulin:  (existingValue as DoseIndicator).isInsulin ? true : (d as DoseIndicator).isInsulin,
        amount: (existingValue.amount ?? 1) + 1,
      });
    } else if (existingValue) {
      let isControlled = (existingValue as DoseIndicator).isControlled;
      let timeCritical = (existingValue as DoseIndicator).isTimeCritical;
      let insulin = (existingValue as DoseIndicator).isInsulin;
      if ((d as DoseIndicator).isControlled) {
        isControlled = true;
      }
      if ((d as DoseIndicator).isTimeCritical) {
        timeCritical = true;
      }
      if ((d as DoseIndicator).isInsulin) {
        insulin = true;
      }
      doseStatuses.set(key, {
        ...existingValue,
        isControlled: isControlled,
        isTimeCritical: timeCritical,
        isInsulin: insulin
      });
    }
  });
  const DoseTimeIndicators = Array.from(doseStatuses.values()).map((d) => (d.amount === 1 ? { ...d, amount: undefined } : d));
  return DoseTimeIndicators
}

function roundToNearestMinutes(date: Date, intervalMinutes: number) {
  const roundedMinutes = Math.floor(datefns.getMinutes(date) / intervalMinutes) * intervalMinutes;
  return datefns.setMinutes(datefns.startOfMinute(date), roundedMinutes);
}

function patientClockAreEqual(preProps: IProps, nextProps: IProps) {
  return isEqual(preProps, nextProps);
}

export const MemorizedPatientClock = React.memo(PatientClock, patientClockAreEqual);
