/* eslint-disable max-lines */
import * as lodash from 'lodash';
import React from 'react';
import { IoAddSharp, IoCheckmarkSharp, IoCloseSharp } from 'react-icons/io5';
import styled, { css } from 'styled-components';
import SvgRings from '../../kit/Icons/Rings';
import { ClockOutlineStyle, IClockOutlineStyle, IGradient } from './ClockOutlineStyle';
import { DateUtils } from '../../core/utils/dateUtils';
import { IconTimeCriticalDrug } from '../../kit/Icons/DrugIcons/TimeCriticalDrug';
import {IconInsulinDrug} from "../../kit/Icons/DrugIcons/InsulinDrug";
import { IconControlledDrug } from '../../kit/Icons/DrugIcons/ControlledDrug';

export interface DoseIndicator {
  time: Date;
  status: DoseStatus;
  amount?: number;
  isTimeCritical?: boolean;
  isInsulin?: boolean;
  isControlled?: boolean;
  drugHsId: number;
}

export interface PrnDoseIndicator {
  time: Date;
  status: PrnStatus;
  amount?: number;
}

interface DateInterval {
  start: Date;
  end: Date;
}

interface IProps {
  doses?: DoseIndicator[];
  prns?: PrnDoseIndicator[];
  showTimes?: boolean;
  interval: DateInterval;
  children: React.ReactNode;
  clockOutlineStyle?: IClockOutlineStyle;
  isPast?: boolean;
  size?: string;
}

const RootContainer = styled.div<{
  size?: string;
}>`
  min-width: ${(p) => p.size ?? `250px`};
  aspect-ratio: 1;
  @media (max-height: 680px) {
    min-width: 180px;
  }
  @media (min-height: 680px) and (max-height: 820px) {
    min-width: 200px;
  }

  position: relative;
`;

export function BaseClock(props: IProps) {
  const now = new Date();
  return (
    <RootContainer size={props.size}>
      <ProgressRing currentDate={now}>
        <InnerContainer
          gradient={
            props.clockOutlineStyle ? props.clockOutlineStyle.gradient : ClockOutlineStyle.DEFAULT.value.gradient
          }
        >
          <ChildContainer>{props.children}</ChildContainer>
          <ClockSegments interval={props.interval} />
          {!props.isPast && <IntervalHighlight interval={props.interval} />}
          <BackgroundImage
            color={
              props.clockOutlineStyle ? props.clockOutlineStyle.svgColor : ClockOutlineStyle.DEFAULT.value.svgColor
            }
          />
        </InnerContainer>
      </ProgressRing>

      <Satellites showTimes={props.showTimes} doses={props.doses ?? []} prns={props.prns ?? []} />
    </RootContainer>
  );
}

/* --- */

const ProgressRing = styled.div<{ currentDate: Date }>`
  ${(p) => {
    const currentDeg = (360 / 24) * (p.currentDate.getMinutes() / 60 + p.currentDate.getHours());

    return css`
      background: conic-gradient(
        ${p.theme.button.primary.bg} ${currentDeg}deg,
        rgba(0, 0, 0, 0) ${currentDeg}deg,
        rgba(0, 0, 0, 0) 360deg
      );
    `;
  }}
  z-index: 1;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 9999999rem;
  width: 100%;
  height: 100%;
`;

/* --- */

const InnerContainer = styled.div<{
  gradient: IGradient;
}>`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #c35cff;
  background-image: linear-gradient(35deg, ${(p) => p.gradient.light} 0%, ${(p) => p.gradient.dark} 80%);
  border-radius: 9999999rem;
`;

/* --- */

const ChildContainer = styled.div`
  z-index: 4;
  position: absolute;
  width: 72%;
  height: 72%;
  top: 14%;
  left: 14%;
  & > * {
    border-radius: 9999999rem;
    width: 100%;
    height: 100%;
  }
`;

/* --- */

const ClockSegmentsContainer = styled.div`
  z-index: 3;
  position: absolute;
  width: 100%;
  height: 100%;
  p {
    color: ${(p) => p.theme.backgrounds.default.fg}};
  }
  & > div {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
`;

const ClockSegment = styled.div<{ militaryHour: number }>`
  position: absolute;
  height: 95%;
  transform: rotate(${(p) => (360 / 24) * p.militaryHour}deg);
`;
const ClockDigit = styled.p<{ militaryHour: number }>`
  transform: rotate(-${(p) => (360 / 24) * p.militaryHour}deg);
  font-size: 0.7em;
`;

const ClockSegments = (props: { interval: DateInterval }) => {
  const startHour = props.interval.start.getHours();
  const endHour = props.interval.end.getHours();

  const visibleHours = [...lodash.range(startHour, endHour < startHour ? endHour + 24 : endHour), endHour].map((h) =>
    h === 24 ? 24 : h % 24,
  );

  return (
    <ClockSegmentsContainer>
      <div>
        {visibleHours.map((h) => (
          <ClockSegment militaryHour={h} key={h}>
            <ClockDigit militaryHour={h}>{h}</ClockDigit>
          </ClockSegment>
        ))}
      </div>
    </ClockSegmentsContainer>
  );
};

/* --- */

const IntervalHighlight = styled.div<{ interval: { start: Date; end: Date } }>`
  z-index: 2;
  position: absolute;

  ${(props) => {
    const startDeg = (360 / 24) * (props.interval.start.getHours() + props.interval.start.getMinutes() / 60);
    const endDeg = (360 / 24) * (props.interval.end.getHours() + props.interval.end.getMinutes() / 60);
    const diffDeg = endDeg < startDeg ? Math.abs(360 + endDeg - startDeg) : Math.abs(endDeg - startDeg);

    return css`
      background: conic-gradient(
        ${props.theme.button.primary.bg}5c 0deg,
        ${props.theme.button.primary.bg}5c ${diffDeg}deg,
        rgba(0, 0, 0, 0) ${diffDeg}deg
      );
      transform: scale(1.65) rotate(${startDeg}deg);
      @media (max-height: 820px) {
        transform: scale(1.85) rotate(${startDeg}deg);
      }
    `;
  }}
  border-radius: 9999999rem;
  height: 100%;
  width: 100%;
`;

/* --- */

const BackgroundImage = styled(SvgRings)`
  z-index: 1;
  width: 90%;
  height: 90%;
  position: absolute;
  top: 5%;
  left: 5%;
`;

/* --- */

const SatelliteContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  p {
    color: ${(p) => p.theme.backgrounds.default.fg}};
  }
  & > div {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
`;

const SatelliteSegment = styled.div<{ date: Date; isPrn?: boolean }>`
  position: absolute;
  height: calc(100% + ${(p) => (p.isPrn ? '50px' : '130px')});
  @media (max-height: 820px) {
    height: calc(100% + ${(p) => (p.isPrn ? '25%' : '60%')});
  }
  transform: rotate(${(p) => (360 / 24) * p.date.getHours() + (360 / 1440) * p.date.getMinutes()}deg);
  width: 1px;
`;

const SatelliteLine = styled.div`
  border: solid 1px rgba(255, 255, 255, 0.8);
  height: 50%;
  width: 1px;
`;
const SatelliteCircle = styled.div<{
  date: Date;
  status?: DoseStatus;
  prnStatus?: PrnStatus;
}>`
  font-size: 0.7em;

  ${(props) => {
    const size = props.prnStatus !== undefined ? 20 : 30;
    return css`
      width: ${size}px;
      height: ${size}px;
      transform: translate(-${size / 2}px, 0px)
        rotate(-${(360 / 24) * props.date.getHours() + (360 / 1440) * props.date.getMinutes()}deg);
    `;
  }}

  ${(props) => {
    if (props.status !== DoseStatus.COMPLETE_WITH_DOSE_SUPPLIED) {
      return css`
        border-radius: 9999999rem;
      `;
    }
  }}
  border: solid 1px rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) =>
    p.status === DoseStatus.MISSED ? p.theme.backgrounds.default.fg : p.theme.typography.defaults.color};

  ${(props) => {
    switch (props.status) {
      case DoseStatus.COMPLETE_WITH_DOSE_SUPPLIED:
      case DoseStatus.COMPLETE:
        return css`
          background-color: #a4e92a;
        `;
      case DoseStatus.COMPLETE_LATE:
        return css`
          background-color: #262626;
          color: #00FF00 ;
        `;
      case DoseStatus.NO_STOCK:
        return css`
          background-color: #ea9d2a;
        `;
      case DoseStatus.OTHER:
        return css`
          background-color: #ffff;
        `;
      case DoseStatus.HOSPITAL_ABSENT:
        return css`
          background-color: #00b3da;
        `;
      case DoseStatus.REFUSED:
        return css`
          background-color: #fc4b00;
        `;
      case DoseStatus.WITHHELD:
        return css`
          background-color: #fff752;
        `;
    }
  }}

  ${(props) => {
    switch (props.prnStatus) {
      case PrnStatus.ADMINISTERED:
      case PrnStatus.ADMINISTERED_WITH_OUTCOME:
        return css`
          background-color: #fe89bd;
        `;
    }
  }}
`;

// the value determines the precedence of the status in the aggregated result when multiple meds are administered at the same time
// e.g. if a med is withheld and another is complete, the final status indicator will be withheld (i.e. WITHHELD > COMPLETE)
export enum DoseStatus {
  COMPLETE,
  COMPLETE_WITH_DOSE_SUPPLIED,
  MISSED,
  NO_STOCK,
  HOSPITAL_ABSENT,
  OTHER,
  NONE,
  COMPLETE_LATE,
  REFUSED,
  WITHHELD,
}

export enum PrnStatus {
  ADMINISTERED,
  ADMINISTERED_WITH_OUTCOME,
}

const getStatusIcon = (props: { status?: DoseStatus; prnStatus?: PrnStatus }) => {
  switch (props.status) {
    case DoseStatus.COMPLETE_WITH_DOSE_SUPPLIED:
      return <IoCheckmarkSharp />;
    case DoseStatus.COMPLETE:
      return <IoCheckmarkSharp />;
  case DoseStatus.COMPLETE_LATE:
    return <IoCheckmarkSharp />;
    case DoseStatus.REFUSED:
    case DoseStatus.WITHHELD:
      return <IoCloseSharp />;
  }

  // eslint-disable-next-line sonarjs/no-small-switch
  switch (props.prnStatus) {
    case PrnStatus.ADMINISTERED_WITH_OUTCOME:
      return <IoAddSharp />;
  }
  return null;
};

const Badge = styled.div`
  color: #323232;
  background-color: #fff;
  border-radius: 2000em;
  position: absolute;
  top: -15px;
  right: -7px;
  width: 1em;
  height: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
`;

const Time = styled.div<{ date: Date }>`
  color: #fff;
  position: absolute;
  text-align: center;
  white-space: nowrap;

  ${(props) => {
    const size = 30;

    const hour = props.date.getHours();
    switch (true) {
      case hour > 21 || hour <= 3:
        return css`
          transform: translate(0, -${size * 0.75}px);
        `;
      case hour > 3 && hour <= 9:
        return css`
          transform: translate(${size * 1.25}px, 0);
        `;
      case hour > 9 && hour <= 15:
        return css`
          transform: translate(0, ${size * 0.75}px);
        `;
      case hour > 15 && hour <= 21:
        return css`
          transform: translate(-${size * 1.25}px, 0);
        `;
    }
  }}
`;
const TimeCriticalDrugWarningContainer = styled.div`
  display: inline;
  float: left;
  top: -5px;
  left: -5px;
  position: absolute;
`;

const TimeCriticalDrugWarning = () => {
  return (
    <TimeCriticalDrugWarningContainer>
      <IconTimeCriticalDrug data-testid="drug-warning-icon" />
    </TimeCriticalDrugWarningContainer>
  );
};

const InsulinDrugWarningContainer = styled.div`
  display: inline;
  float: right;
  top: -5px;
  right: -5px;
  position: absolute;
`;
const InsulinDrugWarning = () => {
  return (
      <InsulinDrugWarningContainer>
        <IconInsulinDrug data-testid="insulin-drug-icon" />
      </InsulinDrugWarningContainer>
  );
};

const ControlledDrugWarningContainer = styled.div`
  width: 20px;
`;
const ControlledDrugWarning = () => {
  return (
      <ControlledDrugWarningContainer>
        <IconControlledDrug data-testid="controlled-drug-icon" width={20} xlinkTitle={`Controlled Drug`}  />
      </ControlledDrugWarningContainer>
  );
};

const Satellites = (props: { doses: Array<DoseIndicator>; prns: Array<PrnDoseIndicator>; showTimes?: boolean }) => {
  return (
    <SatelliteContainer>
      <div>
        {props.doses.map((p, index) => (
          <SatelliteSegment date={p.time} key={index}>
            <SatelliteCircle date={p.time} status={p.status}>
              {p.isTimeCritical && <TimeCriticalDrugWarning />}
              {p.isInsulin && <InsulinDrugWarning />}
              {getStatusIcon({ status: p.status })}
              {p.amount !== undefined && <Badge>{p.amount}</Badge>}
              {props.showTimes && <Time date={p.time}>{DateUtils.dateTo24HourTimeString(p.time)}</Time>}
            </SatelliteCircle>
            <SatelliteLine />
          </SatelliteSegment>
        ))}
        {props.prns.map((p, index) => (
          <SatelliteSegment date={p.time} key={index} isPrn>
            <SatelliteCircle date={p.time} prnStatus={p.status}>
              {getStatusIcon({ prnStatus: p.status })}
              {p.amount !== undefined && <Badge>{p.amount}</Badge>}
            </SatelliteCircle>
            <SatelliteLine />
          </SatelliteSegment>
        ))}
      </div>
    </SatelliteContainer>
  );
};
