import styled from 'styled-components';
import { Button } from '../../../../kit/Button';

export const PageNavigationButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding-right: 50px;
  padding-bottom: 50px;
  display: grid;
  grid-gap: 20px;
  grid-auto-flow: column;
  width: 100%;
  justify-content: end;
`;

export const PreviousButton = styled(Button)`
  width: 120px;
  padding: 0.5em 1em;
  border: solid 2px ${(props) => props.theme.button.secondary.bg};
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
  background: ${(props) => props.theme.backgrounds.default.bg};
  height: 45px;
`;

export const NextButton = styled(Button)`
  width: 120px;
  font-weight: bold;
  font-size: 15px;
  height: 45px;
`;
