import itiriri from 'itiriri';
import { HSFacility, HSFacilityGroup } from 'server-openapi';

interface FacilityUtilStore {
  facilityStore: ReadonlyMap<string, HSFacility>;
}

export class FacilityUtils {
  private stores: FacilityUtilStore;

  constructor(apiStores: FacilityUtilStore) {
    this.stores = apiStores;
  }
  public fetchAllFacilities = (facilityGroup?: HSFacilityGroup) =>
    fetchAllFacilities(this.stores.facilityStore, facilityGroup);
  public fetchFacilityById = (facilityId?: number) => fetchFacilityById(this.stores.facilityStore, facilityId);
}

function fetchAllFacilities(facilityStore: ReadonlyMap<string, HSFacility>, facilityGroup?: HSFacilityGroup) {
  return itiriri(facilityStore.values())
    .filter((f) => f.facilityGroupId === facilityGroup?.hsId)
    .toArray();
}

function fetchFacilityById(facilityStore: ReadonlyMap<string, HSFacility>, facilityId?: number) {
  return itiriri(facilityStore.values()).find((facility) => facility.hsId === facilityId);
}
