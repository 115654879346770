import React from 'react';
import styled, { css } from 'styled-components';
import { IconClose } from '../Icons/Close';
import { Layout } from '../Layout';
import { Paper } from '../Paper';
import { Intent } from '../Theme/Theme';

export interface Toast {
  id: number;
  text: string;
  options: ToastOptions;
}

export interface ToastOptions {
  intent?: Intent;
  timeoutSeconds: number;
}

interface ToastProps {
  onClose: () => void;
  intent?: Intent;
  children: React.ReactNode;
}

export function Toast(props: ToastProps) {
  return (
    <StyledToast elevation={1} intent={props.intent}>
      <Layout gap={0.5} horizontal align="center" justify="space-between">
        <div>{props.children}</div>
        <StyledCloseButton onClick={props.onClose}>
          <IconClose height={12} />
        </StyledCloseButton>
      </Layout>
    </StyledToast>
  );
}

const StyledToast = styled(Paper)`
  color: ${(props) => props.theme.toast.default.fg};
  background: ${(props) => props.theme.toast.default.bg};

  ${(props) => {
    if (props.intent) {
      return css`
        color: ${props.theme.toast[props.intent].fg};
        background: ${props.theme.toast[props.intent].bg};
      `;
    }
  }}
`;

const StyledCloseButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
`;
