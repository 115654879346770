import React from 'react';
import styled from 'styled-components';

const StatusContainer = styled.div`
  text-align: center;

  p {
    color: ${(p) => p.theme.backgrounds.default.fg};
  }
`;

const StatusText = styled.h2<{ color: string }>`
  background-color: ${(p) => p.color};
  padding: 1em;
  border-radius: 99999em;
  display: inline-flex;

  justify-content: center;
  align-items: center;

  height: 2em;
  width: 2em;

  span {
    color: #000;
  }
`;

export function RoundStatusIndicator(props: { color: string; count: number; label: string }) {
  return (
    <StatusContainer>
      <StatusText color={props.color}>
        <span>{props.count}</span>
      </StatusText>
      <p style={{ textTransform: 'uppercase' }}>{props.label}</p>
    </StatusContainer>
  );
}
