import React from 'react';
import { HSDrug } from 'server-openapi';
import styled, { useTheme } from 'styled-components';
import { IconControlledDrug } from '../../../../kit/Icons/DrugIcons/ControlledDrug';
import { IconCytotoxic } from '../../../../kit/Icons/DrugIcons/Cytotoxic';
import { IconDrowsiness } from '../../../../kit/Icons/DrugIcons/Drowsiness';
import { IconTestRequired } from '../../../../kit/Icons/DrugIcons/INRTestRequired';
import { IconNonCytotoxic } from '../../../../kit/Icons/DrugIcons/NonCytotoxic';
import { IconPsychotropic } from '../../../../kit/Icons/DrugIcons/Psychotropic';
import { TickInsideGreenCircle } from '../../../../kit/Icons/TickInsideGreenCircle';
import { IconReproductiveHazardous } from '../../../../kit/Icons/DrugIcons/ReproductiveHazardous';
import { Layout } from '../../../../kit/Layout';
import { Text } from '../../../../kit/Text';
import { DrugWarnings, DrugUtils, AdministrationWarnings } from '../../../../syncstream/utils/DrugUtils';
import { IconNewDrug } from '../../../../kit/Icons/DrugIcons/NewDrug';
import { IconDoNotCrush } from '../../../../kit/Icons/DrugIcons/DoNotCrush';
import IconAllergyAlert from '../../../../kit/Icons/DrugIcons/AllergyAlert';
import { TimeCriticalDrugWarning } from '../../../Rounds/CreateRoundPage/components/ResidentRow';
import SelfAdministeredIcon from '../../../../kit/Icons/ResidentIcons/SelfAdministered';
import {Tooltip} from "react-tooltip";

interface DrugWarningProps {
  drugWarnings: DrugWarnings;
  displayLabels?: boolean;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function DrugWarningIcons(props: DrugWarningProps) {
  const medicineColors = useTheme().medicineColor;
  return (
    <>
        {props.drugWarnings.controlledDrug && (
            <div>
                <IconControlledDrug data-testid="drug-warning-icon" width={20} xlinkTitle={'Controlled Drug'}/>
                {props.displayLabels && <Text style={{ color: medicineColors.controlledDrug }}>Controlled Drug</Text>}
            </div>
        )}

      {props.drugWarnings.psychotropic && (
        <div>
          <IconPsychotropic data-testid="drug-warning-icon" width={20} xlinkTitle={`Psychotropic Medication`}/>
          {props.displayLabels && <Text style={{ color: medicineColors.psychotropic }}>Psychotropic</Text>}
        </div>
      )}

      {props.drugWarnings.reproductiveHazardous && (
        <div>
          <IconReproductiveHazardous data-testid="drug-warning-icon" width={20} xlinkTitle={`Reproductive Hazardous`}/>
          {props.displayLabels && (
            <Text style={{ color: medicineColors.reproductiveHazardous }}>Reproductive Hazardous</Text>
          )}
        </div>
      )}

      {props.drugWarnings.cytotoxic && (
        <div>
          <IconCytotoxic data-testid="drug-warning-icon" width={20} xlinkTitle={`Cytotoxic Medication`}/>
          {props.displayLabels && <Text style={{ color: medicineColors.cytotoxic }}>Cytotoxic</Text>}
        </div>
      )}

      {props.drugWarnings.nonCytotoxic && (
        <div>
          <IconNonCytotoxic data-testid="drug-warning-icon" width={20} xlinkTitle={`Non Cytotoxic Medication`}/>
          {props.displayLabels && <Text style={{ color: medicineColors.nonCytotoxic }}>Non Cytotoxic</Text>}
        </div>
      )}
    </>
  );
}

export function DrugLabel(props: { drug: HSDrug; isCeased: boolean; isTimeCritical?: boolean; isPsychotropicConsent: boolean }) {
  const drugWarnings = DrugUtils.getDrugWarnings(props.drug);
  const textDecorationLine = props.isCeased ? 'line-through' : '';
  return (
    <Layout horizontal>
      <DrugWarningIcons drugWarnings={drugWarnings} />
      <Text
        weight={'bold'}
        style={{ color: DrugUtils.getTextColour(drugWarnings), textDecorationLine: textDecorationLine }}
      >
        {props.drug.name} {props.drug.formAbbreviation} {props.drug.strength}
      </Text>
      {props.isTimeCritical && <TimeCriticalDrugWarning />}
      {drugWarnings.psychotropic && props.isPsychotropicConsent && <TickInsideGreenCircle xlinkTitle={`Psychotropic Consent`} width={13}/> }
    </Layout>
  );
}

export function AdministrationWarningIcons(props: { administrationWarnings: AdministrationWarnings }) {
  return (
    <>
      {props.administrationWarnings.medicationStatus && (
        <AdministrationInstructionsField>
          <IconNewDrug width={20} />
          <Text weight={'bold'}>&nbsp;{props.administrationWarnings.medicationStatus}</Text>
        </AdministrationInstructionsField>
      )}

      {props.administrationWarnings.drowsiness && (
          <>
              <Tooltip id="Drowsiness" />
              <AdministrationInstructionsField
                  data-tooltip-content="May cause Drowsiness"
                  data-tooltip-id="Drowsiness"
                  data-tooltip-place="top">
              <IconDrowsiness width={20} />
              <Text weight={'bold'}>&nbsp;Drowsiness</Text>
              </AdministrationInstructionsField>
          </>
      )}

        {props.administrationWarnings.hasRelatedPrnRegular && (
            <>
            <Tooltip id="REGandPRN" />
            <AdministrationInstructionsField
                data-tooltip-content="Regular and PRN medication"
                data-tooltip-id="REGandPRN"
                data-tooltip-place="top">
                <IconDrowsiness width={20} />
                <Text weight={'bold'}>&nbsp;REG & PRN</Text>
            </AdministrationInstructionsField>
            </>
        )}

      {props.administrationWarnings.inrTestRequired && (
        <AdministrationInstructionsField>
          <IconTestRequired width={20} />
          <Text weight={'bold'}>INR Test Required</Text>
        </AdministrationInstructionsField>
      )}

      {props.administrationWarnings.bglTestRequired && (
          <>
          <Tooltip id="BGLTestRequired" />
            <AdministrationInstructionsField
                data-tooltip-content="Blood glucose test required"
                data-tooltip-id="BGLTestRequired"
                data-tooltip-place="top">
              <IconTestRequired width={20} />
              <Text weight={'bold'}>BGL Test Required</Text>
            </AdministrationInstructionsField>
          </>
      )}

      {props.administrationWarnings.allergyAlert && (
        <AdministrationInstructionsField>
          <IconAllergyAlert width={20} />
          <Text weight={'bold'}>&nbsp;Allergy Alert</Text>
        </AdministrationInstructionsField>
      )}

      {
        //if not crushable show do not crush warning
        !props.administrationWarnings.crushable && (
          <AdministrationInstructionsField>
            <IconDoNotCrush width={20} />
            <Text weight={'bold'}>&nbsp;Do not crush</Text>
          </AdministrationInstructionsField>
        )
      }

      {props.administrationWarnings.isSelfAdministered && <SelfAdministeredIcon />}
    </>
  );
}

const AdministrationInstructionsField = styled.div`
  background-color: ${(props) => props.theme.backgrounds.light.bg};
  color: ${(props) => props.theme.button.info.fg};
  border-radius: 20px;
  display: flex;
  padding: 3px 9px 3px 3px;
  width: fit-content;
`;
