import itiriri from 'itiriri';
import React from 'react';
import { useRouteMatch, useParams, Switch, Redirect, Route } from 'react-router';
import styled, { css } from 'styled-components';
import urljoin from 'url-join';
import { NavigationBar } from '../../../components/NavigationBar/NavigationBar';
import { MenuRoute, PageMenuBar } from '../../../components/PageMenuBar';
import { Grid } from '../../../kit/Grid';
import { IconBack } from '../../../kit/Icons/Back';
import { useSyncCenter } from '../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../core/storage/hooks/UseStore';
import { NimsSettings } from './components/Nims/NimsSettings';
import { SecondCheckSettings } from './components/SecondCheck/SecondCheckSettings';
import { UsersSettings } from './components/Users/UsersSettings';
import { LayoutContainer } from '../../../kit/LayoutContainer';
import { Paper } from '../../../kit/Paper';
import { FacilityGroupConfiguration } from './components/FacilityGroupConfigurations/FacilityGroupConfigurations';
import { Button } from '../../../kit/Button';
import { colors, Intent } from '../../../kit/Theme/Theme';
import { RequireInternet } from '../../../components/RequireInternet/RequireInternet';
import {useHistory} from "react-router-dom";
import {useGroupPermissions} from "../../../core/authz/PermissionsProvider";
import WarningTriangle from '../../../kit/Icons/WarningTriangle';

interface IParams {
  facilityGroupId: string;
}

export function FacilitySettingsPage() {
  const { facilityGroupId } = useParams<IParams>();

  const services = useSyncCenter();
  const history = useHistory();
  const facilityGroupsStore = useStore(services.facilityGroups.store).store;
  const facilityGroups = itiriri(facilityGroupsStore.values()).toArray();
  const facilityGroup = facilityGroups.find((fg) => fg.hsId === parseInt(facilityGroupId))!;
  const groupPermissions = useGroupPermissions();
  const canViewFacilityGroupConfigurationExcludingUserManagement = groupPermissions.canViewFacilityGroupConfigurationExcludingUserManagement;
  const canViewFacilityGroupConfigurationIncludingUserManagement = groupPermissions.canViewFacilityGroupConfigurationIncludingUserManagement;
  const canAssignRolesExcludingUserManagementPermission = groupPermissions.canAssignRolesExcludingUserManagementPermission;
  return (
    <Grid cols={1} gap={0}>
      <NavigationBar
        nodes={() => ([
          <LeftNav gap={1} cols={2}>
              <IconBack onClick={() => history.push(`/facility-group/${facilityGroupId}`)} />
            <h1>Settings - {facilityGroup.name}</h1>
          </LeftNav>,
        ])}
      ></NavigationBar>
      {canViewFacilityGroupConfigurationIncludingUserManagement ||
      canViewFacilityGroupConfigurationExcludingUserManagement ||
      canAssignRolesExcludingUserManagementPermission ? (
        <RequireInternet>
          <FacilitySettingsContent />
        </RequireInternet>
      ) : (
        <LayoutContainer>
          <Paper intent={Intent.Warning}>
            <h3>
              <span><WarningTriangle style={{verticalAlign: "text-bottom"}}/> Permission Denied - You cannot view facility group configuration</span>
            </h3>
          </Paper>
        </LayoutContainer>
      )}
    </Grid>
  );
}

function FacilitySettingsContent() {
  const { path } = useRouteMatch();
    const groupPermissions = useGroupPermissions();

    const canConfigureNim = groupPermissions.canConfigureNim;
    const canConfigureSecondCheck = groupPermissions.canConfigureSecondCheck;
    const canAssignRolesExcludingUserManagementPermission = groupPermissions.canAssignRolesExcludingUserManagementPermission;
    const canAssignRolesWithUserManagementPermission = groupPermissions.canAssignRolesWithUserManagementPermission;
    const canAssignBasicUserManagementRoles = groupPermissions.canAssignBasicUserManagementRoles;
    const canAssignRegisteredNurseRoles = groupPermissions.canAssignRegisteredNurseRoles;

    const menuRoutes: MenuRoute[] = [];
    if (canConfigureNim) menuRoutes.push({ route: 'processes', label: 'PROCESSES' });
    if (canConfigureNim) menuRoutes.push({ route: 'nims', label: 'NIMS' });
    if (canConfigureSecondCheck) menuRoutes.push({ route: 'second-check', label: 'SECOND CHECK' });
    if (canAssignRolesExcludingUserManagementPermission || canAssignRolesWithUserManagementPermission || canAssignBasicUserManagementRoles || canAssignRegisteredNurseRoles) menuRoutes.push({ route: 'users', label: 'USERS' });

    return (
    <>
      <PageMenuBar menuRoutes={menuRoutes} />
      <SettingsContentContainer>
        <Switch>
          <Redirect exact path={path} to={urljoin(path, 'processes')} />
            {canConfigureNim ? (<Route path={urljoin(path, 'nims')} component={NimsSettings} />) : null}
            {canConfigureSecondCheck ? (<Route path={urljoin(path, 'second-check')} component={SecondCheckSettings} />) : null}

            {(canAssignRolesExcludingUserManagementPermission || canAssignRolesWithUserManagementPermission || canAssignBasicUserManagementRoles || canAssignRegisteredNurseRoles) ? (<Route exact path={urljoin(path, 'users')} component={UsersSettings} />) : null}
            {canConfigureNim ? (<Route exact path={urljoin(path, 'processes')} component={FacilityGroupConfiguration} />) : null}
        </Switch>
      </SettingsContentContainer>
    </>
  );
}

const SettingsContentContainer = styled.div`
  padding: 50px;
`;

const LeftNav = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const TableActionButton = styled(Button)`
  color: ${colors.bg_purple_400};
  padding: 0;

  ${(props) => {
    if (props.disabled) {
      return css`
        opacity: 35%;
      `;
    }
  }}
`;
