import React from 'react';
import styled from 'styled-components';
import { Text } from '../../kit/Text';

import { addDays, format, isTomorrow, startOfDay, subDays } from 'date-fns';
import IconLeftArrow from '../../kit/Icons/IconLeftArrow';
import IconRightArrow from '../../kit/Icons/IconRightArrow';

interface IProps {
  selectedDate: Date;
  onChange: (date: Date) => void;
}

export function DateSelector(props: IProps) {
  return (
    <DatePickerContainer>
      <SelectorContainer>
        {props.selectedDate > subDays(startOfDay(new Date()), 6) && (
          <IconLeftArrow onClick={() => props.onChange(subDays(props.selectedDate, 1))} />
        )}
      </SelectorContainer>

      <Text weight={'bold'} size={'medium'}>
        {format(props.selectedDate, 'd MMMM')}
      </Text>
      <SelectorContainer>
        {!isTomorrow(props.selectedDate) && (
          <IconRightArrow onClick={() => props.onChange(addDays(props.selectedDate, 1))} />
        )}
      </SelectorContainer>
    </DatePickerContainer>
  );
}

const DatePickerContainer = styled.div`
  display: flex;
  gap: 1em;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: ${(p) => p.theme.backgrounds.default.fg};
`;

const SelectorContainer = styled.div`
  vertical-align: text-top;
  cursor: pointer;
  z-index: 5;
`;
