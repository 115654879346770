import React from 'react';
import { useTransition, animated } from 'react-spring';
import styled from 'styled-components';
import { Toast } from './Toast';

interface ToastContainerProps {
  toasts: Toast[];
  onClose: (toast: Toast) => void;
}

export function ToastContainer(props: ToastContainerProps) {
  const { toasts, onClose } = props;

  const transitions = useTransition(toasts, {
    from: { transform: 'translateX(100%)', opacity: 0 },
    enter: { transform: 'translateX(0%)', opacity: 1 },
    leave: { transform: 'translateX(0%)', opacity: 0 },
    config: {
      mass: 1,
      tension: 500,
      friction: 30,
    },
  });

  return (
    <StyledToastContainer>
      {transitions((style, item) => (
        <animated.div style={style}>
          <Toast intent={item.options.intent} onClose={() => onClose(item)}>
            {item.text}
          </Toast>
        </animated.div>
      ))}
    </StyledToastContainer>
  );
}

const StyledToastContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 1rem;
  }
`;
