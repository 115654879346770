import {useSyncCenter} from "../../../syncstream/SyncCenterProvider";
import {useMap, useStore} from "../../../core/storage/hooks/UseStore";
import {useEffect, useState} from "react";

const useDashboardData = () => {
  const [indexedDbData, setIndexedDbData] = useState<{ dataType: string, numberOfRecords: number, queueSize: string }[]>([{ dataType: '', numberOfRecords: 0, queueSize: '' }]);

  const syncCenter = useSyncCenter();
  const drugsRepo = useMap(syncCenter.drugs);
  const patientsRepo = useMap(syncCenter.patients);
  const packedPatientDaysRepo = useMap(syncCenter.packedPatientDays);
  const packedPatientPrnMedicationsRepo = useMap(syncCenter.packedPatientPrnMedications);
  const syringeDriverActivityRepo = useMap(syncCenter.syringeDriverActivity);
  const syringeDriverActivityQueue = useStore(syncCenter.syringeDriverActivity.queue.storage);
  const patchObservationsRepo = useMap(syncCenter.patchObservations);
  const patchObservationsQueue = useStore(syncCenter.patchObservations.queue.storage);
  const doseRoundsRepo = useMap(syncCenter.rounds);
  const doseRoundsQueue = useStore(syncCenter.rounds.queue.storage);
  const ordersRepo = useMap(syncCenter.orders);

  useEffect(() => {
    setIndexedDbData([
      {dataType: 'Drugs', numberOfRecords: drugsRepo.size, queueSize: '-'},
      {dataType: 'Patients', numberOfRecords: patientsRepo.size, queueSize: '-'},
      {dataType: 'Packed Patients Days', numberOfRecords: packedPatientDaysRepo.size, queueSize: '-'},
      {dataType: 'Packed Patient PRN Medication', numberOfRecords: packedPatientPrnMedicationsRepo.size, queueSize: '-'},
      {dataType: 'Syringe Driver Activities', numberOfRecords: syringeDriverActivityRepo.size, queueSize: syringeDriverActivityQueue.changeCount.toString()},
      {dataType: 'Patch Observations', numberOfRecords: patchObservationsRepo.size, queueSize: patchObservationsQueue.changeCount.toString()},
      {dataType: 'Dose Rounds', numberOfRecords: doseRoundsRepo.size, queueSize: doseRoundsQueue.changeCount.toString()},
      {dataType: 'Orders', numberOfRecords: ordersRepo.size, queueSize: '-'},
    ])
  }, [
    drugsRepo,
    patientsRepo,
    packedPatientDaysRepo,
    packedPatientPrnMedicationsRepo,
    syringeDriverActivityRepo,
    syringeDriverActivityQueue,
    patchObservationsRepo,
    patchObservationsQueue,
    doseRoundsRepo,
    doseRoundsQueue,
    ordersRepo
  ]);

  return {indexedDbData};
}

export default useDashboardData;