import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { HSPatient, HSPatientAllergy } from 'server-openapi';
import { Button } from '../../../kit/Button';
import { Grid } from '../../../kit/Grid';
import { Layout } from '../../../kit/Layout';
import { Text } from '../../../kit/Text';
import { Dictionary, groupBy } from 'lodash';
import { useSyncCenter } from '../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../core/storage/hooks/UseStore';
import itiriri from 'itiriri';
import { format } from 'date-fns';
import { DateUtils } from '../../../core/utils/dateUtils';
import { IconClose } from '../../../kit/Icons/Close';
import { Link } from 'react-router-dom';
import { PatientLocalizations } from '../../../localisation/PatientLocalizations';
import { Dialog } from '../../../kit/Dialog';
import { AllergyInformation } from './allergyInformation/AllergyInformation';
import { AllergyUtils } from '../../../syncstream/utils/AllergyUtils';
import { Intent } from '../../../kit/Theme/Theme';
import { useApiUtils } from '../../../syncstream/utils/hooks/useApiUtils';
import { useHistory } from 'react-router';
import SvgScrollArrow from '../../../kit/Icons/ScrollArrow';
import { ScrollHandler } from './ScrollHandler';
import { UpdateResidentDialog } from './editResidentDetails/ResidentDetailDialog';
import OffsiteIcon from '../../../kit/Icons/ResidentIcons/Offsite';
import SelfAdministeredIcon from '../../../kit/Icons/ResidentIcons/SelfAdministered';
import { Tooltip } from 'react-tooltip';

interface Props {
  patient: HSPatient;
  roundId?: string;
}

export function ResidentDetailsNavbar(props: Props) {
  const patientUtils = useApiUtils().patients;

  const facilityGroupId = patientUtils.findFacilityGroup(props.patient);
  const closeLink = props.roundId
    ? `/facility-group/${facilityGroupId}/rounds/${props.roundId}`
    : `/facility-group/${facilityGroupId}`;
  return (
    <Container horizontal gap={2}>
      <LeftColumn {...props} />
      <RightColumn {...props} />
      <CloseLink to={closeLink}>
        <IconClose width="20px" />
      </CloseLink>
    </Container>
  );
}

function LeftColumn(props: Props) {
  const patientUtils = useApiUtils().patients;
  const facilityGroupId = patientUtils.findFacilityGroup(props.patient);
  const services = useSyncCenter();
  const facilityGroupsStore = useStore(services.facilityGroups.store).store;
  // facility group is the actual building, facilities are wings
  const facilityGroups = itiriri(facilityGroupsStore.values()).toArray();
  const facilityGroup = facilityGroups.find((fg) => fg.hsId === facilityGroupId);
  const facilitiesStore = useStore(services.facilities.store).store;

  const [open, setOpen] = React.useState(false);

  const facility = itiriri(facilitiesStore.values()).find((f) => f.hsId === props.patient.facility);

  const history = useHistory();

  return (
    <Layout gap={0.5}>
      <Layout align={'center'} horizontal gap={0.5}>
        <h2>{patientUtils.getDisplayPatientName(props.patient)}</h2>
        {props.patient.selfAdministered && <SelfAdministeredIcon />}
        {props.patient.suspended && <OffsiteIcon />}
      </Layout>

      <Heading>
        Room {props.patient.roomNumber}, {facility?.name} &bull; DOB:{' '}
        {props.patient.dateOfBirth && format(DateUtils.toOffsetlessDate(props.patient.dateOfBirth), 'dd MMM yyyy')}{' '}
        &bull; MPS ID: {props.patient.hsId} &bull;{' '}
        <Text weight={'200'} style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setOpen(true)}>
          SEE MORE
        </Text>
      </Heading>
      <br />
      <Grid cols={4} gap={0.5}>
        <Button fullWidth intent={Intent.Secondary} onClick={() => history.push('prns')}>
          ADMINISTER PRN
        </Button>
        <Button fullWidth intent={Intent.Secondary} onClick={() => history.push('nims')}>
          ADMINISTER NIM
        </Button>
      </Grid>
      <UpdateResidentDialog
        facilityGroup={facilityGroup!}
        isOpen={open}
        setClosed={() => setOpen(false)}
        patient={props.patient}
      />
    </Layout>
  );
}

function RightColumn(props: Props) {
  const groupedAllergies: Dictionary<HSPatientAllergy[]> = PatientLocalizations.assignDefaultToMissingAllergies(
    groupBy(props.patient.patientAllergies, (allergy) => allergy.type),
  );
  const [showScrollIndicator, setShowScrollIndicator] = React.useState(false);
  const [open, setOpen] = useState(false);
  const residentDetailUtils = useApiUtils().residentDetails;
  const specialConsiderationsText =
    residentDetailUtils.getSpecialConsiderationText(props.patient.considerations!, props.patient.swallowDifficulty!)!.length > 0
      ? residentDetailUtils.getSpecialConsiderationText(props.patient.considerations!, props.patient.swallowDifficulty!)
      : 'None';
  const primaryDiagnoses = props.patient.primaryDiagnoses!?.length > 0 ? props.patient.primaryDiagnoses?.map(n => n.name).sort().join(', ') : 'Nil Known';
  return (
    <RightColumnContainer
      className={'rightColumn'}
      onVisibilityChange={(isVisible) => setShowScrollIndicator(isVisible)}
      onClick={() => setOpen(true)}
    >
      {Object.entries(groupedAllergies).map(([type, allergies]) => (
        <AllergyLayout key={type}>
          <Heading style={{ display: 'inline-block', marginRight: '0.5em' }}>
            {`${PatientLocalizations.mapAllergyGroupType(type)} allergies:`}&nbsp;
          </Heading>
          {allergies.map((allergy, index) => (
            <AllergyListItem key={index}>
              <Layout horizontal align={'center'} gap={0.1}>
                {allergy.severity && <AllergyCircle color={AllergyUtils.getSeverityColor(allergy.severity)} />}
                <AllergyText isNotNil={allergy.name !== 'Nil Known'} key={index}>
                  {allergy.name}
                </AllergyText>
              </Layout>
            </AllergyListItem>
          ))}
        </AllergyLayout>
      ))}
      <OutlinedLayout>
        <Layout horizontal style={{ borderBottom: 'groove', borderWidth: '1px', padding: '0 0 7px 0' }}>
          <Heading>Primary diagnosis:&nbsp;</Heading>
          <Tooltip id="PrimaryDiagnosis" />
          <Text
              data-tooltip-html={primaryDiagnoses?.split(', ').join('<br />')}
              data-tooltip-id="PrimaryDiagnosis"
              data-tooltip-place="top"
              style={{textOverflow: `ellipsis`, overflow: `hidden`, whiteSpace: `nowrap`}}>{primaryDiagnoses}</Text>
        </Layout>
        <Layout horizontal style={{ borderBottom: 'groove', borderWidth: '1px', padding: '0 0 7px 0' }}>
          <Heading>Special instructions:&nbsp;</Heading>
          <Text>{props.patient.specialInstructions}</Text>
        </Layout>
        <Layout horizontal style={{ borderBottom: 'groove', borderWidth: '1px', padding: '0 0 7px 0' }}>
          <Heading>Special considerations:&nbsp;</Heading>
          <Text>{specialConsiderationsText}</Text>
        </Layout>
      </OutlinedLayout>
      <Layout horizontal gap={0.25}>
        <Circle color={AllergyUtils.getChartReviewDateColour(props.patient.enrmcValidTo)} />
        <Heading>Chart review:</Heading>
        <Text style={{ color: AllergyUtils.getChartReviewDateColour(props.patient.enrmcValidTo) }}>
          {props.patient.enrmcValidTo
            ? format(DateUtils.toOffsetlessDate(props.patient.enrmcValidTo), 'dd MMM yyyy')
            : 'Unknown'}
        </Text>
      </Layout>

      {showScrollIndicator && (
        <ScrollArrowContainer justify={'right'}>
          <SvgScrollArrow />
        </ScrollArrowContainer>
      )}
      <AllergyDialog secondary closeButtonColor={'black'} open={open} onRequestClose={() => setOpen(false)} size="md">
        <AllergyInformation
          onDismiss={() => setOpen(false)}
          groupedAllergies={groupedAllergies}
          specialInstructions={props.patient.specialInstructions}
          specialConsiderations={props.patient.considerations!}
          swallowDifficulty={props.patient.swallowDifficulty!}
          primaryDiagnoses={props.patient.primaryDiagnoses!}
        />
      </AllergyDialog>
    </RightColumnContainer>
  );
}

const Container = styled(Layout)`
  background-color: ${(p) => p.theme.appbar.bg};
  padding: 1em;
  grid-template-columns: 1fr 1fr;
  height: calc(100%-180px);
  overflow: hidden;
`;

const RightColumnContainer = styled(ScrollHandler)`
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 180px;
  cursor: pointer;
  overflow: auto;
`;

const OutlinedLayout = styled(Layout)<{
  showBottomBorder?: boolean;
}>`
  border-top: groove;
  border-bottom: groove;
  border-width: 1px;
  padding: 7px 0 7px 0;
`;

const Heading = styled.h4`
  font-weight: bold;
`;

const CloseLink = styled(Link)`
  padding: 1em;
  position: relative;
`;

const AllergyLayout = styled.div`
  width: 100%;
`;

const AllergyText = styled(Text)<{
  isNotNil?: boolean;
}>`
  margin-right: 0.5em;
  ${(props) => {
    if (props.isNotNil) {
      return css`
        color: red;
      `;
    }
  }}
`;

const Circle = styled.div<{ color: string }>`
  height: 20px;
  width: 20px;
  border-radius: 10000000000px;
  background-color: ${(props) => props.color};
`;

const AllergyCircle = styled.div<{ color: string }>`
  height: 10px;
  width: 10px;
  border-radius: 10000000000px;
  background-color: ${(props) => props.color};
`;

const AllergyListItem = styled.div`
  display: inline-block;
`;

const AllergyDialog = styled(Dialog)`
  background-color: white;
`;

const ScrollArrowContainer = styled(Layout)`
  position: sticky;
  bottom: 0;
`;
