/* eslint-disable no-console */
import {loggerDispatch} from "../../context/AppStateProvider";
import * as util from "util";
export class Logger {
  constructor(private name: string) {}

  private addMessage(message: string, level: string, params: unknown[]) {
    if (loggerDispatch) {
      loggerDispatch({
        level: level,
        message: util.format(message, ...params),
        module: this.name,
        type: "Append"
      })
    }
  }

  info(message: string, ...params: unknown[]) {
    this.addMessage(message, "Info", params);
    console.info(this.prefix(message), ...params);
  }

  error(message: string, ...params: unknown[]) {
    this.addMessage(message, "Error", params);
    console.error(this.prefix(message), ...params);
  }

  warn(message: string, ...params: unknown[]) {
    this.addMessage(message, "Warning", params);
    console.warn(this.prefix(message), ...params);
  }

  exception(message: string, ...params: unknown[]) {
    this.addMessage(message, "Exception", params);
    console.error(this.prefix(message), ...params);
  }

  private prefix(message: string) {
    return `${new Date().toLocaleString()}: ${this.name}: ${message}`;
  }
}
