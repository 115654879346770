export type OrderCommentCancel = {
  id: string;
  cancelBySubjectId: string;
  cancelledAt: Date | string;
};

export type OrderUpdate = {
  isActive?: boolean;
  updatedBySubjectId?: string;
  pendingComments?: OrderComment[];
  commentsCancelled?: OrderCommentCancel[];
};
export enum OrderState {
  Pending = 'Pending',
  OnOrder = 'On Order',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
}

export type OrderComment = {
  id: string;
  hsId: number;
  isActive: boolean;
  comment: string;
  commentType: string;
  commentByName: string;
  commentBySubjectId: string;
  commentDate: Date | string;
};

export type Order = {
  id: string;
  hsId: number;
  changeNumber: number;
  isActive: boolean;
  hsFacilityId: number;
  hsPatientId: number;
  hsMedicationId: number;
  hsDrugCode?: string;
  nextDailyDelivery: boolean;
  orderedAt: Date | string;
  orderedByName: string;
  orderedBySubjectId?: string;
  dispatchedAt?: Date | string;
  comments: OrderComment[];
  update: OrderUpdate;
};

export function GetOrderState(order: Order): OrderState {
  if (!order.isActive || !(order.update.isActive ?? true)) {
    return OrderState.Cancelled;
  }

  if (order.dispatchedAt) {
    return OrderState.Completed;
  }

  if (order.hsId === 0) {
    return OrderState.Pending;
  }

  return OrderState.OnOrder;
}

export function GetOrderComments(order: Order): OrderComment[] {
  const allComments = order.comments;

  for (const delComment of order.update.commentsCancelled ?? []) {
    for (const comment of allComments) {
      if (delComment.id === comment.id) {
        comment.isActive = false;
      }
    }
  }

  return allComments;
}

export interface IOrderService {
  createMedicationOrder(
    nextDailyDelivery: boolean,
    hsOrderedBySubjectId: string,
    hsPatientId: number,
    hsMedicationId: number,
    hsDrugCode?: string,
    notes?: string,
    userName?: string,
  ): Promise<string>;
  cancelOrder(orderId: string, hsCancelBySubjectId: string, notes?: string): Promise<void>;
  createOrderComment(orderId: string, hsCommentBySubjectId: string, comment: string, userName?: string): Promise<void>;
  cancelOrderComment(orderId: string, orderCommentId: string, hsCancelBySubjectId: string): Promise<void>;
}
