import React from 'react';
import { FieldError } from 'react-hook-form';
import styled from 'styled-components';
import { capitalize } from '../Text';
import { FormFieldError } from './FormFieldError';

interface Props {
  label?: string;
  error?: string | Error | FieldError;
  help?: string;
  fullWidth?: boolean;
  children: React.ReactNode;
  isRequired?: boolean;
}

export function FormGroup(props: Props) {
  return (
    <Container fullWidth={props.fullWidth}>
      <FormLabel>
        {props.label && <span>{capitalize(props.label)}{props.isRequired && <RequiredField>*</RequiredField>}</span>}
        {props.help && <HelpText>{capitalize(props.help)}</HelpText>}
      </FormLabel>
      <Child fullWidth={props.fullWidth}>{props.children}</Child>
      <FormFieldError error={props.error} />
    </Container>
  );
}

const Container = styled.div<{ fullWidth?: boolean }>`
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
`;

export const FormLabel = styled.label`
    color: currentColor;
    display: flex;
`;

const Child = styled.div<{ fullWidth?: boolean }>`
    margin-top: 3px;
    display: grid;
    grid-template: 1fr / repeat(${(props) => (props.fullWidth ? 1 : 2)}, minmax(0, 1fr));
    grid-template-rows: 1fr;
    grid-gap: 5px;
`;

const HelpText = styled.span`
    font-size: 10px;
`;

const RequiredField = styled.span`
  margin-left: 5px;
  color: orangered;
`;
