import * as React from 'react';

export function IconBack(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <ellipse cx="21" cy="21" rx="21" ry="21" transform="rotate(-180 21 21)" fill="white" />
      <path
        d="M10.2929 20.2929C9.90237 20.6834 9.90237 21.3166 10.2929 21.7071L16.6569 28.0711C17.0474 28.4616 17.6805 28.4616 18.0711 28.0711C18.4616 27.6805 18.4616 27.0474 18.0711 26.6569L12.4142 21L18.0711 15.3431C18.4616 14.9526 18.4616 14.3195 18.0711 13.9289C17.6805 13.5384 17.0474 13.5384 16.6569 13.9289L10.2929 20.2929ZM32 20L11 20L11 22L32 22L32 20Z"
        fill="black"
      />
    </svg>
  );
}
