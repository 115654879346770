import React from 'react';
import { HSDrug, PackType } from 'server-openapi';
import { Order } from '../../../../common/Order';
import { OrderNotesProps } from '../../../FacilityManagement/FacilityOrdersPage/Components/OrderNotesDialog';
import { OrderResupplyProps } from '../../../FacilityManagement/FacilityOrdersPage/Components/OrderResupplyDialog';
import { OrderRow } from '../../../FacilityManagement/FacilityOrdersPage/Components/OrderRow';
import { mapDrugToView } from '../../../FacilityManagement/FacilityOrdersPage/Components/OrderViewModel';
import { AdministrationWarnings } from '../../../../syncstream/utils/DrugUtils';

export interface MedicationOrderStatusBoxProps {
  facilityGroupId: number;
  hsPatientId?: number;
  hsMedicationId?: number;
  packType?: PackType;
  drug?: HSDrug;
  route?: string;
  directions?: string;
  administrationWarnings: AdministrationWarnings;
  openOrderResupply?: (orderResupply: OrderResupplyProps) => void;
  openOrderNotes?: (orderNotes: OrderNotesProps) => void;
  findRecentOrder?: (hsPatientId: number, hsDrugCode: string) => Order | undefined;
}

export function MedicationOrderStatusBox(props: MedicationOrderStatusBoxProps) {
  if (props.drug) {
    const vw = mapDrugToView(
      props.facilityGroupId,
      props.drug,
      props.route ?? '',
      props.directions ?? '',
      props.administrationWarnings,
      props.hsPatientId,
      props.openOrderResupply,
      props.openOrderNotes,
      props.findRecentOrder,
    );
    return <OrderRow includeDrug={false} hsMedicationId={props.hsMedicationId} {...vw}></OrderRow>;
  }

  return <></>;
}
