import itiriri from 'itiriri';
import _ from 'lodash';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Button } from '../../../kit/Button';
import { Dialog } from '../../../kit/Dialog';
import { Form } from '../../../kit/Forms/Form';
import { FormGroup } from '../../../kit/Forms/FormGroup';
import { Select } from '../../../kit/Forms/Select';
import { Text } from '../../../kit/Text';
import { useSyncCenter } from '../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../core/storage/hooks/UseStore';
import { PinInput } from '../../../components/PinComponents/PinInput';
import { MedicationInformationBox, MedicationInformationProps } from './medicationInformation/MedicationInformationBox';
import { sha512 } from 'js-sha512';
import { appconfig } from '../../../appconfig';
import {PinUserDto} from 'server-openapi';
import { toasts } from '../../../kit/Toasts/Toaster';
import { Layout } from '../../../kit/Layout';
import { useCurrentUser } from '../../../core/authn/UserProvider';
import {useGroupPermissions} from "../../../core/authz/PermissionsProvider";

interface FormFields {
  secondCheckUser: PinUserDto;
  pin: string;
}

interface Props extends MedicationInformationProps {
  onSubmit: (secondCheckUser: PinUserDto) => void;
}

export function SecondCheckDialog(props: Props) {
  const [open, setOpen] = useState(false);
  const form = useForm<FormFields>();
  const services = useSyncCenter();
  const currentUser = useCurrentUser();
  const groupPermissions = useGroupPermissions();
  const pinUsersStore = useStore(services.pinUsers.store).store;
  const secondCheckerUsers = itiriri(pinUsersStore.values()).filter(
    (u) => !!u.pinHash && !!u.subjectId && u.subjectId !== currentUser.profile.sub && (groupPermissions.canBeSecondChecker ?? false));

  function onSubmit(data: FormFields) {
    const hashedPin = sha512(data.pin.concat(appconfig.PINSALT));
    if (data.secondCheckUser.pinHash!.toUpperCase() !== hashedPin.toUpperCase()) {
      toasts.error('Incorrect Pin');
      return;
    }
    props.onSubmit(data.secondCheckUser);
    setOpen(false);
  }

  function openDialog() {
    if (!props.administrationPermissions.canAdministerSecondCheckMedication) {
      toasts.error('You do not have permission to use the second check.');
      return;
    }
    setOpen(true);
  }

  return (
    <>
      <ActionButton onClick={() => openDialog()}>SECOND CHECK</ActionButton>
      <Dialog lazy secondary open={open} onRequestClose={() => setOpen(false)}>
        <Form form={form} onSubmit={onSubmit}>
          <Text size="large" weight="bold">
            Confirm second check
          </Text>
          <MedicationInformationBox {...props} showPatientInfo />
          <FlexContainer>
            <FormGroup label="USER" fullWidth>
              <Select
                name="secondCheckUser"
                options={secondCheckerUsers.sort((a, b) => `${a.firstName} ${a.familyName}`.localeCompare(`${b.firstName} ${b.familyName}`))
                  .map((user) => ({ label: `${user.firstName} ${user.familyName}`, value: user }))
                  .toArray()}
                control={form.control}
                required
                fullWidth
              />
            </FormGroup>
            <FormGroup label="PIN" fullWidth>
              <PinInput name="pin" required />
            </FormGroup>
            <Layout align={'flex-end'} height={'100%'} style={{ width: '100%' }}>
              <Button style={{ height: '75%' }} type="submit" fullWidth>
                Submit
              </Button>
            </Layout>
          </FlexContainer>
        </Form>
      </Dialog>
    </>
  );
}

const FlexContainer = styled.div`
  display: flex;
  grid-gap: 1rem;
`;

const ActionButton = styled(Button)`
  min-width: 10rem;
`;

