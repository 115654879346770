import React, {useEffect, useState} from "react";
import styled from "styled-components";

interface BadgeProps {
  type?: string;
  text: string | number;
}

interface BadgeContainerProps {
  backgroundColour: string;
  textColour: string;
}

const Badge = ({type, text}: BadgeProps) => {
  const [bgColour, setBgColour] = useState('');
  const [textColour, setTextColour] = useState('');

  // add more colours if needed
  const setBadgeColour = () => {
    switch (type) {
      case 'info':
        setBgColour('#DADADA');
        setTextColour('#000000');
        break;
      case 'warning':
        setBgColour('#FFCA28');
        setTextColour('#000000');
        break;
      case 'default':
        setBgColour('#FFFFFF');
        setTextColour('#323232');
        break;
    }
  }

  useEffect(() => {
    setBadgeColour();
  }, [bgColour]);



  return (
      <BadgeContainer backgroundColour={bgColour} textColour={textColour} >
        {text}
      </BadgeContainer>
  )
}

const BadgeContainer = styled.div<BadgeContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.backgroundColour};
    padding: 5px 8px;
    margin: 0 5px;
    border-radius: 999em;
    color: ${(props) => props.textColour};
    font-weight: bold;
    font-size: 13px;
`

export default Badge;

