import * as React from 'react';

export function IconInsulinDrug(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="14.44" height="14.44" viewBox="8 11 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="16.8121" cy="19.5667" r="8.40851" fill="#03BEFC" />
      <mask
        id="mask0_7317_18804"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="16"
        y="8"
        width="13"
        height="13"
      >
        <rect x="16.8124" y="8.35547" width="11.2113" height="12.6128" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_7317_18804)">
        <circle cx="16.8121" cy="19.5667" r="8.40851" fill="#028CBA" />
      </g>
      <rect x="15.4159" y="22.4229" width="2.80284" height="2.80284" fill="white" />
      <rect x="15.4159" y="14.0146" width="2.80284" height="7.00709" fill="white" />
    </svg>
  );
}
