import React from 'react';
import styled, { css } from 'styled-components';
import { Loading } from './Loading';

interface TableProps extends React.HTMLAttributes<HTMLTableElement> {
  centerHeaders?: boolean;
  fullWidth?: boolean;
}

export const Table = (props: TableProps) => {
  return (
    <TableWrapper>
      <StyledTable {...props} />
    </TableWrapper>
  );
};

const StyledTableHead = styled.thead`
  th {
    border-width: ${(props) => props.theme.table.border.outer.width};
  }
`;

export const TableHead: React.FC = (props) => {
  return (
    <StyledTableHead>
      <tr>{props.children}</tr>
    </StyledTableHead>
  );
};

interface TableBodyProps {
  loading?: boolean;
  error?: string | Error;
  children?: React.ReactNode;
}

const StyledTableBody = styled.tbody`
  tr:last-child td {
    border-bottom-width: ${(props) => props.theme.table.border.outer.width};
    border-bottom-color: ${(props) => props.theme.table.border.outer.color};
  }

  td:first-child {
    border-left-width: ${(props) => props.theme.table.border.outer.width};
    border-left-color: ${(props) => props.theme.table.border.outer.color};
  }

  td:last-child {
    border-right-width: ${(props) => props.theme.table.border.outer.width};
    border-right-color: ${(props) => props.theme.table.border.outer.color};
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: ${(props) => props.theme.table.border.outer.radius};
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: ${(props) => props.theme.table.border.outer.radius};
  }
`;

export function TableBody(props: TableBodyProps) {
  const { loading, error, children } = props;
  return (
    <StyledTableBody>
      {React.Children.count(children) > 0 ? (
        <>
          {error || loading ? (
            <TableRow>
              <TableCell colSpan={999}>
                <Loading loading={loading ?? false} error={error} />
              </TableCell>
            </TableRow>
          ) : (
            children
          )}
        </>
      ) : (
        <TableRow>
          <TableCell colSpan={999}>No Items</TableCell>
        </TableRow>
      )}
    </StyledTableBody>
  );
}

export const TableRow = styled.tr``;

const cellcss = () => css`
  border-style: solid;
  border-width: 0; //calc(${(props) => props.theme.table.border.inner.width} / 2);
  border-top-width: calc(${(props) => props.theme.table.border.inner.width} / 2);
  border-color: ${(props) => props.theme.table.border.inner.color};
`;

export const TableColumn = styled.th`
  font-weight: bold;
  text-align: left;
  padding: ${(props) => props.theme.table.header.cell.padding};
  background: ${(props) => props.theme.table.header.bg};
  color: ${(props) => props.theme.table.header.fg};
  ${cellcss()}
`;

export const TableCell = styled.td`
  padding: ${(props) => props.theme.table.body.cell.padding};
  background: ${(props) => props.theme.table.body.bg};
  color: ${(props) => props.theme.table.body.fg};
  ${cellcss()}
`;

const StyledTable = styled.table<TableProps>`
  border-spacing: 0px;
  flex-grow: ${(props) => (props.fullWidth ? 1 : 0)};

  ${(props) =>
    props.centerHeaders &&
    css`
      ${TableColumn} {
        text-align: center;
      }
    `}
`;

const TableWrapper = styled.div`
  display: flex;

  a {
    color: currentColor;
  }
`;
