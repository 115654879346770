import _, { Dictionary } from 'lodash';
import React from 'react';
import { HSConsiderations, HSPatientAllergy, HSPrimaryDiagnosis } from 'server-openapi';
import styled, { css } from 'styled-components';
import { Button } from '../../../../kit/Button';
import { Grid } from '../../../../kit/Grid';
import { Layout } from '../../../../kit/Layout';
import { Text } from '../../../../kit/Text';
import { PatientLocalizations } from '../../../../localisation/PatientLocalizations';
import { AllergyUtils } from '../../../../syncstream/utils/AllergyUtils';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import { Tooltip } from 'react-tooltip';

interface Props {
  groupedAllergies: Dictionary<HSPatientAllergy[]>;
  specialInstructions: string | null | undefined;
  specialConsiderations: HSConsiderations;
  swallowDifficulty: boolean;
  primaryDiagnoses: HSPrimaryDiagnosis[];
  onDismiss?: () => void;
}

export function AllergyInformation(props: Props) {
  const { groupedAllergies, specialInstructions, specialConsiderations } = props;
  const residentDetailUtils = useApiUtils().residentDetails;
  const specialConsiderationsText =
    residentDetailUtils.getSpecialConsiderationText(specialConsiderations, props.swallowDifficulty).length > 0
      ? residentDetailUtils.getSpecialConsiderationText(specialConsiderations, props.swallowDifficulty)
      : 'None';
    const primaryDiagnoses = props.primaryDiagnoses!?.length > 0 ? props.primaryDiagnoses?.map(n => n.name).sort().join(', ') : 'Nil Known';
  return (
    <Layout gap={0.5}>
      {Object.entries(groupedAllergies).map(([type, allergies]) => (
        <AllergyLayout horizontal key={type}>
          <AllergyHeading>{PatientLocalizations.mapAllergyGroupType(type)} allergies</AllergyHeading>
          <AllergyDivider>
            {allergies.map((allergy, index) => (
              <div key={index}>
                <Grid cols={3}>
                  <AllergyText isNotNil={allergy.name !== 'Nil Known'}>{allergy.name}</AllergyText>
                  {allergy.severity ? (
                    <Grid cols={2} colsTemplate={'10px auto'} alignItemsCenter>
                      <AllergyCircle color={AllergyUtils.getSeverityColor(allergy.severity)} />
                      <Text>{allergy.severity ?? 'Unknown'} reaction</Text>
                    </Grid>
                  ) : (
                    <div></div>
                  )}

                  <Text>&nbsp;{allergy.reaction}</Text>
                </Grid>
                {index !== allergies.length - 1 && <AllergyItemDivider />}
              </div>
            ))}
          </AllergyDivider>
        </AllergyLayout>
      ))}
        <SpecialPropertiesText>Primary diagnosis&nbsp;</SpecialPropertiesText>
        <Tooltip id="PrimaryDiagnosis" />
        <PropertiesContainer
            data-tooltip-html={primaryDiagnoses?.split(', ').join('<br />')}
            data-tooltip-id="PrimaryDiagnosis"
            data-tooltip-place="top"
        >{primaryDiagnoses}</PropertiesContainer>
      <SpecialPropertiesText>Special instructions&nbsp;</SpecialPropertiesText>
      <PropertiesContainer>{specialInstructions ?? 'None'}</PropertiesContainer>
      <SpecialPropertiesText>Special considerations&nbsp;</SpecialPropertiesText>
      <PropertiesContainer>{specialConsiderationsText}</PropertiesContainer>


      <ButtonContainer>
        <Button fullWidth onClick={() => props.onDismiss?.()}>
          OK
        </Button>
      </ButtonContainer>
    </Layout>
  );
}

const AllergyLayout = styled(Layout)`
  display: inline;
`;

const AllergyText = styled(Text)<{
  isNotNil?: boolean;
}>`
  ${(props) => {
    if (props.isNotNil) {
      return css`
        color: red;
      `;
    }
  }}
`;

const AllergyCircle = styled.div<{ color: string }>`
  height: 10px;
  width: 10px;
  border-radius: 10000000000px;
  background-color: ${(props) => props.color};
`;

const PropertiesContainer = styled.div`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AllergyDivider = styled.div`
  border-top: groove;
  border-bottom: groove;
  border-width: 1px;
  padding: 5px 0 5px 0;
`;

const AllergyHeading = styled.h4`
  margin: 10px 0 10px 0;
  font-weight: bold;
`;

const SpecialPropertiesText = styled.h4`
  margin-top: 10px;
`;

//source - https://css-tricks.com/examples/hrs/
const AllergyItemDivider = styled.hr`
  border: 0;
  height: 0;
  border-top: 1px solid ${(props) => props.theme.table.border.inner.color};
  border-bottom: 1px solid ${(props) => props.theme.table.border.outer.color};
`;

const ButtonContainer = styled.div`
  display: grid;
  margin-top: 10px;
  justify-content: flex-end;
  grid-template-columns: 120px;
`;
