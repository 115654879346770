import * as React from 'react';
import { Tooltip } from 'react-tooltip';

export function IconNonCytotoxic(props: React.SVGProps<SVGSVGElement>) {
  return (
      <>
        <Tooltip id="IconNonCytotoxic" />
          <svg
            id="prefix__Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x={0}
            y={0}
            viewBox="0 0 19.1 16.5"
            xmlSpace="preserve"
            data-tooltip-content={props.xlinkTitle ?? "Non Cytotoxic"}
            data-tooltip-id="IconNonCytotoxic"
            data-tooltip-place="top"
            {...props}
          >
            <style>{'.prefix__stnoncyt{fill:#a032e1}'}</style>
            <path className="prefix__stnoncyt" d="M10.3 7.5H8.8l.4 3.6.7-.2.4-3.4zm-.1 5.5v-1.4H8.9V13h1.3z" />
            <path className="prefix__stnoncyt" d="M19.1 16.5H0L9.6 0l9.5 16.5zm-15.6-2h12.2L9.6 4 3.5 14.5z" />
          </svg>
      </>
  );
}
