/* eslint-disable @typescript-eslint/no-explicit-any */
import { DBSchema, IDBPDatabase, IDBPTransaction, openDB, deleteDB } from 'idb/with-async-ittr-cjs';
import { Entry, IStorage } from './Contract';
import lzString from "lz-string";
import _ from 'lodash';
import {encryptionVersion, setIndexedDbException, useAppState} from '../../context/AppStateProvider';
import {Logger} from "../logger/logger";


interface Schema<T> extends DBSchema {
  store: {
    key: string;
    value: T;
  };
}
const logger = new Logger('IndexedDBStore');

export class IndexedDBStorage<T> implements IStorage<T> {

  static async open<T = any>(name: string, idFn?: (obj: T) => string): Promise<IStorage<T>> {

    const db = await openDB<Schema<T|string>>(name, 1, {
      upgrade: (db:IDBPDatabase<Schema<any>>) => {
        db.createObjectStore('store');
      },
    });
    return new IndexedDBStorage<T>(db, idFn);
  }

  private constructor(private db: IDBPDatabase<Schema<T|string>>, public get_key: ((obj: T) => string) | undefined) {
  }

  private type = (function(global) {
    var cache: { [key: string]: string } = {};
    return function(obj: any) {
      var key;
      return obj === null ? 'null' // null
          : obj === global ? 'global' // window in browser or global in nodejs
              : (key = typeof obj) !== 'object' ? key // basic: string, boolean, number, undefined, function
                  : obj.nodeType ? 'object' // DOM element
                      : cache[key = ({}).toString.call(obj)] // cached. date, regexp, error, object, array, math
                      || (cache[key] = key.slice(8, -1).toLowerCase()); // get XXXX from [object XXXX], and cache it
    };
  }(this));


  smartDecompress(value: T|string, key: string) {

    if (this.type(value) === 'array') return value;

    try {
      let tryDecompress = JSON.parse(lzString.decompressFromUTF16(value as string | any | T));
      return tryDecompress;
    } catch (e)
    {
      return value;
    }
  }

  smartCompress(value: T, key: string, tx:  IDBPTransaction<Schema<T|string>, ["store"], "readwrite">, forceCompress: boolean = false) {

    if (!forceCompress)
    {
      tx.store.put(value, key);
      return;
    }

    if (this.type(value) === "object" || (key !== 'queue' && key !== ''))
    {
      const compressedValue = lzString.compressToUTF16(JSON.stringify(value)) as string;
      // @ts-ignore

      const decompressedValue = this.smartDecompress(value, key);
      if (_.isEqual(this.type(value), this.type(decompressedValue)))
      {
        tx.store.put(compressedValue, key);
      } else {
        tx.store.put(value, key);
      }

    } else {
      tx.store.put(value, key);
    }

  }


  async get(key: string) {
    const value = await this.readtx().store.get(key);
    return this.smartDecompress(value as T, key);
  }

  async set(key: string, value: T, forceCompress = false) {
    try {

      const tx = this.writetx();
      this.smartCompress(value, key, tx, forceCompress);
      await tx.done;
    }
    catch(e) {
      logger.error(`Error writing to indexed DB.  Error is ${e}`);
      setIndexedDbException(true);
      throw e;
    }
  }

  async setMany(entries: Entry<T>[], forceCompress  = false) {
    try {
      const tx = this.writetx();
      entries.forEach((entry) => {
        this.smartCompress(entry.value, entry.key, tx, forceCompress);
      });
      await tx.done;
    }
    catch(e) {
      logger.error(`Error writing to indexed DB.  Error is ${e}`);
      setIndexedDbException(true);
      throw e;
    }
  }

  async delete(key: string) {
    await this.deleteMany([key]);
  }
  async deleteMany(keys: string[]) {
    if (keys.length === 0) {
      return;
    }
    const tx = this.writetx();
    for(let key of keys) {
      await tx.store.delete(key);
    }
    await tx.done;
  }

  async has(key: string) {
    return (await this.readtx().store.count(key)) === 1;
  }

  async all() {
    const dataset = new Map<string, T>();
    const tx = this.readtx();
    for await (const cursor of tx.store.iterate()) {
      dataset.set(cursor.key, this.smartDecompress(cursor.value, cursor.key));
    }
    return dataset;
  }

  // Add a filter to filter the loaded data.  Typically this is a filter which filters on
  // facilityGroupId - i.e. only load the data for the facilityGroup I am currently logged in to.
  async some(loadFilter?: (obj: T) => Promise<boolean>) {
    const dataset = new Map<string, T>();
    const tx = this.readtx();
    for await (const cursor of tx.store.iterate()) {
      if (!loadFilter || await loadFilter(this.smartDecompress(cursor.value, cursor.key))) {
        dataset.set(cursor.key, this.smartDecompress(cursor.value, cursor.key));
      }
    }

    return dataset;
  }

  async iterate(fn: (entry: Entry<T>) => void) {
    const tx = this.readtx();
    for await (const cursor of tx.store.iterate()) {
      fn({
        key: cursor.key,
        value: this.smartDecompress(cursor.value as string | T | any, cursor.key),
      });
    }
  }

  async clear() {
    const tx = this.writetx();
    tx.store.clear();
    await tx.done;
  }

  private readtx() {
    return this.db.transaction('store', 'readonly');
  }

  private writetx() {
    return this.db.transaction('store', 'readwrite');
  }
}
