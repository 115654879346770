import itiriri from 'itiriri';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useCurrentUser } from '../../core/authn/UserProvider';
import { Button } from '../../kit/Button';
import { Dialog } from '../../kit/Dialog';
import { Layout } from '../../kit/Layout';
import { Text } from '../../kit/Text';
import { toasts } from '../../kit/Toasts/Toaster';
import { useSyncCenter } from '../../syncstream/SyncCenterProvider';
import { useStore } from '../../core/storage/hooks/UseStore';
import { useForm } from 'react-hook-form';
import { Form } from '../../kit/Forms/Form';
import styled from 'styled-components';
import IdleTimer from 'react-idle-timer';
import { useAppState } from '../../context/AppStateProvider';
import { sha512 } from 'js-sha512';
import { appconfig } from '../../appconfig';
import { logout } from '../../core/authn/Client';
import { useQueueDataChecker } from '../../syncstream/utils/hooks/useQueueDataChecker';
import { PinInput } from './PinInput';
import {Intent} from "../../kit/Theme/Theme";

export function LockScreenDialog() {
  const services = useSyncCenter();
  const appState = useAppState();
  const pinUsersStore = useStore(services.pinUsers.store).store;
  const currentUser = useCurrentUser();
  const hasQueuedData = useQueueDataChecker();

  const [locked, setLocked] = useState(false);

  const user = itiriri(pinUsersStore.values()).find((user) => user.subjectId === currentUser.profile.sub);

  function onSubmit(pin: string) {
    const hashedPin = sha512(pin.concat(appconfig.PINSALT));
    if (user!.pinHash!.toUpperCase() !== hashedPin.toUpperCase()) {
      toasts.error('Incorrect Pin');
      return;
    }
    appState.set({ isLocked: false });
  }

  // using appState because property needs to persist after reloading page
  useEffect(() => {
    setLocked(!!appState.state.isLocked);
  }, [appState.state.isLocked]);

  return (
    <>
      <IdleTimer
        timeout={1000 * 60 * 10}
        onIdle={ async () => {
          if (appState.state.isInitialSyncComplete) {
            if (!user?.pinHash) {
              await logout('UserInactive');
            }
            else {
              appState.set({ isLocked: true });
            }
          }
        }}
      />
      <Dialog size="full" hideCloseButton lazy secondary open={locked}>
        <Layout horizontal justify="right">
          <Button
            onClick={async () => {
              if (hasQueuedData) {
                window.alert('Please wait for sync up before logging out.');
              } else {
                await logout();
              }
            }}
          >
            Log out
          </Button>
        </Layout>
        <LockScreenDialogContent onSubmit={onSubmit} />
      </Dialog>
    </>
  );
}

interface FormFields {
  pin: string;
}

function LockScreenDialogContent(props: { onSubmit: (pin: string) => void }) {
  const form = useForm<FormFields>();

  async function onSubmit(data: FormFields) {
    if (data.pin.length !== 4) {
      return;
    }
    props.onSubmit(data.pin);
  }

  return (
    <StyledForm form={form} onSubmit={onSubmit}>
      <Layout gap={1} justify="center" align="center">
        <Text weight="bold">You have been inactive for 10 minutes. You will be logged out after 1 hour. </Text>
        <Text weight="bold">Please enter your four digit PIN to unlock.</Text>
        <Layout horizontal justify="center">
          <PinInput required name="pin" />
          <Button type="submit" style={{ height: '100%' }}>
            OK
          </Button>
        </Layout>
      </Layout>
    </StyledForm>
  );
}

const StyledForm = styled((props) => Form<FormFields>(props))`
  margin: auto;
`;
