import { CreateOrUpdatePinReq, FacilityGroupOnboardingReq, PatientMedicationUpdateRequest } from 'server-openapi';
import { apis } from './apis';

// The following services are only used by the online-only functions (e.g onboarding)

export const FacilityGroupOnlineService = {
  listAvailableFacilityGroups: async (changeNumber = -1) => {
    return (await apis.facilityGroups.facilityGroupsListFacilityGroupsAvailableForOnboarding(changeNumber)).data;
  },

  onboard: async (req: FacilityGroupOnboardingReq) => {
    return await apis.facilityGroups.facilityGroupsOnboardFacility(req);
  },
};

export const ReportingOnlineService = {
  getReportingEmbedInfo: async (facilityGroupId: number) => {
    return (await apis.reporting.reportingGetEmbedInfo(facilityGroupId)).data;
  },
};

export const PinOnlineService = {
  checkIfPinExistsForCurrentUser: async () => {
    return (await apis.users.userIfPinExists()).data;
  },
  upsertPin: async (req: CreateOrUpdatePinReq) => {
    return (await apis.users.userUpsertPin(req)).data;
  },
};

export const PatientMedicationService = {
  updateMedicationSelfAdministeredStatus: async (req: PatientMedicationUpdateRequest) => {
    return await apis.PatientMedicationApi.patientMedicationUpdateSelfAdministeredStatus(req);
  },
};
