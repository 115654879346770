/* eslint-disable sonarjs/cognitive-complexity */
import _ from 'lodash';
import {
  HandlingIndication,
  HSAdministeredAdHocDrug,
  HSAdministeredAdHocDrugComment,
  HSAdministeredAdHocDrugOutcome,
  HSAdministeredDrug,
  HSAdministeredDrugComment,
  HSAdministeredDrugOutcome,
  HSDrug,
  HSMedication,
  HSPackedMedication,
  HSPackedPrnMedication,
  HSPatient,
  MedicationStatus, MedicationType, PackType,
} from 'server-openapi';
import { useTheme } from 'styled-components';
import { useApiUtils } from '../utils/hooks/useApiUtils';
import { isWithinInterval, subDays } from 'date-fns';
import { DateUtils } from '../../core/utils/dateUtils';
import { PatchUtils } from '../../pages/ResidentDetails/components/patches/PatchUtils';

export interface DrugWarnings {
  reproductiveHazardous: boolean;
  cytotoxic: boolean;
  nonCytotoxic: boolean;
  controlledDrug: boolean;
  psychotropic: boolean;
}

// TODO: refactor into a more meaningful interface
export interface ICommentsOrOutcomesCompact {
  commentText: string;
  lastUpdatedAt: string;
  lastUpdatedSubjectId: string;
}

// TODO: refactor into a more meaningful interface
export interface IAdministeredDrug extends HSAdministeredDrug {
  doseRoundClinicalSystemId: string;
  comments: ICommentsOrOutcomesCompact[];
  outcomes: ICommentsOrOutcomesCompact[];
  // needed to fetch packed medication from administered drug
  doseTimestamp?: string | null; // identify packed medication using dose timestamp + medication ID
}

// TODO: refactor into a more meaningful interface
export interface IAdHocAdministeredDrug extends HSAdministeredAdHocDrug {
  doseRoundClinicalSystemId: string;
  comments: ICommentsOrOutcomesCompact[];
  outcomes: ICommentsOrOutcomesCompact[];
  doseTimestamp?: string | null;
  medicationId?: number;
}

export interface AdministrationWarnings {
  isCeasedMedication: boolean;
  medicationStatus?: string;
  drowsiness: boolean;
  inrTestRequired: boolean;
  bglTestRequired: boolean;
  allergyAlert: boolean;
  crushable: boolean;
  isSelfAdministered: boolean;
  isPrn: boolean;
  isRegular: boolean;
  hasRelatedPrnRegular: boolean;
}

export interface PreviousDosedDrugs {
  mostRecentDosedDrugs: HSAdministeredDrug[];
  packedMedication: HSPackedMedication;
}
export interface MedicationInformation {
  packed: boolean;
  interim: boolean;
  syringeDriver: boolean;
  injectable: boolean;
  controlled: boolean;
  schedule4: boolean;
  shortCourse: boolean;
  insulin: boolean;
  warfarin: boolean;
  prn :boolean;
  timeCritical: boolean;
  textInstruction: boolean;
  patch: boolean;
}

export const DrugUtils = {
   getMedicationInformation: (packedMedication: HSPackedMedication | HSPackedPrnMedication | undefined, drug?: HSDrug): MedicationInformation => {
    if (!packedMedication || !drug) {
      return {
        controlled: false,
        injectable: false,
        insulin: false,
        interim: false,
        prn: false,
        packed: false,
        schedule4: false,
        shortCourse: false,
        syringeDriver: false,
        warfarin: false,
        timeCritical: false,
        textInstruction: false,
        patch: false
      };
    }
     const pm = packedMedication as HSPackedMedication;
     return {
      packed:  (packedMedication.packType === PackType.Packette || packedMedication.packType === PackType.Blister),
      interim: (packedMedication.interim ?? false),
      syringeDriver: packedMedication.route?.code === 'SID' ?? false,
      injectable: DrugUtils.isInjection(drug, packedMedication.route?.code),
      controlled: DrugUtils.getDrugWarnings(drug).controlledDrug,
      schedule4: drug.schedule?.startsWith('4') ?? false,
      shortCourse: packedMedication.medicationType === MedicationType.ShortCourse,
      insulin: drug.isInsulin ?? false,
      textInstruction: drug.isTextInstruction ?? false,

      // The server won't mark text instructions as warfarin so need to add an additional test here as the text instructions for warfarin are permissioned like warfarin itself.
      warfarin: (drug.isVariableDose || drug.genericName?.toLocaleLowerCase().includes("warfarin")) ?? false,
      prn: packedMedication.medicationType === MedicationType.Prn,
      timeCritical: pm?.timeCritical ?? false,
      patch: PatchUtils.isPatch(drug)
    };
  },


  getTextColour: (drugWarnings: DrugWarnings) => {
    const theme = useTheme();
    switch (true) {
      case drugWarnings.controlledDrug:
        return theme.medicineColor.controlledDrug;
      case drugWarnings.cytotoxic:
        return theme.medicineColor.cytotoxic;
      case drugWarnings.nonCytotoxic:
        return theme.medicineColor.nonCytotoxic;
      case drugWarnings.psychotropic:
        return theme.medicineColor.psychotropic;
      case drugWarnings.reproductiveHazardous:
        return theme.medicineColor.reproductiveHazardous;
      default:
        return undefined;
    }
  },
  getDrugWarnings: (drug: HSDrug): DrugWarnings => {
    return {
      reproductiveHazardous: drug?.reproductiveHazardousDrug ?? false,
      cytotoxic: drug?.handlingIndication === HandlingIndication.Cytotoxic,
      nonCytotoxic: drug?.handlingIndication === HandlingIndication.HandleWithCare,
      controlledDrug: drug?.schedule?.startsWith('8') ?? false,
      psychotropic: drug?.psychotropicDrug ?? false,
    };
  },

  getMedStartDate(medication?: HSPackedMedication | HSPackedPrnMedication | HSMedication) {
    if (!medication) {
      return undefined;
    }

    if ('startDate' in medication) {
      return medication.startDate ?? undefined;
    }

    if ('medicationStartDate' in medication) {
      return medication.medicationStartDate ?? undefined;
    }

    return undefined;
  },

  getMedInterimDeleted(medication?: HSPackedMedication | HSPackedPrnMedication | HSMedication) {
    if (!medication) {
      return undefined;
    }

    if ('interimDeleted' in medication) {
      return medication.interimDeleted ?? false;
    }

    return medication.medicationStatus === MedicationStatus.Deleted;
  },

  getMedLastUpdated(medication?: HSPackedMedication | HSPackedPrnMedication | HSMedication) {
    if (!medication) {
      return undefined;
    }

    //always check HSMedication object first
    if ('lastModifiedAt' in medication) {
      return (medication as HSMedication).lastModifiedAt;
    }

    //fallback in case HSMedication does not exist
    if ('lastUpdated' in medication) {
      return medication.lastUpdated;
    }

    return undefined;
  },

  getMedicationStatus: (hsMedication?: HSMedication, packedMedication?: HSPackedMedication | HSPackedPrnMedication) => {
    //ideally check HSMedication object for medication start and end
    //if not available for some reason then use PackedMedication
    const medStartDate = DrugUtils.getMedStartDate(hsMedication ?? packedMedication);
    const medChangeDate = DrugUtils.getMedLastUpdated(hsMedication ?? packedMedication);
    const currentTime = Date.now();
    const isNew =
      packedMedication?.medicationStatus !== MedicationStatus.Deleted &&
      medStartDate &&
      isWithinInterval(DateUtils.toDate(medStartDate!), { start: subDays(currentTime, 1), end: currentTime })
        ? true
        : false;
    const isChanged =
      medChangeDate &&
      isWithinInterval(DateUtils.toDate(medChangeDate!), { start: subDays(currentTime, 1), end: currentTime })
        ? true
        : false;
    //specifically use packed medication to check if medication has ceased
    const isCeasedMedication = DrugUtils.getMedInterimDeleted(packedMedication) ?? false;

    return { isNew, isChanged, isCeasedMedication };
  },
  getAdministrationWarnings: (
    drug?: HSDrug,
    packedMedication?: HSPackedMedication | HSPackedPrnMedication,
    hsMedication?: HSMedication,
    isSelfAdministered?: boolean,
  ): AdministrationWarnings => {
    const medStatus = DrugUtils.getMedicationStatus(hsMedication, packedMedication);
    const warningLabels = drug?.warningLabels?.split(' ') ?? [];
    return {
      medicationStatus: medStatus.isNew ? 'New Drug' : medStatus.isChanged ? 'Changed' : undefined,
      isCeasedMedication: medStatus.isCeasedMedication,
      drowsiness: warningLabels.includes('1') || warningLabels.includes('1A') || warningLabels.includes('1B'),
      inrTestRequired: drug?.genericName?.includes('Warfarin') ?? false,
      bglTestRequired: drug?.genericName?.includes('Insulin') ?? false,
      allergyAlert: packedMedication?.hasAllergyAlert ?? false,
      crushable: !(!drug?.crushable && drug?.isDrugFormCrushable && (packedMedication?.route?.code === 'PO' || packedMedication?.route?.code === undefined)) ?? false,
      isSelfAdministered: isSelfAdministered ?? false,
      isPrn: packedMedication?.medicationType === MedicationType.Prn ?? false,
      isRegular: packedMedication?.medicationType === MedicationType.Regular ?? false,
      hasRelatedPrnRegular: false
    };
  },
  drugHasNoWarning: (drug: HSDrug) => {
    const drugWithNoWarning: DrugWarnings = {
      reproductiveHazardous: false,
      cytotoxic: false,
      nonCytotoxic: false,
      controlledDrug: false,
      psychotropic: false,
    };
    return _.isEqual(DrugUtils.getDrugWarnings(drug), drugWithNoWarning);
  },

  extractCompactCommentInfo: (
    comment:
      | HSAdministeredAdHocDrugComment
      | HSAdministeredDrugComment
      | HSAdministeredAdHocDrugOutcome
      | HSAdministeredDrugOutcome,
  ): ICommentsOrOutcomesCompact => {
    return {
      commentText: comment.commentText!,
      lastUpdatedAt: comment.lastUpdatedAt!,
      lastUpdatedSubjectId: comment.lastUpdatedBySubjectId!,
    };
  },
  getDrugAdministrationHistoryForPatient: (
    drug: HSDrug,
    patient: HSPatient,
    isAdHoc: boolean,
  ): Array<IAdministeredDrug | IAdHocAdministeredDrug | undefined> => {
    const administeredDoses = useApiUtils().patients.getAdministeredDoses(patient.hsId!);

    return administeredDoses
      .map((dose) => {
        if (isAdHoc)
          return dose.administeredDose?.administeredAdHocDrugs
            ?.filter((ad) => ad.drugCode === drug.drugCode)
            .map((ad) => {
              return {
                ...ad,
                doseRoundClinicalSystemId: dose.doseRoundClinicalSystemId,
                doseTimestamp: dose.administeredDose.doseTimestamp,
                comments:
                  ad.administeredAdHocDrugComments?.map((comment) => DrugUtils.extractCompactCommentInfo(comment)) ??
                  [],
                outcomes:
                  ad.administeredAdHocDrugOutcomes?.map((outcome) => DrugUtils.extractCompactCommentInfo(outcome)) ??
                  [],
              };
            });

        return dose.administeredDose?.administeredDrugs
          ?.filter((ad) => ad.drugCode === drug.drugCode)
          .map((ad) => {
            return {
              ...ad,
              doseRoundClinicalSystemId: dose.doseRoundClinicalSystemId,
              // dose timestamp is null for prns
              doseTimestamp: dose.administeredDose.doseTimestamp,
              comments:
                ad.administeredDrugComments?.map((comment) => DrugUtils.extractCompactCommentInfo(comment)) ?? [],
              outcomes:
                ad.administeredDrugOutcomes?.map((outcome) => DrugUtils.extractCompactCommentInfo(outcome)) ?? [],
            };
          });
      })
      .flat();
  },
  getActivePreviousDoses: (
    currentDosedDrug?: HSAdministeredDrug,
    previousDosedDrugs?: PreviousDosedDrugs[],
    packedMedicationId?: number,
  ) => {
    const previousDoses = previousDosedDrugs?.find(
      (drugs) => drugs.packedMedication.hsId === packedMedicationId,
    )?.mostRecentDosedDrugs;

    const activeDose = currentDosedDrug ?? previousDoses?.find((drug) => drug.active);

    return { activeDose, previousDoses };
  },
  allowSelfAdminister: (
    packedMedication: HSPackedMedication | HSPackedPrnMedication | undefined,
    drug: HSDrug,
    isUnscheduled: boolean | undefined,
  ) => {
    if (!packedMedication || packedMedication.route?.code === 'SID' || PatchUtils.isPatch(drug) || isUnscheduled) {
      return false;
    }
    return true;
  },
  isInjection: (drug: HSDrug, routeCode: string | null | undefined): boolean => {
    return drug?.formAbbreviation === 'INJ' || drug?.formAbbreviation === 'AUTO-INJ' || drug?.formAbbreviation === 'AMP' ||
      routeCode === 'IMI' || routeCode === 'IM/V' || routeCode == 'IVI' || routeCode == 'IVT' || routeCode == 'S/IM' || routeCode == 'SCCI' || routeCode == 'SID' || routeCode == 'SC';
  },
};
