import { User } from 'oidc-client';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { createServiceProvider } from '../../context/AppServiceProvider';
import { usermanager } from './Client';
import { LoginPage } from './LoginPage';
import jwt_decode, { JwtPayload } from 'jwt-decode';

const [ServiceProvider, useHook] = createServiceProvider<User>();

interface Props {
  children?: React.ReactNode;
  onUserLoaded?: (user: User) => void;
  onUserUnloaded?: () => void;
}

export function UserProvider(props: Props) {
  const [user, setUser] = useState<User | undefined>();

  // Baked access token for Cypress testing
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');

  useEffect(() => {
    const userLoaded = (user: User) => {
      setUser(user);
      props.onUserLoaded && props.onUserLoaded(user);
    };
    const userUnloaded = () => {
      setUser(undefined);
      props.onUserUnloaded && props.onUserUnloaded();
    };

    // Register event handlers
    usermanager.events.addUserLoaded(userLoaded);
    usermanager.events.addUserUnloaded(userUnloaded);

    // Load user from previous session
    usermanager.getUser().then((user) => {
      if (user) {
        usermanager.events.load(user);
      }
    });

    // Load baked access token from URL for cypress tests
    if (token) {
      // HACK: Decode the JWT ourselves so that we can set `user.profile.sub`
      // correctly. Could not find something in UserManager to do this.
      const decoded = jwt_decode<JwtPayload>(token);
      const user = { access_token: token, profile: { sub: decoded.sub }, expires_in: 9001 } as User; // it's over 9000
      usermanager.events.load(user);
    }

    // Return unregister handlers
    return () => {
      usermanager.events.removeUserLoaded(userLoaded);
      usermanager.events.removeUserUnloaded(userUnloaded);
    };
  }, []);

  if (!user || user.expired) {
    return <LoginPage />;
  }

  return <ServiceProvider value={user}>{props.children}</ServiceProvider>;
}

export function useCurrentUser() {
  return useHook();
}
