import React from 'react';
import { filter } from 'lodash-es';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Toast } from './Toast';
import { ToastContainer } from './ToastContainer';

interface ToasterRuntime {
  add?: (toast: Toast) => void;
  remove?: (toast: Toast) => void;
}

export const runtime: ToasterRuntime = {
  add: undefined,
  remove: undefined,
};

export function ToastProvider() {
  const [toasts, setToasts] = useState(new Array<Toast>());

  const add = (toast: Toast) => {
    setToasts((toasts) => [...toasts, toast]);
  };

  const remove = (toast: Toast) => {
    setToasts((toasts) => filter(toasts, (t) => t.id !== toast.id));
  };

  useEffect(() => {
    runtime.add = add;
    runtime.remove = remove;
  }, [setToasts]);

  return (
    <FixedContainer>
      <ToastContainer toasts={toasts} onClose={(toast) => remove(toast)} />
    </FixedContainer>
  );
}

const FixedContainer = styled.div`
  position: fixed;
  right: 1rem;
  top: 1rem;
  z-index: 99999;
`;
