import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { render } from 'react-dom';
import { appconfig } from './appconfig';
import { AppStateProvider } from './context/AppStateProvider';
import { Logger } from './core/logger/logger';
import { sentry, beforeCapture } from './core/logger/sentry';
import { startLiveReloadListener } from './core/utils/livereload';
import { PDFViewerProvider } from './kit/PDFViewer/PDFViewerProvider';
import { createTheme } from './kit/Theme/Theme';
import { ThemeProvider } from './kit/Theme/ThemeProvider';
import { Router } from './Router';
import  { Redirect } from 'react-router-dom'

startLiveReloadListener();

const logger = new Logger('index');
const theme = createTheme();

function Root() {
  return (
    <ErrorBoundary
      onError={(err) => {
        console.log('ErrorBoundary hit');
        logger.error('Hit error boundary', err);
      }}
      beforeCapture={beforeCapture}
      fallback={() =>
      { console.log('ErrorBoundary fallback hit');
        window.location.replace('/');
        return (
        <div>An error has occurred, please <a href='/'>refresh</a> the app or <a href='/'>click here if you wanted to go
          to the MediSphere home page</a>.</div>
      )
      }
    }
    >
      <AppStateProvider>
        <ThemeProvider theme={theme}>
          <PDFViewerProvider>
            <Router />
          </PDFViewerProvider>
        </ThemeProvider>
      </AppStateProvider>
    </ErrorBoundary>
  );
}

if (appconfig.SENTRY_ENABLED && appconfig.SENTRY_DSN) {
  sentry({
    version: appconfig.APP_VERSION,
    environment: appconfig.ENVIRONMENT,
    dsn: appconfig.SENTRY_DSN,
    build: appconfig.GIT_SHA,
  });
}

// eslint-disable-next-line no-console
// console.log(JSON.stringify(appconfig, null, '  '));

render(<Root />, document.getElementById('root'));
