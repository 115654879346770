/* eslint-disable max-lines */
/* eslint-disable sonarjs/cognitive-complexity */
import { format } from 'date-fns';
import itiriri from 'itiriri';
import React, { useRef, useCallback, useState } from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { useForm } from 'react-hook-form';
import { Configuration, CreateTestResultReq, GroupPermissionsRes, HSPatient } from 'server-openapi';
import styled from 'styled-components';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { Button } from '../../../../kit/Button';
import { Form } from '../../../../kit/Forms/Form';
import { Select } from '../../../../kit/Forms/Select';
import { TextArea } from '../../../../kit/Forms/TextArea';
import { Layout } from '../../../../kit/Layout';
import { Paper } from '../../../../kit/Paper';
import { Text } from '../../../../kit/Text';
import { toasts } from '../../../../kit/Toasts/Toaster';
import { useSyncCenter } from '../../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../../core/storage/hooks/UseStore';
import { SyncPatients } from '../../../../syncstream/SyncPatients';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import { PatientCompactInfo } from '../PatientCompactInfo';
import { PatientNoteDrugInfoCard } from '../../../FacilityManagement/NurseNotesPage';
import { RequirePermission } from '../../../../components/RequirePermission/RequirePermission';
import { Grid } from '../../../../kit/Grid';
import { useCurrentUser } from '../../../../core/authn/UserProvider';
import { User } from 'oidc-client';
import { ResidentDetailsUtils } from '../../../../syncstream/utils/ResidentDetailsUtils';
import { AdministeredDrugTests } from '../administeredDrugTests/AdministeredDrugTests';
import { Intent, colors, adminTheme } from '../../../../kit/Theme/Theme';
import { MedisphereTestResult } from '../../../../syncstream/SyncTestResults';
import { areTestResultsValidNew } from '../MedicineAdministerDialog';
import { TextInput } from '../../../../kit/Forms/TextInput';
import {useGroupPermissions} from "../../../../core/authz/PermissionsProvider";
import { fetchAttachment, uploadAttachment } from '../../../../core/mrs/apis';
import { Tooltip } from 'react-tooltip';
import { appconfig } from '../../../../appconfig';
import { getUser } from '../../../../core/authn/Client';
import { useHealthCheck } from '../../../../core/healthcheck/HealthCheckProvider';
import { pdfjs} from 'react-pdf';
import IconAttachment from '../../../../kit/Icons/Attachment';
import { IconClose } from '../../../../kit/Icons/Close';
import { PDFViewer } from '../../../../kit/PDFViewer/PDFViewer';
import { useAsync } from '../../../../kit/hooks/UseAsync';


export const getWorker = () => {
  try {
    return require('react-pdf/node_modules/pdfjs-dist/build/pdf.worker.js');
  } catch(e) {
    console.log('caught error when loading pdfjs.version. Loading CDN version:' + pdfjs.version);
    return require(`//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`);
  }
}

pdfjs.GlobalWorkerOptions.workerSrc = getWorker();

interface Props {
  groupPermissions: GroupPermissionsRes;
  patient: HSPatient;
}

//this function checks the extension of the file or URL passed to it and checks if it is a 'pdf'.
function isPDF( url : string | null) {
  return url ? url.split(/[#?]/)[0].split('.').pop()!.trim().toLowerCase() === "pdf" : false;
}

const config = new Configuration({
  basePath: appconfig.API_URL,
  apiKey: () => {
    return `Bearer ${getUser()?.access_token}`;
  },
});

const access_token = () => getUser()?.access_token;

type PdfZoomRenderProps = {
  attachmentReference: string | null;
  onUnzoomImage: () => void;
}

const PdfZoomRender = (props: PdfZoomRenderProps) => {
  const {attachmentReference, onUnzoomImage} = props;
  const [pageError, setPageError] = React.useState(true);

  const blobUrl = useAsync(async () => {
    if (!attachmentReference) {
      return null;
    }
    const blob = await fetchAttachment(attachmentReference);
    return URL.createObjectURL(blob);
  }, [attachmentReference]);

  if (!blobUrl) {
    return null;
  }

  if (!attachmentReference) {
    return null;
  }

  const handleLoadSuccess = () => {
    setPageError(false);
  };

  return (
      <ZoomImageDialog open style={{zIndex : 1}}>
        <header style={{padding : '0px', textAlign: 'center'}}>
          <h2>{attachmentReference!.split('/').pop()}</h2>
        </header>
        {blobUrl.value ?
            <PDFViewer pdfBlobUrl={blobUrl.value!} onLoadSuccess={handleLoadSuccess} onClick={onUnzoomImage} />
            :
            blobUrl.error ? blobUrl.error.message : 'Downloading PDF...'
        }
        <Layout align={'center'} padding={'1em'} justify={'center'} horizontal gap={1}>
          {!pageError ?
              <Button disabled={pageError}
                      onClick={() => downloadPdf(attachmentReference!)}
              >Download PDF</Button>
              : null }
          <Button onClick={() => onUnzoomImage()}>Close</Button>
        </Layout>
      </ZoomImageDialog>
  );
};

type ImgZoomRenderProps = {
  attachmentReference: string | null;
  onUnzoomImage: () => void;
}

const ImgZoomRender = (props: ImgZoomRenderProps) => {
  const {attachmentReference, onUnzoomImage} = props;

  if (!attachmentReference) {
    return null;
  }

    return (
      <ZoomImageDialog open style={{zIndex : 1, paddingTop : 0, resize: 'both', overflow: 'auto'}}>
        <header style={{padding : '0px', textAlign: 'center'}}>
          <h2>{attachmentReference!.split('/').pop()}</h2>
        </header>
        <img onClick={() => onUnzoomImage()} src={attachmentReference} loading="lazy" alt="Attachment" style={{width:'100%'}}/>
        <Button onClick={() => onUnzoomImage()}>Close</Button>
      </ZoomImageDialog>
  );
};


const Container = styled(Grid)`
  height: 100%;
  grid-template-rows: min-content 1fr;
`;


function PatientNotesContent(props: Props) {
  const services = useSyncCenter();
  const groupPermissions = useGroupPermissions();
  const residentDetailsUtils = useApiUtils().residentDetails;
  const userUtils = useApiUtils().users;
  const patientUtils = useApiUtils().patients;
  const facilitiesStore = useStore(services.facilities.store).store;
  const facility = itiriri(facilitiesStore.values()).find((f) => f.hsId === props.patient.facility);
  const [noteType, setNoteType] = React.useState<string>();
  const [zoomedAttachment, setZoomedAttachment] = useState<string | null>(null);
  const canViewDocuments = groupPermissions.canViewDocuments;

  const isServerHealthy = useHealthCheck().isHealthy;

  const handleZoomImage = (name : string) => {
    //console.log("Pausing syncCenter");
    //services.syncCenter.stop();
    setZoomedAttachment(name);
  }
  const handleUnzoomImage = useCallback(() => {
    //console.log("Starting syncCenter");
    //services.syncCenter.start(groupPermissions.canListPatients ?? false, patient.facility?.toString());
    setZoomedAttachment(null);
  }, [setZoomedAttachment]);


  const notes = residentDetailsUtils.getDisplayableNoteInfosForPatient(
        props.patient,
        patientUtils.findPackedPatientPrnMedications(props.patient.hsId!)?.packedPrnMedications ?? [],
        noteType,
    );


  return (
    <Layout gap={1} style={{overflowY: "auto"}}>
      <PatientCompactInfo patient={props.patient} facility={facility!} />
      <AddTestOrNoteCard patient={props.patient} facilityGroupId={facility!.facilityGroupId!} groupPermissions={groupPermissions}/>
      <Layout horizontal gap={1} align={'center'}>
        <Heading>Recent notes</Heading>
        <Select
          required
          options={notesFilterOptions}
          value={noteType}
          onChange={(_, value) => setNoteType(value)}
          name={'notes_filter'}
          placeholder="Type of Notes"
        />
      </Layout>
      {/*  {Boolean(zoomedAttachment) && <PdfZoomRender attachmentReference={zoomedAttachment} onUnzoomImage={handleUnzoomImage} />}  */}

      {notes.map((note, index) => {
        const attachmentURL = note.attachmentReference ? `${config.basePath}/facility-group/${facility?.facilityGroupId}/resident-details/${props.patient.hsId}/attachments/${note.attachmentReference}` : null;
        return (
        <div key={index}>
        <InformationBox horizontal key={index}>
          <InformationColumn>
            <Text weight={'bold'}>{DateUtils.dateTo24HourTimeString(note.date)}</Text>
            <Text>{format(note.date, 'dd MMM yyyy')}</Text>
          </InformationColumn>
          <InformationColumn>
            <Text>
              {!!note.updatedBySubjectId
                ? userUtils.getUserFullNameAndRoleFromSubjectId(note.updatedBySubjectId)
                : userUtils.getUserFullNameAndRoleFromUsername(note.updatedByLogin)}
            </Text>
          </InformationColumn>
          <InformationColumn style={{ flexBasis: '60%' }}>
            <Text weight={'bold'}>{note.title}</Text>
            <Text>{note.comment}</Text>
            <PatientNoteDrugInfoCard note={note} />
          </InformationColumn>
          <InformationColumn>
          {(attachmentURL && note.attachmentReference) ?
              canViewDocuments ?
                  <>
                    <Tooltip id="{note.attachmentReference}" />
                    {(isPDF(note.attachmentReference)) ?
                        <Thumbnail onClick={() => handleZoomImage(attachmentURL)} src="/assets/icons/PDF_file_icon.svg" width="50px" alt="PDF" data-tooltip-content="Click to view PDF"
                                   data-tooltip-id="{note.attachmentReference}" data-tooltip-place="left"/>
                        : <Thumbnail width="100" src={attachmentURL}
                               data-tooltip-content="Click to zoom"
                               data-tooltip-id="{note.attachmentReference}" data-tooltip-place="left"

                               onLoad={({ currentTarget }) => {
                                 //console.log("Image onLoad");
                                 if (!currentTarget.src.includes("brokenimagethumbnail")) {
                                   currentTarget.className = "zoom-in-cursor"
                                 }
                                 currentTarget.alt = "Error while loading image";
                                 currentTarget.onclick = () => {
                                   if (!currentTarget.src.includes("brokenimagethumbnail")) {
                                     handleZoomImage(attachmentURL)
                                   }
                                 };
                               }}
                               onError={({ currentTarget }) => {
                                 //console.log("Image onError");
                                 currentTarget.onerror = null; // prevents looping
                                 currentTarget.alt = "Error while loading image";
                                 currentTarget.onclick = (event) => {
                                   event.preventDefault();
                                   return false;
                                 };
                                 currentTarget.onload = (event) => {
                                   event.preventDefault();
                                   return false;
                                 }
                                 currentTarget.src = "/assets/icons/brokenimagethumbnail.png";
                                 currentTarget.dataset.tooltipContent = "Document could not be found or could not load.";
                               }}
                               loading="lazy" alt="Click to Zoom"/>}

                    {zoomedAttachment===attachmentURL ?
                        isPDF(attachmentURL) ? <PdfZoomRender attachmentReference={zoomedAttachment} onUnzoomImage={handleUnzoomImage}/>
                                : <ImgZoomRender attachmentReference={zoomedAttachment} onUnzoomImage={handleUnzoomImage}/> : null}
                  </>
              : <>
                <Tooltip id="cantViewDocuments" /><IconAttachment
                  data-tooltip-content="You don't have permission to view attachments"
                  data-tooltip-id="cantViewDocuments" data-tooltip-place="left" />
              </>
              : null
          }
          </InformationColumn>
        </InformationBox>
        </div>
      )})}
    </Layout>
  );
}

const Thumbnail = styled.img`
  cursor: zoom-in;
`;

const ZoomImageDialog = styled.dialog`
  
  //max-height:90%;
  z-index: 1;
  max-width:90%;
  object-fit: contain;
  display: block;
  cursor: zoom-out;

  -moz-box-shadow: 3px 3px 5px #535353;
  -webkit-box-shadow: 3px 3px 5px #535353;
  box-shadow: 3px 3px 5px #535353;
  //position: absolute;
  //min-height: 50%;
  //min-width: 50%;
  //position: fixed;
  text-align: -webkit-center;
  //top: 0dvmax;
  //left: 0%;
  //bottom: 0dvmax;
  //right: 0%;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  //border: 3px solid ${props => props.theme.dialog.secondary.bg};
  //background-color: ${props => props.theme.dialog.secondary.bg};
  //padding: 0px;
  //top: 50%;
  //left: 50%;
  -webkit-transform: translateX(0%) translateY(-50%);
  -moz-transform: translateX(0%) translateY(-50%);
  -ms-transform: translateX(0%) translateY(-50%);
  transform: translateX(0%) translateY(-50%);
`;

const downloadPdf = (path: string) => {

  axios.get(path,
      { responseType: 'blob',
               headers: { "Access-Control-Allow-Origin": "*",
                          "Authorization": "Bearer " + access_token()}
      })
       .then((response) => {
         const blobPDF = new Blob([response.data],
             {
               type: 'application/pdf',
             });
         const link = document.createElement("a");
         link.href = URL.createObjectURL(blobPDF);
         link.setAttribute("download", response.config.url?.split('/').pop() ?? 'downloadfile.pdf');
         document.body.appendChild(link);
         link.click();
       })
}

export function PatientNotes(props: Props) {
  const [zoomedAttachment, setZoomedAttachment] = useState<string|null>(null);
  const handleUnzoomImage = useCallback(() => setZoomedAttachment(null), [setZoomedAttachment]);

  const handleZoomedAttachment = (name: string) => {
    setZoomedAttachment(name);
  };

  return (
      <>
        <Grid cols={1} gap={1}>
          <RequirePermission hasPermission={props.groupPermissions.canViewResidentNotes}>
            <PatientNotesContent {...props}/>
          </RequirePermission>
        </Grid>
      </>
  );

}


interface FormFields {
  subject: string;
  comment: string;
  attachment: string;
  attachmentReference: string;
  //hack to display error if test result is invalid
  progressNoteTestResult?: string;
}

interface AddTestOrNoteProps extends Props {
  groupPermissions: GroupPermissionsRes;
  facilityGroupId: number;
}

function AddTestOrNoteCard(this: any, props: AddTestOrNoteProps) {
  const services = useSyncCenter();
  const testResultTypeStore = useStore(services.testResultTypes.store).store;
  const form = useForm<FormFields>({
    mode: 'onChange',
  });

  const canRecordNote = props.groupPermissions.canRecordNote;
  const canCreateTestResults = props.groupPermissions.canRecordVitals;
  const canUploadDocuments = props.groupPermissions.canUploadDocuments;
  const user = useCurrentUser();
  const residentDetailUtils = useApiUtils().residentDetails;
  const [progressNoteTestResults, setProgressNoteTestResults] = React.useState<MedisphereTestResult[]>([]);
  const [attachmentReference, setAttachmentReference] = React.useState<string>('');
  const [uploadingFile, setUploadingFile] = React.useState(false);
  const [fileInputKey, setFileInputKey] = React.useState('');
  const [uploadError, setUploadError] = React.useState<any>(false);
  const [uploadErrorMessage, setUploadErrorMessage] = React.useState<string>('Error uploading file ;-(');
  const [showAttachmentPreview, setShowAttachmentPreview] = React.useState<any>(false);
  const [attachmentPreviewURL, setAttachmentPreviewURL] = React.useState<string>('');
  const [attachmentFileName, setAttachmentFileName] = React.useState<string>('');
  const isServerHealthy = useHealthCheck().isHealthy;
  const uploadFileTypes = ".jpg,.jpeg,.png,.gif,.tiff,.bmp,.webp,.tif,.pdf";

  function setError(str: string){
    form.setError('progressNoteTestResult', {
      type: 'Test Results Invalid',
      message: str,
    });
  }

  React.useEffect(() => {
    form.clearErrors('progressNoteTestResult');
    if (!areTestResultsValidNew(progressNoteTestResults, testResultTypeStore, setError)) {
    }
  }, [progressNoteTestResults]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;

    if (!fileList || fileList.length === 0) {
      // No file attached
      setAttachmentReference('');
      setShowAttachmentPreview(false);
      return;
    }

    const file = fileList[0];

    if (!(file.type.includes('image/') || file.type.includes('/pdf'))) {
      // Not supported type
      setUploadErrorMessage('File type is unsupported.');
      setUploadError(true);
      setAttachmentReference('');
      setShowAttachmentPreview(false);
      return;
    }


    setAttachmentFileName(file.name);

    // should always be defined, keeps typescript happy
    if (!props.patient.hsId) {
      return;
    }

    setUploadingFile(true);
    setUploadError(false);
    uploadAttachment({
      patientId: props.patient.hsId,
      facilityGroupId: props.facilityGroupId,
      file: file
    }).then(output => {
      setAttachmentReference(attachmentReference => {return `${output}`});
      //console.log('output:' + `${output}`);
      setShowAttachmentPreview(true);
      var attachmentPreviewURL = `${config.basePath}/facility-group/${props.facilityGroupId}/resident-details/${props.patient.hsId}/attachments/${output}`;
      //console.log('attachmentPreviewURL:' + attachmentPreviewURL);
      setAttachmentPreviewURL(attachmentPreviewURL);
      setShowAttachmentPreview(true);
    }).catch(err => {
      console.log('uploadAttachment Error :' + err.toString());
      setUploadError(err);
      setShowAttachmentPreview(false);
      setUploadingFile(false);
      setAttachmentFileName('');
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      } else if (err.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser
        // and an instance of http.ClientRequest in node.js
        console.log(err.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', err.message);
      }
    }).finally(() => {
      setUploadingFile(false);
    });
  };

  async function onSubmit(data: FormFields) {
    if (data.comment && !canRecordNote) {
      toasts.error('You do not have permission to record notes');
      return;
    }

    data.attachmentReference = attachmentReference;

    //submit the test results
    for (const result of progressNoteTestResults) {
      const updatedTestResultObj = {
        ...result,
        patientId: props.patient.hsId,
        timeStamp: DateUtils.fromDate(new Date()),
        createdBySubjectId: user.profile.sub
      };
      const testResultReq: CreateTestResultReq = {
        testResult: updatedTestResultObj,
        facilityGroupId: props.facilityGroupId,
      };

      await services.testResults.service.enqueue.createTestResult({
        type: 'test-result-create',
        medisphereTestResult: updatedTestResultObj,
        request: testResultReq,
      });
    }

    const toFixedDown = (thenumber: string, digits: number) => {
      return parseFloat(thenumber).toFixed(digits);
    }

    const intext = (value: string, test: string) => {
      return value.toUpperCase().indexOf(test.toUpperCase()) >= 0
    }

    function formatTestResults(value: string, testtype: string) {
      if (intext(testtype, 'Pulse') || intext(testtype, 'Systolic') || intext(testtype, 'Diastolic') || intext(testtype, 'Pain'))
      {
        return value.substring(0, value.indexOf('.') != -1 ? value.indexOf('.') : value.length);  // remove everything after decimal place
      } else if (intext(testtype, 'mmol/L') || intext(testtype, 'INR') || intext(testtype, 'Degree'))
      {
        return toFixedDown(value, 1);
      } else if (intext(testtype, 'Weight')) {
        return toFixedDown(value, 2)
      }

      return value;
    }

    const testResultNoteComment = progressNoteTestResults.map((testResult): string => {
      const testResultType = testResult.hsTestResultTypeId
        ? testResultTypeStore.get(testResult.hsTestResultTypeId?.toString())
        : undefined;

      return `${testResultType?.description ?? ''} test result: ${testResult.testResultEntries?.map(
        (entry, index) =>
          ` ${formatTestResults(entry.value!,entry.key!)} (${entry.key}) ${index !== testResult.testResultEntries!.length - 1 ? ' ' : ''}`,
      )}`;
    });

    await addPatientProgressNote(
      props,
      data,
      services.patients.service,
      user,
      residentDetailUtils,
      testResultNoteComment.join('| '),
    );
    form.setValue('subject', null);
    form.setValue('comment', null);
    //form.formState.isValid = true;
    //form.clearErrors();
    setFileInputKey(Math.random().toString(36)); //https://stackoverflow.com/questions/69835186/how-to-clear-file-type-input-fields-file-name-in-react
    setShowAttachmentPreview(false);
    setUploadError(false);
    setAttachmentReference('');
    setAttachmentPreviewURL('');
    setProgressNoteTestResults([]);
    form.reset();
  }
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    const inputFile = hiddenFileInput.current;
    if (!inputFile) return;
    inputFile.click();
  };

  const handleRemoveAttachment = () => {
    setShowAttachmentPreview(false);
    setAttachmentReference('');
    setAttachmentPreviewURL('');
    if (hiddenFileInput.current) hiddenFileInput.current.value = '';
  };


  return (
    <Paper style={{ backgroundColor: 'rgba(255,255,255,.2)' }}>
      <Form form={form} onSubmit={onSubmit}>
        <Select required options={progressNoteOptions} name="subject" placeholder="Select a subject"/>
        <TextArea name="comment" required fullWidth/>
        <>
          <AdministeredDrugTests
            disabled={!canCreateTestResults}
            value={progressNoteTestResults}
            onUpdate={(r) => setProgressNoteTestResults(r)}
          />
          <HiddenTextInput name={'progressNoteTestResult'} />{' '}
        </>
        <Layout horizontal justify={'right'} gap={0.5} style={{justifyContent: "space-between"}} >
          <Layout horizontal gap={0.5} style={{display: 'inline-flex', alignItems: 'center'}}>
          <Tooltip id="add-attachment-tooltip" />
          <Button intent={Intent.Secondary}
                  onClick={handleClick}
                  disabled={!isServerHealthy || !canUploadDocuments}
                  data-tooltip-content={(!isServerHealthy) ? "Please go online to upload an attachment" : (!canUploadDocuments ? "You don't have permission to upload documents." : "Click to upload an attachment")}
                  data-tooltip-id="add-attachment-tooltip" data-tooltip-place="right">
            Add Attachment &nbsp; <IconAttachment /><input type="file" name="attachment" ref={hiddenFileInput} key={fileInputKey || '' } style={{display: 'none'}} onChange={handleFileChange} title="Attach an image to this progress note." accept={uploadFileTypes} capture="environment"/>
          </Button>
          <Loader
              visible={uploadingFile}
              height="20"
              width="20"
              color='#4F2676'
              type='Bars'
          /> <h3 hidden={!uploadingFile}>Uploading attachment...</h3><h3 hidden={!uploadError}>{uploadErrorMessage}</h3>
            {showAttachmentPreview ?
                  <>
                    <Tooltip id="remove-attachment-tooltip" />
                    <h3 hidden={!showAttachmentPreview}>{attachmentFileName}</h3><IconClose color={adminTheme.button.danger.bg} height="1em" width="1em" onClick={handleRemoveAttachment} data-tooltip-content="Remove attachment" data-tooltip-id="remove-attachment-tooltip" data-tooltip-place="right"/>
                  </>
          : null}
          </Layout>
          <Tooltip id="add-note-tooltip" />
          <Button intent={Intent.Primary} type="submit" disabled={!form.formState.isValid} data-tooltip-hidden={form.formState.isValid} data-tooltip-content="Please complete the subject and message of the note" data-tooltip-id="add-note-tooltip" data-tooltip-place="top" style={{width: "11rem"}} >
            SAVE
          </Button>
        </Layout>
      </Form>
    </Paper>
  );
}


async function addPatientProgressNote(
  props: AddTestOrNoteProps,
  data: FormFields,
  patientsService: SyncPatients,
  user: User,
  residentDetailUtils: ResidentDetailsUtils,
  testResultString: string,
) {
  await residentDetailUtils.addPatientProgressNote(
    props.patient,
    props.facilityGroupId,
    data.subject,
    testResultString.length > 0 ? `${data.comment} | ${testResultString}` : data.comment,
    data.attachmentReference,
    patientsService,
    user,
  );
  toasts.success('Note Added');
}

export const patientProfileChangeString = 'Patient Profile Changes';

const progressNoteOptions = [
  {
    label: 'Consent Form',
    value: 'Consent Form',
  },
  {
    label: 'Consent Form - Chemical Restraint',
    value: 'Consent Form - Chemical Restraint',
  },
  {
    label: 'Hospital Admission',
    value: 'Hospital Admission',
  },
  {
    label: 'Hospital Discharge Summary',
    value: 'Hospital Discharge Summary',
  },
  {
    label: 'Imprest Stock Use',
    value: 'Imprest Stock Use',
  },
  {
    label: 'Medication Review',
    value: 'Medication Review',
  },
    /*
  {
    label: 'Non-pharmacological intervention',
    value: 'Non-pharmacological intervention',
  },
    */
  {
    label: 'Nurse Initiated Medication Administration',
    value: 'Nurse Initiated Medication Administration',
  },
  {
    label: 'OTC Request',
    value: 'OTC Request',
  },
  {
    label: 'Pathology',
    value: 'Pathology',
  },
  {
    label: patientProfileChangeString,
    value: patientProfileChangeString,
  },
  {
    label: 'Prescription',
    value: 'Prescription',
  },
  {
    label: 'PRN Administration',
    value: 'PRN Administration',
  },
  {
    label: 'Referral Letter',
    value: 'Referral Letter',
  },
  {
    label: 'Risk Assessment',
    value: 'Risk Assessment',
  },
  {
    label: 'Self Administration of Medication Assessment Tool',
    value: 'Self Administration of Medication Assessment Tool',
  },
  {
    label: 'Social Leave',
    value: 'Social Leave',
  },
  {
    label: 'Specialist Notes',
    value: 'Specialist Notes',
  },
  {
    label: 'Wound Image',
    value: 'Wound Image',
  },
  {
    label: 'Other',
    value: 'Other',
  }
];

const notesFilterOptions = [
  {
    label: 'Drug Administration Notes',
    value: 'Drug Administration Notes',
  },
  {
    label: 'Patient Progress Notes',
    value: 'Patient Progress Notes',
  },
  {
    label: 'Patient Notes with Attachment',
    value: 'Patient Notes with Attachment',
  },
  {
    label: patientProfileChangeString,
    value: patientProfileChangeString,
  },
];

const HiddenTextInput = styled(TextInput)`
  height: 0;
  width: 0;
  overflow: hidden;
  position: absolute;
  display: none;
`;

const Heading = styled.h3`
  font-weight: bold;
`;

const InformationBox = styled(Layout)`
  border-radius: 2px;
  background: white;
  display: flex;
  align-items: center;
  padding: 0.875rem;
  width: 100%;
`;

const InformationColumn = styled(Layout)`
  flex-basis: 20%;
  padding-right: 15px;
`;
