import { cloneDeep } from 'lodash-es';
import { IStorage } from '../../core/storage/Contract';
import {HSFacility} from "server-openapi";
import itiriri from "itiriri";

interface HsObject {
  hsId?: number;
  clinicalSystemId?: string | null;
  changeNumber?: number | null;
}

export const SyncUtils = {
  async getChangeNumberForFacilities(
    facilityIds: number[] | string[],
    changeNumberStore: IStorage<number | undefined>,
  ): Promise<number> {
    const facilityChangeNumberLookups = facilityIds.map(async (facId) => {
      return (await changeNumberStore.get(facId!.toString())) ?? 0;
    });
    const facilityChangeNumbers = await Promise.all(facilityChangeNumberLookups);
    if (!facilityChangeNumbers || facilityChangeNumbers.length === 0) {
      return 0;
    }
    return Math.min(...facilityChangeNumbers);
  },

  async setChangeNumberForFacilities(
    facilityIds: string[] | number[],
    changeNumberStore: IStorage<number | undefined>,
    data: HsObject[],
  ) {
    if (!data || data.length === 0) {
      return;
    }
    const changeNumber = data[data.length - 1].changeNumber;
    if (!changeNumber) {
      return;
    }

    return await changeNumberStore.setMany(
      facilityIds.map((id) => ({
        key: id.toString(),
        value: changeNumber
      })),
    );
  },

  getLatestChangeNumber<T extends HsObject>(data: T[]) {
      if (data.length == 0) {
        return 0;
      }
      return data[data.length - 1].changeNumber ?? 0;
  },
  async getFacilitiesForGroup(
      facilityGroupId: string | undefined,
      facilitiesStore: IStorage<HSFacility>) {
    if (!facilityGroupId) {
      return [];
    }
    const facilities = await facilitiesStore.all();

    return itiriri(facilities.values())
        .filter((facility) => facility.facilityGroupId?.toString() === facilityGroupId)
        .toArray();
  },


  OverrideClinicalSystemId<T extends HsObject>(data: T[]) {
    const cloned = this.deepClone<T>(data);
    cloned.forEach((item) => {
      /* eslint-disable eqeqeq */
      if (item.clinicalSystemId == null) {
        item.clinicalSystemId = item.hsId?.toString();
      }
    });
    return cloned;
  },

  deepClone<T>(data: T[]) {
    const cloned: T[] = [];
    data.forEach((item) => cloned.push(cloneDeep(item)));
    return cloned;
  },
};
