import { format } from 'date-fns';
import React from 'react';
import { WarningDetailRes } from 'server-openapi';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { Button, Variation } from '../../../../kit/Button';
import { createDataSource, DataTable } from '../../../../kit/DataTable/DataTable';
import { Dialog } from '../../../../kit/Dialog';
import { AsyncResource } from '../../../../kit/hooks/UseAsync';
import { Layout } from '../../../../kit/Layout';
import { Text } from '../../../../kit/Text';
import { toasts } from '../../../../kit/Toasts/Toaster';
import { SyncStreamAPI } from '../../../../syncstream/api';
import { TableActionButton } from '../../FacilitySettings/FacilitySettingsPage';

interface IProps {
  warningDetailList: AsyncResource<WarningDetailRes[]>;
}

export function WarningDetailTable(props: IProps) {
  const warningDetailsApi = new SyncStreamAPI().warningDetails;

  const [selectedWarningDetails, setSelectedWarningDetails] = React.useState<WarningDetailRes>();

  const { warningDetailList } = props;

  function formatWarningDate(date: string) {
    return format(DateUtils.toDate(date), 'PPP HH:mm');
  }

  function onDelete(warningId: string) {
    warningDetailsApi.warningDetailsDeleteWarningDetail(warningId).then(() => {
      toasts.success('Warning record deleted');
      setSelectedWarningDetails(undefined);
      warningDetailList.refresh();
    });
  }

  const tableData =
    warningDetailList.value?.map((warningDetailRecord) => {
      return {
        col1: formatWarningDate(warningDetailRecord.startTime!),
        col2: formatWarningDate(warningDetailRecord.endTime!),
        col3: warningDetailRecord.message,
        col4: formatWarningDate(warningDetailRecord.createdAt!),
        col5: (
          <TableActionButton
            variation={Variation.text}
            onClick={() => {
              setSelectedWarningDetails(warningDetailRecord);
            }}
          >
            Delete
          </TableActionButton>
        ),
      };
    }) ?? [];

  const dataSource = createDataSource(tableData, () => [
    { accessor: (data) => data.col1, Header: 'Start Time', width: '120px' },
    { accessor: (data) => data.col2, Header: 'End Time', width: '120px' },
    { accessor: (data) => data.col3, Header: 'Message', width: '250px' },
    { accessor: (data) => data.col4, Header: 'Created At', width: '120px' },
    { accessor: (data) => data.col5, Header: 'Actions', width: '50px' },
  ]);

  return (
    <Layout gap={1}>
      <DataTable datasource={dataSource} filters={[]} />
      {selectedWarningDetails !== undefined && (
        <Dialog
          secondary
          size={'sm'}
          open={!!selectedWarningDetails}
          onRequestClose={() => setSelectedWarningDetails(undefined)}
        >
          <Layout padding={1} gap={1}>
            <Text weight={'bold'} size={'medium'}>
              Warning
            </Text>
            <Text>
              The message you are about to delete is set between{' '}
              <b>{formatWarningDate(selectedWarningDetails.startTime!)}</b> to{' '}
              <b>{formatWarningDate(selectedWarningDetails.endTime!)}</b>
            </Text>
            <Layout justify={'right'} horizontal gap={0.5} style={{ width: '100%' }}>
              <Layout style={{ width: '5em' }}>
                <Button fullWidth onClick={() => onDelete(selectedWarningDetails.id!)}>
                  Delete
                </Button>
              </Layout>
              <Layout style={{ width: '5em' }}>
                <Button fullWidth onClick={() => setSelectedWarningDetails(undefined)}>
                  Cancel
                </Button>
              </Layout>
            </Layout>
          </Layout>
        </Dialog>
      )}
    </Layout>
  );
}
