/* eslint-disable max-lines */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {NavigationBar} from '../../../components/NavigationBar/NavigationBar';
import {Select as FormSelect} from '../../../kit/Forms/Select';
import {Grid} from '../../../kit/Grid';
import {IoArrowBackCircle} from 'react-icons/io5';
import itiriri from 'itiriri';
import {useSyncCenter} from '../../../syncstream/SyncCenterProvider';
import {useStore} from '../../../core/storage/hooks/UseStore';
import {RoundLeftPanel} from './components/RoundLeftPanel';
import {RoundRightPanel} from './components/RoundRightPanel';
import {CreateRoundSidebar} from './components/CreateRoundSidebar';
import {useApiUtils} from '../../../syncstream/utils/hooks/useApiUtils';
import {FilterDrugTypeControlled, FilterDrugTypeOther} from '../../Dashboard/DashboardPage/DashboardPage';
import {HSDrug, HSFacility, HSPatient} from 'server-openapi';
import {PatientUtils} from '../../../syncstream/utils/PatientUtils';
import {DateUtils, Interval} from '../../../core/utils/dateUtils';
import {RoundScheduleItem} from '../../../syncstream/utils/RoundUtils';
import {DrugUtils} from '../../../syncstream/utils/DrugUtils';
import {useDashboardStore} from '../../Dashboard/DashboardContext';
import {SelectWards} from '../../Dashboard/DashboardPage/components/SelectWards';
import {useGroupPermissions} from '../../../core/authz/PermissionsProvider';
import Select, {components, MultiValue} from 'react-select';
import {NavigationBarMenu} from "../../../components/NavigationBar/NavigationBarMenu";
import {PatchUtils} from "../../ResidentDetails/components/patches/PatchUtils";

const LeftNav = styled(Grid)`
  display: flex;
  align-items: center;
`;

export interface IRoundCreationOptions {
  doseTimeOptions: IRoundCreationDoseTimeColumnOption[];
  patientOptions: IRoundCreationPatientOption[];
}

export interface IRoundCreationDoseTimeColumnOption {
  doseTimestamp: Date;
  isHidden: boolean;
  isSelected: boolean;

  // The isPartial flag is used to style the checkbox to provide a visual indication that, for example, even though it is checked,
  // not everything beneath it is checked.
  // i.e. if all are checked or all are unchecked then isPartial will be false.
  isPartial: boolean;
  idx: number;
}

export interface IRoundCreationScheduleItem {
  isTimeCritical: boolean;
  isControlled: boolean;
  isInsulin: boolean;
  roundScheduleItem: RoundScheduleItem;
  isPatch: boolean;
  isInjection: boolean;
}

export interface IRoundCreationPatientDoseTimeOption {
  doseTimestamp: Date;
  // 'n/a' - the patient does not have a dose at this time.
  // 'hidden' - the dose type does not match the selected filter criteria.
  // 'visible' - the dose time is visible and is able to be interacted with.
  displayState: 'n/a' | 'hidden' | 'visible';
  isSelected: boolean;
  idx: number;
  scheduleItems: IRoundCreationScheduleItem[];
}

export interface IRoundCreationPatientOption {
  hsPatient: HSPatient;
  patientName: string;
  wardName: string;
  wardId: number;
  doseTimestamps: IRoundCreationPatientDoseTimeOption[];
  isHidden: boolean;
  idx: number;
  }

// eslint-disable-next-line max-lines-per-function
export function CreateRoundPage(): JSX.Element {
    const apiUtils = useApiUtils();
    const facilityGroupUtils = apiUtils.facilityGroups;
    const groupPermissions = useGroupPermissions();

    // Retrieve data from store.
    const services = useSyncCenter();
    const facilitiesStore = useStore(services.facilities.store).store;
    const facilityGroupsStore = useStore(services.facilityGroups.store).store;
    const drugsStore = useStore(services.drugs.store).store;

    // Get the currently selected facility from the URL parameters.
    const { facilityGroupId } = useParams<IParams>();
    const history = useHistory();

    // This is the facility group that the user has selected on login.
    const selectedFacilityGroup = facilityGroupsStore.get(facilityGroupId)!;

    // Get all the facilities that are a member of this facility group.
    const facilities = itiriri(facilitiesStore.values())
        .toArray()
        .filter((f) => {
            return f.facilityGroupId === selectedFacilityGroup.hsId;
        });

    const [dashboardState, dashboardDispatch] = useDashboardStore();
    const [roundCreationOptions, setRoundCreationOptions] = useState<IRoundCreationOptions>({
        patientOptions: [],
        doseTimeOptions: [],
    });

    useEffect(() => {
        if (!facilityGroupId) {
            return;
        }
        const roundWindow = apiUtils.rounds.getRoundWindow(new Date(), parseInt(facilityGroupId));
        const roundDetails: RoundScheduleItem[] = apiUtils.rounds.getScheduleFromParameters(
            roundWindow,
            facilities!.map((x) => x!.hsId!),
        );
        initialiseOptions(drugsStore, facilitiesStore, apiUtils.patients, roundWindow, roundDetails);
    }, [facilityGroupId, drugsStore, facilitiesStore]);

    // Creates the view models based upon the list of current dashboard patients.
    // Is only updated whenever the dashboard patients change.
    // eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
    function initialiseOptions(
        drugStore: ReadonlyMap<string, HSDrug>,
        facilityStore: ReadonlyMap<string, HSFacility>,
        patientUtils: PatientUtils,
        doseRoundWindow: Interval,
        roundDetails: RoundScheduleItem[],
    ) {
        // Get the list of patients.
        const patientMap = new Map<number, IRoundCreationPatientOption>();
        for (const roundDetail of roundDetails) {
            if (!patientMap.has(roundDetail.patient.hsId!)) {
                patientMap.set(roundDetail.patient.hsId!, {
                    doseTimestamps: [],
                    idx: -1,
                    hsPatient: roundDetail.patient,
                    isHidden: false,
                    patientName: patientUtils.getDisplayPatientName(roundDetail.patient),
                    wardId: roundDetail.patient.facility!,
                    wardName: facilityStore.get(roundDetail.patient.facility!.toString())?.name ?? '',
                });
            }
            const patientInScope = patientMap.get(roundDetail.patient.hsId!);
            let dt = DateUtils.toOffsetlessDate(roundDetail.packedMedication.doseTimestamp!);
            if (roundDetail.scheduledActivity) {
                // This is a scheduled activity (e.g. a patch observation. In this case, the time is the time of the
                // observation, not the time when the patch was applied.
                dt = roundDetail.scheduledActivity.time;
            }

            // Add the dose time.
            let doseTime = patientInScope!.doseTimestamps.find((x) => DateUtils.compareDates(x.doseTimestamp, dt) === 0);
            if (!doseTime) {
                doseTime = {
                    doseTimestamp: dt,
                    idx: -1,
                    displayState: 'visible',
                    isSelected: true,
                    scheduleItems: [],
                };
                patientInScope!.doseTimestamps.push(doseTime);
            }
            const drug = drugStore.get(roundDetail.packedMedication.drugHsId!.toString());
            const routeCode = roundDetail.packedMedication.route?.code;
            doseTime.scheduleItems.push({
              roundScheduleItem: roundDetail,
              isControlled: DrugUtils.getDrugWarnings(drug!).controlledDrug,
              isTimeCritical: roundDetail.packedMedication?.timeCritical ?? false,
              isInsulin: drug?.isInsulin ?? false,
              isPatch: PatchUtils.isPatch(drug),
              isInjection: DrugUtils.isInjection(drug as HSDrug, routeCode as string),
            });
        }
        const patients = [...patientMap.values()].sort((a, b) => {
            const result = a.wardName.localeCompare(b.wardName);
            if (result !== 0) {
                return result;
            }
            return a.patientName.localeCompare(b.patientName);
        });

        // Get the master list of available dose times.
        const doseTimes = patients.flatMap((x) => x.doseTimestamps.map((y) => y.doseTimestamp));

        // Unique and sort them.
        const uniqueDoseTimes = DateUtils.sortDates(DateUtils.uniqueDates(doseTimes), true);
        const doseTimeSelections: IRoundCreationDoseTimeColumnOption[] = [];
        uniqueDoseTimes.forEach((doseTime, idx) => {
            doseTimeSelections.push({
                isSelected: true,
                doseTimestamp: doseTime,
                isPartial: false,
                isHidden: false,
                idx: idx,
            });
        });
        // Now create 1 row per patient.  Each patient contains a list of dose times.  The order and number of patient
        // dose times corresponds to the order and number of the headers.  So if the header contains the dose times 8:00, 9:00,
        // but the patient only has an 9:00, they will still contain 2 dose time entries, but the 8:00 entry
        // will be marked as hidden.

        patients.forEach((p, idx) => {
            // Update the index.
            p.idx = idx;

            const patientDoseTimes = p.doseTimestamps;
            p.doseTimestamps = [];
            // Create 1 entry per dose time.
            // Set 'hidden' to true if the patient doesn't contain this dose time.
            uniqueDoseTimes.forEach((doseTime, idx) => {
                let matchingDoseTime = patientDoseTimes.find((x) => DateUtils.compareDates(x.doseTimestamp, doseTime) === 0);
                matchingDoseTime ??= {
                    scheduleItems: [],
                    doseTimestamp: doseTime,
                    displayState: 'n/a', // This dose time does not apply for this patient.
                    isSelected: false,
                    idx: -1,
                };
                matchingDoseTime.idx = idx;
                p.doseTimestamps.push(matchingDoseTime);
            });
        });
        setRoundCreationOptions({
            doseTimeOptions: doseTimeSelections,
            patientOptions: patients,
        });
    }


    // eslint-disable-next-line sonarjs/cognitive-complexity
    useEffect(() => { ``
        setRoundCreationOptions((oldOptions) => {
            const newDoseTimes = [...oldOptions.doseTimeOptions];
            const newPatients = [...oldOptions.patientOptions];

            oldOptions.patientOptions.forEach((patient, idx) => {
                // Go through and update the state of the individual dose times.
                if (!dashboardState.filterDrugTypeControlled && !dashboardState.filterDrugTypeOther) {
                    // Not filtering. Show everything
                    for (const entry of newPatients[idx].doseTimestamps.filter((x) => x.displayState === 'hidden')) {
                        entry.displayState = 'visible';
                    }
                } else {
                    // We are only interested in the specific doses that match the search criteria.
                    for (const entry of newPatients[idx].doseTimestamps.filter((x) => x.displayState !== 'n/a')) {
                        const matchingScheduleItemsList = entry.scheduleItems.filter(x => apiUtils.rounds.isScheduleItemVisible(+facilityGroupId,
                            groupPermissions, drugsStore,apiUtils.residentDetails, x.roundScheduleItem, dashboardState.filterDrugTypeControlled, dashboardState.filterDrugTypeOther));
                        if (matchingScheduleItemsList.length > 0) {
                            entry.displayState = 'visible';
                        } else {
                            entry.displayState = 'hidden';
                        }
                    }
                }

                // Do they match one of the wards we care about?
                const facilityInScope = !dashboardState.filterWards || dashboardState.filterWards.includes(patient.wardId!.toString());
                const dosesInScope = newPatients[idx].doseTimestamps.some((x) => x.displayState === 'visible');

                newPatients[idx].isHidden = !facilityInScope || !dosesInScope;
            });

            // Update the state of each of the dose time headers
            newDoseTimes.forEach((doseTime, idx) => {
                updateHeaderInformation(newPatients, doseTime, idx);
            });
            return {
                patientOptions: newPatients,
                doseTimeOptions: newDoseTimes,
            };
        });
    }, [dashboardState]);

    function updateHeaderInformation(
        newPatients: IRoundCreationPatientOption[],
        newDoseTime: IRoundCreationDoseTimeColumnOption,
        idx: number,
    ) {
        // Update the header dose times to reflect what is visible.
        const patientsWithThisDoseTime = newPatients.filter(
            (x) => !x.isHidden && x.doseTimestamps[idx].displayState === 'visible',
        );
        // The dose time in the header is hidden if there are no patients in scope that have this dose time.
        newDoseTime.isHidden = patientsWithThisDoseTime.length === 0;
        if (!newDoseTime.isHidden) {
            const patientsWithThisDoseTimeChecked = patientsWithThisDoseTime.filter((x) => x.doseTimestamps[idx].isSelected);
            if (patientsWithThisDoseTimeChecked.length === 0) {
                // None checked
                newDoseTime.isPartial = false;
                newDoseTime.isSelected = false;
            } else if (patientsWithThisDoseTimeChecked.length === patientsWithThisDoseTime.length) {
                // All checked
                newDoseTime.isPartial = false;
                newDoseTime.isSelected = true;
            } else {
                newDoseTime.isPartial = true;
                newDoseTime.isSelected = true;
            }
        }
    }

    const handleSelectionChange = useCallback(
        (patientRowNumber: number, doseTimeColumnNumber: number, isSelected: boolean) => {
            const newDoseTimes = [...roundCreationOptions.doseTimeOptions];
            const newPatients = [...roundCreationOptions.patientOptions];
            if (!newPatients[patientRowNumber]?.doseTimestamps[doseTimeColumnNumber]) {
                // Ignore;
                return;
            }
            newPatients[patientRowNumber].doseTimestamps[doseTimeColumnNumber].isSelected = isSelected;
            updateHeaderInformation(newPatients, newDoseTimes[doseTimeColumnNumber], doseTimeColumnNumber);
            setRoundCreationOptions({
                doseTimeOptions: newDoseTimes,
                patientOptions: newPatients,
            });
        },
        [roundCreationOptions],
    );

    const handleColumnSelect = useCallback(
        (doseTimeColumnNumber: number, isSelected: boolean) => {
            const newDoseTimes = [...roundCreationOptions.doseTimeOptions];
            const newPatients = [...roundCreationOptions.patientOptions];

            newDoseTimes[doseTimeColumnNumber].isSelected = !newDoseTimes[doseTimeColumnNumber].isSelected;
            newDoseTimes[doseTimeColumnNumber].isPartial = false;
            newPatients.forEach((patient) => {
                if (!patient.isHidden && patient.doseTimestamps[doseTimeColumnNumber].displayState === 'visible') {
                    patient.doseTimestamps[doseTimeColumnNumber].isSelected = isSelected;
                }
            });
            setRoundCreationOptions({
                doseTimeOptions: newDoseTimes,
                patientOptions: newPatients,
            });
        },
        [roundCreationOptions],
    );

    const patientsWithControlledDrugs = useMemo<HSPatient[]>(() => {
        return roundCreationOptions.patientOptions
            .filter(
                (x) =>
                    !x.isHidden &&
                    x.doseTimestamps.some(
                        (y) => y.displayState === 'visible' && y.isSelected && y.scheduleItems.some((z) => z.isControlled),
                    ),
            )
            .map((x) => x.hsPatient);
    }, [roundCreationOptions]);

    // Get the wing label
    const wingLabel = useMemo(() => {
        if (!facilityGroupId) {
            return '';
        }
        return facilityGroupUtils.getFacilityUILabel(parseInt(facilityGroupId));
    }, [facilityGroupId]);

    // eslint-disable-next-line sonarjs/cognitive-complexity
    const selectedScheduleItems = useMemo<RoundScheduleItem[]>(() => {
        const selectedPatients = roundCreationOptions.patientOptions.filter((x) => !x.isHidden);
        const items = selectedPatients
            .flatMap((x) => x.doseTimestamps)
            .filter((y) => y.displayState === 'visible' && y.isSelected)
            .flatMap((z) => z.scheduleItems)
            .map(x => x.roundScheduleItem)
            .filter(x => apiUtils.rounds.isScheduleItemVisible(+facilityGroupId, groupPermissions, drugsStore, apiUtils.residentDetails, x, dashboardState.filterDrugTypeControlled, dashboardState.filterDrugTypeOther));
        return items;
    }, [roundCreationOptions,dashboardState.filterDrugTypeControlled, dashboardState.filterDrugTypeOther]);

    const controlledDrugsTypeOptions = [
        { label: 'Controlled', value: FilterDrugTypeControlled.Controlled },
        { label: 'Non-controlled', value: FilterDrugTypeControlled.NonControlled }
    ];

    if (!apiUtils.rounds.canAdministerEverything(groupPermissions))
    {
        controlledDrugsTypeOptions.push({ label: 'Administrable', value: FilterDrugTypeControlled.Administrable });
    }

    const medicationTypeOptions = [
        { value: FilterDrugTypeOther.TimeCritical, label: "Time Critical" },
        { value: FilterDrugTypeOther.Insulin, label: "Insulin" },
        { value: FilterDrugTypeOther.Patch, label: "Patch" },
        { value: FilterDrugTypeOther.Injection, label: "Injection" },
    ];

    const allOptions:any[] = [
        {
            label: "Medication Type",
            options: medicationTypeOptions,
            value: -1
        }
    ];

    const convertMultiValueOptionToEnum = (options: MultiValue<any>): FilterDrugTypeOther | undefined => {
        if (options.length === 0) {
            return undefined;
        }
        if (options.length === 1) {
            return options[0].value;
        }

        return options.map(x => x.value).reduce((a, b) => {
            return (a | b);
        });
    }

    interface IParams {
        facilityGroupId: string;
    }



  return (
    <div>
      <CreateRoundSidebar patients={patientsWithControlledDrugs} facilityGroupId={facilityGroupId} />
      <NavigationBar
          inverted
        nodes={() => ([
            <h1>
              <LeftNav gap={1} cols={2}>
                <IoArrowBackCircle onClick={() => history.push(`/facility-group/${facilityGroupId}`)} /> Create Round
              </LeftNav>
            </h1>,
            <>
                <Grid colsTemplate='1fr 1fr 1fr' gap={1} style={{ zIndex: 20, marginRight: "1em" }} >
                  <FormSelect
                      data-testid='select-drugs'
                      fullWidth
                      placeholder='All Medications'
                      name='control'
                      options={controlledDrugsTypeOptions}
                      onChange={(_, value) => {
                        dashboardDispatch({
                          type: 'SET_CONTROLLED_DRUG_TYPE_FILTER',
                          payload: value,
                        });
                      }}
                      value={dashboardState.filterDrugTypeControlled}
                  />
                  <Select
                      aria-label="Filter By"
                      isMulti={true}
                      isClearable={true}
                      escapeClearsValue={true}
                      name={`FilterDrugTypeOther`}
                      placeholder={`Filter by: `}
                      options={allOptions}
                      onChange={(option, actionMeta) => {
                          const optionEnum = convertMultiValueOptionToEnum(option);
                          dashboardDispatch({
                            type: 'SET_OTHER_DRUG_TYPE_FILTER',
                            payload: optionEnum,
                          });
                      }}
                      components={{
                        Option: (props) => {
                          return (
                              <div>
                                <components.Option {...props}>
                                  <input type='checkbox' checked={props.isSelected} onChange={() => null}/>{' '}
                                  <label>{props.label}</label>
                                </components.Option>
                              </div>
                          );
                        },
                      }}
                      defaultValue={medicationTypeOptions.filter(v =>
                        (dashboardState.filterDrugTypeOther ?
                          (v.value | FilterDrugTypeOther.TimeCritical) === dashboardState.filterDrugTypeOther ||
                          (v.value | FilterDrugTypeOther.Patch) === dashboardState.filterDrugTypeOther ||
                          (v.value | FilterDrugTypeOther.Injection) === dashboardState.filterDrugTypeOther ||
                          (v.value | FilterDrugTypeOther.Insulin) === dashboardState.filterDrugTypeOther : undefined))}
                      hideSelectedOptions={false}
                      closeMenuOnSelect={false}
                      styles={{
                        control: (provided) => ({ ...provided, width: '100%', height: '100%'}),
                        menu: provided =>({...provided})
                      }}
                  />
                  <SelectWards wards={facilities} selectedWardIds={dashboardState.filterWards} wingLabel={wingLabel}
                               selectionChanged={(facilityIds) => {
                                 dashboardDispatch({
                                   type: 'SET_WARD_FILTER',
                                   payload: facilityIds,
                                 });
                               }}
                  />
                </Grid>
                <NavigationBarMenu facilityGroup={selectedFacilityGroup}/>
            </>
        ])}
        facilityGroup={selectedFacilityGroup}
      />
      <div>
        <Grid cols={2} colsMobile={1} gap={2} colsTemplate={'80% 20%'}>
          <RoundLeftPanel
            patientOptions={roundCreationOptions.patientOptions}
            facilityGroupId={parseInt(facilityGroupId)}
            handleSelectionChange={handleSelectionChange}
            handleColumnSelectionChange={handleColumnSelect}
            wingLabel={wingLabel}
            doseTimeOptions={roundCreationOptions.doseTimeOptions}
          />
          <RoundRightPanel
            facilityIds={dashboardState?.filterWards?.map(x => +x) ?? facilities.map(x => x.hsId!) }
            facilityGroupId={facilityGroupId}
            numberOfPatients={roundCreationOptions.patientOptions.filter((x) => !x.isHidden).length}
            scheduleItems={selectedScheduleItems}
            drugTypeControlled={dashboardState?.filterDrugTypeControlled}
            drugTypeOther={dashboardState?.filterDrugTypeOther}
          />
        </Grid>
      </div>
    </div>
  );
}

