import styled, { css } from 'styled-components';

export enum ViewSize {
  Default,
  Medium,
  Large,
}

interface Props {
  size?: ViewSize;
}

export const LayoutContainer = styled.div<Props>`
  margin: 0 auto;
  padding: 1rem;

  ${(props) => {
    switch (props.size) {
      case ViewSize.Large:
        return css`
          max-width: 1540px;
          width: 100%;
        `;

      case ViewSize.Medium:
        return css`
          max-width: 1366px;
          width: 100%;
        `;

      case ViewSize.Default:
      default:
        return css`
          max-width: 986px;
          width: 100%;
        `;
    }
  }};
`;
