import React from 'react';
import styled, { useTheme } from 'styled-components';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { Layout } from '../../../../kit/Layout';
import { Text } from '../../../../kit/Text';
import { ICommentsOrOutcomesCompact } from '../../../../syncstream/utils/DrugUtils';
import { format } from 'date-fns';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';

interface IProps {
  outcome: ICommentsOrOutcomesCompact;
}

export function OutcomesCompact(props: IProps) {
  const theme = useTheme();
  const lastUpdatedAt = !!props.outcome.lastUpdatedAt ? DateUtils.toDate(props.outcome.lastUpdatedAt) : undefined;
  const dateText = lastUpdatedAt ? `${format(lastUpdatedAt, 'd MMM y')} at ${format(lastUpdatedAt, 'HH:mm')}` : '';
  const userUtils = useApiUtils().users;
  return (
    <Layout gap={0.5}>
      <OutcomesContainer gap={0.5} padding={'0.875rem'}>
        <Text
          style={{ color: theme.button.success.bg }}
          size={'small'}
          weight={'bold'}
        >{`OUTCOME on ${dateText} by ${userUtils.getUserFullNameAndRoleFromSubjectId(
          props.outcome.lastUpdatedSubjectId,
        )}`}</Text>
        <Text size={'small'} weight={'200'}>
          {props.outcome.commentText}
        </Text>
      </OutcomesContainer>
    </Layout>
  );
}

const OutcomesContainer = styled(Layout)`
  border-width: 1px;
  border-style: groove;
`;
