/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import itiriri from 'itiriri';
import {AxiosError} from 'axios';
import {User} from 'oidc-client';
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {
  AdHocReasonCode,
  HSAdministeredAdHocDrug,
  HSAdministeredDrug,
  HSDrug,
  HSTestResult,
  HSTestResultType,
  PinUserDto,
  ReasonCode,
} from 'server-openapi';
import styled from 'styled-components';
import {useCurrentUser} from '../../../core/authn/UserProvider';
import {useStore} from '../../../core/storage/hooks/UseStore';
import {DateUtils} from '../../../core/utils/dateUtils';
import {PatientMedicationService} from '../../../core/mrs/Services';
import {Button} from '../../../kit/Button';
import {Checkbox} from '../../../kit/Checkbox';
import {Dialog} from '../../../kit/Dialog';
import {DateInput} from '../../../kit/Forms/DateInput';
import {Form} from '../../../kit/Forms/Form';
import {FormGroup} from '../../../kit/Forms/FormGroup';
import {RadioGroup} from '../../../kit/Forms/RadioGroup';
import {TextArea} from '../../../kit/Forms/TextArea';
import {TextInput} from '../../../kit/Forms/TextInput';
import IconDotMenu from '../../../kit/Icons/DotMenu';
import {Layout} from '../../../kit/Layout';
import {Text} from '../../../kit/Text';
import {AppServices, useSyncCenter} from '../../../syncstream/SyncCenterProvider';
import {AdministrationWarnings, DrugUtils} from '../../../syncstream/utils/DrugUtils';
import {toasts} from '../../../kit/Toasts/Toaster';
import {useApiUtils} from '../../../syncstream/utils/hooks/useApiUtils';
import {ResidentDetailsUtils} from '../../../syncstream/utils/ResidentDetailsUtils';
import {AdministeredDrugTests} from './administeredDrugTests/AdministeredDrugTests';
import {DrugDetailLeftPanel} from './drugDetails/DrugDetailLeftPanel';
import {
  MedicationInformationBox,
  MedicationInformationProps,
  SecondCheckDialogContainer
} from './medicationInformation/MedicationInformationBox';
import {PatchUtils} from './patches/PatchUtils';
import {patientProfileChangeString} from './sidebar/PatientNotes';
import {PatientUtils} from '../../../syncstream/utils/PatientUtils';
import {useGroupPermissions} from "../../../core/authz/PermissionsProvider";
import {PatchLocationDTO} from "./patches/PatchLocations";
import {PatchLocationSelectorFullBody} from "./patches/PatchAdministerDialog";
import {PatchStatus} from "./MedicationListsTabbedRouter/TabLists/PatchesMedicationList";
import { Tooltip } from 'react-tooltip';

export interface MedicationAdministerProps extends MedicationInformationProps {
  drug: HSDrug;
  confirmationInitials?: string;
  confirmationUserId?: number;
  requiresSecondCheck?: boolean;
  isLate?: boolean;
  hasBeenDosed?: boolean;
}

interface Props extends MedicationAdministerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  isDisabled?: boolean;
}

interface ITestResultParams {
  headingText: string;
  unitPlaceholder: string;
  testResultType?: HSTestResultType;
}

export function MedicineAdministerDialog(props: Props) {
  const getDotsBtnDisabilityStatus = (): boolean => {
    return props.isDisabled || !props.administrationPermissions.canAdministerPatchMedicationExcludingS8;
  };



  return (
    <>
      <DotsButton onClick={() => {
        props.setOpen(true);
      }} disabled={getDotsBtnDisabilityStatus()}>
      <IconDotMenu/>
      </DotsButton>
      <Dialog lazy closeButtonColor={'#fff'} open={props.open} onRequestClose={() => props.setOpen(false)} size="full">
        <DialogContainer horizontal gap={2} data-testid="medicine-administer-dialog">
          <DrugDetailLeftPanel
            drug={props.drug}
            packedMedication={props.packedMedication}
            facilityGroupId={props.facilityGroupId}
            patient={props.patient}
          />
          <AdministerDrugForm {...props} />
        </DialogContainer>
      </Dialog>
    </>
  );
}

const DotsButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  border: none;
  &:disabled {
    cursor: not-allowed;
  }
`;

interface FormFields {
  status?: ReasonCode;
  notes?: string;
  dose?: string;
  adHocStatus?: AdHocReasonCode;
  testResultValue?: number;
  testResultDate?: Date;
  //hack to display error if test result is a required field
  unscheduledMedicationTestResult?: string;
}

function AdministerDrugForm(props: MedicationAdministerProps) {
  const apiUtils = useApiUtils();
  const services = useSyncCenter();
  const testResultTypeStore = useStore(services.testResultTypes.store).store;
  const groupPermissions = useGroupPermissions();
  const canRecordNote = groupPermissions.canRecordNote;
  const administrationWarnings = DrugUtils.getAdministrationWarnings(
    props.drug,
    props.packedMedication,
    apiUtils.patients.getHSMedicationFromPackedMedication(props.patient, props.packedMedication),
  );
  const showLateAdministration = props.isLate && !props.hasBeenDosed
  const user = useCurrentUser();
  const [unscheduledMedicationTestResults, setUnscheduledMedicationTestResults] = React.useState<HSTestResult[]>([]);
  const [isMedicationSelfAdministered, setIsMedicationSelfAdministered] = React.useState(false);
  //just to disable the submit button when SA status is being updated
  const [isMedicationAPIRunning, setIsMedicationAPIRunning] = React.useState(false);
  const canMarkResidentMedicationAsSelfAdministered = groupPermissions.canMarkResidentMedicationAsSelfAdministered;
  const [selectedPatchLocation, setSelectedPatchLocation] = useState<PatchLocationDTO>();
  const recentPatchLocations = PatchUtils.getRecentPatchLocations(apiUtils.patients, props.patient);

  const updateMedicationStateCallback = React.useCallback(
    (newState: boolean) => {
      setIsMedicationSelfAdministered(newState);
    },
    [isMedicationSelfAdministered],
  );

  const updateMedicationAPIRunningCallback = React.useCallback(
    (newState: boolean) => {
      setIsMedicationAPIRunning(newState);
    },
    [isMedicationAPIRunning],
  );
  const isPatch = PatchUtils.isPatch(props.drug);

  //check the SA (self-administered) state of medication when component loads
  React.useEffect(() => {
    const medication = props.patient.patientProfiles
      ?.flatMap((profile) => profile.allCurrentMedications)
      .find((medication) => medication?.hsId === props.packedMedication?.medicationId);

    if (medication) {
      setIsMedicationSelfAdministered(medication.selfAdministered ?? false);
    }
  }, []);

  function reasonCodes() {
    let reasonCodes: Array<AdHocReasonCode | ReasonCode> = props.nimAvailableDrug
        ? administerableAdHocReasonCodes
        : showLateAdministration ? administerableLateReasonCodes : administerableReasonCodes;
    if (props.hasBeenDosed) {
      // Hide the dosed element.
      reasonCodes = reasonCodes.filter(x => x !== ReasonCode.Dosed);
    }
    return reasonCodes;
  }

  const form = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: {
      status:
        props.currentDosedDrug && isReasonCode(reasonCodes(), props.currentDosedDrug.reasonCode!)
          ? props.currentDosedDrug.reasonCode!
          : props.requiresSecondCheck
          ? ReasonCode.Refused
          : props.isLate ? undefined : isReasonCode(reasonCodes(), ReasonCode.Dosed) ? ReasonCode.Dosed : undefined,
      notes: props.currentDosedDrug ? getComments(props.currentDosedDrug) : '',
      dose: props.packedMedication?.dosage?.toString(),
      testResultDate: new Date(),
    },
  });
  const currentStatus = form.watch('status');
  const testResultParamsMandatory = (currentStatus === ReasonCode.Dosed || currentStatus === ReasonCode.DosedLate);
  const testResultParams = testResultParamsMandatory ? setTestResultParams(administrationWarnings, testResultTypeStore) : undefined;
  const showSelfAdministerButton = DrugUtils.allowSelfAdminister(
    props.packedMedication,
    props.drug,
    props.isUnscheduledMedication,
  );


  let statusOptions = reasonCodes()
    .filter((status) => !isPatch || status !== ReasonCode.Dosed)
    .map((status) => {
      return { label: apiUtils.residentDetails.reasonCodeToString(status), value: status };
    });

  let nonpharmacologicalinterventionOptions = [
              { label : "12:22pm 7/3/2024 - Music therapy", value : "Music therapy" },
              { label : "4:22pm 7/3/2024 - Heat pack", value : "Heat pack" }
      ]
  ;

  if (props.requiresSecondCheck) {
    statusOptions = statusOptions.filter((s) => s.value !== 'Dosed' && s.value !== 'SelfAdministered');
  }

  function setError(str: string){
    form.setError('unscheduledMedicationTestResult', {
      type: 'Test Results Invalid',
      message: str,
    });
  }

  React.useEffect(() => {
    form.clearErrors('unscheduledMedicationTestResult');
    if (form.formState.isDirty && !areTestResultsValidNew(unscheduledMedicationTestResults, testResultTypeStore, setError)) {
    }
  }, [unscheduledMedicationTestResults]);

  async function onSubmit(data: FormFields) {
    if (data.notes && !canRecordNote) {
      form.setError('notes', {
        type: 'Permission denied',
        message: 'You do not have permission to record notes',
      });
      return;
    }
    //if the test result ID is undefined - throw an error as it is not present
    if (testResultParams && !testResultParams.testResultType && testResultParams) {
      form.setError('testResultValue', {
        type: 'Invalid Test Result Type',
        message: `${testResultParams.headingText} tests are not configured in the system. Please contact admin`,
      });
      return;
    }
    const testResults: HSTestResult[] = [...unscheduledMedicationTestResults];

    function setError(str: string){
      form.setError('unscheduledMedicationTestResult', {
        type: 'Test Results Invalid',
        message: str,
      });
    }

    if (!areTestResultsValidNew(unscheduledMedicationTestResults, testResultTypeStore, setError)) {
      return;
    }

    if (testResultParams && testResultParams.testResultType) {
      testResults.push({
        hsTestResultTypeId: testResultParams.testResultType.hsId,
        testResultEntries: [
          {
            key: testResultParams.testResultType.testResultFields?.[0].description,
            value: data.testResultValue?.toString(),
          },
        ],
        timeStamp: DateUtils.fromDate(data.testResultDate!),
      });
    }

    form.clearErrors();
    processDrugAdministration(data, props, user, apiUtils.residentDetails, testResults, (isPatch && currentStatus === ReasonCode.DosedLate) ? selectedPatchLocation : undefined);
    if (isPatch && selectedPatchLocation) {
      await PatchUtils.createPatchObservation(props, services, user, PatchStatus.Applied);
    }
  }

  async function triggerMedicationSelfAdministerUpdate(updatedSAStatus: boolean) {
    await runMedicationStatusUpdateApiProcedure(
      updatedSAStatus,
      updateMedicationStateCallback,
      updateMedicationAPIRunningCallback,
      props,
      canMarkResidentMedicationAsSelfAdministered ?? false,
      apiUtils.patients,
      apiUtils.residentDetails,
      services,
      user,
    );
  }
  function validateNotes(notes: string | undefined) : string | undefined {
   if (props.isUnscheduledMedication && (!notes || notes.length === 0)) {
      return 'Notes required for PRNs and NIMS';
    }
    const status = form.watch('status');
   if (!status) {
     return;
   }
    if (status !== ReasonCode.SelfAdministered && status !== ReasonCode.Dosed && (!notes || notes.length === 0)) {
      return showLateAdministration ? 'Notes required unless self administered' : 'Notes required if not administered and if not self-administered';
    }
    return;
  }


  return (
    <Form form={form} onSubmit={onSubmit}>
      <Layout gap={1}>
        <WhiteText size="large">Current status</WhiteText>
        <MedicationInformationBox displayCurrentStatusOnly {...props} />
        {!props.nimAvailableDrug && (
          <StatusSection gap={1}>
            <Text size="large">Update status</Text>
            <RadioGroup
              name={props.nimAvailableDrug ? 'adHocStatus' : 'status'}
              options={statusOptions}
              cols={3}
              control={form.control}
              required
              onChange={async () => await form.trigger(["notes"])}
            />
          </StatusSection>
        )}
        {testResultParams && (
          <Layout gap={1}>
            <Text size={'medium'}>{testResultParams.headingText} Test Result</Text>
            <Layout horizontal gap={1}>
              <TextInput
                name="testResultValue"
                type="number"
                placeholder={testResultParams.unitPlaceholder}
                fullWidth
                required
                data-testid="test_result_value"
                rules={{
                  validate: (value: string) => {
                    return validateTestResult(value, testResultParams?.testResultType?.name!);
                  }
                }}
              />
              <DateInput dateTime name="testResultDate" required fullWidth />
            </Layout>
          </Layout>
        )}
        {props.isUnscheduledMedication && !props.nimAvailableDrug && (
          <>
            <AdministeredDrugTests
              value={unscheduledMedicationTestResults}
              onUpdate={(r) => setUnscheduledMedicationTestResults(r)}
            />
            <HiddenTextInput name={'unscheduledMedicationTestResult'} />{' '}
          </>
        )}
        

        {(props.isUnscheduledMedication || testResultParams) && (
          <FormGroup fullWidth>
            <Tooltip id="medication-dosage" />
            <TextInput
              name="dose"
              type="number"
              placeholder="Set dose"
              fullWidth
              required
              data-testid="medication-dosage"
              data-tooltip-content="Set medication dosage"
              data-tooltip-id="medication-dosage"
              data-tooltip-place="top-start"
            />
          </FormGroup>
        )}

        {
          isPatch && currentStatus === ReasonCode.DosedLate &&
            <PatchLocationSelectorFullBody
                isViewOnly={false}
                selectedPatchLocation={selectedPatchLocation}
                recentlyPatchedLocations={recentPatchLocations}
                setSelectedPatchLocation={setSelectedPatchLocation}
            />
        }
        <FormGroup fullWidth>
          <TextArea
            name="notes"
            placeholder="Add note"
            rows={3}
            fullWidth
            rules={{
              validate: (notes: string) => validateNotes(notes)
            }}
            data-testid="medication-notes"
          />
        </FormGroup>
        {showSelfAdministerButton && (
          <Layout horizontal gap={0.5} align={'center'}>
            <Checkbox
              checked={isMedicationSelfAdministered}
              disabled={!canMarkResidentMedicationAsSelfAdministered}
              onChange={(event) => {
                triggerMedicationSelfAdministerUpdate(event.target.checked);
              }}
            />
            <Text>Set as self-administered</Text>
          </Layout>
        )}

        { props.requiresSecondCheck && currentStatus === ReasonCode.DosedLate ?

            <SecondCheckDialogContainer {...props} /> :
            <Button
                disabled={form.formState.isSubmitSuccessful || !form.formState.isValid || isMedicationAPIRunning}
                type="submit"
                fullWidth
                data-testid="medication-administer-button">UPDATE STATUS</Button>
        }
      </Layout>
    </Form>
  );
}

const HiddenTextInput = styled(TextInput)`
  height: 0;
  width: 0;
  overflow: hidden;
  position: absolute;
  display: none;
`;

const DialogContainer = styled(Layout)`
  padding: 1em;
  grid-template-columns: 3fr 5fr;
`;

const StatusSection = styled(Layout)`
  color: white;
`;

const WhiteText = styled(Text)`
  color: white;
`;

function setTestResultParams(
  administrationWarnings: AdministrationWarnings,
  testResultTypeStore: ReadonlyMap<string, HSTestResultType>,
): ITestResultParams | undefined {
  if (administrationWarnings.inrTestRequired) {
    return {
      headingText: 'INR',
      unitPlaceholder: 'INR Level',
      testResultType:
        itiriri(testResultTypeStore.values()).find((testResultType) => testResultType.name === 'INR') ?? undefined,
    };
  }
  if (administrationWarnings.bglTestRequired) {
    return {
      headingText: 'BGL',
      unitPlaceholder: 'mmol/l',
      testResultType:
        itiriri(testResultTypeStore.values()).find((testResultType) => testResultType.name === 'Blood Sugar Level') ??
        undefined,
    };
  }
  return undefined;
}
// eslint-disable-next-line sonarjs/cognitive-complexity
function processDrugAdministration(
  data: FormFields,
  props: MedicationAdministerProps,
  user: User,
  residentDetails: ResidentDetailsUtils,
  testResults?: HSTestResult[],
  patchLocation?: PatchLocationDTO
) {
  const notes = data.notes ? [data.notes] : [];
  const dose = data.dose ? parseFloat(data.dose) : undefined;
  props.nimAvailableDrug
    ? props.onAdministerAdHoc?.(
        residentDetails.generateAdministeredAdHocDrug(
          dose!,
          props.drug,
          AdHocReasonCode.Dosed,
          notes,
          user,
          props.confirmationInitials,
          props.confirmationUserId,
        ),
      )
    : props.onAdminister?.(
        residentDetails.generateAdministeredDrug(
          props.packedMedication!,
          props.drug,
          data.status!,
          notes,
          user,
          props.confirmationInitials,
          props.confirmationUserId,
          dose,
          patchLocation
        ),
        props.packedMedication && 'doseTimestamp' in props.packedMedication
          ? props.packedMedication?.doseTimestamp ?? ''
          : '',
        testResults && testResults.length > 0 ? testResults : [],
      );
}

export function getComments(currentDosedDrug: HSAdministeredDrug | HSAdministeredAdHocDrug) {
  if (
    'administeredDrugComments' in currentDosedDrug &&
    currentDosedDrug.administeredDrugComments &&
    currentDosedDrug.administeredDrugComments?.length > 0
  ) {
    return currentDosedDrug.administeredDrugComments[currentDosedDrug.administeredDrugComments?.length - 1]
      .commentText!;
  } else if (
    'administeredAdHocDrugComments' in currentDosedDrug &&
    currentDosedDrug.administeredAdHocDrugComments &&
    currentDosedDrug.administeredAdHocDrugComments?.length > 0
  )
    return currentDosedDrug.administeredAdHocDrugComments[currentDosedDrug.administeredAdHocDrugComments?.length - 1]
      .commentText!;
  else return '';
}

function isReasonCode(reasonCodes: Array<AdHocReasonCode | ReasonCode>, token: ReasonCode | AdHocReasonCode): token is ReasonCode {
  return reasonCodes.includes(token as ReasonCode);
}

export function validateTestResult(value: string, testType:  string) {
  switch (testType) {
    case 'Blood Pressure':
      if (value && parseInt(value, 10) >= 0 && parseInt(value, 10) <= 250){
        return true
      } else {
        return "Blood Pressure has to be between 0 - 250";
      }
    case 'Blood Sugar Level':
      if (value && parseFloat(value) >= 0 && parseFloat(value) <= 30){
        return true
      } else {
        return "Blood Sugar Level has to be between 0 - 30.0";
      }
    case 'Weight':
      if (value && parseFloat(value) >= 0 && parseFloat(value) <= 350){
        return true
      } else {
        return "Weight has to be between 0 - 350.00";
      }
    case 'INR':
      if (value && parseFloat(value) >= 0 && parseFloat(value) <= 10){
        return true
      } else {
        return "INR has to be between 0 - 10.0";
      }
    case 'Pulse':
      if (value && parseInt(value, 10) >= 0 && parseInt(value, 10) <= 200){
        return true
      } else {
        return "Pulse has to be between 0 - 200";
      }
    case 'Temperature':
      if (value && parseFloat(value) >= 0 && parseFloat(value) <= 45){
        return true
      } else {
        return "Temperature has to be between 0 - 45.0";
      }
    case 'Pain':
      if (value && parseInt(value, 10) >= 0 && parseInt(value, 10) <= 10){
        return true
      } else {
        return "Pain has to be between 0 - 10";
      }
    case 'Pain Level':
      if (value && parseInt(value, 10) >= 0 && parseInt(value, 10) <= 10){
        return true
      } else {
        return "Pain Level has to be between 0 - 10";
      }
    default: //if it is not one of the above, then check if it has a value as per old functionality
      return (value && value.length > 0);
  }
}


export function areSpecificTestResultsValid(testResult: HSTestResult, testResultTypeStore:  ReadonlyMap<string, HSTestResultType>, setError: (str: string) => void) {

  const testResultType = itiriri(testResultTypeStore.values()).find((testResultType) => testResultType.hsId === testResult.hsTestResultTypeId)
  switch (testResultType?.name) {
    case 'Blood Pressure':
        if (testResult.testResultEntries?.every((tr) => tr.value && parseInt(tr.value, 10) >= 0 && parseInt(tr.value, 10) <= 250)){
          return true
        } else {
          setError("Blood Pressure has to be between 0 - 250");
          return false;
        }
    case 'Blood Sugar Level':
      if (testResult.testResultEntries?.every((tr) => tr.value && parseFloat(tr.value) >= 0 && parseFloat(tr.value) <= 30)){
        return true
      } else {
        setError("Blood Sugar Level has to be between 0 - 30.0");
        return false;
      }
    case 'Weight':
      if (testResult.testResultEntries?.every((tr) => tr.value && parseFloat(tr.value) >= 0 && parseFloat(tr.value) <= 350)){
        return true
      } else {
        setError("Weight has to be between 0 - 350.00");
        return false;
      }
    case 'INR':
      if (testResult.testResultEntries?.every((tr) => tr.value && parseFloat(tr.value) >= 0 && parseFloat(tr.value) <= 10)){
        return true
      } else {
        setError("INR has to be between 0 - 10.0");
        return false;
      }
    case 'Pulse':
      if (testResult.testResultEntries?.every((tr) => tr.value && parseInt(tr.value, 10) >= 0 && parseInt(tr.value, 10) <= 200)){
        return true
      } else {
        setError("Pulse has to be between 0 - 200");
        return false;
      }
    case 'Temperature':
      if (testResult.testResultEntries?.every((tr) => tr.value && parseFloat(tr.value) >= 0 && parseFloat(tr.value) <= 45)){
        return true
      } else {
        setError("Temperature has to be between 0 - 45.0");
        return false;
      }
    case 'Pain':
      if (testResult.testResultEntries?.every((tr) => tr.value && parseInt(tr.value, 10) >= 0 && parseInt(tr.value, 10) <= 10)){
        return true
      } else {
        setError("Pain has to be between 0 - 10");
        return false;
      }
    case 'Pain Level':
      if (testResult.testResultEntries?.every((tr) => tr.value && parseInt(tr.value, 10) >= 0 && parseInt(tr.value, 10) <= 10)){
        return true
      } else {
        setError("Pain Level has to be between 0 - 10");
        return false;
      }
    default: //if it is not one of the above, then check if it has a value as per old functionality
      return testResult.testResultEntries?.every((tr) => tr.value && tr.value.length > 0);
  }
}

export function areTestResultsValidNew(testResults: HSTestResult[], testResultTypeStore:  ReadonlyMap<string, HSTestResultType>, setError: (str: string) => void) {
  return testResults.every(
    (item) =>
      item.testResultEntries !== undefined &&
      areSpecificTestResultsValid(item, testResultTypeStore, setError) &&
      item.timeStamp !== undefined &&
      item.hsTestResultTypeId !== undefined,
  );
}

export function areTestResultsValid(testResults: HSTestResult[]) {
  return testResults.every(
      (item) =>
          item.testResultEntries !== undefined &&
          item.testResultEntries?.every((tr) => tr.value && tr.value.length > 0) &&
          item.timeStamp !== undefined &&
          item.hsTestResultTypeId !== undefined,
  );
}

export async function runMedicationStatusUpdateApiProcedure(
  updatedStatus: boolean,
  updateMedicationStateCallback: (newState: boolean) => void,
  updateMedicationAPIRunningCallback: (newState: boolean) => void,
  props: MedicationAdministerProps,
  canMarkResidentMedicationAsSelfAdministered: boolean,
  patientUtils: PatientUtils,
  residentDetailUtils: ResidentDetailsUtils,
  services: AppServices,
  user: User,
) {
  if (!canMarkResidentMedicationAsSelfAdministered) {
    toasts.error('You do not have permission to mark medication as self administered');
    return;
  }
  updateMedicationAPIRunningCallback(true);
  //create the req object
  const req = patientUtils.createPatientMedicationUpdateRequestObject(
    updatedStatus,
    props.patient,
    props.packedMedication!,
    props.facilityGroupId,
  );
  if (req === undefined) {
    toasts.error('Medication not found. Contact admin');
    return;
  }
  //make the API call here
  const res = await PatientMedicationService.updateMedicationSelfAdministeredStatus(req).catch((error: AxiosError) => {
    //Note - currently there is no reliable way to ensure that the error is due to incorrect version
    //for all cases, the API fails with a 500 error
    //TODO: Better error message??
    toasts.error(
      'Unable to update. This could be due to incorrect version. Please wait for the correct medication version to be synced before trying again',
    );
    updateMedicationAPIRunningCallback(false);
    throw error;
  });

  if (res) {
    //eager update the patient object in indexed db and memory cache
    await patientUtils.updatePatientMedicationSAStatus(
      services.patients.store,
      props.patient,
      props.packedMedication!,
      updatedStatus,
    );
    //create a progress note to log the update made to resident medication
    //med_id is required for a different process and the format should not be updated
    const progressNoteComment = `Medication (med_id - ${req.medicationId}:${req.selfAdministeredStatus}) self administered status for ${props.drug.name} updated to ${req.selfAdministeredStatus}`;
    //enqueue the progress note
    await residentDetailUtils.addPatientProgressNote(
      props.patient,
      props.facilityGroupId,
      patientProfileChangeString,
      progressNoteComment,
      '',
      services.patients.service,
      user,
    );
    updateMedicationStateCallback(updatedStatus);
    toasts.success('Medication successfuly set as Self-Administered');
    updateMedicationAPIRunningCallback(false);
  }
}

export const administerableLateReasonCodes: ReasonCode[] = [
  ReasonCode.DosedLate,
  ReasonCode.DoseSupplied,
  ReasonCode.Refused,
  ReasonCode.NoStock,
  ReasonCode.Withheld,
  ReasonCode.Hospital,
  ReasonCode.Absent,
  ReasonCode.Ceased,
  ReasonCode.Omitted,
  ReasonCode.SelfAdministered,
  ReasonCode.Leave,
  ReasonCode.Other,
];
export const administerableReasonCodes: ReasonCode[] = [
  ReasonCode.Dosed,
  ReasonCode.DoseSupplied,
  ReasonCode.Refused,
  ReasonCode.NoStock,
  ReasonCode.Withheld,
  ReasonCode.Hospital,
  ReasonCode.Absent,
  ReasonCode.Ceased,
  ReasonCode.Omitted,
  ReasonCode.SelfAdministered,
  ReasonCode.Leave,
  ReasonCode.Other,
];

const administerableAdHocReasonCodes: AdHocReasonCode[] = [
  AdHocReasonCode.Dosed,
  AdHocReasonCode.Refused,
  AdHocReasonCode.NoStock,
];
