import {HSFacility, HSPatient, HSUser} from 'server-openapi';
import {Entry, IStorage} from '../core/storage/Contract';
import { SyncStreamAPI } from './api';
import {IFacilityGroupSyncService, ISyncService} from './SyncCenter';
import { SyncUtils } from './utils/SyncUtils';
import {MemoryCache} from "../core/storage/MemoryCache";

export class SyncUsers implements IFacilityGroupSyncService {
  get name(): string {
    return 'Users';
  }
  private isStale(u: HSUser): boolean {
    return !u.active
  }

  private async isFacilityGroup(u: HSUser, facilityGroupId: string): Promise<boolean> {
    return !!u.facilities?.some(async x => {
      const facilityGroup = await this.facilitiesStore.get(x.toString());
      return facilityGroup?.facilityGroupId?.toString() == facilityGroupId;
    });
  }

  constructor(
    private api: SyncStreamAPI,
    private storage: MemoryCache<HSUser>,
    private facilitiesStore: IStorage<HSFacility>,
    private latestChangeNumbers: IStorage<number | undefined>,
    private epochStore: MemoryCache<string>
  ) {}
  async load(facilityGroupId: string): Promise<void> {
    await this.storage.reset(async (p) => {
      return true;
    });
  }

  async syncDown(facilityGroupId?: string) {
    const facilitiesToSync = await SyncUtils.getFacilitiesForGroup(facilityGroupId, this.facilitiesStore);
    const changeNumber = await SyncUtils.getChangeNumberForFacilities(
        facilitiesToSync.map((x) => x.hsId!),
        this.latestChangeNumbers,
    );
    const users = await this.syncFacilityDown(
      facilityGroupId!,
      facilitiesToSync.map((facility) => facility.hsId!),
      changeNumber);

    await this.storage.setMany(
      users
        .map((user) => ({
          key: user.hsId!.toString(),
          value: user,
        })),
    );

    await SyncUtils.setChangeNumberForFacilities(
        facilitiesToSync.map((x) => x.hsId!),
        this.latestChangeNumbers,
        users);
  }

  async syncFacilityDown(facilityGroupId: string, facilityIds: number[], changeNumber: number): Promise<HSUser[]> {
    const pageSize = 500;
    const users = await this.api.users.userListUsers(
      changeNumber,
      +facilityGroupId,
      pageSize,
    );

    if (users.data.length === pageSize) {
      return [
        ...users.data,
        ...(await this.syncFacilityDown(facilityGroupId, facilityIds, SyncUtils.getLatestChangeNumber(users.data)!)),
      ];
    }
    return users.data;
  }

  async syncUp() {
    // Do nothing
  }

  async clear() {
    await this.storage.clear();
    await this.latestChangeNumbers.clear();
  }

  async hasQueuedData() {
    return false;
  }
  isAllowed(canUserAccessMedication: boolean): boolean {
    // Only if you can view a round.
    return true;
  }
  async archive(): Promise<string[]> {
    const keysToDelete: string[] = [];
    for (let [k, v] of (await this.storage.all()).entries()) {
      if (this.isStale(v)) {
        keysToDelete.push(k)
      }
    }
    await this.storage.deleteMany(keysToDelete);
    return keysToDelete;
  }
  setEncryptionVersion(version: number): void {
    this.storage.compressOnSave = (version > 1);
  }
  async rewrite(): Promise<void> {
    const entries: Entry<HSUser>[] = [...(await this.storage.all())].map((keyValueArray) => {
      return {
        key: keyValueArray[0],
        value: keyValueArray[1]
      };
    });

    return await this.storage.setMany(entries);
  }}
