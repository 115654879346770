// temporary solution until MEM-531 is addressed in HealthStream
export const POLYPHARMACY_DRUG_FORMS = [
  'A-CAPS',
  'A-HALER',
  'AER-SPR',
  'AMP',
  'CAP',
  'CAPLET',
  'CAPSEALS',
  'CD-CAP',
  'CHEW-CAP',
  'CHEW-TAB',
  'CHEW-TABS',
  'CR-CAP',
  'CR-TAB',
  'CRM-PESS',
  'D-TAB',
  'DISKS',
  'DISP-TAB',
  'EC-TABS',
  'EFF-TAB',
  'ELIX',
  'ENEMA',
  'ER-TAB',
  'EXPECT',
  'FOAM',
  'G',
  'GRAN',
  'IMP',
  'INF',
  'INH',
  'INH-CAP',
  'INHL',
  'INHR',
  'INJ',
  'INS',
  'IV',
  'IV-SOLN',
  'LINCT',
  'LIQ',
  'LOZ',
  'M-WASH',
  'MET-AERO',
  'MET-AREO',
  'MET-INHR',
  'MIXT',
  'MR-TABLET',
  'MSPR',
  'NAS-CRM',
  'NAS-DRP',
  'NAS-OINT',
  'NAS-SOLN',
  'NAS-SPR',
  'NAS-SPRY',
  'NAS-SPY',
  'NEB',
  'NEB-SOLN',
  'O-DRPS',
  'O-LIQ',
  'O-SOLN',
  'OR-DISTAB',
  'OR-GEL',
  'ORAL-DROPS',
  'ORAL-TINC',
  'ORAL-VAC',
  'PELL',
  'PESS',
  'PFS',
  'PTCH',
  'R-CAPS',
  'RECT-FOAM',
  'RECT-OINT',
  'RESP',
  'SACH',
  'SL-FILM',
  'SL-SPRY',
  'SL-TAB',
  'SOL-TAB',
  'SOLN',
  'SP-CAPS',
  'SPRY',
  'SR-CAP',
  'SR-TAB',
  'ST-NEBS',
  'SUPP',
  'SUSP',
  'SUSP-SACH',
  'SYR-SACH',
  'SYRNG',
  'SYRP',
  'SYRUP',
  'T-HALER',
  'TAB',
  'TAB-EFF',
  'TABSULES',
  'TURBHAL',
  'UDV',
  'UNI-DOSE',
  'VACC',
  'VAG-CRM',
  'VAG-FOAM',
  'VAG-GEL',
  'VAG-TAB',
  'VL',
  'WAF',
];

export const MIN_THRESHOLD_FOR_POLYPHARMACY = 9;
