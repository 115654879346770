import React from 'react';
import { LayoutContainer } from '../../kit/LayoutContainer';
import { Paper } from '../../kit/Paper';
import Loader from "react-loader-spinner";

/*
  You want to keep this decoupled from the api. Permissions aren't for blocking individual apis.
  They're for blocking entire procedures. There aren't that many enqueues anyways.
*/

export function RequirePermission(props: { hasPermission?: boolean; children: JSX.Element }) {

  if (props.hasPermission === undefined) {
      // Permissions haven't been retrieved yet.  Show spinner.
      return (<Loader type="Oval" height={40} width={40} />);
  }
  if (props.hasPermission) {
    return props.children;
  }

  return <PermissionDenied />;
}

function PermissionDenied() {
  return (
    <LayoutContainer>
      <Paper secondary>
        <h3>Permission Denied</h3>
      </Paper>
    </LayoutContainer>
  );
}
