interface IValidation {
  regex?: RegExp; // optional for pattern matching
  message: string;
}

export class ValidationMessages {
  static email: IValidation = {
    regex: /^[A-Z0-9._'%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: 'invalid email address',
  };
}
