/* eslint-disable react/prop-types */
import React from 'react';
import { Control, Controller, useFormContext, Validate, ValidationValueMessage } from 'react-hook-form';
import { FormFieldError } from './FormFieldError';

export interface FormInput<T> {
  name: string;
  placeholder?: string;
  value?: T;
  defaultValue?: T;
  required?: boolean;
  disabled?: boolean;
  rules?: {
    minLength?: string | number | ValidationValueMessage<React.ReactText> | undefined;
    validate?: Validate | Record<string, Validate> | undefined;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef?: React.MutableRefObject<any> | React.RefObject<any>;
  onChange?: OnInputChange<T>;
  onBlur?: () => void;
}

export type OnInputChange<T> = (event: React.FormEvent | undefined, value: T) => void;

// Lots of type hacks to make this work, but it's basically
// just taking in a react component that has props extending the FormInput props
// and then wrapping that component with the react-hook-form Controller
// component.
// This is done so that we can wrap all form inputs with some shared behaviour.
// namely:
// - make them all controlled components
// - ensure that the inputs are connected to the form
// - ensure field error messages are always displayed
export function formInput<T>(component: T): T {
  const Component = component as unknown as React.FC<FormInput<unknown>>;

  const wrapper = (props: FormInput<unknown>) => {
    const form = useFormContext();

    if (!form) {
      return (
        <div>
          <Component {...props} />
        </div>
      );
    }

    return (
      <div style={{ width: '100%' }}>
        <Controller
          control={props.control ?? form.control}
          name={props.name}
          defaultValue={props.value ?? null}
          rules={{
            ...props.rules,
            required: props.required ?? false,
          }}
          render={({ onChange, onBlur, value, name, ref }) => {
            return (
              <Component
                {...props}
                name={name}
                value={value}
                onChange={(event, value) => {
                  onChange(value);
                  props.onChange?.(event, value);
                }}
                inputRef={ref}
                onBlur={() => {
                  props.onBlur?.();
                  onBlur();
                }}
                disabled={form?.formState?.isSubmitting || props.disabled}
              />
            );
          }}
        />
        <FormFieldError error={form?.formState?.errors[props.name]} />
      </div>
    );
  };

  return wrapper as unknown as T;
}
