import { HSFacility } from 'server-openapi';
import { PersistentQueue } from '../core/queue/PersistentQueue';
import { IStorage } from '../core/storage/Contract';
import { SyncStreamAPI } from './api';
import { ISyncService } from './SyncCenter';

interface Operation {
  type: void;
  payload: HSFacility;
}

export class SyncFacilities implements ISyncService {
  get name(): string {
    return 'SyncFacilities';
  }

  constructor(
    private api: SyncStreamAPI,
    private storage: IStorage<HSFacility>,
    private queue: PersistentQueue<Operation>,
  ) {
  }

  async syncDown() {
    const facilities = await this.syncDownWithChangeNumber(0);
    await this.storage.clear();
    await this.storage.setMany(
      facilities
        .map((facility) => ({
          key: this.storage.get_key!(facility),
          value: facility,
        })),
    );
  }

  async syncDownWithChangeNumber(changeNumber: number): Promise<HSFacility[]> {
    // TODO: storage locking so that we can be sure the UI
    // didn't accidentally change a resource in between the API
    // giving us fresh data and updating the storage backend.
    const facilities = await this.api.facilities.facilitiesListFacilities();
    return facilities.data;
  }

  async syncUp() {
    // Do nothing
  }

  async clear() {
    await this.storage.clear();
    await this.queue.clear();
  }

  async hasQueuedData() {
    return (await this.queue.length()) > 0;
  }
  isAllowed(canUserAccessMedication: boolean): boolean {
    // Only if you can view a round.
    return true;
  }
}
