import React from 'react';
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import urljoin from 'url-join';
import { NavigationBar } from '../../../components/NavigationBar/NavigationBar';
import { PageMenuBar } from '../../../components/PageMenuBar';
import { Grid } from '../../../kit/Grid';
import { IconBack } from '../../../kit/Icons/Back';
import { WarningDetailsForm } from './components/WarningDetailsForm';
import {GlobalSettingsForm} from "./components/GlobalSettingsForm";;

//TODO: add permission check to verify that user has system admin role
export function GlobalSettingsPage() {
  return (
    <Grid cols={1} gap={0}>
      <NavigationBar
        nodes={() => ([
          <LeftNav gap={1} cols={2}>
            <Link to="/facility-group/switch">
              <IconBack />
            </Link>{' '}
            <h1>Global System Settings</h1>
          </LeftNav>
        ])}
      />
      <GlobalSettingsContent />
    </Grid>
  );
}

function GlobalSettingsContent() {
  const { path } = useRouteMatch();
  return (
    <>
      <PageMenuBar menuRoutes={[{ route: 'warnings', label: 'WARNINGS' }, { route: 'global-settings', label: 'GLOBAL SETTINGS'}]} />
      <SettingsContentContainer>
        <Switch>
          <Redirect exact path={path} to={urljoin(path, 'warnings')} />
          <Route exact path={urljoin(path, 'warnings')} component={WarningDetailsForm} />
          <Route exact path={urljoin(path, 'global-settings')} component={GlobalSettingsForm} />
        </Switch>
      </SettingsContentContainer>
    </>
  );
}

const LeftNav = styled(Grid)`
  display: flex;
  align-items: center;
`;

const SettingsContentContainer = styled.div`
  padding: 50px;
`;
