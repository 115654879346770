import React from 'react';
import { HSFacilityGroup } from 'server-openapi';
import styled from 'styled-components';
import { breakpoints } from '../../kit/Theme/Theme';
import { NavigationBarMenu } from './NavigationBarMenu';

const NavigationGrid = styled.div<{ inverted?: boolean }>`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto auto;
  ${breakpoints.mobileDown} {
    grid-template-columns: 1fr;
  }
  grid-template-rows: 1fr;
  grid-auto-flow: row;
  align-items: center;
  padding: 2.5em 2em;
  background-color: ${(p) => (p.inverted ? p.theme.appbar.fg : p.theme.appbar.bg)};
  min-height: 84px;
`;

const NavigationColumn = styled.div<{ inverted?: boolean; right?: boolean }>`
  display: flex;
  justify-content: ${(p) => (p.right ? 'flex-end' : 'flex-start')};

  p,
  h1,
  h2,
  h3 {
    color: ${(p) => (p.inverted ? p.theme.appbar.bg : p.theme.appbar.fg)};
  }
  a {
    text-decoration: none;
  }
`;

export function NavigationBar(props: {
  nodes: () => React.ReactNode[];
  inverted?: boolean;
  facilityGroup?: HSFacilityGroup;
}) {
  return (
    <NavigationGrid inverted={props.inverted}>
        {
            props.nodes().map((node, idx) => {
                return <NavigationColumn key={idx} inverted={props.inverted} right={idx % 2 != 0}>{node}</NavigationColumn>
            })
        }
    </NavigationGrid>
  );
}
