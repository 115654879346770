import { isEqual } from 'lodash-es';
import React from 'react';
import styled from 'styled-components';
import { formInput, FormInput } from './FormInput';

interface Props<T> extends FormInput<T> {
  options: Array<SelectOption<T>>;
  fullWidth?: boolean;
}

export interface SelectOption<T> {
  label: string;
  value: T;
}

export const Select = formInput(function <T>(props: Props<T>) {
  const selectedIndex = props.options.findIndex((opt) => {
    return isEqual(opt.value, props.value);
  });

  const defaultValue = props.options.findIndex((opt) => {
    return isEqual(opt.value, props.defaultValue);
  });

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    props.onChange?.(event, props.options[Number(event.currentTarget.value)]?.value);
  };

  return (
    <StyledContainer fullWidth={props.fullWidth}>
      <StyledSelect
        {...props}
        ref={props.inputRef}
        name={props.name}
        placeholder={props.placeholder}
        value={props.onChange && selectedIndex}
        defaultValue={props.onChange && selectedIndex === undefined ? defaultValue : undefined}
        onChange={onChange}
        fullWidth={props.fullWidth}
      >
        <option value={-1} style={{ fontStyle: 'italic', color: 'gray' }}>
          {props.placeholder}
        </option>

        {props.options.map((opt, i) => (
          <option key={i} value={i}>
            {opt.label}
          </option>
        ))}
      </StyledSelect>
    </StyledContainer>
  );
});

const StyledSelect = styled.select<Pick<Props<unknown>, 'fullWidth'>>`
  appearance: none;
  position: relative;
  padding: ${(props) => props.theme.forms.input.padding};
  padding-right: 1.875rem;
  border: solid;
  border-radius: ${(props) => props.theme.forms.input.border.radius};
  border-width: ${(props) => props.theme.forms.input.border.width};
  border-color: ${(props) => props.theme.forms.input.border.color};
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  color: ${(props) => (!props.defaultValue && props.value === -1 ? 'gray' : 'black')};
  font-weight: ${(props) => props.theme.typography.defaults.fontWeight};

  &::-ms-expand {
    display: none;
  }
`;

const StyledContainer = styled.div<Pick<Props<unknown>, 'fullWidth'>>`
  position: relative;
  display: inline-grid;
  z-index: 0;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};

  &::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 8px;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    background-color: #777;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    z-index: 99;
  }
`;
