import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import IconMail from '../../kit/Icons/IconMail';
import IconPhone from '../../kit/Icons/IconPhone';
import { Layout } from '../../kit/Layout';
import { Text } from '../../kit/Text';
import {Button} from "../../kit/Button";
import {useLogger} from "../../core/logger/LoggerContext";
import {colors} from "../../kit/Theme/Theme";
import {toasts} from "../../kit/Toasts/Toaster";
import useDashboardData from "../AppDiagnosis/hooks/useDashboardData";
import {apis} from "../../core/mrs/apis";
import {AxiosError} from "axios";
import {useAppState} from "../../context/AppStateProvider";
import {EmailReq} from "server-openapi";
import {useStore} from "../../core/storage/hooks/UseStore";
import {useSyncCenter} from "../../syncstream/SyncCenterProvider";
import {useCurrentUser} from "../../core/authn/UserProvider";
import {useGroupPermissions} from "../../core/authz/PermissionsProvider";
import {useApiUtils} from "../../syncstream/utils/hooks/useApiUtils";
import itiriri from "itiriri";

export function SupportInformation() {
  const {indexedDbData} = useDashboardData();
  const [dbData, setDbData] = useState<{indexedDbData: { dataType: string, numberOfRecords: number, queueSize: string }[]}>({indexedDbData: indexedDbData});
  const [logs] = useLogger();
  const [logsArray, setLogsArray] = useState(logs);
  const appState = useAppState();

  const syncCenter = useSyncCenter();
  const syringeDriverActivityQueue = useStore(syncCenter.syringeDriverActivity.queue.storage);
  const patchObservationsQueue = useStore(syncCenter.patchObservations.queue.storage);
  const doseRoundsQueue = useStore(syncCenter.rounds.queue.storage);
  const user = useCurrentUser();
  const apiUtils = useApiUtils();
  const userNameAndRole = apiUtils.users.getUserFullNameAndRoleFromSubjectId(user.profile.sub);
  const permissions = useGroupPermissions();
  const services = useSyncCenter();
  const facilityGroupsStore = useStore(services.facilityGroups.store).store;
  const facilityGroups = itiriri(facilityGroupsStore.values()).toArray();
  const facilityGroup = facilityGroups.find((fg) => fg.hsId === parseInt(appState.state.selectedFacilityGroupId as string));

  const getQueueMessagesActivities = () => {
    return {
      syringeDriverQueueActivities: syringeDriverActivityQueue.store.values(),
      patchObservationsQueueActivities: patchObservationsQueue.store.values(),
      doseRoundsQueueActivities: doseRoundsQueue.store.values(),
    };
  }

  const getBrowserType = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();

    if (userAgent.indexOf("firefox") !== -1) {
      return 'Firefox';
    }
    if (userAgent.indexOf("edg") !== -1) {
      return 'Edge';
    }
    if (userAgent.indexOf("opr") !== -1) {
      return 'Opera';
    }
    if (userAgent.indexOf("chrome") !== -1) {
      return 'Chrome';
    }
    if (userAgent.indexOf("safari") !== -1) {
      return 'Safari';
    }
    if (userAgent.indexOf("msie") !== -1) {
      return 'Explorer';
    }
  };

  const userInformation = {
    name: user.profile.name,
    surname: user.profile.surname,
    currentRole: userNameAndRole,
    permissions: permissions,
    facilityName: facilityGroup?.name,
    facilityHsId: facilityGroup?.hsId,
    facilityLocality: facilityGroup?.locality,
    facilityLastUpdatedByLogin: facilityGroup?.lastUpdatedByLogin
  };

  const browserInformation = {
    type: getBrowserType(),
    language: window.navigator.language,
    pdfViewerEnabled: window.navigator.pdfViewerEnabled
  };

  const sendDiagnosticDataEmail = async () => {
    const emailReq: EmailReq = {
      facilityGroupId: +(appState.state.selectedFacilityGroupId ?? "0"),
      diagnosticData: {
        logsData: JSON.stringify(logsArray.messages),
        dashboardData: JSON.stringify(indexedDbData),
        queueMessages: JSON.stringify(getQueueMessagesActivities()),
        userInformation: JSON.stringify(userInformation),
        browserInformation: JSON.stringify(browserInformation)
      },
    };

    await apis.email.emailSend(emailReq).catch((error: AxiosError) => {
      toasts.error(error.response?.data.detail);
      throw error;
    });

    toasts.success('Diagnostic information has successfully been sent');
  };

  useEffect(() => {
    setLogsArray(logs);
    setDbData(dbData);
  }, [logs, indexedDbData]);


  return (
    <InformationLayout gap={2}>
      <img src={require('../../core/authn/mps-medica-logo.svg')} />
      <Layout gap={3}>
        <Layout>
          <InfoText weight={'bold'} size={'medium'}>
            CUSTOMER SUPPORT
          </InfoText>
        </Layout>
        <Layout gap={4}>
          <Layout gap={1}>
            <Layout horizontal align={'center'}>
              <IconPhone />
              <EmailIdText href="tel:1800003938">
                &nbsp; 1800 003 938
              </EmailIdText>
            </Layout>
            <Layout horizontal align={'center'}>
              <InfoText weight={'100'} size={'medium'}>
                Customer Service Hours: Monday–Friday 8am–7pm (AEST)
              </InfoText>
            </Layout>
            <Layout horizontal align={'center'}>
              <InfoText weight={'100'} size={'medium'}>
                MediSphere Emergency Support: 24 hours 7 days
              </InfoText>
            </Layout>
            <Layout horizontal align={'center'}>
              <InfoText weight={'100'} size={'medium'}>
                eNRMC Prescriber Support: 24 hours 7 days
              </InfoText>
            </Layout>
          </Layout>


          <Layout gap={1}>
            <Layout horizontal align={'center'}>
              <IconMail />
              <InfoText weight={'200'} size={'medium'}>
                &nbsp; Email: &nbsp;
                <EmailIdText href="mailto:customerservice@mpsconnect.com.au">
                  customerservice@mpsconnect.com.au
                </EmailIdText>
              </InfoText>
            </Layout>
            <Layout horizontal align={'center'}>
              <InfoText weight={'100'} size={'medium'}>
                Email Monitored: Monday–Friday 8am–7pm (AEST)
              </InfoText>
            </Layout>
          </Layout>

          <Layout gap={1}>
            <Layout align={'center'}>
              <DiagnosticInfoContainer>
                <Line></Line>
                <DiagnosticText>
                  If you are experiencing any issue with the system, send us the technical information by clicking button below.
                </DiagnosticText>
                <SendDiagnosisButton onClick={sendDiagnosticDataEmail}>Send Diagnostic Info</SendDiagnosisButton>
              </DiagnosticInfoContainer>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </InformationLayout>
  );
}

const InfoText = styled(Text)`
    font-size: 20px;
    color: ${(p) => p.theme.backgrounds.default.fg};
`;

const EmailIdText = styled.a`
    text-decoration: none;
    font-size: ${(p) => p.theme.typography.h2.fontSize};
    color: ${(p) => p.theme.backgrounds.default.fg};
`;

const InformationLayout = styled(Layout)`
    grid-template-rows: auto 1fr;
`;

const SendDiagnosisButton = styled(Button)`
    width: 250px;
    margin: 1em 0;
`

const DiagnosticInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 16em;
`

const Line = styled.hr`
    border: 1px solid ${colors.bg_green_400};
    margin: 2em auto;
    width: 60%;
`

const DiagnosticText = styled.p`
    color: ${colors.text_white};
    font-size: 20px;
    margin: 1em 0 0.5em 0;
`
