import * as React from 'react';
import { Tooltip } from 'react-tooltip';

export function IconCytotoxic(props: React.SVGProps<SVGSVGElement>) {
  return (
      <>
        <Tooltip id="IconCytotoxic" />
        <svg
          id="prefix__Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x={0}
          y={0}
          viewBox="0 0 19.1 16.5"
          xmlSpace="preserve"
          data-tooltip-content={props.xlinkTitle ?? "Cytotoxic"}
          data-tooltip-id="IconCytotoxic"
          data-tooltip-place="top"
          {...props}
        >
          <style>{'.prefix__stcyt{fill:#a032e1}'}</style>
          <path
            className="prefix__stcyt"
            d="M11.4 13.6l-.2-1.1c-.4.2-.8.4-1.3.4-.8 0-1.4-.6-1.4-1.7S9 9.5 9.9 9.5c.5 0 .9.2 1.2.4l.2-1.2c-.4-.2-.9-.4-1.5-.4-1.6 0-2.6 1.2-2.6 2.9s1 2.8 2.7 2.8c.5 0 1-.2 1.5-.4z"
          />
          <path className="prefix__stcyt" d="M19.1 16.5H0L9.6 0l9.5 16.5zm-15.6-2h12.2L9.6 4 3.5 14.5z" />
        </svg>
        </>
  );
}
