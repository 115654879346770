import React from 'react';
import { HSDrug, HSRouteType } from 'server-openapi';
import styled from 'styled-components';
import { Button } from '../../../../../kit/Button';
import { Layout } from '../../../../../kit/Layout';
import { Grid } from '../../../../../kit/Grid';
import { Image } from '../../../../../kit/Image';
import { FormGroup } from '../../../../../kit/Forms/FormGroup';
import { TextArea } from '../../../../../kit/Forms/TextArea';
import { Text } from '../../../../../kit/Text';
import { TextInput } from '../../../../../kit/Forms/TextInput';
import { Form } from '../../../../../kit/Forms/Form';
import { useForm } from 'react-hook-form';
import { NimAvailableDrug } from './NimsSettings';
import { apis } from '../../../../../core/mrs/apis';
import { useParams } from 'react-router-dom';
import itiriri from 'itiriri';
import { Select, SelectOption } from '../../../../../kit/Forms/Select';
import { useSyncCenter } from '../../../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../../../core/storage/hooks/UseStore';
import { toasts } from '../../../../../kit/Toasts/Toaster';

interface IProps {
  drug?: HSDrug;
  existingDrugEntry?: NimAvailableDrug;
  onSubmit: (data: INimDrugFormFields) => void;
}

export interface INimDrugFormFields {
  dose: string;
  route: string;
  frequency: string;
  indications: string;
}

interface IParams {
  facilityGroupId: string;
}

// eslint-disable-next-line max-lines-per-function
export function DrugDialogContent(props: IProps) {
  const { facilityGroupId } = useParams<IParams>();

  const existingDrugEntry = props.existingDrugEntry;
  const drug = props.drug ?? existingDrugEntry?.drug;

  const services = useSyncCenter();
  const drugFormsStore = useStore(services.drugForms.store).store;

  const getRouteOptions = (routeTypes: HSRouteType[]) =>
    itiriri(routeTypes)
      .sort((a, b) => a.code!.localeCompare(b.code!))
      .toArray()
      .map((r) => ({ label: `${r.code} (${r.name})`, value: r.code } as SelectOption<string>));

  const drugForms = itiriri(drugFormsStore.values()).toArray();

  const routeTypes = drugForms
    .filter((df) => df.abbreviation === drug?.formAbbreviation)
    .map((df) => df.routeTypes ?? [])
    .flat()
    .filter((rt, index, self) => index === self.findIndex((s) => s.code === rt.code)); // get all available route types without duplicates

  const form = useForm<INimDrugFormFields>({
    defaultValues: {
      dose: existingDrugEntry?.dose ?? '',
      route: existingDrugEntry?.route ?? '',
      frequency: existingDrugEntry?.frequency ?? '',
      indications: existingDrugEntry?.indications ?? '',
    },
  });

  async function onSubmit(data: INimDrugFormFields) {
    if (!existingDrugEntry && drug) {
      await apis.nimAvailableDrugs.nimAvailableDrugCreate({
        facilityGroupId: parseInt(facilityGroupId), // todo can remove this field on server side
        nimAvailableDrug: {
          drugHsId: drug.hsId!,
          dose: data.dose,
          route: data.route,
          frequency: data.frequency,
          indications: data.indications,
          facilityGroupConfigurationId: parseInt(facilityGroupId),
        },
      });

      toasts.success('New entry added successfully.');
    } else if (existingDrugEntry) {
      await apis.nimAvailableDrugs.nimAvailableDrugUpdate({
        facilityGroupId: parseInt(facilityGroupId),
        updatedAvailableDrug: {
          id: existingDrugEntry.id,
          dose: data.dose,
          route: data.route,
          frequency: data.frequency,
          indications: data.indications,
        },
      });

      toasts.success('Entry updated successfully.');
    }

    props.onSubmit(data);
  }

  return (
    <DialogContainer horizontal gap={2}>
      <Layout gap={0.5}>
        {drug?.imageUri && (
          <DrugImageContainer>
            <DrugImage src={drug.imageUri} facilityGroupId={parseInt(facilityGroupId)} />
          </DrugImageContainer>
        )}
        <DrugNameContainer>
          {drug?.name} {drug?.formAbbreviation} {drug?.strength}
        </DrugNameContainer>
      </Layout>
      <Layout gap={0.5}>
        <Form form={form} onSubmit={onSubmit}>
          <Grid gap={1} cols={3}>
            <div>
              <WhiteText size="medium">Dose</WhiteText>
              <FormGroup fullWidth>
                <TextInput name="dose" fullWidth required autoComplete="off" />
              </FormGroup>
            </div>
            <div>
              <WhiteText size="medium">Route</WhiteText>
              <FormGroup fullWidth>
                <Select
                  fullWidth
                  required
                  placeholder="- Select route -"
                  name="route"
                  options={getRouteOptions(routeTypes)}
                />
              </FormGroup>
            </div>
            <div>
              <WhiteText size="medium">Frequency</WhiteText>
              <FormGroup fullWidth>
                <TextInput name="frequency" fullWidth required autoComplete="off" />
              </FormGroup>
            </div>
          </Grid>
          <WhiteText size="medium">Indications</WhiteText>
          <FormGroup fullWidth>
            <TextArea name="indications" rows={3} required fullWidth />
          </FormGroup>
          <Button type="submit" fullWidth>
            {existingDrugEntry ? 'UPDATE' : 'ADD'}
          </Button>
        </Form>
      </Layout>
    </DialogContainer>
  );
}

const DialogContainer = styled(Layout)`
  padding: 1em;
  grid-template-columns: 3fr 5fr;
`;

const DrugImageContainer = styled.div`
  width: 233px;
  height: 162px;
  background-color: ${(p) => p.theme.backgrounds.default.fg};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DrugImage = styled(Image)`
  height: 120px;
`;

const DrugNameContainer = styled.div`
  color: ${(p) => p.theme.backgrounds.default.fg};
  margin-top: 30px;
  font-weight: bold;
  font-size: 32px;
`;

const WhiteText = styled(Text)`
  color: white;
`;
