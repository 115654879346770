import React from 'react';
import { Document, Page } from 'react-pdf';
import { Button } from '../Button';
import { Text } from '../Text';
import IconLeftArrow from '../Icons/IconLeftArrow';
import IconRightArrow from '../Icons/IconRightArrow';
import { Layout } from '../Layout';

interface IProps {
  pdfBlobUrl: string;
  onLoadSuccess?: () => void;
  onClick?: () => void;
}

export function PDFViewer(props: IProps) {
  const {onLoadSuccess, onClick} = props;
  const [numPages, setNumPages] = React.useState<number>(0);
  const [pageNumber, setPageNumber] = React.useState(1);

  function onDocumentLoadSuccess(numPages: number) {
    setNumPages(numPages);
    setPageNumber(1);
    if (onLoadSuccess) onLoadSuccess();
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <Document file={props.pdfBlobUrl} loading={'Loading...'} noData={'Loading PDF data...'} onLoadSuccess={(pdf) => onDocumentLoadSuccess(pdf.numPages)}>
      <Layout align={'center'} padding={'1em'} justify={'center'} horizontal gap={1}>
        <Button disabled={pageNumber <= 1} onClick={previousPage}>
          <IconLeftArrow />
        </Button>
        <Text>{`${pageNumber} of ${numPages}`}</Text>
        <Button disabled={pageNumber >= numPages} onClick={nextPage}>
          <IconRightArrow />
        </Button>
      </Layout>
      <Page height={800} pageNumber={pageNumber} onClick={onClick} />
    </Document>
  );
}

