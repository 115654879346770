/* eslint-disable max-lines-per-function */
import itiriri from 'itiriri';
import React from 'react';
import { HSPatient, HSDrug, HSPackedMedication, HSPackedPrnMedication, HSTestResult } from 'server-openapi';
import styled from 'styled-components';
import { assertNotUndefined } from '../../../../core/utils/assertionUtils';
import { Button } from '../../../../kit/Button';
import { Dialog } from '../../../../kit/Dialog';
import { TextArea } from '../../../../kit/Forms/TextArea';
import { Grid } from '../../../../kit/Grid';
import { Layout } from '../../../../kit/Layout';
import { Text } from '../../../../kit/Text';
import { useSyncCenter } from '../../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../../core/storage/hooks/UseStore';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import { AdministeredDrugTests } from '../administeredDrugTests/AdministeredDrugTests';
import { PatientCompactInfo } from '../PatientCompactInfo';
import { DrugDetailLeftPanel } from '../drugDetails/DrugDetailLeftPanel';
import { MedisphereTestResult } from '../../../../syncstream/SyncTestResults';
import { useForm } from 'react-hook-form';
import { areTestResultsValid, areTestResultsValidNew } from '../MedicineAdministerDialog';
import { Form } from '../../../../kit/Forms/Form';
import { TextInput } from '../../../../kit/Forms/TextInput';

export interface DrugOutcome {
  comment: string;
  testResults: MedisphereTestResult[];
}

interface FormFields {
  comment: string;
  //hack to display error if test result is a required field
  unscheduledMedicationTestResult?: string;
}

interface IProps {
  isVisible: boolean;
  patient: HSPatient;
  drug?: HSDrug;
  packedMedication?: HSPackedMedication | HSPackedPrnMedication;
  administeredDrugClinicalSystemId: string;
  onClose: () => void;
  onAddOutcome: (outcome: DrugOutcome, administeredDrugClinicalSystemId: string) => Promise<void>;
  isAdHoc: boolean;
}

export function DrugOutcomeDialog(props: IProps) {
  const [unscheduledMedicationTestResults, setUnscheduledMedicationTestResults] = React.useState<HSTestResult[]>([]);
  const form = useForm<FormFields>({
    defaultValues: {
      comment: '',
    },
    mode: 'onChange',
  });
  const services = useSyncCenter();
  const testResultTypeStore = useStore(services.testResultTypes.store).store;
  const apiUtils = useApiUtils();

  const facilityStore = useStore(services.facilities.store).store;
  const facility = itiriri(facilityStore.values()).find((facility) => facility.hsId === props.patient.facility);
  const facilityGroupId = apiUtils.patients.findFacilityGroup(props.patient);

  const typeString = props.isAdHoc ? 'NIM' : 'PRN';

  function setError(str: string){
    form.setError('unscheduledMedicationTestResult', {
      type: 'Test Results Invalid',
      message: str,
    });
  }

  React.useEffect(() => {
    form.clearErrors('unscheduledMedicationTestResult');
    if (!areTestResultsValidNew(unscheduledMedicationTestResults, testResultTypeStore, setError)) {
    }
  }, [unscheduledMedicationTestResults]);

  async function onSubmit(data: FormFields) {
    const newOutcome: DrugOutcome = {
      comment: data.comment,
      testResults: unscheduledMedicationTestResults.map((testResult): MedisphereTestResult => {
        return {
          ...testResult,
          patientId: props.patient.hsId,
        };
      }),
    };

    await props.onAddOutcome(newOutcome, props.administeredDrugClinicalSystemId);
    form.clearErrors();
    form.reset();
    setUnscheduledMedicationTestResults([]);
  }

  if (assertNotUndefined(facility)) {
    return (
      <Dialog open={props.isVisible} onRequestClose={() => props.onClose()} size="full" closeButtonColor={'#fff'} lazy>
        <Layout align={'flex-start'} gap={1}>
          <PatientCompactInfo light patient={props.patient} facility={facility!} />
          <DialogContainer horizontal gap={2}>
            <DrugDetailLeftPanel
              drug={props.drug!}
              packedMedication={props.packedMedication}
              facilityGroupId={facilityGroupId}
              patient={props.patient}
            />
            <Grid gap={2}>
              <Form form={form} onSubmit={onSubmit}>
                <Text weight={'bold'} size="large">
                  {typeString} Outcome
                </Text>
                <TextArea
                  rows={5}
                  name="comment"
                  fullWidth
                  placeholder={`Outcome of ${typeString}...`}
                  rules={{
                    validate: (comment: string) => {
                      if (comment.length === 0) {
                        return 'Note required for submitting outcome';
                      }
                      return true;
                    },
                  }}
                  autoFocus
                />

                <Text weight={'bold'} size="large">
                  Patient Tests
                </Text>
                <>
                  <AdministeredDrugTests
                    value={unscheduledMedicationTestResults}
                    onUpdate={(r) => setUnscheduledMedicationTestResults(r)}
                  />
                  <HiddenTextInput name={'unscheduledMedicationTestResult'} />{' '}
                </>

                <Button disabled={!form.formState.isValid} fullWidth type="submit">
                  Add Outcome
                </Button>
              </Form>
            </Grid>
          </DialogContainer>
        </Layout>
      </Dialog>
    );
  }

  return null;
}

const HiddenTextInput = styled(TextInput)`
  height: 0;
  width: 0;
  overflow: hidden;
  position: absolute;
  display: none;
`;

const DialogContainer = styled(Layout)`
  padding: 1em;
  grid-template-columns: 3fr 5fr;
`;
