import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import urljoin from 'url-join';
import { ChartListPage } from './ChartListPage';
import { DashboardPage } from './DashboardPage/DashboardPage';
import { MedicationListPage } from './MedicationListPage/MedicationListPage';
import { OffsiteListPage } from './OffsiteListPage';
import {MissedDosesPage} from "./MissedDosesPage";

export function Dashboard() {
  const { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>
        {/* TODO: Move Narbar here */}
        <Route exact path={urljoin(path, '/facility-group/:facilityGroupId')} component={DashboardPage} />
        <Route
          path={urljoin(path, '/facility-group/:facilityGroupId/dashboard-lists/off-site')}
          component={OffsiteListPage}
        />
        <Route
            path={urljoin(path, '/facility-group/:facilityGroupId/dashboard-lists/missed-doses')}
            component={MissedDosesPage}
        />
        <Route
          path={urljoin(path, '/facility-group/:facilityGroupId/dashboard-lists/medication-list/:administrationType')}
          component={MedicationListPage}
        />
        <Route
          path={urljoin(path, '/facility-group/:facilityGroupId/dashboard-lists/charts')}
          component={ChartListPage}
        />
        <Route path="/" component={DashboardPage} />
      </Switch>
    </React.Fragment>
  );
}
