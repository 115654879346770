import React from 'react';
import { useHealthCheck } from '../../core/healthcheck/HealthCheckProvider';
import WarningTriangleIcon from '../../kit/Icons/WarningTriangle';
import { Layout } from '../../kit/Layout';
import { Text } from '../../kit/Text';
import { colors } from '../../kit/Theme/Theme';

export function OfflineWarning() {
  const isServerHealthy = useHealthCheck().isHealthy;
  if (isServerHealthy) {
    return null;
  }

  return (
    <Layout
      horizontal
      justify={'center'}
      height={'3em'}
      align={'center'}
      style={{ width: '100%', background: colors.bg_purple_300 }}
    >
      <WarningTriangleIcon />
      <Text style={{ color: colors.bg_purple_900 }}>
        You are currently offline, medication changes will not be synced
      </Text>
    </Layout>
  );
}
