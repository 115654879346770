import { useSyncCenter } from '../../SyncCenterProvider';
import { useStore } from '../../../core/storage/hooks/UseStore';
import { FacilityUtils } from '../FacilityUtils';
import { PatientUtils } from '../PatientUtils';
import { ResidentDetailsUtils } from '../ResidentDetailsUtils';
import { RoundUtils } from '../RoundUtils';
import { UserUtils } from '../UserUtils';
import { FacilityGroupUtils } from '../FacilityGroupUtils';

export function useApiUtils() {
  const services = useSyncCenter();
  const stores = {
    packedDayStore: useStore(services.packedPatientDays.store).store,
    patientStore: useStore(services.patients.store).store,
    drugStore: useStore(services.drugs.store).store,
    packedPrnStore: useStore(services.packedPatientPrnMedications.store).store,
    roundStore: useStore(services.rounds.store).store,
    facilityStore: useStore(services.facilities.store).store,
    testResultsStore: useStore(services.testResults.store).store,
    testResultsTypeStore: useStore(services.testResultTypes.store).store,
    usersStore: useStore(services.users.store).store,
    secondCheckStore: useStore(services.secondCheckSettings.store).store,
    nimAvailableDrugStore: useStore(services.nimAvailableDrugs.store).store,
    facilityGroupConfigurationStore: useStore(services.facilityGroupConfigurations.store).store,
    syringeDriverActivityStore: useStore(services.syringeDriverActivity.store).store,
    patchObservationStore: useStore(services.patchObservations.store).store,
  };

  return {
    rounds: new RoundUtils(stores),
    residentDetails: new ResidentDetailsUtils(stores),
    patients: new PatientUtils(stores),
    users: new UserUtils(stores),
    facilities: new FacilityUtils(stores),
    facilityGroups: new FacilityGroupUtils(stores),
  };
}
