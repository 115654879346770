import itiriri from 'itiriri';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Order } from '../../common/Order';
import { DateSelector } from '../../components/dateSelector/DateSelector';
import { PageMenuBar } from '../../components/PageMenuBar';
import { MemorizedPatientClock } from '../../components/PatientClock/PatientClock';
import { Logger } from '../../core/logger/logger';
import {DateUtils, Interval} from '../../core/utils/dateUtils';
import { Grid } from '../../kit/Grid';
import { useSyncCenter } from '../../syncstream/SyncCenterProvider';
import {useMap, useStore} from '../../core/storage/hooks/UseStore';
import { useApiUtils } from '../../syncstream/utils/hooks/useApiUtils';
import {
  OrderNotesDialog,
  OrderNotesProps,
} from '../FacilityManagement/FacilityOrdersPage/Components/OrderNotesDialog';
import {
  OrderResupplyDialog,
  OrderResupplyProps,
} from '../FacilityManagement/FacilityOrdersPage/Components/OrderResupplyDialog';
import { DrugDetailDialog, IDrugDetailProps } from './components/drugDetails/DrugDetailDialog';
import { MedicationListsTabbedRouter } from './components/MedicationListsTabbedRouter/MedicationListsTabbedRouter';
import { ResidentDetailsNavbar } from './components/ResidentDetailsNavbar';
import { ResidentDetailsSidebar } from './components/ResidentDetailsSidebar';
import {useGetScheduleFromParameters} from "../../syncstream/utils/hooks/GetSchedule";
import {endOfDay, startOfDay} from "date-fns";

interface IParams {
  patientId: string;
  roundId?: string;
}

const logger = new Logger('ResidentDetailsPage');

// eslint-disable-next-line sonarjs/cognitive-complexity
// eslint-disable-next-line max-lines-per-function
export function ResidentDetailsPage() {
  const services = useSyncCenter();
  const apiUtils = useApiUtils();
  const patientsStore = useMap(services.patients);
  const roundStore = useMap(services.rounds);
  const orderStore = useMap(services.orders);

  const [selectedDate, setSelectedDate] = useState(new Date());

  const { patientId, roundId } = useParams<IParams>();
  const patient = patientsStore.get(patientId)!;

  const facilityGroupId = apiUtils.patients.findFacilityGroup(patient);
  const facilityGroupsStore = useMap(services.facilityGroups);
  const facilitiesStore = useMap(services.facilities);
  const facilityGroup = facilityGroupsStore.get(facilityGroupId?.toString());

  const facilities = itiriri(facilitiesStore.values())
      .filter((f) => f.facilityGroupId === facilityGroupId)
      .toArray();

  const getScheduleFromParameters = useGetScheduleFromParameters();

  const windowForToday: Interval = {
    start: DateUtils.fromDate(startOfDay(selectedDate)),
    end: DateUtils.fromDate(endOfDay(selectedDate))
  };
  const scheduleItems = getScheduleFromParameters(windowForToday, facilities.map(x => x.hsId!)).filter(x => x.patient.hsId!.toString() === patientId);

  const round = roundId ? roundStore.get(roundId) : undefined;

  if (!round && roundId) {
    throw new Error('Error: round not found!');
  }

  const [drugDetail, setDrugDetail] = useState<IDrugDetailProps>();
  const [orderResupply, setOrderResupply] = useState<OrderResupplyProps>();
  const [orderNotes, setOrderNotes] = useState<OrderNotesProps>();

  if (!patient || !patient.patientProfiles) {
    throw new Error('Error: patient not found!');
  }
  const routes = [
    { route: 'continuous', label: 'CONTINUOUS' },
    { route: 'prns', label: 'PRNS' },
    { route: 'nims', label: 'NIMS' },
    { route: 'syringe-drivers', label: 'SYRINGE DRIVERS' },
    { route: 'patches', label: 'PATCHES' },
    { route: 'short-course', label: 'SHORT COURSE' },
    {
      route: 'emims',
      label: 'EMIMS',
      externalUrl: 'https://www.emims.com.au/Australia/membership/index/',
    },
  ];

  return (
    <ResidentDetailPageWrapper>
      <ResidentDetailsNavbar patient={patient} roundId={roundId} />
      <PageMenuBar menuRoutes={routes} />
      <ResidentDetailsSidebar patient={patient} openOrderResupply={setOrderResupply} openOrderNotes={setOrderNotes} />
      <MedicationListContainer colsTemplate={'4fr 3fr'} cols={2} gap={2}>
        <MedicationListsTabbedRouter
          patient={patient}
          round={round}
          facilityGroupId={facilityGroupId}
          date={selectedDate}
          openDrugDetail={setDrugDetail}
          roundId={roundId}
        />
        <ClockElementsContainer>
          <DateSelector selectedDate={selectedDate} onChange={(date: Date) => setSelectedDate(date)} />
          <MemorizedPatientClock
            selectedDate={selectedDate}
            showTimes
            disableLink
            patientId={patient.hsId!}
            activeRound={round}
            size={'50%'}
            scheduleItems={scheduleItems}
          />
        </ClockElementsContainer>
      </MedicationListContainer>

      {drugDetail && (
        <DrugDetailDialog
          {...drugDetail}
          facilityGroupId={facilityGroupId}
          openOrderResupply={setOrderResupply}
          openOrderNotes={setOrderNotes}
          isOpen={true}
          setClosed={() => setDrugDetail(undefined)}
          findRecentOrder={(hsPatientId: number, hsDrugCode: string): Order | undefined => {
            const order =
              itiriri(orderStore.values())
                .filter((order) => order.hsPatientId === hsPatientId && order.hsDrugCode === hsDrugCode)
                .sort((lhs, rhs) =>
                  DateUtils.compareDatesDescending(
                    DateUtils.ensureDate(lhs.dispatchedAt) ?? DateUtils.ensureDate(lhs.orderedAt)!,
                    DateUtils.ensureDate(rhs.dispatchedAt) ?? DateUtils.ensureDate(rhs.orderedAt)!,
                  ),
                )
                .toArray()[0] ?? undefined;
            // logger.info(`Find Order ${hsPatientId} ${hsDrugCode} : ${order?.id}`);
            return order;
          }}
        />
      )}

      {orderResupply && (
        <OrderResupplyDialog {...orderResupply} isOpen={true} setClosed={() => setOrderResupply(undefined)} />
      )}

      {orderNotes && <OrderNotesDialog {...orderNotes} isOpen={true} setClosed={() => setOrderNotes(undefined)} />}
    </ResidentDetailPageWrapper>
  );
}

const MedicationListContainer = styled(Grid)`
  padding: 2rem;
  align-items: flex-start;
  flex: 1;
  overflow: hidden;
`;
const ClockElementsContainer = styled.div`
  justify-self: center;
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  gap: 5rem;
`;

const ResidentDetailPageWrapper = styled.div`
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
