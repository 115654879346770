import itiriri from 'itiriri';
import React, {useState} from 'react';
import styled from 'styled-components';
import { CsvParser } from '../../../../components/CsvParser';
import { ValidationMessages } from '../../../../core/utils/validationMessages';
import { createDataSource, DataTable } from '../../../../kit/DataTable/DataTable';
import { Layout } from '../../../../kit/Layout';
import { OnboardingPageActions } from '../OnboardingPage';
import { NextButton, PageNavigationButtonContainer, PreviousButton } from './NavigationButtonContainer';
import { Text } from '../../../../kit/Text';
import { apis } from '../../../../core/mrs/apis';
import { useAsync } from '../../../../kit/hooks/UseAsync';
import {RoleDto, LoginType, UserOnboardingReq} from 'server-openapi';
import {ImportUsersDialogContent} from "../../FacilitySettings/components/Users/ImportUsersDialogContent";
import {ImportUsersDetail} from "../../FacilitySettings/components/Users/ImportUsersDetail";
import {Button, Variation} from "../../../../kit/Button";
import {Intent} from "../../../../kit/Theme/Theme";
import {Dialog} from "../../../../kit/Dialog";

interface IProps extends OnboardingPageActions {
  selectedFacilityGroupId: number;
}

export function InviteFacilityUsersPanel(props: IProps) {
  const [importUsers, setImportUsers] = useState<UserOnboardingReq[]>([]);
  const [openImportUsersDialog, setOpenImportUsersDialog] = useState(false);
  const roles =
    useAsync(async () => {
      return (await apis.roles.roleGetAll(props.selectedFacilityGroupId)).data;
    }).value ?? [];

function usersSelected(usersToImport: UserOnboardingReq[]) {
    setImportUsers(usersToImport);
    setOpenImportUsersDialog(false);
}
  function updateCompleted() {
        setImportUsers([]);
  }

  return (
    <>
        <Dialog
            lazy
            open={openImportUsersDialog}
            onRequestClose={() => setOpenImportUsersDialog(false)}
            size="sm"
            secondary
        >

        <ImportUsersDialogContent
            facilityGroupId={props.selectedFacilityGroupId}
            roles={roles}
            usersSelected={usersSelected} />
        </Dialog>
        {
            (importUsers.length > 0) ?
                <ImportUsersDetail usersToImport={importUsers} facilityGroupId={props.selectedFacilityGroupId} roles={roles} updateCompleted={updateCompleted}></ImportUsersDetail>
                :
                <Layout horizontal gap={1} justify="right">
                    <Button onClick={() => setOpenImportUsersDialog(true)} intent={Intent.Secondary} variation={Variation.outline}>
                        IMPORT CSV
                    </Button>
                </Layout>

        }
    </>
  );

}

const ButtonActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;
