import React, {useContext, useEffect, useReducer, useState} from "react";
import {
    DrugTypeFilters,
    FilterDrugTypeControlled,
    FilterDrugTypeOther,
} from './DashboardPage/DashboardPage';
import { ActionMeta } from 'react-select';

interface DashboardState {
    filterDrugTypeControlled: FilterDrugTypeControlled | undefined;
    filterDrugTypeOther: FilterDrugTypeOther | undefined;
    filterWards: string[] | undefined;
}

// This function fixes any persistent state from a previous version
interface NoopAction {
    type: "NOOP",
    payload?: any;
}

interface SetDrugTypeControlledFilter {
    type: "SET_CONTROLLED_DRUG_TYPE_FILTER",
    payload: FilterDrugTypeControlled | undefined;
}
interface SetDrugTypeOtherFilter {
    type: "SET_OTHER_DRUG_TYPE_FILTER",
    payload: FilterDrugTypeOther | undefined;
}

interface SetWardFilter {
    type: "SET_WARD_FILTER",
    payload: string[] | undefined;
}
interface Reset {
    type: "RESET"
}

type Action = NoopAction | SetDrugTypeControlledFilter | SetDrugTypeOtherFilter | SetWardFilter | Reset;

function reducer(state: DashboardState, action: Action): DashboardState {
    switch (action.type) {
        case "SET_CONTROLLED_DRUG_TYPE_FILTER":
           state = {
               ...state,
               filterDrugTypeControlled: action.payload
           };
           break;

        case "SET_OTHER_DRUG_TYPE_FILTER":
            state = {
                ...state,
                filterDrugTypeOther: action.payload
            };
            break;

        case "SET_WARD_FILTER":
            state = {
                ...state,
                filterWards: action.payload
            };
            break;

        case "RESET":
            state = {
                filterDrugTypeControlled: undefined,
                filterDrugTypeOther: undefined,
                filterWards: undefined
            };

        case "NOOP":
        default:
            break;
    }
    return state;
};

export type DashboardStateDispatchFunc = (action: Action) => void;
export type DashboardStore = [DashboardState, DashboardStateDispatchFunc];
// eslint-disable-next-line @typescript-eslint/no-empty-function
const defaultStore: DashboardStore = [{filterWards: undefined, filterDrugTypeControlled: undefined, filterDrugTypeOther: undefined}, () => {
}];
const Context = React.createContext<DashboardStore>(defaultStore);

export function useDashboardStore(): DashboardStore {
    return useContext(Context);
}


export function useDashboardState(): DashboardState {
    return useContext(Context)[0];
}

interface DashboardStateProviderProps {
    children?: React.ReactNode;
}

export const DashboardStateProvider: React.FC<DashboardStateProviderProps> = props => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [state, setState] = useState<DashboardState>({filterWards: undefined, filterDrugTypeControlled: undefined, filterDrugTypeOther: undefined});
    const store: DashboardStore = useReducer(reducer, state);

    return (
        <Context.Provider value={store}>
            {props.children}
        </Context.Provider>
    );
};

export function useResetDashboardContext() {
    const [, dispatch] = useDashboardStore();
    useEffect(() => {
        dispatch({
            type: "RESET"
        })
    }, []);
}
