export interface IGradient {
  light: string;
  dark: string;
}

export interface IClockOutlineStyle {
  svgColor: string;
  gradient: IGradient;
}
//source - https://stackoverflow.com/questions/41179474/use-object-literal-as-typescript-enum-values
export class ClockOutlineStyle {
  static readonly WITHHELD = new ClockOutlineStyle('WITHHELD', {
    svgColor: '#a87f03',
    gradient: { light: '#ffcc33', dark: '#ffb347' },
  });

  static readonly REFUSED = new ClockOutlineStyle('REFUSED', {
    svgColor: '#ab0519',
    gradient: { light: '#ED213A', dark: '#93291E' },
  });

  static readonly DEFAULT = new ClockOutlineStyle('DEFAULT', {
    svgColor: '#AE48FB',
    gradient: { light: '#c35cff', dark: '#8600ec' },
  });

  static readonly ADMINISTERED = new ClockOutlineStyle('ADMINISTERED', {
    svgColor: '#3c6609',
    gradient: { light: '#a8e063', dark: '#56ab2f' },
  });
  static readonly ADMINISTERED_LATE = new ClockOutlineStyle('ADMINISTERED_LATE', {
    svgColor: '#404040',
    gradient: { light: '#666666', dark: '#000000' },
  });

  static readonly NOSTOCK = new ClockOutlineStyle('NOSTOCK', {
    svgColor: '#b86e04',
    gradient: { light: '#F09819', dark: '#FF512F' },
  });

  static readonly ABSENT = new ClockOutlineStyle('ABSENT', {
    svgColor: '#3b8a61',
    gradient: { light: '#FAFFD1', dark: '#A1FFCE' },
  });

  static readonly REDOSED = new ClockOutlineStyle('REDOSED', {
    svgColor: '#00151A',
    gradient: { light: '#006A80', dark: '#00404D' },
  });
  private constructor(private readonly key: string, public readonly value: IClockOutlineStyle) {}

  toString() {
    return this.key;
  }
}
