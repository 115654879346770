import React from 'react';
import {FieldValues, FormProvider, SubmitHandler, UseFormMethods} from 'react-hook-form';
import styled from 'styled-components';
import { DotPath } from './DotPath';

export interface FormProps<T extends FieldValues> {
  form: UseFormMethods<T>;
  onSubmit: SubmitHandler<T>;
  children: React.ReactNode;
  className?: string;
}

export function Form<T extends FieldValues>(props: FormProps<T>) {
  const { form, onSubmit, ...formProps } = props;
  // We're using react-hook-form for all validation so we'll
  // disable html5 validation because it'll conflict with
  // props like "required"
  //
  // The form provider makes the form methods available to all
  // formInputs via the `useFormContext()` hook.
  // This is used for any global form features like disabling
  // inputs when submitting or showing field validation errors.
  //
  // We also call `event.stopPropagation()` on the form submission
  // so that nested forms can be validated and submitted without
  // trigguring the parent form's validation/submission.
  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    if (onSubmit) {
      form.handleSubmit(onSubmit)(event);
    }
  };
  return (
    <FormProvider {...form}>
      <StyledForm noValidate {...formProps} onSubmit={onFormSubmit} />
    </FormProvider>
  );
}

const StyledForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 0.75rem;
  text-align: left;
  font-size: 1rem;
`;

// The form library needs to add a generated "id" property
// for all values in a form array field i.e. useFieldArray()
// It's pretty common for API models to also have an "id"
// property which conflicts and makes everything less fun.
// This type should be used to make sure you build forms
// that don't have the conflict.
export type FormArray<T> = Array<{ id: string; value: T }>;

// this is a handy function to improve typesafety of form input name property selection
export function nameof<T>(name: DotPath<T>): DotPath<T> {
  return name;
}
