/* eslint-disable max-lines */
import React, { useState} from 'react';
import { Grid } from '../../../../../kit/Grid';
import {
    HSAdministeredDrug,
    MedicationType,
    ReasonCode,
    PackType,
    HSPackedMedication,
    HSTestResult, GroupPermissionsRes, HSDoseRound,
} from 'server-openapi';
import { MedicationInformationBox } from '../../medicationInformation/MedicationInformationBox';
import {MedicationGroup} from '../../../../../syncstream/utils/ResidentDetailsUtils';
import { SyringeDriversMedicationList } from './SyringeDriversMedicationList';
import { PatchesMedicationList } from './PatchesMedicationList';
import { ShortCoursesMedicationList } from './ShortCoursesMedicationList';
import { Text } from '../../../../../kit/Text';
import styled, { useTheme } from 'styled-components';
import { useForm } from 'react-hook-form';
import { MedicationListProps } from '../MedicationListsTabbedRouter';
import { Button } from '../../../../../kit/Button';
import { useApiUtils } from '../../../../../syncstream/utils/hooks/useApiUtils';
import { Layout } from '../../../../../kit/Layout';
import { useCurrentUser } from '../../../../../core/authn/UserProvider';
import { Dialog } from '../../../../../kit/Dialog';
import { administerableReasonCodes } from '../../MedicineAdministerDialog';
import { Form } from '../../../../../kit/Forms/Form';
import { RadioGroup } from '../../../../../kit/Forms/RadioGroup';
import { FormGroup } from '../../../../../kit/Forms/FormGroup';
import { TextArea } from '../../../../../kit/Forms/TextArea';
import IconDotMenu from '../../../../../kit/Icons/DotMenu';
import { toasts } from '../../../../../kit/Toasts/Toaster';
import {DrugUtils} from '../../../../../syncstream/utils/DrugUtils';
import { EnqueuedDrugCreateData } from '../../../../../syncstream/SyncRounds';
import {useGroupPermissions} from "../../../../../core/authz/PermissionsProvider";

interface Props extends MedicationListProps {
  onAdministerDrug: (
    drug: HSAdministeredDrug,
    doseTimestamp: string,
    testResults?: HSTestResult[],
  ) => Promise<EnqueuedDrugCreateData | undefined>;
  groupedPackedMedicationList: MedicationGroup[];
  patientId: number;
  isToday?: boolean;
  lastTwoPackedPatientDaysMedication: HSPackedMedication[];
}

const canAdministerPacketteIndividually = (medicationList: MedicationGroup, groupPermissions: GroupPermissionsRes, currentRound: HSDoseRound | undefined, isToday: boolean): boolean => {
    if (!currentRound) {
        return false;
    }
    return (medicationList.medications.every((medication) => medication.packType === PackType.Packette) &&
        !groupPermissions.canAdministerPackedMedicationIndividually && currentRound && isToday);
}

export function ContinuousMedicationList(props: Props) {
  const [administerAllLoading, setAdministerAllLoading] = useState(false);

  let sortedGroupedMedications = props.groupedPackedMedicationList
    .map((gm) => {
      return { ...gm, listOrder: getGroupedMedicationPriority(gm) };
    })
    .sort(
      (a, b) =>
        (a.categories.doseDate && b.categories.doseDate
          ? a.categories.doseDate.getTime() - b.categories.doseDate.getTime()
          : 0) || a.listOrder - b.listOrder,
    );

  const apiUtils = useApiUtils();
  const groupPermissions = useGroupPermissions();


  return (
    <Grid cols={1} gap={1}>
      {sortedGroupedMedications.map((medicationGroup, index) => {
        if (medicationGroup.categories.isSyringeDriver)
          return <SyringeDriversMedicationList {...props} groupedPackedMedicationList={[medicationGroup]} key={index} />;
        if (medicationGroup.categories.isPatch)
          return <PatchesMedicationList {...props} groupedPackedMedicationList={[medicationGroup]} key={index} />;
        if (medicationGroup.categories.medicationType === MedicationType.ShortCourse)
          return <ShortCoursesMedicationList {...props} groupedPackedMedicationList={[medicationGroup]} key={index} />;

        return (
          <Grid cols={1} gap={0.5} key={index}>
            <Layout align="center" justify="space-between" horizontal>
              <CategoryHeading weight={'bold'}>
                {apiUtils.residentDetails.getMedicationHeader(medicationGroup)}
              </CategoryHeading>
              {medicationGroup.medications.every((medication) => medication.packType === PackType.Packette) &&
                props.currentRound &&
                props.isToday && (
                  <AdministerAllButton
                    {...props}
                    groupPermissions={groupPermissions}
                    groupedPackedMedicationList={[medicationGroup]}
                    loading={administerAllLoading}
                    setLoading={setAdministerAllLoading}
                  />
                )}
            </Layout>
            {medicationGroup.medications.map((packedMedication) => (
              <MedicationInformationBox
                previousDosedDrug={apiUtils.residentDetails.getDosedDrugForPreviousDoseTime(props.patient, packedMedication)}
                key={packedMedication.hsId}
                packedMedication={packedMedication}
                currentDosedDrug={apiUtils.rounds.getMostRecentDrugInRoundByPackedMedication(
                  packedMedication,
                  props.patient.hsId!,
                  props.currentRound,
                )}
                onAdminister={props.onAdministerDrug}
                status={apiUtils.rounds.getPackedMedicationStatus(packedMedication, props.patientId)}
                scheduledTime={packedMedication.doseTimestamp}
                disableAdminister={administerAllLoading}
                isAdministerBtnDisabled={canAdministerPacketteIndividually(medicationGroup, groupPermissions, props.currentRound, props.isToday ?? false)}
                {...props}
              />
            ))}
          </Grid>
        );
      })}
    </Grid>
  );
}

function getGroupedMedicationPriority(groupedMedication: MedicationGroup) {
  if (groupedMedication.categories.isSyringeDriver) return 5;
  if (groupedMedication.categories.isPatch) return 4;
  if (groupedMedication.categories.medicationType === MedicationType.ShortCourse) return 3;
  if (
    groupedMedication.categories.medicationType === MedicationType.Regular &&
    !groupedMedication.medications.every((medication) => medication.packType === PackType.Packette)
  )
    return 2;

  return 1;
}

interface AdministerAllProps extends Props {
  groupPermissions: GroupPermissionsRes;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

function AdministerAllButton(props: AdministerAllProps) {
  const apiUtils = useApiUtils();
  const user = useCurrentUser();
  const canRecordNote = props.groupPermissions.canRecordNote;
  const unAdministeredRoundItems =
    props.roundSchedule
      ?.filter((item) => item.packedMedication.packType === PackType.Packette)
      ?.filter((item) =>
        props.groupedPackedMedicationList.some((group) =>
          group.medications.some((medication) => medication.hsId === item.packedMedication.hsId),
        ),
      )
      .filter(
        (item) =>
          !props.currentRound?.administeredDoses?.some((dose) =>
            dose.administeredDrugs?.some(
              (drug) =>
                drug.medicationId === item.packedMedication.medicationId &&
                dose.doseTimestamp === item.packedMedication.doseTimestamp,
            ),
          ),
      ) ?? [];

  const cantAdministerAll =
    props.loading ||
    unAdministeredRoundItems?.length === 0 ||
    unAdministeredRoundItems.some((item) => {
      const drug = props.drugList?.find((drug) => drug.hsId === item.packedMedication?.drugHsId);
      const administrationWarnings = DrugUtils.getAdministrationWarnings(
        drug,
        item.packedMedication,
        apiUtils.patients.getHSMedicationFromPackedMedication(props.patient, item.packedMedication),
      );
      return (
        props.secondCheckData?.secondCheckableMedication.some(
          (med) => item.packedMedication.hsId === med.id && !med.userName,
        ) ||
        administrationWarnings.inrTestRequired ||
        administrationWarnings.bglTestRequired ||
        administrationWarnings.isCeasedMedication
      );
    });

  async function administerAll(reasonCode?: ReasonCode, comment?: string) {
    if (comment && !canRecordNote) {
      toasts.error('You do not have permission to record notes');
      return;
    }
    props.setLoading(true);
    for (const item of unAdministeredRoundItems) {
      const drug = props.drugList?.find((drug) => drug.hsId === item.packedMedication?.drugHsId);
      const confirmationUser = props.secondCheckData?.secondCheckableMedication.find(
        (med) => med.id === item.packedMedication.hsId,
      );
      await props.onAdministerDrug(
        apiUtils.residentDetails.generateAdministeredDrug(
          item.packedMedication,
          drug!,
          reasonCode ?? ReasonCode.Dosed,
          comment ? [comment] : [],
          user,
          confirmationUser?.userName,
          confirmationUser?.hsId,
        ),
        item.packedMedication.doseTimestamp ?? '',
      );
    }
    props.setLoading(false);
  }

  const canAdministerIndividually = props.groupPermissions.canAdministerPackedMedicationIndividually;

  return (
    <ActionsLayout>
      <Button onClick={() => administerAll()} disabled={cantAdministerAll}>
        {props.loading ? 'Loading' : 'Administer All'}
      </Button>
      {!cantAdministerAll && canAdministerIndividually &&
        <AdministerAllDialog {...props} onSubmit={async (status, comments) => await administerAll(status, comments)} />
      }
    </ActionsLayout>
  );
}

interface MedicationAdministerProps extends Props {
  groupPermissions: GroupPermissionsRes;
  onSubmit: (status: ReasonCode, comment?: string) => Promise<void>;
}

function AdministerAllDialog(props: MedicationAdministerProps) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  return (
    <>
      <IconDotMenu
        color={theme.backgrounds.default.fg}
        opacity={1}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setOpen(true);
        }}
      />
      <Dialog lazy closeButtonColor={'#fff'} open={open} onRequestClose={() => setOpen(false)} size="full">
        <AdministerAllForm
          {...props}
          onSubmit={async (status, comment) => {
            await props.onSubmit(status, comment);
            setOpen(false);
          }}
        />
      </Dialog>
    </>
  );
}

interface FormFields {
  status: ReasonCode;
  notes?: string;
}

function AdministerAllForm(props: MedicationAdministerProps) {
  const apiUtils = useApiUtils();

  const form = useForm<FormFields>();

  const canMarkSelfAdminister = props.groupPermissions.canMarkResidentMedicationAsSelfAdministered;

  const statusOptions = administerableReasonCodes
    .filter((status) => canMarkSelfAdminister || status !== ReasonCode.SelfAdministered)
    .map((status) => {
      return { label: apiUtils.residentDetails.reasonCodeToString(status), value: status };
    });

  function onSubmit(data: FormFields) {
    props.onSubmit(data.status, data.notes);
  }

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Layout gap={1}>
        <StatusSection gap={1}>
          <Text size="large">Update status</Text>
          <RadioGroup name="status" options={statusOptions} cols={3} control={form.control} required />
        </StatusSection>
        <FormGroup fullWidth>
          <TextArea
            name="notes"
            placeholder="Add note"
            rows={3}
            fullWidth
            rules={{
              validate: (notes: string) => {
                if (
                  form.watch('status') !== ReasonCode.SelfAdministered &&
                  form.watch('status') !== ReasonCode.Dosed &&
                    (notes?.trim() ?? "").length === 0
                ) {
                  return 'Notes required if not Self Administered or Dosed';
                }
                return true;
              },
            }}
            data-testid="medication-notes"
          />
        </FormGroup>
        <Button disabled={form.formState.isSubmitSuccessful} type="submit" fullWidth>
          UPDATE STATUS
        </Button>
      </Layout>
    </Form>
  );
}

const StatusSection = styled(Layout)`
  color: white;
`;

const ActionsLayout = styled(Layout)`
  display: flex;
  margin-left: auto;
  align-items: center;
  gap: 0.5rem;
  color: ${(props) => props.theme.backgrounds.default.fg};
`;

export const CategoryHeading = styled(Text)`
  color: white;
`;
