import {
  HSDrug,
  HSPackedMedication,
  HSPackedPatientDay,
  HSPackedPrnMedication,
  HSPatient,
  MedicationStatus,
} from 'server-openapi';
import { DateUtils } from '../../core/utils/dateUtils';
import itiriri from 'itiriri';
import { differenceInDays, startOfDay } from 'date-fns';
import { PatchUtils } from '../../pages/ResidentDetails/components/patches/PatchUtils';

interface IMedDose {
  doseTimestamp: string;
  doseQuantityNew: number;
  doseQuantityOld?: number;
}

export interface IPackedMedChange {
  patientId: number;
  medicationId: number;
  doseDate: Date;
  dosesByDoseTimestamp: Map<string, IMedDose>;
  directionsNew: string;
  directionsOld?: string;
}

interface IPackedPatientMed {
  patientId: number;
  doseDate: Date;
  packedMedication: HSPackedMedication;
}

function buildPackedMedChange(pm: IPackedPatientMed, doseTimestamp: string): IPackedMedChange {
  const dose: IMedDose = {
    doseTimestamp: doseTimestamp,
    doseQuantityNew: pm.packedMedication.interimDeleted ? 0 : pm.packedMedication.dosage ?? 0,
  };

  const doseMap = new Map();
  doseMap.set(doseTimestamp, dose);

  return {
    patientId: pm.patientId!,
    doseDate: pm.doseDate,
    medicationId: pm.packedMedication.medicationId!,
    dosesByDoseTimestamp: doseMap,
    directionsNew: pm.packedMedication.directions ?? '',
  };
}

function buildPackedMedChanges(packedInterimMeds: IPackedPatientMed[]) {
  const allInterim: Map<number, IPackedMedChange> = new Map();

  for (const pm of packedInterimMeds) {
    const doseTimestamp = pm.packedMedication.doseTimestamp
      ? DateUtils.dateTo24HourTimeString(new Date(pm.packedMedication.doseTimestamp))
      : '';

    const med = allInterim.get(pm.packedMedication.medicationId!);
    if (!med || pm.doseDate > med.doseDate) {
      const newMed = buildPackedMedChange(pm, doseTimestamp);

      allInterim.set(pm.packedMedication.medicationId!, newMed);
    } else {
      med.dosesByDoseTimestamp.set(doseTimestamp, {
        doseTimestamp: doseTimestamp,
        doseQuantityNew: pm.packedMedication.dosage ?? 0,
      });
    }
  }

  return allInterim;
}

function getPackedToday(packedDaysStore: ReadonlyMap<string, HSPackedPatientDay>, patientIds: number[]) {
  return itiriri(packedDaysStore.values())
    .filter((pd) =>
      pd.packDate
        ? patientIds.includes(pd.patientId!) &&
          differenceInDays(startOfDay(new Date()), DateUtils.toOffsetlessDate(pd.packDate)) === 0
        : false,
    )
    .flat((pd) =>
      (pd.packedMedications ?? [])
        .filter((pm) => pm.interim || pm.interimDeleted)
        .map((pm): IPackedPatientMed => {
          return {
            patientId: pd.patientId!,
            doseDate: DateUtils.toOffsetlessDate(pd.packDate!),
            packedMedication: pm,
          };
        }),
    )
    .toArray();
}

function getPackedPrev(
  packedDaysStore: ReadonlyMap<string, HSPackedPatientDay>,
  packedToday: IPackedPatientMed[],
  packedMedToday: Map<number, IPackedMedChange>,
) {
  return itiriri(packedDaysStore.values())
    .filter((pd) =>
      pd.packDate
        ? !!packedToday.find((pi) => pi.patientId === pd.patientId) &&
          DateUtils.toOffsetlessDate(pd.packDate) < startOfDay(new Date())
        : false,
    )
    .flat((pd) =>
      (pd.packedMedications ?? [])
        .filter((pm) => packedMedToday.has(pm.medicationId!))
        .map((pm): IPackedPatientMed => {
          return { patientId: pd.patientId!, doseDate: DateUtils.toOffsetlessDate(pd.packDate!), packedMedication: pm };
        }),
    )
    .toArray();
}

function buildPackedDoseChanges(packedMedToday: IPackedMedChange, packedMedPrev: IPackedMedChange) {
  const doseChanges = new Map();

  for (const packedDoseToday of packedMedToday.dosesByDoseTimestamp.values()) {
    const packedDosePrev = packedMedPrev.dosesByDoseTimestamp.get(packedDoseToday.doseTimestamp);
    if (!packedDosePrev) {
      // Dose Added
      doseChanges.set(packedDoseToday.doseTimestamp, packedDoseToday);
    } else if (packedDoseToday.doseQuantityNew !== packedDosePrev.doseQuantityNew) {
      // Dose Changed
      doseChanges.set(packedDoseToday.doseTimestamp, {
        doseTimestamp: packedDoseToday.doseTimestamp,
        doseQuantityNew: packedDoseToday.doseQuantityNew,
        doseQuantityOld: packedDosePrev.doseQuantityNew,
      });
    }
  }

  for (const packedDosePrev of packedMedPrev.dosesByDoseTimestamp.values()) {
    const packedDoseToday = packedMedToday.dosesByDoseTimestamp.get(packedDosePrev.doseTimestamp);
    if (!packedDoseToday) {
      // Dose Removed
      doseChanges.set(packedDosePrev.doseTimestamp, {
        doseTimestamp: packedDosePrev.doseTimestamp,
        doseQuantityNew: 0,
        doseQuantityOld: packedDosePrev.doseQuantityNew,
      });
    }
  }
  return doseChanges;
}

function buildPackedChange(
  packedMedsPrev: Map<number, IPackedMedChange>,
  packedMedToday: IPackedMedChange,
): IPackedMedChange | undefined {
  const packedMedPrev = packedMedsPrev.get(packedMedToday.medicationId);
  if (!packedMedPrev) {
    return packedMedToday;
  } else {
    const directionsChanged = packedMedToday.directionsNew !== packedMedPrev.directionsNew;
    const doseChanges = buildPackedDoseChanges(packedMedToday, packedMedPrev);

    if (directionsChanged || doseChanges.size > 0) {
      return {
        patientId: packedMedToday.patientId,
        medicationId: packedMedToday.medicationId,
        doseDate: packedMedToday.doseDate,
        dosesByDoseTimestamp: doseChanges,
        directionsNew: packedMedToday.directionsNew,
        directionsOld: directionsChanged ? packedMedPrev.directionsNew : undefined,
      };
    }
  }

  return undefined;
}

function buildPackedChanges(
  packedMedsToday: Map<number, IPackedMedChange>,
  packedMedsPrev: Map<number, IPackedMedChange>,
) {
  const packedChanges: Map<number, IPackedMedChange> = new Map();

  for (const packedMedToday of packedMedsToday.values()) {
    const packedChange = buildPackedChange(packedMedsPrev, packedMedToday);
    if (packedChange) {
      packedChanges.set(packedMedToday.medicationId, packedChange);
    }
  }
  return packedChanges;
}

export function gatherChartChangesByPatientId(
  patientIds: number[],
  packedDaysStore: ReadonlyMap<string, HSPackedPatientDay>,
): Map<number, IPackedMedChange[]> {
  const packedToday = getPackedToday(packedDaysStore, patientIds);
  const packedMedsToday = buildPackedMedChanges(packedToday);
  const packedPrev = getPackedPrev(packedDaysStore, packedToday, packedMedsToday);
  const packedMedsPrev = buildPackedMedChanges(packedPrev);
  const changes = buildPackedChanges(packedMedsToday, packedMedsPrev);

  const patientChanges = new Map();
  for (const change of changes.values()) {
    const patientChange = patientChanges.get(change.patientId);
    if (!patientChange) {
      patientChanges.set(change.patientId, [change]);
    } else {
      patientChange.push(change);
    }
  }

  return patientChanges;
}

export function isMedicationActive(packedMedication: HSPackedMedication) {
  return packedMedication.medicationStatus !== MedicationStatus.Deleted && !packedMedication.interimDeleted;
}

export function checkIfMedicationIsSelfAdministered(
  packedMedication: HSPackedMedication | HSPackedPrnMedication,
  patient: HSPatient,
): boolean {
  //find the HSMedication from patient profile
  const patientMedication = patient.patientProfiles
    ?.flatMap((profile) => profile.allCurrentMedications)
    .find((medication) => packedMedication.medicationId === medication?.hsId);

  return patientMedication?.selfAdministered ?? false;
}
export function isPatchRemovalPackedMed(med: HSPackedMedication, drugStore: ReadonlyMap<string, HSDrug>) {
  if (!med.drugHsId) {
    return false;
  }
  const drug = drugStore.get(med.drugHsId.toString());
  return drug ? PatchUtils.isPatchRemoval(drug) : false;
}
