import itiriri from 'itiriri';
import React, { useEffect } from 'react';
import { HSTestResultEntry, HSTestResultFieldType, HSTestResultType } from 'server-openapi';
import { DateInput } from '../../../../kit/Forms/DateInput';
import { Select } from '../../../../kit/Forms/Select';
import { TextInput } from '../../../../kit/Forms/TextInput';
import { Grid } from '../../../../kit/Grid';
import { useSyncCenter } from '../../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../../core/storage/hooks/UseStore';
import { IconClose } from '../../../../kit/Icons/Close';
import styled from 'styled-components';
import { Layout } from '../../../../kit/Layout';

export interface IAdministeredDrugTestFormItem {
  fields: Array<HSTestResultEntry>;
  typeId: string;
  timestamp: Date;
}

export const AdministeredDrugTestForm = (props: {
  answers: Partial<IAdministeredDrugTestFormItem>;
  onUpdate: (answers: Partial<IAdministeredDrugTestFormItem>) => void;
  onDelete: (clinicalSystemId: string) => void;
  index: number;
  clinicalSystemIdentifier: string;
}) => {
  const services = useSyncCenter();
  const testResultTypeStore = useStore(services.testResultTypes.store).store;
  const testResultTypes = itiriri(testResultTypeStore.values())
    .sort((a, b) => (a.hsId ?? 0) - (b.hsId ?? 0))
    .toArray();
  const testResultType = testResultTypes.find((type) => type.hsId?.toString() === props.answers.typeId);
  useEffect(() => {
    if (testResultType?.testResultFields) {
      props.onUpdate({
        ...props.answers,
        fields: new Array(testResultType.testResultFields.length).fill(''),
      });
    } else {
      props.onUpdate({
        ...props.answers,
        fields: [],
      });
    }
  }, [testResultType]);

  return (
    <Grid alignItemsCenter cols={3}>
      <Select
        placeholder="Test Type"
        fullWidth
        name={`${props.clinicalSystemIdentifier}-${props.index}-test`}
        options={testResultTypes.map((t) => ({
          label: t.name!,
          value: t.hsId!.toString(),
        }))}
        value={props.answers.typeId}
        onChange={(_, typeId) => {
          props.answers.fields = [];
          props.onUpdate({
            ...props.answers,
            typeId: typeId,
          });
        }}
      />

      <DateInput
        dateTime
        name={`${props.index}-date`}
        fullWidth
        value={props.answers.timestamp}
        onChange={(_, v) => {
          const timeStamp = v instanceof Date && !isNaN(v.valueOf()) ? v : undefined;
          props.onUpdate({
            ...props.answers,
            timestamp: timeStamp,
          });
        }}
      />

      {testResultType?.testResultFields!.map((testResultField, index) => {
        return (
          <TestResultFieldInputs
            key={props.index + '-test-result-type' + testResultField.hsId + '-field-' + index}
            {...props}
            testResultField={testResultField}
            index={index}
            parentKey={props.index + '-field-' + index}
            testResultType={testResultType!}
            answers={props.answers}
            onDelete={() => {
              props.onDelete(props.clinicalSystemIdentifier);
            }}
          />
        );
      })}
      {props.answers.typeId === undefined && (
        <CloseButton type="button" onClick={() => props.onDelete(props.clinicalSystemIdentifier)}>
          <IconClose height={10} />
        </CloseButton>
      )}
    </Grid>
  );
};

function TestResultFieldInputs(props: {
  answers: Partial<IAdministeredDrugTestFormItem>;
  onUpdate: (answers: Partial<IAdministeredDrugTestFormItem>) => void;
  onDelete: () => void;
  testResultField: HSTestResultFieldType;
  index: number;
  testResultType: HSTestResultType;
  parentKey: string;
}) {
  const { testResultField, index, testResultType } = props;
  const placeholder = testResultField.description ? testResultField.description! : 'Placeholder missing';
  const answer = props.answers.fields?.[index] ? props.answers.fields[index].value : undefined;
  return (
    <Layout align={'center'} key={index} horizontal gap={1}>
      <TextInput
        name={`${props.parentKey}-${props.index}-${index}`}
        fullWidth
        placeholder={placeholder}
        onChange={(_, v) => {
          props.onUpdate({
            ...props.answers,
            fields: props.answers.fields?.map((f, i) => {
              if (i === index) {
                return {
                  key: testResultField.description,
                  value: v,
                };
              }
              return f;
            }),
          });
        }}
        value={answer ? answer : undefined}
        type="number"
      />
      {index === testResultType.testResultFields!.length - 1 && (
        <CloseButton type="button" onClick={() => props.onDelete()}>
          <IconClose height={10} />
        </CloseButton>
      )}
    </Layout>
  );
}

const CloseButton = styled.button`
  display: flex;
  justify-content: flex-start;
  align-item: center;
  width: 1em;
  color: ${(p) => p.theme.backgrounds.default.fg};
  opacity: 0.65;
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 28px;
  z-index: 100;
`;
