import React, { useState } from 'react';
import { HSFacility, HSMedication, HSPatient, PackType } from 'server-openapi';
import { OrderRow } from '../../OrderRow';
import { OrderResupplyDialog, OrderResupplyProps } from '../../OrderResupplyDialog';
import { OrderNotesDialog, OrderNotesProps } from '../../OrderNotesDialog';
import { mapMedicationToView } from '../../OrderViewModel';
import { Order } from '../../../../../../common/Order';
import { Layout } from '../../../../../../kit/Layout';
import { useSyncCenter } from '../../../../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../../../../core/storage/hooks/UseStore';

interface Props {
  facility: HSFacility;
  patient: HSPatient;
  orders: Order[];
}

export function OrderPatientResupply(props: Props) {
  const services = useSyncCenter();
  const drugsStore = useStore(services.drugs.store).store;
  const [orderResupply, setOrderResupply] = useState<OrderResupplyProps>();
  const [orderNotes, setOrderNotes] = useState<OrderNotesProps>();

  const allCurrentMedications: HSMedication[] = (props.patient.patientProfiles ?? [])
    .map((p) => p.allCurrentMedications ?? [])
    .flat()
    .filter((m) => m.packType === PackType.OriginalContainer);

  const findDrug = (hsDrugId: number) => drugsStore.get(hsDrugId.toString())!;
  const findOrder = (hsMedicationId: number) => props.orders.find((order) => order.hsMedicationId === hsMedicationId);
  const mapper = mapMedicationToView(
    findDrug,
    findOrder,
    props.facility.facilityGroupId!,
    props.facility,
    props.patient,
    setOrderResupply,
    setOrderNotes,
  );
  const orders = allCurrentMedications.map(mapper);
  return (
    <Layout>
      {orderResupply && (
        <OrderResupplyDialog {...orderResupply} isOpen={true} setClosed={() => setOrderResupply(undefined)} />
      )}
      {orderNotes && <OrderNotesDialog {...orderNotes} isOpen={true} setClosed={() => setOrderNotes(undefined)} />}
      <Layout gap={1}>{orders.map(OrderRow)}</Layout>
    </Layout>
  );
}
