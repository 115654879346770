import React, {useEffect, useState} from 'react';
import {ArrayField, UseFormMethods, FieldError} from 'react-hook-form';
import {RoleDto} from 'server-openapi';
import {DateInput} from '../../../../kit/Forms/DateInput';
import {FormGroup} from '../../../../kit/Forms/FormGroup';
import {SelectOption, Select} from '../../../../kit/Forms/Select';
import {Grid} from '../../../../kit/Grid';
import {RoleAccessDto, UserDetailsFormValues} from '../UserDetailsPage';

interface IProps {
  field: Partial<ArrayField<RoleAccessDto, 'id'>>;
  index: number;
  form: UseFormMethods<UserDetailsFormValues>;
  roleOptions: SelectOption<RoleDto>[];
  disabled?: boolean;
  roleDisabled?: boolean;
}

export const UserRolesDetails = (props: IProps) => {
  const {field, index, form, roleOptions, disabled, roleDisabled} = props;
  const [isCreatedWithinThirtyMinutesAgo, setIsCreatedWithinThirtyMinutesAgo] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [expirydate, setExpiryDate] = useState<Date>();

  const addFiveMinuteToCurrentTime = (): Date => {
    const currentTime = new Date();
    currentTime.setMinutes(currentTime.getMinutes() + 5);
    return currentTime;
  }

  const checkStartDate = () => {
    const thirtyMinutesAgo = Date.now() - (1000 * 30 * 60);

    if (field.startDate && field.startDate.getTime() < thirtyMinutesAgo) {
      setIsCreatedWithinThirtyMinutesAgo(true);
    }
  }

  const checkExpiryDate = () => {
    const todayDate = new Date();

    if (field.expiryDate && field.expiryDate < todayDate) {
      setIsDisabled(true);
    }
  }

  const validateStartDate = (startDate: Date | undefined) => {
    const startTime = startDate?.getTime();
    const todayDate = new Date();
    const todayTime = todayDate.getTime();
    // when adding new role, start time is automatically set to todayDate/Time () hence causing validation error. Here we give 10 minutes window for an error-free role assignment
    const tenMinutesAgo = Date.now() - (1000 * 10 * 60);

    // Don't validate if soft-deleted.
    const softDelete = form.getValues().roleAccess[index].softDelete;
    if (softDelete) {
      return;
    }

    if (!startDate) {
      return 'Start date is required';
    }
    const currentStart = form.getValues().roleAccess[index].startDate;

    if (!props.field.isNew && currentStart.getTime() < tenMinutesAgo && startTime != currentStart.getTime()) {
      return "You cannot alter the start date of a current role assignment."
    }
    if (startTime && startDate && startDate < todayDate && startTime < todayTime && startTime < tenMinutesAgo && props.field.isNew) { // it only validates the newly added role. Old roles/start dates won't be validated.
      return 'Start date cannot be earlier than today\'s date';
    }
  };

  const validateExpiryDate = (expiryDate: Date | undefined) => {
    const todayDate = new Date();
    const todayTime = todayDate.getTime();
    const currentExpiryDate = form.getValues().roleAccess[index].expiryDate;
    const currentExpiryTime = currentExpiryDate ? currentExpiryDate.getTime() : null;
    const expiryTime = expiryDate ? expiryDate?.getTime() : null;
    const startDate = form.getValues().roleAccess[index].startDate;
    const startTime = form.getValues().roleAccess[index].startDate ? form.getValues().roleAccess[index].startDate.getTime() : undefined;

    // Don't validate if soft-deleted.
    const softDelete = form.getValues().roleAccess[index].softDelete;
    if (softDelete) {
      return;
    }

    // Don't validate if expiry date is invalid (lovely!)
    // Reason: For example facility manager like how a nurse works and re-hires him for temp permanent then
    // facility manager will adjust his expiry date to non expiry.
    // We must not let an invalid date to enter into the system in the future and managers have to adhere to professional practices.
    if (expiryDate?.toString() === 'Invalid Date') {
      return;
    }

    if (expiryTime && expiryDate && startTime && startDate && expiryDate < startDate && expiryTime < startTime && !isDisabled) {
      return 'Expiry date cannot be earlier than start date';
    }
    if (!props.field.isNew && expiryDate && expiryDate !== currentExpiryDate && expiryDate < todayDate && !isDisabled) { // it is for old and currently null expiry dates. Old expiry dates with value are already read only (greyed out).
      return 'Expiry date cannot be earlier than today\'s date';
    }
    if (expiryDate && expiryTime && todayDate && todayTime && expiryDate < todayDate && props.field.isNew) { // it only validates the newly added role. Old roles/expiry dates won't be validated.
      return 'Expiry date cannot be earlier than today\'s date';
    }
    if (startTime && startDate && expiryTime && expiryDate && startDate > expiryDate && startTime > expiryTime && !isDisabled) {
      return 'Start date cannot be later than expiry date';
    }
  }

  useEffect(() => {
    checkStartDate();
    checkExpiryDate();
    setExpiryDate(addFiveMinuteToCurrentTime);
  }, []);


  return (
    <Grid colsTemplate="2fr 1fr 1fr">
      <FormGroup label="Title" fullWidth
                 error={(form.errors.roleAccess?.[index]?.role as FieldError)?.message}>
        <Select
          fullWidth
          name={`roleAccess.${index}.role`}
          options={roleOptions}
          disabled={roleDisabled || disabled || isCreatedWithinThirtyMinutesAgo}
          value={
            roleOptions.find((role) => role.value.urn === field.role?.urn)?.value ?? {'No RoleOption for field.role?.urn': 'No RoleOption for field.role?.urn'}
          }
          rules={{
            validate: (role: RoleDto) => {
              if (role === undefined || Object.keys(role).length <= 1) {
                return 'The role field is required';
              }
            },
          }}
        />
      </FormGroup>
      <FormGroup label="Start Date" isRequired fullWidth error={form.errors.roleAccess?.[index]?.startDate?.message}>
        <DateInput
          dateTime
          name={`roleAccess.${index}.startDate`}
          value={field.startDate}
          fullWidth
          rules={{
            validate: (value) => {
              return validateStartDate(value);
            }
          }}
          required
          disabled={disabled || isCreatedWithinThirtyMinutesAgo}
        />
      </FormGroup>
      <FormGroup label="Expiry Date" fullWidth error={form.errors.roleAccess?.[index]?.expiryDate?.message}>
        <DateInput
          dateTime
          name={`roleAccess.${index}.expiryDate`}
          onClick={() => {
            if (!field.expiryDate) {
              form.setValue(`roleAccess.${index}.expiryDate`, expirydate);
            }
          }}
          value={field.expiryDate ?? undefined}
          rules={{
            validate: (value) => {
              return validateExpiryDate(value);
            }
          }}
          fullWidth
          disabled={disabled || isDisabled}
        />
      </FormGroup>
    </Grid>
  );
}
