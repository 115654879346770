import itiriri from 'itiriri';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {HSFacility} from 'server-openapi';
import styled from 'styled-components';
import {IconBack} from '../../kit/Icons/Back';
import {Layout} from '../../kit/Layout';
import {Text} from '../../kit/Text';
import {useSyncCenter} from '../../syncstream/SyncCenterProvider';
import {useStore} from '../../core/storage/hooks/UseStore';
import {DashboardNavbar} from './DashboardPage/components/DashboardNavbar';
import {Grid} from '../../kit/Grid';
import {SearchInput} from '../../kit/Forms/SearchInput';
import {Select} from '../../kit/Forms/Select';
import {DateUtils, Interval} from '../../core/utils/dateUtils';
import {useApiUtils} from '../../syncstream/utils/hooks/useApiUtils';
import {startOfDay} from 'date-fns';
import {RoundScheduleItem} from '../../syncstream/utils/RoundUtils';
import {MissedDosesCard} from "./components/MissedDosesCard";
import {groupBy} from "lodash-es";
import {useGetScheduleFromParameters} from "../../syncstream/utils/hooks/GetSchedule";
import Badge from "../../kit/Badge";

interface IParams {
    facilityGroupId: string;
}

export function MissedDosesPage() {
    // Get the currently selected facility from the URL parameters.
    const {facilityGroupId} = useParams<IParams>();
    const services = useSyncCenter();
    const apiUtils = useApiUtils();
    const getScheduleFromParameters = useGetScheduleFromParameters();

    const facilityGroupsStore = useStore(services.facilityGroups.store).store;
    const facilityGroup = itiriri(facilityGroupsStore.values()).find((f) => f.hsId?.toString() === facilityGroupId);

    const facilityStore = useStore(services.facilities.store).store;
    const drugStore = useStore(services.drugs.store).store;

    const [filterPatientName, setFilterPatientName] = useState<string>('');
    const [filterWing, setFilterWing] = useState<HSFacility | undefined>(undefined);

    const facilities = itiriri(facilityStore.values())
        .filter((facility) => facility.facilityGroupId?.toString() === facilityGroupId)
        .toArray();

    const patientUtils = useApiUtils().patients;
    const roundWindow = apiUtils.rounds.getRoundWindow(new Date(), facilityGroup?.hsId ?? 0);
    const startOfToday = startOfDay(new Date());
    const newRoundWindow: Interval = {
        start: DateUtils.fromDate(startOfToday),
        end: roundWindow.start,
    };
    const roundDetails: RoundScheduleItem[] = getScheduleFromParameters(
            newRoundWindow,
            facilities!.map((x) => x!.hsId!)
        );
    const activeRounds = apiUtils.rounds.getActiveRounds(
        new Date(),
        apiUtils.rounds.getIdsOfFacilitiesInFacilityGroup(facilityGroupId ? +facilityGroupId : -1),
    );
    const inactiveScheduleItems = apiUtils.rounds.inactiveScheduleItems(activeRounds, roundDetails);


    const facilityOptions = facilities.map((facility) => {
        return {label: facility.name ?? 'Unknown', value: facility};
    });

    const wingLabel = apiUtils.facilityGroups.getFacilityUILabel(parseInt(facilityGroupId));
    const residentLabel = apiUtils.facilityGroups.getResidentUILabel(parseInt(facilityGroupId));

    const history = useHistory();
    const [filteredScheduleItems, setFilteredScheduleItems] = useState<{[nm: string]: RoundScheduleItem[]}>({});
    useEffect(() => {
        const filteredPatients = inactiveScheduleItems.filter((y) => {
          if (!!filterWing && y.patient.facility !== filterWing.hsId) {
            // Wing does not match - row is ignored.
            return false;
          }
          if (!!filterPatientName) {
            // Check if the patient matches.
            const residentName = patientUtils.getQueryPatientString(y.patient);
            return residentName.includes(filterPatientName.toUpperCase());
          }
          return true;
        });

        setFilteredScheduleItems(groupBy(filteredPatients, (i) => patientUtils.getDisplayPatientName(i.patient)));

    }, [filterWing, filterPatientName]);
    function patientNames(): string[] {
        return Object.keys(filteredScheduleItems);
    }

    return (
        <Layout>
            <DashboardNavbar facilityGroup={facilityGroup}/>
            <Container gap={1}>
                <Layout horizontal gap={1.5}>
                    <IconBack onClick={() => history.push(`/facility-group/${facilityGroupId}`)}/>
                    <Layout horizontal>
                        <Text weight={'bold'} size="large" style={{color: 'white'}}>
                            Missed Doses
                        </Text>
                        <Badge type={'info'} text={patientNames().length}></Badge>
                    </Layout>
                </Layout>
                <Grid cols={2} colsMobile={1} gap={1}>
                    <SearchInput onChange={(_, v) => setFilterPatientName(v)} value={filterPatientName} name="search"
                                 placeholder={`Search ${residentLabel}`} fullWidth/>
                    {facilityOptions.length === 1 ? (
                        <WingNameBox>
                            <Text>{facilityOptions[0].label}</Text>
                        </WingNameBox>
                    ) : (
                        <Select fullWidth placeholder={`All ${wingLabel}`} name="wing" options={facilityOptions}
                                value={filterWing} onChange={(_, value) => setFilterWing(value)}/>
                    )}
                </Grid>
                {
                    patientNames().map((nm) => <MissedDosesCard patientName={nm} scheduleItems={filteredScheduleItems[nm]} facilityGroup={facilityGroup!} facilityStore={facilityStore} drugStore={drugStore} key={nm}/> )
                }
            </Container>
        </Layout>
    );
}

const Container = styled(Layout)`
    margin: 1.5rem;
`;

const WingNameBox = styled(Layout)`
    display: flex;
    padding: ${(props) => props.theme.forms.input.padding};
    align-items: center;
    background: white;
`;
