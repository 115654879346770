/* eslint-disable max-lines */
import React from 'react';
import { Grid } from '../../../../../kit/Grid';
import {
  HSAdministeredDrug,
  HSDoseRound,
  HSDrug,
  HSPackedMedication,
  HSPackedPatientDay,
  HSPatchObservation,
  ReasonCode,
} from 'server-openapi';
import { MedicationInformationBox } from '../../medicationInformation/MedicationInformationBox';
import { DateUtils } from '../../../../../core/utils/dateUtils';
import { CategoryHeading } from './ContinuousMedicationList';
import itiriri from 'itiriri';
import { useSyncCenter } from '../../../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../../../core/storage/hooks/UseStore';
import { MedicationListProps } from '../../../components/MedicationListsTabbedRouter/MedicationListsTabbedRouter';
import { useApiUtils } from '../../../../../syncstream/utils/hooks/useApiUtils';
import { DrugUtils, PreviousDosedDrugs } from '../../../../../syncstream/utils/DrugUtils';
import { PatchLocations, PatchLocationDTO } from '../../patches/PatchLocations';
import { PatchUtils } from '../../patches/PatchUtils';
import { format, intervalToDuration } from 'date-fns';
import { UserUtils } from '../../../../../syncstream/utils/UserUtils';
import { MedicationGroup } from '../../../../../syncstream/utils/ResidentDetailsUtils';
import { ScheduledActivity } from '../../../../../syncstream/utils/RoundUtils';
import { EnqueuedDrugCreateData } from '../../../../../syncstream/SyncRounds';

interface Props extends MedicationListProps {
  onAdministerDrug: (drug: HSAdministeredDrug, doseTimestamp: string) => Promise<EnqueuedDrugCreateData | undefined>;
  groupedPackedMedicationList: MedicationGroup[];
  patientId: number;
  displayAllActions?: boolean;
}

export enum PatchStatus {
  Applied = 'Applied',
  ToSight = 'Sight',
  Sighted = 'Sighted',
  Removed = 'Removed',
  FallenOff = 'FallenOff',
  Reapplied = 'Reapplied',
}

export function getPatchStatusText(patchStatus: PatchStatus) {
  switch (patchStatus) {
    case PatchStatus.Applied:
      return 'Administered';
    case PatchStatus.ToSight:
      return 'Sight';
    case PatchStatus.Sighted:
      return 'Sighted';
    case PatchStatus.Removed:
      return 'Removed';
    case PatchStatus.FallenOff:
      return 'Fallen off';
    case PatchStatus.Reapplied:
      return 'Reapplied';
    default:
      return '';
  }
}

export interface PatchInfo {
  PatchStatus?: PatchStatus;
  ActionedDate?: string;
  ActionedBy?: string;
  TimeRemaining?: string;
}

// eslint-disable-next-line max-lines-per-function
export function PatchesMedicationList(props: Props) {
  const services = useSyncCenter();
  const roundsStore = useStore(services.rounds.store).store;
  const patchObservationsStore = useStore(services.patchObservations.store).store;
  const packedPatientDaysStore = useStore(services.packedPatientDays.store).store;
  const drugStore = useStore(services.drugs.store).store;
  const apiUtils = useApiUtils();

  const profiles = props.patient.patientProfiles ?? [];

  const roundWindow = props.currentRound?.createdAt
    ? apiUtils.rounds.getRoundWindow(DateUtils.toDate(props.currentRound.createdAt), props.facilityGroupId)
    : undefined;

  const patches = props.groupedPackedMedicationList
    .filter(
      (group, index) =>
        group.categories.isPatch &&
        (!props.displayAllActions ||
          index ===
            props.groupedPackedMedicationList.findIndex(
              (group2) =>
                group.scheduledActivityAction?.packedMed?.hsId === group2.scheduledActivityAction?.packedMed?.hsId,
            ) ||
          group.scheduledActivityAction === undefined) &&
        (props.displayAllActions ||
          group.scheduledActivityAction === undefined ||
          !roundWindow ||
          (DateUtils.toDate(roundWindow.start) <= group.scheduledActivityAction!.activity.time &&
            DateUtils.toDate(roundWindow.end) >= group.scheduledActivityAction!.activity.time)),
    )
    .sort((lhs, rhs) => DateUtils.compareDatesDescending(lhs.categories.doseDate!, rhs.categories.doseDate!, true));

  const patientPatchObservations = itiriri(patchObservationsStore.values())
    .filter((p) => p.patientId === props.patientId && p.active!)
    .toArray();
  const packedPatientDays = itiriri(packedPatientDaysStore.values())
    .filter((p) => p.patientId === props.patientId && !!p.packDate)
    .map((day) => ({
      ...day,
      packedMedications: day.packedMedications?.filter((packedMed) =>
        profiles.some(
          (profile) =>
            [...(profile.allCurrentMedications ?? []), ...(profile.recentlyDeletedMedications ?? [])].find(
              (med) => med.hsId === packedMed.medicationId,
            )?.showOnSigningSheet,
        ),
      ),
    }))
    .toArray();
  const drugs = itiriri(drugStore.values()).toArray();

  return (
    <Grid cols={1} gap={1}>
      {patches.map((packedMedicationList, index) => (
        <Grid cols={1} gap={0.5} key={index}>
          <CategoryHeading weight={'bold'}>
            {apiUtils.residentDetails.getMedicationHeader(packedMedicationList, true)}
          </CategoryHeading>
          {packedMedicationList.medications.map((packedMedication) => {
            const currentDosedDrug = apiUtils.rounds.getMostRecentDrugInRoundByPackedMedication(
              packedMedication,
              props.patient.hsId!,
              props.currentRound,
            );
            const status = apiUtils.rounds.getPackedMedicationStatus(packedMedication, props.patientId);

            return (
              <PatchMedicationInformation
                key={packedMedication.hsId}
                packedMedication={packedMedication}
                currentDosedDrug={currentDosedDrug}
                onAdminister={props.onAdministerDrug}
                status={status}
                patchInfo={getPatchInfo(
                  packedMedication,
                  packedPatientDays,
                  drugs,
                  patientPatchObservations,
                  apiUtils.users,
                  currentDosedDrug,
                  props.previousDosedDrugs,
                  status,
                )}
                userUtils={apiUtils.users}
                roundsStore={roundsStore}
                {...(props as MedicationListProps)}
              />
            );
          })}
          {packedMedicationList.scheduledActivityAction && (
            <PatchMedicationInformation
              key={packedMedicationList.scheduledActivityAction.packedMed.hsId}
              packedMedication={packedMedicationList.scheduledActivityAction.packedMed}
              currentDosedDrug={apiUtils.rounds.getMostRecentDrugInRoundByPackedMedication(
                packedMedicationList.scheduledActivityAction.packedMed,
                props.patient.hsId!,
                props.currentRound,
              )}
              selectedActivity={packedMedicationList.scheduledActivityAction?.activity}
              onAdminister={props.onAdministerDrug}
              roundsStore={roundsStore}
              status={apiUtils.rounds.getPackedMedicationStatus(
                packedMedicationList.scheduledActivityAction.packedMed,
                props.patient.hsId!,
              )}
              displayAllActivity={props.displayAllActions}
              patchInfo={getPatchInfo(
                packedMedicationList.scheduledActivityAction.packedMed,
                packedPatientDays,
                drugs,
                patientPatchObservations,
                apiUtils.users,
                apiUtils.rounds.getMostRecentDrugInRoundByPackedMedication(
                  packedMedicationList.scheduledActivityAction.packedMed,
                  props.patient.hsId!,
                  props.currentRound,
                ),
                props.previousDosedDrugs,
                apiUtils.rounds.getPackedMedicationStatus(
                  packedMedicationList.scheduledActivityAction.packedMed,
                  props.patientId,
                ),
              )}
              userUtils={apiUtils.users}
              {...(props as MedicationListProps)}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function getPatchInfo(
  packedMedication: HSPackedMedication,
  packedPatientDays: HSPackedPatientDay[],
  drugs: HSDrug[],
  patientPatchObservations: HSPatchObservation[],
  userUtils: UserUtils,
  currentDosedDrug?: HSAdministeredDrug,
  previousDosedDrugs?: PreviousDosedDrugs[],
  status?: ReasonCode,
): PatchInfo | undefined {
  const hasPatchBeenApplied = status === ReasonCode.Dosed;

  const patchDrug = drugs.find((d) => d.hsId === packedMedication.drugHsId)!;

  const patchInfo: PatchInfo = {
    PatchStatus: hasPatchBeenApplied ? PatchStatus.Applied : undefined,
    TimeRemaining: getPatchTimeRemaining(hasPatchBeenApplied, packedPatientDays, packedMedication, patchDrug),
  };
  const patchObservations = itiriri(
    patientPatchObservations.filter(
      (p) =>
        p.medicationId === packedMedication.medicationId && p.operation?.startsWith(packedMedication.hsId!.toString()),
    ),
  );
  const latestPatchObservation = patchObservations
    .sort((a, b) => DateUtils.compareDateStringsDescending(a.createdAt, b.createdAt))
    .first();
  if (latestPatchObservation) {
    const patchStatus = PatchStatus[latestPatchObservation.operation!.split(':')[1] as keyof typeof PatchStatus];

    return {
      ...patchInfo,
      TimeRemaining:
        patchStatus === PatchStatus.Removed || patchStatus === PatchStatus.FallenOff ? '' : patchInfo.TimeRemaining,
      PatchStatus: patchStatus,
      ActionedDate: latestPatchObservation.createdAt
        ? DateUtils.dateStringTo24HourTimeString(latestPatchObservation.createdAt)
        : '',
      ActionedBy:
        userUtils.getUserFullNameAndRoleFromSubjectId(latestPatchObservation.lastUpdatedBySubjectId) ?? undefined,
    };
  }

  const { activeDose: activePatch } = DrugUtils.getActivePreviousDoses(
    currentDosedDrug,
    previousDosedDrugs,
    packedMedication.hsId,
  );

  return hasPatchBeenApplied && !PatchUtils.isPatchRemoval(patchDrug) && isPatchToSight(activePatch)
    ? {
        ...patchInfo,
        PatchStatus: PatchStatus.ToSight,
      }
    : patchInfo;
}

function getIntervalToNextPatchOrRemoval(index: number, patchAndRemovalLatestFirst: HSPackedMedication[]) {
  let intervalText;

  const nextPatchRemovalTime =
    index > 0 ? DateUtils.toDate(patchAndRemovalLatestFirst[index - 1].doseTimestamp!) : undefined;

  const now = new Date();

  if (nextPatchRemovalTime && now < nextPatchRemovalTime) {
    const intervalDuration = intervalToDuration({ start: now, end: nextPatchRemovalTime });

    intervalText = `${intervalDuration.days! > 0 ? intervalDuration.days + ' day(s)' : ''}`;
  } else {
    intervalText = '0';
  }

  return intervalText;
}

export function getPatchTimeRemaining(
  hasPatchBeenApplied: boolean,
  packedPatientDays: HSPackedPatientDay[],
  currentPackedMedication: HSPackedMedication,
  patchDrug: HSDrug,
) {
  let patchTimeRemaining = '';

  if (!hasPatchBeenApplied) {
    return patchTimeRemaining;
  }

  const patchPackedMedications = packedPatientDays
    .flatMap((p) => p.packedMedications!)
    .filter((pm) => pm.medicationId === currentPackedMedication.medicationId);

  const patchRemovalPackedMedications = packedPatientDays
    .flatMap((p) => p.packedMedications!)
    .filter((pm) => pm.drugHsId === patchDrug?.patchRemovalDrugId);

  const patchAndRemovalLatestFirst = patchPackedMedications
    .concat(patchRemovalPackedMedications)
    .sort((a, b) => DateUtils.compareDateStringsDescending(a.doseTimestamp, b.doseTimestamp));

  if (patchAndRemovalLatestFirst.length > 1) {
    patchAndRemovalLatestFirst.every((pm, index) => {
      if (pm.hsId === currentPackedMedication.hsId) {
        patchTimeRemaining = getIntervalToNextPatchOrRemoval(index, patchAndRemovalLatestFirst);

        return false;
      }
      return true;
    });
  }

  return patchTimeRemaining;
}

function isPatchToSight(activePatch?: HSAdministeredDrug) {
  if (activePatch) {
    const patchAdministeredAt = DateUtils.toDate(activePatch.administeredAt!);
    const scheduledSightingAt = new Date();
    scheduledSightingAt.setHours(7, 0, 0, 0); // default to one hour before 8 am daily

    if (patchAdministeredAt < scheduledSightingAt && new Date() >= scheduledSightingAt) {
      return true;
    }
  }
  return false;
}

interface MedicationInformationProps extends MedicationListProps {
  packedMedication: HSPackedMedication;
  currentDosedDrug?: HSAdministeredDrug;
  onAdminister: (drug: HSAdministeredDrug, doseTimestamp: string) => Promise<EnqueuedDrugCreateData | undefined>;
  roundsStore: ReadonlyMap<string, HSDoseRound>;
  userUtils: UserUtils;
  patchInfo?: PatchInfo;
  status?: ReasonCode;
  selectedActivity?: ScheduledActivity;
  displayAllActivity?: boolean;
}

function PatchMedicationInformation(props: MedicationInformationProps) {
  const { activeDose: activePatch, previousDoses: previousDosedDrugs } = DrugUtils.getActivePreviousDoses(
    props.currentDosedDrug,
    props.previousDosedDrugs,
    props.packedMedication.hsId ?? props.selectedActivity?.packedMedicationId,
  );
  const services = useSyncCenter();
  const patchObservationsStore = useStore(services.patchObservations.store).store;
  const apiUtils = useApiUtils();

  const activities = itiriri(patchObservationsStore.values())
    .filter((activity) => PatchUtils.getPatchOperationData(activity) === props.packedMedication.hsId)
    .toArray();

  const activityRound = itiriri(props.roundsStore.values()).find(
    (round) =>
      round.administeredDoses?.some(
        (dose) =>
          dose.doseTimestamp === props.packedMedication.doseTimestamp &&
          dose.administeredDrugs?.some((drug) => drug.medicationId === props.packedMedication.medicationId),
      ) ?? false,
  );

  const administeredDrug = activityRound?.administeredDoses
    ?.flatMap((dose) => (dose.doseTimestamp === props.packedMedication.doseTimestamp ? dose.administeredDrugs : []))
    .find((drug) => drug?.medicationId === props.packedMedication.medicationId);

  const startActivity: HSPatchObservation | undefined = activityRound
    ? {
        facilityId: props.patient.facility,
        patientId: props.patient.hsId,
        createdAt: administeredDrug?.createdAt,
        lastUpdated: administeredDrug?.lastUpdatedAt,
        medicationId: administeredDrug?.medicationId,
        drugId: props.packedMedication.drugHsId,
        lastUpdatedById: administeredDrug?.lastUpdatedById,
        lastUpdatedByLogin: administeredDrug?.lastUpdatedByLogin,
        operation: props.packedMedication?.hsId + ':' + PatchStatus.Applied,
      }
    : undefined;

  const latestActivity =
    activities
      ?.sort((a, b) => DateUtils.compareDateStringsDescending(a.createdAt, b.createdAt))
      .find((activity) => !!activity.createdAt) ?? startActivity;

  const patchLocation = PatchLocations.find((pl) => pl.LocationNumber === activePatch?.patchLocationNumber);

  const appliedText =
    activePatch?.reasonCode === ReasonCode.Dosed
      ? getAppliedText(activePatch, patchLocation!, props.userUtils)
      : undefined;

  return (
    <MedicationInformationBox
      infoLabel={appliedText}
      previousDosedDrug={ apiUtils.residentDetails.getDosedDrugForPreviousDoseTime(props.patient, props.packedMedication) }
      currentDosedDrug={props.currentDosedDrug}
      scheduledTime={props.packedMedication.doseTimestamp}
      scheduledActivityInformation={
        props.selectedActivity && startActivity
          ? {
              selectedActivity: props.selectedActivity,
              startActivity: startActivity,
              latestActivity: latestActivity!,
              activityRound: activityRound!,
              displayAllActivity: props.displayAllActivity ?? false,
            }
          : undefined
      }
      {...props}
    />
  );
}

const getAppliedText = (activePatch: HSAdministeredDrug, patchLocation: PatchLocationDTO, userUtils: UserUtils) => {
  return `Applied to ${patchLocation?.PatchArea.toLowerCase()} ${
    activePatch.administeredBySubjectId
      ? ' by ' + userUtils.getUserFullNameAndRoleFromSubjectId(activePatch.administeredBySubjectId)
      : ''
  } on ${format(DateUtils.toDate(activePatch.administeredAt!), 'dd MMM yyyy')}`;
};
