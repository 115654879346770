import itiriri from 'itiriri';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  HSAdministeredDose,
  HSAdministeredDrug,
  HSDoseRound,
  HSDrug,
  HSFacility,
  HSMedication,
  HSPatient,
  MedicationStatus,
  MedicationType,
  ReasonCode,
} from 'server-openapi';
import { DateUtils } from '../../../core/utils/dateUtils';
import { useSyncCenter } from '../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../core/storage/hooks/UseStore';
import { MedicationDoses, MedicationListLayout, MedicationListType } from './MedicationListLayout';
import { DrugUtils } from '../../../syncstream/utils/DrugUtils';
import { isToday } from 'date-fns';
import { useApiUtils } from '../../../syncstream/utils/hooks/useApiUtils';
import { findPatientProfileMedication } from '../DashboardPage/components/DashboardNavbar';

interface IParams {
  facilityGroupId: string;
  administrationType: string;
}

export function getRecentMedicationDoses(
  drugs: (drugId: number) => HSDrug,
  rounds: HSDoseRound[],
  facilities: HSFacility[],
  patients: HSPatient[],
  medicationListType?: MedicationListType,
): MedicationDoses[] {
  return rounds
    .sort((a, b) => b.createdAt?.localeCompare(a.createdAt ?? '') ?? 0)
    .map((round) =>
      (round.administeredDoses ?? [])
        .map((v) => mapToDoses(round.clinicalSystemId!, drugs, facilities, patients, medicationListType)(v))
        .flat(),
    )
    .flat();
}

function mapToDoses(
  doseRoundClinicalSystemId: string,
  drugs: (drugId: number) => HSDrug,
  facilities: HSFacility[],
  patients: HSPatient[],
  medicationListType?: MedicationListType,
) {
  return (administeredDose: HSAdministeredDose) => {
    const patient = patients.find((p) => p.hsId === administeredDose.patientId);
    const allMedicationDoses = [];
    if (patient && administeredDose.administeredDrugs) {
      const medicationDoses = getMedicationDoses(
        doseRoundClinicalSystemId,
        drugs,
        facilities,
        patient,
        administeredDose,
        medicationListType,
      );
      allMedicationDoses.push(medicationDoses);
    }

    if (medicationListType === MedicationListType.Nims && patient && administeredDose.administeredAdHocDrugs) {
      const adhocDoses = getAdHocDoses(doseRoundClinicalSystemId, drugs, facilities, patient, administeredDose);
      allMedicationDoses.push(adhocDoses);
    }

    return allMedicationDoses.flat();
  };
}

function getAdHocDoses(
  doseRoundClinicalSystemId: string,
  drugs: (drugId: number) => HSDrug | undefined,
  facilities: HSFacility[],
  patient: HSPatient,
  administeredDose: HSAdministeredDose,
): MedicationDoses[] {
  const facility = facilities.find((f) => f.hsId === patient.facility);
  return (
    administeredDose.administeredAdHocDrugs
      ?.filter((adr) => adr.administeredAt && isToday(DateUtils.toDate(adr.administeredAt)))
      .map((adr): MedicationDoses => {
        const drug = drugs(adr.drugId!)!;
        const adrWithComments = {
          ...adr,
          doseRoundClinicalSystemId: doseRoundClinicalSystemId,
          comments:
            adr.administeredAdHocDrugComments?.map((comment) => DrugUtils.extractCompactCommentInfo(comment)) ?? [],
          outcomes:
            adr.administeredAdHocDrugOutcomes?.map((outcome) => DrugUtils.extractCompactCommentInfo(outcome)) ?? [],
        };

        return {
          key: adr.clinicalSystemId!,
          facility: facility,
          patient: patient,
          drug: drug,
          isCeased: false,
          isNimOrPrn: true,
          ad: adrWithComments,
          medicationListType: MedicationListType.Nims,
        };
      }) ?? []
  );
}

function getMedicationDoses(
  doseRoundClinicalSystemId: string,
  drugs: (drugId: number) => HSDrug,
  facilities: HSFacility[],
  patient: HSPatient,
  administeredDose: HSAdministeredDose,
  medicationListType?: MedicationListType,
): MedicationDoses[] {
  const facility = facilities.find((f) => f.hsId === patient.facility);
  const map = mapToMedicationDoses(doseRoundClinicalSystemId, drugs, patient, facility);
  return (
    administeredDose.administeredDrugs
      ?.filter(
        (adr) =>
          !medicationListType ||
          (medicationListType === MedicationListType.Refused && adr.reasonCode === ReasonCode.Refused) ||
          (medicationListType === MedicationListType.Other && adr.reasonCode === ReasonCode.Other) ||
          (medicationListType === MedicationListType.Withheld && adr.reasonCode === ReasonCode.Withheld) ||
          (medicationListType === MedicationListType.NoStock && adr.reasonCode === ReasonCode.NoStock) ||
          medicationListType === MedicationListType.Prns,
      )
      .filter((adr) => adr.administeredAt && isToday(DateUtils.toDate(adr.administeredAt)))
      .map((adr): [HSAdministeredDrug, HSMedication?] => {
        const profile =
          patient.patientProfiles?.find((p) => p.allCurrentMedications?.find((m) => m.hsId === adr.medicationId)) ??
          undefined;
        const medication = profile ? findPatientProfileMedication(profile, adr.medicationId!) : undefined;
        return [adr, medication];
      })
      .filter((data) => {
        const [_, medication] = data;
        return (
          !medicationListType ||
          medicationListType !== MedicationListType.Prns ||
          medication?.medicationType === MedicationType.Prn
        );
      })
      .map((data) => {
        const [adr, medication] = data;
        return map(adr, medication);
      }) ?? []
  );
}

function mapToMedicationDoses(
  doseRoundClinicalSystemId: string,
  drugs: (drugId: number) => HSDrug,
  patient: HSPatient,
  facility?: HSFacility,
) {
  return (adr: HSAdministeredDrug, medication?: HSMedication): MedicationDoses => {
    const drug = medication?.drug ? drugs(medication.drug) : undefined;
    const medicationListType = getMedicationListType(adr.reasonCode, medication?.medicationType);
    const adrWithComments = {
      ...adr,
      doseRoundClinicalSystemId: doseRoundClinicalSystemId,
      comments: adr.administeredDrugComments?.map((comment) => DrugUtils.extractCompactCommentInfo(comment)) ?? [],
      outcomes: adr.administeredDrugOutcomes?.map((outcome) => DrugUtils.extractCompactCommentInfo(outcome)) ?? [],
    };
    return {
      key: adr.clinicalSystemId!,
      facility: facility,
      patient: patient,
      drug: drug,
      isCeased: medication?.medicationStatus === MedicationStatus.Deleted,
      isNimOrPrn: medication?.medicationType === MedicationType.Prn,
      ad: adrWithComments,
      medicationListType: medicationListType,
    };
  };
}

function getMedicationListType(reasonCode?: ReasonCode, medicationType?: MedicationType) {
  if (reasonCode === ReasonCode.Refused) {
    return MedicationListType.Refused;
  } else if (reasonCode === ReasonCode.Withheld) {
    return MedicationListType.Withheld;
  } else if (reasonCode === ReasonCode.NoStock) {
    return MedicationListType.NoStock;
  } else if (medicationType === MedicationType.Prn) {
    return MedicationListType.Prns;
  }

  return MedicationListType.Other;
}

function getReason(administrationType: string): [string, MedicationListType] {
  if (administrationType === 'refused') {
    return ['Refused', MedicationListType.Refused];
  }

  if (administrationType === 'withheld') {
    return ['Withheld', MedicationListType.Withheld];
  }
  if (administrationType === 'prns') {
    return ['PRN', MedicationListType.Prns];
  }
  if (administrationType === 'nostock') {
    return ['No Stock', MedicationListType.NoStock];
  }

  if (administrationType === 'nims') {
    return ['NIM', MedicationListType.Nims];
  }
  return ['Other', MedicationListType.Other];
}

export function MedicationListPage() {
  // Get the currently selected facility from the URL parameters.
  const { facilityGroupId, administrationType } = useParams<IParams>();
  const [reasonText, medicationListType] = getReason(administrationType);

  const services = useSyncCenter();
  const apiUtils = useApiUtils();

  const facilityGroupsStore = useStore(services.facilityGroups.store).store;
  const facilityGroup = itiriri(facilityGroupsStore.values()).find((f) => f.hsId?.toString() === facilityGroupId);

  const facilityStore = useStore(services.facilities.store).store;
  const roundsStore = useStore(services.rounds.store).store;
  const drugStore = useStore(services.drugs.store).store;
  const findDrug = useCallback(
    (drugId: number): HSDrug => {
      return drugStore.get(drugId.toString())!;
    },
    [drugStore],
  );

  const rounds = itiriri(roundsStore.values()).toArray();

  const facilities = itiriri(facilityStore.values())
    .filter((facility) => facility.facilityGroupId?.toString() === facilityGroupId)
    .toArray();

  const patients = apiUtils.patients
    .getActivePatients()
    .toArray()
    .filter((patient) => facilities.some((facility) => facility.hsId === patient.facility));

  const rows = getRecentMedicationDoses(findDrug, rounds, facilities, patients, medicationListType);

  return (
    <MedicationListLayout
      facilityGroupId={facilityGroupId}
      reasonText={reasonText}
      facilities={facilities}
      rows={rows}
      facilityGroup={facilityGroup}
    />
  );
}
