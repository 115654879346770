import React from 'react';
import { IoCheckmarkSharp } from 'react-icons/io5';
import styled, { useTheme } from 'styled-components';
import { CircularProgress } from '../../../../kit/CircularProgress';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';

const TextContainer = styled.div`
  margin: auto;
  text-align: center;

  * {
    color: ${(p) => p.theme.backgrounds.default.fg};
  }
`;

const ProgressContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  TextContainer
`;

export function RoundProgressCircle(props: {
  residentsSeen: number;
  residentsInRound: number;
  facilityGroupId: number;
}) {
  const theme = useTheme();
  const apiUtils = useApiUtils();
  const ResidentLabel = apiUtils.facilityGroups.getResidentUILabel(props.facilityGroupId);
  return (
    <CircularProgress percent={props.residentsSeen / props.residentsInRound}>
      <ProgressContent>
        {props.residentsSeen === props.residentsInRound && (
          <IoCheckmarkSharp color={theme.button.success.bg} size={'4em'} />
        )}

        <TextContainer>
          <h3>
            {props.residentsSeen} of {props.residentsInRound}
          </h3>
          <p>{`${ResidentLabel} seen`}</p>
        </TextContainer>
      </ProgressContent>
    </CircularProgress>
  );
}
