import {errorText} from '../Text';
import {Intent} from '../Theme/Theme';
import {Toast, ToastOptions} from './Toast';
import {runtime} from './ToastProvider';

class Toaster {
  private ids = 0;

  success(text: string) {
    this.toast(text, {
      timeoutSeconds: 8,
      intent: Intent.Success,
    });
  }

  error(text: string | Error) {
    this.toast(errorText(text), {
      timeoutSeconds: 8,
      intent: Intent.Danger,
    });
  }

  warning(text: string | Error) {
    this.toast(errorText(text), {
      timeoutSeconds: 8,
      intent: Intent.Warning,
    });
  }

  info(text: string | Error) {
    this.toast(errorText(text), {
      timeoutSeconds: 8,
      intent: Intent.Info,
    });
  }

  toast(text: string, options: ToastOptions) {
    this.add({
      id: this.ids++,
      text: text,
      options: options,
    });
  }

  private add(toast: Toast) {
    if (!runtime.add || !runtime.remove) {
      // eslint-disable-next-line no-console
      console.warn("missing toast runtime. did you forget to render the UI Kit's <ThemeProvider /> element?");
    }

    runtime.add?.(toast);
    setTimeout(() => {
      runtime.remove?.(toast);
    }, toast.options.timeoutSeconds * 1000);
  }
}

export const toasts = new Toaster();
