import itiriri from 'itiriri';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import { IconBack } from '../../../kit/Icons/Back';
import { Layout } from '../../../kit/Layout';
import { Text } from '../../../kit/Text';
import { useSyncCenter } from '../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../core/storage/hooks/UseStore';
import { DashboardNavbar } from '../../Dashboard/DashboardPage/components/DashboardNavbar';
import { MetricsContainer } from './components/MetricsContainer';
import { RequirePermission } from '../../../components/RequirePermission/RequirePermission';
import {useGroupPermissions} from "../../../core/authz/PermissionsProvider";

interface IParams {
  facilityGroupId: string;
}

export function FacilityMetricsPage() {
  const facilityGroupId = parseInt(useParams<IParams>().facilityGroupId);
  const history = useHistory();
  const services = useSyncCenter();
  const facilityGroupsStore = useStore(services.facilityGroups.store).store;
  const corporatesStore = useStore(services.corporates.store).store;
  const facilityGroup = itiriri(facilityGroupsStore.values()).find((f) => f.hsId === facilityGroupId);
  const corporate = itiriri(corporatesStore.values()).find(
    (c) => c.hsId === parseInt(facilityGroup?.corporateId ?? ''),
  );
  const groupPermissions = useGroupPermissions();
  return (
    <Layout>
      <DashboardNavbar facilityGroup={facilityGroup} />
      <Container gap={5}>
        <Layout horizontal gap={1.5}>
          <IconBack onClick={() => history.push(`/facility-group/${facilityGroupId}`)} />
          <Text weight={'bold'} size="large">
            Facility Metrics
          </Text>
        </Layout>
        <RequirePermission hasPermission={groupPermissions.canViewMetricsDashboard}>
          <Layout justify="center" gap={1}>
            <Text weight={'bold'}>{(corporate?.name ?? '').toLocaleUpperCase()}</Text>
            <Text weight={'bold'} size="large">
              {facilityGroup?.name}
            </Text>
            <MetricsContainer facilityGroupId={facilityGroupId} />
          </Layout>
        </RequirePermission>
      </Container>
    </Layout>
  );
}

const Container = styled(Layout)`
  margin: 1.5rem;
  color: ${(props) => props.theme.backgrounds.default.fg};
`;
