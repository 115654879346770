import { HSTestResultType } from 'server-openapi';
import { IStorage } from '../core/storage/Contract';
import { SyncStreamAPI } from './api';
import { ISyncService } from './SyncCenter';

export class SyncTestResultTypes implements ISyncService {
  get name(): string {
    return 'SyncTestResultTypes';
  }

  constructor(private api: SyncStreamAPI, private storage: IStorage<HSTestResultType>) {}

  async syncDown() {
    const testResultTypes = await this.api.testResults.testResultListTestResultType();
    await this.storage.clear();
    await this.storage.setMany(
      testResultTypes.data
        .map((testResultType) => ({
          key: testResultType.hsId!.toString(),
          value: testResultType,
        })),
    );
  }

  async syncUp() {
    // Do nothing
  }

  async clear() {
    await this.storage.clear();
  }

  async hasQueuedData() {
    return false;
  }
  isAllowed(canUserAccessMedication: boolean): boolean {
    // Only if you can view a round.
    return true;
  }
}
