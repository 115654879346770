import itiriri from 'itiriri';
import {HSDrug, HSPatient} from 'server-openapi';
import { PersistentQueue } from '../core/queue/PersistentQueue';
import { IStorage } from '../core/storage/Contract';
import { patchRemovalIdentifier } from '../pages/ResidentDetails/components/patches/PatchUtils';
import { SyncStreamAPI } from './api';
import { ISyncService } from './SyncCenter';
import { SyncUtils } from './utils/SyncUtils';
import {MemoryCache} from "../core/storage/MemoryCache";
import {DateUtils} from "../core/utils/dateUtils";
import {startOfDay, subDays} from "date-fns";

interface Operation {
  type: void;
  payload: HSDrug;
}

export class SyncDrugs implements ISyncService {
  get name(): string {
    return 'SyncDrugs';
  }

  constructor(
    private api: SyncStreamAPI,
    private storage: IStorage<HSDrug>,
    private latestChangeNumbers: IStorage<number | undefined>,
    private queue: PersistentQueue<Operation>,
    private epochStore: MemoryCache<string>
  ) {}

  async syncDown(facilityGroupId?: string) {
    // TODO: storage locking so that we can be sure the UI
    // didn't accidentally change a resource in between the API
    // giving us fresh data and updating the storage backend.
    const newDrugs = await this.syncDownWithChangeNumber(
      await SyncUtils.getChangeNumberForFacilities([''], this.latestChangeNumbers),
      facilityGroupId,
    );


    await this.storage.setMany(
        newDrugs.map((drug) => ({
          key: this.storage.get_key!(drug),
          value: drug,
        })),
    );
    await SyncUtils.setChangeNumberForFacilities(
        [''],
        this.latestChangeNumbers,
        newDrugs
    );

    await this.postSyncDownMarkPatchRemovalDrugs(newDrugs)
  }

  async syncDownWithChangeNumber(changeNumber: number, facilityGroupId?: string): Promise<HSDrug[]> {
    const pageSize = 1000; //same as in API
    const drugs = await this.api.drugs.drugsListDrugs(
      changeNumber,
      pageSize,
    );

    if (drugs.data.length === pageSize) {
      return [
        ...drugs.data,
        ...(await this.syncDownWithChangeNumber(SyncUtils.getLatestChangeNumber(drugs.data)!, facilityGroupId)),
      ];
    }
    return drugs.data;
  }

  // HS/SS doesn't provide an easy way to identify if a drug is of the type patch removal text instruction
  // todo: after SP ticket (MPS001-5729) has been implemented, remove this and just use the field on HSDrug instead
  async postSyncDownMarkPatchRemovalDrugs(drugs: HSDrug[]) {
    for (const drug of drugs) {
      const patchRemovalDrugId = drug.patchRemovalDrugId;

      if (patchRemovalDrugId) {
        const patchRemovalDrug = await this.storage.get(patchRemovalDrugId.toString());

        if (
          patchRemovalDrug &&
          patchRemovalDrug.isTextInstruction &&
          !patchRemovalDrug.description?.endsWith(patchRemovalIdentifier)
        ) {
          // Update the description.
          var newDrug = {...patchRemovalDrug, description: patchRemovalDrug.description + patchRemovalIdentifier };
          await this.storage.set(this.storage.get_key!(newDrug), newDrug);
        }
      }
    }
  }

  async syncUp() {
    // Do nothing
  }

  async clear() {
    await this.storage.clear();
    await this.latestChangeNumbers.clear();
    await this.queue.clear();
  }

  async hasQueuedData() {
    return (await this.queue.length()) > 0;
  }

  isAllowed(canUserAccessMedication: boolean): boolean {
    // Only if you can view a round.
    return canUserAccessMedication;
  }
  private async isStale(d: HSDrug): Promise<boolean> {
    return !!(!d.active && d.lastModifiedAt && d.lastModifiedAt < DateUtils.fromDate(subDays(startOfDay(new Date()), 14)));
  }
  async archive(): Promise<void> {
    const keysToDelete: string[] = [];
    for (let [k, v] of (await this.storage.all()).entries()) {
      if (await this.isStale(v)) {
        keysToDelete.push(k)
      }
    }
    await this.storage.deleteMany(keysToDelete);
  }
}
