/* eslint-disable sonarjs/cognitive-complexity */
import * as datefns from 'date-fns';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import styled, { useTheme } from 'styled-components';
import { NavigationBar } from '../../../components/NavigationBar/NavigationBar';
import { Button } from '../../../kit/Button';
import { Grid } from '../../../kit/Grid';
import { IconClose } from '../../../kit/Icons/Close';
import { LayoutContainer } from '../../../kit/LayoutContainer';
import { RoundProgressCircle } from './components/RoundProgressCircle';
import { RoundStatusIndicator } from './components/RoundStatusIndicator';
import { useSyncCenter } from '../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../core/storage/hooks/UseStore';
import { DateUtils } from '../../../core/utils/dateUtils';
import { toasts } from '../../../kit/Toasts/Toaster';
import { DoseRoundStatus, HSDoseRound, ReasonCode } from 'server-openapi';
import { CompleteRoundOp } from '../../../syncstream/SyncRounds';
import { useCurrentUser } from '../../../core/authn/UserProvider';
import { useApiUtils } from '../../../syncstream/utils/hooks/useApiUtils';
import { useRoundSchedule } from '../services/RoundScheduleProvider';
import {setSyncRequired, useAppState} from '../../../context/AppStateProvider';

interface IParams {
  facilityGroupId: string;
  roundId: string;
}

const TextContainer = styled.div`
  margin: auto;
  text-align: center;
  * {
    color: ${(p) => p.theme.backgrounds.default.fg};
  }
`;

const CloseContainer = styled.h2`
  margin: 0;
  cursor: pointer;
`;

// eslint-disable-next-line max-lines-per-function
export function FinishRoundPage() {
  const params = useParams<IParams>();
  const roundId = params.roundId;
  const history = useHistory();
  const appState = useAppState();
  const theme = useTheme();
  const user = useCurrentUser();
  const roundUtils = useApiUtils().rounds;
  const services = useSyncCenter();
  const roundStore = useStore(services.rounds.store).store;
  const round = roundStore.get(roundId);
  const roundScheduleContext = useRoundSchedule();
  React.useEffect(() => {
    if (!roundScheduleContext.roundSchedule) {
      roundScheduleContext.set(round);
    }
  }, [roundId]);
  const roundSchedule = roundScheduleContext.roundSchedule ?? [];

  if (!round) {
    throw new Error('Error: round not found!');
  }

  const residentsInRound = roundUtils.getPatientsFromRound(round);
  const residentsSeen = roundUtils.getPatientsSeenWithinRound(round, roundSchedule, false);

  const roundReport = {
    residentsInRound: residentsInRound.length,
    residentsSeen: residentsSeen.length,
    startTime: DateUtils.toDate(round.createdAt!),
    stats: {
      Refused: roundUtils
        .getRoundSummaryItems(round, roundSchedule, false)
        .filter((summaryItem) => summaryItem.reason === ReasonCode.Refused).length,
      Withheld: roundUtils
        .getRoundSummaryItems(round, roundSchedule, false)
        .filter((summaryItem) => summaryItem.reason === ReasonCode.Withheld).length,
      PRNS: roundUtils.getPrnSummaryItems(round).length,
      NIMS: roundUtils.getNimSummaryItems(round).length,
    },
  };

  const roundCompleted = roundReport.residentsInRound === roundReport.residentsSeen;
  const roundCommenced = !!round.administeredDoses?.some(
    (dose) => dose.administeredDrugs && dose.administeredDrugs.length > 0,
  );
  const roundHasOthers =
    !!round.doseRoundSegments &&
    round.doseRoundSegments.some((segment) => !segment.endedAt && segment.startedBySubjectId !== user.profile.sub);

  async function LeaveRound() {
    const allowAction = roundCompleted || !roundCommenced || roundHasOthers;
    if (allowAction) {
      const leaveRoundOp = roundUtils.createLeaveRoundOp(
        round!.clinicalSystemId!,
        parseInt(params.facilityGroupId, 10),
        user.profile.sub,
        round!,
      );
      if (leaveRoundOp) {
        await services.rounds.service.enqueue.updateRoundSegment(leaveRoundOp);
      }
    } else {
      toasts.error('Cannot leave a round unless another nurse takes over.');
    }
  }

  const roundIsCompletable = round.status !== DoseRoundStatus.Completed && roundCompleted;
  const roundAlreadyCompleted = round.status === DoseRoundStatus.Completed;

  const leaveRoundText = roundAlreadyCompleted
    ? 'Round completed'
    : roundIsCompletable
    ? 'Finish Round'
    : roundHasOthers
    ? 'Leave Round'
    : 'Cancel Round';

  return (
    <div>
      <NavigationBar
        inverted
        nodes={() => [
            <></>,
            <CloseContainer>
              <IconClose
                height={24}
                fill={theme.backgrounds.default.fg}
                onClick={() => history.push(`/facility-group/${params.facilityGroupId}/rounds/${params.roundId}`)}
              />
            </CloseContainer>
        ]}
      />

      <LayoutContainer>
        <Grid cols={1} gap={4}>
          <RoundProgressCircle
            residentsInRound={roundReport.residentsInRound}
            residentsSeen={roundReport.residentsSeen}
            facilityGroupId={parseInt(params.facilityGroupId)}
          />

          <TextContainer>
            <h2>
              {datefns.format(roundReport.startTime, 'p')} {roundCompleted ? 'round complete' : 'round not complete'}
            </h2>
          </TextContainer>

          <Grid cols={4} colsMobile={2} gap={1}>
            <RoundStatusIndicator color={theme.roundStats.missed} count={roundReport.stats.Refused} label="Refused" />
            <RoundStatusIndicator
              color={theme.roundStats.withheld}
              count={roundReport.stats.Withheld}
              label="Withheld"
            />
            <RoundStatusIndicator color={theme.roundStats.prns} count={roundReport.stats.PRNS} label="PRNS" />
            <RoundStatusIndicator color={theme.roundStats.nims} count={roundReport.stats.NIMS} label="NIMS" />
          </Grid>

          <Button
            onClick={async () => {
              await LeaveRound();
              if (!roundAlreadyCompleted) {
                await EndRound(
                  roundCompleted || (!roundCommenced && !roundHasOthers),
                  round,
                  parseInt(params.facilityGroupId, 10),
                  services.rounds.service.enqueue.completeRound,
                );
              }
              setSyncRequired(true);
              history.push(`/facility-group/${params.facilityGroupId}`);
            }}
            fullWidth
            disabled={!(roundCompleted || !roundCommenced || roundHasOthers)}
          >
            {leaveRoundText}
          </Button>
        </Grid>
      </LayoutContainer>
    </div>
  );
}

async function EndRound(
  allowAction: boolean,
  round: HSDoseRound,
  facilityGroupId: number,
  completeRound: (req: CompleteRoundOp) => Promise<HSDoseRound | undefined>,
) {
  if (allowAction) {
    await completeRound({
      type: 'round-complete',
      clinicalSystemId: round.clinicalSystemId!,
      request: {
        facilityGroupId: facilityGroupId,
        doseRoundId: round.hsId ?? -1,
        completedAt: DateUtils.fromDate(new Date()),
      },
    });
  }
}
