import React, { CSSProperties, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import { animated, PickAnimated, useSpring, useTransition } from 'react-spring';
import styled, { css } from 'styled-components';
import { IconClose } from './Icons/Close';
import { breakpoints } from './Theme/Theme';

export interface DialogProps {
  label?: string;
  open: boolean;
  children: React.ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'full';
  onRequestClose?: () => void;
  lazy?: boolean;
  backgroundImage?: string;
  hideCloseButton?: boolean;
  removeScrolling?: boolean;
  secondary?: boolean;
  closeButtonColor?: string;
}

interface CloseButtonProps {
  closeButtonColor?: string;
}

export function Dialog(props: DialogProps) {
  const { onRequestClose, ...rest } = props;

  const [root, setRoot] = useState<Element | null>(null);

  useHotkeys('esc', () => {onRequestClose?.()});

  const bgTransition = getBgTransition(props);

  const animation = {
    in: 'translateY(0%) scale(1)',
    out: 'translateY(10%) scale(0.8)',
    config: {
      mass: 1,
      tension: 700,
      friction: 40,
    },
  };

  const nonLazyAnimation = useSpring({
    transform: props.open ? animation.in : animation.out,
    from: { transform: animation.out },
    config: animation.config,
  });

  const dialogTransition = useTransition(props.open, {
    from: { transform: animation.out },
    enter: { transform: animation.in },
    leave: { transform: animation.out },
    config: animation.config,
  });

  useEffect(() => {
    const el = document.createElement('div');
    document.body.appendChild(el);
    setRoot(el);
    return () => {
      document.body.removeChild(el);
    };
  }, []);

  if (!root) {
    return null;
  }

  const dialog = (style: PickAnimated<CSSProperties>) => {
    return (
      <StyledDialog {...rest} onClick={(event: React.MouseEvent) => event.stopPropagation()} style={style}>
        {!props.hideCloseButton && (
          <CloseButton closeButtonColor={props.closeButtonColor} onClick={() => onRequestClose?.()}>
            <IconClose height={25} aria-label="close" aria-details={props.label} />
          </CloseButton>
        )}
        {props.children}
      </StyledDialog>
    );
  };

  return createPortal(
    <PortalContainer onClick={() => onRequestClose?.()} style={bgTransition} secondary={props.secondary}>
      {!props.lazy ? dialog(nonLazyAnimation) : dialogTransition((styles, item) => item && dialog(styles))}
    </PortalContainer>,
    root,
  );
}

function getBgTransition(props: DialogProps) {
  return useSpring({
    opacity: props.open ? 1 : 0,
    pointerEvents: props.open ? 'all' : 'none',
    from: { opacity: 0 },
    config: {
      mass: 1,
      tension: 500,
      friction: 50,
    },
  });
}

const PortalContainer = animated(styled.div<DialogProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: ${(props) =>
    props.secondary ? props.theme.dialog.secondary.backdrop.bg : props.theme.dialog.primary.backdrop.bg};
  display: grid;
  grid-template: 1fr / 1fr;
  justify-items: center;
  align-items: center;
  will-change: transform;
`);

const StyledDialog = animated(styled.div<DialogProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, .75);
  ${(props) => {
    if (props.secondary) {
      return css`
        padding: ${(props) => props.theme.dialog.secondary.padding};
        border-radius: ${(props) => props.theme.dialog.secondary.border.radius};
        border-width: ${(props) => props.theme.dialog.secondary.border.width};
        border-color: ${(props) => props.theme.dialog.secondary.border.color};
        color: ${(props) => props.theme.dialog.secondary.fg};
        background: ${(props) => props.theme.dialog.secondary.bg};
      `;
    }
    return css`
      padding: ${(props) => props.theme.dialog.primary.padding};
      border-radius: ${(props) => props.theme.dialog.primary.border.radius};
      border-width: ${(props) => props.theme.dialog.primary.border.width};
      border-color: ${(props) => props.theme.dialog.primary.border.color};
      color: ${(props) => props.theme.dialog.primary.fg};
      background: ${(props) => props.theme.dialog.primary.bg};
    `;
  }}
  transform-origin: 0% 100%;
  will-change: transform opacity;
  overflow-y: ${(props) => (props.removeScrolling ? 'hidden' : 'auto')};
  max-height: 85vh;

  ${(props) =>
    props.size === 'full' &&
    css`
      height: 100%;
      max-height: 100%;
      width: 100%;
    `};

  ${breakpoints.mobileUp} {
    min-width: 550px;
  }

  max-width: ${(props) => {
    switch (props.size) {
      case 'full':
        return '100%';
      case 'sm':
        return '486px';
      case 'lg':
        return '95%';
      case 'md':
      default:
        return '768px';
    }
  }};
`);

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  color: ${(props: CloseButtonProps) => props.closeButtonColor ?? '#333'};
  opacity: 0.65;
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 28px;
  margin: .25em;
  padding: 1em;
  z-index: 100;
`;
