import React from 'react';
import { HSPatient } from 'server-openapi';
import styled from 'styled-components';
import { DialogButton } from '../../../../kit/DialogButton';
import { Layout } from '../../../../kit/Layout';
import { ControlledDrugs } from './ControlledDrugs';

interface Props {
  patients: HSPatient[];
  facilityGroupId: string;
}

export function CreateRoundSidebar(props: Props) {
  return (
    <Container>
      <Layout horizontal gap={2}>
        <SidebarButton label="Controlled drugs" size="full" dialogSecondary>
          <ControlledDrugs patients={props.patients} facilityGroupId={props.facilityGroupId} groupedPackedMedicationList={[]}/>
        </SidebarButton>
      </Layout>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  margin-bottom: 3rem;
  transform: rotate(-90deg) translateX(100%);
  transform-origin: bottom right;
`;

const SidebarButton = styled(DialogButton)`
  background: ${(props) => props.theme.backgrounds.light.bg};
  border-radius: 12px 12px 0px 0px;
`;
