import React from 'react';
import styled from 'styled-components';
import { RoundClock } from '../../../../components/RoundClock/RoundClock';
import {
    DrugTypeFilters,
    FilterDrugTypeControlled,
    FilterDrugTypeOther,
} from '../../../Dashboard/DashboardPage/DashboardPage';
import { RoundScheduleItem } from '../../../../syncstream/utils/RoundUtils';

interface Props {
  facilityGroupId: string;
  numberOfPatients: number;
  scheduleItems: RoundScheduleItem[];
  drugTypeControlled?: FilterDrugTypeControlled;
  drugTypeOther?: FilterDrugTypeOther;
  facilityIds: number[];
}

// TODO: rounds are configurable blocks
export function RoundRightPanel(props: Props) {
  return (
    <Container>
      <RoundClock
        facilityIds={props.facilityIds}
        facilityGroupId={props.facilityGroupId}
        numberOfPatients={props.numberOfPatients}
        scheduleItems={props.scheduleItems}
        drugTypeControlled={props.drugTypeControlled}
        drugTypeOther={props.drugTypeOther}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
