import { isToday } from 'date-fns';
import React from 'react';
import { HSAdministeredDrug, HSDoseRound, HSPackedPrnMedication, HSTestResult } from 'server-openapi';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { EnqueuedDrugCreateData } from '../../../../syncstream/SyncRounds';
import { MedicationListProps } from '../MedicationListsTabbedRouter/MedicationListsTabbedRouter';
import { DrugOutcome } from './DrugOutcomeDialog';
import { MedicationInformationBox } from './MedicationInformationBox';
import { useApiUtils } from "../../../../syncstream/utils/hooks/useApiUtils";
import { Tooltip } from 'react-tooltip';
import { MedicationGroup } from '../../../../syncstream/utils/ResidentDetailsUtils';

interface IProps extends MedicationListProps {
  packedPrnMedication: HSPackedPrnMedication;
  currentDosedDrug?: HSAdministeredDrug;
  onAdminister: (
    drug: HSAdministeredDrug,
    doseTimestamp?: string,
    testResults?: HSTestResult[],
  ) => Promise<EnqueuedDrugCreateData | undefined>;
  onAddOutcome: (
    outcome: DrugOutcome,
    administeredDrugClinicalSystemId: string,
    doseRoundClinicalSystemId: string,
    isAdHoc: boolean,
  ) => Promise<void>;
  roundsStore: ReadonlyMap<string, HSDoseRound>;
  groupedPackedMedicationList: MedicationGroup[];
}

export function PrnMedicationInformation(props: IProps) {
  const apiUtils = useApiUtils();
  const patientUtils = apiUtils.patients;
  const sortedHistoricalAdministrations = getSortedHistoricalAdministrations(
    props.patient.hsId!,
    props.packedPrnMedication,
    Array.from(props.roundsStore.values()),
  );
  const lastAdministeredDrug: { doseRoundClinicalSystemId: string; administeredDrug: HSAdministeredDrug } | undefined =
    sortedHistoricalAdministrations[0];
  const dosageLast24Hours = sortedHistoricalAdministrations
    .filter(
      (drug) =>
        drug.administeredDrug.administeredAt !== undefined &&
        isToday(DateUtils.toDate(drug.administeredDrug.administeredAt)),
    )
    .reduce((sum, drug) => {
      if (drug.administeredDrug.administeredDosage) {
        return sum + drug.administeredDrug.administeredDosage;
      }
      return sum;
    }, 0);

  const chartItemDose = patientUtils.getHSChartDoseFromPackedMedication(props.patient, props.packedPrnMedication);
  const infoLabel = chartItemDose?.maxDoseIn24Hours
    ? `${dosageLast24Hours} of ${chartItemDose.maxDoseIn24Hours} ${chartItemDose.doseUnitAbbreviation}(s) administered in the last 24 hours`
    : '';

  return (
      <>
        <MedicationInformationBox
          {...props}
          infoLabel={infoLabel}
          packedMedication={props.packedPrnMedication}
          currentDosedDrug={props.currentDosedDrug}
          previousDosedDrug={lastAdministeredDrug?.administeredDrug}
          isUnscheduledMedication
          groupedPackedMedicationList={props.groupedPackedMedicationList}
          onAdministerPrn={props.onAdminister}
          onAddOutcome={(outcome, drugClinicalSystemId) =>
            props.onAddOutcome(outcome, drugClinicalSystemId, lastAdministeredDrug.doseRoundClinicalSystemId, false)
          }
        />
      </>
  );
}

function getSortedHistoricalAdministrations(
  patientId: number,
  packedPrnMedication: HSPackedPrnMedication,
  rounds: HSDoseRound[],
) {
  return (
    rounds
      .flatMap((r) =>
        (r.administeredDoses ?? []).map((ad) => ({ ...ad, doseRoundClinicalSystemId: r.clinicalSystemId! })),
      )
      .filter((p) => p.patientId === patientId)
      ?.flatMap((r) =>
        (r.administeredDrugs ?? []).map((ad) => ({
          doseRoundClinicalSystemId: r.doseRoundClinicalSystemId,
          administeredDrug: ad,
        })),
      )
      .filter((d) => d.administeredDrug.medicationId === packedPrnMedication.medicationId)
      .sort((a, b) =>
        DateUtils.compareDateStringsDescending(a.administeredDrug.administeredAt, b.administeredDrug.administeredAt),
      ) || []
  );
}
