import React from 'react';
import { FieldError } from 'react-hook-form';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { capitalize, errorText } from '../Text';

export function FormFieldError(props: { error?: string | Error | FieldError }) {
  const { height } = useSpring({
    height: props.error ? 38 : 0,
    config: {
      mass: 1,
      tension: 440,
      friction: 26,
    },
  });

  return (
    <animated.div style={{ height }}>
      {props.error && (
        <div style={{ paddingTop: '2px' }}>
          <ErrorText>{capitalize(errorText(props.error))}</ErrorText>
        </div>
      )}
    </animated.div>
  );
}

const ErrorText = styled.div`
  color: white;
  background: rgba(204, 0, 0, 0.8);
  padding: 0.25rem 0.5rem;
  align-self: flex-start;
  position: relative;
  border-radius: 0 4px 4px 4px;
  margin-top: 9px;
  font-size: 1rem;

  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: -7px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid rgba(204, 0, 0, 0.8);
  }
`;
