import { useEffect, useState } from 'react';

export type AsyncCallback<T> = () => Promise<T>;

export interface AsyncResource<T> {
  loading: boolean;
  value?: T;
  error?: Error;
  refresh(): Promise<void>;
}

export function useAsync<T>(fetcher: AsyncCallback<T>, deps?: React.DependencyList): AsyncResource<T> {
  const [value, setValue] = useState<T | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  async function getResource() {
    try {
      setLoading(true);
      const result = await fetcher();
      setValue(result);
    } catch (error : unknown) {
        if (error instanceof Error) {setError(error)}
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getResource();
  }, deps ?? []);

  return {
    value,
    error,
    loading,
    refresh: getResource,
  };
}

export function mapResource<T, TOut>(resource: AsyncResource<T>, mapper: (value: T) => TOut): AsyncResource<TOut> {
  return {
    ...resource,
    get value() {
      if (resource.value === undefined) {
        return undefined;
      }
      return mapper(resource.value);
    },
  };
}
