/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines-per-function */
// the react-table api makes you call "engine.getProps()" and spread
// them into the various table elements.
// the props do contain a key but eslint doesn't know so it raises
// a linting error.
// because of this we'll just turn off the rule for this file to avoid
// false positives.
/* eslint-disable react/jsx-key */
import { debounce, uniqBy } from 'lodash-es';
import React, { useEffect, useMemo, useState } from 'react';
import { Column, Filters, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { Layout } from '../Layout';
import { Table, TableBody, TableCell, TableColumn, TableHead, TableRow } from '../Table';
import { GlobalFilter } from './GlobalFilter';
import { PaginationControls } from './Pagination';
import { ControlledFilter, StrictColumn, TRow } from './Types';
import { Button } from '../Button';

interface Props<T extends TRow> {
  datasource: {
    columns: StrictColumn<T>[];
    data: T[];
  };
  filters: ControlledFilter<any>[];
  defaultPageSize?: number;
  showGlobalFilter?: boolean;
  backgroundColour?: string;
  itemLabel?: string;
}

export function createDataSource<T extends TRow>(
  data: T[],
  columns: () => StrictColumn<T>[],
  deps?: React.DependencyList,
) {
  return { data, columns: useMemo(columns, deps ?? []) };
}

function getCellText(node: string | number | Array<string | number> | React.ReactElement): string {
  if (['string', 'number'].includes(typeof node)) {
    return node as string;
  }

  if (Array.isArray(node)) {
    return node.map(getCellText).join('');
  }

  if (typeof node === 'object' && node) {
    return getCellText(node.props.children);
  }

  return '';
}

export function DataTable<T extends TRow>(props: Props<T>) {
  const [currentPage, setCurrentPage] = useState(0);
  const engine = useTable<T>(
    {
      columns: props.datasource.columns as Column<T>[],
      data: props.datasource.data,
      autoResetSortBy: false,
      autoResetFilters: false,
      initialState: {
        pageSize: props.defaultPageSize ?? 25,
        pageIndex: currentPage,
        filters: props.filters,
      },
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => {
          const value1 = getCellText(row1.values[columnName]).toLowerCase();
          const value2 = getCellText(row2.values[columnName]).toLowerCase();

          return value1.localeCompare(value2) > 0 ? 1 : -1;
        },
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  );

  const [filters, setFilters] = useState<Filters<T>>(props.filters);

  // this is a memoized function tha can be called to
  // sync the local filters state with the datatable
  // engine. there's additional complexity due to this
  // state duplication but it has a really big effect on
  // performance of larger tables.
  // this function will debounce changes to the filters
  // and ensure they are applied when the browser is ready
  // to render a frame.
  // the debouncing is especially important because applying
  // filters to a large data table can be really expensive
  // and cause the entire UI to freeze.
  const updateFilters = useMemo(() => {
    return debounce((filters) => {
      requestAnimationFrame(() => {
        engine.setAllFilters(filters);
      });
    }, 250);
  }, []);

  // when the local filters state changes
  // call to sync the filter state with the
  // datatable engine.
  useEffect(() => {
    updateFilters(filters);
  }, [filters]);


  useEffect(() => {
    setCurrentPage(engine.state.pageIndex);
  }, [engine.state.pageIndex]);

  // when the datasource columns change we need to update the
  // local filters state with any changes the parent has made
  // to the controlled filter values.
  useEffect(() => {
    setFilters((filters) => uniqBy([...props.filters, ...filters], (item) => item.id));
  }, [props.filters]);
  return (
    <Layout gap={1}>
      {props.showGlobalFilter && (
        <GlobalFilter
          count={engine.preGlobalFilteredRows.length}
          value={engine.state.globalFilter}
          onChange={(value) => engine.setGlobalFilter(value)}
        />
      )}
      <Layout gap={0.3} horizontal align="flex-start">
        <span>Found <strong>{engine.rows.length}</strong> {props.itemLabel ?? 'items'}.</span>
      </Layout>
      <Table {...engine.getTableProps()} fullWidth>
        {engine.headerGroups.map((headerGroup) => (
          <TableHead {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableColumn
                {...column.getHeaderProps(
                  column.getSortByToggleProps({
                    style: { width: column.width, backgroundColor: props.backgroundColour ?? 'default' },
                  }),
                )}
              >
                <Layout horizontal justify="space-between" gap={0.5}>
                  {column.render('Header')}
                  <span>{column.isSorted && (column.isSortedDesc ? '🔽' : '🔼')}</span>
                </Layout>
              </TableColumn>
            ))}
          </TableHead>
        ))}

        {props.filters.length > 0 &&
          engine.headerGroups.map((headerGroup) => (
            <TableHead {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableColumn {...column.getHeaderProps()}>
                  {column.canFilter &&
                    column.Filter &&
                    column.render('Filter', {
                      column: {
                        ...column,
                        filterValue: filters.find((f) => f.id === column.id)?.value,
                        setFilter: (value: unknown) => {
                          // we're overriding the "setFilter" method that's passed
                          // to column filter components so that when they attempt
                          // to update the value for the column filter it's written
                          // to the local filters state rather than to the engine.
                          // this allows the change to be debounced for improved UX.
                          const filter = props.filters.find((f) => f.id === column.id);
                          if (filter) {
                            // if the column's filter value is controlled by the
                            // parent then we need to tell them it's being changed.
                            filter.onChange(value);
                          } else {
                            // otherwise update the local filters state with the
                            // new value from the filter component
                            setFilters((filters) => {
                              return uniqBy([{ id: column.id, value: value }, ...filters], (item) => item.id);
                            });
                          }
                        },
                      },
                    })}
                </TableColumn>
              ))}
            </TableHead>
          ))}

        <TableBody {...engine.getTableBodyProps()}>
          {engine.page.map((row) => {
            engine.prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps({ style: { backgroundColor: props.backgroundColour ?? 'default' } })}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <PaginationControls engine={engine} hideControlWhenAtMostOnePage />
    </Layout>
  );
}
