import * as React from 'react';
import { SVGProps } from 'react';

const IconMail = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1.5em" height="1.5em" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={16.5} cy={16.5} r={16.5} fill="#fff" />
    <path
      d="M25 11.625c0-.894-.765-1.625-1.7-1.625H9.7c-.935 0-1.7.731-1.7 1.625v9.75C8 22.269 8.765 23 9.7 23h13.6c.935 0 1.7-.731 1.7-1.625v-9.75Zm-1.7 0-6.8 4.063-6.8-4.063h13.6Zm0 9.75H9.7V13.25l6.8 4.063 6.8-4.063v8.125Z"
      fill="#AE48FB"
    />
  </svg>
);

export default IconMail;
