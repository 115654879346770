import itiriri from 'itiriri';
import {
  CreateSyringeDriverActivityCommentReq,
  CreateSyringeDriverActivityReq,
  HSDoseRound,
  HSFacility, HSPatient,
  HSSyringeDriverActivity,
  HSSyringeDriverActivityComment,
} from 'server-openapi';
import { PersistentQueue } from '../core/queue/PersistentQueue';
import {Entry, IStorage} from '../core/storage/Contract';
import { SyncStreamAPI } from './api';
import {IFacilityGroupSyncService } from './SyncCenter';
import { v4 as uuidv4 } from 'uuid';
import { SyncUtils } from './utils/SyncUtils';
import { Logger } from '../core/logger/logger';
import { DateUtils } from '../core/utils/dateUtils';
import { startOfDay, subDays } from 'date-fns';
import {MemoryCache} from "../core/storage/MemoryCache";

export type SyringeDriverActivityOp = CreateSyringeDriverActivityOp | CreateSyringeDriverActivityCommentOp;

export interface CreateSyringeDriverActivityOp {
  type: 'syringe-driver-activity-create';
  clinicalSystemId?: string;
  administeredDrugRoundClinicalSystemId?: string;
  medisphereSyringeDriverActivity: MedisphereSyringeDriverActivity;
  request: CreateSyringeDriverActivityReq;
}

export interface CreateSyringeDriverActivityCommentOp {
  type: 'syringe-driver-activity-comment-create';
  clinicalSystemId?: string;
  syringeDriverActivityClinicalSystemId: string;
  request: CreateSyringeDriverActivityCommentReq;
}

export interface MedisphereSyringeDriverActivity extends HSSyringeDriverActivity {
  administeredDrugClinicalSystemId?: string;
}

const logger = new Logger('SyncSyringeDriverActivities');

export class SyncSyringeDriverActivities implements IFacilityGroupSyncService {
  get name(): string {
    return 'SyncSyringeDriverActivities';
  }
  private async isStale(p: MedisphereSyringeDriverActivity): Promise<boolean> {
    return (!!(!p.active && p.lastUpdated && p.lastUpdated < DateUtils.fromDate(subDays(startOfDay(new Date()), 14))));
  }

  private async isFacilityGroup(p: HSPatient, facilityGroupId: string): Promise<boolean> {
    if (!p.facility) {
      return false;
    }
    const facilityGroup = await this.facilitiesStore.get(p.facility!.toString());
    return facilityGroup?.facilityGroupId?.toString() == facilityGroupId;
  }

  constructor(
    private api: SyncStreamAPI,
    private storage: MemoryCache<MedisphereSyringeDriverActivity>,
    private facilitiesStore: IStorage<HSFacility>,
    private latestChangeNumbers: IStorage<number | undefined>,
    private roundsStore: IStorage<HSDoseRound>,
    private queue: PersistentQueue<SyringeDriverActivityOp>
  ) { }
  async load(facilityGroupId: string): Promise<void> {
    await this.storage.reset(async (p) => {
          return await this.isFacilityGroup(p, facilityGroupId);
    });
  }

  async syncDown(facilityGroupId?: string) {
    const facilitiesToSync = await SyncUtils.getFacilitiesForGroup(facilityGroupId, this.facilitiesStore);
    const changeNumber = await SyncUtils.getChangeNumberForFacilities(
      facilitiesToSync.map((x) => x.hsId!),
      this.latestChangeNumbers,
    );

    const syringeDriverActivitiesData = await this.syncFacilityDown(
      facilityGroupId!,
      changeNumber,
    );

    await this.storage.setMany(
      syringeDriverActivitiesData
        .map((activity) => {
          const key = this.storage.get_key!(activity);

          return {
            key: key!,
            value: {
              ...activity,
              clinicalSystemId: key,
            },
          };
        }),
    );

    await SyncUtils.setChangeNumberForFacilities(
        facilitiesToSync.map((x) => x.hsId!),
        this.latestChangeNumbers,
        syringeDriverActivitiesData
    );
  }

  async syncFacilityDown(facilityGroupId: string, changeNumber: number): Promise<MedisphereSyringeDriverActivity[]> {
    const pageSize = 200;
    //changeNumber = isFinite(changeNumber) ? changeNumber : 0;
    const syringeDriverActivities =
      await this.api.syringeDriverActivities.syringeDriverActivitiesListSyringeDriverActivities(
        changeNumber,
        parseInt(facilityGroupId),
        pageSize
      );

    if (syringeDriverActivities.data.length === pageSize) {
      return [
        ...syringeDriverActivities.data,
        ...(await this.syncFacilityDown(facilityGroupId, SyncUtils.getLatestChangeNumber(syringeDriverActivities.data)!)),
      ];
    }
    return syringeDriverActivities.data;
  }

  enqueue = {
    createSyringeDriverActivity: async (req: CreateSyringeDriverActivityOp) => {
      const syringeDriverActivityClinicalSystemId = uuidv4();
      req.request.syringeDriverActivity.clinicalSystemId = syringeDriverActivityClinicalSystemId;

      const syringeDriverActivity = {
        key: syringeDriverActivityClinicalSystemId,
        value: { ...req.request.syringeDriverActivity, comments: [] },
      };

      await this.storage.set(syringeDriverActivity.key, syringeDriverActivity.value);
      await this.queue.unshift({ ...req, clinicalSystemId: syringeDriverActivityClinicalSystemId });

      return syringeDriverActivity;
    },

    createSyringeDriverActivityComment: async (req: CreateSyringeDriverActivityCommentOp) => {
      const syringeDriverCommentClinicalSystemId = uuidv4();

      const Activity = await this.storage.get(req.syringeDriverActivityClinicalSystemId);

      const createdComment: HSSyringeDriverActivityComment = {
        ...req.request.syringeDriverActivityComment,
        clinicalSystemId: syringeDriverCommentClinicalSystemId,
      };

      const updatedComments = [...(Activity?.comments ?? []), createdComment];

      const updatedActivity: MedisphereSyringeDriverActivity = {
        ...Activity,
        comments: updatedComments,
      };

      await this.storage.set(Activity!.clinicalSystemId!, updatedActivity);

      await this.queue.unshift({
        ...req,
        request: {
          ...req.request,
          syringeDriverActivityComment: createdComment,
        },
        clinicalSystemId: syringeDriverCommentClinicalSystemId,
      });

      return { Activity: updatedActivity, administeredComment: createdComment };
    },
  };

  //eslint-disable-next-line sonarjs/cognitive-complexity, max-lines-per-function
  async syncUp() {
    const entries = new Map<string, MedisphereSyringeDriverActivity>();
    for await (const delivery of this.queue.iterate()) {
      try {
        switch (delivery.value.type) {
          case 'syringe-driver-activity-create': {
            const storedActivity =
              (await this.storage.get(delivery.value.clinicalSystemId!)) ??
              entries.get(delivery.value.clinicalSystemId!);
            if (delivery.value.administeredDrugRoundClinicalSystemId) {
              const round = await this.roundsStore.get(delivery.value.administeredDrugRoundClinicalSystemId);
              if (round === undefined) {
                throw new Error('Round id not found when creating syringe driver activity');
              }
              const drugClinicalSystemId =
                delivery.value.medisphereSyringeDriverActivity.administeredDrugClinicalSystemId;
              if (drugClinicalSystemId) {
                const drugId = round.administeredDoses
                  ?.flatMap((dose) => dose.administeredDrugs)
                  .find((drug) => drug?.clinicalSystemId === drugClinicalSystemId)?.hsId;
                if (drugId === undefined) {
                  throw new Error('Administered drug id not found when creating syringe driver activity');
                }
                delivery.value.request.syringeDriverActivity.administeredDrugId = drugId;
              }
            }
            // These fields need to be defined, and have these values as defaults
            delivery.value.request.syringeDriverActivity = {
              ...delivery.value.request.syringeDriverActivity,
              lineCondition: delivery.value.request.syringeDriverActivity.lineCondition ?? 'New',
              siteCondition: delivery.value.request.syringeDriverActivity.siteCondition ?? 'New',
            };
            const newObservation =
              await this.api.syringeDriverActivities.syringeDriverActivitiesCreateSyringeDriverActivity(
                delivery.value.request,
              );
            entries.set(delivery.value.clinicalSystemId!, {
              ...newObservation.data,
              administeredDrugClinicalSystemId:
                delivery.value.medisphereSyringeDriverActivity.administeredDrugClinicalSystemId,
              changeNumber: undefined,
              clinicalSystemId: delivery.value.clinicalSystemId!,
              comments: storedActivity?.comments ?? [],
            });
            break;
          }
          case 'syringe-driver-activity-comment-create': {
            const storedActivity =
              (await this.storage.get(delivery.value.syringeDriverActivityClinicalSystemId)) ??
              entries.get(delivery.value.syringeDriverActivityClinicalSystemId);
            if (storedActivity?.hsId === undefined) {
              throw new Error('Activity id not found when creating comment');
            }
            delivery.value.request.syringeDriverActivityId = storedActivity.hsId;
            const newComment =
              await this.api.syringeDriverActivityComments.syringeDriverActivityCommentsCreateSyringeDriverActivityComment(
                delivery.value.request,
              );
            entries.set(delivery.value.syringeDriverActivityClinicalSystemId, {
              ...storedActivity,
              changeNumber: undefined,
              comments: [
                ...(storedActivity.comments?.map((comment) =>
                  comment.clinicalSystemId === newComment.data.clinicalSystemId ? newComment.data : comment,
                ) ?? []),
              ],
            });
            break;
          }
        }
        await delivery.complete();
      } catch (error) {
        logger.error('Sync Syringe Driver Activities failed', error);
        await delivery.failed();
      }
    }
    await this.storage.setMany(
      itiriri(entries.entries())
        .map((entry) => ({ key: entry[0], value: entry[1] }))
        .toArray(),
    );
  }

  async clear() {
    await this.storage.clear();
    await this.latestChangeNumbers.clear();
    await this.queue.clear();
  }

  async hasQueuedData() {
    return (await this.queue.length()) > 0;
  }
  isAllowed(canUserAccessMedication: boolean): boolean {
    // Only if you can view a round.
    return canUserAccessMedication;
  }
  async archive(deletedPatientIds: string[]): Promise<void> {
    const keysToDelete: string[] = [];
    for (let [k, v] of (await this.storage.all()).entries()) {
      if (await this.isStale(v) || deletedPatientIds.some(x => x === v.patientId?.toString() ?? "-1")) {
        keysToDelete.push(k)
      }
    }
    await this.storage.deleteMany(keysToDelete);
  }
  setEncryptionVersion(version: number): void {
    this.storage.compressOnSave = (version > 1);
  }
  async rewrite(): Promise<void> {
    const entries: Entry<HSSyringeDriverActivity>[] = [...(await this.storage.all())].map((keyValueArray) => {
      return {
        key: keyValueArray[0],
        value: keyValueArray[1]
      };
    });

    return await this.storage.setMany(entries);
  }}
