import itiriri from 'itiriri';
import React, { Fragment, useEffect, useState } from 'react';
import { HSAdministeredDrug, HSTestResult } from 'server-openapi';
import { Grid } from '../../../../../kit/Grid';
import { toasts } from '../../../../../kit/Toasts/Toaster';
import { useSyncCenter } from '../../../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../../../core/storage/hooks/UseStore';
import { DrugOutcome } from '../../medicationInformation/DrugOutcomeDialog';
import { useApiUtils } from '../../../../../syncstream/utils/hooks/useApiUtils';
import { PrnMedicationInformation } from '../../medicationInformation/PrnMedicationInformation';
import { MedicationListProps } from '../MedicationListsTabbedRouter';
import { CategoryHeading } from './ContinuousMedicationList';
import { EnqueuedDrugCreateData } from '../../../../../syncstream/SyncRounds';
import {useGroupPermissions} from "../../../../../core/authz/PermissionsProvider";
import { MedicationGroup } from '../../../../../syncstream/utils/ResidentDetailsUtils';
import { DebugHover } from '../../../../../core/utils/debughover';

interface Props extends MedicationListProps {
  onAdministerDrug: (
    drug: HSAdministeredDrug,
    doseTimestamp?: string,
    testResults?: HSTestResult[],
  ) => Promise<EnqueuedDrugCreateData | undefined>;
  groupedPackedMedicationList: MedicationGroup[];
  onAddOutcome: (
    outcome: DrugOutcome,
    administeredDrugId: string,
    doseRoundClinicalSystemId: string,
    isAdHoc: boolean,
  ) => Promise<void>;
}


export function PRNsMedicationList(props: Props) {
  const services = useSyncCenter();
  const apiUtils = useApiUtils();
  const roundsStore = useStore(services.rounds.store).store;
  const packedPrnMedicationStore = useStore(services.packedPatientPrnMedications.store).store;
  const values = itiriri(packedPrnMedicationStore.values());
  const packedPatientPrnMedication = values.find((p) => p.patientId === props.patient.hsId) ?? {};
  // TODO: is this permission for administering prns in and out of rounds?
  const groupPermissions = useGroupPermissions();
  const canUndertakeMedicationRound = groupPermissions.canUndertakeMedicationRound;

  const onAdminister = async (drug: HSAdministeredDrug, doseTimestamp?: string, testResults?: HSTestResult[]) => {
    if (!canUndertakeMedicationRound) {
      toasts.error('You do not have permission to administer medication in a round.');
      // TODO:
      throw new Error('Unhandled permissions error, please refactor.');
    }

    return await props.onAdministerDrug(drug, doseTimestamp, testResults);
  };
  const randomNumberInRange = (min: number, max: number) => {
    return Math.floor(Math.random()
        * (max - min + 1)) + min;
  };

  return (
    <>
    <DebugHover name={'PRNsMedicationList'} content={props}>
    <Grid cols={1} gap={0.5} data-testid="prns-medication-list-container">
      <DebugHover name={'CategoryHeading'}>
        <CategoryHeading weight={'bold'} size="large">
          PRNs ({apiUtils.residentDetails.getTotalDoseAmounts(packedPatientPrnMedication.packedPrnMedications ?? []) ?? 0}
          )
        </CategoryHeading>
      </DebugHover>
      {packedPatientPrnMedication.packedPrnMedications &&
        packedPatientPrnMedication.packedPrnMedications
          .sort((a, b) => {
            return (
              (props.drugList &&
                props.drugList
                  .find((d) => d.hsId === a.drugHsId)
                  ?.name?.localeCompare(props.drugList.find((d) => d.hsId === b.drugHsId)?.name ?? '')) ??
              0
            );
          })
          .map((packedPrnMedication) => (
              <Fragment key={'PrnMedicationInformationFragment' + packedPrnMedication.hsId}>
              <DebugHover name={"PrnMedicationInformation" + packedPrnMedication.hsId} key={"PrnMedicationInformation" + packedPrnMedication.hsId}>
                <PrnMedicationInformation
                  {...props}
                  key={packedPrnMedication.hsId}
                  packedPrnMedication={packedPrnMedication}
                  onAdminister={onAdminister}
                  onAddOutcome={props.onAddOutcome}
                  roundsStore={roundsStore}
                  facilityGroupId={props.facilityGroupId}
                />
              </DebugHover>
              </Fragment>
          ))}
    </Grid>
    </DebugHover>
    </>
  );
}
