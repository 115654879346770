import React from 'react';
import { useSyncCenter } from '../../SyncCenterProvider';

export function useQueueDataChecker() {
  const services = useSyncCenter();

  const [hasQueuedData, setHasQueuedData] = React.useState<boolean>();

  React.useEffect(() => {
    const interval = setInterval(async () => setHasQueuedData(await services.syncCenter.hasQueuedData()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return hasQueuedData;
}
