import _ from 'lodash';
import { HSPatient, HSDrug, MedicationType, DrugCategoryMapping } from 'server-openapi';
import { DrugUtils } from '../../../../syncstream/utils/DrugUtils';
import { MIN_THRESHOLD_FOR_POLYPHARMACY, POLYPHARMACY_DRUG_FORMS } from './PolypharmacyData';

interface FacilityMetrics {
  getValue: (
    facilityGroupPatients: HSPatient[],
    allDrugs?: HSDrug[],
    allDrugCategories?: DrugCategoryMapping[],
  ) => string;
  getTypeDescriptor: (residentUILabel: string) => string;
}

export const FACILITY_METRICS: FacilityMetrics[] = [
  {
    getTypeDescriptor: (residentUILabel) => `${residentUILabel} on polypharmacy`,

    getValue: (facilityGroupPatients, allDrugs) => {
      const patientsOnPolypharmacy = facilityGroupPatients.filter((p) => {
        const patientPolypharmacyDrugs =
          p.patientProfiles
            ?.flatMap((pp) => pp.allCurrentMedications)
            .filter((m) => m?.medicationType !== MedicationType.Prn)
            .map((m) => allDrugs!.find((d) => d.hsId === m?.drug))
            .filter((d) => POLYPHARMACY_DRUG_FORMS.includes(d?.formAbbreviation ?? '')) ?? [];

        // count towards polypharmacy only when they have different drug generic names
        const patientDrugsByGenericName = _.groupBy(patientPolypharmacyDrugs, 'genericName');

        return Object.keys(patientDrugsByGenericName).length >= MIN_THRESHOLD_FOR_POLYPHARMACY;
      });

      return ((patientsOnPolypharmacy.length / facilityGroupPatients.length) * 100).toFixed(0) + '%';
    },
  },
  {
    getTypeDescriptor: (residentUILabel) => `${residentUILabel} on psychotropic medication`,
    getValue: (facilityGroupPatients, allDrugs) =>
      (
        (facilityGroupPatients.filter((p) =>
          p.patientProfiles?.some((pp) =>
            pp.allCurrentMedications?.some(
              (m) =>
                DrugUtils.getDrugWarnings(allDrugs!.find((d) => d.hsId === m.drug)!).psychotropic &&
                m.medicationType !== MedicationType.Prn,
            ),
          ),
        ).length /
          facilityGroupPatients.length) *
        100
      ).toFixed(0) + '%',
  },
  {
    getTypeDescriptor: (residentUILabel) => `${residentUILabel} on cytotoxic medication`,
    getValue: (facilityGroupPatients, allDrugs) =>
      (
        (facilityGroupPatients.filter((p) =>
          p.patientProfiles?.some((pp) =>
            pp.allCurrentMedications?.some(
              (m) =>
                DrugUtils.getDrugWarnings(allDrugs!.find((d) => d.hsId === m.drug)!).cytotoxic &&
                m.medicationType !== MedicationType.Prn,
            ),
          ),
        ).length /
          facilityGroupPatients.length) *
        100
      ).toFixed(0) + '%',
  },
  {
    getTypeDescriptor: (residentUILabel) => `${residentUILabel} on syringe drivers`,
    getValue: (facilityGroupPatients) =>
      facilityGroupPatients
        .filter((p) =>
          p.patientProfiles?.some((pp) =>
            pp.allCurrentMedications?.some((m) => m.route?.code === 'SID' && m.medicationType !== MedicationType.Prn),
          ),
        )
        .length.toString(),
  },
  {
    getTypeDescriptor: (residentUILabel) => `${residentUILabel} on antimicrobials`,
    getValue: (facilityGroupPatients, allDrugs, allDrugCategories) =>
      facilityGroupPatients
        .filter((p) =>
          p.patientProfiles?.some((pp) =>
            pp.allCurrentMedications?.some((m) => {
              const drug = allDrugs!.find((d) => d.hsId === m.drug)!;
              const drugCategory = allDrugCategories!.find((c) => c.drug_id === drug?.hsId);

              return drugCategory?.category === 'Antibacterials';
            }),
          ),
        )
        .length.toString(),
  },
];
