/* eslint-disable max-lines-per-function */
import itiriri from 'itiriri';
import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HSFacility, HSPatient } from 'server-openapi';
import styled from 'styled-components';
import { IconBack } from '../../kit/Icons/Back';
import { Layout } from '../../kit/Layout';
import { Text } from '../../kit/Text';
import { useSyncCenter } from '../../syncstream/SyncCenterProvider';
import { useStore } from '../../core/storage/hooks/UseStore';
import { DashboardNavbar } from './DashboardPage/components/DashboardNavbar';
import { Grid } from '../../kit/Grid';
import { SearchInput } from '../../kit/Forms/SearchInput';
import { Select, SelectOption } from '../../kit/Forms/Select';
import { ChartCard, ChartRow, hasReviewOrUpdate } from './components/ChartCard';
import { DateUtils } from '../../core/utils/dateUtils';
import { RadioGroup } from '../../kit/Forms/RadioGroup';
import { useApiUtils } from '../../syncstream/utils/hooks/useApiUtils';
import { PatientUtils } from '../../syncstream/utils/PatientUtils';
import { debounce } from 'lodash-es';
import Badge from "../../kit/Badge";

interface IParams {
  facilityGroupId: string;
}

function mapPatientToChartRow(facility: HSFacility, patient: HSPatient, patientUtils: PatientUtils) {
  const lastChartUpdate = patientUtils.fetchChangedMedicationDetailsFromPatient(patient).lastUpdatedAt;
  const nextChartReview = patient.enrmcValidTo ? DateUtils.toOffsetlessDate(patient.enrmcValidTo) : undefined;

  return {
    chartId: patient.hsId!,
    lastChartUpdate: lastChartUpdate,
    nextChartReview: nextChartReview,
    facilityGroupId: facility.facilityGroupId!,
    facilityName: facility.name ?? undefined,
    patientHsId: patient.hsId!,
    patientRoomNumber: patient.roomNumber ?? undefined,
    patientImageUrl: patient.imageUrl ?? undefined,
    patientGivenName: patient.givenName ?? '',
    patientFamilyName: patient.familyName ?? '',
    patientPreferredName: patient.preferredName ?? '',
  };
}

export function getChartReviews(
  facilities: ReadonlyMap<string, HSFacility>,
  patients: HSPatient[],
  patientUtils: PatientUtils,
  debouncedQuery: string,
  selectedFacility?: HSFacility,
): ChartRow[] {
  return itiriri(patients.values())
    .filter((patient) => {
      const residentName = patientUtils.getQueryPatientString(patient);
      return (
        (!selectedFacility || patient.facility === selectedFacility?.hsId) &&
        residentName.includes(debouncedQuery.toUpperCase())
      );
    })
    .map((patient) => {
      const facility = facilities.get(patient.facility!.toString());

      if (!facility) {
        throw new Error('facility not found!');
      }

      return mapPatientToChartRow(facility, patient, patientUtils);
    })
    .filter((r) => {
      return hasReviewOrUpdate(r);
    })
    .toArray();
}

export function ChartListPage() {
  // Get the currently selected facility from the URL parameters.
  const { facilityGroupId } = useParams<IParams>();
  const services = useSyncCenter();
  const [chartSort, setChartSort] = useState<ChartSort>(ChartSort.LastChartChange);
  const apiUtils = useApiUtils();

  const facilityGroupsStore = useStore(services.facilityGroups.store).store;
  const facilityGroup = itiriri(facilityGroupsStore.values()).find((f) => f.hsId?.toString() === facilityGroupId);

  const facilityStore = useStore(services.facilities.store).store;

  const [filterPatientName, setFilterPatientName] = useState<string>('');
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const debouncedOnSearch = useMemo(() => debounce((query: string) => setDebouncedQuery(query), 500), []);
  const [filterWing, setFilterWing] = useState<HSFacility | undefined>(undefined);

  const facilities = itiriri(facilityStore.values())
    .filter((facility) => facility.facilityGroupId?.toString() === facilityGroupId)
    .toArray();

  const patients = apiUtils.patients
    .getActivePatients()
    .toArray()
    .filter((patient) => facilities.some((facility) => facility.hsId === patient.facility));

  const facilityOptions = facilities.map((facility) => {
    return { label: facility.name ?? 'Unknown', value: facility };
  });

  const wingLabel = apiUtils.facilityGroups.getFacilityUILabel(parseInt(facilityGroupId));
  const residentLabel = apiUtils.facilityGroups.getResidentUILabel(parseInt(facilityGroupId));

  const history = useHistory();
  const rows = getChartReviews(facilityStore, patients, apiUtils.patients, debouncedQuery, filterWing);

  function onSearch(newQuery: string) {
    setFilterPatientName(newQuery);
    debouncedOnSearch(newQuery);
  }

  return (
    <Layout>
      <DashboardNavbar facilityGroup={facilityGroup} />
      <Container gap={1}>
        <Layout horizontal gap={1.5}>
          <IconBack onClick={() => history.push(`/facility-group/${facilityGroupId}`)} />
          <Layout horizontal>
            <Text weight={'bold'} size="large" style={{ color: 'white' }}>
              Charts
            </Text>
            <Badge type={'info'} text={rows.length}></Badge>
          </Layout>
        </Layout>
        <Grid cols={3} colsTemplate={'auto auto max-content'} colsMobile={1} gap={1}>
          <SearchInput
            onChange={(_, v) => onSearch(v)}
            value={filterPatientName}
            name="search"
            placeholder={`Search ${residentLabel}`}
            fullWidth
          />
          {facilityOptions.length === 1 ? (
            <WingNameBox>
              <Text>{facilityOptions[0].label}</Text>
            </WingNameBox>
          ) : (
            <Select
              fullWidth
              placeholder={`All ${wingLabel}`}
              name="wing"
              options={facilityOptions}
              value={filterWing}
              onChange={(_, value) => setFilterWing(value)}
            />
          )}
          <RadioGroupWrapper>
            <RadioGroup
              name="chartSort"
              cols={2}
              options={chartSortOptions}
              value={chartSort}
              onChange={(_, value) => setChartSort(value)}
            />
          </RadioGroupWrapper>
        </Grid>
      </Container>
      {rows &&
        Filter(rows)
          .sort((lhs, rhs) => Sort(chartSort, lhs, rhs))
          .map((row) => <ChartCard key={row.chartId} {...row} />)}
    </Layout>
  );
}

function Filter(rows: ChartRow[]): ChartRow[] {
  return rows;
}

function Sort(sort: ChartSort, lhs: ChartRow, rhs: ChartRow) {
  if (sort === ChartSort.ChartReviewDate) {
    const cmp = DateUtils.compareDates(lhs.nextChartReview, rhs.nextChartReview, true);
    if (cmp !== 0) {
      return cmp;
    }
  }

  return DateUtils.compareDates(lhs.lastChartUpdate, rhs.lastChartUpdate) + 10;
}

const Container = styled(Layout)`
  margin: 1.5rem;
`;

const WingNameBox = styled(Layout)`
  display: flex;
  padding: ${(props) => props.theme.forms.input.padding};
  align-items: center;
  padding: 0.875rem;
  width: 100%;
  background: white;
`;

const RadioGroupWrapper = styled(Layout)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.backgrounds.default.fg};
`;

enum ChartSort {
  LastChartChange,
  ChartReviewDate,
}

const chartSortOptions: SelectOption<ChartSort>[] = [
  {
    label: 'Last chart change',
    value: ChartSort.LastChartChange,
  },
  {
    label: 'Chart review date',
    value: ChartSort.ChartReviewDate,
  },
];
