import { debounce } from 'lodash-es';

/* eslint-disable no-console */
export function startLiveReloadListener() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((process.env as any).LIVERELOAD === 'ON') {
    const listener = new LiveListener();
    listener.start();
  }
}

class LiveListener {
  private client = new window.WebSocket('ws://localmachine:17721');

  async start() {
    this.connect();
  }

  private onClose() {
    console.log('Live reload listener is disconnected');
    setTimeout(() => this.connect(), 500);
  }

  private onOpen() {
    console.log('Live reload listener initialized');

    this.client.onmessage = (e) => {
      if (e.data === 'DEV:RELOAD') {
        debounce(() => {
          console.log('Live reloading');
          location.reload();
        }, 100)();
      }
    };
  }

  private async connect() {
    return new Promise<void>((res) => {
      console.log('Connecting to live reload server');
      this.client = new window.WebSocket('ws://localmachine:17721');
      this.client.onclose = () => this.onClose();
      this.client.onopen = () => {
        this.onOpen();
        res();
      };
    });
  }
}
