import * as React from 'react';
import { SVGProps } from 'react';
import { Tooltip } from 'react-tooltip';

const SelfAdministeredIcon = (props: SVGProps<SVGSVGElement>) => (
    <>
        <Tooltip id="SelfAdministeredIcon" />
          <svg width="1em" height="1em" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}
               style={props.style}
               data-tooltip-content={props.xlinkTitle}
               data-tooltip-id="TickInsideGreenCircle"
               data-tooltip-place="top">
            <path
              d="M12 10.143H9.857V8H9v2.143H6.857V11H9v2.143h.857V11H12v-.857ZM5.143 7.143a3.429 3.429 0 1 0 0-6.857 3.429 3.429 0 0 0 0 6.857Z"
              fill="#1A1A1A"
            />
            <path
              d="M8.143 11.857H6V9.286h2.143V7.273c-.104-.076-.206-.155-.316-.223a4.263 4.263 0 0 1-5.368 0A5.139 5.139 0 0 0 0 11.43v1.714h8.143v-1.286Z"
              fill="#1A1A1A"
            />
          </svg>
        </>
);

export default SelfAdministeredIcon;
