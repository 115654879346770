import { CSSProperties } from 'styled-components';

enum PatchArea {
  HeadFront = 'Head Front',
  HeadBack = 'Head Back',
  HeadLeftSide = 'Head Left Side',
  HeadRightSide = 'Head Right Side',
  FrontRightChest = 'Front Right Chest',
  FrontLeftChest = 'Front Left Chest',
  BackLeftShoulder = 'Back Left Shoulder',
  BackRightShoulder = 'Back Right Shoulder',
  RightUpperArm = 'Right Upper Arm',
  LeftUpperArm = 'Left Upper Arm',
  RightTorso = 'Right Torso',
  LeftTorso = 'Left Torso',
  LowerArmFrontRightSide = 'Lower Arm Front Right Side',
  LowerArmFrontLeftSide = 'Lower Arm Front Left Side',
  LowerArmBackLeftSide = 'Lower Arm Back Left Side',
  LowerArmBackRightSide = 'Lower Arm Back Right Side',
  LowerArmSideLeftSide = 'Lower Arm Left Side',
  LowerArmSideRightSide = 'Lower Arm Right Side',
  UpperAbdomenFrontRightSide = 'Upper Abdomen Front Right Side',
  UpperAbdomenFrontLeftSide = 'Upper Abdomen Front Left Side',
  LowerAbdomenLeftSide = 'Lower Abdomen Left Side',
  LowerAbdomenRightSide = 'Lower Abdomen Right Side',
  ButtockLeftSide = 'Buttock Left Side',
  ButtockRightSide = 'Buttock Right Side',
  LowerBackRightSide = 'Lower Back Right Side',
  LowerBackLeftSide = 'Lower Back Left Side',
  UpperLegFrontRightSide = 'Upper Leg Front Right Side',
  UpperLegFrontLeftSide = 'Upper Leg Front Left Side',
  UpperLegBackLeftSide = 'Upper Leg Back Left Side',
  UpperLegBackRightSide = 'Upper Leg Back Right Side',
  UpperLegLeftSide = 'Upper Leg Left Side',
  UpperLegRightSide = 'Upper Leg Right Side',
  HandLeft = 'Hand Left',
  HandRight = 'Hand Right',
  LowerLegFrontRightSide = 'Lower Leg Front Right Side',
  LowerLegFrontLeftSide = 'Lower Leg Front Left Side',
  LowerLegBackLeftSide = 'Lower Leg Back Left Side',
  LowerLegBackRightSide = 'Lower Leg Back Right Side',
  LowerLegSideLeftSide = 'Lower Leg Side Left Side',
  LowerLegSideRightSide = 'Lower Leg Side Right Side',
  FootFrontRightSide = 'Foot Front Right Side',
  FootFrontLeftSide = 'Foot Front Left Side',
  FootBackLeftSide = 'Foot Back Left Side',
  FootBackRightSide = 'Foot Back Right Side',
  FootSideLeftSide = 'Foot Side Left Side',
  FootSideRightSide = 'Foot Side Right Side',
}

export interface PatchLocationDTO {
  LocationNumber: number;
  PatchArea: PatchArea;
  PositionInfo: CSSProperties; // position the marker using top % absolute wrt. parent container and translate horizontally based on the middle of the (mirror) avatars image
}

export const PatchLocations: PatchLocationDTO[] = [
  {
    LocationNumber: 1,
    PatchArea: PatchArea.FrontRightChest,
    PositionInfo: { top: '24%', transform: 'translate(-350px)' },
  },
  {
    LocationNumber: 2,
    PatchArea: PatchArea.FrontRightChest,
    PositionInfo: { top: '24%', transform: 'translate(-320px)' },
  },
  {
    LocationNumber: 3,
    PatchArea: PatchArea.FrontRightChest,
    PositionInfo: { top: '29%', transform: 'translate(-350px)' },
  },
  {
    LocationNumber: 4,
    PatchArea: PatchArea.FrontRightChest,
    PositionInfo: { top: '29%', transform: 'translate(-320px)' },
  },
  {
    LocationNumber: 5,
    PatchArea: PatchArea.FrontLeftChest,
    PositionInfo: { top: '24%', transform: 'translate(-285px)' },
  },
  {
    LocationNumber: 6,
    PatchArea: PatchArea.FrontLeftChest,
    PositionInfo: { top: '24%', transform: 'translate(-255px)' },
  },
  {
    LocationNumber: 7,
    PatchArea: PatchArea.FrontLeftChest,
    PositionInfo: { top: '29%', transform: 'translate(-285px)' },
  },
  {
    LocationNumber: 8,
    PatchArea: PatchArea.FrontLeftChest,
    PositionInfo: { top: '29%', transform: 'translate(-255px)' },
  },
  {
    LocationNumber: 9,
    PatchArea: PatchArea.BackLeftShoulder,
    PositionInfo: { top: '24%', transform: 'translate(-110px)' },
  },
  {
    LocationNumber: 10,
    PatchArea: PatchArea.BackLeftShoulder,
    PositionInfo: { top: '24%', transform: 'translate(-80px)' },
  },
  {
    LocationNumber: 11,
    PatchArea: PatchArea.BackLeftShoulder,
    PositionInfo: { top: '30%', transform: 'translate(-110px)' },
  },
  {
    LocationNumber: 12,
    PatchArea: PatchArea.BackLeftShoulder,
    PositionInfo: { top: '30%', transform: 'translate(-80px)' },
  },
  {
    LocationNumber: 13,
    PatchArea: PatchArea.BackRightShoulder,
    PositionInfo: { top: '24%', transform: 'translate(-45px)' },
  },
  {
    LocationNumber: 14,
    PatchArea: PatchArea.BackRightShoulder,
    PositionInfo: { top: '24%', transform: 'translate(-15px)' },
  },
  {
    LocationNumber: 15,
    PatchArea: PatchArea.BackRightShoulder,
    PositionInfo: { top: '30%', transform: 'translate(-45px)' },
  },
  {
    LocationNumber: 16,
    PatchArea: PatchArea.BackRightShoulder,
    PositionInfo: { top: '30%', transform: 'translate(-15px)' },
  },
  {
    LocationNumber: 17,
    PatchArea: PatchArea.RightUpperArm,
    PositionInfo: { top: '24%', transform: 'translate(295px)' },
  },
  {
    LocationNumber: 18,
    PatchArea: PatchArea.RightUpperArm,
    PositionInfo: { top: '29%', transform: 'translate(295px)' },
  },
  {
    LocationNumber: 19,
    PatchArea: PatchArea.RightTorso,
    PositionInfo: { top: '31%', transform: 'translate(335px)' },
  },
  {
    LocationNumber: 20,
    PatchArea: PatchArea.RightTorso,
    PositionInfo: { top: '35%', transform: 'translate(325px)' },
  },
  {
    LocationNumber: 21,
    PatchArea: PatchArea.LeftUpperArm,
    PositionInfo: { top: '24%', transform: 'translate(150px)' },
  },
  {
    LocationNumber: 22,
    PatchArea: PatchArea.LeftUpperArm,
    PositionInfo: { top: '29%', transform: 'translate(150px)' },
  },
  {
    LocationNumber: 23,
    PatchArea: PatchArea.LeftTorso,
    PositionInfo: { top: '31%', transform: 'translate(110px)' },
  },
  {
    LocationNumber: 24,
    PatchArea: PatchArea.LeftTorso,
    PositionInfo: { top: '35%', transform: 'translate(120px)' },
  },
  {
    LocationNumber: 25,
    PatchArea: PatchArea.HeadFront,
    PositionInfo: {top: '7%', transform: 'translate(-303px)'},
  },
  {
    LocationNumber: 26,
    PatchArea: PatchArea.HeadBack,
    PositionInfo: {top: '7%', transform: 'translate(-62px)'},
  },
  {
    LocationNumber: 27,
    PatchArea: PatchArea.HeadLeftSide,
    PositionInfo: {top: '7%', transform: 'translate(150px)'},
  },
  {
    LocationNumber: 28,
    PatchArea: PatchArea.HeadRightSide,
    PositionInfo: {top: '7%', transform: 'translate(300px)'},
  },
  {
    LocationNumber: 29,
    PatchArea: PatchArea.LowerArmFrontRightSide,
    PositionInfo: {top: '40%', transform: 'translate(-390px)'},
  },
  {
    LocationNumber: 30,
    PatchArea: PatchArea.LowerArmFrontLeftSide,
    PositionInfo: {top: '40%', transform: 'translate(-215px)'},
  },
  {
    LocationNumber: 31,
    PatchArea: PatchArea.LowerArmBackLeftSide,
    PositionInfo: {top: '40%', transform: 'translate(-150px)'}
  },
  {
    LocationNumber: 32,
    PatchArea: PatchArea.LowerArmBackRightSide,
    PositionInfo: {top: '40%', transform: 'translate(28px)'}
  },
  {
    LocationNumber: 33,
    PatchArea: PatchArea.LowerArmSideLeftSide,
    PositionInfo: {top: '40%', transform: 'translate(150px)'}
  },
  {
    LocationNumber: 34,
    PatchArea: PatchArea.LowerArmSideRightSide,
    PositionInfo: {top: '40%', transform: 'translate(297px)'}
  },
  {
    LocationNumber: 35,
    PatchArea: PatchArea.UpperAbdomenFrontRightSide,
    PositionInfo: {top: '39%', transform: 'translate(-334px)'}
  },
  {
    LocationNumber: 36,
    PatchArea: PatchArea.UpperAbdomenFrontLeftSide,
    PositionInfo: {top: '39%', transform: 'translate(-271px)'}
  },
  {
    LocationNumber: 37,
    PatchArea: PatchArea.LowerAbdomenLeftSide,
    PositionInfo: {top: '45%', transform: 'translate(-260px)'}
  },
  {
    LocationNumber: 38,
    PatchArea: PatchArea.LowerAbdomenRightSide,
    PositionInfo: {top: '45%', transform: 'translate(-345px)'}
  },
  {
    LocationNumber: 39,
    PatchArea: PatchArea.ButtockLeftSide,
    PositionInfo: {top: '45%', transform: 'translate(-100px)'}
  },
  {
    LocationNumber: 40,
    PatchArea: PatchArea.ButtockRightSide,
    PositionInfo: {top: '45%', transform: 'translate(-23px)'}
  },
  {
    LocationNumber: 41,
    PatchArea: PatchArea.LowerBackRightSide,
    PositionInfo: {top: '40%', transform: 'translate(-30px)'}
  },
  {
    LocationNumber: 42,
    PatchArea: PatchArea.LowerBackLeftSide,
    PositionInfo: {top: '40%', transform: 'translate(-92px)'}
  },
  {
    LocationNumber: 43,
    PatchArea: PatchArea.UpperLegFrontRightSide,
    PositionInfo: {top: '52%', transform: 'translate(-340px)'}
  },
  {
    LocationNumber: 44,
    PatchArea: PatchArea.UpperLegFrontLeftSide,
    PositionInfo: {top: '52%', transform: 'translate(-265px)'}
  },
  {
    LocationNumber: 45,
    PatchArea: PatchArea.UpperLegFrontLeftSide,
    PositionInfo: {top: '60%', transform: 'translate(-265px)'}
  },
  {
    LocationNumber: 46,
    PatchArea: PatchArea.UpperLegFrontRightSide,
    PositionInfo: {top: '60%', transform: 'translate(-340px)'}
  },
  {
    LocationNumber: 47,
    PatchArea: PatchArea.UpperLegBackLeftSide,
    PositionInfo: {top: '54%', transform: 'translate(-100px)'}
  },
  {
    LocationNumber: 48,
    PatchArea: PatchArea.UpperLegBackRightSide,
    PositionInfo: {top: '54%', transform: 'translate(-22px)'}
  },
  {
    LocationNumber: 49,
    PatchArea: PatchArea.UpperLegBackRightSide,
    PositionInfo: {top: '60%', transform: 'translate(-22px)'}
  },
  {
    LocationNumber: 50,
    PatchArea: PatchArea.UpperLegBackLeftSide,
    PositionInfo: {top: '60%', transform: 'translate(-100px)'}
  },
  {
    LocationNumber: 51,
    PatchArea: PatchArea.UpperLegLeftSide,
    PositionInfo: {top: '61%', transform: 'translate(135px)'}
  },
  {
    LocationNumber: 52,
    PatchArea: PatchArea.UpperLegRightSide,
    PositionInfo: {top: '61%', transform: 'translate(305px)'}
  },
  {
    LocationNumber: 53,
    PatchArea: PatchArea.HandLeft,
    PositionInfo: {top: '51%', transform: 'translate(125px)'}
  },
  {
    LocationNumber: 54,
    PatchArea: PatchArea.HandRight,
    PositionInfo: {top: '51%', transform: 'translate(320px)'}
  },
  {
    LocationNumber: 55,
    PatchArea: PatchArea.LowerLegFrontRightSide,
    PositionInfo: {top: '75%', transform: 'translate(-340px)'}
  },
  {
    LocationNumber: 56,
    PatchArea: PatchArea.LowerLegFrontLeftSide,
    PositionInfo: {top: '75%', transform: 'translate(-265px)'}
  },
  {
    LocationNumber: 57,
    PatchArea: PatchArea.LowerLegBackLeftSide,
    PositionInfo: {top: '75%', transform: 'translate(-100px)'}
  },
  {
    LocationNumber: 58,
    PatchArea: PatchArea.LowerLegBackRightSide,
    PositionInfo: {top: '75%', transform: 'translate(-22px)'}
  },
  {
    LocationNumber: 59,
    PatchArea: PatchArea.LowerLegSideLeftSide,
    PositionInfo: {top: '75%', transform: 'translate(150px)'}
  },
  {
    LocationNumber: 60,
    PatchArea: PatchArea.LowerLegSideRightSide,
    PositionInfo: {top: '75%', transform: 'translate(295px)'}
  },
  {
    LocationNumber: 61,
    PatchArea: PatchArea.FootFrontRightSide,
    PositionInfo: {top: '90%', transform: 'translate(-343px)'}
  },
  {
    LocationNumber: 62,
    PatchArea: PatchArea.FootFrontLeftSide,
    PositionInfo: {top: '90%', transform: 'translate(-263px)'}
  },
  {
    LocationNumber: 63,
    PatchArea: PatchArea.FootBackLeftSide,
    PositionInfo: {top: '90%', transform: 'translate(-100px)'}
  },
  {
    LocationNumber: 64,
    PatchArea: PatchArea.FootBackRightSide,
    PositionInfo: {top: '90%', transform: 'translate(-22px)'}
  },
  {
    LocationNumber: 65,
    PatchArea: PatchArea.FootSideLeftSide,
    PositionInfo: {top: '90%', transform: 'translate(150px)'}
  },
  {
    LocationNumber: 66,
    PatchArea: PatchArea.FootSideRightSide,
    PositionInfo: {top: '90%', transform: 'translate(295px)'}
  },
];
