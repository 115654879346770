import React, { Dispatch, useCallback, useMemo, useReducer, useState } from "react";
import _ from "lodash";
import { addDays, endOfDay, isAfter, isBefore, isValid, startOfDay } from 'date-fns';
import { DateUtils, Interval } from "../../../core/utils/dateUtils";
import { createServiceProvider } from "../../../context/AppServiceProvider";
import { RoundScheduleItem, RoundUtils, RoundUtilStores } from "../../../syncstream/utils/RoundUtils";
import { useSyncCenter } from "../../../syncstream/SyncCenterProvider";
import { useMap } from "../../../core/storage/hooks/UseStore";
import {Logger} from "../../../core/logger/logger";

interface Parameters {
    // Stores used to calculate result
    stores: RoundUtilStores;
    interval: Interval;
    facilityIds: number[];
}
const logger = new Logger('GetSchedule');

interface NeedNewRoundScheduleProps {
    cachedParameters?: Parameters | null;
    requestedParameters: Parameters;
}
function needNewRoundSchedule(props: NeedNewRoundScheduleProps): boolean {
    const {cachedParameters, requestedParameters} = props;
    if (!cachedParameters) {
        return true;
    }
    if (!_.isEqual(cachedParameters.stores, requestedParameters.stores) ||
        !_.isEqual(cachedParameters.facilityIds, requestedParameters.facilityIds)) {
        return true;
    }

    const cacheStart = DateUtils.toDate(cachedParameters.interval.start);
    const cacheEnd = DateUtils.toDate(cachedParameters.interval.end);
    const requestedStart = DateUtils.toDate(requestedParameters.interval.start);
    const requestedEnd = DateUtils.toDate(requestedParameters.interval.end);

    return isBefore(requestedStart, cacheStart) || isAfter(requestedEnd, cacheEnd);
}

// This function was no longer used because of the Cinderella bug where before midnight it would
// include the following day's data
// eslint-disable-next-line sonarjs/cognitive-complexity
function areParametersEqual(p1: Parameters | null, p2 : Parameters | null) {
    if (!p1 || !p2) {
        return false;
    }
    if (_.isEqual(p1, p2)) {
        return true;
    }

    // This helps with testing to verify what exactly has changed.
    // At some point it could be removed.

    /****

    if (!_.isEqual(p1.interval, p2.interval)) {
        console.log("GetSchedule: interval has changed");
    }
    if (!_.isEqual(p1.facilityIds, p2.facilityIds)) {
        console.log("GetSchedule: facilities have changed");
    }
    if (!_.isEqual(p1.stores, p2.stores)) {
        if (p1.stores.drugStore !== p2.stores.drugStore) {
            console.log("GetSchedule: drugs have changed");
        }
        if (p1.stores.facilityStore !== p2.stores.facilityStore) {
            console.log("GetSchedule: facilities have changed");
        }
        if (p1.stores.roundStore !== p2.stores.roundStore) {
            console.log("GetSchedule: rounds have changed");
        }
        if (p1.stores.patientStore !== p2.stores.patientStore) {
            console.log("GetSchedule: patients have changed");
        }
        if (p1.stores.packedDayStore !== p2.stores.packedDayStore) {
            console.log("GetSchedule: packed days have changed");
        }
        if (p1.stores.facilityGroupConfigurationStore !== p2.stores.facilityGroupConfigurationStore) {
            console.log("GetSchedule: facility group configurations have changed");
        }
        if (p1.stores.packedPrnStore !== p2.stores.packedPrnStore) {
            console.log("GetSchedule: packed prns have changed");
        }
        if (p1.stores.patchObservationStore !== p2.stores.patchObservationStore) {
            console.log("GetSchedule: patch observations have changed");
        }
        if (p1.stores.syringeDriverActivityStore !== p2.stores.syringeDriverActivityStore) {
            console.log("GetSchedule: syringe driver activities have changed");
        }
    }

    ***/

    return false;
}

interface State {
    parameters: Parameters | null;
    result: RoundScheduleItem[];
}

const initialState: State = {
    parameters: null,
    result: []
};

type ContextValue = [State, Dispatch<Action>];

const [Provider, useHook] = createServiceProvider<ContextValue>();

interface ReplaceStateAction {
    type: "REPLACE";
    payload: State;
}

type Action = ReplaceStateAction;

function reducer(state: State, action: Action): State {
    if (action.type === "REPLACE") {
        state = action.payload;
    }
    return state;
}

interface GetScheduleProviderProps {
    children: React.ReactNode;
}
export function GetScheduleProvider(props: GetScheduleProviderProps) {
    const value = useReducer(reducer, initialState);
    const {children} = props;

    return (
        <Provider value={value}>
            {children}
        </Provider>
    );
}

function useRoundUtilStore(): RoundUtilStores {
    const services = useSyncCenter();

    const packedDayStore = useMap(services.packedPatientDays);
    const patientStore = useMap(services.patients);
    const drugStore = useMap(services.drugs);
    const packedPrnStore = useMap(services.packedPatientPrnMedications);
    const roundStore = useMap(services.rounds);
    const facilityStore = useMap(services.facilities);
    const facilityGroupConfigurationStore = useMap(services.facilityGroupConfigurations);
    const syringeDriverActivityStore = useMap(services.syringeDriverActivity);
    const patchObservationStore = useMap(services.patchObservations);

    return useMemo(() => ({
        packedDayStore,
        patientStore,
        drugStore,
        packedPrnStore,
        roundStore,
        facilityStore,
        facilityGroupConfigurationStore,
        syringeDriverActivityStore,
        patchObservationStore
    }), [
        packedDayStore,
        patientStore,
        drugStore,
        packedPrnStore,
        roundStore,
        facilityStore,
        facilityGroupConfigurationStore,
        syringeDriverActivityStore,
        patchObservationStore
    ]);
}

// This returns a function that can be called from within a side-effect or a render method
export function useGetScheduleFromParameters() {
    const contextValue = useHook();
    const stores = useRoundUtilStore();
    return useCallback((interval: Interval, facilityIds: number[]) => {
        return getScheduleFromParameters(interval, facilityIds, stores, contextValue);
    }, [contextValue, stores]);
}

function getScheduleFromParameters(interval: Interval, facilityIds: number[], stores: RoundUtilStores, contextValue: ContextValue) {
    const dayParameters = getParametersForDay(interval, facilityIds, stores);
    const dayItems = getScheduleForDay(dayParameters, contextValue);
    return filterItems(dayItems, interval);
}

function filterItems(items: RoundScheduleItem[], interval: Interval): RoundScheduleItem[] {
    const filteredItems: RoundScheduleItem[] = [];
    const dateInterval = DateUtils.intervalToDateInterval(interval);

    for (const item of items) {
        const timestamp = getTimestamp(item);
        if (isBefore(timestamp, dateInterval.start)) {
            continue;
        }
        if (isAfter(timestamp, dateInterval.end)) {
            continue;
        }
        filteredItems.push(item);
    }

    return filteredItems;
}

function getTimestamp(item: RoundScheduleItem): Date {
    if (item.scheduledActivity?.time && isValid(item.scheduledActivity?.time)) {
        return item.scheduledActivity.time;
    }
    return DateUtils.toDate(item.packedMedication.doseTimestamp!);
}

function getParametersForDay(interval: Interval, facilityIds: number[], stores: RoundUtilStores): Parameters {
    const dateInterval = DateUtils.intervalToDateInterval(interval);
    dateInterval.start = startOfDay(dateInterval.start);
    // now we are getting tomorrow's data as well
    dateInterval.end = endOfDay(dateInterval.end);
      // if (Math.random() > 0.5) {
      //     console.log("Adding an Extra Day");
      //     dateInterval.end = addDays(dateInterval.end, 1);
      // }

    const dayInterval: Interval = {
        start: DateUtils.fromDate(dateInterval.start),
        end: DateUtils.fromDate(dateInterval.end)
    };

    facilityIds = [...facilityIds];
    facilityIds.sort((id1, id2) => id1 - id2);

    stores = { ...stores };

    return {
        stores,
        facilityIds,
        interval: dayInterval
    };
}

// getScheduleForDay gets called multiple times concurrently.  This tempState is a rather pathetic
// attempt to stop this recalculating.
let tempState: State | null = null;
function getScheduleForDay(parameters: Parameters, contextValue: ContextValue) {
    const [state, dispatch] = contextValue;

    if (!needNewRoundSchedule({cachedParameters: state.parameters, requestedParameters: parameters})) {
        return state.result;
    }

    if (tempState && !needNewRoundSchedule({cachedParameters: tempState!.parameters, requestedParameters: parameters})) {
        return tempState!.result;
    }
    logger.info("Recomputing daily schedule");
    const roundUtils = new RoundUtils(parameters.stores);
    const result = roundUtils.getScheduleFromParameters(parameters.interval, parameters.facilityIds);
    tempState = {
        parameters: {...parameters},
        result: [...result]
    }
    const newState = {
        parameters: {...parameters},
        result: [...result]
    };
    setTimeout(() => dispatch({type: "REPLACE", payload: newState}));
    setTimeout(() => tempState = null);
    logger.info("Daily schedule recomputed");
    return result;
}
