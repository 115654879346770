import React from 'react';
import { Grid } from '../../../../../kit/Grid';
import {
  HSAdministeredDrug,
  HSDoseRound,
  HSPackedMedication,
  ReasonCode,
  SyringeDriverActivityKind,
} from 'server-openapi';
import { MedicationInformationBox } from '../../medicationInformation/MedicationInformationBox';
import { DateUtils } from '../../../../../core/utils/dateUtils';
import { CategoryHeading } from './ContinuousMedicationList';
import { useSyncCenter } from '../../../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../../../core/storage/hooks/UseStore';
import { MedicationListProps } from '../MedicationListsTabbedRouter';
import { useApiUtils } from '../../../../../syncstream/utils/hooks/useApiUtils';
import { ScheduledActivity } from '../../../../../syncstream/utils/RoundUtils';
import itiriri from 'itiriri';
import { MedicationGroup } from '../../../../../syncstream/utils/ResidentDetailsUtils';
import { colors } from '../../../../../kit/Theme/Theme';
import { EnqueuedDrugCreateData } from '../../../../../syncstream/SyncRounds';

interface Props extends MedicationListProps {
  onAdministerDrug: (drug: HSAdministeredDrug, doseTimestamp: string) => Promise<EnqueuedDrugCreateData | undefined>;
  groupedPackedMedicationList: MedicationGroup[];
  lastTwoPackedPatientDaysMedication: HSPackedMedication[];
  displayAllActions?: boolean;
}

export function SyringeDriversMedicationList(props: Props) {
  const services = useSyncCenter();
  const apiUtils = useApiUtils();

  const roundsStore = useStore(services.rounds.store).store;

  const roundWindow = props.currentRound?.createdAt
    ? apiUtils.rounds.getRoundWindow(DateUtils.toDate(props.currentRound.createdAt), props.facilityGroupId)
    : undefined;

  const syringeDrivers = props.groupedPackedMedicationList.filter(
    (group, index) =>
      group.categories.isSyringeDriver &&
      (!props.displayAllActions ||
        group.scheduledActivityAction === undefined ||
        index ===
          props.groupedPackedMedicationList.findIndex(
            (group2) =>
              group.scheduledActivityAction?.packedMed?.hsId === group2.scheduledActivityAction?.packedMed?.hsId,
          )) &&
      (props.displayAllActions ||
        group.scheduledActivityAction === undefined ||
        !roundWindow ||
        (DateUtils.toDate(roundWindow.start) <= group.scheduledActivityAction!.activity.time &&
          DateUtils.toDate(roundWindow.end) >= group.scheduledActivityAction!.activity.time)),
  );

  return (
    <Grid cols={1} gap={1}>
      {syringeDrivers.map((driverList, index) => (
        <Grid cols={1} gap={0.5} key={index}>
          <CategoryHeading weight={'bold'}>
            {`${
              driverList.categories.doseDate && DateUtils.dateTo24HourTimeString(driverList.categories.doseDate)
            } Syringe Drivers ${driverList.categories.profileNumber ? `${driverList.categories.profileNumber} ` : ''}(${
              apiUtils.residentDetails.getTotalDoseAmounts([
                ...driverList.medications,
                ...(driverList.scheduledActivityAction?.packedMed
                  ? [driverList.scheduledActivityAction.packedMed]
                  : []),
              ]) ?? 0
            })`}
          </CategoryHeading>
          {driverList.medications.map((packedMedication) => (
            <SyringeDriverMedicationInformation
              key={packedMedication.hsId}
              packedMedication={packedMedication}
              currentDosedDrug={apiUtils.rounds.getMostRecentDrugInRoundByPackedMedication(
                packedMedication,
                props.patient.hsId!,
                props.currentRound,
              )}
              onAdminister={props.onAdministerDrug}
              roundsStore={roundsStore}
              status={apiUtils.rounds.getPackedMedicationStatus(packedMedication, props.patient.hsId!)}
              {...(props as MedicationListProps)}
            />
          ))}
          {driverList.scheduledActivityAction && (
            <SyringeDriverMedicationInformation
              key={driverList.scheduledActivityAction.packedMed.hsId}
              packedMedication={driverList.scheduledActivityAction.packedMed}
              currentDosedDrug={apiUtils.rounds.getMostRecentDrugInRoundByPackedMedication(
                driverList.scheduledActivityAction.packedMed,
                props.patient.hsId!,
                props.currentRound,
              )}
              selectedActivity={driverList.scheduledActivityAction?.activity}
              onAdminister={props.onAdministerDrug}
              roundsStore={roundsStore}
              status={apiUtils.rounds.getPackedMedicationStatus(
                driverList.scheduledActivityAction.packedMed,
                props.patient.hsId!,
              )}
              displayAllActivity={props.displayAllActions}
              {...(props as MedicationListProps)}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
}

interface MedicationInformationProps extends MedicationListProps {
  packedMedication: HSPackedMedication;
  currentDosedDrug?: HSAdministeredDrug;
  onAdminister: (drug: HSAdministeredDrug, doseTimestamp: string) => Promise<EnqueuedDrugCreateData | undefined>;
  roundsStore: ReadonlyMap<string, HSDoseRound>;
  selectedActivity?: ScheduledActivity;
  status?: ReasonCode;
  displayAllActivity?: boolean;
}

function SyringeDriverMedicationInformation(props: MedicationInformationProps) {
  const services = useSyncCenter();
  const syringeDriverActivityStore = useStore(services.syringeDriverActivity.store).store;
  const activities = itiriri(syringeDriverActivityStore.values())
    .filter(
      (activity) =>
        activity.administeredDrugId?.toString() === (props.selectedActivity?.administeredDrugId ?? false) ||
        activity.administeredDrugClinicalSystemId ===
          (props.selectedActivity?.administeredDrugClinicalSystemId ?? false),
    )
    .toArray();

  const startActivity = activities.find((activity) => activity.kind === SyringeDriverActivityKind.Start);

  const latestActivity =
    activities
      ?.sort((a, b) => DateUtils.compareDateStringsDescending(a.createdAt, b.createdAt))
      .find((activity) => !!activity.timeRemaining && !!activity.createdAt) ?? startActivity;

  const latestStartOrStopOrPause = activities
    ?.sort((a, b) => DateUtils.compareDateStringsDescending(a.createdAt, b.createdAt))
    .find((activity) => activity.kind !== SyringeDriverActivityKind.Observation);

  const userUtils = useApiUtils().users;

  const activityRound = itiriri(props.roundsStore.values()).find(
    (round) =>
      round.administeredDoses?.some((dose) =>
        dose.administeredDrugs?.some(
          (drug) =>
            drug.clinicalSystemId === props.selectedActivity?.administeredDrugClinicalSystemId ||
            drug.hsId?.toString() === props.selectedActivity?.administeredDrugId,
        ),
      ) ?? false,
  )!;

  function getStatusLabel() {
    switch (latestStartOrStopOrPause?.kind) {
      case SyringeDriverActivityKind.Cease:
        return { label: 'CEASED', colour: colors.text_white, backgroundColour: colors.bg_purple_700 };
      case SyringeDriverActivityKind.Pause:
        return { label: 'PAUSED', colour: colors.text_white, backgroundColour: colors.bg_purple_700 };
      case SyringeDriverActivityKind.Stop:
        return { label: 'STOPPED', colour: colors.text_white, backgroundColour: colors.bg_purple_700 };
      case SyringeDriverActivityKind.Restart:
      case SyringeDriverActivityKind.Start:
        return { label: 'RUNNING', colour: colors.text_black, backgroundColour: colors.bg_green_700 };
      default:
        return undefined;
    }
  }

  return (
    <MedicationInformationBox
      infoLabel={
        startActivity && startActivity?.createdAt
          ? `Applied to ${startActivity.sitePosition} at ${DateUtils.dateStringTo24HourTimeString(
              startActivity.createdAt,
            )} by ${userUtils.getUserFullNameAndRoleFromSubjectId(
              startActivity.lastUpdatedBySubjectId,
            )} on ${DateUtils.toDate(startActivity.createdAt).toDateString()}`
          : 'Not started'
      }
      packedMedication={props.packedMedication}
      currentDosedDrug={props.currentDosedDrug}
      onAdminister={props.onAdminister}
      scheduledTime={props.packedMedication.doseTimestamp}
      scheduledActivityInformation={
        props.selectedActivity && startActivity
          ? {
              selectedActivity: props.selectedActivity,
              startActivity: startActivity,
              latestActivity: latestActivity!,
              activityRound: activityRound,
              displayAllActivity: props.displayAllActivity ?? false,
            }
          : undefined
      }
      status={props.status}
      statusLabel={getStatusLabel()}
      {...(props as MedicationListProps)}
    />
  );
}
