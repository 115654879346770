import React from 'react';
import { Layout } from '../../../../kit/Layout';
import styled, { useTheme } from 'styled-components';
import { Grid } from '../../../../kit/Grid';
import { Button } from '../../../../kit/Button';
import { FormGroup } from '../../../../kit/Forms/FormGroup';
import { TextArea } from '../../../../kit/Forms/TextArea';
import { DateInput } from '../../../../kit/Forms/DateInput';
import { Form } from '../../../../kit/Forms/Form';
import { useForm } from 'react-hook-form';
import { isBefore } from 'date-fns';
import { SyncStreamAPI } from '../../../../syncstream/api';
import { CreateWarningDetailReq } from 'server-openapi';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { toasts } from '../../../../kit/Toasts/Toaster';
import { AxiosError } from 'axios';
import { RequirePermission } from '../../../../components/RequirePermission/RequirePermission';
import { RequireInternet } from '../../../../components/RequireInternet/RequireInternet';
import { WarningDetailTable } from './WarningDetailTable';
import { useAsync } from '../../../../kit/hooks/UseAsync';
import {useGlobalPermissions} from "../../../../core/authz/PermissionsProvider";
import {TextInput} from "../../../../kit/Forms/TextInput";

interface FormFields {
  startTime?: Date;
  endTime?: Date;
  message?: string;
}

export function WarningDetailsForm() {
    const globalPermission = useGlobalPermissions();
  return (
    <RequirePermission hasPermission={globalPermission.canManageMaintenanceWarning}>
      <RequireInternet>
        <WarningDetailsFormContent />
      </RequireInternet>
    </RequirePermission>
  );
}

function WarningDetailsFormContent() {
  const warningDetailsApi = new SyncStreamAPI().warningDetails;
  const warningDetailList = useAsync(async () => {
    return (await warningDetailsApi.warningDetailsListWarningDetails()).data;
  });
  const theme = useTheme();
  const form = useForm<FormFields>();
  async function onSubmit(data: FormFields) {
    const newWarningReq: CreateWarningDetailReq = {
      startTime: DateUtils.fromDate(data.startTime!),
      endTime: DateUtils.fromDate(data.endTime!),
      message: data.message!,
    };

    await warningDetailsApi.warningDetailsCreateNewWarningDetail(newWarningReq).catch((error: AxiosError) => {
      toasts.error(error.response?.data.detail);
      throw error;
    });

    toasts.success('Maintenance warning sent successfully');
    warningDetailList.refresh();
    form.reset();
  }
  return (
    <Layout gap={1}>
      <HeaderContainer>
        <HeaderText>Maintenance Warning</HeaderText>
      </HeaderContainer>
      <SubTitleContainer>
        <SubTitleText>Specify the period during which users need to be warned of a scheduled maintenance </SubTitleText>
      </SubTitleContainer>
      <Layout gap={1} padding={'1.25rem'} style={{ background: theme.backgrounds.lighter.bg, maxWidth: '50rem' }}>
        <Form form={form} onSubmit={onSubmit}>
          <Grid cols={2}>
            <FormGroup label=" Warning Start Time" fullWidth>
              <DateInput
                required
                dateTime
                fullWidth
                name={'startTime'}
                rules={{
                  validate: (startTime: Date) => {
                    if (isBefore(startTime, Date.now())) {
                      return 'Start time cannot be in the past';
                    }
                  },
                }}
              />
            </FormGroup>
            <FormGroup label="Warning End Time" fullWidth>
              <DateInput
                required
                dateTime
                fullWidth
                name={'endTime'}
                rules={{
                  validate: (endTime: Date) => {
                    if (isBefore(endTime, form.watch('startTime') ?? Date.now())) {
                      return 'End time cannot be earlier than start';
                    }
                  },
                }}
              />
            </FormGroup>
          </Grid>
          <FormGroup label="Warning Message" fullWidth>
            <TextArea required name={'message'} fullWidth />
          </FormGroup>

          <Button type={'submit'} style={{ justifySelf: 'flex-end', width: '250px' }}>
            Submit
          </Button>
        </Form>
      </Layout>
      <HeaderContainer>
        <HeaderText>Maintenance Warning History</HeaderText>
      </HeaderContainer>
      <SubTitleContainer>
        <SubTitleText>List of maintenance warnings </SubTitleText>
      </SubTitleContainer>
      <WarningDetailTable warningDetailList={warningDetailList} />
    </Layout>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderText = styled.h2`
  margin: auto 0;
`;

const SubTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const SubTitleText = styled.p`
  margin: auto 0;
`;
