import * as React from 'react';

export function IconNewDrug(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 5 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style, backgroundColor: 'white', borderRadius: '999999px', marginRight: '4px' }}
      {...props}
    >
      <rect x="0.692383" y="0.5" width="3.61538" height="11" rx="1.80769" fill="white" stroke="#EA2A2A" />
      <path
        d="M4.67512 0.538452H0.459135C0.283469 2.10489 -0.672004 6.99999 2.56713 6.99999C5.2666 6.99999 4.85079 2.10489 4.67512 0.538452Z"
        fill="#EA2A2A"
      />
    </svg>
  );
}
