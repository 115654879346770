import React from 'react';
import { CreateTestResultReq, HSPatient, HSTestResultEntry } from 'server-openapi';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { Layout } from '../../../../kit/Layout';
import { useSyncCenter } from '../../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../../core/storage/hooks/UseStore';
import Tab from '../../../../kit/Tabs/Tab';
import { TestSidebarForm } from '../testResults/TestsSidebarForm';
import itiriri from 'itiriri';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import { TestResultGraph } from '../testResults/TestResultGraph';
import { PatientCompactInfo } from '../PatientCompactInfo';
import Tabs from '../../../../kit/Tabs/Tabs';
import { toasts } from '../../../../kit/Toasts/Toaster';
import { MedisphereTestResult } from '../../../../syncstream/SyncTestResults';
import {useGroupPermissions} from "../../../../core/authz/PermissionsProvider";

interface IProps {
  patient: HSPatient;
}

export function PatientTests(props: IProps) {
  const services = useSyncCenter();
  const apiUtils = useApiUtils();
  const groupPermissions = useGroupPermissions();
  const canCreateTestResults = groupPermissions.canRecordVitals;
  const patientUtils = useApiUtils().patients;

  const facility = patientUtils.findPatientFacility(props.patient);
  const testResultTypeStore = useStore(services.testResultTypes.store).store;
  const testResultTypes = itiriri(testResultTypeStore.values())
    .sort((a, b) => (a.hsId ?? 0) - (b.hsId ?? 0))
    .toArray();
  const testResults = patientUtils.getTestResults(props.patient);

  async function onSubmit(hsResultType: number, testResultEntires: Array<HSTestResultEntry>) {
    if (!canCreateTestResults) {
      toasts.error('You do not have permission to record vitals');
      return;
    }
    const testResult: MedisphereTestResult = {
      hsTestResultTypeId: hsResultType,
      patientId: props.patient.hsId,
      facilityId: facility?.hsId,
      timeStamp: DateUtils.fromDate(new Date()),
      createdAt: DateUtils.fromDate(new Date()),
      testResultEntries: testResultEntires,
    };

    const testResultReq: CreateTestResultReq = {
      testResult: testResult,
      facilityGroupId: patientUtils.findFacilityGroup(props.patient),
    };

    await services.testResults.service.enqueue.createTestResult({
      type: 'test-result-create',
      medisphereTestResult: testResult,
      request: testResultReq,
    });
  }

  return (
    <Layout gap={1}>
      <PatientCompactInfo patient={props.patient} facility={facility!} />
      <Tabs>
        {testResultTypes.map((testResultType) => {
          return (
            <Tab key={testResultType.hsId} title={testResultType.name!}>
              <Layout gap={2}>
                <TestSidebarForm onSubmit={onSubmit} testResultType={testResultType} />
                <TestResultGraph
                  testResults={testResults.filter(
                    (testResult) => testResult.hsTestResultTypeId === testResultType.hsId,
                  )}
                  testResultType={testResultType.name!}
                  graphDataKey={testResultType.testResultFields?.map((field) => field.description ?? undefined) ?? []}
                />
              </Layout>
            </Tab>
          );
        })}
      </Tabs>
    </Layout>
  );
}
