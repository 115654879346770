import _ from 'lodash';
import React, { useState } from 'react';
import { PinOnlineService } from '../../core/mrs/Services';
import { Dialog } from '../../kit/Dialog';
import { useAsync } from '../../kit/hooks/UseAsync';
import { PinUpdateForm } from './PinUpdateForm';

export function ForcedPinDialog() {
  const [open, setOpen] = useState(true);

  const userHasPin = useAsync(async () => {
    return await PinOnlineService.checkIfPinExistsForCurrentUser();
  });

  if (userHasPin.value !== false) {
    return null;
  }

  return (
    <Dialog size="full" hideCloseButton lazy secondary open={open}>
      <PinUpdateForm onSubmit={() => setOpen(false)} />
    </Dialog>
  );
}
