/* eslint-disable max-lines */
import {
  CancelOrderCommentRequest,
  CancelOrderRequest,
  CreateOrderCommentRequest,
  HSFacility,
  HSOrder,
  HSOrderComment, HSPatient,
} from 'server-openapi';
import { PersistentQueue } from '../core/queue/PersistentQueue';
import {Entry, IStorage} from '../core/storage/Contract';
import {IFacilityGroupSyncService, ISyncService} from './SyncCenter';
import { IOrderService, Order, OrderComment, OrderUpdate } from '../common/Order';
import { CreateOrderRequest } from 'server-openapi/dist/generated-sdk/api';
import { v4 as uuidv4 } from 'uuid';
import { DateUtils } from '../core/utils/dateUtils';
import { SyncStreamAPI } from './api';
import { Logger } from '../core/logger/logger';
import { SyncUtils } from './utils/SyncUtils';
import { startOfDay, subDays } from 'date-fns';
import {MemoryCache} from "../core/storage/MemoryCache";

enum OperationType {
  Create,
  Cancel,
  Comment,
  CancelComment,
}

export type OrderOp = {
  type: OperationType;
  orderId: string;
};

const logger = new Logger('SyncOrders');

export class SyncOrders implements IFacilityGroupSyncService, IOrderService {
  get name(): string {
    return 'SyncOrders';
  }
  private async isStale(o: Order): Promise<boolean> {
    return (!!(o.orderedAt && DateUtils.ensureDate(o.orderedAt)! < new Date(DateUtils.fromDate(subDays(startOfDay(new Date()), 7)))));
  }

  private async isFacilityGroup(o: Order, facilityGroupId: string): Promise<boolean> {
    const facilityGroup = await this.facilitiesStore.get(o.hsFacilityId!.toString());
    return facilityGroup?.facilityGroupId?.toString() == facilityGroupId;
  }

  hsToLocalOrder: Map<number, string> = new Map();
  hsToLocalOrderComment: Map<number, string> = new Map();
  constructor(
    private api: SyncStreamAPI,
    private storage: MemoryCache<Order>,
    private queue: PersistentQueue<OrderOp>,
    private facilitiesStore: IStorage<HSFacility>,
    private latestChangeNumbers: IStorage<number | undefined>,
  ) {}
  async load(facilityGroupId: string): Promise<void> {
    await this.storage.reset(async (p) => {
          return await this.isFacilityGroup(p, facilityGroupId);
    });
    await this.init();
  }

  async init() {
    this.hsToLocalOrder.clear();
    for (const [_, order] of await this.storage.all()) {
      if (order.hsId > 0) {
        this.hsToLocalOrder.set(order.hsId, order.id);
      }
    }
  }

  async syncDown(facilityGroupId?: string) {
    const facilitiesToSync = await SyncUtils.getFacilitiesForGroup(facilityGroupId, this.facilitiesStore);
    const changeNumber = await SyncUtils.getChangeNumberForFacilities(
      facilitiesToSync.map((x) => x.hsId!),
      this.latestChangeNumbers,
    );

    const ordersData = await this.syncFacilityDown(
      facilityGroupId!,
      facilitiesToSync.map((facility) => facility.hsId!),
      changeNumber,
    );

    const orders: { key: string; value: Order }[] = [];
    for (const hsOrder of ordersData?.filter((hsOrder: HSOrder) => hsOrder.medicationId) ?? []) {
      const orderId = this.hsToLocalOrder.get(hsOrder.hsId!) ?? uuidv4();
      // Find pending update?
      const update = (await this.storage.get(orderId))?.update ?? {};
      const order = this.mapFromHsOrderWithId(orderId, hsOrder, update);

      orders.push(order);
    }

    await this.storage.setMany(orders);
    await SyncUtils.setChangeNumberForFacilities(
        facilitiesToSync.map((x) => x.hsId!),
        this.latestChangeNumbers,
        ordersData
    );
  }

  async syncFacilityDown(facilityGroupId: string, facilityIds: number[], changeNumber: number): Promise<HSOrder[]> {
    const pageSize = 200; //same as API
    //changeNumber = isFinite(changeNumber) ? changeNumber : 0;
    const orders = await this.api.orders.ordersListOrders(
      parseInt(facilityGroupId),
      changeNumber,
      pageSize
    );
    if (orders.data.length === pageSize) {
      return [
        ...orders.data,
        ...(await this.syncFacilityDown(facilityGroupId, facilityIds, SyncUtils.getLatestChangeNumber(orders.data)!)),
      ];
    }
    return orders.data;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  private mapFromHsOrderWithId(orderId: string, hsOrder: HSOrder, orderUpdate: OrderUpdate) {
    this.hsToLocalOrder.set(hsOrder.hsId!, orderId);

    const orderComments: OrderComment[] = [];

    for (const hsOrderComment of hsOrder.orderComments ?? []) {
      const orderComment = this.mapFromHsOrderComment(hsOrderComment);
      orderComments.push(orderComment);
    }

    const order: Order = {
      id: orderId,
      changeNumber: hsOrder.changeNumber!,
      hsId: hsOrder.hsId!,
      isActive: hsOrder.active ?? false,
      hsFacilityId: hsOrder.facilityId ?? 0,
      hsPatientId: hsOrder.patientId ?? 0,
      hsMedicationId: hsOrder.medicationId ?? 0,
      hsDrugCode: hsOrder.drugCode ?? undefined,
      nextDailyDelivery: hsOrder.urgent ?? false,
      orderedAt: hsOrder.requestedAt!,
      dispatchedAt: hsOrder.dispatchedDate ?? undefined,
      orderedByName: hsOrder?.requestedByName ?? hsOrder?.requestedByLogin ?? '',
      orderedBySubjectId: hsOrder?.requestedBySubjectId ?? undefined,
      comments: orderComments,
      update: orderUpdate,
    };

    return {
      key: orderId,
      value: order,
    };
  }

  private mapFromHsOrderComment(hsOrderComment: HSOrderComment): OrderComment {
    const orderCommentId = this.hsToLocalOrderComment.get(hsOrderComment.hsId!) ?? uuidv4();
    return this.mapFromHsOrderCommentWithId(orderCommentId, hsOrderComment);
  }

  private mapFromHsOrderCommentWithId(orderCommentId: string, hsOrderComment: HSOrderComment): OrderComment {
    this.hsToLocalOrderComment.set(hsOrderComment.hsId!, orderCommentId);
    return {
      id: orderCommentId,
      hsId: hsOrderComment.hsId!,
      isActive: hsOrderComment.active ?? false,
      comment: hsOrderComment.commentText ?? '',
      commentType: hsOrderComment.commentType ?? 'Other',
      commentByName: hsOrderComment.lastUpdatedByLogin ?? '',
      commentBySubjectId: hsOrderComment.lastUpdatedBySubjectId ?? '',
      commentDate: hsOrderComment.createdAt!,
    };
  }

  async syncUp() {
    for await (const delivery of this.queue.iterate()) {
      try {
        switch (delivery.value.type) {
          case OperationType.Create:
            await this.syncUpCreateOrder(delivery.value.orderId);

            break;
          case OperationType.Cancel:
            await this.syncUpCancel(delivery.value.orderId);

            break;
          case OperationType.Comment:
            await this.syncUpComment(delivery.value.orderId);
            break;

          case OperationType.CancelComment:
            await this.syncUpCommentCancel(delivery.value.orderId);
            break;
        }

        await delivery.complete();
      } catch (error) {
        logger.error('syncUp Failed', error);
        //TODO: This is great and all,
        // but if an update is failing repeatedly, then it will lock the rest of the queue
        // Should there be a limit to retry? Should there be a DLQ? Should it be based on the type of failure?
        await delivery.failed();
        return;
      }
    }
  }

  private async syncUpCreateOrder(orderId: string): Promise<void> {
    logger.info(`Sync Up Order ${orderId}`);
    const order = await this.storage.get(orderId);
    if (!order) {
      logger.info(`Sync Up Order ${orderId} error - invalid Id`);
      return;
    }

    if (order.isActive) {
      const createOrderRequest: CreateOrderRequest = {
        medicationId: order.hsMedicationId,
        urgent: order.nextDailyDelivery,
        orderedBySubjectId: order.update.updatedBySubjectId,
      };
      const hsOrder = await this.api.orders.ordersCreateOrder(createOrderRequest);
      const kv = this.mapFromHsOrderWithId(order.id, hsOrder.data, order.update);
      await this.storage.set(kv.key, kv.value);
      logger.info(`Sync Up Order ${orderId} - HS ID ${kv.value.hsId}`);
    } else {
      logger.info(`Sync Up Order ${orderId} error - inactive`);
    }
  }

  private async syncUpComment(orderId: string): Promise<void> {
    const order = await this.storage.get(orderId);
    if (!order) {
      return;
    }

    const pendingCommentArray = order?.update.pendingComments ?? [];

    //repeat the api call for every comment in the pending comment array
    for (const newComment of pendingCommentArray) {
      const createOrderCommentRequest: CreateOrderCommentRequest = {
        hsOrderId: order.hsId,
        comment: newComment.comment,
        createdAt: DateUtils.ensureString(newComment.commentDate),
        createdBySubjectId: newComment.commentBySubjectId,
      };
      const hsOrderComment = await this.api.orderComments.ordersCommentCreateOrdersComment(createOrderCommentRequest);
      const orderComment = this.mapFromHsOrderComment(hsOrderComment.data);

      order.comments.push(orderComment);
    }
    order.update.pendingComments = undefined;
    logger.info(`Order Comment uploaded: ${orderId}`);
    await this.storage.set(orderId, order);
  }

  private async syncUpCancel(orderId: string): Promise<void> {
    const order = await this.storage.get(orderId);
    if (!order) {
      logger.info(`Sync Up Order ${orderId} error - invalid Id`);
      return;
    }
    const cancelOrderRequest: CancelOrderRequest = {
      hsOrderId: order.hsId,
      cancelledBySubjectId: order.update.updatedBySubjectId,
    };
    await this.api.orders.ordersCancelOrder(cancelOrderRequest);
    logger.info(`Sync Up Order ${orderId} canceled - ${order.hsId}`);
  }

  private async syncUpCommentCancel(orderId: string): Promise<void> {
    const order = await this.storage.get(orderId);
    if (!order || !order.update.commentsCancelled) {
      return;
    }

    const orderCommentCancel = order.update.commentsCancelled[0];

    const orderComment = order.comments.find((o) => o.id === orderCommentCancel.id);
    if (orderComment) {
      const cancelOrderRequest: CancelOrderCommentRequest = {
        hsOrderCommentId: orderComment.hsId,
        cancelledBySubjectId: orderCommentCancel.cancelBySubjectId,
      };
      await this.api.orderComments.ordersCommentCancelOrdersComment(cancelOrderRequest);
    }
  }

  async createMedicationOrder(
    nextDailyDelivery: boolean,
    hsOrderedBySubjectId: string,
    hsPatientId: number,
    hsMedicationId: number,
    hsDrugCode?: string,
    notes?: string,
    userName?: string,
  ): Promise<string> {
    const orderId = uuidv4();
    const pendingComment: OrderComment[] | undefined = notes
      ? [
          {
            id: uuidv4(),
            hsId: 0,
            comment: notes,
            commentByName: userName ?? '',
            commentBySubjectId: hsOrderedBySubjectId,
            commentDate: new Date(),
            commentType: 'NurseComment',
            isActive: true,
          },
        ]
      : undefined;

    const order: Order = {
      id: orderId,
      hsId: 0,
      changeNumber: 0,
      isActive: true,
      hsFacilityId: 0, // TODO?
      hsPatientId: hsPatientId,
      hsMedicationId: hsMedicationId,
      hsDrugCode: hsDrugCode,
      nextDailyDelivery: nextDailyDelivery,
      orderedAt: new Date(),
      orderedByName: userName ?? '',
      orderedBySubjectId: hsOrderedBySubjectId,
      comments: [],
      update: {
        updatedBySubjectId: hsOrderedBySubjectId,
        pendingComments: pendingComment,
      },
    };

    logger.info(`Queue Order Create: ${orderId}`);
    await this.storage.set(orderId, order);
    await this.queue.unshift({ type: OperationType.Create, orderId: order.id });
    if (notes) {
      await this.queue.unshift({ type: OperationType.Comment, orderId: orderId });
    }
    return orderId;
  }

  async cancelOrder(orderId: string, hsCancelBySubjectId: string, notes?: string): Promise<void> {
    // putting this at the top or else two different objects were being used to update the same data
    await this.createOrderComment(orderId, hsCancelBySubjectId, `Order cancelled (Note: ${notes!})`);
    //now this order contains the updated object with the cancellation comment
    const order = await this.storage.get(orderId);
    if (order) {
      order.update.isActive = false;
      order.update.updatedBySubjectId = hsCancelBySubjectId;

      logger.info(`Queue Order Cancel: ${orderId} ${order.update?.isActive}`);
      await this.storage.set(orderId, order);
      await this.queue.unshift({ type: OperationType.Cancel, orderId: orderId });
    } else {
      logger.info(`Queue Order Cancel: ${orderId} error - invalid id`);
    }
  }

  async createOrderComment(
    orderId: string,
    hsCommentBySubjectId: string,
    comment: string,
    userName?: string,
  ): Promise<void> {
    const order = await this.storage.get(orderId);
    if (order) {
      const newComment: OrderComment = {
        id: uuidv4(),
        hsId: 0,
        isActive: true,
        comment: comment,
        commentByName: userName ?? '',
        commentBySubjectId: hsCommentBySubjectId,
        commentDate: new Date(Date.now()),
        commentType: 'NurseComment',
      };
      if (order.update.pendingComments) {
        order.update.pendingComments = [...order.update.pendingComments, newComment];
      } else {
        order.update.pendingComments = [newComment];
      }

      // Potential overwrite of sync from HS, but risk is: unlikely/negligible impact
      await this.storage.set(orderId, order);
      await this.queue.unshift({ type: OperationType.Comment, orderId: orderId });
      logger.info(`Queue Order Comment: ${orderId}`);
    }
  }

  async cancelOrderComment(orderId: string, hsCancelBySubjectId: string): Promise<void> {
    const order = await this.storage.get(orderId);
    if (order) {
      order.update.commentsCancelled ??= [];
      order.update.commentsCancelled.push({
        id: uuidv4(),
        cancelBySubjectId: hsCancelBySubjectId,
        cancelledAt: new Date(),
      });

      await this.storage.set(orderId, order);
      await this.queue.unshift({ type: OperationType.CancelComment, orderId: orderId });
    }
  }

  async clear() {
    await this.storage.clear();
    await this.latestChangeNumbers.clear();
    await this.queue.clear();
  }

  async hasQueuedData() {
    return (await this.queue.length()) > 0;
  }
  isAllowed(canUserAccessMedication: boolean): boolean {
    // Only if you can view a round.
    return canUserAccessMedication;
  }
  async archive(deletedPatientIds: string[]): Promise<string[]> {
    const keysToDelete: string[] = [];
    for (let [k, v] of (await this.storage.all()).entries()) {
      if (await this.isStale(v) || deletedPatientIds.some(x => x === v.hsPatientId.toString())) {
        keysToDelete.push(k)
      }
    }
    await this.storage.deleteMany(keysToDelete);
    return keysToDelete;
  }
  setEncryptionVersion(version: number): void {
    this.storage.compressOnSave = (version > 1);
  }
  async rewrite(): Promise<void> {
    const entries: Entry<Order>[] = [...(await this.storage.all())].map((keyValueArray) => {
      return {
        key: keyValueArray[0],
        value: keyValueArray[1]
      };
    });

    return await this.storage.setMany(entries);
  }}
