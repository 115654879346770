import {HSDrug, HSDrugForm} from 'server-openapi';
import { PersistentQueue } from '../core/queue/PersistentQueue';
import { IStorage } from '../core/storage/Contract';
import { SyncStreamAPI } from './api';
import { ISyncService } from './SyncCenter';
import { SyncUtils } from './utils/SyncUtils';
import {MemoryCache} from "../core/storage/MemoryCache";

interface Operation {
  type: void;
  payload: HSDrugForm;
}

export class SyncDrugForms implements ISyncService {
  get name(): string {
    return 'SyncDrugForms';
  }

  constructor(
    private api: SyncStreamAPI,
    private storage: IStorage<HSDrugForm>,
    private latestChangeNumbers: IStorage<number | undefined>,
    private queue: PersistentQueue<Operation>,
    private epochStore: MemoryCache<string>
  ) {}

  async syncDown() {
    // TODO: storage locking so that we can be sure the UI
    // didn't accidently change a resource in between the API
    // giving us fresh data and updating the storage backend.

    const drugForms = await this.syncDownWithChangeNumber((await this.latestChangeNumbers.get('')) ?? 0);
    await this.storage.setMany(
      drugForms
        .map((drugForm) => ({
          key: this.storage.get_key!(drugForm),
          value: drugForm,
        })),
    );
    await SyncUtils.setChangeNumberForFacilities(
        [''],
        this.latestChangeNumbers,
        drugForms
    );
  }

  async syncDownWithChangeNumber(changeNumber: number): Promise<HSDrugForm[]> {
    const pageSize = 1000; //same as in API
    const drugForms = await this.api.drugForms.drugFormsListDrugForms(
      changeNumber,
      pageSize);

    if (drugForms.data.length === pageSize) {
      return [
        ...drugForms.data,
        ...(await this.syncDownWithChangeNumber(SyncUtils.getLatestChangeNumber(drugForms.data)!)),
      ];
    }
    return drugForms.data;
  }

  async syncUp() {
    // Do nothing
  }

  async clear() {
    await this.storage.clear();
    await this.latestChangeNumbers.clear();
    await this.queue.clear();
  }

  async hasQueuedData() {
    return (await this.queue.length()) > 0;
  }
  isAllowed(canUserAccessMedication: boolean): boolean {
    // Only if you can view a round.
    return canUserAccessMedication;
  }
  private async isStale(d: HSDrugForm): Promise<boolean> {
    return !d.active;
  }
  async archive(): Promise<void> {
    const keysToDelete: string[] = [];
    for (let [k, v] of (await this.storage.all()).entries()) {
      if (await this.isStale(v)) {
        keysToDelete.push(k)
      }
    }
    await this.storage.deleteMany(keysToDelete);
  }
}
