import { FacilityGroupConfigurationDto, HSFacilityGroup, PinUserDto } from 'server-openapi';
import { Entry, IStorage } from '../core/storage/Contract';
import { SyncStreamAPI } from './api';
import { IFacilityGroupSyncService, ISyncService } from './SyncCenter';
import { setEncryptionVersion } from '../context/AppStateProvider';
import { MemoryCache } from '../core/storage/MemoryCache';

export class SyncFacilityGroupConfigurations implements IFacilityGroupSyncService {
  get name(): string {
    return 'SyncFacilityGroupConfigurations';
  }

  constructor(
    private api: SyncStreamAPI,
    private storage: MemoryCache<FacilityGroupConfigurationDto>,
    private facilityGroupsStore: IStorage<HSFacilityGroup>,
  ) {}

  async load(facilityGroupId: string): Promise<void> {
    await this.storage.reset(async (p) => {
      return true;
    });
  }

  async syncDown(facilityGroupId?: string) {
    if (!facilityGroupId) {
      return;
    }
    const facilityGroup = await this.facilityGroupsStore.get(facilityGroupId);
    if (!facilityGroup) {
      return;
    }
    await this.syncConfigurationByFacilityGroup(facilityGroup);
  }

  private async syncConfigurationByFacilityGroup(facilityGroup: HSFacilityGroup) {
    const configuration = (
      await this.api.facilityGroups.facilityGroupsGetFacilityGroupConfiguration(facilityGroup.hsId!)
    ).data;

    if (configuration) {
      setEncryptionVersion(configuration.encryptionVersion ?? 1);
      await this.storage.set(this.storage.get_key!(configuration), configuration);
    }
  }
  async clear() {
    await this.storage.clear();
  }
  async hasQueuedData() {
    // sync up is not available in this service so no queue data
    return false;
  }
  async syncUp() {
    // sync up is not available in this service
  }
  isAllowed(canUserAccessMedication: boolean): boolean {
    // Only if you can view a round.
    return true;
  }
  async getEncryptionVersion(facilityGroupId?: string): Promise<number | undefined> {
    if (!facilityGroupId) {
      return;
    }
    const cfg = await this.storage.get(facilityGroupId);
    return cfg?.encryptionVersion;
  }

  setEncryptionVersion(version: number): void {
    this.storage.compressOnSave = (version > 1);
  }
  async rewrite(): Promise<void> {
    const entries: Entry<FacilityGroupConfigurationDto>[] = [...(await this.storage.all())].map((keyValueArray) => {
      return {
        key: keyValueArray[0],
        value: keyValueArray[1]
      };
    });
    return await this.storage.setMany(entries);
  }

}
