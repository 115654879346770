import itiriri from 'itiriri';
import { factories, IEmbedConfiguration, models, service } from 'powerbi-client';
import React, { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {EmbedParams, GroupPermissionsRes} from 'server-openapi';
import styled from 'styled-components';
import { NavigationBar } from '../../components/NavigationBar/NavigationBar';
import { Logger } from '../../core/logger/logger';
import { ReportingOnlineService } from '../../core/mrs/Services';
import { Button, Variation } from '../../kit/Button';
import { useAsync } from '../../kit/hooks/UseAsync';
import { IconBack } from '../../kit/Icons/Back';
import { Layout } from '../../kit/Layout';
import { Text } from '../../kit/Text';
import { toasts } from '../../kit/Toasts/Toaster';
import { useSyncCenter } from '../../syncstream/SyncCenterProvider';
import { useStore } from '../../core/storage/hooks/UseStore';
import { RequirePermission } from '../../components/RequirePermission/RequirePermission';
import { RequireInternet } from '../../components/RequireInternet/RequireInternet';
import {useGroupPermissions} from "../../core/authz/PermissionsProvider";
import {NavigationBarMenu} from "../../components/NavigationBar/NavigationBarMenu";

const logger = new Logger('ReportingPage');

const powerbi = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);

interface IParams {
  facilityGroupId: string;
}

interface ReportItem {
  label: string;
  reportName: string;
  hasPermission: boolean;
}

export function ReportingPage() {
  const groupPermissions = useGroupPermissions();

  return (
    <RequirePermission hasPermission={groupPermissions.canViewReport}>
      <ReportingPageContent groupPermissions={groupPermissions} />
    </RequirePermission>
  );
}

function ReportingPageContent(props: { groupPermissions: GroupPermissionsRes}) {
  const facilityGroupId = parseInt(useParams<IParams>().facilityGroupId);
  const history = useHistory();
  const reportRef = useRef(null);

  const services = useSyncCenter();
  const facilityGroupsStore = useStore(services.facilityGroups.store).store;
  const facilityGroup = itiriri(facilityGroupsStore.values()).find((f) => f.hsId === facilityGroupId);

  return (
    <>
      <NavigationBar
        inverted={true}
        facilityGroup={facilityGroup}
        nodes={() => ([
          <Layout horizontal gap={1.5}>
            <IconBack onClick={() => history.push(`/facility-group/${facilityGroupId}`)} />
            <Text weight={'bold'} size="large" style={{ color: 'white' }}>
              Reporting
            </Text>
          </Layout>,
            <>
              <ReportItemsSection reportRef={reportRef} facilityGroupId={facilityGroupId} groupPermissions={props.groupPermissions}/>,
              { facilityGroup ? <NavigationBarMenu facilityGroup={facilityGroup} /> : null },
            </>
        ])}
      />
      <RequireInternet>
        <ReportingContainer id="reportContainer" ref={reportRef} />
      </RequireInternet>
    </>
  );
}

function ReportItemsSection(props: { reportRef: React.MutableRefObject<null>; facilityGroupId: number; groupPermissions: GroupPermissionsRes }) {
  const [currentReportIndex, setCurrentReportIndex] = useState<number>();

  const embedParams = useAsync<EmbedParams>(async () => {
    return await ReportingOnlineService.getReportingEmbedInfo(props.facilityGroupId);
  }).value;

  const reportItems: ReportItem[] = getReports(props.facilityGroupId, props.groupPermissions);

  return (
    <Layout horizontal gap={1.5} padding="0 20px">
      {reportItems
        .filter((ri) => ri.hasPermission)
        .map((ri, index) => (
          <SelectReportButton
            key={index}
            variation={Variation.text}
            onClick={() => {
              if (showReport(ri, props.reportRef, embedParams)) {
                setCurrentReportIndex(index);
              }
            }}
            selected={index === currentReportIndex}
          >
            {ri.label}
          </SelectReportButton>
        ))}
    </Layout>
  );
}

function getReports(facilityGroupId: number, groupPermissions: GroupPermissionsRes): ReportItem[] {
  return [
    {
      label: 'Registered Nurse',
      reportName: 'Healthstream - RN Report',
      hasPermission: groupPermissions.canViewRegisteredNurseReport ?? false,
    },
    {
      label: 'Facility Manager',
      reportName: 'Healthstream - FM Report',
      hasPermission: groupPermissions.canViewFacilityManagerReport ?? false,
    },
    {
      label: 'Corporate',
      reportName: 'Healthstream - CORP Report',
      hasPermission: groupPermissions.canViewCorporateReport ?? false,
    },
    {
      label: 'MPS',
      reportName: 'Healthstream - Sigma Report',
      hasPermission: groupPermissions.canViewMpsReport ?? false,
    },
  ];
}

function showReport(reportItem: ReportItem, reportRef: React.MutableRefObject<null>, embedParams?: EmbedParams) {
  const reportContainer = reportRef.current!;

  const reportUrl = embedParams!.embedReport!?.find((r) => r.reportName === reportItem.reportName)?.embedUrl;

  if (!reportUrl) {
    toasts.error('Unable to find the given report');
    return false;
  }

  const embedConfiguration: IEmbedConfiguration = {
    type: 'report',
    tokenType: models.TokenType.Embed,
    accessToken: embedParams!.embedToken!.token!,
    embedUrl: reportUrl!,
  };

  const report = powerbi.embed(reportContainer, embedConfiguration);
  report.iframe.id = 'powerbiembedded';

  report.off('loaded'); // Clear any other loaded handler events

  report.on('loaded', function () {
    logger.info('Report load successful'); // Triggers when a content schema is successfully loaded
  });

  report.off('rendered'); // Clear any other rendered handler events

  report.on('rendered', function () {
    logger.info('Report render successful'); // Triggers when a content is successfully embedded in UI
  });

  report.off('error'); // Clear any other error handler event

  report.on('error', function (event) {
    const errorMsg = event.detail; // handling errors that occur during embedding

    logger.error('Error receiving reports', errorMsg); // Use errorMsg variable to log error in any destination of choice
  });

  return true;
}

const ReportingContainer = styled.div`
  height: 100%; // todo: fix so this will only take up rest of browser height
  background-color: ${(p) => p.theme.appbar.bg};
`;

const SelectReportButton = styled(Button)<{ selected?: boolean }>`
  color: ${(p) => p.theme.appbar.bg};
  font-size: 1.4rem;
  font-weight: ${(p) => (p.selected ? 'bold' : 'inherit')};
`;
