import itiriri from 'itiriri';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../../../../kit/Button';
import { SearchInput } from '../../../../../kit/Forms/SearchInput';
import { Grid } from '../../../../../kit/Grid';
import { Layout } from '../../../../../kit/Layout';
import { Text } from '../../../../../kit/Text';
import { Image } from '../../../../../kit/Image';
import { useSyncCenter } from '../../../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../../../core/storage/hooks/UseStore';
import { HSPatient, NimConsumerDto } from 'server-openapi';
import { toasts } from '../../../../../kit/Toasts/Toaster';
import { Checkbox } from '../../../../../kit/Checkbox';
import { NimAvailableDrug } from './NimsSettings';
import { apis } from '../../../../../core/mrs/apis';
import { useAsync } from '../../../../../kit/hooks/UseAsync';
import { useApiUtils } from '../../../../../syncstream/utils/hooks/useApiUtils';
import { NimsResidentCommentDialog } from './NimsResidentCommentDialog';

interface IProps {
  existingDrugEntry?: NimAvailableDrug;
  isDialogOpen: boolean;
}

interface IParams {
  facilityGroupId: string;
}

interface IPatientSelection extends HSPatient {
  isPrescribed: boolean;
  isHidden: boolean;
  comment?: string;
}

// eslint-disable-next-line max-lines-per-function
export function ResidentsDialogContent(props: IProps) {
  const patientUtils = useApiUtils().patients;
  const facilityGroupUtils = useApiUtils().facilityGroups;
  const { facilityGroupId } = useParams<IParams>();

  const existingDrugEntry = props.existingDrugEntry;

  const [query, setQuery] = useState<string>('');

  const [patientSelections, setPatientSelections] = useState<IPatientSelection[]>();

  const [isSelectAll, setIsSelectAll] = useState<boolean>();

  const services = useSyncCenter();

  const facilityStore = useStore(services.facilities.store).store;
  const facilities = itiriri(facilityStore.values())
    .filter((facility) => facility.facilityGroupId?.toString() === facilityGroupId)
    .toArray();

  const residentLabel = facilityGroupUtils.getResidentUILabel(parseInt(facilityGroupId));

  const patients = patientUtils
    .getActivePatients()
    .toArray()
    .filter((patient) => facilities.some((facility) => facility.hsId === patient.facility));

  const { refresh } = useAsync(async () => {
    const nimConsumers: NimConsumerDto[] = existingDrugEntry ? (await apis.nimConsumers.nimConsumerList(existingDrugEntry.id)).data : [];

    const items = patients.map((p) => ({
      isPrescribed: nimConsumers.some((c) => c.hsPatientId === p.hsId && c.isActive),
      isHidden: false,
      comment: nimConsumers.find((c) => c.hsPatientId === p.hsId)?.comment ?? undefined,
      ...p,
    }));

    setPatientSelections(items);
  }, [props.isDialogOpen]);

  useEffect(() => {
    if (patientSelections) {
      const items = [...patientSelections];

      items.forEach((i) => {
        const residentName = patientUtils.getQueryPatientString(i);

        if (residentName.includes(query.toUpperCase())) {
          i.isHidden = false;
        } else {
          i.isHidden = true;
        }
      });

      setPatientSelections(items);
    }
  }, [query]);

  useEffect(() => {
    const isSelectAll = !patientSelections?.every((p) => p.isPrescribed);

    setIsSelectAll(isSelectAll);
  }, [patientSelections]);

  async function handleSelectionChange(isPrescribed: boolean, patient: HSPatient) {
    const items = [...patientSelections!];

    items.find((i) => i.hsId === patient.hsId)!.isPrescribed = isPrescribed;

    setPatientSelections(items);

    const response = (
      await apis.nimConsumers.nimConsumerCreate({
        nimAvailableDrugId: existingDrugEntry?.id,
        hsPatientIds: items.filter((i) => i.isPrescribed).map((i) => i.hsId!),
      })
    ).status;

    if (response === 200) {
      toasts.success(`${patientUtils.getDisplayPatientName(patient)} has been ${isPrescribed ? 'added' : 'removed'}`);
    }
  }

  async function handleSelectAll() {
    const items = [...patientSelections!];

    items.forEach((i) => (i.isPrescribed = isSelectAll!));

    setPatientSelections(items);

    const response = (
      await apis.nimConsumers.nimConsumerCreate({
        nimAvailableDrugId: existingDrugEntry?.id,
        hsPatientIds: items.filter((i) => i.isPrescribed).map((i) => i.hsId!),
      })
    ).status;

    if (response === 200) {
      toasts.success(`All ${residentLabel.toLowerCase()}(s) have been ${isSelectAll ? 'added' : 'removed'}`);
    }
  }

  const [openResidentsDialog, setOpenResidentsDialog] = useState(false);
  const [residentComment, setResidentComment] = useState<NimsResidentComment>();

  return (
    <DialogContainer gap={1.5}>
      <Header>
        {existingDrugEntry?.drug.name} {existingDrugEntry?.drug.formAbbreviation} {existingDrugEntry?.drug.strength}
      </Header>
      <ActionBarContainer>
        <SearchResidentContainer>
          <SearchInput
            onChange={(_, v) => setQuery(v)}
            value={query}
            name="search"
            placeholder={`Search ${residentLabel}`}
            fullWidth
          />
        </SearchResidentContainer>
        <SelectAllButton
          onClick={handleSelectAll}
          disabled={patientSelections?.filter((p) => !p.isHidden).length === 0}
        >
          {isSelectAll ? 'SELECT ALL' : 'UNSELECT ALL'}
        </SelectAllButton>
      </ActionBarContainer>
      <div>
        {patientSelections
          ?.filter((p) => !p.isHidden)
          .map((patient, index) => {
            const facility = facilities.find((facility) => facility.hsId === patient.facility);

            return (
              <ResidentEntry key={index}>
                <Grid colsTemplate={'1fr 2fr 2fr 4fr auto'}>
                  <PatientImageContainer>
                    {patient.imageUrl && (
                      <PatientImage src={patient.imageUrl} facilityGroupId={parseInt(facilityGroupId)} />
                    )}
                  </PatientImageContainer>
                  <Text weight={'bold'}>{patientUtils.getDisplayPatientName(patient)}</Text>
                  <Text>
                    {facility?.name} Rm {patient.roomNumber}
                  </Text>
                  <Layout horizontal gap={1}>
                    {patient.comment && patient.comment.length > 30
                      ? patient.comment?.substring(0, 30) + ' ...'
                      : patient.comment}
                    <ActionLink
                      onClick={() => {
                        setResidentComment({ patientId: patient.hsId!, comment: patient.comment });
                        setOpenResidentsDialog(true);
                      }}
                    >
                      {patient.comment ? 'Edit' : 'Add comment'}
                    </ActionLink>
                  </Layout>
                  <Checkbox
                    roundInput
                    checked={patient.isPrescribed}
                    onChange={(event) => handleSelectionChange(event.target.checked, patient)}
                  />
                </Grid>
              </ResidentEntry>
            );
          })}
      </div>
      <NimsResidentCommentDialog
        open={openResidentsDialog}
        setOpen={setOpenResidentsDialog}
        nimAvailableDrugId={existingDrugEntry?.id}
        hsPatientId={residentComment?.patientId}
        comment={residentComment?.comment}
        refreshResidents={refresh}
      />
    </DialogContainer>
  );
}

interface NimsResidentComment {
  patientId: number;
  comment?: string;
}

const DialogContainer = styled(Layout)`
  padding: 1em;
`;

const Header = styled.div`
  color: white;
  font-size: 32px;
  font-weight: bold;
`;

const ActionBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SelectAllButton = styled(Button)`
  width: 147px;
`;

const SearchResidentContainer = styled.div`
  width: 600px;
`;

const ResidentEntry = styled.div`
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #a032e1;
  overflow: hidden;

  & div {
    max-height: 60px;
  }
`;

const PatientImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PatientImage = styled(Image)`
  height: 39px;
  width: 39px;
  object-fit: cover;
  border-radius: 999999999px;
`;

const ActionLink = styled.div`
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 2px;
  font-weight: bold;
`;
