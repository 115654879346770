import * as React from 'react';
import { SVGProps } from 'react';

const IconDotMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 5 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 4.667A1.922 1.922 0 0 0 4.417 2.75 1.922 1.922 0 0 0 2.5.833 1.922 1.922 0 0 0 .583 2.75c0 1.054.863 1.917 1.917 1.917Zm0 1.916A1.922 1.922 0 0 0 .583 8.5c0 1.054.863 1.917 1.917 1.917A1.922 1.922 0 0 0 4.417 8.5 1.922 1.922 0 0 0 2.5 6.583ZM.583 14.25c0-1.054.863-1.917 1.917-1.917s1.917.863 1.917 1.917A1.922 1.922 0 0 1 2.5 16.167 1.922 1.922 0 0 1 .583 14.25Z"
      fill={props.color ?? 'black'}
      fillOpacity={props.opacity ?? '0.54'}
    />
  </svg>
);

export default IconDotMenu;
