import React, { useState } from 'react';
import { Button } from './Button';
import { Dialog } from './Dialog';
import { Intent } from './Theme/Theme';

interface RenderButtonProps {
  onClick: () => void;
  className?: string;
  children: React.ReactNode;
}

interface Props {
  label: string;
  // TODO: why have intent and secondary prop? Remove 1
  secondary?: boolean;
  intent?: Intent;
  children: React.ReactNode | ((props: DialogContentProps) => React.ReactNode);
  size?: 'sm' | 'md' | 'lg' | 'full';
  lazy?: boolean;
  className?: string;
  removeScrolling?: boolean;
  dialogSecondary?: boolean;
  renderButton?: (props: RenderButtonProps) => JSX.Element;
}

interface DialogContentProps {
  close: () => void;
}

export function DialogButton(props: Props) {
  const [open, setOpen] = useState(false);

  return (
    <>
      {props.renderButton ? (
        props.renderButton({ onClick: () => setOpen(true), className: props.className, children: props.label })
      ) : (
        <Button
          type="button"
          intent={props.secondary ? Intent.Secondary : Intent.Primary}
          onClick={() => setOpen(true)}
          className={props.className}
        >
          {props.label}
        </Button>
      )}
      <Dialog
        label={props.label}
        size={props.size}
        removeScrolling={props.removeScrolling}
        secondary={props.dialogSecondary}
        open={open}
        onRequestClose={() => setOpen(false)}
        lazy={props.lazy}
      >
        {typeof props.children === 'function'
          ? props.children({
              // if children is a callback then we'll pass in some
              // props that can be used to control the dialog state
              close: () => setOpen(false),
            })
          : props.children}
      </Dialog>
    </>
  );
}
