import { format } from 'date-fns';
import React from 'react';
import { HSPatient } from 'server-openapi';
import styled from 'styled-components';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { Button } from '../../../../kit/Button';
import { Layout } from '../../../../kit/Layout';
import { AllergyUtils } from '../../../../syncstream/utils/AllergyUtils';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import { PatientCompactInfo } from '../PatientCompactInfo';
import { Text } from '../../../../kit/Text';
import { SyncStreamAPI } from '../../../../syncstream/api';
import Loader from 'react-loader-spinner';
import { Grid } from '../../../../kit/Grid';
import { useAsync } from '../../../../kit/hooks/UseAsync';
import { PDFViewer } from '../../../../kit/PDFViewer/PDFViewer';
import { RequireInternet } from '../../../../components/RequireInternet/RequireInternet';

interface IPatientChartsProps {
  patient: HSPatient;
}

export function PatientCharts(props: IPatientChartsProps) {
  return (
    <RequireInternet>
      <PatientChartsContent {...props} />
    </RequireInternet>
  );
}

function PatientChartsContent(props: IPatientChartsProps) {
  const api = new SyncStreamAPI();
  const { patient } = props;
  const patientUtils = useApiUtils().patients;
  const facility = patientUtils.findPatientFacility(patient);
  const pdfUrl = useAsync(async () => {
    //fetch the pdf data in base64
    const pdfContent = (await api.medicationCharts.medicationChartGetResidentMedicationSummary(patient.hsId)).data
      .content;
    //convert to byte array
    const byteArr = base64ToArrayBuffer(pdfContent!);
    //create blob
    const pdfByteArray = new Blob([byteArr], { type: 'application/pdf' });

    //create blob url
    return URL.createObjectURL(pdfByteArray);
  });

  return (
    <Container gap={1}>
      <Layout style={{ width: '100%' }} horizontal justify={'space-between'}>
        <PatientCompactInfo patient={props.patient} facility={facility!} />
        <Layout horizontal gap={2} align={'center'}>
          <Button
            disabled={pdfUrl.loading || !!pdfUrl.error}
            onClick={() => downloadPdf(pdfUrl.value!, `${props.patient.hsId!}_medication_chart.pdf`)}
          >
            Open pdf
          </Button>
          <Layout horizontal gap={0.25}>
            <Circle color={AllergyUtils.getChartReviewDateColour(props.patient.enrmcValidTo)} />
            <Heading>Chart review:</Heading>
            <Text style={{ color: AllergyUtils.getChartReviewDateColour(props.patient.enrmcValidTo) }}>
              {props.patient.enrmcValidTo
                ? format(DateUtils.toOffsetlessDate(props.patient.enrmcValidTo), 'dd MMM yyyy')
                : 'Unknown'}
            </Text>
          </Layout>
        </Layout>
      </Layout>
      <Layout justify={'center'} style={{ width: '100%' }}>
        {pdfUrl.loading && <Loader type="Oval" color="#005555" height={80} width={80} />}
        {pdfUrl.error !== undefined ? <Text>{pdfUrl.error.message}</Text> : <PDFViewer pdfBlobUrl={pdfUrl.value!} />}
      </Layout>
    </Container>
  );
}

function base64ToArrayBuffer(base64Arr: string) {
  const binaryString = window.atob(base64Arr);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

const Heading = styled.h4`
  font-weight: bold;
`;

const Circle = styled.div<{ color: string }>`
  height: 20px;
  width: 20px;
  border-radius: 10000000000px;
  background-color: ${(props) => props.color};
`;

const Container = styled(Grid)`
  height: 100%;
  grid-template-rows: min-content 1fr;
`;

//reference - https://attacomsian.com/blog/javascript-download-file
function downloadPdf(path: string, filename: string) {
  // Create a new link
  const anchor = document.createElement('a');
  anchor.href = path;
  anchor.download = filename;

  // Append to the DOM
  document.body.appendChild(anchor);

  // Trigger `click` event
  anchor.click();

  // Remove element from DOM
  document.body.removeChild(anchor);
}
