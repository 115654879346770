import {useState, useEffect} from "react";

/**
 * Hook returns true if the component is loaded, false if it has been unloaded.
 */
export function useIsMounted(): boolean {
    const [isMounted, setIsMounted] = useState(true);
    useEffect(() => {
        return () => setIsMounted(false);
    }, [setIsMounted]);
    return isMounted;
}
