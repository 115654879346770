import React, {useState} from 'react';
import {useForm, UseFormMethods} from 'react-hook-form';
import styled from 'styled-components';
import { ValidationMessages } from '../../../../core/utils/validationMessages';
import { Form } from '../../../../kit/Forms/Form';
import { FormGroup } from '../../../../kit/Forms/FormGroup';
import { TextInput } from '../../../../kit/Forms/TextInput';
import { Select } from '../../../../kit/Forms/Select';
import { Grid } from '../../../../kit/Grid';
import { OnboardingPageActions } from '../OnboardingPage';
import { NextButton, PageNavigationButtonContainer, PreviousButton } from './NavigationButtonContainer';
import { Text } from '../../../../kit/Text';
import {HSFacility, RoleDto} from "server-openapi";
import {toasts} from "../../../../kit/Toasts/Toaster";
import {FacilityGroupOnlineService} from "../../../../core/mrs/Services";
import {AxiosError} from "axios";
import {LoginType} from "server-openapi/dist/generated-sdk/api";
import {SelectOption} from "../../../../kit/Forms/Select";

const loginTypeOptions = Object.keys(LoginType).map((t) => ({ label: t, value: LoginType[t as keyof typeof LoginType] } as SelectOption<LoginType>));
interface IProps extends OnboardingPageActions {
  facilityGroupId: number | undefined;
}

export interface FacilityManager {
  firstName?: string;
  lastName?: string;
  email?: string;
  loginType?: LoginType;
}

async function onboardFacilityGroup(
    form: UseFormMethods<FacilityManager>,
    selectedFacilityGroupId?: number
): Promise<boolean> {
  if (!selectedFacilityGroupId) {
    toasts.error('Insufficient data to onboard facility.');
    return false;
  }

  try {
    await FacilityGroupOnlineService.onboard({
      facilityGroupId: selectedFacilityGroupId!,
      facilityGroupAdmin: {
        firstName: form.getValues().firstName || '',
        familyName: form.getValues().lastName || '',
        email: form.getValues().email || '',
        loginType: form.getValues().loginType || LoginType.Sso
      },
    });
    toasts.success('Facility group onboarded successfully.');
  }
  catch (e) {
    toasts.error('Error onboarding facility.  Error is ' + e);
    return false;
  }

  return true;
}

export function InviteFacilityManagerPanel(props: IProps) {
  const form = useForm<FacilityManager>();
  async function onSubmit(fm: FacilityManager) {
    if (await onboardFacilityGroup(form, props.facilityGroupId)) {
      props.goToNextPage();
    }
  }

  return (
    <>
      <Form form={form} onSubmit={onSubmit}>
        <InviteFacilityManagerContainer colsTemplate="350px 350px 350px 1fr" gap={1}>
          <FormGroup label="FIRST NAME" fullWidth>
            <TextInput
              name="firstName"
              fullWidth
              required
              autoComplete="off"
            />
          </FormGroup>
          <FormGroup label="LAST NAME" fullWidth>
            <TextInput
              name="lastName"
              fullWidth
              required
              autoComplete="off"
            />
          </FormGroup>
          <FormGroup label="LOGIN TYPE" fullWidth>
            <Select
                name="loginType"
                fullWidth
                required
                options={loginTypeOptions}
            />
          </FormGroup>
          <FormGroup label="EMAIL" fullWidth>
            <TextInput
              name="email"
              fullWidth
              required
              autoComplete="off"
              rules={{
                validate: (email: string) =>
                  ValidationMessages.email.regex!.test(email) || ValidationMessages.email.message,
              }}
            />
          </FormGroup>
        </InviteFacilityManagerContainer>
        <PageNavigationButtonContainer>
          <PreviousButton onClick={props.goToPreviousPage}>Back</PreviousButton>
          <NextButton type="submit">Next</NextButton>
        </PageNavigationButtonContainer>
      </Form>
    </>
  );
}

const InviteFacilityManagerContainer = styled(Grid)`
  background: ${(p) => p.theme.backgrounds.lighter.bg};
  padding: 20px;
`;
