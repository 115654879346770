import itiriri from 'itiriri';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { NavigationBar } from '../../../../components/NavigationBar/NavigationBar';
import { useSyncCenter } from '../../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../../core/storage/hooks/UseStore';
import { RoundProgressIndicator } from './RoundProgressIndicator';
import { HSDoseRound } from 'server-openapi';
import { Grid } from '../../../../kit/Grid';
import { Button } from '../../../../kit/Button';
import { Intent } from '../../../../kit/Theme/Theme';
import { Layout } from '../../../../kit/Layout';
import { setSyncRequired } from '../../../../context/AppStateProvider';
import {NavigationBarMenu} from "../../../../components/NavigationBar/NavigationBarMenu";

interface IProps {
  numberOfResidentsInRound: number;
  numberOfResidentsSeen: number;
  facilityGroupId: string;
  round: HSDoseRound;
}

export function RoundNavbar(props: IProps): JSX.Element {
  const history = useHistory();
  const services = useSyncCenter();
  const facilityGroupsStore = useStore(services.facilityGroups.store).store;

  const selectedFacilityGroup = itiriri(facilityGroupsStore.values()).find(
    (fg) => `${fg.hsId}` === props.facilityGroupId,
  )!;

  const dashboardLink = `/facility-group/${props.facilityGroupId}`;

  return (
    <NavigationBar
      nodes={() => ([
              <Grid gap={0} colsTemplate="0.2fr 1fr">
                  <RoundProgressIndicator
                      residentsSeen={props.numberOfResidentsSeen}
                      totalResidentsInRound={props.numberOfResidentsInRound}
                  />
                  <h1>
                      {props.numberOfResidentsSeen} of {props.numberOfResidentsInRound} Residents seen
                  </h1>
              </Grid>,
              <>
                  <Layout horizontal gap={1} style={{ marginRight: '1em' }}>
                      <Link to={dashboardLink}>
                          <Button intent={Intent.Secondary}>Administer PRN</Button>
                      </Link>
                      <Link to={dashboardLink}>
                          <Button intent={Intent.Secondary}>Administer NIM</Button>
                      </Link>
                      <Button
                          onClick={() => {
                              setSyncRequired(true);
                              history.push(`/facility-group/${props.facilityGroupId}/rounds/${props.round.clinicalSystemId}/finish`);
                          }
                          }
                      >
                          Complete round
                      </Button>
                  </Layout>
                  <NavigationBarMenu facilityGroup={selectedFacilityGroup}/>
              </>,
          ]
      )}
      facilityGroup={selectedFacilityGroup}
    />
  );
}
