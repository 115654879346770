import React, { useContext, useEffect, useReducer, useState} from "react";
export type ResidentSortType = 'Name' | 'Room' | 'Wing';
export type ResidentSortOrderType = 'Ascending' | 'Descending';


interface RoundFilterState {
    sort: ResidentSortType;
    order: ResidentSortOrderType;
}

// This function fixes any persistent state from a previous version
interface NoopAction {
    type: "NOOP",
    payload?: any;
}

interface SetSortType {
    type: "SET_SORT_TYPE",
    payload: ResidentSortType;
}

interface SetSortOrder {
    type: "SET_SORT_ORDER",
    payload: ResidentSortOrderType;
}
interface Reset {
    type: "RESET"
}

type Action = NoopAction | SetSortType | SetSortOrder | Reset;

function reducer(state: RoundFilterState, action: Action): RoundFilterState {
    switch (action.type) {
        case "SET_SORT_TYPE":
           state = {
               ...state,
               sort: action.payload
           };
           break;

        case "SET_SORT_ORDER":
            state = {
                ...state,
                order: action.payload
            };
            break;

        case "RESET":
            state = {
                sort: "Name",
                order: "Ascending"
            };

        case "NOOP":
        default:
            break;
    }
    return state;
};

export type RoundFilterStateDispatchFunc = (action: Action) => void;
export type RoundFilterStore = [RoundFilterState, RoundFilterStateDispatchFunc];
// eslint-disable-next-line @typescript-eslint/no-empty-function
const defaultStore: RoundFilterStore = [{sort: "Name", order: "Ascending"}, () => {
}];
const Context = React.createContext<RoundFilterStore>(defaultStore);

export function useRoundFilterStore(): RoundFilterStore {
    return useContext(Context);
}


export function useRoundFilterState(): RoundFilterState {
    return useContext(Context)[0];
}

interface RoundFilterStateProviderProps {
    children?: React.ReactNode;
}

export const RoundFilterStateProvider: React.FC<RoundFilterStateProviderProps> = props => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [state, setState] = useState<RoundFilterState>({sort: "Name", order: "Ascending"});
    const store: RoundFilterStore = useReducer(reducer, state);

    return (
        <Context.Provider value={store}>
            {props.children}
        </Context.Provider>
    );
};

export function useResetRoundFilterContext() {
    const [, dispatch] = useRoundFilterStore();
    useEffect(() => {
        dispatch({
            type: "RESET"
        })
    }, []);
}
