


export const appconfig = {
  API_URL: getApiUrl(),
  OIDC_ISSUER: process.env.OIDC_ISSUER ?? `http://${window.location.hostname}:7100`,
  OIDC_CLIENT_ID: process.env.OIDC_CLIENT_ID ?? 'development',
  OIDC_SCOPE: process.env.OIDC_SCOPE ?? 'openid', // NOTE: For Azure B2C this must be "openid <application_id>": https://docs.microsoft.com/en-us/azure/active-directory-b2c/access-tokens#scopes
  PINSALT: 'AVV3Ji3nt601pKM8Mn9z',
  // Observability configuration
  ENVIRONMENT: process.env.ENVIRONMENT ?? 'development',
  APP_VERSION: process.env.APP_VERSION ?? 'development',
  GIT_SHA: process.env.GIT_SHA ?? 'development',
  SENTRY_ENABLED: process.env.SENTRY_ENABLED ?? false,
  SENTRY_DSN: process.env.SENTRY_DSN ?? '',
};

// Originally Medisphere was deployed with the SPA and the API server being in different kubernetes containers.
// The API_URL parameter was necessary. Now that the API server and SPA are served from the same origin, this
// parameter is not necessary in production and staging environments. Use the special value "origin" to indicate
// that the API server is from the same origin as the SPA.
//
// Use of this special value provides the ability to serve production from different URLs.
//
// In development the MRS (API server) listens on a different port to the SPA, so when API_URL is not specified,
// assume the development configuration.
function getApiUrl() {
  if (process.env.API_URL === "origin") {
    return window.location.origin
  }

  // If API_URL is not specified, assume we are running in the development environment
  return process.env.API_URL ?? `http://${window.location.hostname}:8000`;
}
