import { Dictionary } from 'lodash';
import { HSPatientAllergy } from 'server-openapi';
export const PatientLocalizations = {
  mapAllergyGroupType: (allergyGroupType: string) => {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (allergyGroupType) {
      case 'Substance':
        return 'Physical';
      default:
        return allergyGroupType;
    }
  },

  assignDefaultToMissingAllergies: (groupedAllergies: Dictionary<HSPatientAllergy[]>) => {
    const keys = Object.keys(groupedAllergies);
    ['Drug', 'Substance', 'Food'].forEach((allergyType) => {
      if (!keys.includes(allergyType)) groupedAllergies[allergyType] = [{ name: 'Nil Known' }];
    });
    return groupedAllergies;
  },
};
