import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid } from '../../kit/Grid';
import { useHistory, useParams } from 'react-router-dom';
import { Layout } from '../../kit/Layout';
import { IconBack } from '../../kit/Icons/Back';
import { Button } from '../../kit/Button';
import { useAsync } from '../../kit/hooks/UseAsync';
import { FacilityGroupConfigurationDto } from 'server-openapi';
import { apis } from '../../core/mrs/apis';
import { Loading } from '../../kit/Loading';
import { Text } from '../../kit/Text';

interface IParams {
  facilityGroupId: string;
}

export function HelpPage() {
  const history = useHistory();
  const { facilityGroupId } = useParams<IParams>();
  const [facilityGroupSettings, setFacilityGroupSettings] = useState<FacilityGroupConfigurationDto>();

  const asyncHook = useAsync(async () => {
    const data = (await apis.facilityGroups.facilityGroupsGetFacilityGroupConfiguration(parseInt(facilityGroupId)))
      .data;
    setFacilityGroupSettings(data);
  });
  const incidentsUrl =
    facilityGroupSettings?.facilityGroupIncidentUrl && facilityGroupSettings?.facilityGroupIncidentUrl !== ''
      ? facilityGroupSettings?.facilityGroupIncidentUrl
      : 'https://sites.riskman.net.au/RMPRStaging/Default.aspx?Pagename=/RMPRStaging/Riskman.aspx&qs=';

  const medicinesDatabaseURL =
      facilityGroupSettings?.facilityGroupMedicinesDatabaseURL && facilityGroupSettings?.facilityGroupMedicinesDatabaseURL !== ''
          ? facilityGroupSettings?.facilityGroupMedicinesDatabaseURL
          : 'about:blank';

  function navigateToUrl(url: string) {
    window.open(url, '_blank');
  }
  return (
    <>
      {asyncHook.loading || asyncHook.error ? (
        <Loading loading={asyncHook.loading} error={asyncHook.error} />
      ) : (
        <HelpPageContainer>
          <BackButtonContainer align={'center'} horizontal gap={1.5} onClick={() => history.goBack()}>
            <IconBack />
            <HelpText>Help</HelpText>
          </BackButtonContainer>
          <Layout horizontal gap={1} align={'center'} justify={'center'}>
            <Button large onClick={() => navigateToUrl('https://mpsconnect.com.au/Medisphere-training')}>
              <Text weight={'bold'}>TRAINING</Text>
            </Button>
            <Button large onClick={() => navigateToUrl('https://psp.mps-aust.com.au/Home/Resources')}>
              <Text weight={'bold'}>RESOURCES</Text>
            </Button>
            <Button large onClick={() => navigateToUrl(incidentsUrl)}>
              <Text weight={'bold'}>INCIDENTS</Text>
            </Button>
            <Button large onClick={() => navigateToUrl(medicinesDatabaseURL)}>
              <Text weight={'bold'}>Medicines Database</Text>
            </Button>

          </Layout>
        </HelpPageContainer>
      )}
    </>
  );
}

const HelpPageContainer = styled(Grid)`
  background-image: linear-gradient(165deg, #ad51f7 0%, #c24ddd 45%, #fa4197 80%);
  width: 100%;
  height: 100%;
  grid-template-rows: auto 1fr;
  padding: 2.5rem;
`;

const BackButtonContainer = styled(Layout)`
  cursor: pointer;
  max-height: 10%;
`;

const HelpText = styled.h1`
  color: ${(props) => props.theme.backgrounds.default.fg};
`;
