import React, { useEffect, useState } from 'react';
import ReactJson, { InteractionProps } from 'react-json-view';
import { Button } from '../Button';
import { setClipboard } from '../Clipboard';
import { Dialog } from '../Dialog';
import { Layout } from '../Layout';
import { Paper } from '../Paper';
import { Text } from '../Text';
import { Theme } from './themes';
import { toasts } from '../Toasts/Toaster';
import { useThemeContext } from './ThemeProvider';

export function ThemeEditor() {
  const [open, setOpen] = useState(false);

  const { theme, setTheme } = useThemeContext();

  // This effect adds the `window.theme()` method that
  // can be used to open the theme editor from devtools
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const w = window as any;

    w.theme = () => {
      setOpen(true);
    };

    return () => {
      delete w.theme;
    };
  }, []);

  const onEdit = (value: InteractionProps) => {
    setTheme(value.updated_src as Theme);
  };

  const onCopy = () => {
    setClipboard(JSON.stringify(theme, null, 2));
    toasts.success('Theme copied to clipboard ✅');
  };

  const onPaste = (event: React.ClipboardEvent) => {
    try {
      setTheme(JSON.parse(event.clipboardData.getData('Text')));
      toasts.success('Theme loaded from clipboard 🚀');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };

  return (
    <Dialog open={open} onRequestClose={() => setOpen(false)} secondary>
      <div onPaste={onPaste} style={{ display: 'flex', overflow: 'hidden' }}>
        <Layout gap={1}>
          <h2>Theme Editor</h2>
          <Paper>
            <Layout gap={0.5}>
              <Text>1. Edit a field using "cmd+click".</Text>
              <Text>2. Save a field using "cmd+enter".</Text>
              <Text>3. Copy the theme JSON using the "Copy To Clipboard" button</Text>
              <Text>4. Paste a theme JSON using "cmd+v".</Text>
            </Layout>
          </Paper>
          <div style={{ overflow: 'scroll', height: '100%' }}>
            <ReactJson
              src={theme}
              name="theme"
              indentWidth={2}
              enableClipboard={false}
              displayObjectSize={false}
              displayDataTypes={false}
              quotesOnKeys={false}
              sortKeys={true}
              onEdit={onEdit}
            />
          </div>

          <Button onClick={onCopy}>Copy To Clipboard</Button>
        </Layout>
      </div>
    </Dialog>
  );
}
