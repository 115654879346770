import itiriri from 'itiriri';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { HSDrug, HSRouteType } from 'server-openapi';
import styled from 'styled-components';
import { apis } from '../../../../../core/mrs/apis';
import { Button } from '../../../../../kit/Button';
import { createDataSource, DataTable } from '../../../../../kit/DataTable/DataTable';
import { Dialog } from '../../../../../kit/Dialog';
import { useAsync } from '../../../../../kit/hooks/UseAsync';
import { IconBin } from '../../../../../kit/Icons/Bin';
import { Layout } from '../../../../../kit/Layout';
import { Intent } from '../../../../../kit/Theme/Theme';
import { toasts } from '../../../../../kit/Toasts/Toaster';
import { useSyncCenter } from '../../../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../../../core/storage/hooks/UseStore';
import { DrugFormUtils } from '../../../../../syncstream/utils/DrugFormUtils';
import { AddItemDialogContent, SecondCheckItemType } from './AddItemDialogContent';

interface IItemToRemove {
  id: string;
  name: string;
  itemType: SecondCheckItemType;
}

interface IParams {
  facilityGroupId: string;
}

// eslint-disable-next-line max-lines-per-function
export function SecondCheckSettings() {
  const facilityGroupId = parseInt(useParams<IParams>().facilityGroupId);

  const services = useSyncCenter();

  const [openAddItemDialog, setOpenAddItemeDialog] = useState(false);
  const [secondCheckItemType, setSecondCheckItemType] = useState<SecondCheckItemType>(SecondCheckItemType.Drug);

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const [itemToRemove, setItemToRemove] = useState<IItemToRemove>();

  const secondCheckSettings = useAsync(async () => {
    return (await apis.secondCheckSettings.secondCheckSettingList(facilityGroupId)).data;
  });

  const routeSecondChecks = secondCheckSettings.value?.routes;

  const drugFormsStore = useStore(services.drugForms.store).store;
  const drugForms = itiriri(drugFormsStore.values()).toArray();

  const routeTypes = DrugFormUtils.getRouteTypes(drugForms);

  const routesTableData = routeSecondChecks
    ? routeSecondChecks.map((routeSecondCheck) => {
        const routeCode = routeSecondCheck.itemId;
        const routeType = routeTypes.find((rt) => rt.code === routeCode);

        return {
          col1: (
            <b>
              {routeCode} ({routeType?.name})
            </b>
          ),
          col2: (
            <DeleteItemContainer
              id={routeSecondCheck.id!.toString()}
              name={`${routeCode} (${routeType?.name})`}
              secondCheckItemType={SecondCheckItemType.Route}
            />
          ),
        };
      })
    : [];

  const drugSecondChecks = secondCheckSettings.value?.drugs;

  const drugStore = useStore(services.drugs.store).store;
  const drugs = itiriri(drugStore.values()).toArray();

  const drugsTableData = drugSecondChecks
    ? drugSecondChecks.map((drugSecondCheck) => {
        const drug = drugs.find((drug) => drug.hsId === parseInt(drugSecondCheck.itemId!))!;

        return {
          col1: (
            <b>
              {drug.name} {drug.formAbbreviation} {drug.strength}
            </b>
          ),
          col2: (
            <DeleteItemContainer
              id={drugSecondCheck.id!.toString()}
              name={`${drug.name!} ${drug.formAbbreviation} ${drug.strength}`}
              secondCheckItemType={SecondCheckItemType.Drug}
            />
          ),
        };
      })
    : [];

  function createSecondCheckDataSource(tableData: { col1: JSX.Element; col2: JSX.Element }[]) {
    return createDataSource(tableData, () => [
      { accessor: (data) => data.col1, Header: 'ITEM', width: '350px' },
      { accessor: (data) => data.col2, id: 'actions-table-column', width: '350px' },
    ]);
  }

  function DeleteItemContainer(props: { id: string; name: string; secondCheckItemType: SecondCheckItemType }) {
    return (
      <ActionContainer>
        <ActionButton
          onClick={() => {
            setItemToRemove({
              id: props.id,
              name: props.name,
              itemType: props.secondCheckItemType,
            });
            setOpenConfirmDeleteDialog(true);
          }}
        >
          <IconBin fill={'#636363'} />
        </ActionButton>
      </ActionContainer>
    );
  }

  async function onItemAdd(item?: HSDrug | HSRouteType) {
    let response;

    switch (secondCheckItemType) {
      case SecondCheckItemType.Route:
        response = (
          await apis.secondCheckSettings.secondCheckSettingCreateRoute({
            facilityGroupHsId: facilityGroupId,
            itemId: (item! as HSRouteType).code,
          })
        ).status;

        break;
      case SecondCheckItemType.Drug:
        response = (
          await apis.secondCheckSettings.secondCheckSettingCreateDrug({
            facilityGroupHsId: facilityGroupId,
            itemId: (item! as HSDrug).hsId?.toString(),
          })
        ).status;

        break;
    }

    if (response && response === 200) {
      toasts.success('Item added successfully');
      secondCheckSettings.refresh();
    }
  }

  async function onItemRemove() {
    const response = (
      await apis.secondCheckSettings.secondCheckSettingDelete({
        id: parseInt(itemToRemove!.id),
      })
    ).status;

    if (response === 204) {
      toasts.success('Item removed successfully');
      secondCheckSettings.refresh();
    }

    setOpenConfirmDeleteDialog(false);
  }

  function getExistingItems() {
    switch (secondCheckItemType) {
      case SecondCheckItemType.Drug:
        return drugSecondChecks?.map((d) => d.itemId!) ?? [];
      case SecondCheckItemType.Route:
        return routeSecondChecks?.map((r) => r.itemId!) ?? [];
    }
  }

  return (
    <>
      <Layout gap={2}>
        <div>
          <HeaderContainer>
            <HeaderText>Routes</HeaderText>
            <AddDrugContainer>
              <AddButton
                intent={Intent.Secondary}
                onClick={() => {
                  setSecondCheckItemType(SecondCheckItemType.Route);
                  setOpenAddItemeDialog(true);
                }}
              >
                Add
              </AddButton>
            </AddDrugContainer>
          </HeaderContainer>
          <TableContainer>
            <DataTable datasource={createSecondCheckDataSource(routesTableData)} filters={[]} />
          </TableContainer>
        </div>
        <div>
          <HeaderContainer>
            <HeaderText>Drugs</HeaderText>
            <AddDrugContainer>
              <AddButton
                intent={Intent.Secondary}
                onClick={() => {
                  setSecondCheckItemType(SecondCheckItemType.Drug);
                  setOpenAddItemeDialog(true);
                }}
              >
                Add
              </AddButton>
            </AddDrugContainer>
          </HeaderContainer>
          <TableContainer>
            <DataTable datasource={createSecondCheckDataSource(drugsTableData)} filters={[]} />
          </TableContainer>
        </div>
      </Layout>
      <Dialog lazy open={openAddItemDialog} onRequestClose={() => setOpenAddItemeDialog(false)} size="sm" secondary>
        <AddItemDialogContent
          itemType={secondCheckItemType}
          existingItems={getExistingItems()}
          onItemAdd={(item) => onItemAdd(item)}
          onDialogClose={() => setOpenAddItemeDialog(false)}
          isDialogOpen={openAddItemDialog}
        />
      </Dialog>

      <Dialog
        open={openConfirmDeleteDialog}
        onRequestClose={() => setOpenConfirmDeleteDialog(false)}
        size="sm"
        secondary
      >
        <h2>Are you sure you want to remove {itemToRemove?.name}</h2>
        <ConfirmDeleteButtonsContainer>
          <Button onClick={() => setOpenConfirmDeleteDialog(false)} intent={Intent.Secondary}>
            CANCEL
          </Button>
          <Button onClick={() => onItemRemove()} intent={Intent.Secondary}>
            CONFIRM
          </Button>
        </ConfirmDeleteButtonsContainer>
      </Dialog>
    </>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderText = styled.h2`
  margin: auto 0;
`;

const AddDrugContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const AddButton = styled(Button)`
  width: 84px;
  display: inline-block;
`;

const TableContainer = styled.div`
  margin-top: 20px;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: end;
`;

const ActionButton = styled(Button)`
  padding: 0 5px;
  background: white;

  &:focus {
    outline: none;
  }
`;

const ConfirmDeleteButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;
