import * as React from 'react';
import { SVGProps } from 'react';

const SvgWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 23 18"
    id="warning_icon"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="warning_icon"
      d="M 9 -1 h 4 v 14 H 9 z M 9 15 h 4 v 4 H 9 z"
      fill={props.color ?? '#4E2974'}
      stroke={props.color ?? '#4E2974'}
      strokeWidth={0.5}
    />
  </svg>
);

export default SvgWarning;
