import React, { useEffect, useState } from 'react';
import { createServiceProvider } from './AppServiceProvider';
import _ from 'lodash';
import {LoggerContextDispatchFunc} from "../core/logger/LoggerContext";

export let loggerDispatch: LoggerContextDispatchFunc;
export function setLoggerDispatch(fn: LoggerContextDispatchFunc) {
  loggerDispatch = fn;
}

const STORE_NAME = 'APP_STATE';
export interface AppState {
  selectedFacilityGroupId?: string;
  isInitialSyncComplete?: boolean;
  isFacilityGroupInitialSyncComplete?: { id: string; complete: boolean };
  isFacilityGroupLoaded?: boolean;
  isNewFacilityGroup?: boolean;
  lastSyncedUserSub?: string;
  isLocked?: boolean;
  isApplicationLoading?: boolean;
  progress?: number;
  encryptionVersion?: number;
  isLoggingOff?: boolean;
}

export let indexedDbException = false;
export function setIndexedDbException(status: boolean)
{
  indexedDbException = status;
}

//
export let hasBeenArchived = false;
export function setHasBeenArchived(archived: boolean)
{
  hasBeenArchived = archived;
}


// A global flag to indicate if we have refreshed the page or not.
// Defaults to true, and is set to false when the app state is initialised.
export let isRefresh = true;
export function setIsRefresh(refresh: boolean)
{
  isRefresh = refresh;
}

export let isLoggingOff = false;

export function setIsLoggingOff(isLoggingOffNow: boolean)
{
  isLoggingOff = isLoggingOffNow;
}

export let isSyncRequired = false;

export function setSyncRequired(syncRequired: boolean)
{
  // Only set it to true if we are not already in progress.
  // Always set it to false.
  if (!isSyncInProgress || !syncRequired) {
    isSyncRequired = syncRequired;
  }
}

export let lastSyncedAt = new Date();
export let isSyncInProgress = false;
export function setSyncInProgress(syncInProgress: boolean)
{
  if (!syncInProgress && isSyncInProgress) {
    // We were previously in-progress, but are now not in progress.
    // i.e. we have just finished syncing.
    lastSyncedAt = new Date();
  }
    isSyncInProgress = syncInProgress;

}
export let isCacheLoadInProgress = false;
export function setCacheLoadInProgress(cacheLoadInProgress: boolean)
{
  isCacheLoadInProgress = cacheLoadInProgress;
}
export let isSingleFacilityLoadInProgress = false;
export function setSingleFacilityLoadInProgress(singleFacilityLoadInProgress: boolean)
{
  isSingleFacilityLoadInProgress = singleFacilityLoadInProgress;
}

export let encryptionVersion = 1;
export function setEncryptionVersion(newEncryptionVersion: number)
{
  encryptionVersion = newEncryptionVersion;
}

export interface AppStateContext<AppState> {
  state: AppState;
  set: (value: AppState) => void;
}
const [ServiceProvider, useHook] = createServiceProvider<AppStateContext<AppState>>();

interface Props {
  children?: React.ReactNode;
}

export const AppStateProvider = (props: Props) => {
  const serialized = window.localStorage.getItem(STORE_NAME);
  const [state, setState] = useState<AppStateContext<AppState>['state']>(serialized ? JSON.parse(serialized) : {});

  useEffect(() => {
    if (isRefresh) {
      setIsRefresh(false);
    }
    set({
      isFacilityGroupLoaded: false
    });
  }, []);
  useEffect(() => {
    if (!!state) {
      window.localStorage.setItem(STORE_NAME, JSON.stringify(state));
    }
  }, [state]);

  function set(newState: AppState): void {

    const mergedState = { ...state, ...newState };
    if (!_.isEqual(mergedState, state)) {
      setState(mergedState);
    }
  }
  return <ServiceProvider value={{ state, set }}>{props.children}</ServiceProvider>;
};

export function useAppState() {
  return useHook();
}
