import * as React from 'react';
import { SVGProps } from 'react';

const IconLeftArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7.559 15.559.037 7.963 7.56.44V15.56Z" fill="#fff" />
  </svg>
);

export default IconLeftArrow;
