import {CreateTestResultReq, HSDoseRound, HSFacility, HSPatient, HSTestResult} from 'server-openapi';
import { PersistentQueue } from '../core/queue/PersistentQueue';
import {Entry, IStorage} from '../core/storage/Contract';
import { SyncStreamAPI } from './api';
import {IFacilityGroupSyncService } from './SyncCenter';
import { v4 as uuidv4 } from 'uuid';
import itiriri from 'itiriri';
import { SyncUtils } from './utils/SyncUtils';
import { Logger } from '../core/logger/logger';
import { DateUtils } from '../core/utils/dateUtils';
import { subDays, startOfDay } from 'date-fns';
import {MemoryCache} from "../core/storage/MemoryCache";

export interface MedisphereTestResult extends HSTestResult {
  administeredDrugClinicalSystemId?: string;
  administeredAdHocDrugClinicalSystemId?: string;
}

export type TestResultOp = CreateTestResultOp;

export interface CreateTestResultOp {
  type: 'test-result-create';
  clinicalSystemId?: string;
  administeredDrugRoundClinicalSystemId?: string;
  medisphereTestResult: MedisphereTestResult;
  request: CreateTestResultReq;
}

const logger = new Logger('SyncTests');

export class SyncTestResults implements IFacilityGroupSyncService {
  get name(): string {
    return 'SyncTestResults';
  }
  private async isStale(r: MedisphereTestResult): Promise<boolean> {
    return (!!(r.timeStamp && r.timeStamp < DateUtils.fromDate(subDays(startOfDay(new Date()), 7))));

  }
  private async isFacilityGroup(p: MedisphereTestResult, facilityGroupId: string): Promise<boolean> {
    const facilityGroup = await this.facilitiesStore.get(p.facilityId!.toString());
    return facilityGroup?.facilityGroupId?.toString() == facilityGroupId;
  }

  // Need to update this after the test result store has been loaded.

  constructor(
    private api: SyncStreamAPI,
    private storage: MemoryCache<MedisphereTestResult>,
    private facilitiesStore: IStorage<HSFacility>,
    private roundsStore: IStorage<HSDoseRound>,
    private latestChangeNumbers: IStorage<number | undefined>,
    private queue: PersistentQueue<TestResultOp>,
  ) {}
  async load(facilityGroupId: string): Promise<void> {
    await this.storage.reset(async (p) => {
          return await this.isFacilityGroup(p, facilityGroupId);
    });
  }

  enqueue = {
    createTestResult: async (req: CreateTestResultOp) => {
      const testResultClinicalSystemId = uuidv4();
      req.request.testResult.clinicalSystemId = testResultClinicalSystemId;
      const testResult = {
        key: testResultClinicalSystemId,
        value: req.request.testResult,
      };

      await this.storage.set(testResult.key, req.medisphereTestResult);
      await this.queue.unshift({ ...req, clinicalSystemId: testResultClinicalSystemId });
      return testResult;
    },
  };

  async syncDown(facilityGroupId?: string) {
    const facilitiesToSync = await SyncUtils.getFacilitiesForGroup(facilityGroupId, this.facilitiesStore);
    const changeNumber = await SyncUtils.getChangeNumberForFacilities(
      facilitiesToSync.map((x) => x.hsId!),
      this.latestChangeNumbers,
    );

    const testResultsData = await this.syncFacilityDown(
      facilityGroupId!, // non-null assertion on facility group id
      changeNumber,
    );

    await this.storage.setMany(
      testResultsData
        .map((testResult) => {
          const key = this.storage.get_key!(testResult);

          return {
            key: key!,
            value: {
              ...testResult,
              clinicalSystemId: key,
            },
          };
        }),
    );

    await SyncUtils.setChangeNumberForFacilities(
        facilitiesToSync.map((x) => x.hsId!),
        this.latestChangeNumbers,
        testResultsData
    );
  }

  private async syncFacilityDown(
    facilityGroupId: string,
    changeNumber: number,
  ): Promise<MedisphereTestResult[]> {
    const pageSize = 200;
    const currentDate = startOfDay(new Date());
    const fromDate = subDays(currentDate, 7);

    //changeNumber = isFinite(changeNumber) ? changeNumber : 0;
    const testResults = await this.api.testResults.testResultList(
      changeNumber,
      parseInt(facilityGroupId),
      pageSize
    );

    if (testResults.data.length === pageSize) {
      return [
        ...testResults.data,
        ...(await this.syncFacilityDown(
          facilityGroupId,
          SyncUtils.getLatestChangeNumber(testResults.data)!,
        )),
      ];
    }
    return testResults.data;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  async syncUp() {
    const entries = new Map<string, MedisphereTestResult>();
    for await (const delivery of this.queue.iterate()) {
      try {
        // Ignore entries with no test result type.
        if (!delivery.value.request.testResult.hsTestResultTypeId ||
            !delivery.value.request.testResult.testResultEntries ||
            delivery.value.request.testResult.testResultEntries.length === 0 ||
            !delivery.value.request.testResult.testResultEntries[0].value) {
          console.log("Ignoring invalid test result");
          await delivery.complete();
          continue;
        }
        // eslint-disable-next-line sonarjs/no-small-switch
        switch (delivery.value.type) {
          case 'test-result-create':
            if (delivery.value.administeredDrugRoundClinicalSystemId) {
              const round = await this.roundsStore.get(delivery.value.administeredDrugRoundClinicalSystemId);

              if (round === undefined) {
                throw new Error('Round id not found when creating test');
              }

              if (delivery.value.medisphereTestResult.administeredDrugClinicalSystemId) {
                const drugId = round.administeredDoses
                  ?.flatMap((dose) => dose.administeredDrugs)
                  .find(
                    (drug) =>
                      drug?.clinicalSystemId === delivery.value.medisphereTestResult.administeredDrugClinicalSystemId,
                  )?.hsId;

                if (drugId === undefined) {
                  throw new Error('Administered drug id not found when creating test');
                }

                delivery.value.request.testResult.administeredDrugId = drugId;
              }

              if (delivery.value.medisphereTestResult.administeredAdHocDrugClinicalSystemId) {
                const adHocDrugId = round.administeredDoses
                  ?.flatMap((dose) => dose.administeredAdHocDrugs)
                  .find(
                    (drug) =>
                      drug?.clinicalSystemId ===
                      delivery.value.medisphereTestResult.administeredAdHocDrugClinicalSystemId,
                  )?.hsId;

                if (adHocDrugId === undefined) {
                  throw new Error('Administered adhoc drug id not found when creating test');
                }

                delivery.value.request.testResult.administeredAdHocDrugId = adHocDrugId;
              }
            }
            const newTestResult = await this.api.testResults.testResultCreate(delivery.value.request);
            entries.set(delivery.value.clinicalSystemId!, {
              ...newTestResult.data,
              changeNumber: undefined,
              clinicalSystemId: delivery.value.clinicalSystemId!,
            });

            await delivery.complete();
        }
      } catch (error) {
        logger.error('Sync test results failed', error);
        await delivery.failed();
      }
    }
    await this.storage.setMany(
      itiriri(entries.entries())
        .map((entry) => ({ key: entry[0], value: entry[1] }))
        .toArray(),
    );
  }

  async clear() {
    await this.storage.clear();
    await this.latestChangeNumbers.clear();
    await this.queue.clear();
  }

  async hasQueuedData() {
    return (await this.queue.length()) > 0;
  }
  isAllowed(canUserAccessMedication: boolean): boolean {
    // Only if you can view a round.
    return canUserAccessMedication;
  }
  async archive(deletedPatientIds: string[]): Promise<void> {
    const keysToDelete: string[] = [];
    for (let [k, v] of (await this.storage.all()).entries()) {
      if (await this.isStale(v) || deletedPatientIds.some(x => x === v.patientId?.toString() ?? "-1")) {
        keysToDelete.push(k)
      }
    }
    await this.storage.deleteMany(keysToDelete);
  }
  setEncryptionVersion(version: number): void {
    this.storage.compressOnSave = (version > 1);
  }
  async rewrite(): Promise<void> {
    const entries: Entry<HSTestResult>[] = [...(await this.storage.all())].map((keyValueArray) => {
      return {
        key: keyValueArray[0],
        value: keyValueArray[1]
      };
    });

    return await this.storage.setMany(entries);
  }}
