import { MemoryCache } from '../MemoryCache';
import React, { Dispatch, useContext, useEffect, useReducer } from "react";
import {Map as ImmutableMap} from "immutable";

interface State {
    memoryCaches: ImmutableMap<MemoryCache<any>, number>;
}

export function useMemoryCacheChangeCount(store: MemoryCache<any>) {
    const [{ memoryCaches }, dispatch] = useContext(Context);
    const changeCount = memoryCaches.get(store);
    const needsDispatch = typeof changeCount !== "number";

    // This side effect ensures that the store is registered with the
    // MemoryCacheProvider. Without this, the provider would not know
    // about any MemoryCaches.
    useEffect(() => {
        if (needsDispatch) {
            dispatch({type: "Register", payload: store});
        }
    }, [store, needsDispatch, dispatch]);

    return changeCount ?? store.changeCount;
}

interface ChangedAction {
    type: "Changed";
    payload: MemoryCache<any>;
}

interface RegisterAction {
    type: "Register";
    payload: MemoryCache<any>;
}

type Action = ChangedAction | RegisterAction;

function reducer(state: State, action: Action): State {
    switch (action.type) {
    case "Changed":
        state = {
            ...state,
            memoryCaches: state.memoryCaches.set(action.payload, action.payload.changeCount)
        };
        // console.log("MemoryCache: Changed %s, changeCount=%d", action.payload.name, action.payload.changeCount);
        break;
    case "Register":
        if (state.memoryCaches.get(action.payload) === undefined) {
            state = {
                ...state,
                memoryCaches: state.memoryCaches.set(action.payload, action.payload.changeCount)
            };
            // console.log("MemoryCache: Registered %s, changeCount=%d", action.payload.name, action.payload.changeCount);
        }
        break;
    default:
        break;
    }

    return state;
}

const initialState: State = {memoryCaches: ImmutableMap<MemoryCache<any>, number>()};
const initialValue: [State, Dispatch<Action>] = [initialState, () => initialState];
const Context = React.createContext(initialValue);
const {Provider} = Context;

interface MemoryCacheProviderProps {
    children: React.ReactNode;
}
export function MemoryCacheProvider(props: MemoryCacheProviderProps) {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const callback = (mc: MemoryCache<any>) => {
            dispatch({type: "Changed", payload: mc});
        }
        return MemoryCache.subscribe(callback);
    }, [dispatch]);

    return (
        <Provider value={[state, dispatch]}>
            {props.children}
        </Provider>
    );
}
