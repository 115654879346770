import React from 'react';
import { useHealthCheck } from '../../core/healthcheck/HealthCheckProvider';
import { LayoutContainer } from '../../kit/LayoutContainer';
import { Paper } from '../../kit/Paper';

export function RequireInternet(props: { children: JSX.Element }) {
  const isServerHealthy = useHealthCheck().isHealthy;
  if (isServerHealthy) {
    return props.children;
  }

  return (
    <LayoutContainer>
      <Paper secondary>
        <h3>Unavailable in the offline mode</h3>
      </Paper>
    </LayoutContainer>
  );
}
