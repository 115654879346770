/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import { range } from 'lodash-es';
import { User } from 'oidc-client';
import React from 'react';
import { useForm } from 'react-hook-form';
import { HSDrug, HSSyringeDriverActivityComment, SyringeDriverActivityKind } from 'server-openapi';
import styled from 'styled-components';
import { useCurrentUser } from '../../../../core/authn/UserProvider';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { Button } from '../../../../kit/Button';
import { Dialog } from '../../../../kit/Dialog';
import { Form } from '../../../../kit/Forms/Form';
import { FormGroup } from '../../../../kit/Forms/FormGroup';
import { RadioGroup } from '../../../../kit/Forms/RadioGroup';
import { Select } from '../../../../kit/Forms/Select';
import { TextArea } from '../../../../kit/Forms/TextArea';
import { TextInput } from '../../../../kit/Forms/TextInput';
import { Grid } from '../../../../kit/Grid';
import { Layout } from '../../../../kit/Layout';
import { Text } from '../../../../kit/Text';
import { toasts } from '../../../../kit/Toasts/Toaster';
import { useSyncCenter } from '../../../../syncstream/SyncCenterProvider';
import {
  MedisphereSyringeDriverActivity,
  SyncSyringeDriverActivities,
} from '../../../../syncstream/SyncSyringeDriverActivity';
import { ScheduledActivity } from '../../../../syncstream/utils/RoundUtils';
import { useRoundSchedule } from '../../../Rounds/services/RoundScheduleProvider';
import { DrugDetailLeftPanel } from '../drugDetails/DrugDetailLeftPanel';
import {
  MedicationInformationBox,
  MedicationInformationProps,
} from '../medicationInformation/MedicationInformationBox';
import { isSyringeDriverActivity } from '../medicationInformation/ScheduledActivityItems';
import {useGroupPermissions} from "../../../../core/authz/PermissionsProvider";

interface Props extends MedicationInformationProps {
  drug: HSDrug;
  open: boolean;
  setOpen: (open: boolean) => void;
  activity?: ScheduledActivity;
  activityKind: SyringeDriverActivityKind;
  confirmationInitials?: string;
  confirmationUserId?: number;
}

export function SyringeDriverActivityDialog(props: Props) {
  if (props.open && !props.activity?.syringeActivity && !props.administrationPermissions.canOperateSyringeDriver) {
    toasts.error('You do not have permission to operate syringe driver');
    props.setOpen(false);
  }
  return (
    <Dialog lazy closeButtonColor={'#fff'} open={props.open} onRequestClose={() => props.setOpen(false)} size="full">
      <DialogContainer horizontal gap={2} data-testid="medicine-administer-dialog">
        <DrugDetailLeftPanel
          drug={props.drug}
          packedMedication={props.packedMedication}
          facilityGroupId={props.facilityGroupId}
          patient={props.patient}
        />
        <ActivityForm {...props} />
      </DialogContainer>
    </Dialog>
  );
}

interface FormFields {
  siteStatus?: string;
  lineStatus?: string;
  lineClampingOn?: string;
  pumpInfusing?: string;
  batteryLevel?: string;
  notes?: string;
  volumeInfused: string;
  volumeToBeInfused: string;
  rateOfInfusion: string;
  stopReason?: SyringeDriverActivityKind;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function ActivityForm(props: Props) {
  const services = useSyncCenter();
  const groupPermissions = useGroupPermissions();

  const canRecordNote = groupPermissions.canRecordNote;
  const user = useCurrentUser();
  const roundSchedule = useRoundSchedule();
  const form = useForm<FormFields>({
    defaultValues: {
      stopReason: props.activity?.syringeActivity?.kind ?? undefined,
      siteStatus:
        props.activity?.syringeActivity?.siteCondition ?? props.activityKind === SyringeDriverActivityKind.Stop
          ? 'NAD'
          : 'New',
      lineStatus:
        props.activity?.syringeActivity?.lineCondition ?? props.activityKind === SyringeDriverActivityKind.Stop
          ? 'NAD'
          : 'New',
      // boolean values (particularly false) don't work well with form inputs, so we map to and from a boolean
      lineClampingOn:
        props.activity?.syringeActivity?.checkLineClamp === undefined
          ? 'No'
          : props.activity.syringeActivity.checkLineClamp
          ? 'Yes'
          : 'No',
      pumpInfusing:
        props.activity?.syringeActivity?.pumpInfusing === undefined
          ? 'Yes'
          : props.activity.syringeActivity.pumpInfusing
          ? 'Yes'
          : 'No',
      batteryLevel: props.activity?.syringeActivity?.batteryPercentage ?? '100.0',
      notes: props.activity?.syringeActivity?.comments?.[0]?.commentText ?? undefined,
      volumeInfused: props.activity?.syringeActivity?.volumeInfused ?? undefined,
      volumeToBeInfused: props.activity?.syringeActivity?.volumeToBeInfused ?? undefined,
      rateOfInfusion: props.activity?.syringeActivity?.rateOfInfusion ?? undefined,
    },
  });

  async function onSubmit(data: FormFields) {
    if (data.notes && !canRecordNote) {
      toasts.error('You do not have permission to record notes');
      return;
    }
    await processActivity(data, props, user, services.syringeDriverActivity.service);
    // Refresh round schedule
    if (props.currentRound) {
      roundSchedule.set({
        ...props.currentRound,
        version: (props.currentRound.version ?? 0) + 1,
      });
    }
    props.setOpen(false);
  }

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Layout gap={1}>
        <WhiteText size="large">Update Syringe Driver</WhiteText>
        <MedicationInformationBox displayCurrentStatusOnly {...props} />
        {props.activityKind === SyringeDriverActivityKind.Stop && (
          <FormGroup fullWidth label="Status">
            <RadioGroup
              name="stopReason"
              options={stopOptions}
              required
              disabled={!!props.activity?.syringeActivity}
              cols={3}
            />
          </FormGroup>
        )}
        <Grid cols={2}>
          {[SyringeDriverActivityKind.Observation.valueOf(), SyringeDriverActivityKind.Restart.valueOf()].includes(props.activityKind) && (
            <>
              <FormGroup fullWidth label="Site Status">
                <Select
                  name="siteStatus"
                  options={siteStatusOptions.map((option) => ({ label: option, value: option }))}
                  required
                  disabled={!!props.activity?.syringeActivity}
                  fullWidth
                />
              </FormGroup>
              <FormGroup fullWidth label="Line Status">
                <Select
                  name="lineStatus"
                  options={lineStatusOptions.map((option) => ({ label: option, value: option }))}
                  required
                  disabled={!!props.activity?.syringeActivity}
                  fullWidth
                />
              </FormGroup>
              <FormGroup fullWidth label="Line clamping on?">
                <Select
                  name="lineClampingOn"
                  options={booleanOptions.map((option) => ({ label: option, value: option }))}
                  required
                  disabled={!!props.activity?.syringeActivity}
                  fullWidth
                />
              </FormGroup>
              <FormGroup fullWidth label="Pump infusing?">
                <Select
                  name="pumpInfusing"
                  options={booleanOptions.map((option) => ({ label: option, value: option }))}
                  required
                  disabled={!!props.activity?.syringeActivity}
                  fullWidth
                />
              </FormGroup>
              <FormGroup fullWidth label="Battery level">
                <Select
                  name="batteryLevel"
                  options={range(0, 11).map((num) => ({ label: `${num * 10}%`, value: `${num * 10}.0` }))}
                  required
                  disabled={!!props.activity?.syringeActivity}
                  fullWidth
                />
              </FormGroup>
            </>
          )}
          <FormGroup fullWidth label="Volume infused">
            <TextInput
              name="volumeInfused"
              placeholder="Volume infused"
              required
              disabled={!!props.activity?.syringeActivity}
              fullWidth
            />
          </FormGroup>
          <FormGroup fullWidth label="Volume to be infused">
            <TextInput
              name="volumeToBeInfused"
              placeholder="Volume to be infused"
              required
              disabled={!!props.activity?.syringeActivity}
              fullWidth
            />
          </FormGroup>
          <FormGroup fullWidth label="Rate of infusion">
            <TextInput
              name="rateOfInfusion"
              placeholder="Volume infused"
              required
              disabled={!!props.activity?.syringeActivity}
              fullWidth
            />
          </FormGroup>
        </Grid>
        <FormGroup fullWidth>
          <TextArea
            name="notes"
            placeholder="Add note"
            rows={3}
            fullWidth
            required={props.activityKind === SyringeDriverActivityKind.Stop}
            disabled={!!props.activity?.syringeActivity}
          />
        </FormGroup>
        <Button type="submit" fullWidth disabled={!!props.activity?.syringeActivity}>
          UPDATE STATUS
        </Button>
      </Layout>
    </Form>
  );
}

const DialogContainer = styled(Layout)`
  padding: 1em;
  grid-template-columns: 3fr 5fr;
`;

const WhiteText = styled(Text)`
  color: white;
`;

// eslint-disable-next-line sonarjs/cognitive-complexity
async function processActivity(
  data: FormFields,
  props: Props,
  user: User,
  syringeDriverService: SyncSyringeDriverActivities,
) {
  if (
    !props.scheduledActivityInformation ||
    !isSyringeDriverActivity(props.scheduledActivityInformation.latestActivity) ||
    !isSyringeDriverActivity(props.scheduledActivityInformation.startActivity)
  ) {
    return;
  }

  const notes = data.notes ? [data.notes] : [];

  const endDate = DateUtils.getEndDateFromOpenApiDurationAndStartDate(
    props.scheduledActivityInformation.latestActivity.timeRemaining!,
    DateUtils.toDate(props.scheduledActivityInformation.latestActivity.createdAt!),
  );

  const now = new Date();

  const currentTimeRemaining = DateUtils.getOpenApiDurationFromInterval(now, endDate);

  const comments: HSSyringeDriverActivityComment[] = notes.map((note) => ({
    commentText: note,
    createdAt: DateUtils.fromDate(now),
    lastUpdatedBySubjectId: user.profile.sub,
  }));

  const drugId = props.scheduledActivityInformation.startActivity.administeredDrugId;

  const drug = props.scheduledActivityInformation.activityRound.administeredDoses
    ?.flatMap((dose) => dose.administeredDrugs)
    .find((drug) => drug?.hsId === drugId || drug?.clinicalSystemId === drugId?.toString());

  if (!drug) {
    toasts.error('Error finding administered drug');
    return;
  }

  if (!props.scheduledActivityInformation?.activityRound?.clinicalSystemId) {
    toasts.error('Error finding administered drug round');
    return;
  }

  const activity: MedisphereSyringeDriverActivity = {
    kind: data.stopReason ?? props.activityKind,
    volumeInfused: data.volumeInfused,
    volumeToBeInfused: data.volumeToBeInfused,
    rateOfInfusion: data.rateOfInfusion,
    timeRemaining: currentTimeRemaining,
    createdAt: DateUtils.fromDate(now),
    sitePosition: props.scheduledActivityInformation.latestActivity?.sitePosition,
    administeredDrugClinicalSystemId: drug.clinicalSystemId ?? undefined,
    administeredDrugId: drug.hsId ?? undefined,
    lastUpdatedBySubjectId: user.profile.sub,
    patientId: props.patient.hsId,
    comments: comments,
    lineCondition: data.lineStatus,
    checkLineClamp: data.lineClampingOn === 'Yes',
    siteCondition: data.siteStatus,
    batteryPercentage: data.batteryLevel,
    pumpInfusing: data.pumpInfusing === 'Yes',
    scheduledAt: props.activity ? DateUtils.fromDate(props.activity.time) : undefined,
    confirmationInitials:
      props.activityKind === SyringeDriverActivityKind.Restart ? props.confirmationInitials : undefined,
    confirmationUserId:
        props.activityKind === SyringeDriverActivityKind.Restart ? props.confirmationUserId : undefined,
  };

  const createdActivity = await syringeDriverService.enqueue.createSyringeDriverActivity({
    type: 'syringe-driver-activity-create',
    medisphereSyringeDriverActivity: activity,
    administeredDrugRoundClinicalSystemId: props.scheduledActivityInformation.activityRound.clinicalSystemId,
    request: { syringeDriverActivity: activity, facilityGroupId: props.facilityGroupId },
  });

  for (const comment of comments) {
    await syringeDriverService.enqueue.createSyringeDriverActivityComment({
      type: 'syringe-driver-activity-comment-create',
      syringeDriverActivityClinicalSystemId: createdActivity.key,
      request: {
        syringeDriverActivityComment: comment,
        syringeDriverActivityId: -1,
        facilityGroupId: props.facilityGroupId,
      },
    });
  }
}

const siteStatusOptions = ['New', 'NAD', 'Red', 'Inflamed/Swollen', 'Leakage'];

const lineStatusOptions = ['New', 'NAD', 'Crystallised/Cloudy'];

const booleanOptions = ['Yes', 'No'];

const stopOptions = [
  {
    value: SyringeDriverActivityKind.Stop,
    label: 'Stop',
  },
  {
    value: SyringeDriverActivityKind.Pause,
    label: 'Pause',
  },
  {
    value: SyringeDriverActivityKind.Cease,
    label: 'Cease',
  },
];
