import React from 'react';
import { Grid } from '../Grid';
import { RadioButton } from '../RadioButton';
import { FormInput, formInput } from './FormInput';
import { SelectOption } from './Select';
import { Intent } from '../Theme/Theme';

interface Props<T> extends FormInput<T> {
  options: SelectOption<T>[];
  cols?: number;
  disabled?: boolean;
  intent?: Intent;
  buttonBackgroundColor?: string;
}

export const RadioGroup = formInput(function <T>(props: Props<T>) {
  return (
    <Grid gap={1} cols={props.cols}>
      {props.options.map((opt, i) => (
        <RadioButton
          key={i}
          id={`${props.name}-${i}`}
          name={props.name}
          value={i}
          checked={props.value === opt.value}
          type="radio"
          disabled={props.disabled}
          intent={props.intent}
          onChange={(event) => {
            props.onChange?.(event, opt.value);
          }}
          label={opt.label}
          buttonBackgroundColor={props.buttonBackgroundColor}
        />
      ))}
    </Grid>
  );
});
