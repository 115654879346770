import React from 'react';
import { IoCheckmarkSharp, IoCloseSharp } from 'react-icons/io5';
import { AdHocReasonCode, ReasonCode } from 'server-openapi';

interface IProps {
  reasonCode: ReasonCode | AdHocReasonCode | undefined;
}

export function StatusIcon(props: IProps) {
  const { reasonCode } = props;

  switch (reasonCode) {
    case AdHocReasonCode.Dosed:
    case ReasonCode.Dosed:
    case ReasonCode.DosedLate:
    case ReasonCode.SelfAdministered:
      return <IoCheckmarkSharp />;
    case AdHocReasonCode.Refused:
    case ReasonCode.Refused:
      return <IoCloseSharp />;
    default:
      return <IoCloseSharp />;
  }
}
