import React, { useState } from 'react';
import {useForm} from 'react-hook-form';
import { HSTestResultEntry, HSTestResultFieldType, HSTestResultType, ReasonCode } from 'server-openapi';
import { Button } from '../../../../kit/Button';
import { StyledTextInput, TextInput } from '../../../../kit/Forms/TextInput';
import { Grid } from '../../../../kit/Grid';
import { Layout } from '../../../../kit/Layout';
import { Intent } from '../../../../kit/Theme/Theme';
import { getComments, validateTestResult } from '../MedicineAdministerDialog';
import styled from 'styled-components';
import { FormFieldError } from '../../../../kit/Forms/FormFieldError';
import { key } from 'localforage';
interface IProps {
  testResultType: HSTestResultType;
  onSubmit: (hsResultType: number, testResultEntries: Array<HSTestResultEntry>) => void;
}

export function TestSidebarForm(props: IProps) {
  const testResultFields: Array<HSTestResultFieldType> = props.testResultType.testResultFields
    ? props.testResultType.testResultFields
    : [];
  const [response, setResponse] = useState<Array<string>>(new Array(testResultFields.length).fill(''));
  const [error, setError] = useState<string[]>(new Array(testResultFields.length).fill(''));


  function onSubmit() {
    const testResultEntry: Array<HSTestResultEntry> = testResultFields.map((field, index) => {
      return {
        key: field.description,
        value: response[index],
      };
    });

    props.onSubmit(props.testResultType.hsId!, testResultEntry);

    setResponse(new Array(testResultFields.length).fill(''));
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>, position: number) {

    const errorForm = validateTestResult(e.target.value, props.testResultType.name!);

    const errArr: string[] = error;

    if (typeof(errorForm)==='string')
      errArr[position] = errorForm;
    else errArr[position] = '';

    setError(errArr);

    const newArr = response.map((item, index) => {
      return index === position ? e.target.value : item;
    });
    setResponse(newArr);
  }

  return (
    <Grid alignItemsCenter cols={2} colsTemplate={'min-content auto'}>
      <Layout gap={0.5}>
        {testResultFields.map((testResultField, index) => {
          const placeholder: string = testResultFields[index].description
            ? testResultFields[index].description!
            : 'placeholder missing';
          return (
              <Layout key={`layout`+index}>
                <StyledTextInput
                  name="testResultValue"
                  placeholder={placeholder}
                  onChange={(e) => handleChange(e, index)}
                  key={`input`+index}
                  value={response[index]}
                  required
                  type="number"
                />
                <FormFieldError key={`error`+index} error={error[index]} />
              </Layout>
          );
        })}
      </Layout>
      <div>
        <Button disabled={error.join("").length>0 || response.includes("")} onClick={onSubmit} intent={Intent.Secondary} type="submit">
          Add Result
        </Button>
      </div>
   </Grid>
  );
}


const HiddenTextInput = styled(TextInput)`
  height: 0;
  width: 0;
  overflow: hidden;
  position: absolute;
  display: none;
`;