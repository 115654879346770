import React from 'react';
import { HSDrug, HSFacility, HSPatient } from 'server-openapi';
import { OrderRow } from '../../OrderRow';
import { mapOrderToView } from '../../OrderViewModel';
import { OrderResupplyProps } from '../../OrderResupplyDialog';
import { OrderNotesProps } from '../../OrderNotesDialog';
import { Order } from '../../../../../../common/Order';
import { Layout } from '../../../../../../kit/Layout';
import { Text } from '../../../../../../kit/Text';

interface Props {
  patient: HSPatient;
  orders: Order[];
  facilitiesStore: ReadonlyMap<string, HSFacility>;
  patientsStore: ReadonlyMap<string, HSPatient>;
  drugsStore: ReadonlyMap<string, HSDrug>;
  openOrderResupply?: (orderResupply: OrderResupplyProps) => void;
  openOrderNotes?: (orderNotes: OrderNotesProps) => void;
}

export function OrderPatientResupplyHistory(props: Props) {
  const facility = props.facilitiesStore.get(props.patient.facility!.toString())!;
  const mapper = mapOrderToView(
    facility.facilityGroupId!,
    props.patientsStore,
    props.facilitiesStore,
    props.drugsStore,
    false,
    props.openOrderResupply,
    props.openOrderNotes,
  );

  const orders = props.orders.map(mapper);
  return <Layout gap={1}>{orders ? orders.map(OrderRow) : <Text>No Orders</Text>}</Layout>;
}
