import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { UpdateNimConsumerCommentReq } from 'server-openapi';
import { apis } from '../../../../../core/mrs/apis';
import { Button } from '../../../../../kit/Button';
import { Dialog } from '../../../../../kit/Dialog';
import { Form } from '../../../../../kit/Forms/Form';
import { TextArea } from '../../../../../kit/Forms/TextArea';
import { Layout } from '../../../../../kit/Layout';
import { Intent } from '../../../../../kit/Theme/Theme';
import { toasts } from '../../../../../kit/Toasts/Toaster';

interface IProps extends UpdateNimConsumerCommentReq {
  open: boolean;
  setOpen: (open: boolean) => void;
  refreshResidents: () => Promise<void>;
}

export function NimsResidentCommentDialog(props: IProps) {
  const { open, setOpen } = props;

  const form = useForm<FormFields>();

  useEffect(() => {
    form.setValue('comment', props.comment ?? undefined);
  }, [props.comment]);

  async function onSubmit(data: FormFields) {
    await apis.nimConsumers.nimConsumerUpdateComment({
      nimAvailableDrugId: props.nimAvailableDrugId,
      hsPatientId: props.hsPatientId,
      comment: data.comment && data.comment.trim() !== '' ? data.comment.trim() : null,
    });

    toasts.success('Resident NIMs comment has been updated');

    setOpen(false);

    props.refreshResidents();
  }

  return (
    <Dialog lazy open={open} onRequestClose={() => setOpen(false)} size="sm" secondary>
      <Layout gap={1}>
        <Form form={form} onSubmit={onSubmit}>
          <h3>NIM comment</h3>
          <TextArea name="comment" fullWidth rows={3} />
          <Layout justify="right" horizontal gap={1}>
            <Button intent={Intent.Secondary} onClick={() => setOpen(false)}>
              CANCEL
            </Button>
            <Button intent={Intent.Secondary} type="submit">
              {props.comment ? 'UPDATE' : 'ADD'}
            </Button>
          </Layout>
        </Form>
      </Layout>
    </Dialog>
  );
}

interface FormFields {
  comment: string;
}
