import React, { useState } from 'react';
import { FileInput } from '../kit/Forms/FileInput';
import Papa from 'papaparse';
import { isArray } from 'lodash';
import { Grid } from '../kit/Grid';
import { Text } from '../kit/Text';
import styled from 'styled-components';
import { Intent } from '../kit/Theme/Theme';

interface RowError {
  row: number;
  error: string;
}

interface CsvColumnRule {
  name: string;
  match?: string[] | RegExp;
  isValueCommaSeparated?: boolean;
}

interface Props {
  columnRules: CsvColumnRule[];
  onChange: (output: Record<string, string>[]) => void;
  label?: string;
  justify?: 'right' | 'left' | 'center';
  disabled?: boolean;
  fullWidth?: boolean;
  intent?: Intent;
}

export function CsvParser(props: Props) {
  const [fileName, setFileName] = useState<string>();
  const [errors, setErrors] = useState<RowError[]>([]);
  function onUploadCsv(fileInput: File) {
    setFileName(fileInput.name);
    Papa.parse(fileInput, {
      header: true,
      skipEmptyLines: true,
      complete: function (results: { data: Record<string, string>[] }) {
        const newErrors = GetCsvErrors(results.data, props.columnRules);
        setErrors(newErrors);
        if (newErrors.length > 0) {
          return;
        }
        props.onChange(results.data);
      },
    });
  }
  return (
    <Grid cols={1} gap={1}>
      <FileInputContainer justifyRight={props.justify}>
        <FileInput
          label={props.label || 'IMPORT FILE'}
          name="csvInput"
          accept={['.csv', '.xls', '.xml']}
          onChange={(_, file) => onUploadCsv(file)}
          disabled={props.disabled}
          fullWidth={props.fullWidth}
          intent={Intent.Secondary}
        />
      </FileInputContainer>
      {fileName && errors.length === 0 && <Text>Added: {fileName}</Text>}
      {fileName && errors.length > 0 && <Text>Failed to import from: {fileName}</Text>}
      {errors.length > 0 && (
        <ErrorBox cols={1} gap={1}>
          {errors.map((error, index) => (
            <div key={index}>
              <Text weight={'bold'}>Row {error.row + 1}: </Text>
              <Text>{error.error}</Text>
            </div>
          ))}
        </ErrorBox>
      )}
    </Grid>
  );
}

const FileInputContainer = styled.div<{ justifyRight?: string }>`
  justify-self: ${(p) => p.justifyRight ?? ''};
`;

function GetCsvErrors(data: Record<string, string>[], columnRules: CsvColumnRule[]) {
  let newErrors: RowError[] = [];
  if (data.length === 0) {
    newErrors = [...newErrors, { row: 0, error: 'No results found' }];
  }
  data.forEach((result, index) => {
    const entries = Object.entries(result);
    columnRules.forEach((rule) => {
      const columnRuleError = GetColumnRuleError(rule, entries, index);
      if (columnRuleError) {
        newErrors = [...newErrors, columnRuleError];
      }
    });
  });
  return newErrors;
}

function GetColumnRuleError(rule: CsvColumnRule, entries: [string, string][], rowIndex: number): RowError | undefined {
  const ruleMatchEntry = entries.find((entry) => entry[0].toLowerCase() === rule.name.toLowerCase());

  if (ruleMatchEntry === undefined) {
    return { row: rowIndex, error: `No matching column for ${rule.name} found` };
  }

  if (!rule.match) {
    return undefined;
  }

  const values = rule.isValueCommaSeparated ? ruleMatchEntry[1].split(',').map((v) => v.trim()) : [ruleMatchEntry[1]];

  for (const value of values) {
    if (!doesColumnValueMatchRule(rule.match, value)) {
      return { row: rowIndex, error: `${value} is an invalid value for the '${rule.name}' column` };
    }
  }
}

function doesColumnValueMatchRule(match: string[] | RegExp, value: string) {
  return (
    (isArray(match) && match.find((matchString) => value.toLowerCase() === matchString.toLowerCase())) || (!isArray(match) && match.test(value))
  );
}

const ErrorBox = styled(Grid)`
  background: white;
  color: red;
`;
