import * as React from 'react';

export function IconDrowsiness(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      style={{ ...props.style, backgroundColor: 'white', borderRadius: '999999px', marginRight: '4px' }}
      {...props}
    >
      <path d="M14.2 13H3.8L9 4l5.2 9zm-6.9-2h3.5L9 8l-1.7 3z" fill="#ea2a2a" />
    </svg>
  );
}
