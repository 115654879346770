import React, { useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { FacilityManager, InviteFacilityManagerPanel } from './components/InviteFacilityManagerPanel';
import { SelectFacilityGroupPanel } from './components/SelectFacilityGroupPanel';
import itiriri from 'itiriri';
import { InviteFacilityUsersPanel } from './components/InviteFacilityUsersPanel';
import urljoin from 'url-join';
import { useSyncCenter } from '../../../syncstream/SyncCenterProvider';
import {NavigationBar} from "../../../components/NavigationBar/NavigationBar";
import {IconBack} from "../../../kit/Icons/Back";
import {Grid} from "../../../kit/Grid";

interface Step {
  stepNumber: number;
  route: string;
  stepHeader: string;
  stepProgress: string;
  component: (props: OnboardingPageActions) => JSX.Element;
}

export interface OnboardingPageActions {
  goToPreviousPage: () => void;
  goToNextPage: () => void;
}

// eslint-disable-next-line max-lines-per-function
export function OnboardingPage() {
  const [selectedFacilityGroupId, setSelectedFacilityGroupId] = useState<number>();
  const services = useSyncCenter();

  const STEPS: Step[] = [
    {
      stepNumber: 1,
      route: 'facility',
      stepHeader: 'Select your facility',
      stepProgress: 'SELECT FACILITY',
      component: (props: OnboardingPageActions) => (
        <SelectFacilityGroupPanel
          onSetFacilityGroupId={setSelectedFacilityGroupId}
          selectedFacilityGroupId={selectedFacilityGroupId}
          {...props}
        />
      ),
    },
    {
      stepNumber: 2,
      route: 'facility-manager',
      stepHeader: 'Choose facility manager',
      stepProgress: 'FACILITY MANAGER',
      component: (props: OnboardingPageActions) => (
        <InviteFacilityManagerPanel
            facilityGroupId={selectedFacilityGroupId}
          {...props}
        />
      ),
    },
    {
      stepNumber: 3,
      route: 'facility-users',
      stepHeader: 'Add users',
      stepProgress: 'USERS',
      component: (props: OnboardingPageActions) => (
        <InviteFacilityUsersPanel
          selectedFacilityGroupId={selectedFacilityGroupId!}
          {...props}
        />
      ),
    },
  ];

  const history = useHistory();
  const { path } = useRouteMatch();
  const currentUrl = useLocation().pathname;
  const { stepNumber, stepHeader } = itiriri(STEPS).find(
    (s) => s.route === currentUrl.substring(currentUrl.lastIndexOf('/') + 1),
  )!;

  function updatePage(stepNumber: number) {
    const newStep = STEPS.find((step) => step.stepNumber === stepNumber);
    if (newStep === undefined) {
      history.push('/facility-group/switch');
    } else {
      history.push(newStep.route);
    }
  }

  return (
    <OnboardingPageContainer>
      <NavigationBar
          inverted
          nodes={() => ([
              <LeftNav gap={1} cols={2}>
                <IconBack onClick={() => updatePage(-1)} />
                <h1>Onboard Facility</h1>
              </LeftNav>
          ])}
      />
      <div>
        <StepInfo>
          { STEPS[stepNumber - 1] ? STEPS[stepNumber - 1].stepHeader : null } <i>(step {stepNumber} of {STEPS.length})</i>
        </StepInfo>
      </div>

      <Switch>
        <Redirect exact path={path} to={urljoin(path, STEPS[0].route)} />
        {STEPS.map((step) => (
          <Route path={urljoin(path, step.route)} key={step.stepNumber}>
            {step.component({
              goToPreviousPage: () => updatePage(step.stepNumber - 1),
              goToNextPage: () => updatePage(step.stepNumber + 1),
            })}
          </Route>
        ))}
      </Switch>
    </OnboardingPageContainer>
  );
}

const OnboardingPageContainer = styled.div`
  padding: 50px;
`;

const StepInfo = styled.div`
  color: ${(p) => p.theme.backgrounds.dark.fg};
  font-size: 1.5em !important;
  padding-bottom: 1em;
  padding-top: 3em;
`;

const ProgressContainer = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 1fr 1fr 50px;
  margin: 50px -50px;
  background: ${(p) => p.theme.backgrounds.lighter.bg};
  height: 55px;
`;

interface ProgressStepProps {
  readonly isSelected?: boolean;
}

const ProgressStep = styled.span<ProgressStepProps>`
  background: ${(p) => (p.isSelected ? p.theme.backgrounds.light.bg : 'inherit')};
  text-align: center;
  line-height: 55px;
  font-size: 15px;
  font-weight: bold;
`;
const LeftNav = styled(Grid)`
  display: flex;
  align-items: center;
`;
