/* eslint-disable max-lines */
import { format, startOfDay } from 'date-fns';
import itiriri from 'itiriri';
import React, { useState } from 'react';
import {HSDoseRound, HSDrug, HSPatchObservation, SyringeDriverActivityKind} from 'server-openapi';
import styled from 'styled-components';
import { useStore } from '../../../../core/storage/hooks/UseStore';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { Button } from '../../../../kit/Button';
import { Layout } from '../../../../kit/Layout';
import { Text } from '../../../../kit/Text';
import { useSyncCenter } from '../../../../syncstream/SyncCenterProvider';
import { MedisphereSyringeDriverActivity } from '../../../../syncstream/SyncSyringeDriverActivity';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import { ScheduledActivity } from '../../../../syncstream/utils/RoundUtils';
import {MedicationInformationProps} from './MedicationInformationBox';
import { PatchStatus } from '../MedicationListsTabbedRouter/TabLists/PatchesMedicationList';
import { PatchAdministerDialog } from '../patches/PatchAdministerDialog';
import { PatchUtils } from '../patches/PatchUtils';
import { SyringeDriverActivityDialog } from '../syringeDrivers/SyringeDriverActivityForm';
import { toasts } from '../../../../kit/Toasts/Toaster';
import { SyringeDriverAdministerDialog } from '../syringeDrivers/SyringeDriverAdministrationForm';

interface Props extends MedicationInformationProps {
  drug: HSDrug;
  isLate: boolean;
  hasBeenDosed: boolean;
  canReadminister: boolean;
}

//eslint-disable-next-line max-lines-per-function
export function ScheduledActivityItems(props: Props) {
  const scheduledInfo = props.scheduledActivityInformation;

  if (!scheduledInfo) {
    return null;
  }
  const services = useSyncCenter();
  const syringeDriverActivityStore = useStore(services.syringeDriverActivity.store).store;
  const patchObservationStore = useStore(services.patchObservations.store).store;
  const packedDaysStore = useStore(services.packedPatientDays.store).store;
  const facilityGroupConfigurationStore = useStore(services.facilityGroupConfigurations.store).store;
  const sightHours = facilityGroupConfigurationStore.get(props.facilityGroupId.toString())?.patchSightHour;
  const administeredActivity = isSyringeDriverActivity(scheduledInfo.startActivity)
    ? itiriri(syringeDriverActivityStore.values()).filter(
        (activity) =>
          isSyringeDriverActivity(scheduledInfo.startActivity) &&
          ((activity.administeredDrugClinicalSystemId ?? false) ===
            scheduledInfo.startActivity.administeredDrugClinicalSystemId ||
            (activity.administeredDrugId ?? false) === scheduledInfo?.startActivity.administeredDrugId),
      )
    : itiriri(patchObservationStore.values()).filter(
        (activity) => PatchUtils.getPatchOperationData(activity)?.packedMedicationId === props.packedMedication?.hsId,
      );
  const apiUtils = useApiUtils();

  function getSightTimesForDay(day: Date) {
    return sightHours?.map((hour) => DateUtils.getEndDateFromOpenApiDurationAndStartDate(hour, startOfDay(day))) ?? [];
  }

  //eslint-disable-next-line sonarjs/cognitive-complexity
  function getAllDriverActivity(): ScheduledActivity[] {
    if (!isSyringeDriverActivity(scheduledInfo!.startActivity)) {
      return [];
    }
    const administeredDrug = scheduledInfo!.activityRound.administeredDoses
      ?.flatMap((dose) => dose.administeredDrugs)
      .find(
        (drug) =>
          isSyringeDriverActivity(scheduledInfo!.startActivity) &&
          ((drug?.clinicalSystemId ?? false) === scheduledInfo!.startActivity.administeredDrugClinicalSystemId ||
            (drug?.hsId ?? false) === scheduledInfo!.startActivity.administeredDrugId),
      );

    if (!administeredDrug) {
      return [];
    }

    const scheduledActivity = apiUtils.rounds.generateScheduledActivityForSyringeDriver(administeredDrug);
    return [
      ...administeredActivity
        .map((activity) => ({
          time: isSyringeDriverActivity(activity)
            ? DateUtils.toDate(activity.scheduledAt ?? activity.createdAt!)
            : new Date(),
          administeredDrugId: scheduledInfo!.selectedActivity.administeredDrugId,
          administeredDrugClinicalSystemId: scheduledInfo!.selectedActivity.administeredDrugClinicalSystemId,
          kind: isSyringeDriverActivity(activity) ? activity.kind! : SyringeDriverActivityKind.Observation,
          syringeActivity: activity,
        }))
        .toArray()
        .filter(
          (activity) =>
            !scheduledActivity.some(
              (item) => item.syringeActivity?.clinicalSystemId === activity.syringeActivity.clinicalSystemId,
            ),
        ),
      ...scheduledActivity,
    ].sort((a, b) => DateUtils.compareDatesDescending(a.time, b.time, true));
  }

  //eslint-disable-next-line sonarjs/cognitive-complexity
  function getAllPatchActivity(): ScheduledActivity[] {
    if (isSyringeDriverActivity(scheduledInfo!.startActivity)) {
      return [];
    }

    const packedMedication = itiriri(packedDaysStore.values())
      .flat((day) => day.packedMedications ?? [])
      .find((med) => med.hsId === PatchUtils.getPatchOperationData(scheduledInfo!.startActivity!)?.packedMedicationId);

    if (!packedMedication) {
      return [];
    }
    const scheduledActivity = apiUtils.rounds.generateScheduledActivityForPatch(packedMedication);

    return [
      ...administeredActivity
        .map((activity) => {
          const opData = !isSyringeDriverActivity(activity) ? PatchUtils.getPatchOperationData(activity) : undefined;

          return {
            time: activity.createdAt ? DateUtils.toDate(activity.createdAt) : new Date(),
            packedMedicationId: scheduledInfo!.selectedActivity.packedMedicationId,
            kind: !isSyringeDriverActivity(activity) ? opData?.patchStatus ?? PatchStatus.Sighted : PatchStatus.Sighted,
            patchActivity: activity,
          };
        })
        .toArray()
        .filter(
          (activity) =>
            !scheduledActivity.some(
              (item) => item.patchActivity?.clinicalSystemId === activity.patchActivity.clinicalSystemId,
            ),
        ),
      ...scheduledActivity,
    ].sort((a, b) => DateUtils.compareDatesDescending(a.time, b.time, true));
  }

  const now = new Date();

  const allActivities = isSyringeDriverActivity(scheduledInfo.startActivity)
    ? getAllDriverActivity()
    : getAllPatchActivity();

  // Get all the activity for the relevant type (patch or syringe driver). Or if applicable, only include the selected activity.
  const activities: ScheduledActivity[] = scheduledInfo.displayAllActivity
    ? allActivities
    : [scheduledInfo.selectedActivity!];

  const pastActivity = activities?.filter((activity) => activity.time < now).sort((a, b) => a.time.valueOf() - b.time.valueOf());
  const upcomingActivity = activities?.filter((activity) => activity.time >= now);

  function CheckIfCanBeSighted(activity: ScheduledActivity) {
    // Check if syringe driver
    if (Object.values(SyringeDriverActivityKind).some((kind) => kind === activity.kind)) {
      return true;
    }

    if (!getSightTimesForDay(activity.time).some((time) => time.valueOf() === activity.time.valueOf())) {
      return false;
    }

    let currentTime = props.currentRound?.createdAt ? DateUtils.toDate(props.currentRound.createdAt) : new Date();
    const roundWindow = apiUtils.rounds.getRoundWindow(currentTime, props.facilityGroupId);
    if (activity.time.valueOf() < DateUtils.toDate(roundWindow.start).valueOf()) {
      return false;
    }
    if (activity.time.valueOf() > DateUtils.toDate(roundWindow.end).valueOf()) {
      return false;
    }
    return true;
  }
  function CheckIfCanBeSightedLate(activity: ScheduledActivity)
  {
    if (!props.canReadminister) {
      // Not allowed to readminister.
      return false;
    }
    return (activity.time.valueOf() >= startOfDay(new Date()).valueOf());
  }
  function isLate(activity: ScheduledActivity): boolean {
    let currentTime = props.currentRound?.createdAt ? DateUtils.toDate(props.currentRound.createdAt) : new Date();
    const roundWindow = apiUtils.rounds.getRoundWindow(currentTime, props.facilityGroupId);
    // So it is either one that has been missed or a future dose.
    // If it is an activity for today, and is before the current time, then it must be one that can be dosed late.
    return (activity.time.valueOf() < DateUtils.toDate(roundWindow.start).valueOf());
  }

  const isAlreadyFinished = allActivities.some(
    (act) =>
      (act.patchActivity && act.kind === PatchStatus.Removed) ||
      (act.syringeActivity &&
        (act.kind === SyringeDriverActivityKind.Stop || act.kind === SyringeDriverActivityKind.Cease)),
  );
  function updatedProps(original: Props, activity: ScheduledActivity): Props {
    return {...original, isLate: isLate(activity)};
  }


  return (
    <ScheduledItemsBox>
      <Layout gap={0.8}>
        <Text weight={'bold'}>Activity</Text>
        {pastActivity!.map((activity, index) => (
          <SyringeDriverActivityCard
            {...updatedProps(props, activity)}
            scheduledActivity={activity}
            activityRound={scheduledInfo.activityRound}
            startActivity={scheduledInfo.startActivity}
            canBeSighted={!isAlreadyFinished && CheckIfCanBeSighted(activity) && index === pastActivity?.length - 1}
            canBeSightedLate={!isAlreadyFinished && CheckIfCanBeSightedLate(activity) && index === pastActivity?.length - 1}
            key={index}
          />
        ))}
        {!isAlreadyFinished &&
          scheduledInfo.displayAllActivity &&
          isSyringeDriverActivity(scheduledInfo.startActivity) && (
            <UnscheduledObservationCard
              {...props}
              activityRound={scheduledInfo.activityRound}
              startActivity={scheduledInfo.startActivity}
            />
          )}
        {upcomingActivity!.map((activity, index) => (
          <SyringeDriverActivityCard
            {...updatedProps(props, activity)}
            scheduledActivity={activity}
            key={index}
            activityRound={scheduledInfo.activityRound}
            startActivity={scheduledInfo.startActivity}
            canBeSighted={!isAlreadyFinished && CheckIfCanBeSighted(activity)}
            canBeSightedLate={false}
          />
        ))}
      </Layout>
    </ScheduledItemsBox>
  );
}

interface ActivityProps extends Props {
  activityRound: HSDoseRound;
  startActivity: MedisphereSyringeDriverActivity | HSPatchObservation;
}
interface ScheduledActivityProps extends ActivityProps {
  scheduledActivity: ScheduledActivity;
  canBeSighted: boolean;
  canBeSightedLate: boolean;
  isLate: boolean;
  hasBeenDosed: boolean;
}

// eslint-disable-next-line sonarjs/cognitive-complexity, max-lines-per-function
function SyringeDriverActivityCard(props: ScheduledActivityProps) {
  const { time, kind } = props.scheduledActivity;
  const activity = props.scheduledActivity.syringeActivity ?? props.scheduledActivity.patchActivity;
  const apiUtils = useApiUtils();
  const [open, setOpen] = useState(false);
  if (
    (activity && !activity?.createdAt) ||
    (!activity &&
      ![
        SyringeDriverActivityKind.Observation.valueOf(),
        SyringeDriverActivityKind.Stop.valueOf(),
        PatchStatus.Sighted.valueOf(),
        PatchStatus.Removed.valueOf(),
      ].includes(kind))
  ) {
    return null;
  }
  const createdAtString = activity?.createdAt ? DateUtils.dateStringTo24HourTimeString(activity?.createdAt) : '';
  const userString = activity
    ? apiUtils.users.getUserFullNameAndRoleFromSubjectId(activity.lastUpdatedBySubjectId)
    : '';

  function openActivityDialog() {
    if (!props.administrationPermissions.canOperateSyringeDriver && !activity) {
      toasts.error('You do not have permission to operate syringe driver');
    } else {
      setOpen(true);
    }
  }

  //eslint-disable-next-line sonarjs/cognitive-complexity
  function getDescription() {
    switch (kind) {
      case SyringeDriverActivityKind.Observation:
        return activity?.createdAt ? `Observed at ${createdAtString} by ${userString}.` : `No observation added.`;
      case SyringeDriverActivityKind.Cease:
        return `Syringe driver ceased by ${userString}`;
      case SyringeDriverActivityKind.Stop:
        return activity
          ? `Syringe driver stopped at ${createdAtString} by ${userString}`
          : `Syringe driver course due for completion.`;
      case SyringeDriverActivityKind.Pause:
        return `Syringe driver paused by ${userString}`;
      case SyringeDriverActivityKind.Restart:
        return `Syringe driver restarted by ${userString}`;
      case SyringeDriverActivityKind.Start:
        return `Syringe driver started by ${userString}`;
      case PatchStatus.Sighted:
        return activity?.createdAt ? `Sighted at ${createdAtString} by ${userString}.` : `No sighting added.`;
      case PatchStatus.FallenOff:
        return `Patch recorded Fallen Off at ${createdAtString} by ${userString}`;
      case PatchStatus.Removed:
        return activity ? `Patch removed at ${createdAtString} by ${userString}` : `Patch due for removal.`;
      case PatchStatus.Reapplied:
        return `Patch reapplied at ${createdAtString} by ${userString}`;
      case PatchStatus.Applied:
        return `Patch applied at ${createdAtString} by ${userString}`;
    }
  }

  return (
    <ActivityBox>
      <Layout>
        <Text weight={'bold'}>
          {DateUtils.dateTo24HourTimeString(time)} ON {format(time, 'd MMM y')}
        </Text>
        <Text>{getDescription()}</Text>
      </Layout>
      {Object.values(SyringeDriverActivityKind).some((kind) => kind === props.scheduledActivity.kind) ? (
        <>
          {!activity && !props.canBeSighted ? (
                <Text>MISSED</Text>
          ) : kind === SyringeDriverActivityKind.Observation ? (
            <ActionButton onClick={openActivityDialog}>{!activity ? 'OBSERVE' : 'VIEW'}</ActionButton>
          ) : (
            <ActionButton onClick={openActivityDialog}>{!activity ? 'STOP' : 'VIEW'}</ActionButton>
          )}
          {kind === SyringeDriverActivityKind.Start ? (
            <SyringeDriverAdministerDialog {...props} open={open} setOpen={setOpen} />
          ) : (
            <SyringeDriverActivityDialog
              {...props}
              setOpen={setOpen}
              open={open}
              activity={props.scheduledActivity}
              activityKind={kind as SyringeDriverActivityKind}
            />
          )}
        </>
      ) : (
        <>
          {kind === PatchStatus.Sighted && !props.canBeSighted && (!props.canBeSightedLate || !props.isLate) ? (
            <Text>{activity ? 'SIGHTED' : time.valueOf() >= new Date().valueOf() ? 'UPCOMING SIGHT' : 'MISSED'}</Text>
          ) : activity ? (
            <Text>{kind.toUpperCase()}</Text>
          ) : (
            <PatchAdministerDialog
              {...props}
              isSighting={kind === PatchStatus.Sighted}
              patchStatus={kind as PatchStatus}
              observationTime={time}
              hasBeenDosed={false}
            />
          )}
        </>
      )}
    </ActivityBox>
  );
}

function UnscheduledObservationCard(props: ActivityProps) {
  const [open, setOpen] = useState(false);

  function openActivityDialog() {
    if (!props.administrationPermissions.canOperateSyringeDriver) {
      toasts.error('You do not have permission to operate syringe driver');
    } else {
      setOpen(true);
    }
  }

  return (
    <ActivityBox style={{ background: 'white', borderStyle: 'none' }}>
      <Layout>
        <Text weight={'bold'}>
          {DateUtils.dateTo24HourTimeString(new Date())} ON {format(new Date(), 'd MMM y')}
        </Text>
        <Text>Add an observation now.</Text>
      </Layout>
      <ActionButton onClick={openActivityDialog}>OBSERVE NOW</ActionButton>
      <SyringeDriverActivityDialog
        {...props}
        setOpen={setOpen}
        open={open}
        activityKind={SyringeDriverActivityKind.Observation}
      />
    </ActivityBox>
  );
}

export function isSyringeDriverActivity(
  activity: MedisphereSyringeDriverActivity | HSPatchObservation,
): activity is MedisphereSyringeDriverActivity {
  return 'rateOfInfusion' in activity;
}

const ActivityBox = styled.div`
  border-style: solid;
  border-color: #c5bacb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
`;

const ActionButton = styled(Button)`
  min-width: 10rem;
`;

const ScheduledItemsBox = styled.div`
  background-color: rgba(246, 233, 254, 1);
  padding: 1.25rem;

  flex-direction: column;
  display: flex;
  width: 100%;
`;
