import itiriri from 'itiriri';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { IconBack } from '../../kit/Icons/Back';
import { Layout } from '../../kit/Layout';
import { Text } from '../../kit/Text';
import { useSyncCenter } from '../../syncstream/SyncCenterProvider';
import { useStore } from '../../core/storage/hooks/UseStore';
import { DashboardNavbar } from './DashboardPage/components/DashboardNavbar';
import { DashboardResidentsGrid } from './DashboardPage/components/DashboardResidentsGrid';
import { SearchInput } from '../../kit/Forms/SearchInput';
import { Select } from '../../kit/Forms/Select';
import { Grid } from '../../kit/Grid';
import { HSFacility, HSFacilityGroup, HSPatient } from 'server-openapi';
import { useApiUtils } from '../../syncstream/utils/hooks/useApiUtils';
import {useGetScheduleFromParameters} from "../../syncstream/utils/hooks/GetSchedule";
import {RoundScheduleItem} from "../../syncstream/utils/RoundUtils";
import {endOfDay, startOfDay} from "date-fns";
import {DateUtils, Interval} from "../../core/utils/dateUtils";
import Badge from "../../kit/Badge";
interface IParams {
  facilityGroupId: string;
  administrationType: string;
}

export function OffsiteListPage() {
  const getScheduleFromParameters = useGetScheduleFromParameters();

  const patientUtils = useApiUtils().patients;
  // Get the currently selected facility from the URL parameters.
  const { facilityGroupId } = useParams<IParams>();

  const services = useSyncCenter();

  const facilityGroupsStore = useStore(services.facilityGroups.store).store;
  const facilitiesStore = useStore(services.facilities.store).store;
  const facilityGroup = facilityGroupsStore.get(facilityGroupId?.toString());

  const facilities = itiriri(facilitiesStore.values())
    .filter((f) => f.facilityGroupId === parseInt(facilityGroupId))
    .toArray();

  const patients = patientUtils
    .getActivePatients()
    .filter((p) => facilities.map((f) => f.hsId).includes(p.facility))
    .toArray();

  // Filter patients by search query
  const filteredPatients = (filterWing?: HSFacility, searchResidentQuery?: string) => {
    return patients.filter((hsPatient) => {
      if (filterWing && filterWing.hsId !== hsPatient.facility) {
        return false;
      }

      const residentName = patientUtils.getQueryPatientString(hsPatient);

      if (searchResidentQuery && !residentName.includes(searchResidentQuery.toUpperCase())) {
        return false;
      }
      return hsPatient.suspended;
    });
  };
  const windowForToday: Interval = {
    start: DateUtils.fromDate(startOfDay(new Date())),
    end: DateUtils.fromDate(endOfDay(new Date()))
  };

  return (
    <OffsiteListLayout
      facilityGroupId={facilityGroupId}
      facilityGroup={facilityGroup}
      facilities={facilities}
      patients={filteredPatients}
      scheduleItems={getScheduleFromParameters(windowForToday, facilities.map(x => x.hsId!))}
    />
  );
}

function OffsiteListLayout(props: {
  facilityGroupId: string;
  facilityGroup?: HSFacilityGroup;
  facilities: HSFacility[];
  patients: (wing?: HSFacility, query?: string) => HSPatient[];
  scheduleItems: RoundScheduleItem[];
}) {
  const history = useHistory();
  const [filterWing, setFilterWing] = useState<HSFacility | undefined>(undefined);
  const [searchResidentQuery, setSearchResidentQuery] = useState<string>('');

  const facilityOptions = props.facilities.map((facility) => {
    return { label: facility.name ?? 'Unknown', value: facility };
  });

  const patients = props.patients(filterWing, searchResidentQuery);
  const patientIds = patients.map(x => x.hsId);
  const apiUtils = useApiUtils();
  const wingLabel = apiUtils.facilityGroups.getFacilityUILabel(parseInt(props.facilityGroupId));
  const residentLabel = apiUtils.facilityGroups.getResidentUILabel(parseInt(props.facilityGroupId));

  return (
    <Layout>
      <DashboardNavbar facilityGroup={props.facilityGroup} />
      <Container gap={1}>
        <Layout horizontal gap={1.5}>
          <IconBack onClick={() => history.push(`/facility-group/${props.facilityGroupId}`)} />
          <Layout horizontal>
            <Text weight={'bold'} size="large" style={{ color: 'white' }}>
              Off-site
            </Text>
            <Badge text={patients.length} type={'info'}></Badge>
          </Layout>
        </Layout>
        <Grid cols={2} colsMobile={1} gap={1}>
          <SearchInput
            onChange={(_, v) => setSearchResidentQuery(v)}
            value={searchResidentQuery}
            name="search"
            placeholder={`Search ${residentLabel}`}
            fullWidth
          />
          <Select
            fullWidth
            placeholder={`All ${wingLabel}`}
            name="wing"
            options={facilityOptions}
            value={filterWing}
            onChange={(_, value) => setFilterWing(value)}
          />
        </Grid>
        <DashboardResidentsGrid
          residentGridArray={patients.map((patient) => {
            return {
              patient,
              facilityWing: props.facilities.find((f) => f.hsId === patient.facility)?.name ?? 'unknown wing',
              hasMedChanges: apiUtils.patients.fetchChangedMedicationDetailsFromPatient(patient).medicationHasChanged,
              scheduleItems: props.scheduleItems.filter(x => x.patient.hsId === patient.hsId)
            };
          })}
        />
      </Container>
    </Layout>
  );
}

const Container = styled(Layout)`
  margin: 1.5rem;
`;
