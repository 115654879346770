import { NimAvailableDrugDto, PinUserDto } from 'server-openapi';
import { PersistentQueue } from '../core/queue/PersistentQueue';
import { Entry, IStorage } from '../core/storage/Contract';
import { SyncStreamAPI } from './api';
import { IFacilityGroupSyncService, ISyncService } from './SyncCenter';
import { MemoryCache } from '../core/storage/MemoryCache';

interface Operation {
  type: void;
  payload: NimAvailableDrugDto;
}

export class SyncNimAvailableDrugs implements IFacilityGroupSyncService {
  get name(): string {
    return 'SyncNimAvailableDrugs';
  }

  constructor(
    private api: SyncStreamAPI,
    private storage: MemoryCache<NimAvailableDrugDto>,
    private queue: PersistentQueue<Operation>,
  ) {}

  async load(facilityGroupId: string): Promise<void> {
    await this.storage.reset(async (p) => {
      return true;
    });
  }


  async syncDown(facilityGroupId?: string) {
    // TODO: storage locking so that we can be sure the UI
    // didn't accidently change a resource in between the API
    // giving us fresh data and updating the storage backend.
    if (!facilityGroupId) {
      return;
    }

    const nimAvailableDrugs = await this.api.nimAvailableDrugs.nimAvailableDrugList(parseInt(facilityGroupId));

    await this.storage.setMany(
      nimAvailableDrugs.data.map((drug) => ({
        key: this.storage.get_key!(drug),
        value: drug,
      })),
    );
  }

  async syncUp() {
    // Do nothing
  }

  async clear() {
    await this.storage.clear();
    await this.queue.clear();
  }

  async hasQueuedData() {
    return (await this.queue.length()) > 0;
  }
  isAllowed(canUserAccessMedication: boolean): boolean {
    // Only if you can view a round.
    return canUserAccessMedication;
  }

  setEncryptionVersion(version: number): void {
    this.storage.compressOnSave = (version > 1);
  }
  async rewrite(): Promise<void> {
    const entries: Entry<NimAvailableDrugDto>[] = [...(await this.storage.all())].map((keyValueArray) => {
      return {
        key: keyValueArray[0],
        value: keyValueArray[1]
      };
    });
    return await this.storage.setMany(entries);
  }
}
