import React from 'react';
import styled, { css } from 'styled-components';
import { Intent } from '../Theme/Theme';
import { formInput, FormInput } from './FormInput';

const LabelContainer = styled.label`
  width: fit-content;
`;

const TickCheckbox = styled.input<IRadioButtonProps>`
  vertical-align: middle;
  transform: scale(1.5);
`;

const LabelText = styled.span`
  margin-left: 8px;
`;

const InputContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding: 5px;
  width: 43px;
  height: 43px;
  border-radius: 50%;
  border-width: 1px;
  border-color: white;
  border-style: solid;
  transition: all 150ms;
`;

const HiddenCheckbox = styled.input`
  height: 0;
  width: 0;
  overflow: hidden;
  position: absolute;
`;

const StyledRadioButton = styled.div<IRadioButtonProps>`
  vertical-align: middle;
  width: 100%;
  height: 100%;
  border-radius: 50%;

  ${HiddenCheckbox}:checked + & {
    ${(props) => {
      if (props.intent) {
        return css`
          background: ${props.theme.button[props.intent].bg};
        `;
      }

      return css`
        background: white;
      `;
    }}
  }
`;

interface IRadioButtonProps extends FormInput<boolean> {
  label?: string;
  intent?: Intent;
  roundInput?: boolean;
}

export const Checkbox = formInput(function (props: IRadioButtonProps) {
  return (
    <LabelContainer>
      {props.roundInput ? (
        <InputContainer>
          <HiddenCheckbox
            disabled={props.disabled}
            type="checkbox"
            name={props.name}
            onChange={(event) => {
              props.onChange?.(event, event.target.checked);
            }}
            checked={props.value ?? false}
            data-testid="radio-button"
          />
          <StyledRadioButton name={props.name} intent={props.intent} />
        </InputContainer>
      ) : (
        <TickCheckbox
          disabled={props.disabled}
          type="checkbox"
          name={props.name}
          onChange={(event) => {
            if (event !== undefined && event.target instanceof HTMLInputElement) {
              props.onChange?.(event, event.target.checked);
            }
          }}
          checked={props.value ?? false}
          data-testid="radio-button"
        />
      )}

      <LabelText>{props.label}</LabelText>
    </LabelContainer>
  );
});
