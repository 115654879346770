import * as React from 'react';
import styled from 'styled-components';
import { colors } from '../../../../kit/Theme/Theme';

interface IProps {
  residentsSeen: number;
  totalResidentsInRound: number;
}

const DonutContainer = styled.div`
  padding-right: 10px;
  .donutchart-track {
    fill: transparent;
    stroke: ${colors.bg_gray_200};
    stroke-width: 26;
  }
  .donutchart-indicator {
    fill: transparent;
    stroke: ${colors.bg_green_500};
    stroke-dasharray: 0 10000;
    transition: stroke-dasharray 0.3s ease;
  }

  .donutchart {
    margin: 0 auto;
    border-radius: 50%;
    display: block;
  }

  .donutchart-text {
    top: 4px;
    font-weight: ${(p) => p.theme.typography.h1.fontWeight};
  }

  .donutchart-text-val {
    fill: ${colors.bg_gray_200};
    padding-top: 4px;
    font-size: 16px;
  }
`;

export function RoundProgressIndicator(props: IProps): JSX.Element {
  const value = props.residentsSeen;
  const strokeWidth = 5;
  const size = 50;
  const halfsize = size * 0.5;
  const radius = halfsize - strokeWidth * 0.5;
  const circumference = 2 * Math.PI * radius;
  const strokeval = (value * circumference) / props.totalResidentsInRound;
  const dashval = strokeval + ' ' + circumference;
  const textTopPadding = 6;

  const trackstyle = { strokeWidth };
  const indicatorstyle = { strokeWidth, strokeDasharray: dashval };
  const rotateval = 'rotate(-90 ' + halfsize + ',' + halfsize + ')';

  return (
    <DonutContainer>
      <svg width={size} height={size}>
        <circle
          r={radius}
          cx={halfsize}
          cy={halfsize}
          transform={rotateval}
          style={trackstyle}
          className="donutchart-track"
        />
        <circle
          r={radius}
          cx={halfsize}
          cy={halfsize}
          transform={rotateval}
          style={indicatorstyle}
          className="donutchart-indicator"
        />
        <text className="donutchart-text" x={halfsize} y={halfsize + textTopPadding} style={{ textAnchor: 'middle' }}>
          <tspan className="donutchart-text-val">{value}</tspan>
        </text>
      </svg>
    </DonutContainer>
  );
}
