/* eslint-disable max-lines */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid } from '../../kit/Grid';
import { MdMenu, MdClose } from 'react-icons/md';
import { breakpoints, colors, Intent } from '../../kit/Theme/Theme';
import { Link } from 'react-router-dom';
import { logout } from '../../core/authn/Client';
import { HSFacility, HSDoseRound, HSFacilityGroup } from 'server-openapi';
import { useCurrentUser } from '../../core/authn/UserProvider';
import { useSyncCenter } from '../../syncstream/SyncCenterProvider';
import { useStore } from '../../core/storage/hooks/UseStore';
import * as datefns from 'date-fns';
import { DateUtils } from '../../core/utils/dateUtils';
import { Button } from '../../kit/Button';
import { Text } from '../../kit/Text';
import { useHistory } from 'react-router';
import { assertNotUndefined } from '../../core/utils/assertionUtils';
import { toasts } from '../../kit/Toasts/Toaster';
import { useApiUtils } from '../../syncstream/utils/hooks/useApiUtils';
import { Dialog } from '../../kit/Dialog';
import { PinUpdateForm } from '../PinComponents/PinUpdateForm';
import { Layout } from '../../kit/Layout';
import SvgTick from '../../kit/Icons/Tick';
import { LogoutWarningDialog } from '../../core/authn/LogoutWarningDialog';
import { useQueueDataChecker } from '../../syncstream/utils/hooks/useQueueDataChecker';
import SvgWarning from '../../kit/Icons/Warning';
import { useHealthCheck } from '../../core/healthcheck/HealthCheckProvider';
import { useRoundSchedule } from '../../pages/Rounds/services/RoundScheduleProvider';
import { appconfig } from '../../appconfig';
import {useGroupPermissions} from "../../core/authz/PermissionsProvider";
import { MoonLoader } from 'react-spinners';
import { setSyncRequired, useAppState } from '../../context/AppStateProvider';

const MenuContainer = styled(Grid)`
  padding: 0.5em 1em;
  border: solid 2px ${(props) => props.theme.button.secondary.bg};
  cursor: pointer;
  background: ${(props) => props.theme.backgrounds.default.fg};
  font-weight: bold;
  color: ${(p) => p.theme.typography.h2.color};
`;

const CloseContainer = styled.div`
  padding: 0.3em;
  border: solid 2px ${(props) => props.theme.typography.h1.color};
  color: ${(props) => props.theme.typography.h1.color};
  font-size: 1.5em;
  cursor: pointer;
  justify-content: center;
  display: inline-flex;
`;

const SlideOutMenu = styled.div<{ isVisible: boolean }>`
  p,
  h1,
  h2,
  h3 {
    color: ${(p) => (p.theme.appbar.fg)};
  }
  a {
    text-decoration: none;
  }
  width: 375px;
  transform: ${(p) => (p.isVisible ? 'translateX(0px)' : `translateX(375px)`)};
  ${breakpoints.mobileDown} {
    width: 100%;
    transform: ${(p) => (p.isVisible ? 'translateX(0px)' : `translateX(100%)`)};
  }
  position: fixed;
  right: 0px;
  top: 0px;
  height: 100%;
  background: ${(props) => props.theme.button.secondary.bg};
  transition: 0.3s all ease-in-out;
  padding: 2em;
  overflow-y: scroll;
  box-shadow: -5px 0px 18px #0000003b;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SyncCompleteCircle = styled.div`
  font-size: 1.5em;
  padding: 0.4em;
  border: solid 2px ${(props) => props.theme.typography.h1.color};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999999rem;
`;

const VersionDisplay = styled.div`
  width: 100%;
  text-align: right;
  font-weight: 100;
  font-style: italic;
`;

const LogoutText = styled(Text)`
  cursor: pointer;
  color: ${(props) => props.theme.backgrounds.default.bg};
  &:hover {
    color: ${() => colors.bg_purple_700};
  }
`;

interface Props {
  facilityGroup: HSFacilityGroup;
}

// eslint-disable-next-line max-lines-per-function
export function NavigationBarMenu(props: Props) {
  const services = useSyncCenter();
  const appState = useAppState();
  const facilityStore = useStore(services.facilities.store).store;
  const history = useHistory();
  const [isVisible, setIsVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const roundUtils = useApiUtils().rounds;
  const activeRounds = roundUtils.getActiveRounds(
    new Date(),
    roundUtils.getIdsOfFacilitiesInFacilityGroup(props.facilityGroup?.hsId),
  );
  const user = useCurrentUser();
  const userUtils = useApiUtils().users;
  const roundScheduleContext = useRoundSchedule();
  const facilityGroupId = props.facilityGroup.hsId!;
  const groupPermissions = useGroupPermissions();

  const canJoinRound = groupPermissions.canUndertakeMedicationRound;
  const canCreateRound = groupPermissions.canCreateRounds;

  const [pinDialogOpen, setPinDialogOpen] = useState(false);

  const hasQueuedData = useQueueDataChecker();
  const isServerHealthy = useHealthCheck().isHealthy;
  const canViewResupply = groupPermissions.canViewResupplyOrder;

  async function joinRound(round: HSDoseRound, resumeRound: boolean) {
    if (!canJoinRound) {
      toasts.error('You do not have permission to join a round.');
      return;
    }
    if (!resumeRound) {
      if (!canCreateRound) {
        toasts.error('You do not have permission to create round segment.');
        return;
      }

      await services.rounds.service.enqueue.createRoundSegment(
        roundUtils.createRoundSegmentOp(facilityGroupId, round.clinicalSystemId!, user.profile.sub),
      );
    }
    roundScheduleContext.set(round);
    history.push(`/facility-group/${facilityGroupId}/rounds/${round.clinicalSystemId!}`);
  }

  async function onMenuClick(facilityGroupId: number)
  {
    setIsVisible(!isVisible);
    // setSyncRequired(true);
    // if (await services.syncCenter.hasQueuedData()) {
    //   setSyncRequired(true);
    // }
  }

  const userRoundIds = roundUtils
    .activeUserRounds(user.profile.sub, props.facilityGroup.hsId!)
    .flatMap((r) => (!!r.clinicalSystemId ? [r.clinicalSystemId] : []));

  return (
    <div>
      <MenuContainer gap={0} colsTemplate="0.8fr 1fr" onClick={() => onMenuClick(facilityGroupId)}>
        <MdMenu />
        Menu
      </MenuContainer>
      <SlideOutMenu isVisible={isVisible}>
        <Grid gap={2} cols={1}>
          <Layout horizontal align={'center'} justify={'space-between'}>
            <OnlineStatus isServerHealthy={isServerHealthy} hasQueuedData={hasQueuedData ?? false} />
            <CloseContainer onClick={() => setIsVisible(!isVisible)}>
              <MdClose />
            </CloseContainer>
          </Layout>
          {props.facilityGroup && props.facilityGroup.name && (
            <FacilityMenuItems
              activeRounds={activeRounds}
              facilityGroup={props.facilityGroup}
              facilityStore={facilityStore}
              userRoundIds={userRoundIds}
              joinRound={(round: HSDoseRound, resumeRound: boolean) => joinRound(round, resumeRound)}
              canViewResupply={canViewResupply ?? false}
            />
          )}
          <Grid gap={0.75} cols={1}>
            {user.profile.sub && (
              <h3 title={user.profile.sub}>LOGGED IN AS {userUtils.getUserFullNameAndRoleFromSubjectId(user.profile.sub)}</h3>
            )}
            <>
              <Link to="#" onClick={() => setPinDialogOpen(true)}>
                Set Your PIN
              </Link>
              <Dialog lazy secondary size="full" open={pinDialogOpen} onRequestClose={() => setPinDialogOpen(false)}>
                <PinUpdateForm onSubmit={() => setPinDialogOpen(false)} />
              </Dialog>
            </>
            <Link
              onClick={() => !isServerHealthy && toasts.error("Can't switch facilities while offline")}
              to={isServerHealthy ? '/facility-group/switch' : '#'}
            >
              Switch Facility
            </Link>
            <Link to={`/facility-group/${props.facilityGroup.hsId}/help`}>Help</Link>
            <Link to="/support">Support</Link>
            <Link to={`/facility-group/${props.facilityGroup.hsId}/diagnosis-dashboard`}>System Diagnostic Dashboard</Link>
            <LogoutText
              onClick={async () => {
                if (hasQueuedData) {
                  setOpen(true);
                } else {
                  await logout();
                }
              }}
            >
              Log out
            </LogoutText>
          </Grid>
        </Grid>
        <VersionDisplay>{appconfig.APP_VERSION}</VersionDisplay>
      </SlideOutMenu>
      <LogoutWarningDialog isOpen={open} setClosed={() => setOpen(false)} />
    </div>
  );
}

interface IFacilityMenuItems {
  activeRounds: HSDoseRound[];
  facilityGroup: HSFacility;
  facilityStore: ReadonlyMap<string, HSDoseRound>;
  canViewResupply: boolean;
  userRoundIds: string[];
  joinRound: (round: HSDoseRound, resumeRound: boolean) => Promise<void>;
}

function FacilityMenuItems(props: IFacilityMenuItems) {
  return (
    <>
      {assertNotUndefined(props.facilityGroup.name) && assertNotUndefined(props.facilityGroup.hsId) && (
        <>
          <h3>ACTIVE ROUNDS</h3>
          <ShowActiveRounds
            activeRounds={props.activeRounds}
            facilityStore={props.facilityStore}
            userRoundIds={props.userRoundIds}
            joinRound={props.joinRound}
          />
          <Grid gap={0.75} cols={1}>
            <h3>{props.facilityGroup.name}</h3>
            <Link to={`/facility-group/${props.facilityGroup.hsId}/management/facility-metrics`}>Facility Metrics</Link>
            <p style={{ color: 'black' }}>Controlled Drugs</p>
            <Link to={`/facility-group/${props.facilityGroup.hsId}/management/nurse-notes`}>Nurse Notes</Link>
            {
              props.canViewResupply ?
                  <Link
                      to={`/facility-group/${props.facilityGroup.hsId}/management/orders`}
                      style={{ textDecoration: 'none' }}
                  >
                    Resupply History
                  </Link> :
                  <Text>Resupply History</Text>
            }
            <Link to={`/facility-group/${props.facilityGroup.hsId}/management/occupancy-status`}>Occupancy Status</Link>
            <Link to={`/facility-group/${props.facilityGroup.hsId}/management/reporting`}>Reporting</Link>
            <Link to={`/admin/facility-group/${props.facilityGroup.hsId}/settings`}>Facility Settings</Link>
          </Grid>
        </>
      )}
    </>
  );
}

interface IOnlineStatus {
  isServerHealthy: boolean;
  hasQueuedData: boolean;
}

function OnlineStatus(props: IOnlineStatus) {
  const initiateSync = () => {
    setSyncRequired(true);
  }
  return (
    <Layout horizontal gap={0.5} justify={'center'} align={'center'}>
      {!props.isServerHealthy ? (
        <SyncCompleteCircle>
          <SvgWarning />
        </SyncCompleteCircle>
      ) : props.hasQueuedData ? (
          <MoonLoader color={colors.bg_purple_800} size={37}></MoonLoader>
      ) : (
        <SyncCompleteCircle onClick={initiateSync}>
          <SvgTick />
        </SyncCompleteCircle>
      )}
      <Text style={{ color: colors.bg_purple_800 }} weight={'bold'}>
        {!props.isServerHealthy ? 'Offline' : props.hasQueuedData ? `Syncing...` : 'Synced'}
      </Text>
    </Layout>
  );
}

interface IShowActiveRounds {
  activeRounds: HSDoseRound[];
  facilityStore: ReadonlyMap<string, HSFacility>;
  userRoundIds: string[];
  joinRound: (round: HSDoseRound, resumeRound: boolean) => Promise<void>;
}

function ShowActiveRounds(props: IShowActiveRounds) {
  const roundUtils = useApiUtils().rounds;

  return (
    <>
      {props.activeRounds.map((r) => {
        if (r.clinicalSystemId) {
          const facilityNames = roundUtils.getFacilitiesInRound(r).map((f) => f.name);
          const roundName = facilityNames.length > 1 ? 'Multiple wards' : facilityNames[0];
          const roundId = r.clinicalSystemId;
          const resumeRound = props.userRoundIds.includes(roundId);
          return (
            <Grid colsTemplate="1fr 0.2fr" key={roundId}>
              <Text>
                {datefns.format(DateUtils.toDate(r.createdAt ?? ''), 'p')} round, {roundName}
              </Text>
              <Button
                intent={Intent.Info}
                onClick={async () => {
                  await props.joinRound(r, resumeRound);
                }}
                disabled={props.userRoundIds.length > 0 && !resumeRound}
              >
                {resumeRound ? 'Resume' : 'Join'}
              </Button>
            </Grid>
          );
        }
      })}
    </>
  );
}

