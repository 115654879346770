import { PinUserDto, HSFacility, HSPatient } from 'server-openapi';
import { Entry, IStorage } from '../core/storage/Contract';
import { SyncStreamAPI } from './api';
import { IFacilityGroupSyncService, ISyncService } from './SyncCenter';
import { PersistentQueue } from '../core/queue/PersistentQueue';
import { Logger } from '../core/logger/logger';
import { PinOnlineService } from '../core/mrs/Services';
import { MemoryCache } from '../core/storage/MemoryCache';
import { tr } from 'date-fns/locale';

export interface UpsertPinOp {
  type: 'upsert-pin';
  pinUser: PinUserDto;
  newPin: string;
}

export type PinUserOp = UpsertPinOp;

const logger = new Logger('PinUsers');

export class SyncPinUsers implements IFacilityGroupSyncService {
  get name(): string {
    return 'PinUsers';
  }

  constructor(
    private api: SyncStreamAPI,
    private storage: MemoryCache<PinUserDto>,
    private facilitiesStore: IStorage<HSFacility>,
    private queue: PersistentQueue<PinUserOp>,
  ) {}

  enqueue = {
    upsertPin: async (req: UpsertPinOp) => {
      await this.storage.set(req.pinUser.subjectId!, req.pinUser);
      await this.queue.unshift(req);
      return await this.storage.get(req.pinUser.subjectId!);
    },
  };

  async load(facilityGroupId: string): Promise<void> {
    await this.storage.reset(async (p) => {
      return true;
    });
  }

  async syncDown(facilityGroupId?: string) {
    // TODO: storage locking so that we can be sure the UI
    // didn't accidentally change a resource in between the API
    // giving us fresh data and updating the storage backend.
    let pinUsers = new Array<PinUserDto>();
    if (facilityGroupId) {
      pinUsers = await this.syncFacilityGroupDown(+facilityGroupId);
    }
    await this.storage.setMany(
      pinUsers.map((pinUser) => ({
        key: pinUser.subjectId!,
        value: pinUser,
      })),
    );
  }

  async syncFacilityGroupDown(facilityGroupId: number): Promise<PinUserDto[]> {
    const pinUsers = await this.api.users.userGetPinUsers(facilityGroupId);

    return pinUsers.data;
  }

  async syncUp() {
    for await (const delivery of this.queue.iterate()) {
      try {
        // eslint-disable-next-line sonarjs/no-small-switch
        switch (delivery.value.type) {
          case 'upsert-pin':
            await PinOnlineService.upsertPin({ pin: delivery.value.newPin });
            await delivery.complete();
        }
      } catch (error) {
        logger.error('Pin user failed', error);
        await delivery.failed();
      }
    }
  }

  async clear() {
    await this.storage.clear();
  }

  async hasQueuedData() {
    return (await this.queue.length()) > 0;
  }
  isAllowed(canUserAccessMedication: boolean): boolean {
    // Only if you can view a round.
    return canUserAccessMedication;
  }

  setEncryptionVersion(version: number): void {
    this.storage.compressOnSave = (version > 1);
  }
  async rewrite(): Promise<void> {
    const entries: Entry<PinUserDto>[] = [...(await this.storage.all())].map((keyValueArray) => {
      return {
        key: keyValueArray[0],
        value: keyValueArray[1]
      };
    });
    return await this.storage.setMany(entries);
  }
}
