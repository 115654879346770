import React, { useEffect, useRef, useState } from 'react';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';
import { Button } from '../Button';
import { Layout } from '../Layout';
import { Intent } from '../Theme/Theme';
import { FormInput, formInput } from './FormInput';

interface Props extends FormInput<File> {
  // The <input /> accept attribute.
  // Set to `undefined` to accept any file type.
  // Use the exported `FileAccept` object for common
  // accept attribute use-cases like images.
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept
  accept?: string[];
  label?: string;
  intent?: Intent;

  // a url to the existing file that this file
  // input may be updating.
  // this is only used for file preview.
  current?: string;
  fullWidth?: boolean;
}

export const FileInput = formInput(function (props: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.files?.length === 1) {
      props.onChange?.(event, event.currentTarget.files[0]);
    }
  };

  const preview = props.value ?? props.current;

  return (
    <Layout gap={0.5}>
      {/* preview image */}
      {preview && <PreviewImage file={preview} />}

      {/* select/change button */}
      <Button onClick={onClick} intent={props.intent} disabled={props.disabled} fullWidth={props.fullWidth}>
        {props.label ?? 'Select A File'}
      </Button>

      {/* hidden native file input */}
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
        onChange={(event) => {
          onChange(event);
          event.target.value = '';
        }}
        accept={props.accept?.join(',')}
      />
    </Layout>
  );
});

export const Accept = {
  Images: ['image/*'],
};

interface PreviewImageProps {
  file: File | string;
}

export function PreviewImage(props: PreviewImageProps) {
  const [preview, setPreview] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (typeof props.file === 'string') {
      setPreview(props.file);
    } else {
      const url = URL.createObjectURL(props.file);
      setPreview(url);
      return () => URL.revokeObjectURL(url);
    }
  }, [props.file]);

  const dialogTransition = useTransition(preview, {
    from: { height: 0, width: 0 },
    enter: { height: 250, width: 250 },
    leave: { height: 0, width: 0 },
    trail: 320,
    config: {
      mass: 1,
      tension: 500,
      friction: 40,
    },
  });

  return (
    <Layout gap={0} horizontal>
      {dialogTransition(
        (styles, item) =>
          item && (
            <AnimatedBox style={styles}>
              <StyledImg src={item} />
            </AnimatedBox>
          ),
      )}
    </Layout>
  );
}

const AnimatedBox = animated(styled.div`
  position: relative;
  overflow: hidden;
`);

const StyledImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px dashed grey;
  border-radius: 0px;
  object-fit: contain;
  object-position: center;
`;
