import { User, UserManager, Log } from 'oidc-client';
import { appconfig } from '../../appconfig';
import { Logger } from '../logger/logger';
import { setIsLoggingOff } from '../../context/AppStateProvider';

Log.logger = console;
Log.level = Log.DEBUG;

export const usermanager = new UserManager({
  authority: appconfig.OIDC_ISSUER,
  redirect_uri: `${window.location.origin}/auth/callback`,
  silent_redirect_uri: `${window.location.origin}/auth/renew`,
  automaticSilentRenew: true,
  client_id: appconfig.OIDC_CLIENT_ID,
  response_type: 'code',
  response_mode: 'fragment',
  scope: appconfig.OIDC_SCOPE,
  loadUserInfo: false, // Azure B2C does not support the userinfo endpoint.
  post_logout_redirect_uri: window.location.origin ? window.location.origin : 'https://' + window.location.host,
});

const logger = new Logger('AuthClient');

let currentUser: User | undefined;
let expiryTimer: NodeJS.Timeout | undefined;

export function getUser() {
  return currentUser;
}

export async function logout(msg?: string) {

  console.log("Logout");
  const args = createRedirectArgs();

  setIsLoggingOff(true);

  await usermanager.removeUser();
  await usermanager.revokeAccessToken();
  await usermanager.clearStaleState();
  //unloadPermissionsState();
  document.cookie = `msaid=;Path=/;Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

  if (args.post_logout_redirect_uri == undefined) args.post_logout_redirect_uri = 'https://' + window.location.host;

  let querystring='';
  if (msg !== undefined) {
    querystring = 'msg='+msg;
    args.post_logout_redirect_uri = 'https://' + window.location.host + '/?' + querystring;
  }

  // console.log('appconfig.ENVIRONMENT', appconfig.ENVIRONMENT)
  if (appconfig.ENVIRONMENT === 'development') {
    if (querystring.length > 0) {
      window.location.href = location.protocol + '//' + window.location.host + '/?' + querystring;
    } else
    {
      window.location.href = location.protocol + '//' + window.location.host;
    }

  } else {
    console.dirxml('signoutRedirect args:', args);
    await usermanager.signoutRedirect(args);
  }

}

interface SignoutRedirectParam {
  id_token_hint?: string;
  post_logout_redirect_uri?: string;
}

function createRedirectArgs() {

  // if the user is from Azure AD federated account, then check the post logout url to perform single logout
  // otherwise, just provide the id_token for logout
  const id_token = currentUser?.id_token;
  const redirectArgs: SignoutRedirectParam = { id_token_hint: id_token };
  //const regexPattern = new RegExp('^https://login.microsoftonline.com/[a-zA-z0-9-]+/v2.0$');
  const regexPattern = new RegExp('^https://login.microsoftonline.com');
  if (
    currentUser?.profile.idp &&
    typeof currentUser.profile.idp === 'string' &&
    regexPattern.test(currentUser.profile.idp)
  ) {

    redirectArgs.post_logout_redirect_uri = `https://stazb2cprod01.blob.core.windows.net/azb2cuiprod/logout.html??post_logout_redirect_uri=https://login.microsoftonline.com/common/oauth2/logout??post_logout_redirect_uri=${window.location.origin}`;
    //redirectArgs.post_logout_redirect_uri = `/logout.html?post_logout_redirect_uri=https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=${window.location.origin}`;
  }
  return redirectArgs;
}

usermanager.events.addSilentRenewError(() => {
  currentUser = undefined;

  window.alert('Your session has expired and you will now be logged out.');
  logger.info('Silent renew failed, logging out');
  logout();
});

usermanager.events.addUserLoaded((user) => {
  currentUser = user;
});

usermanager.events.addUserUnloaded(() => {
  currentUser = undefined;

  if (expiryTimer) {
    clearTimeout(expiryTimer);
  }
});

export function getErrorMessage(err: any): string {
  if (!err?.message) {
    return "Unknown error condition\n\nAn exception has been raised, but there is no associated message";
  }
  let message: string = err.message;
  let comment = "";

  for (let moreInfo of moreInfos) {
    if (moreInfo.regexp.test(message)) {
      comment = moreInfo.comment;
      break;
    }
  }

  return ("Error condition: " + message + "\n\n" + comment).trimEnd();
}

interface MoreInfo {
  regexp: RegExp;
  comment: string;
}

const moreInfos: MoreInfo[] = [
  {
    regexp: /iat is in the future/,
    comment: `This error condition can happen if the clock on your 
computer is inaccurate by more than five minutes. 

Verify that your computer's clock is accurate and then
try again.

If you are sure that the clock on your computer is 
accurate, please contact technical support.`
  }
];