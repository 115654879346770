import { useAsyncEffect } from '../../kit/hooks/UseAsyncEffect';
import { getErrorMessage, usermanager } from "./Client";
import * as Sentry from "@sentry/react";

export function AuthCallback() {
  useAsyncEffect(async () => {
    try {
      await usermanager.signinPopupCallback();
    }
    catch (err: any) {
      Sentry.captureException(err);
      window.alert(getErrorMessage(err));
    }
  });

  return null;
}
