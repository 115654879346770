import { useAsyncEffect } from '../../kit/hooks/UseAsyncEffect';
import { Logger } from '../logger/logger';
import { usermanager } from './Client';

const logger = new Logger('SilentRenewCallback');

export function SilentRenewCallback() {
  useAsyncEffect(async () => {
    logger.info('Silently renewing token');
    await usermanager.signinSilentCallback();
  });

  return null;
}
