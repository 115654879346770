export function assertNotUndefined<T>(value: T | undefined | null): value is T {
  if (value !== undefined && value !== null) {
    return true;
  }
  throw new Error('unexpected null or undefined value');
}

export function assert(expr: () => boolean) {
  if (!expr()) {
    throw new Error('assertion failed');
  }
}
