import React from 'react';
import { TextInput } from '../Forms/TextInput';

interface Props {
  value: string;
  count: number;
  onChange: (value: string) => void;
}

export function GlobalFilter(props: Props) {
  return (
    <TextInput
      name="globalFilter"
      value={props.value}
      onChange={(_, value) => props.onChange(value)}
      placeholder={`search ${props.count} rows...`}
    />
  );
}
