import itiriri from 'itiriri';
import React from 'react';
import { HSFacilityGroup } from 'server-openapi';
import styled from 'styled-components';
import { SelectOption } from '../../../../kit/Forms/Select';
import { TypeaheadSelect } from '../../../../kit/Forms/TypeaheadSelect';
import { useAsync } from '../../../../kit/hooks/UseAsync';
import { FacilityGroupOnlineService } from '../../../../core/mrs/Services';
import { OnboardingPageActions } from '../OnboardingPage';
import { NextButton, PageNavigationButtonContainer, PreviousButton } from './NavigationButtonContainer';

interface IProps extends OnboardingPageActions {
  onSetFacilityGroupId: (facilityGroupId?: number) => void;

  selectedFacilityGroupId?: number;
}

export function SelectFacilityGroupPanel(props: IProps) {
  const getFacilityGroupOptions = (facilityGroup: HSFacilityGroup[]) =>
    itiriri(facilityGroup)
      .sort((a, b) => a.name!.localeCompare(b.name!))
      .toArray()
      .map((fg) => ({ label: fg.name, value: fg.hsId } as SelectOption<number>));

  const facilityGroupsOptions = useAsync<Array<SelectOption<number>>>(async () => {
    const availableGroups = await FacilityGroupOnlineService.listAvailableFacilityGroups();
    return getFacilityGroupOptions(availableGroups);
  });

  return (
    <>
      <SelectYourFacilityInputContainer>
        <div className="input-label">SELECT YOUR FACILITY</div>
        {facilityGroupsOptions.value && (
          <TypeaheadSelect
            name="selectFacility"
            fullWidth
            options={facilityGroupsOptions.value}
            onChange={(_, v) => props.onSetFacilityGroupId(v.value)}
            onQueryChange={() => props.onSetFacilityGroupId(undefined)}
            defaultValue={facilityGroupsOptions.value?.find((fgo) => fgo.value === props.selectedFacilityGroupId)}
          />
        )}
      </SelectYourFacilityInputContainer>

      <PageNavigationButtonContainer>
        <PreviousButton onClick={props.goToPreviousPage}>Cancel</PreviousButton>
        <NextButton disabled={!props.selectedFacilityGroupId} onClick={props.goToNextPage}>
          Next
        </NextButton>
      </PageNavigationButtonContainer>
    </>
  );
}

const SelectYourFacilityInputContainer = styled.div`
  background: ${(p) => p.theme.backgrounds.lighter.bg};
  padding: 20px;

  & .input-label {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
  }
`;
