import { format } from 'date-fns';
import itiriri from 'itiriri';
import React from 'react';
import { HSMedication, HSPatient, HSDrug } from 'server-openapi';
import styled from 'styled-components';
import { useStore } from '../../../../core/storage/hooks/UseStore';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { Layout } from '../../../../kit/Layout';
import { Text } from '../../../../kit/Text';
import { useSyncCenter } from '../../../../syncstream/SyncCenterProvider';
import { DrugUtils } from '../../../../syncstream/utils/DrugUtils';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import { MedicationInfoSection } from '../medicationInformation/MedicationInfoSection';
import { PatientCompactInfo } from '../PatientCompactInfo';
interface IProps {
  patient: HSPatient;
  facilityGroupId: number;
}

export function PatientCeasedMedications(props: IProps) {
  const patientUtils = useApiUtils().patients;
  const facility = patientUtils.findPatientFacility(props.patient);
  const services = useSyncCenter();
  const drugStore = useStore(services.drugs.store).store;
  const drugs = itiriri(drugStore.values()).toArray();
  const recentlyDeletedMedications =
    props.patient.patientProfiles?.flatMap((profile) => profile.recentlyDeletedMedications ?? []) ?? [];
  return (
    <Layout gap={1}>
      <PatientCompactInfo patient={props.patient} facility={facility!} />
      <Layout gap={1}>
        {recentlyDeletedMedications.map((ceasedMed, index) => (
          <CeasedMedicationCard
            key={index}
            patient={props.patient}
            ceasedMedication={ceasedMed}
            drugList={drugs}
            facilityGroupId={props.facilityGroupId}
          />
        ))}
      </Layout>
    </Layout>
  );
}

function CeasedMedicationCard(props: {
  patient: HSPatient;
  ceasedMedication: HSMedication;
  drugList: HSDrug[];
  facilityGroupId: number;
}) {
  const apiUtils = useApiUtils();
  const residentDetailUtils = apiUtils.residentDetails;
  const patientUtils = apiUtils.patients;
  const ceasedAtDate = DateUtils.toDate(props.ceasedMedication.lastModifiedAt!);
  const ceasedBy = props.ceasedMedication.ceasedBy ?? 'N/A';
  const ceasedDoseTimes =
    props.ceasedMedication.doses?.map((doseObj) => {
      if ('timeOfDay' in doseObj && doseObj.timeOfDay) {
        return DateUtils.timeStringTo24HourTimeString(doseObj.timeOfDay.trim());
      }
      return '';
    }) ?? [];

  const packType = props.ceasedMedication.packType
    ? residentDetailUtils.packTypeToString(props.ceasedMedication.packType)
    : 'N/A';
  const drug = props.drugList?.find((drug) => drug.hsId === props.ceasedMedication.drug);
  const medicationType = residentDetailUtils.getMedicationTypeString(props.ceasedMedication, drug!);
  const route = props.ceasedMedication?.route?.code ?? undefined;
  const directions = props.ceasedMedication?.directions ?? undefined;
  const administrationWarnings = DrugUtils.getAdministrationWarnings(
    drug,
    undefined,
    props.ceasedMedication, //this is hsmedication
    props.ceasedMedication.selfAdministered ?? false,
  );
  const chartItemDose = patientUtils.getHSChartDoseFromMedication(props.patient, props.ceasedMedication);

  return (
    <InformationBox horizontal>
      <InformationColumn justify={'center'} style={{ flex: 1 }}>
        <MedicationInfoSection
          drug={drug}
          route={route}
          directions={directions}
          administrationWarnings={administrationWarnings}
          facilityGroupId={props.facilityGroupId}
          chartItemDose={chartItemDose}
          isTimeCritical={props.ceasedMedication.timeCritical ?? false}
        />
      </InformationColumn>
      <InformationColumn gap={1} justify={'center'} style={{ flex: 1 }}>
        <Layout justify={'center'}>
          <Text weight={'bold'}>Medication Type</Text>
          <Text>{medicationType}</Text>
        </Layout>
        <Layout justify={'center'}>
          <Text weight={'bold'}>Pack Type</Text>
          <Text>{packType}</Text>
        </Layout>
      </InformationColumn>
      <InformationColumn justify={'center'} style={{ flex: 1 }}>
        <Text weight={'bold'}>Ceased Dose Times</Text>
        <Text>{ceasedDoseTimes.length > 0 ? ceasedDoseTimes.join(', ') : 'N/A'}</Text>
      </InformationColumn>
      <InformationColumn justify={'center'} style={{ flex: 1 }}>
        <Text weight={'bold'}>Ceased At</Text>
        <Text>
          {DateUtils.dateTo24HourTimeString(ceasedAtDate)} on {format(ceasedAtDate, 'dd MMM yyyy')}
        </Text>
      </InformationColumn>
      <InformationColumn justify={'center'} style={{ flex: 2 }}>
        <Text weight={'bold'}>Ceased By</Text>
        <Text>{ceasedBy}</Text>
      </InformationColumn>
    </InformationBox>
  );
}

const InformationBox = styled(Layout)`
  background: white;
  color: black;
  display: flex;
  align-items: center;
  padding: 0.875rem;
  width: 100%;
`;

const InformationColumn = styled(Layout)`
  flex-basis: 100%;
  padding-right: 20px;
`;
